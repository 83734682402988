<template>
    <popup :settings="settings">
        <div slot="title">Параметры тарифов</div>
        <div slot="content">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                    <div class="popup-add">
                        <form action="" class="popup-add__form">
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <fieldset v-for="(field, index) in constance" class="popup-add__form--item">
                                        <p>{{ field.help_text }}</p>
                                        <template v-if="field.id === 'DATE_OF_WITHDRAWAL'">
                                            <div class="selected">
                                                <select v-model="constance_data[field.id]">
                                                    <option v-for='n in 28' :value="n">{{ n }}</option>
                                                </select>
                                            </div>
                                        </template>
                                        <template v-else-if="field.id === 'LOYALTY_PROGRAM'">
                                            <div class="selected">
                                                <select v-model="constance_data[field.id]">
                                                    <option v-for="choice in constance[index].choices" :value="choice.key">{{ choice.value }}</option>
                                                </select>
                                            </div>
                                        </template>
                                        <template v-else>

                                            <!--<template v-if="field.type === 'time'">-->
                                            <!--<date-picker v-model="constance_data[field.id]"-->
                                            <!--:lang="'ru'"-->
                                            <!--:type="'time'"-->
                                            <!--:placeholder="field.help_text"-->
                                            <!--:format="'hh:mm:ss'"></date-picker>-->
                                            <!--</template>-->
                                            <!--<template v-else>-->
                                            <!---->
                                            <!--</template>-->

                                            <input type="text" v-model="constance_data[field.id]"
                                                   :placeholder="field.help_text">
                                        </template>
                                    </fieldset>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                    <template v-for="(field, index) in constance">
                        <template v-if="field.id === 'LOYALTY_PROGRAM'">
                            <ul class="popup-info">
                                <li class="popup-info__title">{{ field.help_text }}</li>
                                <li class="popup-info__value">{{ get_choice_value(field) }}</li>
                            </ul>
                        </template>
                        <template v-else>
                            <ul class="popup-info">
                                <li class="popup-info__title">{{ field.help_text }}</li>
                                <li class="popup-info__value">{{ field.value }}</li>
                            </ul>
                        </template>
                    </template>
                </div>
            </div>
            <!--<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">-->
            <!--<div class="modal__content">-->
            <!--<form action="" class="form-add">-->
            <!--<fieldset v-for="(field, index) in constance" class="form-add__item">-->

            <!--<p>{{ field.help_text }}:</p>-->

            <!--<template v-if="field.id === 'DATE_OF_WITHDRAWAL'">-->

            <!--<select v-model="constance_data[field.id]">-->
            <!--<option v-for='n in 28' :value="n">{{ n }}</option>-->
            <!--</select>-->

            <!--</template>-->
            <!--<template v-else>-->

            <!--&lt;!&ndash;<template v-if="field.type === 'time'">&ndash;&gt;-->
            <!--&lt;!&ndash;<date-picker v-model="constance_data[field.id]"&ndash;&gt;-->
            <!--&lt;!&ndash;:lang="'ru'"&ndash;&gt;-->
            <!--&lt;!&ndash;:type="'time'"&ndash;&gt;-->
            <!--&lt;!&ndash;:placeholder="field.help_text"&ndash;&gt;-->
            <!--&lt;!&ndash;:format="'hh:mm:ss'"></date-picker>&ndash;&gt;-->
            <!--&lt;!&ndash;</template>&ndash;&gt;-->
            <!--&lt;!&ndash;<template v-else>&ndash;&gt;-->
            <!--&lt;!&ndash;&ndash;&gt;-->
            <!--&lt;!&ndash;</template>&ndash;&gt;-->

            <!--<input type="text" v-model="constance_data[field.id]" :placeholder="field.help_text">-->
            <!--</template>-->

            <!--<form-error v-bind:value="errors[field.id]"></form-error>-->
            <!--</fieldset>-->
            <!--</form>-->
            <!--</div>-->
            <!--</div>-->
            <!--<div class="info-hide col-md-6 col-lg-6">-->
            <!--<div class="modal__content">-->
            <!--<ul class="form-info">-->
            <!--<template v-for="(field, index) in constance">-->
            <!--<li>{{ field.help_text }}:</li>-->
            <!--<li class="value">{{ field.value }}</li>-->
            <!--</template>-->
            <!--</ul>-->
            <!--</div>-->
            <!--</div>-->
        </div>
        <div slot="footer">
            <a href="#" @click="save" class="modal__btn">Сохранить</a>
            <a href="#" @click="popup_close">Отмена</a>
        </div>
    </popup>
</template>

<script>
    import Popup from '../modal/popup.vue'
    import {CrudMixin} from "../../../../mixins/admin/crud/crud_base";
    import FormErrors from '../form/errors.vue';
    import {ConstanceMixin} from "../../../../mixins/admin/constance";
    import {API} from "../../../../api";
    import {User} from "../../../../mixins/admin/user";
    import DatePicker from 'vue2-datepicker';

    export default {
        name: "options",
        props: ["settings", "crud"],
        mixins: [
            User, CrudMixin, ConstanceMixin
        ],
        data: function () {
            return {
                constance_data: {},
                errors: {}
            }
        },
        mounted() {
            this.fetchUser();
        },
        methods: {
            save() {
                var id, value, self = this, success_save = 0;
                this.errors = {};

                for (var key in this.constance) {
                    id = this.constance[key].id;
                    value = this.constance_data[id];

                    this.change_constance(id, {'value': value})
                        .then(function (response) {
                            success_save += 1;
                            self.success_save(success_save);
                        })
                        .catch(function (error) {
                            var errors = API.get_errors(error);
                            self.$set(self, "errors", Object.assign({}, self.errors, API.convert_errors(errors)))
                            // API.default_catch(self, self.errors)
                        })
                        .then(function () {
                            API.default_always(self);
                        })
                }
            },
            success_save(success_save) {
                if (success_save === this.constance.length) {
                    this.load_constance();
                    this.popup_close();
                }
            },
            fetchUser() {
                if (this.is_superuser()) {
                    this.load_constance();
                }
            },
            get_choice_value(field) {
                for (var [key, value] of Object.entries(field.choices)) {
                    if (value.key === field.value) {
                        return value.value;
                    }
                }
            }
        },
        components: {
            "date-picker": DatePicker,
            "popup": Popup,
            "form-error": FormErrors
        },
        watch: {
            constance(value) {
                for (var key in value) {
                    this.constance_data[value[key].id] = value[key].value;
                }
            }
        }

    }
</script>

<style scoped>

</style>