<template>
    <form action="" class="popup-add__form">
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                <fieldset class="popup-add__form--item">
                    <p><i class="fal fa-box"></i> Название пакета</p>
                    <input type="text" v-model="form.name" placeholder="Название пакета">
                </fieldset>
                <form-error v-bind:value="errors.name"></form-error>
                <form-error v-bind:value="errors.non_field_errors"></form-error>
            </div>
             <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <fieldset class="popup-add__form--item">
                    <p><i class="fal fa-user-lock"></i> Доступно с ЛК</p>
                    <div class="switch-checkbox">
                        <input type="checkbox" v-model="form.is_available_in_profile" class="switch-input" :id="'switch-lk-'+initial.render">
                        <label class="switch-input__for" :for="'switch-lk-'+initial.render"></label>
                    </div>
                    <form-error v-bind:value="errors.is_available_in_profile"></form-error>
                </fieldset>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <fieldset class="popup-add__form--item">
                    <p><i class="fal fa-user-tie"></i> Тип абонента</p>
                    <div class="selected">
                        <select v-model="form.subscriber_type" required>
                            <option :value="null">Все</option>
                            <option v-for="item in subscriber_types" v-bind:value="item.id">
                                {{item.name_expand}}
                            </option>
                        </select>
                    </div>
                    <form-error v-bind:value="errors.subscriber_type"></form-error>
                </fieldset>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <fieldset class="popup-add__form--item">
                    <p><i class="fal fa-wifi"></i> Тип подключения</p>
                    <div class="selected">
                        <select v-model="form.connection_type" required>
                            <option :value="null">Все</option>
                            <option v-for="item in connection_types" v-bind:value="item.id">
                                {{ item.label }}
                            </option>
                        </select>
                    </div>
                    <form-error v-bind:value="errors.connection_type"></form-error>
                </fieldset>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <fieldset class="popup-add__form--item">
                    <p><i class="fal fa-code-branch"></i> Отдел</p>
                    <div class="selected">
                        <select v-model="form.department" required>
                            <option :value="null">Все</option>
                            <option v-for="item in departments" v-bind:value="item.id">{{ item.name }}</option>
                        </select>
                    </div>
                </fieldset>
                <form-error v-bind:value="errors.department"></form-error>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <fieldset class="popup-add__form--item">
                    <p><i class="fal fa-code-branch"></i> Группа</p>
                    <div class="selected">
                        <select v-model="form.group" required>
                            <option :value="null">Все</option>
                            <option v-for="group in groups" v-bind:value="group.id">{{ group.name }}</option>
                        </select>
                    </div>
                </fieldset>
                <form-error v-bind:value="errors.group"></form-error>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <fieldset class="popup-add__form--item">
                    <p><i class="fal fa-calendar-alt"></i> Тарификация</p>
                    <div class="selected">
                        <select v-model="form.charging_type" required>
                            <option v-for="item in ip_address_charging_type" v-bind:value="item.key">{{ item.label }}</option>
                        </select>
                    </div>
                </fieldset>
                <form-error v-bind:value="errors.charging_type"></form-error>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4" v-if="form.charging_type === 'day'">
                <fieldset class="popup-add__form--item">
                    <p><i class="fal fa-hourglass"></i> Сутки</p>
                    <div class="price-input">
                        <input type="text" v-model="form.charging_amount_day" disabled="disabled" placeholder="Сутки">
                    </div>
                </fieldset>
                <form-error v-bind:value="errors.charging_amount_day"></form-error>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <fieldset class="popup-add__form--item">
                    <p><i class="fal fa-calendar-week"></i> Месяц</p>
                    <div class="price-input">
                        <input type="text" v-model="form.charging_amount_month" placeholder="Месяц">
                    </div>
                </fieldset>
                <form-error v-bind:value="errors.charging_amount_month"></form-error>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <fieldset class="popup-add__form--item">
                    <p><i class="fal fa-user-clock"></i> Кол-во дней резерв</p>
                    <input type="number" v-model="form.count_days_reserved" placeholder="Кол-во дней резерв">
                </fieldset>
                <form-error v-bind:value="errors.count_days_reserved"></form-error>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <fieldset class="popup-add__form--item">
                    <p><i class="fal fa-comment-lines"></i> Примечание</p>
                    <textarea name="desc" v-model="form.note" placeholder="Описание IP-pool network"></textarea>
                </fieldset>
                <form-error v-bind:value="errors.note"></form-error>
            </div>
        </div>
        <form-error v-bind:value="errors.non_field_errors"></form-error>
    </form>
</template>

<script>
    import _ from 'lodash';
    import FormErrors from '../../../form/errors.vue';
    import {SubscriberTypeMixin} from "../../../../../../mixins/admin/subscriber_type";
    import {ConnectionTypeMixin} from "../../../../../../mixins/admin/connection_type";
    import {DepartmentMixin} from "../../../../../../mixins/admin/department";
    import {ServiceIpAddressChargingTypeMixin} from "../../../../../../mixins/admin/services_ip_address_charging_type";
    import {loader} from "../../../../../../models/loader";
    import {API} from "../../../../../../api";
    import {GroupMixin} from "../../../../../../mixins/admin/group";

    export default {
        name: "form-ip-address",
        props: ['initial', 'errors'],
        mixins: [SubscriberTypeMixin, ConnectionTypeMixin, DepartmentMixin, ServiceIpAddressChargingTypeMixin,
                 GroupMixin],
        components: {
            "form-error": FormErrors,
        },
        data(){
            return {
                form: this.get_form_clear_data()
            }
        },
        mounted(){
            this.load_subscriber_types();
            this.load_connection_type();
            this.load_departments();
            this.load_ip_address_charging_type();
            this.load_groups();
        },
        methods: {
            get_form_clear_data(){
                return {
                    name: "",
                    is_available_in_profile: false,
                    subscriber_type: null,
                    connection_type: null,
                    department: null,
                    group: null,
                    charging_type: "",
                    count_days_reserved: 30,
                    charging_amount_day: "",
                    charging_amount_month: "",
                    note: "",
                }
            },
            set_default_ip_address_charging_type(){
                if((this.form.charging_type === "")&&(this.ip_address_charging_type)){
                    this.form.charging_type = this.ip_address_charging_type[0]['key'];
                }
            },
            calculate_charging_amount_day: _.throttle((self) => {
                if(self.form.charging_type === 'day'){
                    loader.active = false;
                    API.post('/services/ip-address-calc-day-by-month/', {charging_amount_month: self.form.charging_amount_month}).
                    then(function (response) {
                        self.form.charging_amount_day = response.data.charging_amount_day;
                        loader.active = true;
                    })
                }
            }, 1000)
        },
        watch: {
            form: {
                handler(value) {
                    this.$emit('editing_form', this.form)
                },
                deep: true
            },
            ip_address_charging_type(){
                this.set_default_ip_address_charging_type()
            },
            initial() {
                var field;
                this.form = this.get_form_clear_data();
                this.set_default_ip_address_charging_type();
                for(field in this.form){
                    if(this.initial[field] !== undefined){
                        this.form[field] = this.initial[field];
                    }
                }
            },
            'form.charging_type': function(value){
                this.calculate_charging_amount_day(this);
            },
            'form.charging_amount_month': function(value) {
                this.calculate_charging_amount_day(this);
            },
        }
    }
</script>

<style scoped>

</style>