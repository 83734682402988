<template>
    <div class="grid__box" v-has-perm="'subscribers_access'">
        <div class="view">
            <div class="view__row">
                <div class="view__section">
                    <div class="view__header">
                        <div class="view__header-title">
                            <span class="view__header-icon"><i class="fal fa-plug"></i></span>
                            <span class="view__header-text">PPPoE соединение</span>
                        </div>
                    </div>
                    <!--Логин PPPoE-->
                    <div class="view__item">
                        <div class="view__label">
                            <span class="view__label-icon"><i class="fal fa-user"></i></span>
                            <span class="view__label-title">Логин:</span>
                        </div>
                        <div class="view__value">
                            <span class="view__value-text">{{ subscriber_connection.username }}</span>
                        </div>
                    </div>
                    <!--Пароль PPPoE-->
                    <div class="view__item">
                        <div class="view__label">
                            <span class="view__label-icon"><i class="fal fa-key"></i></span>
                            <span class="view__label-title">Пароль:</span>
                        </div>
                        <div class="view__value">
                            <span class="view__value-text">
                                <template v-if="password_visible">
                                {{ subscriber_connection.password }}
                                <span v-tooltip="TooltipText='Скрыть пароль'" data-title="Скрыть пароль" class="view__password view__password-hide"
                                      @click="set_password_visible(0)"><i class="fal fa-eye-slash"></i></span>
                                </template>
                                <template v-else>
                                    <span v-tooltip="TooltipText='Показать пароль'" data-title="Показать пароль" class="view__password"
                                          @click="set_password_visible(1)"><i class="fal fa-eye"></i></span>
                                </template>
                            </span>
                        </div>
                    </div>
                    <!--Статус PPPoE-->
                    <div class="view__item">
                        <div class="view__label">
                            <span class="view__label-icon"><i class="fal fa-plug"></i></span>
                            <span class="view__label-title">Статус:</span>
                        </div>
                        <div class="view__value">
                            <template v-if="subscriber_connection.online">
                                <span class="view__value-status view__value-status_active">
                                    Online
                                </span>
                            </template>
                            <template v-else>
                                <span class="view__value-status view__value-status_deactive">
                                    Offline
                                </span>
                            </template>
                        </div>
                    </div>
                    <!--IP адрес PPPoE-->
                    <div class="view__item">
                        <div class="view__label">
                            <span class="view__label-icon"><i class="fal fa-network-wired"></i></span>
                            <span class="view__label-title">IP:</span>
                        </div>
                        <div class="view__value">
                            <span class="view__value-text">{{ subscriber_connection.framed_ip }}</span>
                        </div>
                    </div>
                    <!--MAC адрес PPPoE-->
                    <div class="view__item">
                        <div class="view__label">
                            <span class="view__label-icon"><i class="fal fa-phone-laptop"></i></span>
                            <span class="view__label-title">MAC:</span>
                        </div>
                        <div class="view__value">
                            <span class="view__value-text">{{ subscriber_connection.mac_address }}</span>
                        </div>
                    </div>
                    <!--Последняя сессия-->
                    <div class="view__item">
                        <div class="view__label">
                            <span class="view__label-icon"><i class="fal fa-power-off"></i></span>
                            <span class="view__label-title">Последняя активность:</span>
                        </div>
                        <div class="view__value">
                            <span class="view__value-text">{{ date_start | moment("DD/MM/YYYY") }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "pppoe-block",
        props: ['subscriber_connection'],
        data: function () {
            return {
                password_visible: false,
                date_start: null,
            }
        },
        methods: {
            set_password_visible: function (value) {
                this.password_visible = value;
            }
        },
        watch: {
            subscriber_connection(value) {
                this.date_start = this.subscriber_connection.last_session_date_start.date_start;
            }
        }
    }
</script>

<style scoped>

</style>