<template>
    <div>
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                <label>
                    <i class="fal fa-calendar-alt"></i> Дата начала акции (включительно):
                    <span class="control"><i
                            class="fas fa-star-of-life"></i></span>
                </label>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                <DatePicker v-model="settings.start_date" :lang="'ru'" :format="'DD-MM-YYYY'"></DatePicker>
            </div>
            <div class="clearfix"></div>
            <FormErrors :value="errors.start_date"></FormErrors>
        </div>
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                <label>
                    <i class="fal fa-calendar-alt"></i> Дата конца акции (включительно):
                    <span class="control"><i
                            class="fas fa-star-of-life"></i></span>
                </label>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                <DatePicker v-model="settings.finish_date" :lang="'ru'" :format="'DD-MM-YYYY'"></DatePicker>
            </div>
            <div class="clearfix"></div>
            <FormErrors :value="errors.finish_date"></FormErrors>
        </div>
        <fieldset class="form-data__fieldset">
            <div class="form-data__item">
                <div class="form-data__label">
                    <span class="form-data__label-icon"><i
                            class="fal fa-users-crown"></i></span>
                    <span class="form-data__label-title">Тариф для смены:</span>
                    <span class="form-data__label-control"><i
                            class="fal fa-asterisk"></i></span>
                </div>
                <div class="form-data__value">
                    <div class="form-data__select">
                        <select v-model="settings.tariff_to" required>
                            <option :value="null" disabled hidden>Выбирите тариф</option>
                            <option v-for="tariff in filterTariffs" :value="tariff.id">
                                {{ tariff.system_name }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <FormErrors :value="errors.tariff_to"></FormErrors>
            <FormErrors :value="componentErrors.tariff_to"></FormErrors>
        </fieldset>
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                <label>
                    <i class="fal fa-calendar-alt"></i> Дата окончания для подключения акции (включительно):
                    <span class="control"><i
                            class="fas fa-star-of-life"></i></span>
                </label>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                <DatePicker v-model="settings.finish_connect_date" :lang="'ru'" :format="'DD-MM-YYYY'"></DatePicker>
            </div>
            <div class="clearfix"></div>
            <FormErrors :value="errors.finish_connect_date"></FormErrors>
        </div>
        <div v-if="currentTariff.promotional_tariff_settings_expand" class="form-data__footer form-data__footer_right">
            <div class="btn-group">
                <span class="btn btn_red" @click="deletePromotionalSettings">
                    <span class="btn__icon"><i class="fal fa-save"></i></span>
                    <span class="btn__title">Удалить</span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import DatePicker from 'vue2-datepicker';
    import FormErrors from '../../form/errors.vue';
    import {TariffMixin} from "../../../../../mixins/admin/tariff";
    import {loader} from "../../../../../models/loader";
    import {API} from "../../../../../api";

    export default {
        name: "promotional-settings",
        props: ["currentTariff", "parentErrors"],
        mixins: [TariffMixin, ],
        components: {
            DatePicker,
            FormErrors
        },
        mounted() {
            this.getAvailableTariffsForPromotionalSettings();
        },
        data() {
            return {
                // odd construction to viewing errors when promo settings set incorrect
                errors: this.parentErrors[0],
                settings: {
                    start_date: "",
                    finish_date: "",
                    tariff_to: "",
                    finish_connect_date: "",
                },
                filterTariffs: [],
                // errors for viewing if user trying sets promo settings when create new tariff
                componentErrors: [],
            }
        },
        methods: {
            getAvailableTariffsForPromotionalSettings() {
                // load tariffs to choosing what tariff will set after finishing promo tariff
                var params = {};

                if (this.settings.finish_date) {
                    params['transfer_date'] = this.$moment(this.settings.finish_date).format("YYYY-MM-DD");
                }

                if (this.currentTariff.id && !this.currentTariff.promotional_tariff_settings_expand) {
                    this.get_available_tariffs_for_transfer(this.currentTariff.id, params);
                } else {
                    this.getAvailableTariffsForPromoTariff(params);
                }
            },
            deletePromotionalSettings() {
                this.$emit('deletePromotionalSettings');
            },
            addPromotionalSettings(settings) {
                this.$emit('addPromotionalSettings', settings);
            },
            getAvailableTariffsForPromoTariff: function (param={}) {
                var self = this;
                loader.active = false;

                var params = this.getParams(self.currentTariff);

                if (!params) {
                    return
                }

                // if we received a parameter with such a key need put in to params variable for request
                if (param['transfer_date']) {
                    params['transfer_date'] = param['transfer_date'];
                }

                API.post('/promotional-tariff-available-tariffs/', params)
                    .then(function (response) {
                        self.tariffs = response.data;
                    })
                    .catch(function (error) {
                        // API.default_catch(self, error)
                    })
                    .then(function () {
                        loader.active = true;
                        // API.default_always(self);
                    })
            },
            getParams(tariff) {
                let connectionTypesIds = tariff.connection_type.filter(el => el.length > 0);

                if ((tariff.department || tariff.is_common_tariff === true) &&
                    tariff.group && connectionTypesIds.length > 0 && tariff.subscriber_type) {

                    let params = {
                        department: tariff.department,
                        group: tariff.group,
                        connection_types: connectionTypesIds,
                        subscriber_type: tariff.subscriber_type,
                        settlements: tariff.settlements
                    };

                    this.$delete(this.componentErrors, 'tariff_to');
                    return params

                } else {
                    this.$set(this.componentErrors,
                        "tariff_to",
                        ["Для выбора тарифа нужно указать 'Отдел', 'Группу', 'Тип(-ы) подключения', 'Тип абонента'"]
                    )
                }
            },
            catch_non_field_error(value) {
                this.alert("Ошибка валидации", value);
            },
        },
        watch: {
            parentErrors: {
                handler(value) {
                    this.errors = value[0];
                    if (typeof this.errors === 'string') {
                        this.catch_non_field_error(value[0]);
                    }
                },
                deep: true
            },
            tariffs: function (value) {
                var self = this;
                // Exclude current tariff if it exists already
                this.filterTariffs = value.filter(function (el) {
                    if (self.currentTariff.id) {
                        return el.id !== self.currentTariff.id;
                    } else {
                        return el
                    }
                });
            },
            currentTariff: {
                handler(value) {
                    if (Object.keys(value).length > 0) {
                        if (value.promotional_tariff_settings_expand) {
                            this.$set(this, 'settings', Object.assign({}, this.settings, value.promotional_tariff_settings_expand));
                        } else if (value.promotional_tariff_settings) {
                            this.$set(this, 'settings', Object.assign({}, this.settings, value.promotional_tariff_settings));
                        }
                    }
                },
                immediate: true
            },
            'currentTariff.subscriber_type': function (value) {
                this.getAvailableTariffsForPromoTariff();
            },
            'currentTariff.connection_type': function (value) {
                this.getAvailableTariffsForPromoTariff();
            },
            'currentTariff.group': function (value) {
                this.getAvailableTariffsForPromoTariff();
            },
            'currentTariff.department': function (value) {
                this.getAvailableTariffsForPromoTariff();
            },
            'currentTariff.settlements': function (value) {
                this.getAvailableTariffsForPromoTariff();
            },
            'settings.finish_date': function (value) {
                if (value) {
                    this.getAvailableTariffsForPromotionalSettings();
                }
            },
            settings: {
                handler(value) {
                    this.addPromotionalSettings(value);
                },
                deep: true
            }
        }
    }
</script>

<style scoped>

</style>