<template>
    <accordion>
        <div slot="title-accordion"><i class="fal fa-info-circle"></i> Доступные услуги</div>
        <div slot="wrapper-accordion">
            <ul class="cs-list">
                <li v-for="service in service_list">
                    <div class="cs-list__name">{{ service.service_name }}</div>
                    <ul class="cs-list__price">
                        <li><i class="fal fa-hryvnia"></i> {{ service.total_cost_month }}/месяц</li>
                        <li><i class="fal fa-hryvnia"></i> {{ service.total_cost_day }}/день</li>
                    </ul>
                </li>
            </ul>
        </div>
    </accordion>
</template>

<script>
    import {SubscriberConnectionAccordionMixin} from "../../../../../mixins/admin/subscriber_connection_accordion";

    export default {
        name: "services__list",
        props: ['service_list'],
        mixins: [SubscriberConnectionAccordionMixin],
        data: function () {
            return {
                showSubAccordion: false,
            }
        }
    }
</script>

<style scoped>

</style>