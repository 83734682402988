<template>
    <td class="table-subscribers__td table-subscribers__td_center" v-has-perm="'subscribers_access'">
        <span class="table-data__status" v-bind:class="[cls_list_css_class(subscriber_connection)]">
            {{ subscriber_connection.cls_list_expand }}
        </span>
    </td>
</template>

<script>
    import {SubscriberMixin} from "../../../../../../mixins/admin/subscriber";

    export default {
        name: "td_clslist",
        props: ["subscriber_connection"],
        mixins: [SubscriberMixin]
    }
</script>

<style scoped>

</style>