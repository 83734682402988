<template>
    <popup :settings="settings">
        <div slot="title">Возврат карты</div>
        <div slot="content" v-if="item">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="modal__content">
                    <div class="return-unicard">
                        <p><i class="fal fa-info-circle"></i> Вы действительно хотите вернуть карту {{ item.number_display }}?</p>
                        <ul class="return-unicard__list">
                            <li><span class="title">От:</span>
                                <template v-if="is_main_type()">
                                    <field-owner-unicard-batch :unicard="item"></field-owner-unicard-batch>
                                </template>
                                <template v-else>
                                    <field-owner-unicard-shop-batch :unicard="item"></field-owner-unicard-shop-batch>
                                </template>
                            </li>
                            <li><span class="title">Кому:</span>
                                <field-owner-unicard-set :unicard="item"></field-owner-unicard-set>
                            </li>
                            <li><span class="title">Серия:</span>
                                <template v-if="item.unicard_set_expand">
                                    {{ item.unicard_set_expand.series }}
                                </template>
                            </li>
                            <li><span class="title">Номер:</span>
                                {{ item.number_display }}
                            </li>
                        </ul>
                    </div>

                    <form-error v-bind:value="errors.non_field_errors"></form-error>
                </div>
            </div>
        </div>
        <div slot="footer">
            <a href="#" @click="return_item()" class="modal__btn">Вернуть</a>
            <a href="#" @click="popup_close">Отмена</a>
        </div>
    </popup>
</template>

<script>
    import Popup from '../modal/popup.vue'
    import {PopupMixin} from "../../../../mixins/admin/popup";
    import {UnicardsMixin} from "../../../../mixins/admin/unicards";
    import FormErrors from '../form/errors.vue';
    import {API} from "../../../../api";
    import FieldOwnerUnicardBatch from "./sub_components/field_owner_unicard_batch";
    import FieldOwnerUnicardShopBatch from "./sub_components/field_owner_unicard_shop_batch";
    import FieldOwnerUnicardSet from "./sub_components/field_owner_unicard_set";

    export default {
        name: "return",
        props: ["settings", "item", "type"],
        components: {
            "popup": Popup,
            "form-error": FormErrors,
            FieldOwnerUnicardBatch,
            FieldOwnerUnicardShopBatch,
            FieldOwnerUnicardSet
        },
        mixins: [
            PopupMixin, UnicardsMixin
        ],
        data(){
          return {
              errors: {}
          }
        },
        methods: {
            return_item(){
                var url = '', self = this;

                // TODO: Refactor code
                url = '/unicards/unicard/'+this.item.id+'/return-unicard/';

                API.post(url).then(function (response) {
                    self.$emit("update_list");
                    self.popup_close();
                }).catch(function (error) {
                    API.default_catch(self, error);
                })
            }
        },
        watch: {
            item (){
                this.errors = {};
            }
        }
    }
</script>

<style scoped>

</style>