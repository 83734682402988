import {API} from "../../api";
import {loader} from "../../models/loader";

export var TransferTariffMixin = {
    data() {
        return {
            showIsNewAdditionalService: undefined,
            showLeaveServiceBlock: false,
            showPromotionalTariffNotificationBlock: false,
        }
    },
    methods: {
        checkChangeTariffToNewAdditionalServices(subscriber_connection, tariff_from, tariff_to) {
            var self = this;
            var params = {
                subscriber_connection: subscriber_connection,
                tariff_from: tariff_from,
                tariff_to: tariff_to
            }
            loader.active = false;
            API.post('/subscriber-connection-change-tariff-checker/', params)
                .then(function (responce) {
                    self.showIsNewAdditionalService = responce.data.are_services_intersect;
                })
                .catch(function (error) {
                    API.default_catch(self, error);
                })
                .then(function () {
                    API.default_always(self);
                })
        },
        checkChangeTariffToNewAdditionalServicesBySubscriber(subscriber_connection, tariff_from, tariff_to) {
            var self = this;
            var params = {
                subscriber_connection: subscriber_connection,
                tariff_from: tariff_from,
                tariff_to: tariff_to
            }
            // loader.active = false;
            API.post('/subscriber-connection-change-tariff-checker-by-subscriber/', params)
                .then(function (responce) {
                    self.showIsNewAdditionalService = responce.data.are_services_intersect;
                })
                .catch(function (error) {
                    API.default_catch(self, error);
                })
                .then(function () {
                    API.default_always(self);
                })
        },
        hasTariffFromServicesAndTariffToHasNot(tariff_from, tariff_to){
            this.showLeaveServiceBlock = !!(tariff_from.services_expand &&
                tariff_from.services_expand.length > 0 &&
                tariff_to.services_expand.length === 0);
        },
        tariffToIsPromotionalTariff(tariff_to){
            this.showPromotionalTariffNotificationBlock = !!tariff_to.promotional_tariff_settings_expand;
        },
    }
}