import {API} from "../../api";

export var IpAddressMixin = {
    computed: {
        destinations_filtered(){
            var value_list = [];
            for(var i in this.destinations) {
                if(this.destinations[i].key !== 'in_rent') {
                    value_list.push(this.destinations[i]);
                }
            }
            return value_list
        }
    },
    methods: {
        get_destination_label(){
            for(var item of this.destinations){
                if(item.key === this.destination){
                    return item.label
                }
            }
            return "";
        },
        is_destination_rent(){
            return this.destination === 'in_rent'
        }
    },
    watch: {
        "crud.data.item": {
             handler: function () {
                 this.destination = this.crud.data.item.destination;
                 this.note = this.crud.data.item.note;
            },
            deep: true
        },
    }
};
