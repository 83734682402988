<template>
    <section class="page">
        <div class="page__inner">
            <div class="container-width">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="page-header">
                            <div class="row">
                                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <h1 class="page-header__title">Продажи магазина "{{ shop.name }}"</h1>
                                </div>
                                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <DropDown></DropDown>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- CONTENT PAGE -->
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="page-body">
                            <div class="page-body__header">
                                <div class="row">
                                    <div class="col-xs-12 col-sm-3 col-md-2 col-lg-2">
                                        <date-range-picker
                                                :startDate="startDate"
                                                :endDate="endDate"
                                                @update="update_range_picker"
                                                :locale-data="locale">
                                        </date-range-picker>
                                    </div>
                                    <div class="col-xs-12 col-sm-3 col-md-2 col-lg-2">
                                        <date-picker v-model="date_month" lang="ru" placeholder="Месяц"
                                                     format="MM-YYYY" type="month"></date-picker>
                                    </div>
                                    <div class="col-xs-12 col-sm-9 col-md-8 col-lg-8">
                                        <div class="view-data-sales__header">
                                            <ul class="view-data-sales__header-list">
                                                <li>Период:
                                                    <span class="value" v-if="summary.period">
                                                    {{ summary.period.date_used_from | moment("DD.MM.YYYY HH:mm") }} - {{ summary.period.date_used_to | moment("DD.MM.YYYY HH:mm") }}
                                                    </span>
                                                </li>
                                                <li>Кол-во карт:
                                                    <span class="value">
                                                        <template v-if="summary.count_unicards > 0">
                                                            {{ summary.count_unicards }}
                                                        </template>
                                                        <template v-else>
                                                            0
                                                        </template>
                                                        шт
                                                    </span>
                                                </li>
                                                <li>Сумма:
                                                    <span class="value">
                                                        <template v-if="summary.sum_unicards > 0">
                                                            {{ summary.sum_unicards }}
                                                        </template>
                                                        <template v-else>
                                                            0
                                                        </template>
                                                         грн
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="table-container" v-if="sales.length">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Период</th>
                                        <th>Кол-во карт</th>
                                        <th>Номинал (Сумма)</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(sale, index) in sales">
                                        <td>{{ index + 1 }}</td>
                                        <td>
                                            <template v-if="sale.period.date_used_from && sale.period.date_used_to">
                                                {{ sale.period.date_used_from | moment("DD.MM.YYYY HH:mm") }} - {{
                                                sale.period.date_used_to | moment("DD.MM.YYYY HH:mm") }}
                                            </template>
                                            <template v-else>
                                                -
                                            </template>
                                        </td>
                                        <td>
                                            <router-link title="Карты" :to="get_link_to_cards(sale)">
                                                {{ sale.count_unicards }} шт.
                                            </router-link>
                                        </td>
                                        <td><i class="fal fa-hryvnia"></i> {{ sale.face_value }} (<i
                                                class="fal fa-hryvnia"></i> {{ sale.sum_unicards }})
                                        </td>
                                    </tr>
                                    <tr class="summ">
                                        <td>Итого:</td>
                                        <td>
                                            <template v-if="summary.period">
                                                {{ summary.period.date_used_from | moment("DD.MM.YYYY HH:mm") }} - {{
                                                summary.period.date_used_to | moment("DD.MM.YYYY HH:mm") }}
                                            </template>
                                        </td>
                                        <td>
                                            <router-link title="Карты"
                                                         :to="{ name: 'unicards-list-cards', query:{status: 'used', unicard_shop_batch__shop: shop_id, date_used__date_gte: form.date_used_gte, date_used__date_lte: form.date_used_lte }}">
                                                {{ summary.count_unicards }} шт.
                                            </router-link>
                                        </td>
                                        <td><i class="fal fa-hryvnia"></i>{{ summary.sum_unicards }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="row" v-else>
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div class="view-no-data">
                                        <div class="view-no-data__title">Нет данных за период</div>
                                        <div class="view-no-data__date"> {{ form.date_used_gte }} — {{
                                            form.date_used_lte }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import DropDown from "../dropdown/dropdown";
    import DatePicker from 'vue2-datepicker';
    import {User} from "../../../../../mixins/admin/user";
    import {ShopsMixin} from "../../../../../mixins/admin/shops";
    import {PermissionMixin} from "../../../../../mixins/admin/permission";
    import {DateRangePickerDefaults} from "../../../../../mixins/admin/date_range_picker_defaults";
    import {API} from "../../../../../api";

    export default {
        name: "sales",
        mixins: [DateRangePickerDefaults, User, ShopsMixin, PermissionMixin],
        data() {
            return {
                shop: {},
                sales: [],
                summary: {},
                startDate: this.$moment().startOf('month'),
                endDate: this.$moment().endOf('month'),
                date_month: this.$moment().startOf('month'),
                shop_id: "",
                form: {
                    date_used_gte: "",
                    date_used_lte: "",
                },
            }
        },
        components: {
            DropDown,
            "date-picker": DatePicker,
        },
        mounted() {
            this.fetchUser();
        },
        methods: {
            fetchUser() {
                    this.shop_id = this.$route.params.id;
                    this.update_range_picker({startDate: this.startDate, endDate: this.endDate});
                    this.load_shop(this.shop_id);
                    this.load_sales();
            },
            update_range_picker(date_range) {
                this.form.date_used_gte = this.$moment(date_range.startDate).format("YYYY-MM-DD");
                this.form.date_used_lte = this.$moment(date_range.endDate).format("YYYY-MM-DD");
            },
            load_sales() {
                var self = this;
                var filter = Object.assign({}, this.form);
                API.get('/shops/' + this.shop_id + '/sales/', filter).then(function (response) {
                    self.sales = response.data.results;
                    self.summary = response.data.summary;
                });
            },
            get_link_to_cards(sale) {
                return {
                    name: 'unicards-list-cards',
                    query: {
                        status: 'used',
                        unicard_shop_batch__shop: this.shop_id,
                        date_used__date_gte: this.$moment(sale.period.date_used_from).format("YYYY-MM-DD"),
                        date_used__date_lte: this.$moment(sale.period.date_used_to).format("YYYY-MM-DD")
                    }
                }
            }
        },
        watch: {
            form: {
                handler() {
                    this.load_sales();
                },
                deep: true
            },
            date_month() {
                this.form.date_used_gte = this.$moment(this.date_month).format("YYYY-MM-DD");
                this.form.date_used_lte = this.$moment(this.date_month).endOf('month').format("YYYY-MM-DD");
            }
        }
    }
</script>

<style scoped>

</style>