<template>
  <section class="page">
    <div class="page__inner">
      <div class="container-width">
        <!--HEADER PAGE-->
        <div class="page__header">
          <h1 class="page__title">Тарифы</h1>
          <div class="btn-group">
            <div class="page__group-btn" >
              <router-link v-if="is_superuser()" title="Массовые смены тарифов" :to="{ name: 'tariff-mass-transfers-list'}">
                <span class="btn btn_grey">
                    <span class="btn__icon"><i class="fal fa-eye-evil"></i></span>
                    <span class="btn__title">Массовые смены тарифов</span>
                </span>
              </router-link>
              <div title="Добавление в архив">
                    <span class="btn btn_grey">
                        <span class="btn__icon"><i class="fal fa-archive"></i></span>
                        <span class="btn__title">Архив</span>
                    </span>
                </div>
              <span class="btn btn_orange" @click="popup_options()" v-if="is_superuser()">
                <span class="btn__icon"><i class="fal fa-sliders-v"></i></span>
                <span class="btn__title">Параметры</span>
              </span>
              <!--ADD TARIFF-->
                <app-link :perms="'catalog_tariffs_add_edit'"
                          :title="'Добавление тарифа'"
                          :to="{ name: 'tariffs-add' }">
                    <template slot-scope="{ access }">
                        <span class="btn" :class="[access ? 'btn_green' : 'btn_grey']">
                            <span class="btn__icon"><i class="fal fa-plus"></i></span>
                            <span class="btn__title">Добавить тариф</span>
                        </span>
                    </template>
                </app-link>
                <!--END ADD TARIFF-->
            </div>
          </div>
        </div>
        <!-- END HEADER PAGE -->

        <!-- BODY PAGE -->
        <div class="page__body">
          <!-- FILTER PAGE-->
          <FilterBlock @resetComponentFilters="resetComponentFilters">
            <filter-by-field v-model="department"
                             title="Отдел"
                             view="name"
                             :objects="departments">
            </filter-by-field>
            <filter-by-field v-model="group"
                             title="Група"
                             view="name"
                             :objects="groups">
            </filter-by-field>
            <filter-by-field v-model="connection_type"
                             view="label"
                             title="Тип подключения"
                             :objects="connection_types">
            </filter-by-field>
            <filter-by-field v-model="subscriber_type"
                             view="name_expand"
                             title="Тип пользователя"
                             :objects="subscriber_types">
            </filter-by-field>
          </FilterBlock>
          <!-- END FILTER PAGE-->
          <div class="table-box">
            <table class="table-box__table">
              <thead class="table-box__thead">
              <tr class="table-box__thead-tr">
                <th class="table-box__th" @click="set_ordering('system_name')">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-toolbox"></i></span>
                                        <span class="table-box__thead-title">Системное имя</span>
                                        <i class="fas" :class="[get_class_ordering_direction('system_name')]"></i>
                                    </span>
                </th>
                <th class="table-box__th" @click="set_ordering('name')">
                                    <span class="table-box__thead-item">
                                        <span class="table-box__thead-icon"><i class="fal fa-briefcase"></i></span>
                                        <span class="table-box__thead-title">Название</span>
                                        <i class="fas" :class="[get_class_ordering_direction('name')]"></i>
                                    </span>
                </th>
                <th class="table-box__th" @click="set_ordering('subscriber_type')">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-users-crown"></i></span>
                                        <span class="table-box__thead-title">Тип абонента</span>
                                        <i class="fas" :class="[get_class_ordering_direction('subscriber_type')]"></i>
                                    </span>
                </th>
                <th class="table-box__th" @click="set_ordering('department__name')">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-code-branch"></i></span>
                                        <span class="table-box__thead-title">Отдел</span>
                                        <i class="fas" :class="[get_class_ordering_direction('department__name')]"></i>
                                    </span>
                </th>
                <th class="table-box__th" @click="set_ordering('group')">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-folder"></i></span>
                                        <span class="table-box__thead-title">Группа</span>
                                        <i class="fas" :class="[get_class_ordering_direction('group')]"></i>
                                    </span>
                </th>
                <th class="table-box__th" @click="set_ordering('connection_type')">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-ethernet"></i></span>
                                        <span class="table-box__thead-title">Технология</span>
                                        <i class="fas" :class="[get_class_ordering_direction('connection_type')]"></i>
                                    </span>
                </th>
                <th class="table-box__th">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-calendar-alt"></i></span>
                                        <span class="table-box__thead-title">Тарификация</span>
                                    </span>
                </th>
                <!--<th class="table-box__th">-->
                <!--<span class="table-box__thead-item table-box__thead-item_center">-->
                <!--<span class="table-box__thead-icon"><i class="fal fa-wallet"></i></span>-->
                <!--<span class="table-box__thead-title">Стоимость</span>-->
                <!--</span>-->
                <!--</th>-->
                <th class="table-box__th">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i
                                            class="fal fa-tachometer-alt-fastest"></i></span>
                                        <span class="table-box__thead-title">Скорость (Мбит/с)</span>
                                    </span>
                </th>
                <!--<th class="table-box__th">-->
                <!--<span class="table-box__thead-item table-box__thead-item_center">-->
                <!--<span class="table-box__thead-icon"><i class="fal fa-rocket"></i></span>-->
                <!--<span class="table-box__thead-title">Ускорение (Мбит/с)</span>-->
                <!--</span>-->
                <!--</th>-->
                <!--<th class="table-box__th">-->
                <!--<span class="table-box__thead-item table-box__thead-item_center">-->
                <!--<span class="table-box__thead-icon"><i class="fal fa-map-marker-alt"></i></span>-->
                <!--<span class="table-box__thead-title">Населенные пункты</span>-->
                <!--</span>-->
                <!--</th>-->
                <th class="table-box__th">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-mouse-pointer"></i></span>
                                        <span class="table-box__thead-title">Действия</span>
                                    </span>
                </th>
              </tr>
              </thead>
              <tbody class="table-box__tbody">
              <tr class="table-box__tr table-data" v-for="(item, index) in tariffs">
                <!--Системное название тарифа-->
                <td class="table-box__td">
                                    <span class="table-data__text">
                                        {{ item.system_name }}
                                    </span>
                </td>
                <!--Название тарифа-->
                <td class="table-box__td">
                                    <span class="table-data__text">
                                        {{ item.name }}
                                    </span>
                </td>
                <!--Тип абонента-->
                <td class="table-box__td table-box__td_center">
                                    <span class="table-data__text">
                                        <template v-if="item.subscriber_type_expand">{{
                                            item.subscriber_type_expand.name_expand
                                          }}</template>
                                    </span>
                </td>
                <!--Отдел-->
                <td class="table-box__td table-box__td_center">
                                    <span class="table-data__text">
                                        <template v-if="item.department_expand">{{
                                            item.department_expand.name
                                          }}</template>
                                        <template v-if="item.is_common_tariff">Все отделы</template>
                                    </span>
                </td>
                <!--Группа-->
                <td class="table-box__td table-box__td_center">
                                    <span class="table-data__text">
                                        <template v-if="item.group_expand">{{ item.group_expand.name }}</template>
                                    </span>
                </td>
                <!--Тарификация-->
                <!--<td class="table-box__td table-box__td_center">-->
                <!--<span class="table-data__text">-->
                <!--<template v-if="item.charging_type_expand">{{item.charging_type_expand}}</template>-->
                <!--</span>-->
                <!--</td>-->
                <!--Стоимость-->
                <td class="table-box__td table-box__td_center">
                                     <span class="table-data__text">
                                         <template v-if="item.connection_type_expand">
                                             <span v-for="(connection_type, index) in item.connection_type_expand">
                                                 {{ connection_type.label }}<template
                                                 v-if="index + 1 !== item.connection_type_expand.length">, </template>
                                             </span>
                                         </template>
                                     </span>
                </td>
                <!--Технология-->
                <td class="table-box__td table-box__td_center">
                  <tariffs-charging-type :item="item"></tariffs-charging-type>
                </td>
                <!--Скорость-->
                <td class="table-box__td table-box__td_center">
                  <div class="table-data__speed">
                                         <span class="table-data__speed-item">
                                            <span class="table-data__speed-icon"><i
                                                class="fal fa-cloud-download"></i></span>
                                            <span class="table-data__speed-value">{{
                                                item.speed_tx | prettyMbit(1)
                                              }}</span>
                                         </span>
                    <span class="table-data__speed-divide"><i class="fal fa-arrows-h"></i></span>
                    <span class="table-data__speed-item">
                                             <span class="table-data__speed-icon"><i
                                                 class="fal fa-cloud-upload"></i> </span>
                                             <span class="table-data__speed-value">{{
                                                 item.speed_rx | prettyMbit(1)
                                               }}</span>
                                         </span>
                  </div>
                </td>
                <!--Ускорение-->
                <!--<td class="table-box__td table-box__td_center">-->
                <!--<div class="table-data__speed">-->
                <!--<span class="table-data__speed-item">-->
                <!--<span class="table-data__speed-icon"><i class="fal fa-cloud-download"></i></span>-->
                <!--<span class="table-data__speed-value">{{ item.burst_speed_tx | prettyMbit(1) }}</span>-->
                <!--</span>-->
                <!--<span class="table-data__speed-divide"><i class="fal fa-arrows-h"></i></span>-->
                <!--<span class="table-data__speed-item">-->
                <!--<span class="table-data__speed-icon"><i class="fal fa-cloud-upload"></i> </span>-->
                <!--<span class="table-data__speed-value">{{ item.burst_speed_rx | prettyMbit(1) }}</span>-->
                <!--</span>-->
                <!--</div>-->
                <!--</td>-->
                <!--Населенные пункты-->
                <!--<td class="table-box__td table-box__td_center">-->
                <!--<span class="table-data__link">-->
                <!--Населенные пункты-->
                <!--</span>-->
                <!--</td>-->
                <!--Действия-->
                <td class="table-box__td table-box__td_right">
                  <div class="btn-group">
                    <router-link v-tooltip="TooltipText='Просмотр'" v-has-perm="'catalog_tariffs_access'"
                                 :to="{ name: 'tariffs-view', params:{ id: item.id }}">
                                             <span class="btn-l btn-l_blue">
                                                 <span class="btn-l__icon"><i class="fal fa-eye"></i></span>
                                             </span>
                    </router-link>
                    <!--EDIT TARIFF-->
                    <app-link :perms="'catalog_tariffs_add_edit'"
                              :title="'Добавление тарифа'"
                              :to="{ name: 'tariffs-edit', params:{ id: item.id }}">
                        <template slot-scope="{ access }">
                            <span class="btn-l" :class="[access ? 'btn-l_orange' : 'btn-l_grey']" v-tooltip="TooltipText='Редактирование'">
                                <span class="btn-l__icon"><i class="fal fa-edit"></i></span>
                            </span>
                        </template>
                    </app-link>
                    <!--END EDIT TARIFF-->
                    <!--TRANSFER ACTION-->
                    <app-has-perm-action-btn :perms="'bulk_actions_change_tariff'"
                                              @action="popup_transfer(item.id)">
                         <template slot-scope="{ access }">
                             <span v-if="item.count_subscribers" class="btn-l" :class="access ? 'btn-l_indigo' : 'btn-l_grey'" v-tooltip="TooltipText='Трансфер'">
                                <span class="btn-l__icon"><i class="fal fa-exchange"></i></span>
                             </span>
                             <span v-else class="btn-l btn-l_grey" v-tooltip="TooltipText='Трансфер'">
                                 <span class="btn-l__icon"><i class="fal fa-exchange"></i></span>
                             </span>
                         </template>
                     </app-has-perm-action-btn>
                     <!-- END TRANSFER ACTION-->
                     <app-remove-btn :perms="'catalog_tariffs_delete'" ></app-remove-btn>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!--Pagination block-->
          <Pagination @loadItems="load_tarrifs"
                      :options="limitOptions"
                      :limit-items="limit"
                      :page-items="page"
                      :count-items="count"
                      :add-null-option="true"/>
          <!-- END Pagination block-->
        </div>
      </div>
    </div>
    <!--END BODY PAGE-->
    <!-- Modal Transfer window -->
    <!--<tariffs-transfer-popup :settings="transfer_popup" :crud="crud"></tariffs-transfer-popup>-->

    <!-- Modal Transfer window -->
    <tariffs-transfer-popup :settings="transfer_popup"
                            :tariff_mass_id="tariff_mass_id"
                            @update_tariffs="load_tarrifs"></tariffs-transfer-popup>

    <!-- Modal Options window -->
    <tariffs-options-popup :settings="options_popup" :crud="crud"></tariffs-options-popup>
  </section>
</template>

<script>
import {CrudMixin} from "../../../../mixins/admin/crud/crud_base";
import {CrudListMixin} from "../../../../mixins/admin/crud/crud_list";
import TariffsChargingType from './charging_type/index';
import {OrderMixin} from "../../../../mixins/admin/order";
import {RouteHelperMixin} from "../../../../mixins/admin/route_helper";

import TariffsTransferPopup from './transfer-tariffs.vue';
import TariffsOptionsPopup from './options.vue';
import FilterBlock from '../filter-block/filter-block';
import Filter from '../filter-block/sub_components/filter';
import {User} from "../../../../mixins/admin/user";
import {SearchMixin} from "../../../../mixins/admin/search";
import {API} from "../../../../api";
import {DepartmentMixin} from "../../../../mixins/admin/department";
import {SubscriberTypeMixin} from "../../../../mixins/admin/subscriber_type";
import {ConnectionTypeMixin} from "../../../../mixins/admin/connection_type";
import {GroupMixin} from "../../../../mixins/admin/group";
import Pagination from "../pagination/pagination";
import {PaginationMixin} from "../../../../mixins/admin/pagination";
import {FilterHelperMixin} from "../../../../mixins/admin/filter_helper";

export default {
  name: 'tariff-list',
  mixins: [User, CrudMixin, CrudListMixin, SearchMixin, OrderMixin, RouteHelperMixin, DepartmentMixin,
    SubscriberTypeMixin, ConnectionTypeMixin, GroupMixin, PaginationMixin, FilterHelperMixin],
  components: {
    FilterBlock,
    "filter-by-field": Filter,
    "tariffs-charging-type": TariffsChargingType,
    'tariffs-transfer-popup': TariffsTransferPopup,
    'tariffs-options-popup': TariffsOptionsPopup,
    Pagination,
  },
  data: function () {
    return {
      tariff_mass_id: "",
      crud: {
        api: '/tariffs/',
      },
      options_popup: {
        visible: false
      },
      transfer_popup: {
        visible: false
      },
      tariffs: [],
      // options for ordering items
      ordering: "department__name",
      ordering_direction: 'ASC',
      // data for filtering items
      department: undefined,
      departments: [],
      subscriber_type: undefined,
      subscriber_types: [],
      connection_type: undefined,
      connection_types: [],
      group: undefined,
      groups: [],
      // options for pagination block
      limitOptions: [15, 25, 50, 100],
      limit: 15,
      page: 1,
      count: 0,
    }
  },
  created() {
    this.set_data_from_route([
      {'name': 'ordering_direction', 'type': 'str'},
      {'name': 'ordering', 'type': 'str'}], this);

    // init pagination settings
    this.pagination_init(this.page, this.limit);

    this.$root.$on('search_hit_tariffs', (function (data) {
      this.fetchUser()
    }).bind(this));

    this.$root.$on('search_clear_tariffs', (function (data) {
      this.fetchUser()
    }).bind(this));

  },
  beforeDestroy: function () {
    this.destroy_search_listeners("tariffs");
  },
  mounted() {
    this.loadFilterOptions();
    this.fetchUser();
  },
  methods: {
    loadFilterOptions() {
      this.load_departments();
      this.load_subscriber_types();
      this.load_connection_type();
      this.load_groups();
    },
    popup_transfer(tariff_mass_id) {
        if (this.tariffs.find(el => el.id === tariff_mass_id).count_subscribers) {
            this.tariff_mass_id = tariff_mass_id;
            this.transfer_popup.visible = true;
            this.crud.errors = {};
        }
    },
    popup_options() {
      this.options_popup.visible = true;
      this.crud.errors = {};
    },
    load_tarrifs() {
      var self = this;

      var params = Object.assign({}, self.$route.query,
          {
            page: !self.$route.query.page ? self.page : self.$route.query.page,
            limit: !self.$route.query.limit ? self.limit : self.$route.query.limit,
            offset: !self.$route.query.offset ? self.get_offset(self.page) : self.$route.query.offset,
          });

      params = self.setOrderingToParams(params);

      self.$set(params, 'department', self.department)
      self.$set(params, 'group', self.group)
      self.$set(params, 'connection_type', self.connection_type)
      self.$set(params, 'subscriber_type', self.subscriber_type)

      self.$set(params, 'q', self.$route.query.q)

      API.get('/tariffs/', params)
          .then(function (response) {
            self.tariffs = response.data.results;
            self.count = response.data.count;

            // update route ordering params
            self.updateRouteQueryOrderingParams();

            // update department filter
            self.updateRouterQuery('department', self.departments, self.department)

            // update department filter
            self.updateRouterQuery('group', self.groups, self.group)

            // update connection_type filter
            self.updateRouterQuery('connection_type', self.connection_types, self.connection_type)

            // update subscriber_type filter
            self.updateRouterQuery('subscriber_type', self.subscriber_types, self.subscriber_type)
          })
          .catch(function (error) {
            API.default_catch(self, error)
          })
          .then(function () {
            API.default_always(self);
          })
    },
    fetchUser() {
      if (this.has_perm('catalog_tariffs_access')) {
        this.load_tarrifs();
      }
    },
    resetComponentFilters() {
      this.resetFilters();
      this.fetchUser();
    },
  },
  watch: {
    // watch multiple properties with single handler
    orderingAndOrderingName(value) {
      this.fetchUser();
    },
    department(value) {
      if (value && value.length > 0) {
        this.fetchUser();
      }
    },
    group(value) {
      if (value && value.length > 0) {
        this.fetchUser();
      }
    },
    connection_type(value) {
      if (value && value.length > 0) {
        this.fetchUser();
      }
    },
    subscriber_type(value) {
      if (value && value.length > 0) {
        this.fetchUser();
      }
    }
  }
}
</script>

<style scoped>

</style>