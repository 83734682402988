<template>
    <td class="table-subscribers__td table-subscribers__td_wrap" v-has-perm="'subscribers_access'">
        <template v-if="subscriber_connection.nas_expand">
            <span class="table-data__text">
                {{ subscriber_connection.nas_expand.name }}
            </span>
        </template>
    </td>
</template>

<script>
    export default {
        name: "td_nas",
        props: ["subscriber_connection"],
    }
</script>

<style scoped>

</style>