export var LinksMixin = {
    methods: {
        getUrlParams(search) {
            let hashes = search.slice(search.indexOf('?') + 1).split('&')
            return hashes.reduce((params, hash) => {
                let [key, val] = hash.split('=')
                return Object.assign(params, {[key]: decodeURIComponent(val)})
            }, {})
        },
        generate_link: function (link_object_type, link_params, extra_params) {
            // console.log(link_object_type, link_params, extra_params);
            let route_name = "", query = {};

            if (link_object_type === "subscriber-connections-transactions") {
                route_name = "reports-replenishment";
            } else if (link_object_type === "unicards-unicard") {
                route_name = "unicards-list-cards";
            } else if (link_object_type === "subscribers") {
                route_name = "subscribers-list";
            }

            // if(link_params){ query = this.getUrlParams(link_params) }
            // console.log(query);
            if(link_params){ query = Object.assign(query, this.getUrlParams(link_params)) }
            // console.log(query);
            if(extra_params){ query = Object.assign(query, extra_params) }
            // console.log(query);

            if (route_name) { return {name: route_name, query: query} }
        },
        current_url(){
            // console.log(this.$route);
            return this.$route.fullPath;
        }
    }
}