<template>
    <popup :settings="settings">
        <div slot="title">ТВ</div>
        <div slot="content">
            <div class="body-size">
                <div class="body-size__650">
                    <div class="view-modal">
<!--                        <div class="view-modal__item">-->
<!--                            <span class="view-modal__title">-->
<!--                                <span class="view-modal__icon"><i class="fal fa-calendar-check"></i></span>-->
<!--                                       Дата включения:-->
<!--                                </span>-->
<!--                                <span class="view-modal__value">-->
<!--                                09.10.2020-->
<!--                                </span>-->
<!--                        </div>-->
                        <div class="view-modal__item">
                            <span class="view-modal__title">
                                <span class="view-modal__icon"><i class="fal fa-briefcase"></i></span>
                                    Пакет:
                            </span>
                            <span class="view-modal__value">
                               {{ service_tv.service_name }}
                            </span>
                        </div>
                        <div class="view-modal__item">
                            <span class="view-modal__title">
                                <span class="view-modal__icon"><i class="fal fa-cog"></i></span>
                                       Характеристики:
                            </span>
                            <span class="view-modal__value view-modal__value_bold">
                                150 каналов
                            </span>
                        </div>
                        <div class="view-modal__item">
                            <span class="view-modal__title">
                                <span class="view-modal__icon"><i class="fal fa-phone-laptop"></i></span>
                                       Кол-во устройств:
                            </span>
                            <span class="view-modal__value view-modal__value_bold">
                                {{ service_tv.devices.length }}/{{ service_tv.count_devices }}
                            </span>
                        </div>
                        <div class="view-modal__item">
                            <span class="view-modal__title">
                                <span class="view-modal__icon"><i class="fal fa-calendar-alt"></i></span>
                                       Тарификация:
                            </span>
                            <span class="view-modal__value">
                                <span class="view-modal__price">
                                    <span class="view-modal__price-item">
                                        <span class="view-modal__price-icon">₴</span>
                                        <span class="view-modal__price-value">{{ service_tv.total_cost_month }}</span>
                                    </span>
                                    <span class="view-modal__price-divide"></span>
                                    <span class="view-modal__price-item">
                                        <span class="view-modal__price-icon">₴</span>
                                        <span class="view-modal__price-value">{{ service_tv.total_cost_day }}</span>
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div class="view-modal__item">
                            <span class="view-modal__title">
                                <span class="view-modal__icon"><i class="fal fa-phone-laptop"></i></span>
                                Договор партнера:
                            </span>
                            <span class="view-modal__value view-modal__value_bold">
                                {{ service_tv.extra_data.triniti_local_id }}
                            </span>
                        </div>
                        <div class="view-modal__item">
                            <span class="view-modal__title">
                                <span class="view-modal__icon"><i class="fal fa-phone-laptop"></i></span>
                                Договор Trinity:
                            </span>
                            <span class="view-modal__value view-modal__value_bold">
                                {{ service_tv.extra_data.triniti_contract_id }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="btn-group">
                <span class="btn btn_green" @click="popup_close">
                    <span class="btn__icon"><i class="fal fa-check"></i></span>
                    <span class="btn__title">Ознакомлен</span>
                </span>
            </div>
        </div>
    </popup>
</template>

<script>
    import Popup from '../../../../modal/popup.vue';
    import FormErrors from '../../../../form/errors.vue';
    import {CrudMixin} from "../../../../../../../mixins/admin/crud/crud_base";

    export default {
        name: "tv-view",
        props: ["settings", "service_tv"],
        components: {
            "popup": Popup,
            "form-error": FormErrors,
        },
        mixins: [
            CrudMixin,
        ],
    }
</script>

<style scoped>

</style>