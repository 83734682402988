<template>
    <popup :settings="settings">
        <div slot="title">Код авторизации</div>
        <div slot="content">
            <div class="body-size">
                <div class="body-size__480">
                    <form @submit.prevent="submit" class="form-modal">
                        <div class="form-modal__group">
                            <fieldset class="form-modal__item">
                                <input type="text" v-model="daily_password" class="modal-form__input" placeholder="Введите код">
                                <form-error v-bind:value="errors.daily_password"></form-error>
                            </fieldset>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="btn-group">
                <span class="btn btn_blue" @click="submit">
                    <span class="btn__icon"><i class="fal fa-sign-in"></i></span>
                    <span class="btn__title">
                        Войти
                    </span>
                </span>
            </div>
        </div>
    </popup>
</template>

<script>
    import Popup from '../modal/popup.vue';
    import {PopupMixin} from "../../../../mixins/admin/popup";
    import {API} from "../../../../api";
    import FormErrors from '../form/errors.vue';
    import {User} from "../../../../mixins/admin/user";

    export default {
        name: "auth-pin-popup",
        props: ["settings"],
        data: function () {
            return {
                daily_password: '',
                errors: {}
            }
        },
        mixins: [
            PopupMixin,
            User
        ],
        components: {
            "popup": Popup,
            "form-error": FormErrors,
        },
        methods: {
            submit(resp) {
                var self = this;
                // const token = self.$localStorage.get('google_token');
                const token = self.$session.get('google_token');

                this.login({access_token: token, daily_password: self.daily_password})
                    .then(function (response) {
                        // Set token
                        self.set_user_by_data(response.data.user);
                        self.set_token(response.data.token);
                        self.settings.visible = false;
                        self.daily_password = "";
                        self.$router.push({name: "dashboard-page"});
                    })
                    .catch(function (error) {
                        API.default_catch(self, error);

                    }).then(function () {
                });
            },
        }
    }
</script>

<style scoped>

</style>