<template>
    <popup :settings="settings">
        <div slot="title">Отмена запланированной смены тарифа</div>
        <div slot="content">
            <div class="body-size">
                <div class="body-size__480">
                    Вы уверены, что хотите удалить массовую запланировунную смену тарифа {{ this.$moment(item.transfer_date).format("YYYY-MM-DD") }}
                    c {{ tariff_from_obj.system_name }} на {{ tariff_to_obj.system_name }}?
                </div>
                <fieldset class="modal-form__item">
                    <p><i class="fal fa-comment-exclamation"></i> Комментарий для отмены массовой смены:</p>
                    <textarea name="desc" v-model="description" placeholder="Введите коментарий"></textarea>
                    <form-error v-bind:value="errors.description" ></form-error>
                </fieldset>
            </div>
        </div>
        <div slot="footer">
            <a href="#" @click="save" class="modal__btn">Отменить смену</a>
            <a href="#" @click="popup_close(); clear();">Я передумал</a>
        </div>
    </popup>
</template>

<script>
import {User} from "../../../../../mixins/admin/user";
import {CrudMixin} from "../../../../../mixins/admin/crud/crud_base";
import {ConstanceMixin} from "../../../../../mixins/admin/constance";
import Popup from "../../modal/popup";
import FormErrors from "../../form/errors";

export default {
    name: "cancel-tariff-mass-transfer-popup",
    props: ["settings", "errors", "item"],
    mixins: [
        User, CrudMixin, ConstanceMixin
    ],
    components: {
        'popup': Popup,
        'form-error': FormErrors,
    },
    data: function () {
        return {
            description: "",
            tariff_from_obj: {},
            tariff_to_obj: {},
        }
    },
    methods: {
        save() {
            this.$emit('cancel-mass-transfer', this.item.id, this.description);
        },
        clear() {
            this.description = "";
        },
    },
    watch: {
        item: {
            handler(value) {
                if (value) {
                    this.tariff_from_obj = value.tariff_from_expand;
                    this.tariff_to_obj = value.tariff_to_expand;
                }
            },
            deep: true
        }
    }

    }
</script>

<style scoped>

</style>