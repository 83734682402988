<template>
  <section class="page">
    <div class="page__inner">
      <div class="container-width">
        <!--HEADER PAGE-->
        <div class="page__header">
          <h1 class="page__title">Абоненты</h1>
          <div class="btn-group">
            <!--DELETE SUBSCRIBER LINK-->
            <app-link :perms="'subscribers_delete'" :to="{ name: 'login' }" :title="'Архив'">
                <template slot-scope="{ access }">
                    <span class="btn" :class="[access ? 'btn_blue' : 'btn_grey']">
                        <span class="btn__icon"><i class="fal fa-archive"></i></span>
                        <span class="btn__title">Архив</span>
                    </span>
                </template>
            </app-link>
            <!--ADD SUBSCRIBER LINK-->
            <app-link :perms="'subscribers_add_edit'" :to="{ name: 'subscribers-add' }" :title="'Добавить абонента'">
                <template slot-scope="{ access }">
                    <span class="btn" :class="[access ? 'btn_green' : 'btn_grey']">
                        <span class="btn__icon"><i class="fal fa-plus"></i></span>
                        <span class="btn__title">Добавить абонента</span>
                    </span>
                </template>
            </app-link>
          </div>
        </div>
        <!--END HEADER PAGE-->
        <!--BODY PAGE-->
        <div class="page__body">
          <!--BODY HEAD-->
          <div class="page__head">
            <div>
              <filter-by-field v-model="cls_list"
                               title="Все абоненты"
                               view="label"
                               :objects="[{label: 'Активные', key: 'CLS_AUTH'}, {label: 'Заблокированные', key: 'CLS_DENY'}]"
                               :is-choices-filter="true">
              </filter-by-field>
            </div>
            <div class="page__head-right">
              <div class="btn-group">
              <app-has-perm-action-btn :perms="'bulk_actions_send_notif_sms'" >
                     <template slot-scope="{ access }">

                         <span class="btn-l" :class="access ? 'btn-l_orange' : 'btn-l_grey'" v-tooltip="TooltipText='Отправить СМС'">
                            <span class="btn-l__icon"><i class="fal fa-sms"></i></span>
                        </span>

                     </template>
                </app-has-perm-action-btn>

                <app-has-perm-action-btn :perms="'bulk_actions_send_notif_email'">
                     <template slot-scope="{ access }">

                         <span class="btn-l" :class="access ? 'btn-l_blue' : 'btn-l_grey'" v-tooltip="TooltipText='Отправить E-mail'" >
                            <span class="btn-l__icon"><i class="fal fa-at"></i></span>
                        </span>

                     </template>
                </app-has-perm-action-btn>

                <app-has-perm-action-btn :perms="'subscriber_connection_blocking'"
                         @action="bulk_update_block_unblock('CLS_DENY')">
                     <template slot-scope="{ access }">

                         <span class="btn-l btn-l_red" :class="access ? 'btn-l_red' : 'btn-l_grey'" v-tooltip="TooltipText='Заблокировать'" >
                            <span class="btn-l__icon"><i class="fal fa-lock"></i></span>
                        </span>

                     </template>
                </app-has-perm-action-btn>

                <app-has-perm-action-btn :perms="'subscriber_connection_blocking'"
                         @action="bulk_update_block_unblock('CLS_AUTH')">
                     <template slot-scope="{ access }">
                         <span class="btn-l" :class="access ? 'btn-l_green' : 'btn-l_grey'" v-tooltip="TooltipText='Разблокировать'" >
                            <span class="btn-l__icon"><i class="fal fa-unlock"></i></span>
                        </span>
                     </template>
                </app-has-perm-action-btn>

                <app-remove-btn :perms="'subscribers_delete'" ></app-remove-btn>

              </div>
            </div>
          </div>
          <!--END BODY HEAD-->
          <!--PAGE-TABLE-->
          <div class="table-subscribers" v-bind:class="[data_loaded ? '' : 'loading']">
            <table class="table-subscribers__table">
              <thead class="table-subscribers__thead">
              <tr class="table-subscribers__thead-tr table-data">
                <th class="table-subscribers__th table-subscribers__th_checked">
                  <label class="table-data__checkbox">
                    <input type="checkbox" v-model="select_all" id="select_all">
                    <span class="table-data__checkmark"></span>
                  </label>
                </th>
                <th class="table-subscribers__th" v-has-perm="'subscribers_access'">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-address-book"></i></span>
                                        <span class="table-box__thead-title">Фио</span>
                                    </span>
                </th>
                <th class="table-subscribers__th" v-has-perm="'subscribers_access'">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-headset"></i></span>
                                        <span class="table-box__thead-title">Телефон</span>
                                    </span>
                </th>
                <th class="table-subscribers__th" v-has-perm="'subscribers_access'">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-user"></i></span>
                                        <span class="table-box__thead-title">Логин</span>
                                    </span>
                </th>
                <th class="table-subscribers__th"
                    v-has-perm="'subscribers_access'">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-piggy-bank"></i></span>
                                        <span class="table-box__thead-title">Баланс</span>
                                    </span>
                </th>
                <th class="table-subscribers__th" v-has-perm="'subscribers_access'">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-check-double"></i></span>
                                        <span class="table-box__thead-title">Статус</span>
                                    </span>
                </th>
                <th class="table-subscribers__th"
                    v-has-perm="'subscribers_access'">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-server"></i></span>
                                        <span class="table-box__thead-title">NAS</span>
                                    </span>
                </th>
                <th class="table-subscribers__th" v-has-perm="'subscribers_access'">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-ethernet"></i></span>
                                        <span class="table-box__thead-title">Технология</span>
                                    </span>
                </th>
                <th class="table-subscribers__th" v-has-perm="'subscribers_access'">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-map-marker-alt"></i></span>
                                        <span class="table-box__thead-title">Адрес включения</span>
                                    </span>
                </th>
                <th class="table-subscribers__th">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-mouse-pointer"></i></span>
                                        <span class="table-box__thead-title">Действия</span>
                                    </span>
                </th>
              </tr>
              </thead>
              <tbody class="table-subscribers__tbody">
              <template v-for="(subscriber, index) in subscribers">
                <tr class="table-subscribers__tr table-data">
                  <!--CheckBox -->
                  <template v-if="!Object.keys(subscriber.connections_expand).length">
                    <!--<td-record-check v-model="record_checked" :subscriber="subscriber" ></td-record-check>-->
                    <td class="table-subscribers__td table-subscribers__td_center table-subscribers__td_checked">
                      <label class="table-data__checkbox">
                        <input type="checkbox" v-model="record_checked"
                               v-bind:value="{subscriber_id: subscriber.id, subscriber_connection_id: undefined}">
                        <span class="table-data__checkmark"></span>
                      </label>
                    </td>

                  </template>
                  <template v-else>
                    <td class="table-subscribers__td table-subscribers__td_center table-subscribers__td_checked">
                      <label class="table-data__checkbox">
                        <input type="checkbox" v-model="record_checked"
                               v-bind:value="{subscriber_id: subscriber.id, subscriber_connection_id: subscriber.connections_expand[0].id}">
                        <span class="table-data__checkmark"></span>
                      </label>
                    </td>
                  </template>

                  <td class="table-subscribers__td table-subscribers__td_wrap" :rowspan="get_rowspan(subscriber)"
                      v-has-perm="'subscribers_access'">
                    <router-link
                        :to="{ name: 'subscribers-item', params:{id: subscriber.id }}"
                        class="table-data__username">
                      <subscriber-name :subscriber_input="subscriber" :is-list-page="true"></subscriber-name>
                    </router-link>
                  </td>
                  <td class="table-subscribers__td table-subscribers__td_center" :rowspan="get_rowspan(subscriber)"
                      v-has-perm="'subscribers_access'">
                      <span class="table-data__text table-data__text_bold">
                          {{ subscriber.phone }}
                      </span>
                  </td>

                  <template v-if="!Object.keys(subscriber.connections_expand).length">
                    <td class="table-subscribers__td table-subscribers__td_center">—</td>
                    <td class="table-subscribers__td table-subscribers__td_center">—</td>
                    <td class="table-subscribers__td table-subscribers__td_center">—</td>
                    <td class="table-subscribers__td table-subscribers__td_center">—</td>
                    <td class="table-subscribers__td table-subscribers__td_center">—</td>
                    <td class="table-subscribers__td table-subscribers__td_center">—</td>
                    <td-actions :subscriber="subscriber" :index="index"></td-actions>
                  </template>
                  <template v-else>

                    <td-username
                        :subscriber_connection="subscriber.connections_expand[0]"></td-username>
                    <td-balance
                        :subscriber_connection="subscriber.connections_expand[0]"></td-balance>
                    <td-clslist
                        :subscriber_connection="subscriber.connections_expand[0]"></td-clslist>
                    <td-nas :subscriber_connection="subscriber.connections_expand[0]"></td-nas>
                    <td-type-connection :subscriber_connection="subscriber.connections_expand[0]"></td-type-connection>
                    <td-settlement
                        :subscriber_connection="subscriber.connections_expand[0]"></td-settlement>
                    <td-actions
                        :subscriber_connection="subscriber.connections_expand[0]"
                        :index="index"
                        v-on:load_subscribers="load_subscribers()"></td-actions>

                  </template>
                </tr>
                <template
                    v-if="Object.keys(subscriber.connections_expand).length && Object.keys(subscriber.connections_expand).length > 1">
                  <template
                      v-for="(subscriber_connection, index_subscriber_connection) in subscriber.connections_expand">
                    <tr class="table-subscribers__tr table-data" v-if="index_subscriber_connection > 0">
                      <td class="table-subscribers__td table-subscribers__td_center table-subscribers__td_checked">
                        <label class="table-data__checkbox">
                          <input type="checkbox" v-model="record_checked"
                                 v-bind:value="{subscriber_id: subscriber.id, subscriber_connection_id: subscriber_connection.id}">
                          <span class="table-data__checkmark"></span>
                        </label>
                      </td>
                      <td-username
                          :subscriber_connection="subscriber_connection"></td-username>
                      <td-balance
                          :subscriber_connection="subscriber_connection"></td-balance>
                      <td-clslist
                          :subscriber_connection="subscriber_connection"></td-clslist>
                      <td-nas :subscriber_connection="subscriber_connection"></td-nas>
                      <td-type-connection :subscriber_connection="subscriber_connection"></td-type-connection>
                      <td-settlement
                          :subscriber_connection="subscriber_connection"></td-settlement>
                      <td-actions :subscriber_connection="subscriber_connection"
                                  :index="index"
                                  v-on:load_subscribers="load_subscribers()"></td-actions>
                    </tr>
                  </template>
                </template>
              </template>
              </tbody>
            </table>
          </div>
          <!--END PAGE-TABLE-->
          <!--END BODY FOO-->
          <Pagination @loadItems="load_subscribers"
                      :options="limitOptions"
                      :limit-items="limit"
                      :page-items="page"
                      :count-items="count"
                      :add-null-option="false"/>
          <!--END BODY FOO-->
        </div>
        <!--END BODY PAGE-->
      </div>
    </div>
  </section>
</template>

<script>
import {API} from "../../../../api";
import VueNumeric from 'vue-numeric';
import Pagination from "../pagination/pagination";
import {PaginationMixin} from "../../../../mixins/admin/pagination";
import {SubscriberMixin} from "../../../../mixins/admin/subscriber";
import {User} from "../../../../mixins/admin/user";
import {SubscriberConnectionMixin} from "../../../../mixins/admin/subscriber_connection";
import SubscriberName from "../subscriber-connections/sub_components/subscriber_name";
import tdUsername from "./sub_components/subscriber_list/td_username";
import tdBalance from "./sub_components/subscriber_list/td_balance";
import tdClslist from "./sub_components/subscriber_list/td_clslist";
import tdNas from "./sub_components/subscriber_list/td_nas";
import tdSettlement from "./sub_components/subscriber_list/td_settlement";
import tdActions from "./sub_components/subscriber_list/td_actions";
import tdRecordCheck from "./sub_components/subscriber_list/td_record_check";
import tdTypeConnection from "./sub_components/subscriber_list/td_type_connection";
import {SearchMixin} from "../../../../mixins/admin/search";
import {RouteHelperMixin} from "../../../../mixins/admin/route_helper";
import Filter from "../filter-block/sub_components/filter";

export default {
  name: 'subscriber-list',
  mixins: [User, SubscriberConnectionMixin, PaginationMixin, SubscriberMixin, SearchMixin, RouteHelperMixin],
  data: function () {
    return {
      subscriber_connection: {},
      subscribers: [],
      cls_list: "",
      record_checked: [],
      select_all: false,
      data_loaded: true,
      subscribers_add_popup: {
        visible: false
      },
      // options for pagination block
      limitOptions: [15, 25, 50, 100],
      limit: 15,
      page: 1,
      count: 0,
    }
  },
  components: {
    'filter-by-field': Filter,
    'subscriber-name': SubscriberName,
    'td-username': tdUsername,
    'td-balance': tdBalance,
    'td-clslist': tdClslist,
    'td-nas': tdNas,
    'td-settlement': tdSettlement,
    'td-actions': tdActions,
    'td-record-check': tdRecordCheck,
    'td-type-connection': tdTypeConnection,
    VueNumeric,
    Pagination,
  },
  created: function () {

    this.pagination_init(this.page, this.limit);

    this.$root.$on('search_hit_subscribers', (function (data) {
      this.page = 1;
      this.fetchUser();
    }).bind(this));

    this.$root.$on('search_clear_subscribers', (function (data) {
      this.page = 1;
      this.fetchUser()
    }).bind(this));
  },
  beforeDestroy: function () {
    this.destroy_search_listeners("subscribers");
  },
  mounted: function () {
    this.fetchUser()
  },
  methods: {
    set_record_check_value(subscriber, subscriber_connection) {
      var data = {subscriber_id: undefined, subscriber_connection_id: undefined};
      if (subscriber) {
        data.subscriber_id = subscriber.id;
      }
      if (subscriber_connection) {
        data.subscriber_connection_id = subscriber_connection.id;
      }
      return data
    },
    get_rowspan(subscriber) {
      var count = Object.keys(subscriber.connections_expand).length;
      if (count > 1) {
        return count;
      } else {
        return 1
      }
    },
    fetchUser() {
      if (this.has_perm({
        permissions: 'subscribers_access'
      })) {
        this.load_subscribers()
      }
    },
    load_subscribers: function () {
      var self = this;

      // init params object
      var params = Object.assign({}, self.$route.query,
          {
            page: !self.$route.query.page ? self.page : self.$route.query.page,
            limit: !self.$route.query.limit ? self.limit : self.$route.query.limit,
            offset: !self.$route.query.offset ? self.get_offset(self.page) : self.$route.query.offset,
          });

      self.$set(params, "cls_list", self.cls_list);

      self.loader_show();
      self.data_loaded = false;
      API.get('/subscribers/', params)
          .then(function (response) {
            self.$set(self, "subscribers", response.data.results);
            self.count = response.data.count;
          })
          .catch(function (error) {
            // API.default_catch(self, error)
          })
          .then(function () {
            API.default_always(self);
            self.data_loaded = true;
          })
    },
    add_subscribers() {
      this.subscribers_add_popup.visible = true;
    },
    bulk_update_block_unblock(value) {
      var self = this;
      var data = {
        cls_list: value
      };
      var records = [];
      for (var record of this.record_checked) {
        if (record.subscriber_connection_id) {
          records.push(record.subscriber_connection_id)
        }
      }

      if (records.length > 0) {
        this.bulk_edit_subscriber_connection(records, data)
            .then(function (response) {
              self.load_subscribers();
              self.record_checked = [];
              self.select_all = false;
            })
      } else {
        this.alert("Блокировка/Разблокировка", "Не выбраны точки включения")
      }
    },
    bulk_send_sms(value) {
      var self = this;
      var data = {
        cls_list: value
      };

      this.bulk_edit_subscriber_connection(this.record_checked, data)
          .then(function (response) {
            self.load_subscribers();
            self.record_checked = [];
            self.select_all = false;
          })
    }
  },
  watch: {
    select_all: function (val) {
      this.record_checked = [];
      if (val) {
        for (var subscriber of this.subscribers) {
          this.record_checked.push({subscriber_id: subscriber.id, subscriber_connection_id: undefined});

          if (Object.keys(subscriber.connections_expand).length) {
            for (var subscriber_connection of subscriber.connections_expand) {
              this.record_checked.push({
                subscriber_id: subscriber.id,
                subscriber_connection_id: subscriber_connection.id
              });
            }
          }
        }
      }
    },
    subscriber_connection: function (value) {
      var index = null;
      for (var i in this.subscriber_connections) {
        if (this.subscriber_connections[i].id === value.id) {
          index = i;
        }
      }
      if (index) {
        this.$set(this.subscriber_connections, index, value)
      }
    },
    cls_list(value) {
      // Update router
      this.$router.replace({
        query: Object.assign({}, this.$route.query, {cls_list: value}),
      });
      this.fetchUser();
    },
  }
}
</script>

<style scoped>

</style>
