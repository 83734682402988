<template>
    <section class="page">
        <div class="page__inner">
            <div class="container-width">
                <!--HEADER PAGE-->
                <div class="page__header">
                    <h1 class="page__title">NAS</h1>
                    <div class="btn-group">
                        <div class="page__group-btn">
                <span class="btn btn_grey">
                        <span class="btn__icon"><i class="fal fa-archive"></i></span>
                        <span class="btn__title">Архив</span>
                </span>
                            <app-link :perms="'catalog_nas_add_edit'" :to="{ name: 'nas-add' }">
                                <template slot-scope="{ access }">
                    <span class="btn" :class="[access ? 'btn_green' : 'btn_grey']">
                        <span class="btn__icon"><i class="fal fa-plus"></i></span>
                        <span class="btn__title">Добавить NAS</span>
                    </span>
                                </template>
                            </app-link>
                        </div>
                    </div>
                </div>
                <!--END HEADER PAGE-->
                <!--BODY PAGE-->
                <div class="page__body">
                    <!--FILTERING-->
                    <FilterBlock @resetComponentFilters="resetComponentFilters">
                        <filter-by-field v-model="department"
                                         title="Отдел"
                                         view="name"
                                         :objects="departments"
                                         :add-social-tariff="false"/>
                    </FilterBlock>
                    <!--END FILTERING-->
                    <div class="table-box">
                        <table class="table-box__table">
                            <thead class="table-box__thead">
                            <tr class="table-box__thead-tr">
                                <th class="table-box__th" @click="set_ordering('name')">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-server"></i></span>
                                        <span class="table-box__thead-title">Имя NAS</span>
                                        <i class="fas" :class="[get_class_ordering_direction('name')]"></i>
                                    </span>
                                </th>
                                <th class="table-box__th" @click="set_ordering('department__name')">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-code-branch"></i></span>
                                        <span class="table-box__thead-title">Отдел</span>
                                        <i class="fas" :class="[get_class_ordering_direction('department__name')]"></i>
                                    </span>
                                </th>
                                <th class="table-box__th" @click="set_ordering('ip')">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-database"></i></span>
                                        <span class="table-box__thead-title">NAS IP</span>
                                        <i class="fas" :class="[get_class_ordering_direction('ip')]"></i>
                                    </span>
                                </th>
                                <th class="table-box__th">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-trademark"></i></span>
                                        <span class="table-box__thead-title">Vendor</span>
                                    </span>
                                </th>
                                <th class="table-box__th">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-hdd"></i></span>
                                        <span class="table-box__thead-title">Model</span>
                                    </span>
                                </th>
                                <th class="table-box__th">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-map-marker-alt"></i></span>
                                        <span class="table-box__thead-title">Адрес нахождения</span>
                                    </span>
                                </th>
                                <th class="table-box__th">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-mouse-pointer"></i></span>
                                        <span class="table-box__thead-title">Действия</span>
                                    </span>
                                </th>
                            </tr>
                            </thead>
                            <tbody class="table-box__tbody">
                            <tr class="table-box__tr table-data" v-for="(item, index) in crud.data.list">
                                <!--Имя NAS-->
                                <td class="table-box__td">
                                    <span class="table-data__text">
                                        {{ item.name }}
                                    </span>
                                </td>
                                <!--Отдел-->
                                <td class="table-box__td table-box__td_center">
                                    <template v-if="item.department_expand">
                                        {{ item.department_expand.name }}
                                    </template>
                                    <template v-else>
                                        Не задано
                                    </template>
                                </td>
                                <!--NAS IP-->
                                <td class="table-box__td table-box__td_center">
                                    <span class="table-data__text">
                                        {{ item.ip }}
                                    </span>
                                </td>
                                <!--Vendor-->
                                <td class="table-box__td table-box__td_center">
                                    <span class="table-data__text">
                                        -
                                    </span>
                                </td>
                                <!--Model-->
                                <td class="table-box__td table-box__td_center">
                                    <span class="table-data__text">
                                        -
                                    </span>
                                </td>
                                <!--Адрес нахождения-->
                                <td class="table-box__td">
                                    <span class="table-data__text">
                                        {{ item.location_address }}
                                    </span>
                                </td>
                                <td class="table-box__td table-box__td_right">
                                    <div class="btn-group">
                                        <router-link v-tooltip="TooltipText='Просмотр'"
                                                     :to="{ name: 'nas-view', params:{ id: item.id }}">
                                             <span class="btn-l btn-l_blue">
                                                 <span class="btn-l__icon"><i class="fal fa-eye"></i></span>
                                             </span>
                                        </router-link>
                                        <!--EDIT NAS-->
                                        <app-link :perms="'catalog_nas_add_edit'"
                                                  :to="{ name: 'nas-edit', params:{ id: item.id }}">
                                            <template slot-scope="{ access }">
                            <span class="btn-l" :class="[access ? 'btn-l_orange' : 'btn-l_grey']"
                                  v-tooltip="TooltipText='Редактирование'">
                                <span class="btn-l__icon"><i class="fal fa-edit"></i></span>
                            </span>
                                            </template>
                                        </app-link>
                                        <!--END EDIT NAS-->
                                        <!--NAS MAS TRANSFER-->
                                        <app-has-perm-action-btn :perms="'bulk_actions_change_nas'"
                                                                 @action="popup_transfer(item.id)">
                                            <template slot-scope="{ access }">
                             <span class="btn-l" :class="access ? 'btn-l_indigo' : 'btn-l_grey'"
                                   v-tooltip="TooltipText='Массовая смена <br> NAS'">
                                <span class="btn-l__icon"><i class="fal fa-exchange"></i></span>
                            </span>
                                            </template>
                                        </app-has-perm-action-btn>
                                        <!--END NAS MAS TRANSFER -->
                                        <app-remove-btn :perms="'catalog_nas_delete'"
                                                        @action="popup_delete"></app-remove-btn>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- END CONTENT PAGE -->
                    <!--PAGINATION BLOCK-->
                    <Pagination @loadItems="fetchUser"
                                :options="limitOptions"
                                :limit-items="limit"
                                :page-items="page"
                                :count-items="crud.data.list_count"
                                :add-null-option="true"/>
                    <!--END PAGINATION BLOCK-->
                </div>
            </div>
        </div>
        <nases-transfer-popup :settings="transfer_popup" :nas_mass_id="nas_mass_id"></nases-transfer-popup>
        <!-- Modal Delete window -->
        <nas-delete-popup :settings="delete_popup"></nas-delete-popup>
    </section>
</template>

<script>
    import {CrudMixin} from "../../../../mixins/admin/crud/crud_base";
    import {CrudListMixin} from "../../../../mixins/admin/crud/crud_list";

    import NasDeletePopup from './delete.vue'
    import {User} from "../../../../mixins/admin/user";
    import {SearchMixin} from "../../../../mixins/admin/search";
    import NasesTransferPopup from "./transfer-nases";
    import Pagination from "../pagination/pagination";
    import {PaginationMixin} from "../../../../mixins/admin/pagination";
    import FilterBlock from "../filter-block/filter-block";
    import Filter from "../filter-block/sub_components/filter";
    import {DepartmentMixin} from "../../../../mixins/admin/department";
    import {RouteHelperMixin} from "../../../../mixins/admin/route_helper";
    import {FilterHelperMixin} from "../../../../mixins/admin/filter_helper";
    import {OrderMixin} from "../../../../mixins/admin/order";

    export default {
        name: "nas-list",
        components: {
            FilterBlock,
            "filter-by-field": Filter,
            "nas-delete-popup": NasDeletePopup,
            'nases-transfer-popup': NasesTransferPopup,
            Pagination,
        },
        mixins: [User, CrudMixin, CrudListMixin, SearchMixin, PaginationMixin, DepartmentMixin, RouteHelperMixin,
            FilterHelperMixin, OrderMixin],
        data: function () {
            return {
                crud: {
                    api: '/nas/',
                },
                transfer_popup: {
                    visible: false
                },
                nas_mass_id: "",
                // options for ordering items
                ordering: "name",
                ordering_direction: 'ASC',
                // options for pagination block
                limitOptions: [15, 25, 50, 100],
                limit: 15,
                page: 1,
                // filter options for department filter
                department: undefined,
                departments: [],
            }
        },
        methods: {
            fetchUser() {
                var self = this;
                if (this.has_perm('catalog_nas_access')) {
                    var params = Object.assign({}, self.$route.query,
                        {
                            page: !self.$route.query.page ? self.page : self.$route.query.page,
                            limit: !self.$route.query.limit ? self.limit : self.$route.query.limit,
                            offset: !self.$route.query.offset ? self.get_offset(self.page) : self.$route.query.offset,
                        });
                    // set ordering params to load ordered items
                    params = self.setOrderingToParams(params);

                    self.$set(params, 'department', self.department)

                    this.crud_get_list(params);
                    // update route ordering params
                    self.updateRouteQueryOrderingParams();
                    // update department filter
                    self.updateRouterQuery('department', self.departments, self.department);
                }
            },
            popup_transfer(nas_mass_id) {
                this.nas_mass_id = nas_mass_id;
                this.transfer_popup.visible = true;
                this.crud.errors = {};
            },
            // here we have to load data for filtering
            loadFilterOptions() {
                this.load_departments();
            },
            resetComponentFilters() {
                this.resetFilters();
                this.fetchUser();
            }
        },
        created() {
            this.pagination_init(this.page, this.limit);

            this.set_data_from_route([
                {'name': 'ordering_direction', 'type': 'str'},
                {'name': 'ordering', 'type': 'str'}], this);

            this.$root.$on('search_hit_nas', (function (data) {
                this.fetchUser();
            }).bind(this));

            this.$root.$on('search_clear_nas', (function (data) {
                this.fetchUser();
            }).bind(this));
        },
        beforeDestroy: function () {
            this.destroy_search_listeners("nas");
        },
        mounted() {
            this.fetchUser()
            // load items for filter components
            this.loadFilterOptions();
        },
        watch: {
            // watcher for filter department
            department(value) {
                if (value && value.length > 0) {
                    this.fetchUser();
                }
            },
            // watch multiple properties with single handler
            orderingAndOrderingName(value) {
                this.fetchUser();
            },
        }
    }
</script>

<style scoped>

</style>