<template>
  <section class="page">
    <div class="page__inner">
      <div class="container-width">
        <!--HEADER PAGE-->
        <div class="page__header">
          <h1 class="page__title">Массовые смены тарифов</h1>
        </div>
        <!-- END HEADER PAGE -->

        <!-- BODY PAGE -->
        <div class="page__body">
          <div class="table-box">
            <table class="table-box__table">
              <thead class="table-box__thead">
              <tr class="table-box__thead-tr">
                <table-th>
                    <span slot="title-text">С тарифа:</span>
                </table-th>
                <table-th>
                    <span slot="title-text">На тариф:</span>
                </table-th>
                <table-th>
                    <span slot="title-text">Статус:</span>
                </table-th>
                <table-th>
                    <span slot="title-text">Тип перевода:</span>
                </table-th>
                <table-th>
                    <span slot="title-text">Комментарий смены статуса:</span>
                </table-th>
                <table-th>
                    <span slot="title-text">Действия:</span>
                </table-th>
              </tr>
              </thead>
              <tbody class="table-box__tbody">
              <tr class="table-box__tr table-data" v-for="(item, index) in tariffMassTransfers">
                <!--Системное название тарифа c -->
                <table-td>
                    <span slot="data-text">{{ item.tariff_from_expand.system_name }}</span>
                </table-td>
                <!--Системное название тарифа на -->
                <table-td>
                    <span slot="data-text">{{ item.tariff_to_expand.system_name }}</span>
                </table-td>
                <!--Статус-->
                <table-td>
                    <span slot="data-text">{{ getStatusLabel(item.status) }}</span>
                </table-td>
                <!--Тип массовой смены-->
                <table-td>
                    <span slot="data-text">{{ getTransferType(item) }}</span>
                </table-td>
                <table-td>
                    <span slot="data-text">{{ getStatusComment(item) }}</span>
                </table-td>
                <!--Действия-->
                <td class="table-box__td table-box__td_right">
                  <div class="btn-group">
                    <!--TRANSFER ACTION-->
                    <span v-if="item.status === 'scheduled'" class="btn-l btn-l_indigo" @click="popup_cancel_mass_transfer(item)">
                         <span class="btn-l__icon"><i class="fal fa-ban"></i></span>
                    </span>
                    <span v-else class="btn-l btn-l_grey" >
                         <span class="btn-l__icon"><i class="fal fa-ban"></i></span>
                    </span>
                    <!-- END TRANSFER ACTION-->
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!--Pagination block-->
          <Pagination @loadItems="loadTariffMassTransfers"
                      :options="limitOptions"
                      :limit-items="limit"
                      :page-items="page"
                      :count-items="count"
                      :add-null-option="true"/>
          <!-- END Pagination block-->
        </div>
      </div>
    </div>
    <!--END BODY PAGE-->

    <!-- Modal Cancel Mass Transfer -->
    <cancel-mass-transfer-popup :settings="cancel_mass_transfer_popup" :errors="errors"
                                @cancel-mass-transfer="cancelTariffMassTransfer" :item="cancel_mass_transfer_item">
    </cancel-mass-transfer-popup>

  </section>
</template>

<script>
import {CrudMixin} from "../../../../mixins/admin/crud/crud_base";
import {CrudListMixin} from "../../../../mixins/admin/crud/crud_list";
import {User} from "../../../../mixins/admin/user";
import {API} from "../../../../api";
import {PaginationMixin} from "../../../../mixins/admin/pagination";
import {RouteHelperMixin} from "../../../../mixins/admin/route_helper";

import CancelMassTariffTransferPopup from './popup/cancel-tariff-mass-transfer-popup';

import Pagination from "../pagination/pagination";
import TableTh from "./table/table-th";
import TableTd from "./table/table-td";


export default {
  name: 'tariff-mass-transfers-list',
  mixins: [User, CrudMixin, CrudListMixin, RouteHelperMixin, PaginationMixin],
  components: {
    TableTd,
    TableTh,
    'cancel-mass-transfer-popup': CancelMassTariffTransferPopup,
    Pagination,
  },
  data: function () {
    return {
      cancel_mass_transfer_item: {},
      crud: {
        api: '/tariffs-mass-transfer/',
      },
      errors: [],
      cancel_mass_transfer_popup: {
        visible: false
      },
      tariffMassTransfers: [],
      tariffTransferStatuses: [],
      // options for pagination block
      limitOptions: [15, 25, 50, 100],
      limit: 15,
      page: 1,
      count: 0,
    }
  },
  created() {
    // init pagination settings
    this.pagination_init(this.page, this.limit);
  },
  mounted() {
    this.getTariffTransferStatuses();
    this.loadTariffMassTransfers();
  },
  methods: {
    popup_cancel_mass_transfer(cancel_mass_transfer_item) {
      this.cancel_mass_transfer_item = cancel_mass_transfer_item;
      this.cancel_mass_transfer_popup.visible = true;
      this.crud.errors = {};
    },
    getStatusLabel(status) {
        const status_obj_index = this.tariffTransferStatuses.findIndex(el => el.key === status);
        return this.tariffTransferStatuses[status_obj_index].label;
    },
    getStatusComment(item) {
        return item.status_comment ? item.status_comment : "---";
    },
    getTransferType(item) {
        if (item.is_instant_transfer) {
            return "Мгновенный перевод"
        } else {
            return "Запланированный перевод на дату " + '"' + this.$moment(item.transfer_date).format("YYYY-MM-DD") + '"';
        }
    },
    loadTariffMassTransfers() {
      var self = this;

      var params = Object.assign({}, self.$route.query,
          {
            page: !self.$route.query.page ? self.page : self.$route.query.page,
            limit: !self.$route.query.limit ? self.limit : self.$route.query.limit,
            offset: !self.$route.query.offset ? self.get_offset(self.page) : self.$route.query.offset,
          });

      API.get('/tariffs-mass-transfer/', params)
          .then(function (response) {
            self.tariffMassTransfers = response.data.results;
          })
          .catch(function (error) {
            API.default_catch(self, error)
          })
          .then(function () {
            API.default_always(self);
          })
    },
    getTariffTransferStatuses() {
      var self = this;

      API.get('/tariffs-transfer-statuses/')
          .then(function (response) {
            self.tariffTransferStatuses = response.data.results;
          })
          .catch(function (error) {
            API.default_catch(self, error)
          })
          .then(function () {
            API.default_always(self);
          })
    },
    cancelTariffMassTransfer(itemId, description) {
      var self = this;

      var params = { 'description': description };

      API.post('/tariffs-mass-transfer/' + itemId + '/cancel-scheduled-tariff-mass-transfer/', params)
          .then(function (response) {
              self.cancel_mass_transfer_popup.visible = false;
              self.loadTariffMassTransfers();
          })
          .catch(function (error) {
            API.default_catch(self, error)
          })
          .then(function () {
            API.default_always(self);
          })
    },
    fetchUser() {
      this.loadTariffMassTransfers();
    },
  },
}
</script>

<style scoped>

</style>