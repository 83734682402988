<template>
    <span>
        <template v-if="has_perm(perms)">
            <span @click="$emit('action')">
                <slot :access="true" />
            </span>
        </template>
        <template v-else>
            <!-- TODO: Stas add popup -->
            <span @click="permissions_error_show">
                <slot :access="false" />
            </span>
        </template>
    </span>
</template>

<script>
    export default {
        name: "app-has-perm-action-link",
        props: ["perms", "title"]
    }
</script>
