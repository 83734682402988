<template>

    <div class="add-data__item" v-if="intervals">
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                <label><i class="fal fa-server"></i> Доступные карты:: <span
                        class="control"><i class="fas fa-star-of-life"></i></span></label>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                <label v-for="(interval, index) in intervals">
                {{ pad(interval.start) }} - {{ pad(interval.end) }} ({{ interval.count }} шт.)
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    import {UnicardsMixin} from "../../../../../../mixins/admin/unicards";

    export default {
        name: "available-intervals",
        props: ['intervals'],
        mixins: [
            UnicardsMixin
        ],
    }
</script>

<style scoped>

</style>