<template>
  <div class="dashboard-main">
    <DashboardCountItem v-for="blockItem in blockItems" :block-item="blockItem" />
  </div>
</template>

<script>
import DashboardCountItem from "./dashboard-count-item";

export default {
  name: "dashboard-count",
  components: {
    DashboardCountItem,
  },
  data() {
    return {
      blockItems: [
        {
          title: 'Абоненты онлайн',
          iconClass: 'fal fa-users',
          options: null,
          method: 'getTotalSubscriberConnectionsOnline',
        },
        {
          title: 'Новые абоненты подключенные вчера',
          iconClass: 'fal fa-users-medical',
          options: null,
          method: 'getTotalCountCreatedSubscriberConnectionsYesterday',
        },
        {
          title: 'Абоненты с услугой ТВ подключенные вчера',
          iconClass: 'fal fa-tv-retro',
          options: null,
          method: 'getTotalActivateIPTVTrinitiSubscriberConnection',
        },
        {
          title: 'Абоненты с услугой IP подключенные вчера',
          iconClass: 'fal fa-network-wired',
          options: null,
          method: 'getTotalActivateIPAddressSubscriberConnection',
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>