<template>
    <section class="page">
        <div class="page__inner">
            <div class="container-width">
                <div class="row">
                    <!--HEADER PAGE-->
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="page-header">
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                                    <h1 class="page-header__title">MAC ADDRESS</h1>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                    <div class="page-header__btn-group"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--END HEADER PAGE-->
                    <!--PAGE BODY-->
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="page-body">
                            <!--PAGE-BODY-HEAD-->
                            <div class="page-body__header">
                                <div class="row">
                                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                        <div class="selected">
                                            <select required>
                                                <option value="">Все MAC</option>
                                                <option value="">Запрещенный</option>
                                                <option value="">Привязан</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                        <div class="page-body__btn-group">
                                            <div class="btn-group">
                                                <app-has-perm-action-btn :perms="'catalog_mac_address_add_edit'"
                                                                          @action="">
                                                    <template slot-scope="{ access }">
                                                        <span class="btn-l" :class="access ? 'orange' : 'grey'">
                                                            <i class="fal fa-edit"></i>
                                                        </span>
                                                    </template>
                                                </app-has-perm-action-btn>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--END PAGE-BODY-HEAD-->
                            <!--PAGE-TABLE-->
                            <div class="table-container">
                                <table>
                                    <thead>
                                    <tr>
                                        <th><i class="fal fa-sort-numeric-down"></i></th>
                                        <th><i class="fal fa-server"></i>MAC ADDRESS</th>
                                        <th><i class="fal fa-project-diagram"></i>Правило</th>
                                        <th><i class="fal fa-user"></i>Логин</th>
                                        <th><i class="fal fa-comment-exclamation"></i>Примечание</th>
                                        <th><i class="fal fa-cogs"></i> Действия</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>55:25:15:AD:41:B1</td>
                                        <td>Авторизация запрещена</td>
                                        <td>---</td>
                                        <td>Использует чужие логины для подключения к сети</td>
                                        <td>
                                            <div class="btn-group">
                                                <!--EDIT MAC ADDRESS-->
                                                <app-has-perm-action-btn :perms="'catalog_mac_address_add_edit'"
                                                                         @action="">
                                                    <template slot-scope="{ access }">
                                                        <span class="btn-l" :class="access ? 'orange' : 'grey'">
                                                            <span class="btn-l__icon"><i class="fal fa-edit"></i></span>
                                                        </span>
                                                    </template>
                                                </app-has-perm-action-btn>
                                                <!--END EDIT MAC ADDRESS-->
                                                <!--DELETE MAC ADDRESS-->
                                                <app-remove-btn :perms="'catalog_mac_address_delete'" @action=""></app-remove-btn>
                                                <!--END DELETE MAC ADDRESS-->
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>55:25:15:AD:41:B1</td>
                                        <td>Привязан к точке включения</td>
                                        <td>10101002</td>
                                        <td>Использует чужие логины для подключения к сети</td>
                                        <td>
                                            <div class="btn-group">
                                                <span>
                                                    <span class="btn-l blue"><i class="fal fa-edit"></i></span>
                                                </span>
                                                <span>
                                                    <span class="btn-l red"><i class="fal fa-trash-alt"></i></span>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!--END PAGE-TABLE-->
                            <!--PAGE-FOOTER-->
                            <div class="page-body__footer">
                                <div class="row">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div class="pagination">
                                            <a><i class="fal fa-chevron-left"></i></a>
                                            <input type="text" value="1">
                                            <a><i class="fal fa-chevron-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--END PAGE-FOOTER-->
                        </div>
                    </div>
                    <!--END PAGE BODY-->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "list"
    }
</script>

<style scoped>

</style>