<template>
    <section class="page">
        <div class="page__inner">
            <div class="container-width">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="page-header">
                            <div class="row">
                                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <h1 class="page-header__title"> Добавление магазина</h1>
                                </div>
                                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <DropDown></DropDown>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- CONTENT PAGE -->
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="page-body">
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <form action="" class="add-data">
                                        <div class="row">
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div class="add-data__item">
                                                    <div class="row">
                                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                                            <label><i class="fal fa-server"></i> Название: <span
                                                                    class="control"><i class="fas fa-star-of-life"></i></span></label>
                                                        </div>
                                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-9">
                                                            <input type="text" v-model="shop.name" placeholder="Название магазина">
                                                        </div>
                                                    </div>
                                                    <form-error v-bind:value="errors.name"></form-error>
                                                </div>
                                            </div>
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div class="add-data__item">
                                                    <div class="row">
                                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                                            <label><i class="fal fa-server"></i> Агент: <span
                                                                    class="control"><i class="fas fa-star-of-life"></i></span></label>
                                                        </div>
                                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-9">
                                                            <div class="selected">
                                                                <select v-model="shop.agent" required>
                                                                    <option value="" hidden>Выберите агента</option>
                                                                    <option v-for="agent in agents" v-bind:value="agent.id" >{{ agent.fio }}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <form-error v-bind:value="errors.agent"></form-error>
                                                </div>
                                            </div>
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div class="add-data__header">
                                                    <h3>Адрес магазина</h3>
                                                </div>
                                            </div>
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                                <div class="add-data__item">
                                                    <div class="row">
                                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label><i class="fal fa-server"></i> Область: <span
                                                                    class="control"><i class="fas fa-star-of-life"></i></span></label>
                                                        </div>
                                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                            <input type="text" v-model="shop.region" placeholder="Область">
                                                        </div>
                                                    </div>
                                                    <form-error v-bind:value="errors.region"></form-error>
                                                </div>
                                            </div>
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                                <div class="add-data__item">
                                                    <div class="row">
                                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label><i class="fal fa-server"></i> Район: <span
                                                                    class="control"><i class="fas fa-star-of-life"></i></span></label>
                                                        </div>
                                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                            <input type="text" v-model="shop.district" placeholder="Район">
                                                        </div>
                                                    </div>
                                                    <form-error v-bind:value="errors.district"></form-error>
                                                </div>
                                            </div>
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                                <div class="add-data__item">
                                                    <div class="row">
                                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label><i class="fal fa-server"></i> Населенный пункт: <span
                                                                    class="control"><i class="fas fa-star-of-life"></i></span>
                                                            </label>
                                                        </div>
                                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                            <input type="text" v-model="shop.settlement" placeholder="Населенный пункт">
                                                        </div>
                                                    </div>
                                                    <form-error v-bind:value="errors.settlement"></form-error>
                                                </div>
                                            </div>
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                                <div class="add-data__item">
                                                    <div class="row">
                                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label><i class="fal fa-server"></i> Улица: <span
                                                                    class="control"><i class="fas fa-star-of-life"></i></span></label>
                                                        </div>
                                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                            <input type="text" v-model="shop.street" placeholder="Улица">
                                                        </div>
                                                    </div>
                                                    <form-error v-bind:value="errors.street"></form-error>
                                                </div>
                                            </div>
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                                <div class="add-data__item">
                                                    <div class="row">
                                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label><i class="fal fa-server"></i> Дом: <span
                                                                    class="control"><i class="fas fa-star-of-life"></i></span></label>
                                                        </div>
                                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                            <input type="text" v-model="shop.house_number" placeholder="№ дома">
                                                        </div>
                                                    </div>
                                                    <form-error v-bind:value="errors.house_number"></form-error>
                                                </div>
                                            </div>
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div class="add-data__header">
                                                    <h3>Контакты магазина</h3>
                                                </div>
                                            </div>
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                                <div class="add-data__item">
                                                    <div class="row">
                                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label><i class="fal fa-server"></i> Имя контакта: <span
                                                                    class="control"><i class="fas fa-star-of-life"></i></span></label>
                                                        </div>
                                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                            <input type="text" v-model="shop.contact_name" placeholder="Имя контакта">
                                                        </div>
                                                    </div>
                                                    <form-error v-bind:value="errors.contact_name"></form-error>
                                                </div>
                                            </div>
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                                <div class="add-data__item">
                                                    <div class="row">
                                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label><i class="fal fa-server"></i> Телефон контакта: <span
                                                                    class="control"><i class="fas fa-star-of-life"></i></span>
                                                            </label>
                                                        </div>
                                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                            <input type="phone" v-model="shop.contact_phone" placeholder="№ телефона">
                                                        </div>
                                                    </div>
                                                    <form-error v-bind:value="errors.contact_phone"></form-error>
                                                </div>
                                            </div>
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div class="add-data__header">
                                                    <h3>Заметки</h3>
                                                </div>
                                            </div>
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div class="add-data__item">
                                                    <div class="row">
                                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                                            <label><i class="fal fa-server"></i> Примечания: </label>
                                                        </div>
                                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-9">
                                                            <input type="text" v-model="shop.note" placeholder="Примечания">
                                                        </div>
                                                    </div>
                                                    <form-error v-bind:value="errors.note"></form-error>
                                                </div>
                                            </div>
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div class="add-data__footer">
                                                    <router-link title="Магазины" :to="{ name: 'unicards-shops'}">
                                                        <span class="btn grey">Отмена</span>
                                                    </router-link>
                                                    <span @click="save()" class="btn blue"><i class="fal fa-plus"></i> Сохранить</span>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import {User} from "../../../../../mixins/admin/user";
    import {ShopsMixin} from "../../../../../mixins/admin/shops";
    import {PermissionMixin} from "../../../../../mixins/admin/permission";
    import FormErrors from '../../form/errors.vue';
    import {API} from "../../../../../api";
    import DropDown from "../dropdown/dropdown";

    export default {
        name: "shop-add-edit",
        mixins: [User, ShopsMixin, PermissionMixin],
        data(){
            return {
                agents: [],
                shop: {
                  "id": '',
                  "region": "",
                  "district": "",
                  "street": "",
                  "contact_phone": "",
                  "house_number": "",
                  "name": "",
                  "settlement": "",
                  "contact_name": "",
                  "agent": "",
                  "note": ""
                },
                errors: {},
            }
        },
        components: {
            "form-error": FormErrors,
            DropDown,
        },
        mounted(){
            this.load();
        },
        methods: {
            fetchUser() {
                this.load()
            },

            load(){
                this.get_agents();

                if (this.$route.params.id) {
                    this.shop.id = this.$route.params.id;
                    this.getShop(this.shop.id);
                }
            },
            getShop(id){
                var self = this;
                API.get('/shops/'+id+'/')
                    .then(function (response) {
                        self.shop = Object.assign({}, self.shop, response.data);
                    })
            },
            save() {
                var self = this;
                if(this.shop.id){
                    API.patch('/shops/'+this.shop.id+'/', this.shop)
                        .then(function (response) {
                            self.$router.push({name: "unicards-shops"});
                        })
                        .catch(function (error) {
                            API.default_catch(self, error)
                        })
                        .then(function () {
                            API.default_always(self);
                        })
                } else {
                    API.post('/shops/', this.shop)
                        .then(function (response) {
                            self.$router.push({name: "unicards-shops"});
                        })
                        .catch(function (error) {
                            API.default_catch(self, error)
                        })
                        .then(function () {
                            API.default_always(self);
                        })
                }
            },
            get_agents(){
                var self = this;
                API.get('/employee/with-unicard-access/')
                    .then(function (response) {
                        self.agents = response.data.results;
                    })
            }
        }
    }
</script>

<style scoped>

</style>