<template>
    <popup :settings="settings">
        <span class="modal__close" @click="popup_close(); clear();"></span>
        <div slot="title">Дополнительные услуги</div>
        <div slot="content">
            <div class="body-size">
                <div class="body-size__480">
                    <form class="form-modal">
                        <div class="form-modal__group">
                            <fieldset class="form-modal__item">
                                <span class="form-modal__title">
                                <span class="form-modal__icon"><i class="fal fa-toolbox"></i></span>
                                    Доп. услуга
                                </span>
                                <div class="form-modal__select">
                                    <select v-model="service_object_type" required>
                                        <option value="">Выберите услугу</option>
                                        <option v-for="service in services" :value="service.object_type">
                                            {{ service.object_name }}
                                        </option>
                                    </select>
                                </div>
                                <form-error v-bind:value="errors.subscriber_connection"></form-error>
                            </fieldset>
                        </div>
                        <!--Services Components -->
                        <service-ip-address v-if="service_object_type === 'service_ip_address'"
                                            :subscriber_connection="subscriber_connection"
                                            :service="service"
                                            :service_errors="errors"
                                            @set_form_data="set_form_data">
                        </service-ip-address>
                        <service-iptv-triniti v-if="service_object_type === 'service_iptv_triniti'"
                                              :subscriber_connection="subscriber_connection"
                                              :service="service"
                                              :service_errors="errors"
                                              @set_form_data="set_form_data">
                        </service-iptv-triniti>
                    </form>
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="btn-group">
                <span class="btn btn_grey" @click="popup_close(); clear();">
                    <span class="btn__icon"><i class="fal fa-times-circle"></i></span>
                    <span class="btn__title">Отмена</span>
                </span>
                <span class="btn btn_blue" @click="add_service">
                    <span class="btn__icon"><i class="fal fa-check"></i></span>
                    <span class="btn__title">Добавить</span>
                </span>
            </div>
        </div>
    </popup>
</template>

<script>
    import Popup from '../../modal/popup.vue';
    import {CrudMixin} from "../../../../../mixins/admin/crud/crud_base";
    import {API} from "../../../../../api";
    import FormErrors from '../../form/errors.vue';
    import services from "./services";
    import ServiceIpAddress from "./service_forms/service_ip_address";
    import ServiceIPTVTriniti from "./service_forms/service_iptv_triniti";
    import {ServiceMixin} from "../../../../../mixins/admin/services";

    export default {
        name: "add-services",
        props: ["settings", "crud", "subscriber_connection", "subscriber_services", "service_type_added_by_checkbox"],
        mixins: [CrudMixin, ServiceMixin],
        components: {
            "popup": Popup,
            "form-error": FormErrors,
            ServiceIpAddress,
            "service-iptv-triniti": ServiceIPTVTriniti,
        },
        data() {
            return {
                service_object_type: "",
                service: {},
                services: [],
                form_data: {},
                errors: {}
            }
        },
        mounted: function () {
            this.fetchUser()
        },
        methods: {
            fetchUser() {
                this.load_services()
            },
            add_service() {
                this.errors = {};
                if(this.service_object_type === 'service_ip_address'){
                    this.add_service_ip_address();
                }else if(this.service_object_type === 'service_trust_day'){
                    this.add_service_trust_day();
                }else if(this.service_object_type === 'service_iptv_triniti'){
                    this.add_service_iptv_triniti();
                }else{
                    this.$set(this.errors, "subscriber_connection", ["Выберите тип услуги"])
                }
            },
            add_service_ip_address() {
                var self = this;
                var data = Object.assign({}, this.form_data, {subscriber_connection: this.subscriber_connection.id});
                API.post('/services/ip-address-subscriber-connection/', data)
                    .then(function (response) {
                        self.$emit("load_subscriber_services");
                        self.$emit("update_subscriber_connection");
                        self.popup_close();
                        self.clear();
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            },
            add_service_iptv_triniti() {
                var self = this;
                var data = Object.assign({}, this.form_data, {subscriber_connection: this.subscriber_connection.id});
                API.post('/services/iptv-triniti-subscriber-connection/', data)
                    .then(function (response) {
                        self.$emit("load_subscriber_services");
                        self.$emit("update_subscriber_connection");
                        self.popup_close();
                        self.clear();
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            },
            add_service_trust_day() {
                var self = this;
                var data = Object.assign({}, this.form_data, {subscriber_connection: this.subscriber_connection.id});
                API.post('/services/trust-day-subscriber-connection/', data)
                    .then(function (response) {
                        self.$emit("load_subscriber_services");
                        self.$emit("update_subscriber_connection");
                        self.popup_close();
                        self.clear();
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            },
            set_form_data(value){
                this.form_data = value;
            },
            clear(){
                this.$emit('clear_service_add_by_checkbox');
                this.service_object_type = "";
                this.service = {};
                this.errors = {};
            }
        },
        watch: {
            service_object_type() {
                for (var service of this.services) {
                    if(service.object_type === this.service_object_type) {
                        this.service = service;
                    }
                }
            },
            subscriber_services: function (value) {
                var list = this.services.filter( el =>
                    (undefined === this.subscriber_services.service_list.additional_services.find(elm =>
                        (elm.service_type === el.object_type && elm.service_type !== 'service_iptv_triniti'))
                    ));

                if (list.length) {
                  this.services = list;
                }
            },
            subscriber_connection() {
                this.load_services();
            },
            'settings.visible': function(value) {
                if (value) {
                    if (this.service_type_added_by_checkbox.length > 0) {
                        this.service_object_type = this.service_type_added_by_checkbox;
                    }
                }
            },
            service_type_added_by_checkbox(value) {
                if (value.length > 0) {
                    this.service_object_type = value;
                }
            }
        }
    }
</script>

<style scoped>

</style>