import {API} from "../../api";

export var ServiceTrustDayMixin = {
    data: function () {
        return {
            isConnectTrustDay: false,
        }
    },
    methods: {
        isConnectedTrustDay(subscriber_connection) {
            if (subscriber_connection.service_list.loyalty_services.find(x => x.service_type === 'service_trust_day') !== undefined) {
                this.isConnectTrustDay = true;
            }
        },
        isTrustDayActive(subscriber_connection) {
            if (subscriber_connection.service_list) {
                var service_trust_day = subscriber_connection.service_list.loyalty_services.find(x => x.service_type === 'service_trust_day');
                if (service_trust_day) {
                    if (service_trust_day.is_active) {
                        return true
                    }
                }
            }
            return false
        },
        activate_trust_day_subscriber_connection(subscriber_connection){
            var self = this;
            self.errors = {};
            var service_trust_day = subscriber_connection.service_list.loyalty_services.find(x => x.service_type === 'service_trust_day');
            API.post('/services/trust-day-subscriber-connection-activation/', {
                trust_day_subscriber_connection: service_trust_day.service_object_id,
                is_active: true,
            })
            .then(function (response) {
                self.load();
            })
            .catch(function (error) {
                API.default_catch(self, error)
            })
            .then(function () {
                API.default_always(self);
            })
        },
        activate_citizens_support(subscriber_connection){
            var self = this;
            self.errors = {};

            API.post('/services/citizens-support-activation/', {
                subscriber_connection: subscriber_connection.id,
                activate: true,
            })
            .then(function (response) {
                self.load();
            })
            .catch(function (error) {
                API.default_catch(self, error)
                if('non_field_errors' in self.errors) {
                    self.alert("Внимание", self.errors['non_field_errors'][0]);
                }
            })
            .then(function () {
                API.default_always(self);
            })
        },
    }
};
