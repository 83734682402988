// VueJs
import Vue from "vue";
import VueRouter from 'vue-router';
import VueSessionStorage from 'vue-sessionstorage'
import VueLocalStorage from 'vue-localstorage'
import VueAxios from 'vue-axios'
// import VueAuthenticate from 'vue-authenticate'
import GSignInButton from 'vue-google-signin-button'
import axios from 'axios';
import AuthPinPopup from './templates/admin/components/auth/pin.vue';
import AlertPopup from './templates/admin/components/alert/popup.vue';
import NoAccess from './templates/admin/components/global/modal/no-access.vue'
import AppLink from "./templates/admin/components/global/app-link.vue";
import AppHasPermActionButton from "./templates/admin/components/global/app-has-perm-action-btn";
import AppRemoveButton from "./templates/admin/components/global/app-remove-btn";
import store from './store';
import {API} from "./api";
import {User} from "./mixins/admin/user";

// Vue Components
import LoaderView from './templates/admin/layouts/loader-view.vue';
import HeaderView from './templates/admin/layouts/header-view.vue';
import LeftBarView from './templates/admin/layouts/leftbar-view.vue';
import SeachView from './templates/admin/layouts/search-view.vue';
import vSelect from 'vue-select';
import VTooltip from 'v-tooltip';

//FRONT COMPONENTS
import FrontHeader from './templates/front/layouts/header.vue'


//import HeaderSearch from './templates/components/header-search.vue';
//import Populare from './templates/components/populare.vue';
//import Tutorial from './templates/pages/tutorial.vue';
import {LOGIN_REQUIRED_ROUTES, LOGIN_REDIRECT_TO, SOCIAL_ACCOUNTS} from './config';
import VueNumeric from 'vue-numeric'
import Multiselect from 'vue-multiselect'
import interceptorsSetup from './interceptors';
import {loader} from './models/loader';
import {alert_popup} from './models/alert_popup';

// Data
import {admin_routes} from "./routes/admin_routes";

// Models
// import {User} from "./models/user";
// import {API} from "./api";
import {VueDirectives} from "./directives";
import {VueFilters} from "./filters";
import {UserBase} from "./mixins/user_base";
import {Core} from "./mixins/core";
// import {VueMixin} from "./mixin";

Vue.use(VueNumeric);
Vue.use(VueSessionStorage);
Vue.use(VueLocalStorage);
Vue.use(Multiselect);
Vue.use(VTooltip);

Vue.component('v-select', vSelect);
Vue.component('app-link', AppLink);
Vue.component('app-has-perm-action-btn', AppHasPermActionButton);
Vue.component('app-remove-btn', AppRemoveButton);

// Init directives
VueDirectives();
// Init Filters
VueFilters();

Vue.use(VueRouter);
interceptorsSetup();

Vue.use(VueAxios, axios);

const moment = require('moment')
require('moment/locale/ru')

Vue.use(require('vue-moment'), {
    moment
})

Vue.use(GSignInButton);
// Vue.use(VueAuthenticate, { 
//     baseUrl: SOCIAL_ACCOUNTS.baseUrl,
//     providers: SOCIAL_ACCOUNTS.providers
// });


var pin = {
    visible: false
};

var permissions_error = {
    visible: false
};

Vue.mixin({
    mixins: [Core, UserBase, User],
    methods: {
        loader_show: function () {
            loader.is_show= true;
        },
        loader_hide: function () {
            loader.is_show= false;
        },
        pin_show: function () {
            pin.visible= true;
        },
        permissions_error_show: function () {
            permissions_error.visible = true;
        },
        alert: function (title, text) {
            alert_popup.show(title, text);
        }
    }
});

export const router = new VueRouter({
    mode: 'history', // "hash" - by default | "history" - HTML5 History API  | "abstract" - in Node.js
    routes: admin_routes
});

Vue.prototype.$super = function(options) {
  return new Proxy(options, {
    get: (options, name) => {
    	if(options.methods && name in options.methods) {
      	return options.methods[name].bind(this);
      }
    },
  });
};

Vue.prototype.$eventBus = new Vue();

export const app = new Vue({
    router,
    store: store,
    data: function () {
        return {
            loader: loader,
            alert_popup: alert_popup,
            pin: pin,
            permissions_error: permissions_error
        }
    },
    components: {
        'loader-view': LoaderView,
        'header-view': HeaderView,
        'leftbar-view': LeftBarView,
        'search-view': SeachView,
        'auth-pin-popup': AuthPinPopup,
        'alert-popup': AlertPopup,
        'front-header': FrontHeader,
        "no-access": NoAccess,
    },
    beforeCreate() { this.$store.commit('user/initAccessToken');},
    created: async function () {
        // API.access_token = this.$localStorage.get('access_token');
        API.access_token = this.$session.get('access_token');

        if ((API.access_token !== "")&&
            (API.access_token !== undefined)&&
            (API.access_token !== null)) {
            await this.get_user();
        } else {
            this.check_redirect(this.$route)
        }
    },
    watch: {
        '$route' (to, from) {
        },
        user: {
            handler(value) {
                /* User loaded */
                // console.log(typeof this.fetchUser);
                // this.$root.$emit("user_updated");
                if(typeof this.fetchUser === 'function') {
                    this.fetchUser();
                }
            },
            deep: true
        }
    }

}).$mount('#app');