<template>
    <section class="page">
        <div class="page__inner">
            <div class="container-width">
                <!--HEADER PAGE-->
                <div class="page__header">
                    <h1 class="page__title">Должность ({{ crud.data.item_view.name }})</h1>
                    <div class="btn-group">
                        <router-link title="Отмена" :to="{ name: 'employees-position'}">
                                <span class="btn btn_grey">
                                    <span class="btn__icon"><i class="fal fa-undo"></i></span>
                                    <span class="btn__title">Вернуться</span>
                                </span>
                        </router-link>
                        <app-link :perms="'catalog_employee_positions_add_edit'"
                                  :title="'Редактирование должности'"
                                  :to="{ name: 'employees-position-edit', params: {id: id}}">
                            <template slot-scope="{ access }">
                                <span class="btn" :class="[access ? 'btn_orange' : 'btn_grey']">
                                    <span class="btn-l__icon"><i class="fal fa-edit"></i></span>
                                </span>
                            </template>
                        </app-link>
                    </div>
                </div>
                <!--END HEADER PAGE-->
                <!-- CONTENT PAGE -->
                <div class="page-body">
                    <div class="view">
                        <div class="view__row">
                            <div class="view__section">
                                <!--Название должности-->
                                <div class="view__item">
                                    <div class="view__label">
                                        <span class="view__label-icon"><i class="fal fa-industry-alt"></i></span>
                                        <span class="view__label-title">Название должности:</span>
                                    </div>
                                    <div class="view__value">
                                        <span class="view__value-text">{{ crud.data.item.name }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="view__section">
                                <!--Примечания-->
                                <div class="view__item">
                                    <div class="view__label">
                                        <span class="view__label-icon"><i class="fal fa-comments-alt"></i></span>
                                        <span class="view__label-title">Примечания:</span>
                                    </div>
                                    <div class="view__value">
                                        <span class="view__value-text">
                                            {{ crud.data.item.description }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- END CONTENT PAGE -->
                <permissions :permissions="crud.data.item.position_permissions_expand" :readonly="true"></permissions>
                <!--<employee-permissions :permissions="crud.data.item.position_permissions_expand" :readonly="true" ></employee-permissions>-->
            </div>
        </div>
    </section>
</template>

<script>
    import {CrudMixin} from "../../../../../mixins/admin/crud/crud_base";
    import {CrudListMixin} from "../../../../../mixins/admin/crud/crud_list";

    import EmployeePermissions from '../../employee_permissions/list';
    import Permissions from '../permissions/list'

    export default {
        name: "position-view",
        mixins: [CrudMixin, CrudListMixin],

        data: function () {
            return {
                password_visible: false,
                crud: {
                    api: '/employee-positions/',
                },
            }
        },
        computed: {
            id(){
                return this.$route.params.id;
            }
        },
        components: {
            "employee-permissions": EmployeePermissions,
            "permissions": Permissions
        },
        created: function () {
            this.crud_get_item(this.id);
        },

        methods: {
        },
    }
</script>

<style scoped>

</style>