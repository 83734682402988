<template>
    <div>
        <div class="add-data__header">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h3><i class="fal fa-passport"></i> Паспортные данные</h3>
                </div>
            </div>
        </div>
        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label><i class="fal fa-globe"></i> Страна:</label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                    <input type="text"
                           v-model="subscriber.user_passportdetails.country"
                           placeholder="Введите страну">
                </div>
                <div class="clearfix"></div>
                <form-error
                        v-bind:value="errors.user_passportdetails.country"></form-error>
            </div>
        </div>
        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label><i class="fal fa-map-marker-alt"></i> Область:</label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                    <input type="text"
                           v-model="subscriber.user_passportdetails.region"
                           placeholder="Введите область">
                </div>
                <div class="clearfix"></div>
                <form-error
                        v-bind:value="errors.user_passportdetails.region"></form-error>
            </div>
        </div>
        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label><i class="fal fa-map-marker-alt"></i> Район:</label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                    <input type="text"
                           v-model="subscriber.user_passportdetails.district"
                           placeholder="Введите район">
                </div>
                <div class="clearfix"></div>
                <form-error
                        v-bind:value="errors.user_passportdetails.district"></form-error>
            </div>
        </div>
        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label><i class="fal fa-map-marker-alt"></i> Населенный
                        пункт:</label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                    <input type="text"
                           v-model="subscriber.user_passportdetails.settlement"
                           placeholder="Введите населенный пункт">
                </div>
                <div class="clearfix"></div>
                <form-error
                        v-bind:value="errors.user_passportdetails.settlement"></form-error>
            </div>
        </div>
        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label><i class="fal fa-road"></i> Улица:</label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                    <input type="text"
                           v-model="subscriber.user_passportdetails.street"
                           placeholder="Введите улицу">
                </div>
                <div class="clearfix"></div>
                <form-error
                        v-bind:value="errors.user_passportdetails.street"></form-error>
            </div>
        </div>
        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label><i class="fal fa-home"></i> Дом:</label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                    <input type="text"
                           v-model="subscriber.user_passportdetails.house_number"
                           placeholder="Номер дома">
                </div>
                <div class="clearfix"></div>
                <form-error
                        v-bind:value="errors.user_passportdetails.house_number"></form-error>
            </div>
        </div>
        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label><i class="fal fa-building"></i> Квартира:</label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                    <input type="text"
                           v-model="subscriber.user_passportdetails.apartment_number"
                           placeholder="Номер квартиры">
                </div>
                <div class="clearfix"></div>
                <form-error
                        v-bind:value="errors.user_passportdetails.apartment_number"></form-error>
            </div>
        </div>
        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label><i class="fal fa-id-card"></i> Серия/Номер:</label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                    <input type="text"
                           v-model="subscriber.user_passportdetails.passport_series_number"
                           placeholder="Серия/Номер паспорта">
                </div>
                <div class="clearfix"></div>
                <form-error
                        v-bind:value="errors.user_passportdetails.passport_series_number"></form-error>
            </div>
        </div>
        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label><i class="fal fa-calendar-alt"></i> Дата выдачи:</label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                    <input type="text"
                           v-model="subscriber.user_passportdetails.date_of_issue"
                           placeholder="Дата выдачи паспорта">
                </div>
                <div class="clearfix"></div>
                <form-error
                        v-bind:value="errors.user_passportdetails.date_of_issue"></form-error>
            </div>
        </div>
        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label><i class="fal fa-user-secret"></i> Кем выдан:</label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                    <input type="text"
                           v-model="subscriber.user_passportdetails.issued_by"
                           placeholder="Подразделение кем выдан">
                </div>
                <div class="clearfix"></div>
                <form-error
                        v-bind:value="errors.user_passportdetails.issued_by"></form-error>
            </div>
        </div>
        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label><i class="fal fa-file"></i> ИНН:</label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                    <input type="text"
                           v-model="subscriber.user_passportdetails.identification_number"
                           placeholder="Идентификационный код">
                </div>
                <div class="clearfix"></div>
                <form-error
                        v-bind:value="errors.user_passportdetails.identification_number"></form-error>
            </div>
        </div>

        <template v-if="subscriber.user_passportdetails">
            <file-multiupload :title="'Документы удостоверяющие личность'"
                              :files_input="subscriber.user_passportdetails.private_subscriber_passport_details_file_expand"
                              :errors="errors.user_passportdetails.private_subscriber_passport_details_file"
                              @set_files="set_passport_files" >
            </file-multiupload>

            <file-multiupload :title="'Дополнительные документы'"
                              :files_input="subscriber.user_passportdetails.private_subscriber_additional_details_file_expand"
                              :errors="errors.user_passportdetails.private_subscriber_additional_details_file"
                              @set_files="set_additional_documents" >
            </file-multiupload>
        </template>
    </div>
</template>

<script>
    import FormErrors from '../../form/errors.vue';
    import FileMultiupload from '../../form/file-multiupload.vue';

    export default {
        name: "private_passport_data",
        props: ["subscriber", "errors"],
        components: {
            "form-error": FormErrors,
            "file-multiupload": FileMultiupload,
        },
        methods: {
            set_passport_files(value){
                this.subscriber.user_passportdetails.private_subscriber_passport_details_file = value;
            },
            set_additional_documents(value){
                this.subscriber.user_passportdetails.private_subscriber_additional_details_file = value;
            }
        },
        watch: {
            subscriber: {
                handler(value) {
                    this.$emit("update_subscriber", value);
                },
                deep: true
            },
        }
    }
</script>

<style scoped>

</style>
