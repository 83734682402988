<template>
    <td class="table-subscribers__td" v-has-perm="'subscribers_access'">
      <span class="table-data__text" v-if="subscriber_connection.connection_type_expand">
        {{ subscriber_connection.connection_type_expand.label }}
      </span>
    </td>
</template>

<script>
export default {
  name: "td_type_connection",
  props: ["subscriber_connection"],
}
</script>

<style scoped>

</style>