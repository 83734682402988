import {API} from "../../api";

export var DashboardStatisticsMixin = {
    methods: {
        getTotalSubscriberConnectionsOnline() {
            var self = this;

            API.get('/dashboard/total-online-subscriber-connections/')
                .then(function (response) {
                    self.count = response.data.count;
                    self.linkObjectType = response.data.link_object_type;
                    self.linkParams = response.data.link_params;
                })
                .catch(function (error) {
                    // API.default_catch(self, error)
                })
                .then(function () {
                    // API.default_always(self);
                })
        },
        getTotalCountCreatedSubscriberConnectionsYesterday() {
            var self = this;

            API.get('/dashboard/total-count-created-subscriber-connections-yesterday/')
                .then(function (response) {
                    self.count = response.data.count;
                    self.linkObjectType = response.data.link_object_type;
                    self.linkParams = response.data.link_params;
                })
                .catch(function (error) {
                    // API.default_catch(self, error)
                })
                .then(function () {
                    // API.default_always(self);
                })
        },
        getTotalActivateIPTVTrinitiSubscriberConnection() {
            var self = this;

             API.get('/dashboard/total-activate-iptv-triniti-subscriber-connections/')
                .then(function (response) {
                    self.count = response.data.count;
                    self.linkObjectType = response.data.link_object_type;
                    self.linkParams = response.data.link_params;
                })
                .catch(function (error) {
                    // API.default_catch(self, error)
                })
                .then(function () {
                    // API.default_always(self);
                })
        },
        getTotalActivateIPAddressSubscriberConnection() {
            var self = this;

             API.get('/dashboard/total-activate-ip-address-subscriber-connections/')
                .then(function (response) {
                    self.count = response.data.count;
                    self.linkObjectType = response.data.link_object_type;
                    self.linkParams = response.data.link_params;
                })
                .catch(function (error) {
                    // API.default_catch(self, error)
                })
                .then(function () {
                    // API.default_always(self);
                })
        },
        getTopInstallerEmployees() {
            var self = this;

            API.get('/dashboard/top-installer-employees/')
                .then(function (response) {
                    self.items = response.data;
                })
                .catch(function (error) {
                    // API.default_catch(self, error)
                })
                .then(function () {
                    // API.default_always(self);
                })
        },
        getTopTechnicalSupportEmployees() {
            var self = this;

            API.get('/dashboard/top-technical-support-employees/')
                .then(function (response) {
                    self.items = response.data;
                })
                .catch(function (error) {
                    // API.default_catch(self, error)
                })
                .then(function () {
                    // API.default_always(self);
                })
        },
        getSubscriberConnectionsTopTraffic() {
            var self = this;

            API.get('/dashboard/top-traffic-subscriber-connections/')
                .then(function (response) {
                    self.items = response.data;
                })
                .catch(function (error) {
                    // API.default_catch(self, error)
                })
                .then(function () {
                    // API.default_always(self);
                })
        },
    }
}