<template>
    <div>
        <div class="search-view" v-show="search">
            <div class="search-view__info">
                <span class="search-view__info--title">Результаты поиска:</span>
                <span class="search-view__info--result">{{ search }}</span>
                <!--<span class="search-view__info&#45;&#45;title">Фильтры:</span>-->
                <!--<ul class="search-view__info&#45;&#45;filter">-->
                    <!--<li>Абоненты</li>-->
                    <!--<li>Физические лица</li>-->
                <!--</ul>-->
            </div>
            <div class="search-view__reset" @click.prevent="search_clear()">
                <i class="fal fa-times"></i> Сбросить
            </div>
        </div>
    </div>
</template>

<script>
    import {RouteHelperMixin} from "../../../mixins/admin/route_helper";

    export default {
        name: "search-view",
        mixins: [RouteHelperMixin],
        data() {
            return {
                emit_list: ["nas", "subscribers", "subscriber-connections", "employees", "groups", "departments",
                    "tariffs"],
                search: ""
            }
        },
        created(){
            this.set_search();
        },
        methods: {
            set_search(new_route) {
                if("q" in this.$route.query) {
                    this.search = this.$route.query.q;
                } else {
                    this.search_clear(new_route);
                }
            },
            search_clear(new_route){
                new_route = new_route || false;
                // let query = Object.assign({}, this.$route.query);
                if(this.search !== "") {
                    var query = {};
                    this.$router.replace({query});
                    this.search = "";
                    // If route not changed need send emit
                    if(!new_route) {
                        for (var item of this.emit_list) {
                            // console.log("search_clear "+ "search_clear_" + item +" !!!");
                            this.$root.$emit("search_clear_" + item, event);
                        }
                    }
                }
            }
        },
        watch: {
            '$route' (route_to, route_from) {
                var new_route = (route_to.name !== route_from.name);
                // console.log("new_route", new_route);

                this.set_search(new_route);
            }
        }
    }
</script>

<style scoped>

</style>