export var RouteHelperMixin = {
    methods: {
        set_data_from_route(fields, obj) {
            var value;

            for (var i in fields) {
                if (fields[i].name in this.$route.query) {
                    value = this.$route.query[fields[i].name];
                    if (fields[i].type === 'int') {
                        value = parseInt(value);
                    }
                    if(fields[i].type === 'date') {
                        value = this.$moment(value)
                    }

                    this.$set(obj, fields[i].name, value);
                }
            }
        },
        updateRouterQuery(paramName, items, itemId) {
            if (itemId) {
                this.$router.replace({
                    query: Object.assign({}, this.$route.query, {
                        [paramName]: this.findObjectFromArray(paramName, items, itemId)
                    }),
                });
            } else {
                this.removeFilterType(paramName);
            }
        },
        removeFilterType(paramName) {
            let query = Object.assign({}, this.$route.query);
            delete query[paramName];
            this.$router.replace({query});
        },
        findObjectFromArray(paramName, filter_array, filter_id) {
            if(filter_id === 'common_tariff') {
                return "common_tariff"
            }
            if(['agent', 'owner'].includes(paramName)) {
                return filter_array.find(x => x.id === filter_id).fio;
            }
            if(['status', 'series', 'value', 'destination'].includes(paramName)) {
                return filter_array.find(x => x.key === filter_id).key;
            }

            return filter_array.find(x => x.id === filter_id).name;
        }
    }
}