export var ServiceIptvTrinitiDevicesUnitsHelper = {
    data() {
        return {
            devicePaymentUnits: [],
        }
    },
    methods: {
        initDevicePaymentUnits(service) {
            const block_size = service.count_devices;
            const devices = service.devices;
            const payments = service.payments;
            let devicePaymentUnits = [];
            let payments_index = 0;

            for (let i = 0; i < payments.length; i += 1) {
                devicePaymentUnits.push({'payment': payments[i]});
            }

            for (let i = 0; i < devices.length; i += block_size) {
                devicePaymentUnits[payments_index]['devices'] = (devices.slice(i, i + block_size));
                payments_index += 1;
            }

            this.devicePaymentUnits = devicePaymentUnits;
        }
    }
}