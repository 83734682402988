import Vue from "vue";
import {User} from "./mixins/admin/user";
import {UserBase} from "./mixins/user_base";

export const VueDirectives = function () {

    // Same this: v-bind:class="{ 'form-label-error': errors, 'form-control-not-empty': email}"
    Vue.directive('has-perm', function (el, binding) {
        var has_perm = UserBase.methods.has_perm(binding.value);

        if(!has_perm){
            el.classList.add('hidden-block');
        } else {
            el.classList.remove('hidden-block');
        }
    });

    Vue.directive('has-perm-btn', function (el, binding) {
        var has_perm = UserBase.methods.has_perm(binding.value);

        if(!has_perm){
            el.classList.add('btn__green_disabled')
        } else {
            el.classList.remove('btn__green_disabled')
        }

        if(el.tagName === 'A'){
            // el.setAttribute('href', '#');
        }
    });

    Vue.directive('is-superuser', function (el, binding) {
        var has_perm = UserBase.methods.is_superuser();

        if (!has_perm) {
            el.classList.add('hidden-block')
        } else {
            el.classList.remove('hidden-block')
        }
    });

    let handleOutsideClick
    Vue.directive('closable', {
        bind(el, binding, vnode) {
            handleOutsideClick = (e) => {
                e.stopPropagation()
                const {handler, exclude} = binding.value
                let clickedOnExcludedEl = false
                exclude.forEach(refName => {
                    if (!clickedOnExcludedEl) {
                        const excludedEl = vnode.context.$refs[refName]
                        clickedOnExcludedEl = excludedEl.contains(e.target)
                    }
                })
                if (!el.contains(e.target) && !clickedOnExcludedEl) {
                    vnode.context[handler]()
                }
            }
            document.addEventListener('click', handleOutsideClick)
            document.addEventListener('touchstart', handleOutsideClick)
        },

        unbind() {
            document.removeEventListener('click', handleOutsideClick)
            document.removeEventListener('touchstart', handleOutsideClick)
        }
    });
};