<template>
    <div :class="isTableSelect ? 'table-filter__select' : ''">
        <select :value="value" v-on:input="$emit('input', parseInt($event.target.value))" required>
            <option v-for="item in years" :selected="item.year === value" :value="item.year">{{ item.year }} <template v-if="is_current_year(item.year)">(текущий)</template></option>
        </select>
    </div>
</template>

<script>
    export default {
        name: "year_filter",
        props: {
          value: {
            type: Number,
            default: function () { return this.$moment().year() }
          },
          years: {
            type: Array,
            default: function () { return [] }
          },
          isTableSelect: {
            type: Boolean,
            default: function () { return true }
          }
        },
        data(){
            return {
            }
        },
        methods: {
            is_current_year(year){
                return this.$moment().year() === year
            }
        }
    }
</script>

<style scoped>

</style>