<template>
    <section class="page">
        <div class="page__inner">
            <div class="container-width">
                <div class="row">
                    <!--HEADER PAGE-->
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="page-header">
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                                    <h1 class="page-header__title">Дополнительные услуги</h1>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                    <div class="page-header__btn-group"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--END HEADER PAGE-->
                    <!--ITEMS PAGE-->
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" v-if="Object.keys(services).length">
                        <div class="services-list">
                            <div class="row">
                                <!-- Service IP -->
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3" v-if="has_service_perm(services.service_ip_address)" >
                                    <router-link title="Услуга IP-address" :to="{ name: 'services-ip-address'}">
                                        <div class="services-list__item">
                                            <div class="info">
                                                <div class="info__icon"><i class="fal fa-network-wired"></i></div>
                                                <div class="info__title">{{ services.service_ip_address.object_name }}</div>
                                                <div class="info__count">{{ services.service_ip_address.count }}</div>
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                                <!-- END Service IP -->
                                <!-- Service TV -->
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3" v-if="has_service_perm(services.service_iptv_triniti)">
                                    <router-link title="Услуга TВ" :to="{ name: 'services-iptv-triniti'}">
                                    <div class="services-list__item">
                                        <div class="info">
                                            <div class="info__icon"><i class="fal fa-tv-retro"></i></div>
                                            <div class="info__title">{{ services.service_iptv_triniti.object_name }}</div>
                                            <div class="info__count">{{ services.service_iptv_triniti.count }}</div>
                                        </div>
                                    </div>
                                    </router-link>
                                </div>
                                <!-- END Service TV -->
                                <!-- Service Rent -->
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3" v-if="has_service_perm(services.service_equipment_rent)">
                                    <div class="services-list__item">
                                        <div class="info">
                                            <div class="info__icon"><i class="fal fa-router"></i></div>
                                            <div class="info__title">{{ services.service_equipment_rent.object_name }}</div>
                                            <div class="info__count">{{ services.service_equipment_rent.count }}</div>
                                        </div>
                                    </div>
                                </div>
                                <!-- END Service Rent -->
                                <!-- Service Freezing -->
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3" v-if="has_service_perm(services.service_account_freeze)">
                                    <div class="services-list__item">
                                        <div class="info">
                                            <div class="info__icon"><i class="fal fa-snowflakes"></i></div>
                                            <div class="info__title">{{ services.service_account_freeze.object_name }}</div>
                                            <div class="info__count">{{ services.service_account_freeze.count }}</div>
                                        </div>
                                    </div>
                                </div>
                                <!-- END Service Freezing -->
                                <!-- Service Trust day -->
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3" v-if="has_service_perm(services.service_trust_day)">
                                    <div class="services-list__item">
                                        <div class="info">
                                            <div class="info__icon"><i class="fal fa-handshake-alt"></i></div>
                                            <div class="info__title">{{ services.service_trust_day.object_name }}</div>
                                            <div class="info__count">{{ services.service_trust_day.count }}</div>
                                        </div>
                                    </div>
                                </div>
                                <!-- END Service Trust day -->
                                <!-- Service Turbo speed -->
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3" v-if="has_service_perm(services.service_turbo_speed)">
                                    <div class="services-list__item">
                                        <div class="info">
                                            <div class="info__icon"><i class="fal fa-rocket"></i></div>
                                            <div class="info__title">{{ services.service_turbo_speed.object_name }}</div>
                                            <div class="info__count">{{ services.service_turbo_speed.count }}</div>
                                        </div>
                                    </div>
                                </div>
                                <!-- END Service Turbo speed -->
                                <!-- Service Vacation -->
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3" v-if="has_service_perm(services.service_vacation)">
                                    <div class="services-list__item">
                                        <div class="info">
                                            <div class="info__icon"><i class="fal fa-island-tropical"></i></div>
                                            <div class="info__title">{{ services.service_vacation.object_name }}</div>
                                            <div class="info__count">{{ services.service_vacation.count }}</div>
                                        </div>
                                    </div>
                                </div>
                                <!-- END Service Vacation -->
                                <!-- Service Activation -->
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3" v-if="has_service_perm(services.service_connection_activation)">
                                    <div class="services-list__item">
                                        <div class="info">
                                            <div class="info__icon"><i class="fal fa-power-off"></i></div>
                                            <div class="info__title">{{ services.service_connection_activation.object_name }}</div>
                                            <div class="info__count">{{ services.service_connection_activation.count }}</div>
                                        </div>
                                    </div>
                                </div>
                                <!-- END Service Activation -->
                                <!-- Service Free test -->
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3" v-if="has_service_perm(services.service_connection_test)">
                                    <div class="services-list__item">
                                        <div class="info">
                                            <div class="info__icon"><i class="fal fa-heartbeat"></i></div>
                                            <div class="info__title">{{ services.service_connection_test.object_name }}</div>
                                            <div class="info__count">{{ services.service_connection_test.count }}</div>
                                        </div>
                                    </div>
                                </div>
                                <!-- END Service Free test -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import {API} from "../../../../api";

    export default {
        name: "list",
        data(){
            return {
                services: {},
                errors: {}
            }
        },
        mounted: function () {
            this.fetchUser()
        },
        methods: {
            fetchUser() {
                // if (this.has_perm({
                //     permissions: 'subscribers_access',
                //     comparison: 'OR'
                // })) {
                    this.load_services()
                // }
            },
            load_services: function () {
                var self = this;

                self.loader_show();
                API.get('/services/all/')
                    .then(function (response) {
                        for(var item of response.data) {
                            // self.services[item.object_type] = item;
                            self.$set(self.services, item.object_type, item);
                        }
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            },
            has_service_perm: function (service) {
                var perm_name = "catalog_additional_services_service_access";
                if(service.is_personal_service){
                    perm_name = "catalog_additional_services_personal_access"
                }

                return this.has_perm(perm_name);
            }
        },

    }
</script>

<style scoped>

</style>