<template>
    <popup :settings="settings">
        <div slot="title">Редактирование пакета ТВ</div>
        <div slot="content">
            <div class="body-size">
                <div class="body-size__860">
                    <form class="form-modal">
                        <div class="form-modal__group">
                            <fieldset class="form-modal__item">
                                <span class="form-modal__title">
                                <span class="form-modal__icon"><i class="fal fa-box"></i></span>
                                        Название пакета
                                </span>
                                <input type="text" v-model="crud.data.item.name" class="form-modal__input" placeholder="Название пакета">
                                <form-error v-bind:value="crud.errors.name"></form-error>
                                <form-error v-bind:value="crud.errors.non_field_errors"></form-error>
                            </fieldset>
                        </div>
                        <div class="form-modal__group">
                            <fieldset class="form-modal__item">
                                <span class="form-modal__title">
                                <span class="form-modal__icon"><i class="fal fa-plug"></i></span>
                                    Тариф Triniti
                                </span>
                                <div class="form-modal__select">
                                    <select v-model="crud.data.item.triniti_tariff" required>
                                        <option :value="null" hidden>Выберите тариф Triniti</option>
                                        <option v-for="iptv_triniti_tariff in iptv_triniti_tariffs"
                                                v-bind:value="iptv_triniti_tariff.id" >{{ iptv_triniti_tariff.name }}</option>
                                    </select>
                                </div>
                                <form-error v-bind:value="crud.errors.triniti_tariff"></form-error>
                            </fieldset>
                            <fieldset class="form-modal__item">
                                <span class="form-modal__title">
                                <span class="form-modal__icon"><i class="fal fa-phone-laptop"></i></span>
                                    Кол-во устройств
                                </span>
                                <div class="form-modal__select">
                                    <select v-model="crud.data.item.count_devices" required>
                                        <option v-for="number in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" :value="number">
                                            {{ number }}</option>
                                    </select>
                                </div>
                                <form-error v-bind:value="crud.errors.count_devices"></form-error>
                            </fieldset>
                            <fieldset class="form-modal__item form-modal__item_switch">
                                <span class="form-modal__title">
                                <span class="form-modal__icon"><i class="fal fa-user-lock"></i></span>
                                Доступно в ЛК
                                </span>
                                    <input v-model="crud.data.item.is_available_in_profile" type="checkbox"
                                           class="form-modal__input form-modal__input_switch" :id="isAvailableInProfileId">
                                    <label class="form-modal__switch-for" :for="isAvailableInProfileId"></label>
                            </fieldset>
                        </div>
                        <div class="form-modal__group">
                            <fieldset class="form-modal__item">
                                <span class="form-modal__title">
                                <span class="form-modal__icon"><i class="fal fa-users-crown"></i></span>
                                    Тип абонента
                                </span>
                                <div class="form-modal__select">
                                    <select v-model="crud.data.item.subscriber_type" required>
                                        <option :value="null">Все</option>
                                        <option v-for="subscriber_type in subscriber_types"
                                                v-bind:value="subscriber_type.id" >{{ subscriber_type.name_expand }}</option>
                                    </select>
                                </div>
                                <form-error v-bind:value="crud.errors.subscriber_type"></form-error>
                            </fieldset>
                             <fieldset class="form-modal__item">
                                <span class="form-modal__title">
                                <span class="form-modal__icon"><i class="fal fa-code-branch"></i></span>
                                    Отдел
                                </span>
                                <div class="form-modal__select">
                                    <select v-model="crud.data.item.department" required>
                                        <option :value="null">Все</option>
                                        <option v-for="department in departments"
                                                v-bind:value="department.id" >{{ department.name }}</option>
                                    </select>
                                </div>
                                <form-error v-bind:value="crud.errors.department"></form-error>
                            </fieldset>
                             <fieldset class="form-modal__item">
                                <span class="form-modal__title">
                                <span class="form-modal__icon"><i class="fal fa-ethernet"></i></span>
                                    Технология
                                </span>
                                <div class="form-modal__select">
                                    <select v-model="crud.data.item.connection_type" required>
                                        <option :value="null">Все</option>
                                        <option v-for="connection_type in connection_types"
                                                v-bind:value="connection_type.id" >{{ connection_type.label }}</option>
                                    </select>
                                </div>
                                <form-error v-bind:value="crud.errors.connection_type"></form-error>
                            </fieldset>
                        </div>
                        <div class="form-modal__group">
                            <fieldset class="form-modal__item">
                                <span class="form-modal__title">
                                <span class="form-modal__icon"><i class="fal fa-calendar-alt"></i></span>
                                    Группа
                                </span>
                                <div class="form-modal__select">
                                    <select v-model="crud.data.item.group" required>
                                        <option :value="null">Все</option>
                                        <option v-for="group in groups" v-bind:value="group.id" >
                                            {{ group.name }}
                                        </option>
                                    </select>
                                </div>
                                <form-error v-bind:value="crud.errors.group"></form-error>
                            </fieldset>
                            <fieldset class="form-modal__item">
                                <span class="form-modal__title">
                                <span class="form-modal__icon"><i class="fal fa-calendar-alt"></i></span>
                                    Тарификация
                                </span>
                                <div class="form-modal__select">
                                    <select v-model="crud.data.item.charging_type" required>
                                        <option :value="null" disabled>Выберите тарификацию</option>
                                        <option v-for="charging_type in iptv_triniti_charging_types"
                                                v-bind:value="charging_type.key" >{{ charging_type.label }}</option>
                                    </select>
                                </div>
                                <form-error v-bind:value="crud.errors.charging_type"></form-error>
                            </fieldset>
                            <template v-if="crud.data.item.charging_type === 'month'">
                                <fieldset class="form-modal__item">
                                    <span class="form-modal__title">
                                    <span class="form-modal__icon"><i class="fal fa-calendar-week"></i> </span>
                                            Месяц
                                    </span>
                                    <div class="form-modal__money">
                                        <input type="text" v-model="crud.data.item.charging_amount_month"
                                               class="form-data__input form-data__input_money"
                                               placeholder="0.00">
                                        <form-error
                                                v-bind:value="crud.errors.charging_amount_month"></form-error>
                                    </div>
                                </fieldset>
                            </template>
                            <template v-if="crud.data.item.charging_type === 'day'">
                                <fieldset class="form-modal__item">
                                    <span class="form-modal__title">
                                    <span class="form-modal__icon"><i class="fal fa-calendar-week"></i> </span>
                                            Месяц
                                    </span>
                                    <div class="form-modal__money">
                                        <input v-model="crud.data.item.charging_amount_month"
                                            type="text" class="form-modal__input form-modal__input_money" placeholder="0.00">
                                        <form-error
                                            v-bind:value="crud.errors.charging_amount_month"></form-error>
                                    </div>
                                </fieldset>
                                <fieldset class="form-modal__item">
                                    <span class="form-modal__title">
                                    <span class="form-modal__icon"><i class="fal fa-calendar-day"></i></span>
                                            День
                                    </span>
                                    <div class="form-modal__money">
                                        <input v-model="crud.data.item.charging_amount_day"
                                                type="text" class="form-modal__input form-modal__input_money" placeholder="0.00">
                                        <form-error
                                            v-bind:value="crud.errors.charging_amount_day"></form-error>
                                    </div>
                                </fieldset>
                            </template>
                        </div>
                        <div class="form-modal__group">
                             <fieldset class="form-modal__item">
                                <span class="form-modal__title">
                                <span class="form-modal__icon"><i class="fal fa-comment-lines"></i></span>
                                        Примечание
                                </span>
                                 <textarea v-model="crud.data.item.note" rows="3" class="form-modal__textarea"
                                           placeholder="Напишите примечание...">
                                 </textarea>
                                <form-error v-bind:value="crud.errors.note"></form-error>
                            </fieldset>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="btn-group">
                <span class="btn btn_grey" @click="popup_close">
                    <span class="btn__icon"><i class="fal fa-times-circle"></i></span>
                    <span class="btn__title">Я передумал</span>
                </span>
                <span class="btn btn_blue" @click.prevent="crud_edit(crud.data.item.id, crud.data.item)">
                    <span class="btn__icon"><i class="fal fa-check"></i></span>
                    <span class="btn__title">Сохранить</span>
                </span>
            </div>
        </div>
    </popup>
</template>

<script>
    import Popup from '../../modal/popup.vue';
    import FormErrors from '../../form/errors.vue';

    import {CrudMixin} from "../../../../../mixins/admin/crud/crud_base";
    import {loader} from "../../../../../models/loader";
    import _ from 'lodash';
    import {TariffMixin} from "../../../../../mixins/admin/tariff";
    import {GroupMixin} from "../../../../../mixins/admin/group";
    import {CrudListMixin} from "../../../../../mixins/admin/crud/crud_list";

    export default {
        name: "edit-iptv-triniti",
        props: ["settings", "crud", "iptv_triniti_charging_types", "iptv_triniti_tariffs", "subscriber_types", "connection_types", "departments", ],
        mixins: [CrudMixin, TariffMixin, GroupMixin],
        components: {
            "popup": Popup,
            "form-error": FormErrors,
        },
        data() {
          return {
            isAvailableInProfileId: _.uniqueId('id_'),
          }
        },
        created(){
            this.load_groups();
        },
        methods: {
            calculate_charging_amount_day: _.throttle((self) => {
                if (self.crud.data.item.charging_type === 'day') {
                    loader.active = false;
                    self.tariffs_calc_day_by_month(self.crud.data.item.charging_amount_month).then(function (response) {
                        self.crud.data.item.charging_amount_day = response.data.charging_amount_day;
                        loader.active = true;
                    })
                }
            }, 1000),
        },
        watch: {
            'crud.data.item.charging_type': function (value) {
                if (value && this.settings.visible) {
                    this.calculate_charging_amount_day(this);
                }
            },
            'crud.data.item.charging_amount_month': function (value) {
                if (value && this.settings.visible) {
                    this.calculate_charging_amount_day(this);
                }
            },
        }
    }
</script>

<style scoped>

</style>