import {LOGIN_REDIRECT_TO} from "../config";

export var Core = {

    methods: {
        check_redirect: function ($route) {
            // console.log($route);

            if ((LOGIN_REDIRECT_TO !== $route.name)) {
                this.$router.push({name: LOGIN_REDIRECT_TO});
            }
        },
        appendCss(href){
          var file = document.createElement('link');
          file.rel = 'stylesheet';
          file.href = href;
          document.head.appendChild(file);
        },
        is_admin_auth: function () {
            return this.is_auth() && this.is_staff()
        },
    }
}