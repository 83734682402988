<template>
    <div class="content-modal">
        <div class="content-modal__header">
            <div class="content-modal__title">
                <span class="content-modal__title-icon"><i class="fal fa-map-marker-alt"></i></span>
                <span class="content-modal__title-text">Населенные пункты</span>
            </div>
            <div class="btn-group">
                <app-has-perm-action-btn :perms="'catalog_settlements_add_edit'"
                                         @action="showSubAccordion = !showSubAccordion">
                    <template slot-scope="{ access }">
                        <span class="btn" v-if="!showSubAccordion" :class="access ? 'btn_green' : 'btn_grey'">
                            <span class="btn__icon btn__icon_nt"><i class="fal fa-plus"></i></span>
                        </span>
                        <span class="btn btn_grey" v-else @click="showSubAccordion = !showSubAccordion">
                            <span class="btn__icon btn__icon_nt"><i class="fal fa-times-circle"></i></span>
                        </span>
                    </template>
                </app-has-perm-action-btn>
            </div>
        </div>
        <!-- Форма добавления района -->
        <transition name="accordion">
            <div class="accordion-form" v-if="showSubAccordion">
                <CityAdd :city_districts="city_districts" :city_district="district" @update_settlements="update_settlements" ></CityAdd>
            </div>
        </transition>
        <div class="content-modal__body">
            <div class="content-modal__chain">
                <form class="form-modal">
                    <div class="form-modal__select">
                        <select v-model="district" required>
                            <option v-for="item in city_districts" :value="item.id">{{ item.name }}</option>
                        </select>
                    </div>
                </form>
            </div>
            <div class="table-modal">
                <table class="table-modal__table">
                    <tbody class="table-modal__tbody">
                    <tr class="table-modal__tr table-data" v-for="settlement in settlements">
                        <!--Улицы-->
                        <td class="table-modal__td">
                            <template v-if="edit_line_id !== settlement.id">
                                <span class="table-data__text">{{ settlement.name }}</span>
                            </template>
                            <template v-else>
                                <input type="text" v-model="name" class="table-data__input">
                                <form-error v-bind:value="errors.name"></form-error>
                                <div class="btn-group">
                                    <span class="btn-c btn-c_green" @click="save()">
                                        <span class="btn-c__icon"><i class="fal fa-check"></i></span>
                                    </span>
                                    <span class="btn-c btn-c_grey" @click="show_line()">
                                        <span class="btn-c__icon"><i class="fal fa-times"></i></span>
                                    </span>
                                </div>
                            </template>
                        </td>
                        <td class="table-modal__td table-modal__td_right">
                            <div class="btn-group">
                                <!--EDIT CITY-->
                                <app-has-perm-action-btn :perms="'catalog_settlements_add_edit'"
                                                         @action="edit_line(settlement)">
                                    <template slot-scope="{ access }">
                                        <span class="btn-l" :class="access ? 'btn-l_orange' : 'btn-l_grey'">
                                            <span class="btn-l__icon"><i class="fal fa-edit"></i></span>
                                        </span>
                                    </template>
                                </app-has-perm-action-btn>
                                <!--END EDIT CITY-->
                                <!--DELETE CITY-->
                                <app-remove-btn :perms="'catalog_settlements_delete'" ></app-remove-btn>
                                <!--END DELETE CITY-->
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="content-modal__footer"></div>
    </div>
</template>

<script>

    // DATA
    import CityAdd from './city-add';
    import FormErrors from '../../../form/errors.vue';

    // MIXIN
    import {GeographyRegionAccordionMixin} from "../../../../../../mixins/admin/geography_region_accordion";
    import {AddressMixin} from "../../../../../../mixins/admin/address";
    import {API} from "../../../../../../api";

    export default {
        name: "city-view",
        props: ["region", "city_districts"],
        components: {
            CityAdd,
            "form-error": FormErrors,
        },
        data() {
            return {
                district: "",
                settlements: [],
                showSubAccordion: false,
                edit_line_id: "",
                name: "",
                errors: {}
            }
        },
        mixins: [GeographyRegionAccordionMixin, AddressMixin],
        methods: {
            edit_line(settlement){
                this.edit_line_id = settlement.id;
                this.name = settlement.name;
            },
            show_line(){
                this.edit_line_id = "";
                this.name = "";
            },
            save(){
                var self = this;
                self.loader_show();

                API.patch("/address/settlement/" + this.edit_line_id + "/", {name: this.name})
                    .then(function (response) {
                        self.load_settlements(self.district);
                        self.show_line();
                        self.errors = {};
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })

            },
            update_settlements(district){
                if(district === this.district) {
                    this.load_settlements(this.district);
                }
            }
        },
        watch: {
            district(){
                this.load_settlements(this.district);
                this.show_line();
            }
        }
    }
</script>

<style scoped>

</style>