<template>
    <popup :settings="settings">
        <div slot="title">Добавление IP-pool</div>
        <div slot="content">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="add-ippool">
                        <form action="" @submit.prevent="save()" class="add-ippool__form">
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                                    <fieldset class="add-ippool__form--item">
                                        <input type="text" v-model="form.network" placeholder="Network IP">
                                        <form-error v-bind:value="errors.network || errors.host_min"></form-error>
                                    </fieldset>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                    <fieldset class="add-ippool__form--item">
                                        <input type="text" v-model="form.netmask" placeholder="Netmask">
                                        <form-error v-bind:value="errors.netmask"></form-error>
                                    </fieldset>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <fieldset class="add-ippool__form--item">
                                        <textarea name="desc" v-model="form.note" placeholder="Описание IP-pool network"></textarea>
                                        <form-error v-bind:value="errors.note"></form-error>
                                    </fieldset>
                                </div>
                            </div>
                        </form>
                        <pool-calc :network="form.network" :netmask="form.netmask" @set_errors="set_errors" ></pool-calc>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer">
            <a href="#" @click="popup_close">Отмена</a>
            <a href="#" @click="save()" class="modal__btn">Добавить</a>
        </div>
    </popup>
</template>

<script>
    import Popup from '../../modal/popup.vue';
    import {CrudMixin} from "../../../../../mixins/admin/crud/crud_base";
    import FormErrors from '../../form/errors.vue';
    import PoolCalc from "./sub_components/pool-calc.vue";
    import {API} from "../../../../../api";

    export default {
        name: "add-pool",
        props: ["settings", "crud"],
        mixins: [CrudMixin],
        data(){
            return {
                form: {
                    network: "",
                    netmask: 0,
                    note: "",
                },
                errors: {}
            }
        },
        components: {
            "popup": Popup,
            "form-error": FormErrors,
            PoolCalc
        },
        methods: {
            save() {
                var self = this;
                self.errors = {};

                API.post('/ip-address/ip-pool/', this.form)
                    .then(function (response) {
                        self.$emit('update_pools');
                        self.popup_close();
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            },
            set_errors(errors){
                this.errors = errors;
            }
        }
    }
</script>

<style scoped>

</style>