<template>
    <div v-show="settings.visible">
        <div class="modal-full">
            <div class="modal">
                <div class="modal__overlay" @click="popup_close"></div>
                <div class="modal__body">
                    <span class="modal__close" @click="popup_close"></span>
                    <div class="modal__header">
                        <div class="modal__title">
                            {{ settings.title }}
                        </div>
                    </div>
                    <div class="modal__content">
                        <div class="c-modal-alert c-modal-alert_green">
                            <span class="c-modal-alert__icon"><i class="fal fa-smile-wink"></i></span>
                            <span class="c-modal-alert__title">Все круто! </span>
                            <span class="c-modal-alert__text">{{ settings.text }}</span>
                        </div>
                    </div>
                    <div class="modal__footer">
                        <div class="btn-group">
                            <span class="btn btn_green" @click="popup_close">
                                <span class="btn__icon"><i class="fal fa-check"></i></span>
                                <span class="btn__title">Ознакомлен</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'popup',
        props: ["settings"],
        methods: {
            popup_close() {
                this.settings.visible = false;
            }
        }
    }
</script>

<style scoped>

</style>