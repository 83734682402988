<template>
    <section class="page">
        <div class="page__inner">
            <div class="container-width">
                <!--HEADER PAGE-->
                <div class="page__header">
                    <h1 class="page__title">Отчет по абонентам</h1>
                    <report-menu :active_name="'reports-page'"></report-menu>
                </div>
                <!--END HEADER PAGE-->
                <!-- BODY PAGE -->
                <div class="page__body">
                    <div class="table-box">
                        <div class="table-box__header">
                            <div class="table-box__header-block">
                                <div class="table-filter">
                                    <year-filter v-model="year" :years="years"></year-filter>
                                    <div class="table-filter__select">
                                        <select v-model="connection_type" required>
                                            <option value="all">Все</option>
                                            <option v-for="connection_type in connection_types"
                                                    v-bind:value="connection_type.id">
                                                {{ connection_type.label }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <table class="table-box__table">
                            <thead class="table-box__thead">
                            <tr class="table-box__thead-tr">
                                <template v-for="col in cols">
                                    <th @click="set_ordering(col.field)" class="table-box__th">
                                        <span class="table-box__thead-item table-box__thead-item_center">
                                            <span class="table-box__thead-title">
                                                {{ col.label }}
                                                <i class="fal" :class="[get_class_ordering_direction(col.field)]"></i>
                                            </span>
                                        </span>
                                    </th>
                                </template>
                            </tr>
                            </thead>
                            <tbody class="table-box__tbody">
                            <tr class="table-box__tr table-data" v-for="subscriber in subscribers">
                                <td class="table-box__td table-box__td_center">
                                    <span class="table-data__text">
                                        {{ subscriber.department_name }}
                                    </span>
                                </td>
                                <td class="table-box__td table-box__td_center">
                                    <report-link :item="subscriber" :field_name="'total'" :link_params="getLinkParams"></report-link>
                                </td>
<!--                                <td class="table-box__td table-box__td_center">-->
<!--                                    <report-link :item="subscriber" :field_name="'archived'"></report-link>-->
<!--                                </td>-->
                                <td class="table-box__td table-box__td_center">
                                    <report-link :item="subscriber" :field_name="'blocked'" :link_params="getLinkParams"></report-link>
                                </td>
<!--                                <td class="table-box__td table-box__td_center">-->
<!--                                    <report-link :item="subscriber" :field_name="'frozen'"></report-link>-->
<!--                                </td>-->
<!--                                <td class="table-box__td table-box__td_center">-->
<!--                                    <report-link :item="subscriber" :field_name="'rip'"></report-link>-->
<!--                                </td>-->
                                <td class="table-box__td table-box__td_center">
                                    <report-link :item="subscriber" :field_name="'total_created'" :link_params="getLinkParams"></report-link>
                                </td>
                                <td class="table-box__td table-box__td_center"
                                    v-for="month in [1,2,3,4,5,6,7,8,9,10,11,12]">
                                    <report-link :item="subscriber" :field_name="'month_' + month" :link_params="getLinkParams"></report-link>
                                </td>
                            </tr>
                            <tr class="table-box__tr table-data" v-if="Object.keys(total).length > 0">
                                <td class="table-box__td">
                                    <span class="table-data__text table-data__text_bold">
                                        Итого:
                                    </span>
                                </td>
                                <td class="table-box__td table-box__td_center">
                                    <report-link :item="total" :field_name="'total'" :link_params="getLinkParams"></report-link>
                                </td>
<!--                                <td class="table-box__td table-box__td_center">-->
<!--                                    <report-link :item="total" :field_name="'archived'" ></report-link>-->
<!--                                </td>-->
                                <td class="table-box__td table-box__td_center">
                                    <report-link :item="total" :field_name="'blocked'" :link_params="getLinkParams"></report-link>
                                </td>
<!--                                <td class="table-box__td table-box__td_center">-->
<!--                                    <report-link :item="total" :field_name="'frozen'"></report-link>-->
<!--                                </td>-->
<!--                                <td class="table-box__td table-box__td_center">-->
<!--                                    <report-link :item="total" :field_name="'rip'"></report-link>-->
<!--                                </td>-->
                                <td class="table-box__td table-box__td_center">
                                     <report-link :item="total" :field_name="'total_created'" :link_params="getLinkParams"></report-link>
                                </td>
                                <td class="table-box__td table-box__td_center" v-for="month in [1,2,3,4,5,6,7,8,9,10,11,12]">
                                     <report-link :item="total" :field_name="'month_' + month" :link_params="getLinkParams"></report-link>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- END BODY PAGE -->
            </div>
        </div>
    </section>
</template>

<script>
    import YearFilter from './sub_components/year_filter';
    import ReportMenu from './menu/menu.vue';
    import ReportLink from "./sub_components/report-link";
    import {API} from "../../../../api";
    import {OrderMixin} from "../../../../mixins/admin/order";
    import {RouteHelperMixin} from "../../../../mixins/admin/route_helper";
    import {ConnectionTypeMixin} from "../../../../mixins/admin/connection_type";

    export default {
        name: "Report-subscribers",
        components: {
            ReportLink,
            "year-filter": YearFilter,
            "report-menu": ReportMenu
        },
        data() {
            return {
                cols: [
                    {
                        field: 'department_name',
                        label: 'Отдел'
                    },
                    {
                        field: 'total',
                        label: 'Всего'
                    },
                    // {
                    //     field: 'archived',
                    //     label: 'В Архиве'
                    // },
                    {
                        field: 'blocked',
                        label: 'Заблок.'
                    },
                    // {
                    //     field: 'frozen',
                    //     label: 'Заморожено'
                    // },
                    // {
                    //     field: 'rip',
                    //     label: 'RIP'
                    // },
                    {
                        field: 'total_created',
                        label: 'Всего подкл.'
                    },
                    {
                        field: 'month_1',
                        label: 'Январь'
                    },
                    {
                        field: 'month_2',
                        label: 'Февраль'
                    },
                    {
                        field: 'month_3',
                        label: 'Март'
                    },
                    {
                        field: 'month_4',
                        label: 'Апрель'
                    },
                    {
                        field: 'month_5',
                        label: 'Май'
                    },
                    {
                        field: 'month_6',
                        label: 'Июнь'
                    },
                    {
                        field: 'month_7',
                        label: 'Июль'
                    },
                    {
                        field: 'month_8',
                        label: 'Август'
                    },
                    {
                        field: 'month_9',
                        label: 'Сентябрь'
                    },
                    {
                        field: 'month_10',
                        label: 'Октябрь'
                    },
                    {
                        field: 'month_11',
                        label: 'Ноябрь'
                    },
                    {
                        field: 'month_12',
                        label: 'Декабрь'
                    },
                ],
                connection_type: "all",
                total: [],
                year: this.$moment().year(),
                ordering: "department_name",
                ordering_direction: 'ASC',
                years: [],
                subscribers: [],
                errors: []
            }
        },
        created() {
            this.set_data_from_route([
                {'name': 'ordering_direction', 'type': 'str'},
                {'name': 'connection_type', 'type': 'str'},
                {'name': 'year', 'type': 'int'},
                {'name': 'ordering', 'type': 'str'}], this);

            this.loader_show();
            this.load_years();
            this.load_subscribers();
            this.load_connection_type();
        },
        mixins: [OrderMixin, RouteHelperMixin, ConnectionTypeMixin],
        computed: {
          getLinkParams() {
            let self = this;
            if(self.connection_type && self.connection_type != "all") {
              return '&connection_type=' + self.connection_type
            }
            return ""
          }
        },
        methods: {
            load_subscribers() {
                var self = this;
                var filter = {
                    year: this.year,
                    ordering: this.ordering_direction === "ASC" ? this.ordering : "-" + this.ordering
                };
                if (this.connection_type) {
                    filter["connection_type"] = this.connection_type;
                }

                API.get('/report/subscriber/', filter)
                    .then(function (response) {
                        self.subscribers = response.data.results;
                        self.total = response.data.summary_report;

                        // Update router
                        self.$router.replace({
                            query: Object.assign({}, self.$route.query, {
                                year: self.year,
                                ordering: self.ordering,
                                ordering_direction: self.ordering_direction,
                                connection_type: self.connection_type
                            }),
                        });
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            },
            load_years() {
                var self = this;

                API.get('/report/subscriber/years/')
                    .then(function (response) {
                        self.years = response.data;
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            },
        },
        watch: {
            year(value) {
                this.load_subscribers();
            },
            ordering(value) {
                this.load_subscribers();
            },
            ordering_direction(value) {
                this.load_subscribers();
            },
            connection_type(value) {
                this.load_subscribers();
            },
        }
    }
</script>

<style scoped>

</style>