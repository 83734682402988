<template>
    <div class="tariff-regions-view">
        <div class="view__headline">
            <div class="view__headline-title">
                <span class="view__headline-icon"><i class="fal fa-map-marked-alt"></i></span>
                <span class="view__headline-text">Покрытие</span>
            </div>
        </div>
        <div class="tariff-regions-view__item" v-for="item in tariff.settlements_tree_expand">
            <div class="tariff-regions-view__label">
                <div class="tariff-regions-view__label-icon"><i class="fal fa-map"></i></div>
                <!--TODO В заголовке вывести Область/Район населенных пунктов-->
                <div class="tariff-regions-view__label-title">{{ item.region.name }} область, {{ item.district.name }} район</div>
            </div>
            <div class="tariff-regions-view__list">
                <div class="tr-list-view">
                    <div class="tr-list-view__row">
                        <div class="tr-list-view__item" v-for="settlement in item.settlements">
                            <span class="tr-list-view__item-icon"><i class="fal fa-map-marker-alt"></i></span>
                            <span class="tr-list-view__item-name">{{ settlement.name }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "regions-view",
        props: ["tariff"]
    }
</script>

<style scoped>

</style>