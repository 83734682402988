import Vue from "vue";
import {API_URL} from './config';
import {loader} from "./models/loader";

export const API = {
    base_url: '/api/v1',
    access_token: '',
    get: function (url, params) {
        return API.request(url, {}, params, 'GET');
    },
    get_recursive: function (url, params, list) {
        loader.active = false;
        API.request(url, {}, params, 'GET').then(function (response) {
            loader.active = true;
            list.push(...response.data.results);
            if(response.data.next){
                API.get_recursive(response.data.next, {}, list);
            }else{

            }
        })

    },
    post: function (url, data, params) {
        return API.request(url, data, params, 'POST');
    },
    put: function (url, data, params) {
        return API.request(url, data, params, 'PUT');
    },
    patch: function (url, data, params) {
        return API.request(url, data, params, 'PATCH');
    },
    delete: function (url, data, params) {
        return API.request(url, data, params, 'DELETE');
    },
    request: function (url, data, params, method, responseType='json') {
        var headers = {};
        if(this.access_token) {
            headers['Authorization'] = 'JWT ' + this.access_token;
        }

        return Vue.axios({
            url: this.get_url(url),
            method: method,
            params: params, // the URL parameters to be sent with the request
            data: data, // the data to be sent as the request body
            headers: headers,
            responseType: responseType
        })
    },
    get_url: function (url) {
        // Starts with http/https
        if(url.startsWith("http://") || url.startsWith("https://")){
            return url;
        }
        return API_URL+API.base_url + url;
    },
    default_catch: function(self, error){
        var errors = this.get_errors(error);
        self.errors = this.convert_errors(errors);
    },
    get_errors: function(error){
        var errors;

        if (error.response && error.response.data) {
            errors = error.response.data.errors;
        } else {
            errors = error;
        }

        return errors
    },
    convert_errors: function(errors){
        var i, errors_dict = {}, field, error_list;

        for(i in errors){
            field = errors[i]['field'];
            error_list = errors[i]['errors'];
            errors_dict[field] = error_list;
        }
        return errors_dict
    },
    default_always: function (self) {
        self.loader_hide();
    }
};
