<template>
    <form class="form-modal">
        <div class="form-modal__group">
            <fieldset class="form-modal__item">
                <span class="form-modal__title">
                        <span class="form-modal__icon"><i class="fal fa-map-marked-alt"></i></span>
                         Районы
                </span>
                <div class="form-modal__select">
                    <select v-model="district" required>
                        <option :value="undefined">Выберите район</option>
                        <option v-for="item in city_districts" :value="item.id">{{ item.name }}</option>
                    </select>
                </div>
                <form-error v-bind:value="errors.district"></form-error>
            </fieldset>
        </div>
        <div class="form-modal__group">
            <fieldset class="form-modal__item">
                        <span class="form-modal__title">
                        <span class="form-modal__icon"><i class="fal fa-map-marker-alt"></i></span>
                        Название населенного пункта
                        </span>
                        <input type="text" v-model="name" class="form-modal__input" placeholder="Введите название населенного пункта">
                        <form-error v-bind:value="errors.name"></form-error>
            </fieldset>
        </div>
        <div v-if="success">Населенный пункт успешно добавлен</div>
        <div class="form-modal__group form-modal__group_right">
            <div class="btn-group">
                <span class="btn btn_blue" @click="save()">
                    <span class="btn__icon"><i class="fal fa-plus"></i></span>
                    <span class="btn__title">Добавить</span>
                </span>
            </div>
        </div>
    </form>
</template>

<script>
    import {API} from "../../../../../../api";
    import FormErrors from '../../../form/errors.vue';

    export default {
        name: "city-add",
        props: ['city_districts', "city_district"],
        components: {
            "form-error": FormErrors,
        },
        data(){
            return {
                district: undefined,
                name: "",
                success: false,
                errors: {}
            }
        },
        methods: {
            save(){
                var self = this;
                self.loader_show();
                self.success = false;

                API.post("/address/settlement/", {name: this.name, district: this.district})
                    .then(function (response) {
                        self.name = "";
                        self.errors = {};
                        self.success = true;
                        self.$emit("update_settlements", self.district);
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })

            }
        }
    }
</script>

<style scoped>

</style>