import {API} from "../../api";

export var NasMixin = {
    data: function () {
        return {
            nas: [],
            nas_item: {},
        }
    },
    methods: {
        load_nas: function (obj) {
            var self = this;
            API.get('/nas/')
                .then(function (response) {
                    self.nas = response.data.results;
                })
                .catch(function (error) {
                    // API.default_catch(self, error)
                })
                .then(function () {
                    // API.default_always(self);
                })
        },
        get_nas: function (id) {
            var self = this;
            API.get('/nas/'+id+'/')
                .then(function (response) {
                    self.nas_item = response.data;
                })
                .catch(function (error) {
                    // API.default_catch(self, error)
                })
                .then(function () {
                    // API.default_always(self);
                })
        },
        load_nas_by_department_employee_permissions: function (obj) {
            var self = this;
            API.get('/nases-by-department-employee-permissions/')
                .then(function (response) {
                    self.nas = response.data.results;
                })
                .catch(function (error) {
                    // API.default_catch(self, error)
                })
                .then(function () {
                    // API.default_always(self);
                })
        }
    }
};
