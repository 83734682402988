<template>
    <popup :settings="settings">
        <div slot="title">Сбросить ТВ</div>
        <div slot="content">
            <div class="body-size">
                <div class="body-size__480">
                    <div class="modal-inform modal-inform_red">
                        <div class="modal-inform__title">Сброс параметров услуги (ТВ)</div>
                        <div class="modal-inform__desc">
                            Вы собираетесь сбросить параметры услуги <b>TВ</b> по-умолчанию. <br>
                            В случаи согласия будут сброшины данные о пакете и характеристиках.
                        </div>
                    </div>
                    <div class="modal-divide modal-divide_min">
                        <div class="modal-divide__title">
                            <span class="modal-divide__title-icon">
                                <i class="fal fa-database"></i>
                            </span>Данные для сброса
                        </div>
                    </div>
                    <div class="view-modal">
                        <div class="view-modal__item">
                            <span class="view-modal__title">
                                <span class="view-modal__icon"><i class="fal fa-briefcase"></i></span>
                                    Пакет:
                            </span>
                            <span class="view-modal__value">
                               ТВ+
                            </span>
                        </div>
                        <div class="view-modal__item">
                            <span class="view-modal__title">
                                <span class="view-modal__icon"><i class="fal fa-cog"></i></span>
                                       Характеристики:
                            </span>
                            <span class="view-modal__value view-modal__value_bold">
                                150 каналов
                            </span>
                        </div>
                        <div class="view-modal__item">
                            <span class="view-modal__title">
                                <span class="view-modal__icon"><i class="fal fa-phone-laptop"></i></span>
                                       Кол-во устройств:
                            </span>
                            <span class="view-modal__value view-modal__value_bold">
                                2/3
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="btn-group">
                <span class="btn btn_grey" @click="popup_close">
                    <span class="btn__icon"><i class="fal fa-times-circle"></i></span>
                    <span class="btn__title">Я передумал</span>
                </span>
                <span class="btn btn_red">
                    <span class="btn__icon"><i class="fal fa-check"></i></span>
                    <span class="btn__title">Сбросить</span>
                </span>
            </div>
        </div>
    </popup>
</template>

<script>
    import Popup from '../../../../modal/popup.vue';
    import FormErrors from '../../../../form/errors.vue';
    import {CrudMixin} from "../../../../../../../mixins/admin/crud/crud_base";

    export default {
        name: "tv-clear",
        props: ["settings"],
        components: {
            "popup": Popup,
            "form-error": FormErrors,
        },
        mixins: [
            CrudMixin,
        ],
    }
</script>

<style scoped>

</style>