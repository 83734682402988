<template>
  <section class="page">
    <div class="page__inner">
      <div class="container-width">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div class="page-header">
              <div class="row">
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                  <h1 class="page-header__title"> Магазины</h1>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                  <DropDown></DropDown>
                </div>
              </div>
            </div>
          </div>


          <!-- CONTENT PAGE -->
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div class="page-body">
              <!--FILTER BLOCK PAGE-->
              <FilterBlock @resetComponentFilters="resetComponentFilters">
                <filter-by-field v-model="agent"
                                 title="Агента"
                                 view="fio"
                                 :objects="agents"
                                 :add-social-tariff="false"/>
              </FilterBlock>
              <!--END FILTER BLOCK PAGE-->
              <div class="table-container">
                <table>
                  <thead>
                  <tr>
                    <th>#</th>
                    <th>Название</th>
                    <th>Создан/Обновлен</th>
                    <th>Агент</th>
                    <th>Адрес</th>
                    <th>Контакты</th>
                    <th>Примечания</th>
                    <th>Действия</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(shop, index) in shops">
                    <td>{{ index + 1 }}</td>
                    <td><a href="#" class="link">{{ shop.name }}</a></td>
                    <td>
                      <field-created-modify :obj="shop"></field-created-modify>
                    </td>
                    <td>{{ shop.agent_fio }}</td>
                    <td>{{ get_address(shop) }}</td>
                    <td>{{ shop.contact_name }}<br>{{ shop.contact_phone }}</td>
                    <td>{{ shop.note }}</td>
                    <td>
                      <div class="btn-group">
                        <router-link title="Список партий"
                                     :to="{ name: 'unicards-shops-list', params: {'shop_id': shop.id}}">
                          <span class="btn-l blue"><i class="fal fa-stream"></i></span>
                        </router-link>
                        <router-link title="Редактировать магазин"
                                     :to="{ name: 'unicards-shops-edit', params: {'id': shop.id}}">
                          <span class="btn-l orange"><i class="fal fa-edit"></i></span>
                        </router-link>
                        <router-link title="Передать на магазин"
                                     :to="{ name: 'unicards-consignment-new-shop', query: {'shop_id': shop.id}}">
                          <span class="btn-l indigo"><i class="fal fa-exchange"></i></span>
                        </router-link>
                        <router-link title="Продажи магазина"
                                     :to="{ name: 'unicards-shops-sales', params:{'id': shop.id}}">
                          <span class="btn-l green"><i class="fal fa-hryvnia"></i></span>
                        </router-link>
                        <app-remove-btn :perms="'unicards_super_access'" ></app-remove-btn>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <!--PAGINATION BLOCK-->
              <Pagination @loadItems="fetchUser"
                          :options="limitOptions"
                          :limit-items="limit"
                          :page-items="page"
                          :count-items="count"
                          :add-null-option="false"/>
              <!--END PAGINATION BLOCK-->
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import {User} from "../../../../../mixins/admin/user";
import {ShopsMixin} from "../../../../../mixins/admin/shops";
import {PermissionMixin} from "../../../../../mixins/admin/permission";
import FieldCreatedModify from "../sub_components/field_created_modify";
import DropDown from "../dropdown/dropdown";
import {RouteHelperMixin} from "../../../../../mixins/admin/route_helper";
import {PaginationMixin} from "../../../../../mixins/admin/pagination";
import {FilterHelperMixin} from "../../../../../mixins/admin/filter_helper";
import FilterBlock from "../../filter-block/filter-block";
import Filter from "../../filter-block/sub_components/filter";
import Pagination from "../../pagination/pagination";

export default {
  name: "shops-list",
  mixins: [User, ShopsMixin, PermissionMixin, RouteHelperMixin, PaginationMixin, FilterHelperMixin],
  components: {
    FieldCreatedModify,
    DropDown,
    FilterBlock,
    'filter-by-field': Filter,
    Pagination
  },
  data() {
    return {
      shops: [],
      // options for pagination block
      limitOptions: [15, 25, 50, 100],
      limit: 15,
      page: 1,
      count: 0,
      // filter options for department filter
      agent: undefined,
      agents: [],
    }
  },
  created() {
    this.pagination_init(this.page, this.limit);
  },
  mounted() {
    this.loadFilterOptions();
    this.fetchUser()
  },
  methods: {
    resetComponentFilters() {
      this.resetFilters();
      this.fetchUser();
    },
    loadFilterOptions() {
      this.loadAgentWithShops();
    },
    fetchUser() {
      var self = this;
        var params = Object.assign({}, self.$route.query,
            {
              page: !self.$route.query.page ? self.page : self.$route.query.page,
              limit: !self.$route.query.limit ? self.limit : self.$route.query.limit,
              offset: !self.$route.query.offset ? self.get_offset(self.page) : self.$route.query.offset,
            });

        self.$set(params, 'agent', self.agent)

        this.load_shops(params);

        // update department filter
        self.updateRouterQuery('agent', self.agents, self.agent);
    },
    get_address(shop) {
      return shop.region + ", " + shop.district + ", " + shop.street + " " + shop.house_number;
    },
  },
  watch: {
    // watcher for filter department
    agent(value) {
      if (value && value.length > 0) {
        this.fetchUser();
      }
    },
  }
}
</script>

<style scoped>

</style>