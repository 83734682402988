<template>
    <div class="tariffs-services__body">
        <div class="services">
            <div class="table-box">
                <table class="table-box__table">
                    <thead class="table-box__thead">
                    <tr class="table-box__thead-tr">
                        <th class="table-box__th">
                        <span class="table-box__thead-item table-box__thead-item_center">
                            <span class="table-box__thead-icon"><i class="fal fa-toolbox"></i></span>
                            <span class="table-box__thead-title">Название услуги</span>
                        </span>
                        </th>
                        <th class="table-box__th">
                        <span class="table-box__thead-item table-box__thead-item_center">
                            <span class="table-box__thead-icon"><i class="fal fa-briefcase"></i></span>
                            <span class="table-box__thead-title">Пакет</span>
                        </span>
                        </th>
                        <th class="table-box__th">
                        <span class="table-box__thead-item table-box__thead-item_center">
                            <span class="table-box__thead-icon"><i class="fal fa-cog"></i></span>
                            <span class="table-box__thead-title">Характеристики</span>
                        </span>
                        </th>
                        <th class="table-box__th">
                        <span class="table-box__thead-item table-box__thead-item_center">
                            <span class="table-box__thead-icon"><i class="fal fa-calendar-alt"></i></span>
                            <span class="table-box__thead-title">Тарификация</span>
                        </span>
                        </th>
                        <th class="table-box__th">
                        <span class="table-box__thead-item table-box__thead-item_center">
                            <span class="table-box__thead-icon"><i class="fal fa-check-double"></i></span>
                            <span class="table-box__thead-title">Статус</span>
                        </span>
                        </th>
                        <th class="table-box__th">
                        <span class="table-box__thead-item table-box__thead-item_center">
                            <span class="table-box__thead-icon"><i class="fal fa-mouse-pointer"></i></span>
                            <span class="table-box__thead-title">Действия</span>
                        </span>
                        </th>
                    </tr>
                    </thead>
                    <tbody class="table-box__tbody">

                    <tr class="table-box__tr table-data" v-for="(service, index) in tariff.services">
                        <td class="table-box__td">
                            <span class="table-data__text">{{ service.object_name }}</span>
                        </td>
                        <!--ИМЯ ПАКЕТА-->
                        <td class="table-box__td table-box__td_center">
                            <span class="table-data__text" v-if="service.object_type === 'service_iptv_triniti'" >
                                {{ service.service_extra_fields.service_iptv_triniti_expand.name }}
                            </span>
                            <span class="table-data__text" v-if="service.object_type === 'service_ip_address'" >
                                {{ service.service_extra_fields.service_ip_address_expand.name }}
                            </span>
                        </td>
                        <!--ХАРАКТЕРИСТИКИ-->
                        <td class="table-box__td table-box__td_center">
                            <span class="table-data__text" v-if="service.object_type === 'service_iptv_triniti'">
                                {{ service.service_extra_fields.service_iptv_triniti_expand.note }}
                            </span>
                            <span class="table-data__text" v-if="service.object_type === 'service_ip_address'">
                                {{ service.service_extra_fields.service_ip_address_expand.note }}
                            </span>
                        </td>
                        <td class="table-box__td table-box__td_center">
                            <div class="table-data__price">
                                Входят в тариф
                            </div>
                        </td>
                        <td class="table-box__td table-box__td_center">
                            <span v-if="service.is_active" class="table-data__status table-data__status_active">
                                Активно
                            </span>
                            <span v-else class="table-data__status table-data__status_deactive">
                                Неактивно
                            </span>
                        </td>

                        <td class="table-box__td table-box__td_right">
                            <div class="btn-group">
                                <div class="btn-switch">
                                    <input type="checkbox" v-model="service.is_active"
                                           @change="change_is_active(service, index)"
                                           class="btn-switch__input"
                                           id="service-status">
                                    <label class="btn-switch__for" for="service-status"></label>
                                </div>

                                <app-remove-btn :perms="'catalog_additional_services_personal_delete,catalog_additional_services_service_delete'" @action="popup_del_services(service)" ></app-remove-btn>
<!--                                <span class="btn-l btn-l_delete" @click="popup_del_services(service)">-->
<!--                                    <span class="btn-l__icon"><i class="fal fa-trash-alt"></i></span>-->
<!--                                </span>-->


                            </div>
                        </td>
                    </tr>

                    </tbody>
                </table>
            </div>
            <!--TODO Сообщние выводиться при условии если не одна услуга не подключена-->
            <div class="services__inform" v-if="show_not_connected_services_info">
                <span class="services__inform-icon"><i class="fal fa-meh"></i></span>
                <span class="services__inform-title">Услуги еще подключены</span>
            </div>

            <!--Действие "Добавить услугу"-->
            <div class="services__action">
                <app-has-perm-action-btn :perms="'personal_services_management_add_edit'"
                                          @action="popup_add_services">
                     <template slot-scope="{ access }">

                         <div class="services__add" >
                            <span :class="access ? 'services__add-icon' : 'btn-l_grey'" ><i class="fal fa-plus"></i></span>
                            <span class="services__add-title">Подключить услугу</span>!!!
                        </div>

                     </template>
                 </app-has-perm-action-btn>
            </div>

            <!--Модельное окно добавление услуги-->
            <add-services :settings="add_services"
                          :tariff_services="tariff_services"
                          :tariff="tariff"
                          @added_service="added_service"
            ></add-services>
            <!--Модельное окно отключение услуги-->
            <del-services :settings="del_services"
                          :service="delete_service"
                          @deleted_service="deleted_service"
            ></del-services>
        </div>
    </div>
</template>

<script>
    import {CrudMixin} from "../../../../../mixins/admin/crud/crud_base";
    import {CrudListMixin} from "../../../../../mixins/admin/crud/crud_list";
    import AddServices from "./modal/add-services";
    import DelServices from "./modal/del-services"
    import {API} from "../../../../../api";

    export default {
        name: "services-list",
        mixins: [CrudMixin, CrudListMixin],
        props: ['tariff'],
        data() {
            return {
                add_services: {
                    visible: false
                },
                del_services: {
                    visible: false
                },
                tariff_services: [],
                errors: {},
                delete_service: {},
                show_not_connected_services_info: true,
            }
        },
        components: {
            "add-services": AddServices,
            "del-services": DelServices,
        },
        methods: {
            load_subscriber_services() {
                var self = this;

                API.get('/tariffs/' + this.tariff.id + '/services/')
                    .then(function (response) {
                        self.tariff_services = response.data;
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            },
            change_is_active(service, index) {
                if (service.service_type === "service_ip_address") {
                    this.change_ip_address_is_active(service, index);
                }
            },
            added_service(value) {
                this.$emit("added_service", value);
            },
            deleted_service(value) {
                this.$emit("deleted_service", value);
            },
            popup_add_services() {
                this.add_services.visible = true;
                this.crud.errors = {};
            },
            popup_del_services(service) {
                this.del_services.visible = true;
                this.delete_service = service;
                this.crud.errors = {};
            },
        },
        watch: {
            'tariff.services':  function (value){
                if(value.length !== 0) {
                   this.show_not_connected_services_info = false;
                }
            }
        }
    }
</script>

<style scoped>

</style>