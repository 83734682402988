<template>
     <popup :settings="settings">
        <div slot="title">Настройка IP адрес</div>
        <div slot="content">
            <div class="body-size">
                <div class="body-size__480">
                    <form class="form-modal">
                        <div class="form-modal__group">
                            <fieldset class="form-modal__item">
                                <span class="form-modal__title">
                                <span class="form-modal__icon"><i class="fal fa-box"></i></span>
                                        Название пакета
                                </span>
                                <div class="form-modal__select">
                                    <select v-model="new_service_ip_address" required>
                                        <option v-for="service_ip_address in ip_address_service_list"
                                                :value="service_ip_address">{{ service_ip_address.service_name }}</option>
                                    </select>
                                </div>
                                <form-error v-bind:value="errors.new_service_ip_address"></form-error>
                            </fieldset>
                        </div>
                         <div class="form-modal__group">
                            <fieldset class="form-modal__item">
                                <span class="form-modal__title">
                                <span class="form-modal__icon"><i class="fal fa-cog"></i></span>
                                        IP-адресс
                                </span>
                                <div class="form-modal__select">
                                    <select v-model="new_ip_address_id" required>
                                        <option v-for="ip_address in ip_address_list"
                                                :value="ip_address.id">{{ ip_address.ip_address }}</option>
                                    </select>
                                </div>
                                <form-error v-bind:value="errors.new_ip_address"></form-error>
                            </fieldset>
                        </div>
                        <div class="form-service" v-if="Object.keys(new_service_ip_address).length && new_service_ip_address">
                            <div class="form-service__total">
                                <div class="form-service__total-title">Стоимость:</div>
                                <div class="form-service__total-price">
                                    <span class="form-service__summ">
                                        <span class="form-service__summ-currency">&#x20b4</span>
                                        <span class="form-service__summ-value">{{ new_service_ip_address.charging_amount_month }}</span>
                                        <span class="form-service__summ-period">/мес</span>
                                    </span>
                                    <span class="form-service__summ-divide"></span>
                                    <span class="form-service__summ" v-if="new_service_ip_address.charging_amount_day">
                                        <span class="form-service__summ-currency">&#x20b4</span>
                                        <span class="form-service__summ-value">{{ new_service_ip_address.charging_amount_day }}</span>
                                        <span class="form-service__summ-period">/день</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="btn-group">
                <span class="btn btn_grey" @click="popup_close">
                    <span class="btn__icon"><i class="fal fa-times-circle"></i></span>
                    <span class="btn__title">Я передумал</span>
                </span>
                <span class="btn btn_blue" @click="save">
                    <span class="btn__icon"><i class="fal fa-check"></i></span>
                    <span class="btn__title">Сохранить</span>
                </span>
            </div>
        </div>
    </popup>
</template>

<script>
    import Popup from '../../../../modal/popup.vue';
    import FormErrors from '../../../../form/errors.vue';
    import {CrudMixin} from "../../../../../../../mixins/admin/crud/crud_base";
    import {API} from "../../../../../../../api";

    export default {
        name: "ip-address-settings",
        props: ["settings", "subscriber_services", "service_ip_address"],
        components: {
            "popup": Popup,
            "form-error": FormErrors,
        },
         mixins: [
            CrudMixin,
        ],
        data() {
            return {
                new_ip_address_id: "",
                new_service_ip_address: {},
                ip_address_list: [],
                ip_address_service_list: [],
                errors: [],
            }
        },
        methods: {
            save() {
                this.set_new_service_ip_address();
            },
            initIpAddressServiceList() {
                // filter from prop "subscriber_services.available_service_list",
                // service where service_type = service_ip_address
                if (this.subscriber_services.available_service_list &&
                    this.subscriber_services.available_service_list.length > 0) {
                    this.ip_address_service_list = this.subscriber_services.available_service_list.filter(
                        elm => (elm.service_type === 'service_ip_address')
                    );
                }
            },
            set_new_service_ip_address() {
                var self = this;

                API.post('/services/ip-address-subscriber-connection-set/', {
                    ip_address_connection: self.service_ip_address.service_object_id,
                    new_ip_address: self.new_ip_address_id,
                    new_service_ip_address: self.new_service_ip_address.id,
                })
                    .then(function (response) {
                        self.$emit('update_subscriber_connection');
                        self.popup_close();
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            },
            load_ip_address() {
                var self = this;

                API.get('/ip-address/ip-address/', {destination: 'for_rent', ordering: 'ip_address'})
                    .then(function (response) {
                        self.ip_address_list = response.data.results;
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            },
        },
        watch: {
            'settings.visible': function (value) {
                // load ip address which are in_rent now
                if (value) {
                    this.load_ip_address();
                }
            },
            'subscriber_services.available_service_list': function (value) {
                if (value) {
                    this.initIpAddressServiceList();
                }

            }
        }
    }
</script>

<style scoped>

</style>