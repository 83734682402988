<template>
    <div class="tariff-regions__body">
        <div v-for="(tariff_region, index) in tariff_regions" class="tariff-regions__item">
            <div class="tariff-regions__selected">
               <div class="tr-filter">
                   <div class="tr-filter__item">
                       <div class="tr-filter__label">
                           <span class="tr-filter__label-icon"><i class="fal fa-globe-europe"></i></span>
                           <span class="tr-filter__label-title">Выберите регион </span>
                       </div>
                       <div class="tr-filter__select">
                           <select v-model="tariff_region.region" required @change="change_tariff_region__region(index)" >
                               <option :value="null" disabled hidden>Регионы</option>
                               <option v-for="item in regions" v-bind:value="item.id">{{ item.name }}</option>
                           </select>
                       </div>
                   </div>
                   <div class="tr-filter__item">
                       <div class="tr-filter__label">
                           <span class="tr-filter__label-icon"><i class="fal fa-map-marked-alt"></i></span>
                           <span class="tr-filter__label-title">Выберите район</span>
                       </div>
                       <div class="tr-filter__select">
                           <select v-model="tariff_region.district" required @change="change_tariff_region__district(index)">
                               <option :value="null" disabled hidden>Районы</option>
                               <option v-if="check_unique_district(index, item.id)" v-for="item in tariff_region.districts"
                                        v-bind:value="item.id">
                                    {{ item.name }}
                                </option>
                           </select>
                       </div>
                   </div>

                   <div class="tr-filter__action" v-if="is_last_tariff_region(index)">
                        <div class="tr-filter__btn" @click="add_tariff_region">
                            <span class="tr-filter__btn-icon"><i class="fal fa-plus"></i></span>
                            <span class="tr-filter__btn-title">Добавить регион</span>
                        </div>
                   </div>

                   <div class="tr-filter__action" v-else>
                        <div class="tr-filter__btn tr-filter__btn_remove" @click="remove_tariff_region(index)">
                            <span class="tr-filter__btn-icon"><i class="fal fa-minus-circle"></i></span>
                            <span class="tr-filter__btn-title">Удалить регион</span>
                        </div>
                   </div>

               </div>
            </div>
            <div class="tariff-regions__list">
                <div class="tr-list">
                    <div class="tr-list__header">
                        <div class="tr-list__label">
                            <span class="tr-list__label-icon"><i class="fal fa-map-marker-alt"></i></span>
                            <span class="tr-list__label-title">Населенные пункты: <span class="tr-list__label-title_selected">{{ get_tariff_region_fullname(index) }}</span></span>
                        </div>
                        <checker :item="index" :tariff_region="tariff_region" @select_all="select_all"></checker>
                    </div>
                    <div class="tr-list__row">
                        <div class="tr-list__item" v-for="item in tariff_region.settlements">
                            <label class="tr-list__checkbox">
                                <input type="checkbox" v-model="tariff_settlements" :value="item.id" >
                                <span class="tr-list__checkmark"></span>
                                <span class="tr-list__name">{{ item.name }}</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import {loader} from "../../../../../models/loader";
    import {API} from "../../../../../api";
    import {AddressMixin} from "../../../../../mixins/admin/address";
    import Checker from './checker'

    export default {
        name: "regions-filter",
        props: ["item"],
        mixins: [AddressMixin],
        components: {
          'checker': Checker,
        },
        data(){
            return {
                regions: [],
                tariff_settlements: [],
                tariff_regions: []
            }
        },
        mounted() {
            this.fetchUser()
        },
        methods: {
            fetchUser() {
                this.load_regions();

                if(this.item.settlements_tree_expand !== undefined) {
                    this.set_tariff_regions();
                }

                // Add default tariff_regions
                if(this.tariff_regions.length === 0){
                    this.add_tariff_region();
                }
            },
            set_tariff_regions(){
                var index = 0, settlements = [];
                this.tariff_regions = [];

                for(var settlements_tree of this.item.settlements_tree_expand) {
                    settlements = [];
                    for(var settlement of settlements_tree.settlements){
                        settlements.push(settlement.id)
                    }
                    this.tariff_settlements = this.tariff_settlements.concat(settlements);

                    this.tariff_regions.push({
                        region: settlements_tree.region.id,
                        district: settlements_tree.district.id,
                        districts: settlements_tree.districts,
                        settlements: settlements
                    });

                    this.load_settlements(index);
                    index++;
                }
            },
            add_tariff_region(){
                this.tariff_regions.push({region: null, district: null, districts:[], settlements:[]});
            },
            remove_tariff_region(index){
                for(var item of this.tariff_regions[index].settlements) {
                    // Remove
                    var i = this.tariff_settlements.indexOf(item.id);
                    if(i !== -1) {
                        this.tariff_settlements.splice(i, 1);
                    }
                }

                this.tariff_regions.splice(index, 1);
            },
            check_unique_district(index, district_id){
                var tariff_region;

                for(var i in this.tariff_regions){
                    tariff_region = this.tariff_regions[i];
                    if(tariff_region.district === district_id && parseInt(index) !== parseInt(i)){
                        return false;
                    }
                }
                return true;
            },
            get_tariff_region_fullname(index){
                var name = "";
                for(var region of this.regions){
                    if(region.id === this.tariff_regions[index].region){
                        name = region.name + " область";
                    }
                }
                for(var district of this.tariff_regions[index].districts){
                    if(district.id === this.tariff_regions[index].district){
                        name += " / " + district.name + " р-н";
                    }
                }

                return name;
            },
            is_last_tariff_region(index){
                return index + 1 === this.tariff_regions.length;
            },
            select_all(index, select_all_check){
                for(var item of this.tariff_regions[index].settlements) {
                    if(select_all_check) {
                        // Add
                        if (this.tariff_settlements.indexOf(item.id) === -1) {
                            this.tariff_settlements.push(item.id);
                        }
                    }else{
                        // Remove
                        var i = this.tariff_settlements.indexOf(item.id);
                        if(i !== -1) {
                            this.tariff_settlements.splice(i, 1);
                        }
                    }
                }
            },
            remove_tariff_region_settlements(index){
              this.tariff_regions[index].settlements = [];
            },
            change_tariff_region__region(index){
                this.load_districts(index);
                this.select_all(index);
                this.remove_tariff_region_settlements(index);
            },
            change_tariff_region__district(index){
                this.load_settlements(index);
                this.select_all(index);
            },
            load_settlements: function (index) {
                var self = this;
                if(this.tariff_regions[index].district === ""){
                    return
                }
                loader.active = false;
                API.get('/address/settlement/', {district: this.tariff_regions[index].district})
                    .then(function (response) {
                        self.tariff_regions[index].settlements = response.data.results;
                        loader.active = true;
                    })
            },
            load_districts: function (index) {
                var self = this;
                if(this.tariff_regions[index].region === ""){
                    return
                }
                loader.active = false;
                API.get('/address/district/', {region: this.tariff_regions[index].region})
                    .then(function (response) {
                        self.tariff_regions[index].districts = response.data.results;
                        loader.active = true;
                    })
            },
        },
        watch: {
            tariff_settlements(){
                this.$emit("set_settlements", this.tariff_settlements)
            }
        }

    }
</script>

<style scoped>

</style>