import {API} from "../../api";

export var ConstanceMixin = {
    data: function () {
        return {
            constance: [],
        }
    },
    methods: {
        load_constance: function (filter) {
            var self = this;
            filter = filter || {};

            API.get('/constance/', filter)
                .then(function (response) {
                    self.constance = response.data.results;
                })
                .catch(function (error) {
                    API.default_catch(self, error)
                })
                .then(function () {
                    API.default_always(self);
                })
        },
        get_constance_item: function(id) {
            for(var i in this.constance){
                if(this.constance[i]['id'] === id){
                    return this.constance[i];
                }
            }
            return undefined
        },
        change_constance: function (id, data) {
            return API.patch('/constance/'+id+'/', data)
        },
    }
};
