<template>
    <section class="page">
        <div class="page__inner">
            <div class="container-width">
                <!--HEADER PAGE-->
                <div class="page__header">
                    <h1 class="page__title">Должности</h1>
                    <div class="btn-group">
                        <div class="page__group-btn">
                            <span class="btn btn_grey">
                                    <span class="btn__icon"><i class="fal fa-archive"></i></span>
                                    <span class="btn__title">Архив</span>
                            </span>
                            <app-link :perms="'catalog_employee_positions_add_edit'"
                                      :title="'Добавление должности'"
                                      :to="{ name: 'employees-position-add' }">
                                <template slot-scope="{ access }">
                                    <span class="btn" :class="[access ? 'btn_green' : 'btn_grey']">
                                        <span class="btn__icon"><i class="fal fa-plus"></i></span>
                                        <span class="btn__title">Добавить должность</span>
                                    </span>
                                </template>
                            </app-link>
                        </div>
                    </div>
                </div>
                <!--END HEADER PAGE-->
                 <!--BODY PAGE-->
                <div class="page__body">
                    <div class="table-box">
                        <table class="table-box__table">
                            <thead class="table-box__thead">
                            <tr class="table-box__thead-tr">
                                <th class="table-box__th">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-industry-alt"></i></span>
                                        <span class="table-box__thead-title"> Должности</span>
                                    </span>
                                </th>
                                <th class="table-box__th">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-user-tie"></i></span>
                                        <span class="table-box__thead-title"> Кол-во сотрудников</span>
                                    </span>
                                </th>
                                <th class="table-box__th">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-comments-alt"></i></span>
                                        <span class="table-box__thead-title"> Примечание</span>
                                    </span>
                                </th>
                                <th class="table-box__th">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-mouse-pointer"></i></span>
                                        <span class="table-box__thead-title"> Действия</span>
                                    </span>
                                </th>
                            </tr>
                            </thead>
                            <tbody class="table-box__tbody">
                            <tr class="table-box__tr table-data" v-for="(item, index) in crud.data.list">
                                <!--Должность-->
                                <td class="table-box__td">
                                    <span class="table-data__text">
                                        {{ item.name }}
                                    </span>
                                </td>
                                <!--Кол-во сотрудников-->
                                <td class="table-box__td table-box__td_center">
                                    <span class="table-data__text">
                                        {{ item.count_employees }}
                                    </span>
                                </td>
                                <!--Примечания-->
                                <td class="table-box__td">
                                    <span class="table-data__text">
                                        {{ item.description }}
                                    </span>
                                </td>
                                <!--Действия-->
                                <td class="table-box__td table-box__td_right">
                                    <div class="btn-group">
                                        <!--View-->
                                        <router-link :to="{ name: 'employees-position-view', params: {'id': item.id}}">
                                            <span class="btn-l btn-l_blue">
                                                <span class="btn-l__icon"><i class="fal fa-eye"></i></span>
                                            </span>
                                        </router-link>
                                        <!--Edit-->
                                        <app-link :perms="'catalog_employee_positions_add_edit'"
                                                  :title="'Редактирование должности'"
                                                  :to="{ name: 'employees-position-edit', params: {'id': item.id}}">
                                            <template slot-scope="{ access }">
                                                <span class="btn-l" :class="[access ? 'btn-l_orange' : 'btn-l_grey']">
                                                    <span class="btn-l__icon"><i class="fal fa-edit"></i></span>
                                                </span>
                                            </template>
                                        </app-link>
                                        <!--Remove to arhive-->
                                        <app-remove-btn :perms="'catalog_employee_positions_delete'" ></app-remove-btn>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!--END BODY PAGE-->
            </div>
        </div>
    </section>
</template>

<script>
    import {CrudMixin} from "../../../../../mixins/admin/crud/crud_base";
    import {CrudListMixin} from "../../../../../mixins/admin/crud/crud_list";

    export default {
        name: "position",
        mixins: [CrudMixin, CrudListMixin],
        data: function () {
            return {
                crud: {
                    api: '/employee-positions/',
                }
            }
        },
        mounted: function () {
            this.crud_get_list()
        },
        methods: {}
    }
</script>

<style scoped>

</style>