<template>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="page-body">
            <div class="view-data-reports">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="view-data-reports__header">
                            <div class="title">{{ department.name }}</div>
                            <div class="desc">За период: {{ date_from | moment("DD.MM.YYYY") }} - {{ date_to | moment("DD.MM.YYYY") }}</div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6" v-if="results.recharged_by_yours_cards && Object.keys(results.recharged_by_yours_cards).length">
                        <departments-report-recharge :title="'Вашими картами'" :data="results.recharged_by_yours_cards"></departments-report-recharge>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6" v-if="results.recharged_by_other_cards && Object.keys(results.recharged_by_other_cards).length">
                        <departments-report-recharge :title="'Не вашими картами'" :data="results.recharged_by_other_cards"></departments-report-recharge>
                    </div>

                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6" v-if="results.manual_refill.yours_subscribers && Object.keys(results.manual_refill.yours_subscribers).length">
                        <departments-report-refill :title="'Ручные пополнение'" :data="results.manual_refill.yours_subscribers"></departments-report-refill>
                    </div>

                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6" v-if="results.set_balance.yours_subscribers && Object.keys(results.set_balance.yours_subscribers).length">
                        <departments-report-refill :title="'SET пополнение'" :data="results.set_balance.yours_subscribers"></departments-report-refill>
                    </div>

                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6" v-if="results.ucl_sold_unicards && Object.keys(results.ucl_sold_unicards).length">
                        <div class="view-data-reports__item">
                            <div class="view-data-reports__item-head"><i class="fal fa-server"></i> Unicard
                                <span class="explanation">Продано карт</span></div>
                            <ul class="view-data-reports__item-list">
                                <li>Продано UNICARD: <span class="value"><router-link :to="generate_link(results.ucl_sold_unicards.link_object_type, results.ucl_sold_unicards.link_params, {from_url: current_url()})" >{{ results.ucl_sold_unicards.count_sold }} шт.</router-link></span></li>
                                <li>Сумма: <span class="value">{{ results.ucl_sold_unicards.total_amount }}грн</span></li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <departments-report-total :results="results" ></departments-report-total>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DepartmentsReportRecharge from "./departments-report-recharge";
    import DepartmentsReportTotal from "./departments-report-total";
    import {LinksMixin} from "../../../../../mixins/admin/links";
    import DepartmentsReportRefill from "./departments-report-refill";
    export default {
        name: "departments_results",
        components: {DepartmentsReportRefill, DepartmentsReportTotal, DepartmentsReportRecharge},
        mixins: [LinksMixin],
        props: ['department', 'date_from', 'date_to', 'results'],
    }
</script>

<style scoped>

</style>