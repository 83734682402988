<template>
    <popup :settings="settings">
        <div slot="title">Управление IP адресом</div>
        <div slot="content">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                     <div class="add-ippool">
                        <div class="add-ippool__view">
                            <ul>
                                <li>Host: <span class="value"> {{crud.data.item.ip_address}}</span></li>
                            </ul>
                        </div>
                        <form action="" @submit.prevent="save()" class="add-ippool__form">
                            <fieldset class="add-ippool__form--item">
                                <template v-if="is_destination_rent()">
                                    {{ get_destination_label() }}
                                </template>
                                <template v-else>
                                    <div class="selected">
                                        <select v-model="destination" required>
                                            <option value="" hidden>Выберите назначение</option>
                                            <option v-for="item in destinations_filtered" v-bind:value="item.key">{{ item.label }}</option>
                                        </select>
                                    </div>
                                </template>
                                <form-error v-bind:value="errors.destination"></form-error>
                            </fieldset>
                            <fieldset class="add-ippool__form--item">
                                <textarea name="desc" v-model="note" placeholder="Примечание"></textarea>
                                <form-error v-bind:value="errors.note"></form-error>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer">
            <a href="#" @click="popup_close">Отмена</a>
            <a href="#" @click="save()" class="modal__btn">Сохранить</a>
        </div>
    </popup>
</template>

<script>
    import Popup from '../modal/popup.vue';
    import {CrudMixin} from "../../../../mixins/admin/crud/crud_base";
    import FormErrors from '../form/errors.vue';
    import {API} from "../../../../api";
    import {IpAddressMixin} from "../../../../mixins/admin/ip_address";

    export default {
        name: "edit",
        props: ["settings", "crud", "destinations"],
        mixins: [CrudMixin, IpAddressMixin],
        data(){
            return {
                destination: "",
                note: "",
                errors: {}
            }
        },
        components: {
            "popup": Popup,
            "form-error": FormErrors,
        },
        methods: {
            save(){
                var self = this;
                self.errors = {};
                var form = {note: this.note};
                if(!this.is_destination_rent()){
                    form['destination'] = this.destination;
                }

                API.patch('/ip-address/ip-address/'+this.crud.data.item.id+'/', form)
                    .then(function (response) {
                        self.$emit('updateIp', response.data);
                        self.popup_close();
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            }
        }
    }
</script>

<style scoped>

</style>