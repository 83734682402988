/* User model */
import {API} from "../api";
import {UserModel} from "../models/user";


export var UserBase = {
    data: function () {
        return {
            user: UserModel.data
        }
    },
    methods: {
        set_user_by_data: function (data) {
            // Set user data
            for(var key in data) {
                UserModel.data[key] = data[key];
            }
            UserModel.data.is_auth = true;

            if(this.$set !== undefined) {
                this.$set(this, 'user', UserModel.data)
            }
        },
        get_user: async function () {
            if(!UserModel.data.id){
                await this.set_user();
            }
            return UserModel.data
        },
        set_user: async function () {
            var self = this;

            try {
                let response = await API.get('/user/current/');
                self.set_user_by_data(response.data);
            } catch(err) {
                console.log("Logout");
                self.logout();
            }

            // API.get('/user/current/')
            //     .then(function (response) {
            //         self.set_user_by_data(response.data);
            //     })
            //     .catch(function (error) {
            //         self.logout();
            //     })
        },
        is_auth: function () {
            return this.user.is_auth;
        },
        is_superuser: function () {
            return UserModel.data.is_superuser;
        },
        is_staff: function () {
            return UserModel.data.is_staff;
        },
        is_business(){
            return this.user.subscriber_type_expand.name === 'business';
        },
        is_private(){
            return this.user.subscriber_type_expand.name === 'private';
        },
        has_perm: function (permissions_input) {
            /*
            *   permissions_input = 'common_permission' or
            *   permissions_input = {'permissions': 'common_permission', 'comparison': 'AND'}
            *   return inverse result
            */
            var user = UserModel.data;

            var has_perm = false;
            var permissions, comparison = "AND";
            // console.log(user)

            if(typeof permissions_input === 'object') {
                if (Object.keys(permissions_input).includes('permissions')) {
                    permissions = permissions_input.permissions.split(",");
                }

                if (Object.keys(permissions_input).includes('comparison')) {
                    comparison = permissions_input.comparison;
                }

                if (Object.keys(permissions_input).includes('user')) {
                    user = permissions_input.user;
                }
            } else {
                permissions = permissions_input.split(",");
            }

            if(user && user.employee_permissions && permissions) {

                if(user.is_superuser) {
                    has_perm = true;
                    return has_perm;
                }

                if (comparison === 'AND') {
                    has_perm = true;

                    for (var i in permissions) {
                        if (!user.employee_permissions.includes(permissions[i])) {
                            has_perm = false;
                        }
                    }
                } else if (comparison === 'OR') {
                    has_perm = false;

                    for (var i in permissions) {

                        // console.log(i);
                        // console.log(permissions[i]);
                        // console.log(user.employee_permissions.includes(permissions[i]));

                        if (user.employee_permissions.includes(permissions[i])) {
                            has_perm = true;
                            break
                        }
                    }
                }
            }

            // console.log(user.employee_permissions)
            // console.log("permissions = ", permissions, " = ", has_perm)
            return has_perm;
        },
        has_perm_department: function (department_id, perm) {
            /*
                perm - str, is_department_access, is_report_subscriber, is_report_department_full,
                       is_report_department_partial, is_reporting_by_default
            */
            var user = UserModel.data;

            if (user.is_superuser) { return true }

            if(user && user.department_permissions_expand) {
                for(let department_permissions of user.department_permissions_expand){

                    if(department_permissions.department === department_id){
                        return department_permissions[perm]
                    }
                }
            }
            return false;
        },
        has_perm_departments: function (departments_input, permissions_input) {
            /*
                Check if one of permissions is true
                departments_input - str, departments. If Empty - all departments
                permissions_input - str, is_department_access, is_report_subscriber, is_report_department_full,
                       is_report_department_partial, is_reporting_by_default

            */
            var has_perm = false;
            var user = UserModel.data;
            // console.log(user);
            var permissions = permissions_input.split(",");
            // var departments = departments_input.split(",");

            if(user && user.department_permissions_expand) {
                if(user.is_superuser) {
                    has_perm = true;
                    return has_perm;
                }

                for(let department_permissions of user.department_permissions_expand){

                    // TODO should understand what this code is doing ????
                    // if(departments){
                    //     if(departments.indexOf(department_permissions.department) < 0){
                    //         continue
                    //     }
                    // }

                    for(let perm of permissions) {
                        // console.log(perm);
                        if (department_permissions[perm]) {
                            has_perm = true;
                        }
                    }
                }
            }

            return has_perm;
        }
    },
    watch: {
        user: {
            handler(value) {
                /* User loaded */
                if(typeof this.fetchUser === 'function') {
                    this.fetchUser();
                }
            },
            deep: true
        }
    }
};
