<template>
    <section class="page">
        <div class="page__inner">
            <div class="container-width">
                <!--HEADER PAGE-->
                <div class="page__header">
                    <h1 class="page__title">Телевидение</h1>
                    <div class="btn-group">
                        <span class="btn btn_grey">
                            <span class="btn__icon"><i class="fal fa-archive"></i></span>
                            <span class="btn__title">Архив</span>
                        </span>

                        <app-has-perm-action-btn :perms="'personal_services_management_add_edit'" @action="popup_add">
                            <template slot-scope="{ access }">
                                <span class="btn" :class="access ? 'btn_green' : 'btn_grey'">
                                    <span class="btn__icon"><i class="fal fa-plus"></i></span>
                                    <span class="btn__title">Добавить пакет</span>
                                </span>
                            </template>
                        </app-has-perm-action-btn>

                        <app-link :perms="'personal_services_management_add_edit'"
                                  :title="'Тарифы Trinity'"
                                  :to="{ name: 'tariff-triniti' }">
                            <template slot-scope="{ access }">
                                <span class="btn" :class="[access ? 'btn_blue' : 'btn_grey']">
                                    <span class="btn__icon"><i class="fal fa-plus"></i></span>
                                    <span class="btn__title">Тарифы Trinity</span>
                                </span>
                            </template>
                        </app-link>

                    </div>
                </div>
                <!--END HEADER PAGE-->
                <!--BODY PAGE-->
                <div class="page__body">
                    <div class="table-box">
                        <table class="table-box__table">
                            <thead class="table-box__thead">
                            <tr class="table-box__thead-tr">
                                <th class="table-box__th">
                                    <span class="table-box__thead-item">
                                        <span class="table-box__thead-icon"><i class="fal fa-box"></i></span>
                                        <span class="table-box__thead-title">Название пакета</span>
                                    </span>
                                </th>
                                <th class="table-box__th">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-users-crown"></i></span>
                                        <span class="table-box__thead-title">Тариф Trinity</span>
                                    </span>
                                </th>
                                <th class="table-box__th">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-users-crown"></i></span>
                                        <span class="table-box__thead-title">Тип абонента</span>
                                    </span>
                                </th>
                                <th class="table-box__th">
                                     <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-code-branch"></i></span>
                                        <span class="table-box__thead-title">Отдел</span>
                                    </span>
                                </th>
                                <th class="table-box__th">
                                     <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-ethernet"></i></span>
                                        <span class="table-box__thead-title">Технология</span>
                                    </span>
                                </th>
                                <th class="table-box__th">
                                     <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-ethernet"></i></span>
                                        <span class="table-box__thead-title">Группа</span>
                                    </span>
                                </th>
                                <th class="table-box__th">
                                     <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-calendar-alt"></i></span>
                                        <span class="table-box__thead-title">Тарификация</span>
                                    </span>
                                </th>
                                <th class="table-box__th">
                                     <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-tv-retro"></i></span>
                                        <span class="table-box__thead-title">Кол-во каналов</span>
                                    </span>
                                </th>
                                <th class="table-box__th">
                                     <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-phone-laptop"></i></span>
                                        <span class="table-box__thead-title">Кол-во устройств</span>
                                    </span>
                                </th>
                                <th class="table-box__th">
                                     <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-chart-area"></i></span>
                                        <span class="table-box__thead-title">Статистика</span>
                                    </span>
                                </th>
                                <th class="table-box__th">
                                     <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-comment-lines"></i></span>
                                        <span class="table-box__thead-title">Примечания</span>
                                    </span>
                                </th>
                                <th class="table-box__th">
                                     <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-mouse-pointer"></i></span>
                                        <span class="table-box__thead-title">Действия</span>
                                    </span>
                                </th>
                            </tr>
                            </thead>
                            <tbody class="table-box__tbody">
                            <tr v-for="(tv_service, index) in crud.data.list" class="table-box__tr table-data">
                                <!--Название пакета-->
                                <td class="table-box__td">
                                    <span class="table-data__text">{{ tv_service.name }}</span>
                                </td>
                                <td class="table-box__td">
                                    <span class="table-data__text">{{ tv_service.triniti_tariff_expand.name }}</span>
                                </td>
                                <!--Тип абонента-->
                                <td class="table-box__td table-box__td_center">
                                    <SubscriberType :item_service="tv_service"></SubscriberType>
                                </td>
                                <!--Отдел-->
                                <td class="table-box__td table-box__td_center">
                                    <Department :item_service="tv_service"></Department>
                                </td>
                                <!--Технология-->
                                <td class="table-box__td table-box__td_center">
                                    <ConnectionType :item_service="tv_service"></ConnectionType>
                                </td>
                                <!--Группа-->
                                <td class="table-box__td table-box__td_center">
                                    <Group :item_service="tv_service"></Group>
                                </td>
                                <!--Тарификация-->
                                <td class="table-box__td table-box__td_center">
                                    <ChargingType :item_service="tv_service"></ChargingType>
                                </td>
                                <!--Кол-во каналов-->
                                <td class="table-box__td table-box__td_center">
                                    <span class="table-data__text">120 (5 HD)</span>
                                </td>
                                <!--Кол-во устройств-->
                                <td class="table-box__td table-box__td_center">
                                    <span class="table-data__text">{{ tv_service.count_devices }}</span>
                                </td>
                                <!--Статистика-->
                                <td class="table-box__td table-box__td_center">
                                    <span v-if="tv_service.count_subscriber_connections > 0">
                                        <span v-if="has_perm_count_subscriber_connections(tv_service)" class="table-data__link">
                                            <router-link :to="generate_link(tv_service.link_object_type, tv_service.count_subscriber_connections_params, {from_url: current_url()})" >
                                                {{ tv_service.count_subscriber_connections }}
                                            </router-link>
                                        </span>
                                        <span v-else>{{ tv_service.count_subscriber_connections }}</span>
                                    </span>
                                    <span v-else>-</span>
                                </td>
                                <!--Примечания-->
                                <td class="table-box__td table-box__td_center">
                                    <span class="table-data__text">{{ tv_service.note }}</span>
                                </td>
                                <!--Действия-->
                                <td class="table-box__td table-box__td_right">
                                    <div class="btn-group">

                                        <app-has-perm-action-btn :perms="'catalog_additional_services_personal_access'"
                                                              @action="popup_view(tv_service)">
                                            <template slot-scope="{ access }">
                                                <span class="btn-l" :class="access ? 'btn-l_blue' : 'btn-l_grey'" v-tooltip="TooltipText='Просмотр'">
                                                     <span class="btn-l__icon"><i class="fal fa-eye"></i></span>
                                                </span>
                                            </template>
                                        </app-has-perm-action-btn>


                                        <app-has-perm-action-btn :perms="'personal_services_management_add_edit'"
                                                              @action="popup_edit(index, tv_service)">
                                            <template slot-scope="{ access }">
                                                <span class="btn-l" :class="access ? 'btn-l_orange' : 'btn-l_grey'" v-tooltip="TooltipText='Редактирование'">
                                                     <span class="btn-l__icon"><i class="fal fa-edit"></i></span>
                                                </span>
                                            </template>
                                        </app-has-perm-action-btn>


                                        <span class="btn-l btn-l_delete">
                                            <app-remove-btn :perms="'catalog_additional_services_personal_delete'" ></app-remove-btn>
<!--                                            <span class="btn-l__icon"><i class="fal fa-trash-alt"></i></span>-->
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!--END BODY PAGE-->
            </div>
        </div>
        <!-- Add packege modal -->
        <add-package :settings="add_popup"
                     :crud="crud"
                     :iptv_triniti_charging_types="iptv_triniti_charging_types"
                     :iptv_triniti_tariffs="iptv_triniti_tariffs"
                     :subscriber_types="subscriber_types"
                     :connection_types="connection_types"
                     :departments="departments"
        ></add-package>
        <!-- Edit packege modal -->
        <edit-package :settings="edit_popup"
                      :crud="crud"
                      :iptv_triniti_charging_types="iptv_triniti_charging_types"
                      :iptv_triniti_tariffs="iptv_triniti_tariffs"
                      :subscriber_types="subscriber_types"
                      :connection_types="connection_types"
                      :departments="departments"
        ></edit-package>
        <!-- View packege modal -->
        <view-package :settings="view_popup"
                      :crud="crud"
        ></view-package>
    </section>
</template>

<script>
    import {CrudMixin} from "../../../../../mixins/admin/crud/crud_base";
    import {CrudListMixin} from "../../../../../mixins/admin/crud/crud_list";

    import AddPackage from './add-iptv-triniti.vue';
    import EditPackage from './edit-iptv-triniti.vue';
    import ViewPackage from './view-iptv-triniti.vue';
    import SubscriberType from "../components/subscriber_type";
    import Department from "../components/department";
    import ChargingType from "../components/charging_type";
    import ConnectionType from "../components/connection_type";
    import Group from "../components/group";

    import {LinksMixin} from "../../../../../mixins/admin/links";
    import {SubscriberTypeMixin} from "../../../../../mixins/admin/subscriber_type";
    import {ConnectionTypeMixin} from "../../../../../mixins/admin/connection_type";
    import {DepartmentMixin} from "../../../../../mixins/admin/department";
    import {TariffMixin} from "../../../../../mixins/admin/tariff";
    import {ServiceIPTVTrinitiMixin} from "../../../../../mixins/admin/service_iptv_triniti";
    import {ServiceListMixin} from "../../../../../mixins/admin/services_list";

    export default {
        name: "list-iptv-triniti",
        components: {
            "add-package": AddPackage,
            "edit-package": EditPackage,
            "view-package": ViewPackage,
            SubscriberType,
            Department,
            ChargingType,
            ConnectionType,
            Group
        },
        mixins: [CrudMixin, CrudListMixin, LinksMixin, SubscriberTypeMixin, ConnectionTypeMixin, DepartmentMixin, TariffMixin, ServiceIPTVTrinitiMixin, ServiceListMixin],
        data(){
            return {
                crud: {
                      api: '/services/iptv-triniti/',
                },
            }
        },
        mounted: function () {
            this.fetchUser();
            this.load_iptv_triniti_charging_types();
            this.load_iptv_triniti_tariffs();
            this.load_subscriber_types();
            this.load_connection_type();
            this.load_departments();
        },
        methods: {
            fetchUser() {
                var params = this.$route.query;
                this.crud_get_list(params);
            },
        },
    }
</script>

<style scoped>

</style>