import {API} from "../../api";

export var SubscriberConnectionMixin = {
    computed: {
        subscriber_connection_address_full: function () {
            if(!Object.keys(this.subscriber_connection).length ||
                this.subscriber_connection.settlement_expand === undefined ||
                this.subscriber_connection.settlement_expand === null){
                return "-"
            }
            let apartment_number =  this.subscriber_connection.apartment_number ? ", кв." + this.subscriber_connection.apartment_number : ""
            return this.subscriber_connection.settlement_expand.district_expand.region_expand.name + " обл., " +
                   this.subscriber_connection.settlement_expand.district_expand.name + " р-н, " +
                   this.subscriber_connection.settlement_expand.name + ", ул. " +
                   this.subscriber_connection.street_expand.name + ", д." +
                   this.subscriber_connection.house_number + apartment_number

        },
    },
    methods: {
        load_subscriber_connection: function (id) {
            var self = this;
            self.loader_show();

            API.get('/subscriber-connections/' + id + '/')
                .then(function (response) {
                    self.subscriber_connection = response.data;
                })
                .catch(function (error) {
                    // API.default_catch(self, error)
                })
                .then(function () {
                    API.default_always(self);
                })
        },
        edit_subscriber_connection: function (id, data) {
            this.loader_show();
            return API.patch('/subscriber-connections/' + id + '/', data)
        },
        bulk_edit_subscriber_connection: function (ids, data) {
            this.loader_show();
            var list_data = [];
            list_data.push(data);
            return API.patch('/subscriber-connections/bulk_update/?ids='+ids.join(), list_data)
        },
        add_subscriber_connection: function (data) {
            this.loader_show();
            return API.post('/subscriber-connections/', data)
        },
        set_balance: function (id, data) {
            return API.patch('/subscriber-connections/'+id+'/set_balance/', data)
        },
        charge_balance: function (id, data) {
            return API.patch('/subscriber-connections/'+id+'/charge_balance/', data)
        },
        charge_manual_bonus_balance: function (id, data) {
            return API.patch('/subscriber-connections/'+id+'/charge_manual_bonus_balance/', data)
        },
        reset_manual_bonus_balance: function (id, data) {
            return API.post('/subscriber-connections/'+id+'/reset_manual_bonus_balance/', data)
        },
        subscriber_connections_get_available_login: function () {
            return API.get('/subscriber-connections-get-available-login/')
        },
        set_tariff_adjustment: function (id, data) {
            return API.patch('/subscriber-connections/'+id+'/set_tariff_adjustment/', data)
        },
        reset_tariff_adjustment: function (id, data) {
            return API.post('/subscriber-connections/'+id+'/reset_tariff_adjustment/', data)
        },
        recalculate_days: function (id, data) {
            return API.post('/subscriber-connections/'+id+'/recalculate_days/', data)
        },
        reset_balance_delay_days: function (id, data) {
            return API.post('/subscriber-connections/'+id+'/reset_balance_delay_days/', data)
        },
        balance_credit: function (id, data) {
            return API.patch('/subscriber-connections/'+id+'/set_balance_delay_days/', data)
        },
        recharge_with_unicard: function (id, data) {
            return API.post('/subscriber-connections/'+id+'/recharge-with-unicard/', data)
        },
        subscriber_connections_transactions: function (filters) {
            return API.get('/subscriber-connections-transactions/', filters)
        },
        subscriber_connections_sessions: function (filters) {
            return API.get('/subscriber-connections-sessions/', filters)
        },
        subscriber_connections_operations: function (filters) {
            return API.get('/subscriber-connections-operations/', filters)
        },
    }
};
