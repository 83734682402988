<template>
    <div class="tariff-regions">
        <TurningComponent :checkboxProp="use_settlements"
                          :iconPropClass="'fal fa-map-marked-alt'"
                          @checkbox-changed="use_settlements = !use_settlements">
            <span slot="title">
                Привязать тариф к населенному пункту
            </span>
        </TurningComponent>
        <RegionsList v-if="use_settlements" :item="tariff" @set_settlements="set_settlements" ></RegionsList>
    </div>
</template>

<script>
    import RegionsList from "./regions-list";
    import TurningComponent from "../turning-component";

    export default {
        name: "regions",
        props: ['tariff'],
        components: {
            RegionsList,
            TurningComponent,
        },
        data() {
            return {
                use_settlements: false,
            }
        },
        methods: {
            set_settlements(settlements){
                this.$emit("set_settlements", settlements)
            }
        },
        watch: {
            tariff(value) {
                if (value.settlements_expand.length > 0) {
                    this.use_settlements = true;
                }
            }
        }
    }
</script>

<style scoped>

</style>