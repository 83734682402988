<template>
    <div>
        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <label><i class="fal fa-server"></i>Партия:
                        <span class="control"><i class="fas fa-star-of-life"></i></span></label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <div class="selected">
                        <select v-model="unicard_batch" required>

                            <!-- available_batch_series -->
                            <template v-if="available_batch_series">
                                <option value="" disabled hidden>Выберите партию:</option>
                                <option v-for="item in available_batch_series" v-bind:value="item.id">
                                    Серия: {{ item.unicard_set_expand.series }} | Номинал: {{
                                    item.unicard_set_expand.face_value }}грн | Диапазон: {{ item.number_from }}-{{
                                    item.number_to }} | Всего: {{ item.count_unicards }} | Остаток: {{
                                    item.count_available_unicards }}
                                </option>
                            </template>

                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <label>
                        Номинал: <b>{{ face_value }} грн</b>
                    </label>
                </div>

                <form-error v-bind:value="errors.unicard_batch || errors.unicard_set"></form-error>
            </div>
        </div>
        <available-intervals :intervals="get_available_intervals('main')"></available-intervals>
    </div>
</template>

<script>
    import {UnicardsMixin} from "../../../../../../mixins/admin/unicards";
    import FormErrors from '../../../form/errors.vue';
    import AvailableIntervals from './available-intervals';

    export default {
        name: "select_unicard_set",
        props: ["available_batch_series", "errors"],
        mixins: [UnicardsMixin],
        data() {
            return {
                type: 'main',
                face_value: 0,
                unicard_batch: "",
                unicards_intervals: []
            }
        },
        components: {
            "form-error": FormErrors,
            AvailableIntervals,
        },
        methods: {
            set_face_value() {
                for (var unicard_batch of this.available_batch_series) {
                    if (unicard_batch.id === this.unicard_batch) {
                        this.face_value = unicard_batch.unicard_set_expand.face_value;
                    }
                }

            },
            get_available_intervals(type) {
                return this.unicards_intervals.batch_unicards
            },
            emit() {
                this.set_face_value();
                this.$emit("update_unicard_batch", {
                    'unicard_batch': this.unicard_batch,
                });
            }
        },
        watch: {
            unicard_batch() {
                this.emit();
                this.get_unicard_intervals(this.unicard_batch)
            }
        }
    }
</script>

<style scoped>

</style>