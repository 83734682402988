<template>
    <popup :settings="settings">
        <div slot="title">Добавление отдела</div>
        <div slot="content">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="modal__content">
                    <!--<form action="" class="modal-form">
                        <div class="modal-form__item">
                            <label><i class="fal fa-code-branch"></i> Имя отдела:</label>
                            <input type="text" v-model="item.name" placeholder="Введите имя отдела">
                            <form-error v-bind:value="crud.errors.name"></form-error>
                        </div>
                    </form>-->
                    <form action="" class="modal-form">
                        <fieldset class="modal-form__item">
                            <p><i class="fal fa-code-branch"></i> Имя отдела:</p>
                            <input type="text" v-model="item.name" placeholder="Имя отдела">
                            <form-error v-bind:value="crud.errors.name" ></form-error>
                        </fieldset>
                        <fieldset class="modal-form__item">
                            <p><i class="fal fa-comment-exclamation"></i> Описание отдела:</p>
                            <textarea name="desc" v-model="item.description" placeholder="Описание отдела"></textarea>
                            <form-error v-bind:value="crud.errors.description" ></form-error>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
        <div slot="footer">
            <a href="#" class="modal__btn" @click.prevent="crud_create(item)" >Сохранить</a>
            <a href="#" @click="popup_close">Отмена</a>
        </div>
    </popup>
</template>

<script>
    import Popup from '../modal/popup.vue'
    import {CrudMixin} from "../../../../mixins/admin/crud/crud_base";
    import FormErrors from '../form/errors.vue';

    export default {
        name: "departments-add-popup",
        props: ["settings", "crud"],
        mixins: [
            CrudMixin
        ],
        data: function(){
            return {
                item: {
                    name: "",
                    description: ""
                }
            }
        },
        components: {
            "popup": Popup,
            "form-error": FormErrors
        },
    }
</script>

<style scoped>

</style>