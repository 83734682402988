<template>
  <div class="dashboard-main">
    <DashboardTopItem v-for="(blockItem, index) in blockItems"
                      :key="index" :block-item="blockItem" />
  </div>
</template>

<script>
import DashboardTopItem from "./dashboard-top-item";

export default {
  name: "dashboard-top",
  components: {
    DashboardTopItem,
  },
  data() {
    return {
      blockItems: [
        {
          title: 'ТОП-3 монтажника за неделю',
          iconClass: 'fal fa-trophy-alt',
          options: null,
          method: 'getTopInstallerEmployees',
        },
        {
          title: 'ТОП-3 менеджера за неделю',
          iconClass: 'fal fa-medal',
          options: null,
          method: 'getTopTechnicalSupportEmployees',
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>