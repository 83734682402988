<template>
  <section class="page">
    <div class="page__inner">
      <div class="container-width">
        <!--HEADER PAGE-->
        <div class="page__header">
          <h1 class="page__title">Типы подключения</h1>
          <div class="btn-group" v-has-perm="'catalog_connection_types_access'">
            <span class="btn btn_grey">
              <span class="btn__icon"><i class="fal fa-archive"></i></span>
              <span class="btn__title">Архив</span>
            </span>
            <app-has-perm-action-btn :perms="'catalog_connection_types_add_edit'"
                                      @action="popup_add">
                <template slot-scope="{ access }">
                    <span class="page-header__btn-group">
                        <span class="btn" :class="access ? 'green' : 'grey'">
                            <i class="fal fa-plus"></i> Добавить тип подключения
                        </span>
                    </span>
                </template>
            </app-has-perm-action-btn>
          </div>
        </div>
        <!--END HEADER PAGE-->
        <!--BODY PAGE-->
        <div class="page__body">
          <div class="table-box">
            <table class="table-box__table">
              <thead class="table-box__thead">
              <tr class="table-box__thead-tr">
                <!--Название типа  подключения-->
                <th class="table-box__th">
                  <span class="table-box__thead-item table-box__thead-item_center">
                    <span class="table-box__thead-icon"><i class="fal fa-ethernet"></i></span>
                    <span class="table-box__thead-title">Название подключения</span>
                  </span>
                </th>
                <th class="table-box__th">
                  <span class="table-box__thead-item table-box__thead-item_center">
                    <span class="table-box__thead-icon"><i class="fal fa-ethernet"></i></span>
                    <span class="table-box__thead-title">Название подключения (Украинский)</span>
                  </span>
                </th>
                 <!--Название типа  подключения-->
                <th class="table-box__th">
                  <span class="table-box__thead-item table-box__thead-item_center">
                    <span class="table-box__thead-icon"><i class="fal fa-ethernet"></i></span>
                    <span class="table-box__thead-title">Имя подключения</span>
                  </span>
                </th>
                <!--Описание-->
                <th class="table-box__th">
                  <span class="table-box__thead-item table-box__thead-item_center">
                    <span class="table-box__thead-icon"><i class="fal fa-comment-exclamation"></i></span>
                    <span class="table-box__thead-title">Описание</span>
                  </span>
                </th>
                <!--Действия-->
                <th class="table-box__th">
                  <span class="table-box__thead-item table-box__thead-item_center">
                    <span class="table-box__thead-icon"><i class="fal fa-mouse-pointer"></i></span>
                    <span class="table-box__thead-title">Действия</span>
                  </span>
                </th>
              </tr>
              </thead>
              <tbody class="table-box__tbody">
              <tr class="table-box__tr table-data" v-for="(item, index) in crud.data.list">
                <!--Название типа  подключения-->
                <td class="table-box__td">
                  <span class="table-data__text">
                    {{ item.label }}
                  </span>
                </td>
                <!--Название типа  подключения-->
                <td class="table-box__td">
                  <span class="table-data__text">
                    {{ item.label_uk }}
                  </span>
                </td>
                <!--Название типа  подключения-->
                <td class="table-box__td table-box__td_center">
                  <span class="table-data__text">
                    {{ item.name }}
                  </span>
                </td>
                <!--Описание-->
                <td class="table-box__td">
                  <span class="table-data__text">
                    {{ item.description }}
                  </span>
                </td>
                <!--Действия-->
                <td class="table-box__td table-box__td_right">
                  <div class="btn-group">
                    <!--Просмотр-->
                    <span v-tooltip="TooltipText='Просмотр'" class="btn-l btn-l_blue" @click="popup_view(item)">
                      <span class="btn-l__icon"><i class="fal fa-eye"></i></span>
                    </span>
                    <!--EDIT CONNECTION TYPES-->
                    <app-has-perm-action-btn :perms="'catalog_connection_types_add_edit'"
                                             @action="popup_edit(index, item)">
                        <template slot-scope="{ access }">
                            <span class="btn-l" :class="access ? 'orange' : 'grey'" v-tooltip="TooltipText='Редактирование'" >
                                <span class="btn-l__icon"><i class="fal fa-edit"></i></span>
                            </span>
                        </template>
                    </app-has-perm-action-btn>
                    <!--END EDIT CONNECTION TYPES-->
                    <!--DELETE CONNECTION TYPES-->
                    <app-remove-btn :perms="'catalog_connection_types_delete'" ></app-remove-btn>
                    <!--END DELETE CONNECTION TYPES-->


                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal ADD window -->
    <connection_types-add-popup :settings="add_popup" :crud="crud"></connection_types-add-popup>

    <!-- Modal Edit window -->
    <connection_types-edit-popup :settings="edit_popup" :crud="crud"></connection_types-edit-popup>

    <!-- Modal View window -->
    <connection_types-view-popup :settings="view_popup" :crud="crud"></connection_types-view-popup>

    <!-- Modal Delete window -->
    <connection_types-delete-popup :settings="delete_popup"></connection_types-delete-popup>
  </section>
</template>

<script>
import {CrudMixin} from "../../../../mixins/admin/crud/crud_base";
import {CrudListMixin} from "../../../../mixins/admin/crud/crud_list";

import ConnectionTypesAddPopup from './add.vue'
import ConnectionTypesEditPopup from './edit.vue'
import ConnectionTypesViewPopup from './view.vue'
import ConnectionTypesDeletePopup from './delete.vue'
import {User} from "../../../../mixins/admin/user";
import {SearchMixin} from "../../../../mixins/admin/search";

export default {
  components: {
    "connection_types-add-popup": ConnectionTypesAddPopup,
    "connection_types-edit-popup": ConnectionTypesEditPopup,
    "connection_types-view-popup": ConnectionTypesViewPopup,
    "connection_types-delete-popup": ConnectionTypesDeletePopup,
  },
  mixins: [User, CrudMixin, CrudListMixin, SearchMixin],
  data: function () {
    return {
      crud: {
        api: '/connection-type/',
      }
    }
  },
  methods: {
    fetchUser() {
      var params = this.$route.query;
      this.crud_get_list(params);
    }
  },
  created() {
    this.$root.$on('search_hit_connection_types', (function (data) {
      this.fetchUser()
    }).bind(this));

    this.$root.$on('search_clear_connection_types', (function (data) {
      this.fetchUser()
    }).bind(this));
  },
  beforeDestroy: function () {
    this.destroy_search_listeners("connection_types");
  },
  mounted() {
    this.fetchUser()
  }
}
</script>

<style>

</style>