<template>
    <div class="dropdown-groups">
        <div class="dropdown">
            <div class="dropdown__btn blue" @click="toggleDropdown($event)">
                <i class="fal fa-credit-card-front"></i> Unicards <span
                    class="dropdown__btn--icon"><i
                    class="fal fa-chevron-down"></i></span>
            </div>
            <div class="dropdown__wrapper">
                <ul>
                    <li>
                        <router-link title="Список Unicards"
                                     :to="{ name: 'unicards-list'}">
                            <i class="fal fa-stream"></i> Список Unicards
                        </router-link>
                    </li>
                    <li v-has-perm="'unicards_super_access'">
                        <router-link title="Сгенерировать карты"
                                     :to="{ name: 'unicards-generation'}">
                            <i class="fal fa-atom-alt"></i> Сгенерировать карты
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
        <div class="dropdown" v-has-perm="{'permissions': 'unicards_user_access,unicards_super_access', 'comparison': 'OR'}">
            <div class="dropdown__btn green" @click="toggleDropdown($event)">
                <i class="fal fa-box"></i> Партии <span
                    class="dropdown__btn--icon"><i
                    class="fal fa-chevron-down"></i></span>
            </div>
            <div class="dropdown__wrapper">
                <ul>
                    <li>
                        <router-link title="Основные партии"
                                     :to="{ name: 'unicards-consignment-main'}">
                            <i class="fal fa-stream"></i> Основные партии
                        </router-link>
                    </li>
                    <li>
                        <router-link title="Новая основная партия"
                                     :to="{ name: 'unicards-consignment-new-main'}">
                            <i class="fal fa-layer-plus"></i> Новая основная партия
                        </router-link>
                    </li>
                    <li>
                        <router-link title="Магазинные партии"
                                     :to="{ name: 'unicards-consignment-shop'}">
                            <i class="fal fa-stream"></i> Магазинные партии
                        </router-link>
                    </li>
                    <li>
                        <router-link title="Новая магазинная партия"
                                     :to="{ name: 'unicards-consignment-new-shop'}">
                            <i class="fal fa-layer-plus"></i> Новая магазинная партия
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
        <div class="dropdown" v-has-perm="{'permissions': 'unicards_user_access,unicards_super_access', 'comparison': 'OR'}">
            <div class="dropdown__btn orange" @click="toggleDropdown($event)">
                <i class="fal fa-store-alt"></i> Магазины <span
                    class="dropdown__btn--icon"><i
                    class="fal fa-chevron-down"></i></span>
            </div>
            <div class="dropdown__wrapper">
                <ul>
                    <li>
                        <router-link title="Магазины" :to="{ name: 'unicards-shops'}">
                            <i class="fal fa-stream"></i> Список магазинов
                        </router-link>
                    </li>
                    <li>
                        <router-link title="Добавить магазин"
                                     :to="{ name: 'unicards-shops-add'}">
                            <i class="fal fa-cart-plus"></i> Новый магазин
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
        <div v-has-perm="{'permissions': 'unicards_all_reports_access', 'comparison': 'OR'}">
            <router-link title="Отчеты" :to="{ name: 'unicards-reports'}">
                <div class="dropdown__btn indigo">
                    <i class="fal fa-chart-pie-alt"></i> Отчеты
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
    import {User} from "../../../../../mixins/admin/user";

    export default {
        name: "dropdown",
        mixins: [User],
        methods: {
            toggleDropdown(event) {
                event.currentTarget.classList.toggle('is-active')
            },
            fetchUser() {
                this.$forceUpdate();
            }
        }
    }


</script>

<style scoped>

</style>