<template>
    <div>
        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label>
                        <i class="fal fa-phone"></i> Телефон:
                        <span class="control"><i class="fas fa-star-of-life"></i></span>
                    </label>
                </div>
                <div class="col-xs-11 col-sm-11 col-md-5 col-lg-6">
                    <input type="text" v-model="phone_single" placeholder="Введите номер телефона +380(ХХ)ХХХХХХХ">
                </div>
                <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                    <span class="add-btn" @click.prevent="add_phone"><i class="fal fa-plus"></i></span>
                </div>
                <div class="clearfix"></div>
                <form-error v-bind:value="phone_errors.phone"></form-error>
            </div>
        </div>

        <template v-for="(phone_item, index) in phones_list">
            <div class="add-data__item">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                        <label>
                            <i class="fal fa-phone"></i> Телефон #{{ index + 1 }}:
                        </label>
                    </div>
                    <div class="col-xs-11 col-sm-11 col-md-5 col-lg-6">
                        <!--v-model="phones_list[phone_item]"-->
                        <input type="text" v-model="phones_list[index]" placeholder="Введите номер телефона +380(ХХ)ХХХХХХХ">
                    </div>
                    <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                        <span class="remove-btn" @click.prevent="remove_phone(index)"><i
                                class="fal fa-minus"></i></span>
                    </div>
                    <div class="clearfix"></div>
                    <form-error v-bind:value="phone_errors['phone_' + (index + 1)]"></form-error>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import FormErrors from '../form/errors.vue';

    export default {
        props: ['phone', 'phone_1', 'phone_2', 'phone_3', 'phone_4', 'phone_5', 'errors'],
        name: "phones-list",
        data() {
            return {
                phone_single: this.phone,
                phones_list: this.init_phone_list(),
                phone_errors: Object.assign({}, this.errors),
            }
        },
        computed: {},
        methods: {
            init_phone_list() {
                var source_list = [this.phone_1, this.phone_2, this.phone_3, this.phone_4, this.phone_5];
                var phones_list = [];
                for (var item in source_list) {
                    if (source_list[item] !== "" && source_list[item] !== undefined) {
                        phones_list.push(source_list[item])
                    }
                }
                return phones_list
            },
            add_phone() {
                if (this.phones_list.length < 5) {
                    this.phones_list.push("");
                    this.$delete(this.phone_errors, 'phone');
                } else {
                    this.$set(this.phone_errors, 'phone', ["Максимальное кол-во телфонных номеров"]);
                }
            },
            remove_phone(key) {
                this.phones_list.splice(key, 1);
                this.$delete(this.phone_errors, 'phone');
            },
            change_phone_in_list(index, value){
                if((value !== "") && (value !== undefined)) {
                    this.phones_list[index] = value;
                }
            }
        },
        components: {
            "form-error": FormErrors,
        },
        watch: {
            phone(value){
                this.phone_single = value;
            },
            phone_1(value){
                this.change_phone_in_list(0, value);
            },
            phone_2(value){
                this.change_phone_in_list(1, value);
            },
            phone_3(value){
                this.change_phone_in_list(2, value);
            },
            phone_4(value){
                this.change_phone_in_list(3, value);
            },
            phone_5(value){
                this.change_phone_in_list(4, value);
            },
            phone_single: function (value) {
                this.$emit("set_phone_single", value);
            },
            phones_list: function (value) {
                this.$emit("set_phones_list", value);
            },
            errors: function (value) {
                this.phone_errors = Object.assign({}, value);
            }
        }
    }
</script>

<style scoped>

</style>