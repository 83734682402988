<template>
    <accordion>
        <div slot="title-accordion"><i class="fal fa-info-circle"></i> Информация о партии</div>
        <div slot="wrapper-accordion">
            <div class="accordion__header"></div>
            <div class="table-container">
                <table>
                    <thead>
                    <tr>
                        <th>Время создания</th>
                        <th>Создатель</th>
                        <th>Получатель</th>
                        <th>Серия</th>
                        <th>Диапазон</th>
                        <th>Кол-во</th>
                        <th>Номинал</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{{ item.created_at| moment("DD.MM.YYYY HH:mm")  }}</td>
                        <td>
                            <template v-if="item.owner_from_expand">
                                {{ item.owner_from_expand.fio }}
                            </template>
                        </td>
                        <td>
                            <template v-if="item.owner_expand">
                                {{ item.owner_expand.fio }}
                            </template>
                        </td>
                        <td>
                            <template v-if="item.unicard_set_expand">
                                {{ item.unicard_set_expand.series }}
                            </template>
                        </td>
                        <td>{{ item.number_from_display }}-{{ item.number_to_display }}</td>
                        <td>{{ item.count_unicards }}</td>
                        <td>
                            <template v-if="item.unicard_set_expand">
                                {{ item.unicard_set_expand.face_value }} <i class="fal fa-hryvnia"></i>
                            </template>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </accordion>
</template>

<script>
    import accordion from "../accordion/accordion";
    export default {
        name: "main-info-list",
        props: ["unicard_batch", "shop_batch"],
        components: {
            accordion
        },
        data: function () {
            return {
                item: {},
                showSubAccordion: false,
            }
        },
        watch: {
            unicard_batch(){
                if(this.unicard_batch) {
                    this.item = this.unicard_batch
                }
            },
            shop_batch(){
                if(this.shop_batch) {
                    this.item = this.shop_batch.unicard_batch_expand;
                }
            },
        }
    }
</script>

<style scoped>

</style>