import Accordion from '../../templates/admin/components/accordion/accordion.vue';
import {DateRangePickerDefaults} from './date_range_picker_defaults';


export var SubscriberConnectionAccordionMixin = {
    components: {
        "accordion": Accordion,
    },
    mixins: [DateRangePickerDefaults],
    data: function () {
        return {
            showSubAccordion: false
        }
    },
};