<template>
    <section class="page">
        <div class="page__inner">
            <div class="container-width">
                <!--HEADER PAGE-->
                <div class="page__header">
                    <h1 class="page__title">NAS ({{ crud.data.item.name }})</h1>
                    <div class="btn-group">
                        <router-link title="Назад" :to="{ name: 'nas-list'}">
                            <span class="btn btn_grey">
                                <span class="btn__icon"><i class="fal fa-undo"></i></span>
                                <span class="btn__title">Вернуться</span>
                            </span>
                        </router-link>
                        <!-- TODO Добавить ссылку на редактирование текущего item + добавить права на просмотр кнопки -->
                        <router-link :to="{ name: 'nas-edit', params:{ id: crud.data.item.id }}">
                            <span class="btn btn_orange">
                                <span class="btn__icon btn__icon_nt"><i class="fal fa-edit"></i></span>
                            </span>
                        </router-link>
                    </div>
                </div>
                <!--END HEADER PAGE-->
                <!-- CONTENT PAGE -->
                <div class="page-body">
                    <div class="view">
                        <div class="view__row">
                            <div class="view__section">
                                <!--Имя NAS-->
                                <div class="view__item">
                                    <div class="view__label">
                                        <span class="view__label-icon"><i class="fal fa-server"></i></span>
                                        <span class="view__label-title">Имя NAS:</span>
                                    </div>
                                    <div class="view__value">
                                        <span class="view__value-text">{{ crud.data.item.name }}</span>
                                    </div>
                                </div>
                                <!--Отдел-->
                                <div class="view__item">
                                    <div class="view__label">
                                        <span class="view__label-icon"><i class="fal fa-code-branch"></i></span>
                                        <span class="view__label-title">Отдел:</span>
                                    </div>
                                    <div class="view__value">
                                        <span class="view__value-text">
                                            <template v-if=" crud.data.item.department_expand">
                                                {{ crud.data.item.department_expand.name }}
                                            </template>
                                            <template v-else>
                                                Не задано
                                            </template>
                                        </span>
                                    </div>
                                </div>
                                <!--IP-->
                                <div class="view__item">
                                    <div class="view__label">
                                        <span class="view__label-icon"><i class="fal fa-database"></i></span>
                                        <span class="view__label-title">NAS IP:</span>
                                    </div>
                                    <div class="view__value">
                                        <span class="view__value-text">
                                            {{ crud.data.item.ip }}
                                        </span>
                                    </div>
                                </div>
                                <!--Пользователь-->
                                <div class="view__item">
                                    <div class="view__label">
                                        <span class="view__label-icon"><i class="fal fa-user-cog"></i></span>
                                        <span class="view__label-title">Пользователь:</span>
                                    </div>
                                    <div class="view__value">
                                        <span class="view__value-text">
                                            {{ crud.data.item.username }}
                                        </span>
                                    </div>
                                </div>
                                <!--Пароль-->
                                <div class="view__item">
                                    <div class="view__label">
                                        <span class="view__label-icon"><i class="fal fa-key"></i></span>
                                        <span class="view__label-title">Пароль:</span>
                                    </div>
                                    <div class="view__value">
                                        <span class="view__value-text">
                                            <template v-if="password_visible">
                                                {{ crud.data.item.password }}
                                                <span data-title="Скрыть пароль" class="view__password view__password-hide" @click="set_password_visible(0)"><i class="fal fa-eye-slash"></i></span>
                                            </template>
                                            <template v-else>
                                                <span data-title="Показать пароль" class="view__password" @click="set_password_visible(1)"><i class="fal fa-eye"></i></span>
                                            </template>
                                        </span>
                                    </div>
                                </div>
                                <!--Port SSH-->
                                <div class="view__item">
                                    <div class="view__label">
                                        <span class="view__label-icon"><i class="fal fa-unlock"></i></span>
                                        <span class="view__label-title">Port SSH:</span>
                                    </div>
                                    <div class="view__value">
                                        <span class="view__value-text">
                                            {{ crud.data.item.port_ssh }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="view__section">
                                <!--Port API-->
                                <div class="view__item">
                                    <div class="view__label">
                                        <span class="view__label-icon"><i class="fal fa-unlock"></i></span>
                                        <span class="view__label-title">Port API:</span>
                                    </div>
                                    <div class="view__value">
                                        <span class="view__value-text">
                                            {{ crud.data.item.api_port }}
                                        </span>
                                    </div>
                                </div>
                                <!--SNMP Community-->
                                <div class="view__item">
                                    <div class="view__label">
                                        <span class="view__label-icon"><i class="fal fa-users-class"></i></span>
                                        <span class="view__label-title">SNMP Community:</span>
                                    </div>
                                    <div class="view__value">
                                        <span class="view__value-text">
                                            {{ crud.data.item.snmp_community }}
                                        </span>
                                    </div>
                                </div>
                                <!--Vendor-->
                                <div class="view__item">
                                    <div class="view__label">
                                        <span class="view__label-icon"><i class="fal fa-trademark"></i></span>
                                        <span class="view__label-title">Vendor:</span>
                                    </div>
                                    <div class="view__value">
                                        <span class="view__value-text">
                                            Не задано
                                        </span>
                                    </div>
                                </div>
                                <!--Model-->
                                <div class="view__item">
                                    <div class="view__label">
                                        <span class="view__label-icon"><i class="fal fa-hdd"></i></span>
                                        <span class="view__label-title">Model:</span>
                                    </div>
                                    <div class="view__value">
                                        <span class="view__value-text">
                                            Не задано
                                        </span>
                                    </div>
                                </div>
                                <!--Адрес-->
                                <div class="view__item">
                                    <div class="view__label">
                                        <span class="view__label-icon"><i class="fal fa-map-marker-alt"></i></span>
                                        <span class="view__label-title">Адрес:</span>
                                    </div>
                                    <div class="view__value">
                                        <span class="view__value-text">
                                            {{ crud.data.item.location_address }}
                                        </span>
                                    </div>
                                </div>
                                <!--Описание-->
                                <div class="view__item">
                                    <div class="view__label">
                                        <span class="view__label-icon"><i class="fal fa-comment"></i></span>
                                        <span class="view__label-title">Описание:</span>
                                    </div>
                                    <div class="view__value">
                                        <span class="view__value-text">
                                            {{ crud.data.item.description }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- END CONTENT PAGE -->
            </div>
        </div>
    </section>

</template>

<script>
    import {CrudMixin} from "../../../../mixins/admin/crud/crud_base";
    import {CrudListMixin} from "../../../../mixins/admin/crud/crud_list";

    export default {
        name: "nas-view",
        mixins: [CrudMixin, CrudListMixin],

        data: function () {
            return {
                password_visible: false,
                crud: {
                    api: '/nas/',
                },
            }
        },

        created: function () {
            this.crud_get_item(this.$route.params.id);
        },

        methods: {
            set_password_visible: function (value) {
                this.password_visible = value;
            },
        },
    }
</script>

<style scoped>

</style>