<template>
    <div class="search-filter">
        <div class="search-filter__title"><i class="fal fa-sliders-h"></i> Критерии поиска:</div>
        <ul class="search-filter__list row" v-for="(item, index) in filter_object_types">
            <li class="col-lg-4">
                <label class="checkbox">
                    <input type="checkbox" v-model="filters" :value="item.object_type">
                    <span class="checkmark"></span>
                    {{ item.object_name }}
                </label>
            </li>
        </ul>
    </div>
</template>

<script>
    import {API} from "../../../../api";

    export default {
        name: "search-filter",
        props: ['checked_filters', ],
        data: function () {
            return {
                filters: [],
                filter_object_types: [],
            }
        },
        mixins: [API, ],
        created() {
          this.fetchUser();
          this.filters = this.checked_filters;
        },
        methods: {
            fetchUser() {
                // if (this.has_perm('common_search')) {
                    this.get_filter_object_types();
                // }
            },
            get_filter_object_types() {
                var self = this;
                API.get('/search/filter-object-types/')
                    .then(function (response) {
                        self.filter_object_types = response.data;
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            }
        },
        watch: {
            filters() {
                this.$emit('update_checked_filters', this.filters);
            }
        },
    }
</script>

<style scoped>

</style>