<template>
    <popup :settings="settings">
        <div slot="title">Отключение услуги</div>
        <div slot="content">
            <div class="body-size">
                <div class="body-size__480">
                    Вы уверены, что хотите удалить {{ service.object_name }}?
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="btn-group">
                <span class="btn btn_grey" @click="popup_close(); clear();">
                    <span class="btn__icon"><i class="fal fa-times-circle"></i></span>
                    <span class="btn__title">Отмена</span>
                </span>
                <span class="btn btn_blue" @click="delete_service">
                    <span class="btn__icon"><i class="fal fa-check"></i></span>
                    <span class="btn__title">Удалить</span>
                </span>
            </div>
        </div>
    </popup>
</template>

<script>
    import {CrudMixin} from "../../../../../../mixins/admin/crud/crud_base";
    import Popup from '../../../modal/popup.vue';
    export default {
        name: "del-service",
        props: ["settings", "service"],
        mixins: [CrudMixin],
        components: {
            "popup": Popup,
        },
        methods:{
            delete_service(){
                this.$emit("deleted_service", this.service);
                this.popup_close();
            }
        }
    }
</script>

<style scoped>

</style>