<template>
    <section class="page">
        <div class="page__inner">
            <div class="container-width">
                <div class="row">
                    <!-- Heading page -->
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="page-header">
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                                    <h1 class="page-header__title">{{ crud.data.item.fio }}</h1>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                    <div class="page-header__btn-group">
                                        <router-link title="Вернуться" :to="{ name: 'employees-list'}">
                                            <span class="btn grey"><i class="fal fa-arrow-left"></i> <span
                                                    class="title">Вернуться</span></span>
                                        </router-link>
                                        <app-link :perms="'catalog_employees_add_edit'"
                                                  :title="'Редактировать'"
                                                  :to="{ name: 'employees-edit', params: {id: crud.data.item.id }}">
                                            <template slot-scope="{ access }">
                                                <span class="btn" :class="[access ? 'orange' : 'grey']">
                                                    <i class="fal fa-edit"></i><span class="title">Редактировать</span>
                                                </span>
                                            </template>
                                        </app-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Content page -->
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="page-body">
                            <div class="view-data">
                                <div class="row">
                                    <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                                        <div class="view-data__photo">
                                            <img :src="crud.data.item.photo_expand">
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
                                        <div class="view-data__header">
                                            <h3><i class="fal fa-address-card"></i> Персональные данные</h3>
                                        </div>
                                        <div class="view-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                                    <div class="label">
                                                        <i class="fal fa-user-tie"></i> ФИО:
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                                    <div class="value"> {{ crud.data.item.fio }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="view-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                                    <div class="label">
                                                        <i class="fal fa-code-branch"></i> Отдел:
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                                    <div class="value" v-if="crud.data.item.employee_department_expand">
                                                        {{ crud.data.item.employee_department_expand.name }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="view-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                                    <div class="label">
                                                        <i class="fal fa-industry-alt"></i> Должность:
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                                    <div class="value" v-if="crud.data.item.employee_department_expand">
                                                        {{ crud.data.item.employee_position_expand.name }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="view-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                                    <div class="label">
                                                        <i class="fal fa-phone"></i> Телефон:
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                                    <div class="value"> {{ crud.data.item.phone }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="view-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                                    <div class="label">
                                                        <i class="fal fa-at"></i> Email:
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                                    <div class="value"> {{ crud.data.item.email }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="view-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                                    <div class="label">
                                                        <i class="fal fa-at"></i> Доп. email:
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                                    <div class="value"> {{ crud.data.item.email_additional }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="view-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                                    <div class="label">
                                                        <i class="fal fa-calendar-alt"></i> Дата принятия на работу:
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                                    <div class="value"> {{ crud.data.item.date_of_employment |
                                                        moment("DD.MM.YYYY") }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
                                        <div class="view-data__header">
                                            <h3><i class="fal fa-home"></i> Фактический адрес проживания</h3>
                                        </div>
                                        <div class="view-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                                    <div class="label">
                                                        <i class="fal fa-flag"></i> Область:
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                                    <div class="value"> {{ crud.data.item.address_region }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="view-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                                    <div class="label">
                                                        <i class="fal fa-flag"></i> Район:
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                                    <div class="value"> {{ crud.data.item.address_district }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="view-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                                    <div class="label">
                                                        <i class="fal fa-flag"></i> Населенный пункт:
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                                    <div class="value"> {{ crud.data.item.address_settlement }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="view-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                                    <div class="label">
                                                        <label><i class="fal fa-flag"></i>Улица:</label>
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                                    <div class="value"> {{ crud.data.item.address_street }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="view-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                                    <div class="label">
                                                        <i class="fal fa-flag"></i>Дом:
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                                    <div class="value"> {{ crud.data.item.address_house_number }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="view-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                                    <div class="label">
                                                        <i class="fal fa-flag"></i>Квартира:
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                                    <div class="value"> {{ crud.data.item.address_apartment_number }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="page-body">
                            <div class="view-data">
                                <div class="row">
                                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6"
                                         v-has-perm="'employees_view_passport_details'"
                                         v-if="crud.data.item.user_passportdetails">
                                        <div class="view-data__header">
                                            <h3><i class="fal fa-address-card"></i> Паспортные данные</h3>
                                        </div>
                                        <div class="view-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                                    <div class="label">
                                                        <i class="fal fa-globe"></i> Гражданство:
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                                    <div class="value"> {{
                                                        crud.data.item.user_passportdetails.citizenship }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="view-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                                    <div class="label">
                                                        <i class="fal fa-male"></i> Пол:
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                                    <template v-if="crud.data.item.user_passportdetails.gender === 'M'">
                                                        <div class="value"> Мужской</div>
                                                    </template>
                                                    <template v-else>
                                                        <div class="value"> Женский</div>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="view-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                                    <div class="label">
                                                        <i class="fal fa-birthday-cake"></i> Дата рождения:
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                                    <div class="value"> {{
                                                        crud.data.item.user_passportdetails.date_of_birth |
                                                        moment("DD.MM.YYYY") }} ({{
                                                        get_age(crud.data.item.user_passportdetails.date_of_birth) }})
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="view-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                                    <div class="label">
                                                        <i class="fal fa-flag"></i> Страна:
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                                    <div class="value"> {{ crud.data.item.user_passportdetails.country
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="view-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                                    <div class="label">
                                                        <i class="fal fa-map-marked"></i> Область:
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                                    <div class="value"> {{ crud.data.item.user_passportdetails.region
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="view-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                                    <div class="label">
                                                        <i class="fal fa-location-circle"></i> Район:
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                                    <div class="value"> {{ crud.data.item.user_passportdetails.district
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="view-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                                    <div class="label">
                                                        <i class="fal fa-city"></i> Населенный пункт:
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                                    <div class="value"> {{
                                                        crud.data.item.user_passportdetails.settlement }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="view-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                                    <div class="label">
                                                        <i class="fal fa-road"></i> Улица:
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                                    <div class="value"> {{ crud.data.item.user_passportdetails.street
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="view-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                                    <div class="label">
                                                        <i class="fal fa-home"></i> Дом:
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                                    <div class="value"> {{
                                                        crud.data.item.user_passportdetails.house_number }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="view-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                                    <div class="label">
                                                        <i class="fal fa-door-closed"></i>Квартира:
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                                    <div class="value"> {{
                                                        crud.data.item.user_passportdetails.apartment_number }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="view-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                                    <div class="label">
                                                        <i class="fal fa-passport"></i> Серия и номер паспорта:
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                                    <div class="value"> {{
                                                        crud.data.item.user_passportdetails.passport_series_number }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="view-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                                    <div class="label">
                                                        <i class="fal fa-passport"></i> Дата выдачи паспорта:
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                                    <div class="value"> {{
                                                        crud.data.item.user_passportdetails.date_of_issue |
                                                        moment("DD.MM.YYYY") }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="view-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                                    <div class="label">
                                                        <i class="fal fa-passport"></i> Кем выдан паспорт:
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                                    <div class="value"> {{ crud.data.item.user_passportdetails.issued_by
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="view-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                                    <div class="label">
                                                        <i class="fal fa-passport"></i> ИНН:
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                                    <div class="value"> {{
                                                        crud.data.item.user_passportdetails.identification_number }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="view-data__header">
                                            <h3><i class="fal fa-print"></i> Скан документов</h3>
                                        </div>
                                        <div class="row" v-if="crud.data.item.user_passportdetails.docs_expand">
                                            <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3"
                                                 v-for="doc in crud.data.item.user_passportdetails.docs_expand">
                                                <div class="view-data__doc">
                                                    <img :src="doc.file_expand">
                                                    <div class="actions-panel"><a :href="doc.file_expand"><i
                                                            class="fal fa-eye"></i></a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" v-has-perm="'family_status_view'">
                                        <div class="view-data__header">
                                            <h3><i class="fal fa-child"></i> Семейное положение</h3>
                                        </div>
                                        <div class="view-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                                    <div class="label">
                                                        <i class="fal fa-badge-check"></i> Статус:
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                                    <template v-if="crud.data.item.family_is_married">
                                                        <div class="value"> Да</div>
                                                    </template>
                                                    <template v-else>
                                                        <div class="value"> Нет</div>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>

                                        <template v-if="crud.data.item.family_is_married">
                                            <div class="view-data__item">
                                                <div class="row">
                                                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                                        <div class="label">
                                                            <i class="fal fa-user-crown"></i> ФИО супруга(и):
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                                        <div class="value"> {{ crud.data.item.family_partner_fio }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="view-data__item">
                                                <div class="row">
                                                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                                        <div class="label">
                                                            <i class="fal fa-birthday-cake"></i> Дата рождения
                                                            супруга(и):
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                                        <div class="value"> {{
                                                            crud.data.item.family_partner_date_of_birth |
                                                            moment("DD.MM.YYYY") }} ({{
                                                            get_age(crud.data.item.family_partner_date_of_birth) }})
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="view-data__item">
                                                <div class="row">
                                                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                                        <div class="label">
                                                            <i class="fal fa-phone"></i> Телефон супруга(и):
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                                        <div class="value"> {{ crud.data.item.family_partner_phone }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>

                                        <div class="view-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                                    <div class="label">
                                                        <i class="fal fa-smile"></i> Дети:
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                                    <template v-if="crud.data.item.family_is_have_children">
                                                        <div class="value"> Да</div>
                                                    </template>
                                                    <template v-else>
                                                        <div class="value"> Нет</div>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>

                                        <template
                                                v-if="crud.data.item.family_is_have_children && crud.data.item.family_childrens_expand">
                                            <div class="view-data__item"
                                                 v-for="family_children in crud.data.item.family_childrens_expand">
                                                <div class="row">
                                                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                                        <div class="label">
                                                            <i class="fal fa-birthday-cake"></i> Дата рождения ребенка:
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                                        <div class="value"> {{ family_children | moment("DD.MM.YYYY") }}
                                                            ({{ get_age(family_children) }})
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Права доступа к определенным отделам -->
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="page-body">
                            <div class="row">
                                <access-department v-bind:class="{'hide': crud.data.item.is_superuser }"
                                                   :department_permissions="crud.data.item.department_permissions_expand"
                                                   :readonly="true"></access-department>
                            </div>
                        </div>
                    </div>
                    <!-- Права доступа -->
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="page-body">
                            <div class="row">
<!--                                <employee-permissions v-bind:class="{'hide': crud.data.item.is_superuser }"-->
<!--                                                      :permissions="crud.data.item.position_permissions_expand"-->
<!--                                                      :readonly="true"></employee-permissions>-->

                                <permissions
                                         v-bind:class="{'hide': crud.data.item.is_superuser }"
                                         :permissions="crud.data.item.position_permissions_expand"
                                         :readonly="true"
                                         ></permissions>

                                <div v-if="crud.data.item.is_superuser" class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <ul class="access-rights">
                                        <li class="access-rights__super-user">
                                            <p>
                                                <i class="fal fa-question-circle"
                                                   data-title="Права супер-администратора, снимают все ограничения и открывает доступ ко всей системе без исключения!"></i>
                                                Права супер-администратора системы:
                                            </p>
                                            <span class="access-rights__enable">
                                                <i class="far fa-check"></i>
                                            </span>
                                        </li>
                                    </ul>
                                    <div class="clearfix"></div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import {CrudMixin} from "../../../../mixins/admin/crud/crud_base";
    import {CrudListMixin} from "../../../../mixins/admin/crud/crud_list";
    import {pluralize} from "../../../../helpers/pluralize";
    import AccessDepartment from "./access-department";

    import Permissions from './permissions/list';

    export default {
        name: "employees-view",
        mixins: [CrudMixin, CrudListMixin],
        data: function () {
            return {
                crud: {
                    api: '/employee/',
                },
            }
        },
        created: function () {
            this.crud_get_item(this.$route.params.id);
        },
        methods: {
            get_age(date) {
                var years = this.$moment().diff(date, 'years');
                return years + " " + pluralize(years, ['год', 'года', 'лет'])
            }
        },
        components: {
            "access-department": AccessDepartment,
            "permissions": Permissions
        },
    }
</script>

<style scoped>

</style>