<template>
    <div class="btn-l btn-l_grey">
        <span class="btn-l__icon"><i class="fal fa-info"></i></span>
        <div class="btn-l__dropdown">
            <div class="btn-l__body">
                <div class="c-dropdown-info">
                    <div class="c-dropdown-info__item">
                        <div class="c-dropdown-info__label">
                            <span class="c-dropdown-info__label-icon"><i class="fal fa-calendar-alt"></i></span>
                            <span class="c-dropdown-info__label-title">Дата создания:</span>
                        </div>
                        <div class="c-dropdown-info__value">
                            {{ subscriber.created_at | moment("DD.MM.YYYY HH:mm") }}
                        </div>
                    </div>
                    <div class="c-dropdown-info__item">
                        <div class="c-dropdown-info__label">
                            <span class="c-dropdown-info__label-icon"><i class="fal fa-user-tie"></i></span>
                            <span class="c-dropdown-info__label-title">Менеджер:</span>
                        </div>
                        <div class="c-dropdown-info__value">
                            <template v-if="subscriber.employee_creator_expand">
                                {{ subscriber.employee_creator_expand.fio }}
                            </template>
                            <template v-else>
                                -
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "information",
        props: ['subscriber'],
    }
</script>

<style scoped>

</style>