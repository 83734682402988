<template>
  <section class="page">
    <div class="page__inner">
      <div class="container-width">
        <!--HEADER PAGE-->
        <div class="page__header">
          <h1 class="page__title">Сотрудники</h1>
          <div class="btn-group">
            <div class="page__group-btn">
                <span class="btn btn_grey">
                        <span class="btn__icon"><i class="fal fa-archive"></i></span>
                        <span class="btn__title">Архив</span>
                </span>
                <app-link :perms="'catalog_employee_positions_access'"
                          :title="'Открыть должности'"
                          :to="{ name: 'employees-position' }">
                    <template slot-scope="{ access }">
                        <span class="btn" :class="[access ? 'btn_blue' : 'btn_grey']">
                            <span class="btn__icon"><i class="fal fa-industry-alt"></i></span>
                            <span class="btn__title">Должности</span>
                        </span>
                    </template>
                </app-link>
                <app-link :perms="'catalog_employees_add_edit'"
                          :title="'Добавление сотрудника'"
                          :to="{ name: 'employees-add' }">
                    <template slot-scope="{ access }">
                        <span class="btn" :class="[access ? 'btn_green' : 'btn_grey']">
                            <span class="btn__icon"><i class="fal fa-plus"></i></span>
                            <span class="btn__title">Добавить сотрудника</span>
                        </span>
                    </template>
                </app-link>
            </div>
          </div>
        </div>

        <!--END HEADER PAGE-->
        <!--BODY PAGE-->
        <div class="page__body">
          <!--FILTER BLOCK PAGE-->
          <FilterBlock @resetComponentFilters="resetComponentFilters">
            <filter-by-field v-model="employee_department"
                             title="Отдел"
                             view="name"
                             :objects="departments"
                             :add-social-tariff="true"/>
          </FilterBlock>
          <!--END FILTER BLOCK PAGE-->
          <div class="table-box">
            <table class="table-box__table">
              <thead class="table-box__thead">
              <tr class="table-box__thead-tr">
                <th class="table-box__th">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-user-tie"></i></span>
                                        <span class="table-box__thead-title"> Фио</span>
                                    </span>
                </th>
                <th class="table-box__th">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-code-branch"></i></span>
                                        <span class="table-box__thead-title"> Отдел</span>
                                    </span>
                </th>
                <th class="table-box__th">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-industry-alt"></i></span>
                                        <span class="table-box__thead-title"> Должность</span>
                                    </span>
                </th>
                <th class="table-box__th">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-headset"></i></span>
                                        <span class="table-box__thead-title"> Телефон</span>
                                    </span>
                </th>
                <th class="table-box__th">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-at"></i></span>
                                        <span class="table-box__thead-title"> Email</span>
                                    </span>
                </th>
                <th class="table-box__th">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-birthday-cake"></i></span>
                                        <span class="table-box__thead-title"> Дата рождения</span>
                                    </span>
                </th>
                <th class="table-box__th">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-check-double"></i></span>
                                        <span class="table-box__thead-title"> Статус</span>
                                    </span>
                </th>
                <th class="table-box__th">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-mouse-pointer"></i></span>
                                        <span class="table-box__thead-title"> Действия</span>
                                    </span>
                </th>
              </tr>
              </thead>
              <tbody class="table-box__tbody">
              <tr class="table-box__tr table-data" v-for="(item, index) in crud.data.list">
                <!--ФИО-->
                <td class="table-box__td">
                                    <span class="table-data__text">
                                        {{ item.fio }}
                                    </span>
                </td>
                <!--Отдел-->
                <td class="table-box__td table-box__td_center">
                                    <span class="table-data__text">
                                        <template v-if="item.employee_department_expand">
                                            {{ item.employee_department_expand.name }}
                                        </template>
                                        <template v-else>
                                            <i>—</i>
                                        </template>
                                    </span>
                </td>
                <!--Должность-->
                <td class="table-box__td table-box__td_center">
                                    <span class="table-data__text">
                                        <template v-if="item.employee_position_expand">
                                                {{ item.employee_position_expand.name }}
                                        </template>
                                        <template v-else>
                                         <i>—</i>
                                        </template>
                                    </span>
                </td>
                <!--Телефон-->
                <td class="table-box__td table-box__td_center">
                                    <span class="table-data__text">
                                        {{ item.phone }}
                                    </span>
                </td>
                <!--Email-->
                <td class="table-box__td table-box__td_center">
                                    <span class="table-data__text">
                                        {{ item.email }}
                                    </span>
                </td>
                <!--Дата рождения-->
                <td class="table-box__td table-box__td_center">
                                    <span class="table-data__text">
                                        <template v-if="item.user_passportdetails_expand">
                                                {{ item.user_passportdetails_expand.date_of_birth }}
                                        </template>
                                        <template v-else>
                                            <i>—</i>
                                        </template>
                                    </span>
                </td>
                <!--Статус-->
                <td class="table-box__td table-box__td_center">
                                    <span v-if="item.is_active" class="table-data__status table-data__status_active">
                                         Активный
                                    </span>
                  <span v-else class="table-data__status table-data__status_deactive">
                                        Заблокирован
                                    </span>
                </td>
                <!--Действия-->
                <td class="table-box__td table-box__td_right">
                  <div class="btn-group">
                    <!--View-->
                    <router-link v-tooltip="TooltipText='Просмотр'"
                                 :to="{ name: 'employees-view', params: {'id': item.id}}">
                                            <span class="btn-l btn-l_blue">
                                                <span class="btn-l__icon"><i class="fal fa-eye"></i></span>
                                            </span>
                    </router-link>
                    <!--Edit-->
                    <app-link :perms="'catalog_employees_add_edit'"
                              :title="'Редактирование сотрудника'"
                              :to="{ name: 'employees-edit', params: {'id': item.id}}">
                        <template slot-scope="{ access }">
                            <span class="btn-l" :class="[access ? 'btn-l_orange' : 'btn-l_grey']" v-tooltip="TooltipText='Редактирование'">
                                <span class="btn-l__icon"><i class="fal fa-edit"></i></span>
                            </span>
                        </template>
                    </app-link>
                    <!--Remove to arhive-->
                    <app-remove-btn :perms="'catalog_employees_delete'" ></app-remove-btn>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!--PAGINATION BLOCK-->
          <Pagination @loadItems="load"
                      :options="limitOptions"
                      :limit-items="limit"
                      :page-items="page"
                      :count-items="crud.data.list_count"
                      :add-null-option="false"/>
          <!--END PAGINATION BLOCK-->
        </div>
        <!--END BODY PAGE-->
      </div>
    </div>
  </section>
</template>

<script>
import {CrudMixin} from "../../../../mixins/admin/crud/crud_base";
import {CrudListMixin} from "../../../../mixins/admin/crud/crud_list";
import {SearchMixin} from "../../../../mixins/admin/search";
import FilterBlock from "../filter-block/filter-block";
import FilterByField from "../filter-block/sub_components/filter";
import Pagination from "../pagination/pagination";
import {RouteHelperMixin} from "../../../../mixins/admin/route_helper";
import {FilterHelperMixin} from "../../../../mixins/admin/filter_helper";
import {PaginationMixin} from "../../../../mixins/admin/pagination";
import {DepartmentMixin} from "../../../../mixins/admin/department";

export default {
  name: "employees",
  components: {
    FilterByField,
    FilterBlock,
    Pagination,
  },
  mixins: [CrudMixin, CrudListMixin, SearchMixin, RouteHelperMixin, PaginationMixin, DepartmentMixin,
    FilterHelperMixin],
  data: function () {
    return {
      crud: {
        api: '/employee/',
      },
      // options for pagination block
      limitOptions: [15, 25, 50, 100],
      limit: 15,
      page: 1,
      count: 0,
      // filter options for department filter
      employee_department: undefined,
      departments: [],
    }
  },
  created() {
    this.pagination_init(this.page, this.limit)

    this.$root.$on('search_hit_employees', (function (data) {
      this.load()
    }).bind(this));

    this.$root.$on('search_clear_employees', (function (data) {
      this.load()
    }).bind(this));
  },
  beforeDestroy: function () {
    this.destroy_search_listeners("employees");
  },
  mounted: function () {
    this.loadFilterOptions()
    this.load();
  },
  methods: {
    load() {
      var self = this;
      var params = Object.assign({}, self.$route.query,
          {
            page: !self.$route.query.page ? self.page : self.$route.query.page,
            limit: !self.$route.query.limit ? self.limit : self.$route.query.limit,
            offset: !self.$route.query.offset ? self.get_offset(self.page) : self.$route.query.offset,
          });

      self.$set(params, 'employee_department', self.employee_department)

      this.crud_get_list(params)

      // update department filter
      self.updateRouterQuery('employee_department', self.departments, self.employee_department);
    },
    loadFilterOptions() {
      this.load_departments();
    },
    resetComponentFilters() {
      this.resetFilters();
      this.load();
    }
  },
  watch: {
    // watcher for filter department
    employee_department(value) {
      if (value && value.length > 0) {
        this.load();
      }
    },
  }
}
</script>

<style scoped>

</style>