export var OrderMixin = {
    /* init reactive data ordering: "FIELD", ordering_direction: "ASC/DESC",
    * before load items run setOrderingToParams to set ordering params to request
    * after response updateRouteOrderingParams that update route query */
    methods: {
        get_month_by_digit(month){
            return this.$moment.months(month-1);
        },
        get_class_ordering_direction(field){
            if(field === this.ordering) {
                return this.ordering_direction === 'ASC' ? 'fa-sort-down' : 'fa-sort-up'
            }
            else {
                return "fa-sort"
            }
        },
        set_ordering(value){
            if(this.ordering_direction === "ASC"){
                this.ordering_direction = "DESC";
            } else {
                this.ordering_direction = "ASC";
            }

            this.ordering = value;
        },
        setOrderingToParams(params) {
            // set ordering params before request
            params['ordering'] = this.ordering_direction === "ASC" ? this.ordering : "-" + this.ordering;
            return params
        },
        updateRouteQueryOrderingParams() {
            // Update router with params, need use after response
            this.$router.replace({
                query: Object.assign({}, this.$route.query, {
                    ordering: this.ordering,
                    ordering_direction: this.ordering_direction
                }),
            });
        }
    },
    computed: {
        // property to avoid double request
        orderingAndOrderingName() {
            return `${this.ordering}|${this.ordering_direction}`;
        },
    },
};
