<template>
    <header class="header">
        <div class="header__inner">
            <!-- SEARCH -->
            <Search></Search>
            <!-- END SEARCH -->
            <!-- PANEL -->
            <div class="panel">
                <div class="panel__account">
                    <span class="photo"><img src="/media/img/jobs.png"></span>
                    <span class="arrow"><i class="fas fa-sort-down"></i></span>
                    <ul class="dropdown">
                        <li><a href="#"><i class="fal fa-user-tie"></i> Личный кабинет</a></li>
                        <li><a href="#"><i class="fal fa-bell"></i> Оповещения</a></li>
                        <li><a href="#"><i class="fal fa-globe-americas"></i> Сайт компании</a></li>
                        <li><a href="#" @click="logout" class="exit"><i class="fal fa-sign-out"></i> Выход из
                            системы</a></li>
                    </ul>
                </div>
                <!--<div class="panel__alert">-->
                <!--<span class="count">7</span>-->
                <!--<i class="fal fa-bell"></i>-->
                <!--</div>-->
            </div>
        </div>
    </header>
</template>

<script>
    import {User} from "../../../mixins/admin/user";
    import Search from "../components/search/search";

    export default {
        name: 'header-view',
        mixins: [User],
        methods: {},
        components: {
            Search,
        },
    }


</script>
