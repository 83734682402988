<template>
    <section class="page">
        <div class="page__inner">
            <div class="container-width">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="page-header">
                            <div class="row">
                                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <h1 class="page-header__title"> Новая основная партия</h1>
                                </div>
                                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <DropDown></DropDown>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- CONTENT PAGE -->
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="page-body">
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <form action="" class="add-data">
                                        <div class="row">
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div class="add-data__item">
                                                    <div class="row">
                                                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                                            <label><i class="fal fa-server"></i>От кого: <span
                                                                    class="control"><i class="fas fa-star-of-life"></i></span></label>
                                                        </div>
                                                        <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                                                            <input type="text" v-bind:value="user.fio" disabled value="ФИО">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="add-data__item">
                                                    <div class="row">
                                                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                                            <label><i class="fal fa-server"></i> Кому: <span
                                                                    class="control"><i class="fas fa-star-of-life"></i></span></label>
                                                        </div>
                                                        <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                                                            <div class="selected">
                                                                <select v-model="owner" required>
                                                                    <option value="" disabled hidden>Выберите получателя</option>
                                                                    <option v-for="owner in employers" v-bind:value="owner.id" >{{ owner.fio }}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <form-error v-bind:value="get_owner_errors()"></form-error>
                                                </div>

                                                <span v-for="(consignment, index) in consignment_list">

                                                    <div class="add-data__header" v-if="index > 0">
                                                        <div class="add-data__padding-item"></div>
                                                        <div class="row">
                                                            <div class="col-xs-12 col-sm-6 col-md-8 col-lg-8">
                                                                <h3>Дополнительная партия</h3>
                                                            </div>
                                                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                                                <div class="add-data__remove-item" @click="remove_consignment(index)">
                                                                    <i class="fal fa-minus-circle"></i> Удалить партию
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <template v-if="has_perm('unicards_super_access')">
                                                        <additional-consigment
                                                                @update_unicard_set="update_unicard_set"
                                                                :errors="get_errors(index)"
                                                                :index="index"
                                                                :with_type_selected="true"
                                                                :available_batch_series="available_batch_series"
                                                                :available_series="available_series"></additional-consigment>
                                                    </template>
                                                    <template v-else>
                                                        <additional-consigment
                                                                @update_unicard_set="update_unicard_set"
                                                                :errors="get_errors(index)"
                                                                :index="index"
                                                                :available_batch_series="available_batch_series"></additional-consigment>
                                                    </template>
                                                </span>


                                            </div>
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div class="add-data__add-item" @click="add_consignment()">
                                                    <i class="fal fa-plus"></i> Добавить партию
                                                </div>
                                            </div>
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div class="add-data__footer">
                                                    <router-link title="Отмена" :to="{ name: 'unicards-consignment-main'}">
                                                        <span class="btn grey">Отмена</span>
                                                    </router-link>

                                                    <span class="btn blue" @click="save()">Отправить <i class="fal fa-long-arrow-right"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import DropDown from "../dropdown/dropdown";
    import FormErrors from '../../form/errors.vue';
    import AdditionalConsigment from "./sub_components/additional_consigment";
    import {User} from "../../../../../mixins/admin/user";
    import {API} from "../../../../../api";
    import {UnicardsMixin} from "../../../../../mixins/admin/unicards";

    export default {
        name: "new-main-consignment",
        data(){
          var consignment_item = {
                  "owner": "",
                  "number_to": "",
                  "unicard_set": "",
                  "unicard_batch_from": "",
                  "number_from": "",
          };

          return {
              owner: "",
              employers: [],
              available_series: [],
              available_batch_series: [],
              consignment_list: [Object.assign({}, consignment_item)],
              consignment_item: consignment_item,
              errors: []
          }
        },
        mixins: [User, UnicardsMixin],
        components: {
            "form-error": FormErrors,
            AdditionalConsigment,
            DropDown
        },
        mounted(){
            this.fetchUser();
        },
        methods: {
            fetchUser(){
                var self = this;
                API.get("/employee/with-unicard-access/").then(function (response) {
                        self.employers = response.data.results;
                    });

                if(this.has_perm('unicards_super_access')) {
                    this.load_available_for_batch();
                    this.load_available_for_shop();
                }else{
                    this.load_available_for_shop();
                }
            },
            save(){
                var self = this;
                API.post('/api/v1/unicards/batch-many/', {'unicard_batches': this.consignment_list})
                    .then(function (response) {
                        self.$router.push({name: "unicards-consignment-main"});
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            },
            get_errors(index){
                if(this.errors.unicard_batches && index in this.errors.unicard_batches) {
                    return this.errors.unicard_batches[index]
                }
                return {}
            },
            get_owner_errors(){
                if(this.errors.unicard_batches){
                    for(var error of this.errors.unicard_batches){
                        if("owner" in error){
                            return error["owner"];
                        }
                    }
                }
                return undefined
            },
            add_consignment(){
                var item = Object.assign({}, this.consignment_item);
                this.consignment_list.push(item)
            },
            remove_consignment(index){
                this.consignment_list.splice(index, 1);
            },
            update_unicard_set(obj){
                this.consignment_list[obj.index].unicard_set = obj.unicard_set;
                this.consignment_list[obj.index].unicard_batch_from = obj.unicard_batch;
                this.consignment_list[obj.index].number_from = obj.number_from;
                this.consignment_list[obj.index].number_to = obj.number_to;
            }
        },
        watch: {
            owner(){
                // Update owner
                this.consignment_item.owner = this.owner;
                for(var index in this.consignment_list){
                    this.consignment_list[index].owner = this.owner;
                }
            }
        }
    }
</script>

<style scoped>

</style>