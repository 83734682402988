<template>
    <td class="table-subscribers__td table-subscribers__td_wrap" v-has-perm="'subscribers_access'">
        <template v-if="subscriber_connection.settlement_expand">
            <span class="table-data__text">
                {{ subscriber_connection_address_full }}
            </span>
        </template>
    </td>
</template>

<script>
    import {SubscriberConnectionMixin} from "../../../../../../mixins/admin/subscriber_connection";

    export default {
        name: "td_settlement",
        mixins: [SubscriberConnectionMixin],
        props: ["subscriber_connection"],
    }
</script>

<style scoped>

</style>