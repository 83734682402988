<template>
    <popup :settings="settings">
        <div slot="title">Отдел {{ crud.data.item.name }}</div>
        <div slot="content">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="modal__content">
                    <ul class="modal-info">
                        <li class="modal-info__label"><i class="fal fa-code-branch"></i> Имя отдела:</li>
                        <li class="modal-info__value">{{ crud.data.item.name }}</li>
                        <li class="modal-info__label"><i class="fal fa-comment-exclamation"></i> Описание отдела:</li>
                        <li class="modal-info__value">{{ crud.data.item.description }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </popup>
</template>

<script>
    import Popup from '../modal/popup.vue'
    import {CrudMixin} from "../../../../mixins/admin/crud/crud_base";

    export default {
        name: "departments-view-popup",
        props: ["settings", "crud"],
        mixins: [
            CrudMixin
        ],
        components: {
            "popup": Popup
        }
    }
</script>

<style scoped>

</style>