import {API} from "../../../api";

export var CrudMixin = {
    methods: {
        crud_get_list: function (params) {
            var self = this;
            params = params || {};

            API.get(self.crud.api, params)
                .then(function (response) {
                    self.crud.data.list = response.data.results;
                    self.crud.data.list_count = response.data.count;
                })
                .catch(function (error) {
                    // API.default_catch(self, error)
                })
                .then(function () {
                    // API.default_always(self);
                })
        },
        crud_get_item: function (id) {
            var self = this;
            API.get(self.crud.api + id + '/')
                .then(function (response) {
                    self.crud.data.item = response.data;
                    self.crud.data.item_view = Object.assign({}, self.crud.data.item);
                })
        },
        crud_create: function (data, redirect_to = '') {
            var self = this;
            self.loader_show();

            return API.post(self.crud.api, data)

                .then(function (response) {
                    // self.$set(self.crud.data.list, self.crud.data.index, data);
                    if(self.crud.data.list) {
                        self.crud.data.list.push(response.data);
                        // self.item = {};
                        // Set to default Component data()
                        Object.assign(self.$data, self.$options.data.apply(self))
                    }

                    if (redirect_to !== '') {
                        self.$router.push({name: redirect_to})
                    }
                    else {
                        self.popup_close()
                    }

                })
                .catch(function (error) {
                    API.default_catch(self.crud, error)
                })
                .then(function () {
                    API.default_always(self);
                })
        },
        crud_edit: function (id, data, redirect_to = '') {
            var self = this;
            self.loader_show();

            return API.patch(self.crud.api + id + '/', data).then(function (response) {
                    if(self.crud.data.list) {
                        self.$set(self.crud.data.list, self.crud.data.index, response.data);
                    }

                    if (redirect_to !== '') {
                        self.$router.push({name: redirect_to})
                    }
                    else {
                        self.popup_close()
                    }
                })
                .catch(function (error) {
                    API.default_catch(self.crud, error)
                })
                .then(function () {
                    API.default_always(self);
                })
        },
        crud_delete: function () {
            //Todo: need implement
        },
        popup_add() {
            this.add_popup.visible = true;
            this.crud.errors = {};
        },
        popup_edit(index, item) {
            this.crud.data.index = index;
            this.crud.data.item = Object.assign({}, item);
            this.crud.data.item_view = Object.assign({}, item);
            this.crud.errors = {};

            this.edit_popup.visible = true;
        },
        popup_view(item) {
            this.crud.data.item = item;
            this.view_popup.visible = true;
        },
        popup_delete() {
            //Todo: un-comment when future be work
            // this.delete_popup.visible = true;
        },
        popup_close() {
            this.settings.visible = false;
            if(this.crud && this.crud.errors) {
                this.crud.errors = {};
            }
        },
        popup_change() {
            this.change_popup.visible = true;
            this.crud.errors = {};
        },
    }
};

