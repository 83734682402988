<template>
    <div class="modal-confirmation" v-if="settings">
        <div class="modal-confirmation__body">
            <div class="modal-confirmation__title modal-confirmation__title_red">
                <slot name="title"></slot>
            </div>
            <div class="modal-confirmation__desc">
                <slot name="main"></slot>
            </div>
            <div class="modal-confirmation__action">
                <span class="modal-confirmation__btn modal-confirmation__btn_grey" @click="$emit('cancel_action')">
                    <span class="modal-confirmation__btn-icon"><i class="fal fa-times-circle"></i></span>
                    <span class="modal-confirmation__btn-title">Нет</span>
                </span>
                <span class="modal-confirmation__btn modal-confirmation__btn_red" @click="$emit('confirm_action')">
                    <span class="modal-confirmation__btn-icon"><i class="fal fa-check"></i></span>
                    <span class="modal-confirmation__btn-title">Да</span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "tv-device-alert",
    props: {
        settings: {
            required: true,
            type: Boolean,
        }
    },
}
</script>

<style scoped>

</style>