<template>
    <section class="page">
        <div class="page__inner">
            <div class="container-width">
                <!--HEADER PAGE-->
                <div class="page__header">
                    <h1 class="page__title">IP ADDRESS</h1>
                    <div class="btn-group">
                        <router-link :to="{ name: 'ipaddress-pools'}">
                            <span class="btn btn_green">
                                <span class="btn__icon"><i class="fal fa-server"></i></span>
                                <span class="btn__title">IP Pools</span>
                            </span>
                        </router-link>
                    </div>
                </div>
                <!--END HEADER PAGE-->

                <div class="row">
                    <!--PAGE BODY-->
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="page-body">
                            <!--PAGE-BODY-HEAD-->
                            <div class="page-body__header">
                                <div class="row">
                                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                        <FilterBlock @resetComponentFilters="resetComponentFilters">
                                            <filter-by-field v-model="destination"
                                                             title="Все IP"
                                                             view="label"
                                                             :objects="destinations"
                                                             :is-choices-filter="true">
                                            </filter-by-field>
                                        </FilterBlock>
                                    </div>

                                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                         <app-has-perm-action-btn :perms="'catalog_ip_address_management'"
                                                                  @action="popup_add">
                                             <template slot-scope="{ access }">
                                                 <span class="page-body__btn-group">
                                                     <span class="btn-l" :class="access ? 'blue' : 'grey'">
                                                         <i class="fal fa-edit"></i>
                                                     </span>
                                                 </span>
                                             </template>
                                         </app-has-perm-action-btn>
                                    </div>
                                </div>
                            </div>
                            <!--END PAGE-BODY-HEAD-->
                            <!--PAGE-TABLE-->
                            <div class="table-container">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>
                                            <label class="checkbox"><input type="checkbox" v-model="select_all"
                                                                           id="select_all">
                                                <span class="checkmark"></span>
                                            </label>
                                        </th>
                                        <th><i class="fal fa-sort-numeric-down"></i></th>
                                        <th><i class="fal fa-server"></i>IP ADDRESS</th>
                                        <th><i class="fal fa-arrows"></i>Назначение</th>
                                        <th><i class="fal fa-user"></i>Логин</th>
                                        <th><i class="fal fa-comment-exclamation"></i>Примечание</th>
                                        <th><i class="fal fa-cogs"></i> Действия</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <template v-for="(ip, index) in ip_address">
                                        <tr v-bind:class="[tr_class(ip)]">
                                            <td>
                                                <label class="checkbox"><input type="checkbox" v-model="record_checked"
                                                                               v-bind:value="ip">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </td>
                                            <td>{{ index + 1 }}</td>
                                            <td>{{ ip.ip_address }}</td>
                                            <td><span class="status">{{ ip.destination_display }}</span></td>
                                            <td>
                                                <span v-if="ip.subscriber_connection_expand">{{ ip.subscriber_connection_expand.username }}</span>
                                                <span v-else>-</span>
                                            </td>
                                            <td>{{ ip.note }}</td>
                                            <td>
                                                <div class="btn-group">
                                                    <span>
                                                        <!--EDIT IP-ADDRESS-->
                                                        <app-has-perm-action-btn :perms="'catalog_ip_address_management'"
                                                                                 @action="popup_edit(index, ip)">
                                                            <template slot-scope="{ access }">
                                                                <span class="btn-l" :class="access ? 'blue' : 'grey'">
                                                                    <span class="btn-l__icon"><i class="fal fa-edit"></i></span>
                                                                </span>
                                                            </template>
                                                        </app-has-perm-action-btn>
                                                        <!--END EDIT IP-ADDRESS-->
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                    </tbody>
                                </table>
                            </div>
                            <!--END PAGE-TABLE-->
                            <!--PAGE-FOOTER-->
                            <!--Pagination block-->
                            <Pagination @loadItems="loadIpAddress"
                                        :options="limitOptions"
                                        :limit-items="limit"
                                        :page-items="page"
                                        :count-items="count"
                                        :add-null-option="false" />
                            <!-- END Pagination block-->
                            <!--END PAGE-FOOTER-->
                        </div>
                    </div>
                    <!--END PAGE BODY-->
                </div>
            </div>
        </div>
        <!-- Add-pool popup -->
        <edit-all-ip-popup :settings="add_popup" :record_checked="record_checked" :destinations="destinations"
                           @fetchUser="fetchUser"></edit-all-ip-popup>
        <!-- Edit-pool popup -->
        <edit-ip-popup :settings="edit_popup" :crud="crud" :destinations="destinations"
                       @updateIp="updateIp"></edit-ip-popup>
    </section>
</template>

<script>
    import VueNumeric from 'vue-numeric'
    import {CrudMixin} from "../../../../mixins/admin/crud/crud_base";
    import {CrudListMixin} from "../../../../mixins/admin/crud/crud_list";

    import EditAllIp from './edit-all.vue';
    import EditIp from './edit.vue';
    import {API} from "../../../../api";
    import {PaginationMixin} from "../../../../mixins/admin/pagination";
    import Pagination from "../pagination/pagination";
    import FilterBlock from "../filter-block/filter-block";
    import Filter from "../filter-block/sub_components/filter";
    import {FilterHelperMixin} from "../../../../mixins/admin/filter_helper";
    import {RouteHelperMixin} from "../../../../mixins/admin/route_helper";

    export default {
        name: "list",
        components: {
            FilterBlock,
            "filter-by-field": Filter,
            "edit-all-ip-popup": EditAllIp,
            "edit-ip-popup": EditIp,
            VueNumeric,
            Pagination,
        },
        data() {
            return {
                destination: undefined,
                destinations: [],
                ip_address: [],
                record_checked: [],
                select_all: false,
                destination_css_class: {
                    'for_rent': 'row-green',
                    'reserved': 'row-grey',
                    'network': 'row-orange',
                    'transport': 'row-blue',
                    'free': 'row-white',
                    'in_rent': 'row-red'
                },
                errors: {},
                // pagination optionas
                limitOptions: [48, 128, 256],
                limit: 48,
                page: 1,
                count: 0,
            }
        },
        mixins: [CrudMixin, CrudListMixin, PaginationMixin, FilterHelperMixin, RouteHelperMixin],
        created() {
            this.pagination_init(this.page, this.limit);
            this.setDestinations();
        },
        mounted() {
            this.fetchUser();
        },
        methods: {
            resetComponentFilters() {
                this.resetFilters();
                this.fetchUser();
            },
            fetchUser() {
                this.loadIpAddress()
                this.record_checked = [];
            },
            setDestinations() {
                var self = this;
                API.get('/ip-address/destination/')
                    .then(function (response) {
                        self.$set(self, "destinations", response.data.results);
                    })
            },
            loadIpAddress() {
                var self = this;

                var params = Object.assign({}, self.$route.query,
                    {
                        page: !self.$route.query.page ? self.page : self.$route.query.page,
                        limit: !self.$route.query.limit ? self.limit : self.$route.query.limit,
                        offset: !self.$route.query.offset ? self.get_offset(self.page) : self.$route.query.offset,
                    });

                self.$set(params, 'destination', self.destination);

                self.loader_show();
                self.data_loaded = false;
                API.get('/ip-address/ip-address/', params)
                    .then(function (response) {
                        self.$set(self, "ip_address", response.data.results);
                        self.count = response.data.count;

                        self.updateRouterQuery('destination', self.destinations, self.destination);
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                        self.data_loaded = true;
                    })
            },
            updateIp(ip) {
                for (var i in this.ip_address) {
                    if (this.ip_address[i].id === ip.id) {
                        this.$set(this.ip_address, i, ip);
                        break;
                    }
                }
            },
            tr_class(ip) {
                return this.destination_css_class[ip.destination];
            }
        },
        watch: {
            select_all: function (val) {
                this.record_checked = [];
                if (val) {
                    for (var ip_address of this.ip_address) {
                        this.record_checked.push(ip_address);
                    }
                }
            },
            destination(value) {
                if (value && value.length > 0) {
                    this.fetchUser();
                }
            },
        }
    }
</script>

<style scoped>

</style>