// VueJs pages & components
import Login from '../templates/admin/components/auth/auth-login.vue';
// Dashboard
import DashboardPage from '../templates/admin/components/dashboard/dashboard.vue';
// Subscribers
import SubscribersList from '../templates/admin/components/subscribers/list.vue';
import SubscribersAdd from '../templates/admin/components/subscribers/add.vue';
import SubscribersItem from '../templates/admin/components/subscribers/item.vue';
// Point connections
import SubscriberConnectionsAdd from '../templates/admin/components/subscriber-connections/add.vue';
import SubscriberConnectionsItem from '../templates/admin/components/subscriber-connections/item.vue';
// Groups subscribers
import GroupsList from '../templates/admin/components/groups/list.vue';
import GroupsAdd from '../templates/admin/components/groups/add.vue';
import GroupsEdit from '../templates/admin/components/groups/edit.vue';
// Departments
import DepartmentsList from '../templates/admin/components/departments/list.vue';
import DepartmentsAdd from '../templates/admin/components/departments/add.vue';
import DepartmentsEdit from '../templates/admin/components/departments/edit.vue';
// Connection_types
import ConnectionTypesList from '../templates/admin/components/connection_types/list.vue';
import ConnectionTypesAdd from '../templates/admin/components/connection_types/add.vue';
import ConnectionTypesView from '../templates/admin/components/connection_types/view.vue';
import ConnectionTypesEdit from '../templates/admin/components/connection_types/edit.vue';
// NAS
import NasList from '../templates/admin/components/nas/list.vue';
import NasAdd from '../templates/admin/components/nas/add.vue';
import NasView from '../templates/admin/components/nas/view.vue';
import NasEdit from '../templates/admin/components/nas/edit.vue';
// Tariffs
import TariffsList from '../templates/admin/components/tariffs/list.vue';
import TariffsAdd from '../templates/admin/components/tariffs/add-edit.vue';
import TariffsEdit from '../templates/admin/components/tariffs/add-edit.vue';
import TariffsView from '../templates/admin/components/tariffs/view.vue';
import TariffMassTransferList from '../templates/admin/components/tariff-mass-transfers/list.vue';
// import TariffsTransfer from '../templates/components/tariffs/transfer.vue';
// Addresses
import AddressesList from '../templates/admin/components/addresses/list.vue';
// Employees
import EmployeesList from '../templates/admin/components/employees/list.vue';
import EmployeesEdit from '../templates/admin/components/employees/add-edit.vue';
import EmployeesView from '../templates/admin/components/employees/view.vue';
import EmployeesAdd from '../templates/admin/components/employees/add-edit.vue';
// Position employees
import EmployeesPosition from '../templates/admin/components/employees/position/position.vue';
import EmployeesPositionAdd from '../templates/admin/components/employees/position/position-add.vue';
import EmployeesPositionView from '../templates/admin/components/employees/position/position-view.vue';
import EmployeesPositionEdit from '../templates/admin/components/employees/position/position-edit.vue';
// IP address
import IPaddressList from '../templates/admin/components/ip_address/list.vue';
import IPaddressPools from '../templates/admin/components/ip_address/pools/pools.vue';
// MAC address
import MACaddressList from '../templates/admin/components/mac_address/list.vue';
// Service address
import ServicesList from '../templates/admin/components/services/list';
import ServicesIpAddress from '../templates/admin/components/services/ip-address/list-ip-address';
import ServicesTV from '../templates/admin/components/services/iptv-triniti/list-iptv-triniti';
import TariffTriniti from '../templates/admin/components/services/tariff-triniti/list';
// Reports
import ReportsPage from '../templates/admin/components/reports/page.vue';
import ReportsFinance from '../templates/admin/components/reports/finance.vue';
import ReportsSubscribers from '../templates/admin/components/reports/subscribers.vue';
import ReportsNas from '../templates/admin/components/reports/nas.vue';
import ReportsTariffs from '../templates/admin/components/reports/tariffs.vue';
import ReportsSettlements from '../templates/admin/components/reports/settlements.vue';
import ReportsDepartments from '../templates/admin/components/reports/departments.vue';
import ReportsReplenishment from '../templates/admin/components/reports/replenishment_report/list.vue';
import ReportsServices from '../templates/admin/components/reports/services.vue';
import ReportsNetwork from '../templates/admin/components/reports/network/network';
import ReportsSubscribersLeak from '../templates/admin/components/reports/subscribers_leak/subscribers-leak.vue';
import ReportsAnalytics from '../templates/admin/components/reports/analytics';
// Unicards
// TODO: Генерация карт
import UnicardsList from '../templates/admin/components/unicards/list.vue';
import UnicardsGeneration from '../templates/admin/components/unicards/generation.vue';
import UnicardsListCards from '../templates/admin/components/unicards/list-cards.vue';
import UnicardsCardsInfo from '../templates/admin/components/unicards/card-info.vue';
// TODO: Магазины
import UnicardsShops from '../templates/admin/components/unicards/shops/list.vue';
import UnicardsShopsAdd from '../templates/admin/components/unicards/shops/add-edit.vue';
import UnicardsShopsList from '../templates/admin/components/unicards/consignment/list.vue';
import UnicardsShopsTransfer from '../templates/admin/components/unicards/consignment/new-shop-consignment.vue';
import UnicardsShopsSales from '../templates/admin/components/unicards/shops/sales.vue';
import UnicardsShopsInfoCard from '../templates/admin/components/unicards/card-info';
// TODO: Основные партии
import UnicardsConsigmentMain from '../templates/admin/components/unicards/consignment/list.vue';
import UnicardsConsigmentListMain from '../templates/admin/components/unicards/list-cards.vue';
import UnicardsConsigmentInfoMain from '../templates/admin/components/unicards/card-info.vue';
import UnicardsConsigmentNewMain from '../templates/admin/components/unicards/consignment/new-main-consignment.vue';
// TODO: Магазинные партии
import UnicardsConsigmentShop from '../templates/admin/components/unicards/consignment/list.vue';
import UnicardsConsigmentListShop from '../templates/admin/components/unicards/list-cards.vue';
import UnicardsConsigmentInfoShop from '../templates/admin/components/unicards/card-info.vue';
import UnicardsConsigmentNewShop from '../templates/admin/components/unicards/consignment/new-shop-consignment.vue';
// TODO: Отчеты
import UnicardsReports from '../templates/admin/components/unicards/reports/reports';
import UnicardsReportsMore from '../templates/admin/components/unicards/reports/more';
// Settings
import SettingsPage from '../templates/admin/components/settings/list.vue';
// Catalogs
import CatalogsPage from '../templates/admin/components/catalogs/catalogs.vue';
// Error
import Page404 from '../templates/admin/components/error/page-404.vue';
import Page403 from '../templates/admin/components/error/page-403.vue';
import {routePerms} from "../route_perms";
// import Main from '../templates/pages/main.vue';
// import Popups from '../templates/pages/popups.vue';
// import Search from '../templates/pages/search.vue';
// import Settings from '../templates/pages/settings.vue';
// import Tutorial from '../templates/pages/tutorial.vue';
// import EditTutorial from '../templates/pages/tutorial-edit.vue';
// import Welcome from '../templates/pages/welcome.vue';
// import Page404 from '../templates/pages/page-404.vue';
// import {User} from './models/user';
// import PassRestoreConfirm from '../templates/pages/pass-restore-confirm.vue';
// import Favorites from '../templates/pages/favorites.vue';
// import Pages from '../templates/pages/pages.vue';
// import AuthCallback from '../templates/pages/social-redirect.vue';

var ADMIN_PATH = '';

export const admin_routes = [
    {path: ADMIN_PATH + '/', component: Login, name: 'login'},
    // Dashboard
    {path: ADMIN_PATH + '/dashboard/', component: DashboardPage, name: 'dashboard-page'},
    // Subscribers
    {
        path: ADMIN_PATH + '/subscribers/', component: SubscribersList, name: 'subscribers-list',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'subscribers_access',
            }
        }
    },
    {
        path: ADMIN_PATH + '/subscribers/add/', component: SubscribersAdd, name: 'subscribers-add',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'subscribers_add_edit',
            }
        }
    },
    {
        path: ADMIN_PATH + '/subscribers/:id([A-Za-z0-9_-]+)/', component: SubscribersItem, name: 'subscribers-item',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'subscribers_access',
            }
        }
    },
    // Point connections
    {
        path: ADMIN_PATH + '/subscriber-connections/:id([A-Za-z0-9_-]+)/add/',
        component: SubscriberConnectionsAdd,
        name: 'subscribers-connections-add',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'subscribers_add_edit',
            }
        }
    },
    {
        path: ADMIN_PATH + '/subscriber-connections/:id([A-Za-z0-9_-]+)/',
        component: SubscriberConnectionsItem,
        name: 'subscribers-connections-item',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'subscribers_access',
            }
        }
    },
    // Groups subscribers
    {
        path: ADMIN_PATH + '/groups/', component: GroupsList, name: 'groups-list',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'catalog_subscriber_groups_access',
            }
        }
    },
    {
        path: ADMIN_PATH + '/groups/add/:id([A-Za-z0-9_-]+)/', component: GroupsAdd, name: 'groups-add',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'catalog_subscriber_groups_add_edit',
            }
        }
    },
    {
        path: ADMIN_PATH + '/groups/edit/:id([A-Za-z0-9_-]+)/', component: GroupsEdit, name: 'groups-edit',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'catalog_subscriber_groups_add_edit',
            }
        }
    },
    // Departments
    {
        path: ADMIN_PATH + '/departments/', component: DepartmentsList, name: 'departments-list',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'catalog_departments_access',
            }
        }
    },
    {
        path: ADMIN_PATH + '/departments/add/:id([A-Za-z0-9_-]+)/', component: DepartmentsAdd, name: 'departments-add',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'catalog_departments_access',
            }
        }
    },
    {
        path: ADMIN_PATH + '/departments/edit/:id([A-Za-z0-9_-]+)/',
        component: DepartmentsEdit,
        name: 'departments-edit',
        beforeEnter: routePerms,
        meta: {
            perms: {
                permissions: 'catalog_departments_access',
            }
        }
    },
    // Connection types
    {
        path: ADMIN_PATH + '/connection-types/', component: ConnectionTypesList, name: 'connection_types-list',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'catalog_connection_types_access',
            }
        }
    },
    {
        path: ADMIN_PATH + '/connection-types/add/', component: ConnectionTypesAdd, name: 'connection_types-add',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'catalog_connection_types_add_edit',
            }
        }
    },
    {
        path: ADMIN_PATH + '/connection-types/view/:id([A-Za-z0-9_-]+)/',
        component: ConnectionTypesView,
        name: 'connection_types-view',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'catalog_connection_types_access',
            }
        }
    },
    {
        path: ADMIN_PATH + '/connection-types/edit/:id([A-Za-z0-9_-]+)/',
        component: ConnectionTypesEdit,
        name: 'connection_types-edit',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'catalog_connection_types_add_edit',
            }
        }
    },
    // NAS
    {
        path: ADMIN_PATH + '/nas/', component: NasList, name: 'nas-list',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'catalog_nas_access',
            }
        }
    },
    {
        path: ADMIN_PATH + '/nas/view/:id([A-Za-z0-9_-]+)/', component: NasView, name: 'nas-view',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'catalog_nas_access',
            }
        }
    },
    {
        path: ADMIN_PATH + '/nas/add/', component: NasAdd, name: 'nas-add',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'catalog_nas_add_edit',
            }
        }
    },
    {
        path: ADMIN_PATH + '/nas/edit/:id([A-Za-z0-9_-]+)/', component: NasEdit, name: 'nas-edit',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'catalog_nas_add_edit',
            }
        }
    },
    // Tariffs
    {
        path: ADMIN_PATH + '/tariffs/', component: TariffsList, name: 'tariffs-list',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'catalog_tariffs_access',
            }
        }
    },
    {
        path: ADMIN_PATH + '/tariffs/add/', component: TariffsAdd, name: 'tariffs-add', beforeEnter:
        routePerms, meta: {
            perms: {
                permissions: 'catalog_tariffs_add_edit',
            }
        }
    },
    {
        path: ADMIN_PATH + '/tariffs/edit/:id([A-Za-z0-9_-]+)/', component: TariffsEdit, name: 'tariffs-edit',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'catalog_tariffs_add_edit',
            }
        }
    },
    {
        path: ADMIN_PATH + '/tariffs/view/:id([A-Za-z0-9_-]+)/', component: TariffsView, name: 'tariffs-view',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'catalog_tariffs_access',
            }
        }
    },
    {
        path: ADMIN_PATH+'/tariff-mass-transfers/', component: TariffMassTransferList, name: 'tariff-mass-transfers-list',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'catalog_tariffs_access',
            }
        }
    },
    // { path: '/tariffs/transfer/:id([A-Za-z0-9_-]+)/', component: TariffsTransfer, name: 'tariffs-transfer'},
    // Addresses
    {
        path: ADMIN_PATH + '/addresses/', component: AddressesList, name: 'addresses-list',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'catalog_settlements_access',
            }
        }
    },
    // Employees
    {
        path: ADMIN_PATH + '/employees/', component: EmployeesList, name: 'employees-list',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'catalog_employees_access,catalog_employee_positions_access',
                comparison: 'OR'
            }
        }
    },
    {
        path: ADMIN_PATH + '/employees/add/', component: EmployeesAdd, name: 'employees-add',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'catalog_employees_access',
            }
        }
    },
    {
        path: ADMIN_PATH + '/employees/edit/:id([A-Za-z0-9_-]+)/', component: EmployeesEdit, name: 'employees-edit',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'catalog_employees_access',
            }
        }
    },
    {
        path: ADMIN_PATH + '/employees/view/:id([A-Za-z0-9_-]+)/', component: EmployeesView, name: 'employees-view',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'catalog_employees_access',
            }
        }
    },
    // Position employees
    {
        path: ADMIN_PATH + '/employees/position/', component: EmployeesPosition, name: 'employees-position',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'catalog_employee_positions_access',
            }
        }
    },
    {
        path: ADMIN_PATH + '/employees/position/view/:id([A-Za-z0-9_-]+)/',
        component: EmployeesPositionView,
        name: 'employees-position-view',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'catalog_employee_positions_access',
            }
        }
    },
    {
        path: ADMIN_PATH + '/employees/position/add', component: EmployeesPositionAdd, name: 'employees-position-add',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'catalog_employee_positions_add_edit',
            }
        }
    },
    {
        path: ADMIN_PATH + '/employees/position/edit/:id([A-Za-z0-9_-]+)/',
        component: EmployeesPositionEdit,
        name: 'employees-position-edit',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'catalog_employee_positions_add_edit',
            }
        }
    },
    // IP Address
    {
        path: ADMIN_PATH + '/ip-address/', component: IPaddressList, name: 'ipaddress-list',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'catalog_ip_address_access',
            }
        }
    },
    {
        path: ADMIN_PATH + '/ip-address/pools', component: IPaddressPools, name: 'ipaddress-pools',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'catalog_ip_address_access',
            }
        }
    },
    // MAC Address
    {
        path: ADMIN_PATH + '/mac-address/', component: MACaddressList, name: 'macaddress-list',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'catalog_mac_address_access',
            }
        }
    },
    // Services
    {
        path: ADMIN_PATH + '/services/', component: ServicesList, name: 'services-list',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'catalog_additional_services_service_access,catalog_additional_services_personal_access',
                comparison: 'OR'
            }
        }
    },
    {
        path: ADMIN_PATH + '/services/ip-address', component: ServicesIpAddress, name: 'services-ip-address',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'catalog_additional_services_personal_access',
            }
        }
    },
    {
        path: ADMIN_PATH + '/services/tv-triniti', component: ServicesTV, name: 'services-iptv-triniti',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'catalog_additional_services_personal_access',
            }
        }
    },
    {
        path: ADMIN_PATH + '/services/tariff-triniti', component: TariffTriniti, name: 'tariff-triniti',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'catalog_additional_services_personal_access',
            }
        }
    },
    // Reports
    {
        path: ADMIN_PATH + '/reports/', component: ReportsPage, name: 'reports-page',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'reports_access',
            }
        }
    },
    {
        path: ADMIN_PATH + '/reports/finance/', component: ReportsFinance, name: 'reports-finance',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'reports_finances_access',
            }
        }
    },
    {
        path: ADMIN_PATH + '/reports/subscribers/', component: ReportsSubscribers, name: 'reports-subscribers',
        // beforeEnter: routePerms, meta: {
        //     perms: {
        //         permissions: 'reports_subscribers_access',
        //     }
        // }
    },
    {
        path: ADMIN_PATH + '/reports/nas/', component: ReportsNas, name: 'reports-nas',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'reports_nas_access',
            }
        }
    },
    {
        path: ADMIN_PATH + '/reports/tariffs/', component: ReportsTariffs, name: 'reports-tariffs',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'reports_tariffs_access',
            }
        }
    },
    {
        path: ADMIN_PATH + '/reports/departments/', component: ReportsDepartments, name: 'reports-departments',
        // beforeEnter: routePerms, meta: {
        //     perms: {
        //         permissions: 'reports_departments_full_access,reports_departments_partial_access',
        //         comparison: 'OR'
        //     }
        // }
    },
    {
        path: ADMIN_PATH + '/reports/replenishment/', component: ReportsReplenishment, name: 'reports-replenishment',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'reports_access',
            }
        }
    },
    {
        path: ADMIN_PATH + '/reports/settlements/', component: ReportsSettlements, name: 'reports-settlements',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'reports_access',
            }
        }
    },
    {
        path: ADMIN_PATH+'/reports/services/', component: ReportsServices, name: 'reports-services',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'reports_access',
            }
        }
    },
    {
        path: ADMIN_PATH+'/reports/network/', component: ReportsNetwork, name: 'reports-network',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'reports_access',
            }
        }
    },
    {
        path: ADMIN_PATH+'/reports/subscribers-leak/', component: ReportsSubscribersLeak, name: 'reports-subscribers-leak',
        props: {reportName: "subscribers-leak"},
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'reports_access',
            }
        }
    },
        {
        path: ADMIN_PATH+'/reports/payment-delay/', component: ReportsSubscribersLeak, name: 'reports-payment-delay',
        props: {reportName: "payment-delay"},
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'reports_access',
            }
        }
    },
    {
        path: ADMIN_PATH+'/reports/analytics/', component: ReportsAnalytics, name: 'reports-analytics',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'reports_access,reports_analytic_access',
            }
        }
    },
    // Unicards
    {
        path: ADMIN_PATH + '/unicards/', component: UnicardsList, name: 'unicards-list',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'unicards_info_access,unicards_user_access,unicards_super_access',
                comparison: 'OR'
            }
        }
    },
    {
        path: ADMIN_PATH + '/unicards/genaration', component: UnicardsGeneration, name: 'unicards-generation',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'unicards_super_access',
            }
        }
    },
    {
        path: ADMIN_PATH + '/unicards/list-cards', component: UnicardsListCards, name: 'unicards-list-cards',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'unicards_info_access,unicards_user_access,unicards_super_access',
                comparison: 'OR'
            }
        }
    },
    {
        path: ADMIN_PATH + '/unicards/cards-info/:id([A-Za-z0-9_-]+)/',
        component: UnicardsCardsInfo,
        name: 'unicards-cards-info',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'unicards_info_access,unicards_user_access,unicards_super_access',
                comparison: 'OR'
            }
        }
    },
    // Unicards shops
    {
        path: ADMIN_PATH + '/unicards/shops', component: UnicardsShops, name: 'unicards-shops',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'unicards_user_access,unicards_super_access',
                comparison: 'OR'
            }
        }
    },
    {
        path: ADMIN_PATH + '/unicards/shops/add', component: UnicardsShopsAdd, name: 'unicards-shops-add',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'unicards_user_access,unicards_super_access',
                comparison: 'OR'
            }
        }
    },
    {
        path: ADMIN_PATH + '/unicards/shops/edit/:id([A-Za-z0-9_-]+)/',
        component: UnicardsShopsAdd,
        name: 'unicards-shops-edit',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'unicards_user_access,unicards_super_access',
                comparison: 'OR'
            }
        }
    },
    {
        path: ADMIN_PATH + '/unicards/shops/consignment/:shop_id([A-Za-z0-9_-]+)/',
        component: UnicardsShopsList,
        name: 'unicards-shops-list',
        props: {type: 'shop'},
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'unicards_user_access,unicards_super_access',
                comparison: 'OR'
            }
        }
    },
    {
        path: ADMIN_PATH + '/unicards/shops/consignment/info/:id([A-Za-z0-9_-]+)/',
        component: UnicardsShopsInfoCard,
        name: 'unicards-shops-info-card',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'unicards_user_access,unicards_super_access',
                comparison: 'OR'
            }
        }
    },
    {
        path: ADMIN_PATH + '/unicards/shops/consignment/sales/:id([A-Za-z0-9_-]+)/',
        component: UnicardsShopsSales,
        name: 'unicards-shops-sales',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'unicards_user_access,unicards_super_access',
                comparison: 'OR'
            }
        }
    },
    {
        path: ADMIN_PATH + '/unicards/shops/transfer/:id([A-Za-z0-9_-]+)/',
        component: UnicardsShopsTransfer,
        name: 'unicards-shops-transfer',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'unicards_user_access,unicards_super_access',
                comparison: 'OR'
            }
        }
    },
    // Unicards consignment
    {
        path: ADMIN_PATH + '/unicards/consignment/main/',
        component: UnicardsConsigmentMain,
        name: 'unicards-consignment-main',
        props: {type: 'main'},
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'unicards_user_access,unicards_super_access',
                comparison: 'OR'
            }
        }
    },
    {
        path: ADMIN_PATH + '/unicards/consignment/shop/',
        component: UnicardsConsigmentShop,
        name: 'unicards-consignment-shop',
        props: {type: 'shop'},
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'unicards_user_access,unicards_super_access',
                comparison: 'OR'
            }
        }
    },
    {
        path: ADMIN_PATH + '/unicards/consignment/new-main/',
        component: UnicardsConsigmentNewMain,
        name: 'unicards-consignment-new-main',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'unicards_user_access,unicards_super_access',
                comparison: 'OR'
            }
        }
    },
    {
        path: ADMIN_PATH + '/unicards/consignment/main/:id([A-Za-z0-9_-]+)/',
        component: UnicardsConsigmentListMain,
        name: 'unicards-consignment-list-main',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'unicards_user_access,unicards_super_access',
                comparison: 'OR'
            }
        }
    },
    {
        path: ADMIN_PATH + '/unicards/consignment/main/card-info/:id([A-Za-z0-9_-]+)/',
        component: UnicardsConsigmentInfoMain,
        name: 'unicards-consignment-info-main',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'unicards_user_access,unicards_super_access',
                comparison: 'OR'
            }
        }
    },
    {
        path: ADMIN_PATH + '/unicards/consignment/shop/:id([A-Za-z0-9_-]+)/',
        component: UnicardsConsigmentListShop,
        name: 'unicards-consignment-list-shop',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'unicards_user_access,unicards_super_access',
                comparison: 'OR'
            }
        }
    },
    {
        path: ADMIN_PATH + '/unicards/consignment/shop/card-info/:id([A-Za-z0-9_-]+)/',
        component: UnicardsConsigmentInfoShop,
        name: 'unicards-consignment-info-shop',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'unicards_user_access,unicards_super_access',
                comparison: 'OR'
            }
        }
    },
    {
        path: ADMIN_PATH + '/unicards/consignment/new-shop/',
        component: UnicardsConsigmentNewShop,
        name: 'unicards-consignment-new-shop',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'unicards_user_access,unicards_super_access',
                comparison: 'OR'
            }
        }
    },
    // Unicards reports
    {
        path: ADMIN_PATH + '/unicards/reports/',
        component: UnicardsReports,
        name: 'unicards-reports',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'unicards_all_reports_access',
            }
        }
    },
    {
        path: ADMIN_PATH + '/unicards/reports/:id([A-Za-z0-9_-]+)/',
        component: UnicardsReportsMore,
        name: 'unicards-reports-more',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'unicards_all_reports_access',
            }
        }
    },
    // Settings
    {path: ADMIN_PATH + '/settings/', component: SettingsPage, name: 'settings-page'},
    // Catalogs
    {
        path: ADMIN_PATH + '/catalogs/', component: CatalogsPage, name: 'catalogs-all',
        beforeEnter: routePerms, meta: {
            perms: {
                permissions: 'catalog_subscriber_groups_access,catalog_tariffs_access,catalog_nas_access,catalog_ip_address_access,' +
                    'catalog_mac_address_access,catalog_departments_access,catalog_employees_access,catalog_connection_types_access,' +
                    'catalog_settlements_access,catalog_employee_positions_access',
                comparison: 'OR'
            }
        }
    },
    // Error
    {path: ADMIN_PATH + '/404/', component: Page404, name: 'page404'},
    {path: ADMIN_PATH + '/403/', component: Page403, name: 'page403'},
    {path: ADMIN_PATH + '/*', redirect: '/404'}

];

