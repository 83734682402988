<template>
    <div>
        <template v-if="with_type_selected">

            <div class="add-data__item">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                        <label><i class="fal fa-server"></i> Серия: <span
                                class="control"><i class="fas fa-star-of-life"></i></span></label>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <label class="radio">
                            <input type="radio" v-model="selected_type"
                                   v-bind:value="'set'" required/>
                            <div class="radio__text">Серия</div>
                        </label>
                        <label class="radio">
                            <input type="radio" v-model="selected_type"
                                   v-bind:value="'main'" required/>
                            <div class="radio__text">Партия</div>
                        </label>
                    </div>
                </div>
            </div>

            <select-unicard-set v-if="selected_type === 'set'"
                                :available_series="available_series"
                                :errors="errors"
                                @update_unicard_set="update_unicard_set"
                                ></select-unicard-set>

            <select-unicard-batch v-if="selected_type === 'main'"
                                  :available_batch_series="available_batch_series"
                                  :errors="errors"
                                  @update_unicard_batch="update_unicard_batch"
                                  ></select-unicard-batch>

        </template>

        <template v-else>

            <select-unicard-set v-if="available_series"
                                :available_series="available_series"
                                :errors="errors"
                                @update_unicard_set="update_unicard_set"
                                ></select-unicard-set>

            <select-unicard-batch v-if="available_batch_series"
                                  :available_batch_series="available_batch_series"
                                  :errors="errors"
                                  @update_unicard_batch="update_unicard_batch"
                                  ></select-unicard-batch>
        </template>

        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <label><i class="fal fa-server"></i> Диапазон от: <span
                            class="control"><i class="fas fa-star-of-life"></i></span></label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <div class="input-group">
                        <input type="text" v-model="number_from" placeholder="00000">
                        <input type="text" v-model="number_to" placeholder="00000">
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <label>
                        Кол-во: <b>{{ count_unicards }}</b>
                    </label>
                </div>
            </div>
            <form-error v-bind:value="errors.number_from || errors.number_to"></form-error>
        </div>
    </div>
</template>

<script>
    import FormErrors from '../../../form/errors.vue';
    import {UnicardsMixin} from "../../../../../../mixins/admin/unicards";
    import SelectUnicardSet from "./select_unicard_set";
    import SelectUnicardBatch from "./select_unicard_batch";

    export default {
        name: "additional_consigment",
        props: ["index", "with_type_selected", "available_series", "available_batch_series", "errors"],
        data(){
            return {
                selected_type: "set",
                unicard_batch: "",
                unicard_set: "",
                number_from: "",
                number_to: "",
            }
        },
        mixins: [UnicardsMixin],
        components: {
            "form-error": FormErrors,
            SelectUnicardSet,
            SelectUnicardBatch,
        },
        computed: {
            count_unicards(){
                if(this.number_to && this.number_from) {
                    var count = this.number_to - this.number_from + 1;
                    if (count > 0) {
                        return count
                    }
                }
                return 0;
            }
        },
        methods: {
            emit(){
                this.$emit("update_unicard_set", {
                    'unicard_set': this.unicard_set,
                    'unicard_batch': this.unicard_batch,
                    'index': this.index,
                    'number_from': this.number_from,
                    'number_to': this.number_to,
                });
            },
            update_unicard_set(obj){
                this.unicard_set = obj.unicard_set;
                this.emit();
            },
            update_unicard_batch(obj){
                this.unicard_batch = obj.unicard_batch;
                this.emit();
            }
        },
        watch: {
            selected_type(value){
                if(this.selected_type === 'set') {
                    this.unicard_batch = '';
                }else if(this.selected_type === 'main'){
                    this.unicard_set = '';
                }
                this.emit();
            },
            number_from(value){
                this.emit();
            },
            number_to(value){
                this.emit();
            }
        }
    }
</script>

<style scoped>

</style>