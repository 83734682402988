<template>
    <div>
        <template v-if="item_service.charging_type === 'month'">
            <i class="fal fa-hryvnia"></i> {{ item_service.charging_amount_month }}
        </template>
        <template v-if="item_service.charging_type === 'day'">
            <i class="fal fa-hryvnia"></i> {{ item_service.charging_amount_month }} / <i class="fal fa-hryvnia"></i> {{ item_service.charging_amount_day }}
        </template>
        <template v-if="item_service.charging_type === 'hour'">
            <i class="fal fa-hryvnia"></i> {{ item_service.charging_amount_hour }}
        </template>
    </div>
</template>

<script>
    export default {
        name: "charging_type",
        props: ['item_service']
    }
</script>