<template>
    <section class="page">
        <div class="page__inner">
            <div class="container-width">
                <!--HEADER PAGE-->
                <div class="page__header">
                    <h1 class="page__title">Отчеты по отделам</h1>
                    <report-menu :active_name="'reports-page'"></report-menu>
                </div>
                <!--END HEADER PAGE-->
                <div class="row">
                    <!-- CONTENT PAGE -->
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="page-body">
                            <form class="add-data">
                                <div class="row">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div class="add-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                                    <label><i class="fal fa-server"></i> Отдел: <span class="control"><i
                                                            class="fas fa-star-of-life"></i></span></label>
                                                </div>
                                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-9">
                                                    <div class="selected">
                                                        <select v-model="form.department" required>
                                                            <option value="" disabled hidden>Выберите отдел</option>
                                                            <option v-for="item in departments" v-bind:value="item.id" >{{ item.name }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <form-error v-bind:value="errors.department"></form-error>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                        <div class="add-data__item">
                                            <div class="row">
                                                <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
                                                    <label><i class="fal fa-map-pin"></i> Тип времени: <span
                                                            class="control"><i
                                                            class="fas fa-star-of-life"></i></span></label>
                                                </div>
                                                <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
                                                    <label class="radio">
                                                        <input type="radio" v-model="date_range_type"
                                                               v-bind:value="'month'" required/>
                                                        <div class="radio__text">Месяц</div>
                                                    </label>
                                                    <label class="radio">
                                                        <input type="radio" v-model="date_range_type"
                                                               v-bind:value="'interval'" required/>
                                                        <div class="radio__text">Интервал</div>
                                                    </label>
                                                </div>
                                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">

                                                    <template v-if="date_range_type === 'interval'">
                                                        <date-range-picker
                                                                :startDate="startDate"
                                                                :endDate="endDate"
                                                                @update="update_range_picker"
                                                                :locale-data="locale">
                                                        </date-range-picker>
                                                    </template>

                                                    <template v-if="date_range_type === 'month'">
                                                        <date-picker v-model="date_month" lang="ru" placeholder="Месяц"
                                                             format="MM-YYYY" type="month"></date-picker>
                                                    </template>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                                        <div class="add-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                                                    <label><i class="fal fa-map-pin"></i> Тип отчета: <span
                                                            class="control"><i
                                                            class="fas fa-star-of-life"></i></span></label>
                                                </div>
                                                <template v-if="is_full_report_access()">
                                                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">
                                                        <label class="radio">
                                                            <input type="radio" v-model="form.report_type"
                                                                   v-bind:value="'all'" required/>
                                                            <div class="radio__text">Полный</div>
                                                        </label>
                                                        <label class="radio">
                                                            <input type="radio" v-model="form.report_type"
                                                                   v-bind:value="'simple'" required/>
                                                            <div class="radio__text">Упрощенный</div>
                                                        </label>
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">
                                                        <label class="radio">
                                                            <input type="radio" v-model="form.report_type"
                                                                   v-bind:value="'simple'" required/>
                                                            <div class="radio__text">Упрощенный</div>
                                                        </label>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                                        <div class="add-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                    <label><i class="fal fa-map-pin"></i> Тип платежей: <span
                                                            class="control"><i
                                                            class="fas fa-star-of-life"></i></span></label>
                                                </div>
                                                <template v-if="is_full_report_access()">
                                                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                        <label class="radio">
                                                            <input type="radio" v-model="form.payment_type"
                                                                   v-bind:value="'all'" required/>
                                                            <div class="radio__text">Все</div>
                                                        </label>
                                                        <label class="radio">
                                                            <input type="radio" v-model="form.payment_type"
                                                                   v-bind:value="'department'" required/>
                                                            <div class="radio__text">Отдела</div>
                                                        </label>
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <label class="radio">
                                                        <input type="radio" v-model="form.payment_type"
                                                               v-bind:value="'department'" required/>
                                                        <div class="radio__text">Отдела</div>
                                                    </label>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                    <form-error v-bind:value="errors.non_field_errors"></form-error>

                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div class="add-data__footer">
                                            <span @click="generate()" class="btn green"><i class="fal fa-sync-alt"></i> Сгенерировать</span>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <!-- END CONTENT PAGE -->
                    <departments-results v-if="Object.keys(results).length"
                                         :results="results"
                                         :date_from="form.date_used__date_gte"
                                         :date_to="form.date_used__date_lte"
                                         :department="get_department()" ></departments-results>

                    <departments-form-send v-if="Object.keys(results).length"
                                           :department_form="form"
                                           :department="get_department()"
                                           :date_from="form.date_used__date_gte"
                                           :date_to="form.date_used__date_lte"></departments-form-send>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import FormErrors from '../form/errors.vue';
    import ReportMenu from './menu/menu.vue';
    import DepartmentsResults from "./sub_components/departments_results";
    import DepartmentsFormSend from "./sub_components/departments-form-send";
    import {DepartmentMixin} from "../../../../mixins/admin/department";
    import {User} from "../../../../mixins/admin/user";
    import DatePicker from 'vue2-datepicker';
    import {DateRangePickerDefaults} from "../../../../mixins/admin/date_range_picker_defaults";
    import {API} from "../../../../api";
    import {RouteHelperMixin} from "../../../../mixins/admin/route_helper";

    export default {
        name: "departments",
        mixins: [User, DepartmentMixin, DateRangePickerDefaults, RouteHelperMixin],
        data(){
            return {
                departments: [],
                date_range_type: "month",
                startDate: this.$moment().startOf('month'),
                endDate: this.$moment().endOf('month'),
                date_month: this.$moment().startOf('month'),
                form: {
                    department: "",
                    date_used__date_gte: "",
                    date_used__date_lte: "",
                    report_type: "simple",
                    payment_type: "department",
                },
                results: {},
                errors: {}
            }
        },
        components: {
            DepartmentsFormSend,
            DepartmentsResults,
            "report-menu": ReportMenu,
            "date-picker": DatePicker,
            "form-error": FormErrors,
        },
        mounted(){
            this.fetchUser();
        },
        methods: {
            fetchUser(){
                this.load_departments_employee_persmissions();
                this.check_type_report();
                this.set_data_from_route([
                        {'name': 'department', 'type': 'str'},
                        {'name': 'date_used__date_gte', 'type': 'str'},
                        {'name': 'date_used__date_lte', 'type': 'str'},
                        {'name': 'report_type', 'type': 'str'},
                        {'name': 'payment_type', 'type': 'str'},
                    ],
                    this.form);

                this.set_data_from_route([{'name': 'date_range_type', 'type': 'str'}], this);

                if (this.date_range_type === 'month') {
                    this.set_data_from_route([{'name': 'date_month', 'type': 'str'}], this);

                } else {
                    this.set_data_from_route([
                            {'name': 'startDate', 'type': 'date'},
                            {'name': 'endDate', 'type': 'date'},
                        ],
                        this);
                    this.update_range_picker({startDate: this.startDate, endDate: this.endDate});
                }

                this.change_date_range_type();

                // Auto generate
                if (this.form.date_used__date_lte && this.form.date_used__date_gte && this.form.department) {
                    this.generate();
                }
            },
            reset(){
                this.results = {};
            },
            check_type_report() {
                if (this.is_full_report_access()){
                    this.form.report_type = 'all';
                    this.form.payment_type = 'all';
                } else {
                    this.form.report_type = 'simple';
                    this.form.payment_type = 'department';
                }
            },
            is_full_report_access(){
                // Check employee_department permission
                return this.has_perm_department(this.form.department, "is_report_department_full")
            },
            generate(){
                var self = this;
                self.errors = {};

                API.get('/report/department/', this.form)
                    .then(function (response) {
                        self.results = response.data;
                        self.change_route();
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            },
            get_department(){
                var selected_department = {};

                for(var department of this.departments){
                    if(department.id === this.form.department){
                        selected_department = department;
                    }
                }
                return selected_department
            },
            update_range_picker(date_range) {
                this.form.date_used__date_gte = this.$moment(date_range.startDate).format("YYYY-MM-DD");
                this.form.date_used__date_lte = this.$moment(date_range.endDate).format("YYYY-MM-DD");
                this.startDate = date_range.startDate;
                this.endDate = date_range.endDate;
            },
            updated_month_picker(){
                this.form.date_used__date_gte = this.$moment(this.date_month).format("YYYY-MM-DD");
                this.form.date_used__date_lte = this.$moment(this.date_month).endOf('month').format("YYYY-MM-DD");
            },
            change_route(){
                this.$router.replace({
                    query: Object.assign({}, this.$route.query,
                        {
                            department: this.form.department,
                            date_used__date_gte: this.form.date_used__date_gte,
                            date_used__date_lte: this.form.date_used__date_lte,
                            report_type: this.form.report_type,
                            payment_type: this.form.payment_type,
                            date_range_type: this.date_range_type,
                            startDate: this.$moment(this.startDate).format("YYYY-MM-DD"),
                            endDate: this.$moment(this.endDate).format("YYYY-MM-DD"),
                            date_month: this.$moment(this.date_month).format("YYYY-MM-DD"),
                        }),
                });
            },
            change_date_range_type(){
                if(this.date_range_type === 'month'){
                    this.updated_month_picker();
                } else {
                    this.update_range_picker({startDate: this.startDate, endDate: this.endDate});
                }
            }
        },
        watch: {
            form: {
                handler() {
                    this.reset();
                },
                deep: true
            },
            "form.department"(){
                if(!this.has_perm_department(this.form.department, "is_report_department_full")){
                    this.form.report_type = "simple";
                    this.form.payment_type = "department";
                }
            },
            date_month(){
                this.updated_month_picker()
            },
            date_range_type(){
                this.change_date_range_type();
            }
        }
    }
</script>

<style scoped>

</style>