import {UserBase} from "./mixins/user_base";
import {API} from "./api";
import store from './store';

export async function routePerms (to, from, next) {
    /// Check Permission for route
    API.access_token = store.getters['user/getAccessToken'];

    try {
        let response = await API.get('/user/current/');
        UserBase.methods.set_user_by_data(response.data);
    } catch(err) {
        next({'name': "page403"});
    }

    if(UserBase.methods.has_perm(to.meta.perms)) {
        next();
    } else {
        next({'name': "page403"});
    }
}