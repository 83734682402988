<template>
    <popup :settings="settings">
        <div slot="title">Дополнительные услуги</div>
        <div slot="content">
            <div class="body-size">
                <div class="body-size__480">
                    <form class="form-modal">
                        <div class="form-modal__group">
                            <fieldset class="form-modal__item">
                                <span class="form-modal__title">
                                <span class="form-modal__icon"><i class="fal fa-toolbox"></i></span>
                                    Выберите тип услуги
                                </span>
                                <div class="form-modal__select">
                                    <select v-model="service_object_type" required>
                                        <option value="">Выберите услугу</option>
                                        <option v-for="service in services" :value="service.object_type">{{ service.object_name }}
                                        </option>
                                    </select>
                                </div>
                                <form-error v-bind:value="errors.tariff"></form-error>
                            </fieldset>
                        </div>

                        <!--Services Components -->
                        <service-ip-address v-if="service_object_type === 'service_ip_address'"
                                            :tariff="tariff"
                                            :service="service"
                                            :service_errors="errors"
                                            @set_form_data="set_form_data">
                        </service-ip-address>
                        <ServiceTV v-if="service_object_type === 'service_iptv_triniti'"
                                   :tariff="tariff"
                                   :service="service"
                                   :service_errors="errors"
                                   @set_form_data="set_form_data">
                        </ServiceTV>

                    </form>
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="btn-group">
                <span class="btn btn_grey" @click="popup_close(); clear();">
                    <span class="btn__icon"><i class="fal fa-times-circle"></i></span>
                    <span class="btn__title">Отмена</span>
                </span>
                <span class="btn btn_blue" @click="add_service">
                    <span class="btn__icon"><i class="fal fa-check"></i></span>
                    <span class="btn__title">Добавить</span>
                </span>
            </div>
        </div>
    </popup>
</template>

<script>
    import {CrudMixin} from "../../../../../../mixins/admin/crud/crud_base";
    import Popup from '../../../modal/popup.vue';
    import FormErrors from '../../../form/errors.vue';
    import {ServiceMixin} from "../../../../../../mixins/admin/services";
    import ServiceIpAddress from "../service_forms/service_ip_address";
    import ServiceTV from "../service_forms/service_tv";

    export default {
        name: "add-service",
        props: ["settings", "tariff", "tariff_services"],
        mixins: [CrudMixin, ServiceMixin],
        components: {
            "popup": Popup,
            "form-error": FormErrors,
            ServiceIpAddress,
            ServiceTV,
        },
        data() {
            return {
                service_object_type: "",
                service: {},
                services: [],
                form_data: {},
                errors: {}
            }
        },
        mounted: function () {
            this.fetchUser()
        },
        methods: {
            fetchUser() {
                this.load_services()
            },
            add_service() {
                // TODO: change service variable list
                if (this.service_object_type === 'service_ip_address' && Object.keys(this.form_data).length) {
                    this.result();
                }else if(this.service_object_type === 'service_trust_day'){
                    this.result();
                }else if(this.service_object_type === 'service_iptv_triniti' && Object.keys(this.form_data).length){
                    this.result();
                } else {
                    this.$set(this.errors, "tariff", ["Выберите тип услуги"])
                }
            },
            result() {
                var new_service = {};

                this.$emit("added_service", Object.assign(new_service,{"service_extra_fields": this.form_data}, this.service, {"is_active": true}));
                this.popup_close();
                this.clear();
            },
            set_form_data(value){
                this.form_data = value;
                // console.log(this.form_data);
            },
            clear() {
                this.service_object_type = "";
                this.service = {};
                this.errors = {};
                this.form_data = {};
            }
        },
        watch: {
            service_object_type() {
                for (var service of this.services) {
                    if (service.object_type === this.service_object_type) {
                        this.service = service;
                    }
                }
            },
            tariff_services: function (value) {
                // Exclude exists subscriber_services
                var list = [];
                this.tariff_services.service_list.forEach(element => {
                    this.services.forEach(el => {
                        if (el.object_type !== element.service_type) {
                            list.push(el);
                        }
                    })
                });
                if (list.length) {
                    this.services = list;
                }
            },
            tariff() {
                this.load_services();
            },
        }
    }
</script>

<style scoped>

</style>