import Vue from "vue";

export const VueFilters = function () {

    Vue.filter('prettyMbit', function (bytes, decimals) {
        // kbytes to mbytes
        const dm = decimals || 2;
        return parseFloat((bytes / 1024).toFixed(dm))
    });

    Vue.filter('prettyGb', function (bytes, decimals) {
        // kbytes to gbytes
        const dm = decimals || 2;
        return parseFloat((bytes / 1024 / 1024).toFixed(dm))
    });

    Vue.filter('prettySize', function (bits, decimals, label) {
        // bits to correct size
        const dm = decimals || 2;
        label = label || {gb: "Гбайт", mb: "Мбайт", kb: "Кбайт", b: "байт"};
        var gb = bits / 1024 / 1024 / 1024 / 8;
        var mb = bits / 1024 / 1024 / 8;
        var kb = bits / 1024 / 8;
        var result = bits, byte_type;

        if (gb >= 1) {
            result = gb;
            byte_type = 'gb'
        } else if (mb >= 1) {
            result = mb;
            byte_type = 'mb'
        } else if (kb >= 1) {
            result = kb;
            byte_type = 'kb'
        } else {
            result = bits;
            byte_type = 'b'
        }

        return parseFloat((result).toFixed(dm)) + " " + label[byte_type]
    });

    Vue.filter('prettyDuration', function (seconds, decimals, label) {
        const dm = decimals || 2;
        var result = seconds;
        var type_seconds = 's';
        label = label || {h: "ч.", m: "мин.", s: "сек."};
        var h = seconds / 60 / 60;
        var m = seconds / 60;

        if (h >= 1) {
            result = h;
            type_seconds = 'h'
        } else if (m >= 1) {
            result = m;
            type_seconds = 'm'
        } else {
            result = seconds;
            type_seconds = 's'
        }

        return parseFloat((result).toFixed(dm)) + " " + label[type_seconds]
    });

    Vue.filter('abs', function (value) {
        return Math.abs(value)
    });
}