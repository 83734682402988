<template>
    <div>
        <div class="form-modal__group">
            <fieldset class="form-modal__item">
                <span class="form-modal__title">
                    <span class="form-modal__icon"><i class="fal fa-arrow-right"></i></span>
                    Пакет
                </span>
                <div class="form-modal__select">
                    <select v-model="service_ip" required>
                        <option value="">Выберите пакет</option>
                        <option v-for="item in service_ip_address" :value="item.id">{{ item.name }}</option>
                    </select>
                </div>
                <form-error v-bind:value="service_errors.service_ip_address"></form-error>
            </fieldset>
        </div>
        <div class="form-modal__group" v-if="service_ip">
            <fieldset class="form-modal__item">
                <span class="form-modal__title">
                    <span class="form-modal__icon"><i class="fal fa-arrow-right"></i></span>
                    IP
                </span>
                <div class="form-modal__select">
                    <select v-model="ip_address" required>
                        <option value="">Выберите IP</option>
                        <option v-for="item in ip_address_list" :value="item.id">{{ item.ip_address }}</option>
                    </select>
                </div>
                <form-error v-bind:value="service_errors.ip_address"></form-error>
            </fieldset>
        </div>

        <!---->
        <!--<fieldset class="add-cs__form&#45;&#45;item">-->
            <!--<p><i class="fal fa-network-wired"></i> Выберите пакет</p>-->
            <!--<div class="selected">-->
                <!--<select v-model="service_ip" required>-->
                    <!--<option value="">Выберите пакет</option>-->
                    <!--<option v-for="item in service_ip_address" :value="item.id">{{ item.name }}</option>-->
                <!--</select>-->
            <!--</div>-->
            <!--<form-error v-bind:value="service_errors.service_ip_address"></form-error>-->
        <!--</fieldset>-->

        <!--<fieldset class="add-cs__form&#45;&#45;item" v-if="service_ip">-->
            <!--<p><i class="fal fa-network-wired"></i> Выберите IP</p>-->
            <!--<div class="selected">-->
                <!--<select v-model="ip_address" required>-->
                    <!--<option value="">Выберите IP</option>-->
                    <!--<option v-for="item in ip_address_list" :value="item.id">{{ item.ip_address }}</option>-->
                <!--</select>-->
            <!--</div>-->
            <!--<form-error v-bind:value="service_errors.ip_address"></form-error>-->
        <!--</fieldset>-->
        <div class="form-service" v-if="Object.keys(service_ip_obj).length">
            <div class="form-service__total">
                <div class="form-service__total-title">Стоимость:</div>
                <div class="form-service__total-price">
                    <span class="form-service__summ">
                        <span class="form-service__summ-currency">&#x20b4</span>
                        <span class="form-service__summ-value">{{ service_ip_obj.charging_amount_month }}</span>
                        <span class="form-service__summ-period">/мес</span>
                    </span>
                    <span class="form-service__summ-divide"></span>
                    <span class="form-service__summ">
                        <span class="form-service__summ-currency">&#x20b4</span>
                        <span class="form-service__summ-value">{{ service_ip_obj.charging_amount_day }}</span>
                        <span class="form-service__summ-period">/день</span>
                    </span>
                </div>
            </div>
        </div>
        <!--<fieldset class="add-cs__form&#45;&#45;item" v-if="Object.keys(service_ip_obj).length">-->
            <!--<div class="add-cs__price">-->
                <!--<span class="add-cs__price&#45;&#45;title">Стоимость:</span>-->
                <!--<ul class="add-cs__price&#45;&#45;value">-->
                    <!--<li><i class="fal fa-hryvnia"></i>{{ service_ip_obj.charging_amount_month }}/30 дней</li>-->
                    <!--<li><i class="fal fa-hryvnia"></i>{{ service_ip_obj.charging_amount_day }}/день</li>-->
                <!--</ul>-->
            <!--</div>-->
        <!--</fieldset>-->
    </div>
</template>

<script>
    import {API} from "../../../../../../api";
    import {loader} from "../../../../../../models/loader";
    import FormErrors from '../../../form/errors.vue';

    export default {
        name: "service_ip_address",
        props: ["service", "subscriber_connection", "service_errors"],
        components: {
            "form-error": FormErrors,
        },
        data() {
            return {
                service_ip_address: [],
                ip_address_list: [],
                service_ip: "",
                service_ip_obj: {},
                ip_address: ""
            }
        },
        mounted: function () {
            this.fetchUser()
        },
        methods: {
            fetchUser() {
                this.load_service_ip_address();
                this.load_ip_address()
            },
            load_service_ip_address() {
                var self = this;

                loader.active = false;
                API.get('/services/ip-address/', {
                    group_ext: this.subscriber_connection.group,
                    department_ext: this.subscriber_connection.department,
                    subscriber_type_ext: this.subscriber_connection.subscriber_expand.subscriber_type,
                    connection_type_ext: this.subscriber_connection.connection_type,
                    charging_type: this.subscriber_connection.tariff_expand.charging_type,
                })
                    .then(function (response) {
                        self.service_ip_address = response.data.results;
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                        loader.active = true;
                    })
            },
            load_ip_address() {
                var self = this;

                API.get('/ip-address/ip-address/', {destination: 'for_rent', ordering: 'ip_address'})
                    .then(function (response) {
                        self.ip_address_list = response.data.results;
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            },
            emit_form() {
                this.$emit("set_form_data", {"service_ip_address": this.service_ip, "ip_address": this.ip_address});
            },
        },
        watch: {
            service_ip() {
                this.service_ip_obj = {}
                for (var item of this.service_ip_address) {
                    if (item.id === this.service_ip) {
                        this.service_ip_obj = item;
                    }
                }
                this.emit_form();
            },
            ip_address() {
                this.emit_form();
            }
        }
    }
</script>

<style scoped>

</style>