<template>
    <span>
        <template v-if="unicard.unicard_batch_expand && unicard.unicard_batch_expand.owner_expand">
            {{ unicard.unicard_batch_expand.owner_expand.fio }}
        </template>
        <template v-else>
            <template v-if="unicard.unicard_shop_batch_expand && unicard.unicard_shop_batch_expand.shop_expand.agent">
                {{ unicard.unicard_shop_batch_expand.shop_expand.agent_fio }}
            </template>
            <template v-else>
                <template v-if="unicard.unicard_set_expand && unicard.unicard_set_expand.owner_expand">
                    {{ unicard.unicard_set_expand.owner_expand.fio }}
                </template>
            </template>
        </template>
    </span>
</template>

<script>
    export default {
        name: "field_owner_unicard_set",
        props: ["unicard"]
    }
</script>

<style scoped>

</style>