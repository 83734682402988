<template>
    <div class="tariffs-services">
        <TurningComponent :checkboxProp="isPromotionalTariff"
                          :iconPropClass="'fal fa-badge-percent'"
                          @checkbox-changed="isPromotionalTariff = !isPromotionalTariff">
            <span slot="title">
                Настроить как акционный тариф
            </span>
        </TurningComponent>
        <PromotionalSettings v-if="isPromotionalTariff"
                             :current-tariff="tariff"
                             :parentErrors="errors"
                             @addPromotionalSettings="addPromotionalSettings"
                             @deletePromotionalSettings="deletePromotionalSettings"
        ></PromotionalSettings>
    </div>
</template>

<script>

    import PromotionalSettings from "./promotional-settings";
    import TurningComponent from "../turning-component";

    export default {
        name: "settings",
        props: ["tariff", "errors"],
        components: {
            PromotionalSettings,
            TurningComponent,
        },
        data() {
            return {
                isPromotionalTariff: false,
            }
        },
        methods: {
            addPromotionalSettings(value) {
                this.$emit("addPromotionalSettings", value);
            },
            deletePromotionalSettings() {
                this.isPromotionalTariff = false;
                this.$emit("deletePromotionalSettings");
            },
        },
        watch: {
            tariff: function (value) {
                if (value.promotional_tariff_settings_expand) {
                    if (Object.keys(value.promotional_tariff_settings_expand).length > 0) {
                        this.isPromotionalTariff = true;
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>