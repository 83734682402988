<template>
    <popup :settings="settings">
        <div slot="title">Пополнение баланса</div>
        <div slot="content">
            <div class="body-size">
                <div class="body-size__480">
                    <form class="form-modal">
                        <div class="form-modal__group">
                            <fieldset class="form-modal__item">
                                <!--<span class="form-modal__title">-->
                                <!--<span class="form-modal__icon"><i class="fal fa-credit-card-front"></i></span>-->
                                <!--Тип платежа-->
                                <!--</span>-->
                                <div class="form-modal__radio-group">
                                    <label class="form-modal__radio">
                                        <input type="radio" v-model="form.is_cash" v-bind:value="true" required/>
                                        <span class="form-modal__radio-title">Наличный</span>
                                    </label>
                                    <label class="form-modal__radio">
                                        <input type="radio" v-model="form.is_cash" v-bind:value="false" required/>
                                        <span class="form-modal__radio-title">Безналичный</span>
                                    </label>
                                </div>
                                <form-error v-bind:value="errors.is_cash"></form-error>
                            </fieldset>
                        </div>
                        <div class="form-modal__group">
                            <fieldset class="form-modal__item">
                                <span class="form-modal__title">
                                <span class="form-modal__icon"><i class="fal fa-wallet"></i></span>
                                    Сумма пополнения
                                </span>
                                <input type="text" v-model="form.charge_value" class="form-modal__input"
                                       placeholder="0">
                                <form-error v-bind:value="errors.charge_value"></form-error>
                            </fieldset>
                        </div>
                        <div class="form-modal__group">
                            <fieldset class="form-modal__item">
                                <span class="form-modal__title">
                                <span class="form-modal__icon"><i class="fal fa-comments"></i></span>
                                    Примечание
                                </span>
                                <textarea v-model="form.comment" class="form-modal__textarea"
                                          placeholder="Примечание"></textarea>
                                <form-error v-bind:value="errors.comment"></form-error>
                            </fieldset>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="btn-group">
                <span class="btn btn_grey" @click="popup_close">
                    <span class="btn__icon"><i class="fal fa-times-circle"></i></span>
                    <span class="btn__title">Отмена</span>
                </span>
                <span class="btn btn_blue" @click="save()">
                    <span class="btn__icon"><i class="fal fa-check"></i></span>
                    <span class="btn__title">Пополнить</span>
                </span>
            </div>
        </div>
    </popup>
</template>

<script>

    import Popup from '../../modal/popup.vue'
    import {CrudMixin} from "../../../../../mixins/admin/crud/crud_base";
    import FormErrors from '../../form/errors.vue';
    import {SubscriberConnectionMixin} from "../../../../../mixins/admin/subscriber_connection";
    import {API} from "../../../../../api";

    export default {
        name: "balance-up",
        props: ["settings", "subscriber_connection"],
        data() {
            return {
                form: this.form_data(),
                errors: {}
            }
        },
        mixins: [
            CrudMixin, SubscriberConnectionMixin
        ],
        components: {
            "popup": Popup,
            "form-error": FormErrors
        },
        methods: {
            form_data() {
                return {
                    charge_value: 0,
                    is_cash: true,
                    comment: ""
                }
            },
            save() {
                var self = this;
                this.charge_balance(this.subscriber_connection.id, this.form)
                    .then(function (response) {
                        self.$emit('update_subscriber_connection');
                        self.form = self.form_data();
                        self.popup_close();
                    })
                    .catch(function (error) {
                        API.default_catch(self, error);
                    })
                    .then(function () {
                        API.default_always(self);
                    });
            }
        }
    }
</script>

<style scoped>

</style>