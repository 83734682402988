<template>
    <div class="page__foo">
        <div class="page__foo-selected">
            <select v-model="limit" @change="change_route()" required>
                <option v-for="option in limitOptions" :value="option">
                    {{ option }}
                </option>
                <option v-if="addNull" :value="null">Все</option>
            </select>
        </div>
        <div class="pagination" v-on:keyup.enter="change_route()">
            <a class="pagination__btn" @click="page_set(-1, addNull); change_route()"><i class="fal fa-chevron-left"></i></a>
                <VueNumeric v-model="page" :min="1" :empty-value="1"></VueNumeric>
            <a class="pagination__btn" @click="page_set(1, addNull); change_route()"><i class="fal fa-chevron-right"></i></a>
        </div>
    </div>
</template>

<script>
import VueNumeric from 'vue-numeric';
import {PaginationMixin} from "../../../../mixins/admin/pagination";

export default {
    name: "pagination",
    mixins: [PaginationMixin],
    components: {
       VueNumeric
    },
    props: {
        pageItems: {
            type: Number,
            required: true
        },
        limitItems: {
            type: Number,
            required: true
        },
        options: {
            type: Array,
            required: true
        },
        countItems: {
            type: Number,
            required: true
        },
        addNullOption: {
            type: Boolean,
            required: true
        },
    },
    data() {
        return {
            page: this.pageItems,
            limit: this.limitItems,
            limitOptions: this.options,
            addNull: this.addNullOption, // option true if need "Все" all collection of the entities
        }
    },
    created() {
        this.$root.$on('resetQueryPaginationFilters', () => {
          this.resetQueryPaginationFilters();
        });
    },
    beforeDestroy() {
      this.destroyPaginationListeners();
    },
    methods: {
        change_route() {
            var self = this;

            // Update router
            this.$router.replace({
                query: Object.assign({}, this.$route.query,
                {
                    page: self.page,
                    limit: self.limit ? self.limit : 'all',
                    offset: self.get_offset(self.page),
                })
            });

            // emit event that load items in parent component
            this.$emit('loadItems');
        },
    },
    watch: {
        page: {
            handler(value) {
                this.setInitPage(this.page);
            },
            immediate: true
        },
        limit: {
            handler(value) {
                this.setInitLimit(this.limit);
            },
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>