<template>
  <section class="page">
    <div class="page__inner">
      <div class="container-width">
        <!--HEADER PAGE-->
        <div class="page__header">
          <h1 class="page__title">Настройки</h1>
        </div>
        <!--END HEADER PAGE-->
        <!-- BODY PAGE -->
        <div class="tabs-sc" id="tabs-settings">
          <ul class="tabs-sc__nav">
            <!--Общие настройки-->
            <li class="tabs-sc__link" v-bind:class="{ 'is-active': isActive === 'allsettings' }"
                v-on:click="isActive = 'allsettings'">
              <span class="tabs-sc__link-icon"><i class="fal fa-cogs"></i></span>
              <span class="tabs-sc__link-title">Общее</span>
            </li>
            <!--Оплата-->
            <li class="tabs-sc__link" v-bind:class="{ 'is-active': isActive === 'paymentsettings' }"
                v-on:click="isActive = 'paymentsettings'">
              <span class="tabs-sc__link-icon"><i class="fal fa-cash-register"></i></span>
              <span class="tabs-sc__link-title">Оплата</span>
            </li>
            <!--Программа лояльности-->
            <li class="tabs-sc__link" v-bind:class="{ 'is-active': isActive === 'bonussettings' }"
                v-on:click="isActive = 'bonussettings'">
              <span class="tabs-sc__link-icon"><i class="fal fa-piggy-bank"></i></span>
              <span class="tabs-sc__link-title">Программа лояльности</span>
            </li>
            <!--Бизнес модель-->
            <li class="tabs-sc__link" v-bind:class="{ 'is-active': isActive === 'bissettings' }"
                v-on:click="isActive = 'bissettings'">
              <span class="tabs-sc__link-icon"><i class="fal fa-analytics"></i></span>
              <span class="tabs-sc__link-title">Бизнес модель</span>
            </li>
          </ul>
          <div class="tabs-sc__container">
            <!--Общее настройки-->
            <div class="tabs-sc__item" v-bind:class="{ 'is-active': isActive === 'allsettings' }">
              <div class="tabs-sc__header">
                <div class="tabs-sc__title">
                  <span class="tabs-sc__action-icon"><i class="fal fa-cogs"></i></span>
                  <span class="tabs-sc__action-title">Общее настройки</span>
                </div>
              </div>
              <div class="grid">
                <div class="grid__col">
                  <div class="grid__box">
                    Hello man!
                  </div>
                </div>
              </div>
            </div>
            <!--Оплата-->
            <div class="tabs-sc__item" v-bind:class="{ 'is-active': isActive === 'paymentsettings' }">
              <div class="tabs-sc__header">
                <div class="tabs-sc__title">
                  <span class="tabs-sc__action-icon"><i class="fal fa-cash-register"></i></span>
                  <span class="tabs-sc__action-title">Способы оплаты</span>
                </div>
              </div>
              <SetPayment></SetPayment>
            </div>
            <!--Программа лояльности-->
            <div class="tabs-sc__item" v-bind:class="{ 'is-active': isActive === 'bonussettings' }">
              <div class="tabs-sc__header">
                <div class="tabs-sc__title">
                  <span class="tabs-sc__action-icon"><i class="fal fa-piggy-bank"></i></span>
                  <span class="tabs-sc__action-title">Программа лояльности</span>
                </div>
              </div>
              <SetBonus></SetBonus>
            </div>
            <!--Программа лояльности-->
            <div class="tabs-sc__item" v-bind:class="{ 'is-active': isActive === 'bissettings' }">
              <div class="tabs-sc__header">
                <div class="tabs-sc__title">
                  <span class="tabs-sc__action-icon"><i class="fal fa-analytics"></i></span>
                  <span class="tabs-sc__action-title">Бизнес модель</span>
                </div>
              </div>
              <div class="grid">
                <div class="grid__col">
                  <div class="grid__box">
                    Hello man!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END BODY PAGE -->
      </div>
    </div>
  </section>
</template>

<script>

import SetPayment from './tabs/payment';
import SetBonus from './tabs/bonus';

export default {
  name: "list",
  components: {
    SetPayment,
    SetBonus,
  },
  data: function () {
    return {
      isActive: 'allsettings',
    }
  },
}
</script>

<style scoped>

</style>