<template>
    <div class="h-auth">
        <div class="h-auth__btn" ref="auth" @click="showAuth = !showAuth" v-on:click="activationAuth" :class="{ 'h-auth__btn_active':activeAuth }">
            <span class="h-auth__btn-icon"><i class="fal fa-user"></i></span>
            <span class="h-auth__btn-title">Абонент</span>
            <span class="h-auth__btn-arrow"><i class="fal fa-chevron-down"></i></span>
        </div>
        <transition name="fade">
        <div class="h-auth__dropdown" v-show="showAuth" v-closable="{exclude: ['auth'], handler: 'authClose'}">
            <div class="h-auth__nav">
                <router-link title="Персональні дані" :to="{ name: 'personal', params: {'id': user.id}}"
                             class="h-auth__nav-link">
                    <span class="h-auth__nav-icon"><i class="fal fa-file-user"></i></span>Персональні
                    дані
                </router-link>
                <a title="Персональні дані" href="https://bel.net.ua/ua/subscribers/document"
                   class="h-auth__nav-link">
                    <span class="h-auth__nav-icon"><i class="fal fa-info-circle"></i></span>Правила та
                    умови
                </a>
                <a href="#" @click="logout()" class="h-auth__nav-link h-auth__nav-link_exit"><span
                        class="h-auth__nav-icon "><i class="fal fa-sign-out"></i></span>Вихід</a>
            </div>
        </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: "h-auth",
        data: function () {
            return {
                activeAuth: false,
                showAuth: false
            }
        },
        methods: {
            authClose() {
                this.showAuth = false
                if (this.activeAuth === true) {
                    this.activeAuth = !this.activeAuth;
                }
            },
            activationAuth: function () {
                this.activeAuth = !this.activeAuth;
            }
        },
    }
</script>

<style scoped>

</style>