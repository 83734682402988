<template>
  <tbody class="table-box__tbody">
    <tr class="table-box__tr table-data">
      <td class="table-box__td">
        <span class="table-data__text">{{ settlement.settlement_expand.name }}</span>
      </td>
      <td class="table-box__td table-box__td_center">
        <span class="table-data__text">
          <report-link :item="settlement" :field_name="linkPrefix+'_last_three_months'"></report-link>
        </span>
      </td>
      <td class="table-box__td table-box__td_center">
        <span class="table-data__text">
          <report-link :item="settlement" :field_name="linkPrefix+'_last_six_months'"></report-link>
        </span>
      </td>
      <td class="table-box__td table-box__td_center">
        <span class="table-data__text">
          <report-link :item="settlement" :field_name="linkPrefix+'_over_six_months'"></report-link>
        </span>
      </td>
      <td class="table-box__td table-box__td_center"></td>
    </tr>
  </tbody>
</template>

<script>
import ReportLink from '../sub_components/report-link';

export default {
  name: "subscribers-leak-report-settlement-info",
  props: ['settlement', 'linkPrefix'],
  components: {
    'report-link': ReportLink,
  }
}
</script>

<style scoped>

</style>