<template>
    <popup :settings="settings">
        <div slot="title">{{ region.name }}</div>
        <div slot="content">
            <div class="body-size">
                <div class="body-size__860">
                    <div class="tabs" id="tabs">
                        <ul class="tabs__nav">
                            <li class="tabs__link" v-bind:class="{ 'is-active': isActive === 'district' }"
                                v-on:click="isActive = 'district'">
                                <span class="tabs__link-icon"><i class="fal fa-map-marked-alt"></i></span>
                                <span class="tabs__link-title">Районы</span>
                            </li>
                            <li class="tabs__link" v-bind:class="{ 'is-active': isActive === 'city' }"
                                v-on:click="isActive = 'city'">
                                <span class="tabs__link-icon"><i class="fal fa-map-marker-alt"></i></span>
                                <span class="tabs__link-title">Населенные пункты</span>
                            </li>
                            <li class="tabs__link" v-bind:class="{ 'is-active': isActive === 'street' }"
                                v-on:click="isActive = 'street'">
                                <span class="tabs__link-icon"><i class="fal fa-road"></i></span>
                                <span class="tabs__link-title">Улицы</span>
                            </li>
                        </ul>
                        <div class="tabs__container">
                            <div class="tabs__item" v-bind:class="{ 'is-active': isActive === 'district' }">
                                <!--Районы-->
                                <DistrictView :region="region.id" :districts="districts" @update_list="update_list" ></DistrictView>
                            </div>
                            <div class="tabs__item" v-bind:class="{ 'is-active': isActive === 'city' }">
                                <!--Населенные пункты-->
                                <CityView :region="region.id" :city_districts="districts" @update_list="update_list" ></CityView>
                            </div>
                            <div class="tabs__item" v-bind:class="{ 'is-active': isActive === 'street' }">
                                <!--Улицы-->
                                <StreetView :region="region.id" ></StreetView>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="btn-group">
                <span class="btn btn_grey" @click="popup_close">
                    <span class="btn__icon"><i class="fal fa-times-circle"></i></span>
                    <span class="btn__title">Закрыть</span>
                </span>
            </div>
        </div>
    </popup>
</template>

<script>
    // COMPONENTS
    import Popup from '../../modal/popup.vue';
    import FormErrors from '../../form/errors.vue';

    // MIXIN
    import {CrudMixin} from "../../../../../mixins/admin/crud/crud_base";

    // DATA
    import DistrictView from './district/district-view';
    import CityView from './city/city-view';
    import StreetView from './street/street-view';
    import {AddressMixin} from "../../../../../mixins/admin/address";

    export default {
        name: "data-item",
        data() {
            return {
                districts: [],
                isActive: 'district',
                showSubAccordion: false,
            }
        },
        components: {
            "popup": Popup,
            "form-error": FormErrors,
            DistrictView,
            CityView,
            StreetView,
        },
        mixins: [CrudMixin, AddressMixin],
        props: ["settings", "region"],
        methods: {
            popup_close() {
                this.settings.visible = false;
            },
            update_list() {
                this.$emit("update_list");
                this.load_districts(this.region.id);
            }
        },
        watch: {
            region() {
                this.load_districts(this.region.id);
            }
        }
    }
</script>

<style scoped>

</style>