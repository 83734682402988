import {API} from "../../api";

export var NoteMixin = {
    data: function () {
        return {
            notes: [],
        }
    },
    methods: {
        load_notes: function (filter) {
            var self = this;
            API.get('/note/', filter)
                .then(function (response) {
                    self.notes = response.data.results;
                })
                .catch(function (error) {
                    // API.default_catch(self, error)
                })
                .then(function () {
                    // API.default_always(self);
                })
        },
        add_note: function (data) {
            return API.post('/note/', data)
        },
    }
};
