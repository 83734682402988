import Accordion from '../../templates/admin/components/accordion/accordion.vue';

export var GeographyRegionAccordionMixin = {
    components: {
        "accordion": Accordion,
    },
    data: function () {
        return {
            showSubAccordion: false
        }
    },
};