<template>
    <section class="page">
        <div class="page__inner">
            <div class="container-width">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="page-header">
                            <div class="row">
                                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <h1 class="page-header__title">Тарифы TrinitY</h1>
                                </div>
                                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <div class="page-header__btn-group">

                                        <app-has-perm-action-btn :perms="'personal_services_management_add_edit'"
                                                              @action="popup_add">
                                            <template slot-scope="{ access }">

                                                <span class="btn" :class="access ? 'green' : 'grey'">
                                                    <i class="fal fa-plus"></i>Добавить тариф
                                                </span>

                                            </template>
                                        </app-has-perm-action-btn>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="page-body">
                            <div class="table-container">
                                <table>
                                    <thead>
                                    <tr>
                                        <th><i class="fal fa-code-branch"></i>Имя тарифа</th>
                                        <th><i class="fal fa-code-branch"></i>ID тарифа</th>
                                        <th><i class="fal fa-comment-exclamation"></i>Описание тарифа</th>
                                        <th><i class="fal fa-comment-exclamation"></i>Статус</th>
                                        <th><i class="fal fa-cogs"></i> Действия</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(item, index) in crud.data.list">
                                        <td>{{ item.name }}</td>
                                        <td>{{ item.triniti_tariff_id }}</td>
                                        <td>{{ item.note }}</td>
                                        <td>
                                            <span v-if="!item.count_not_synced_subscriber_connections">Синхронизировано</span>
                                            <span v-else>
                                                Ожидание синхронизации
                                                {{ item.count_total_subscriber_connections - item.count_not_synced_subscriber_connections}}
                                                / {{ item.count_total_subscriber_connections }}
                                            </span>

                                        </td>
                                        <td>
                                            <div class="btn-group">

                                                <span>
                                                    <app-has-perm-action-btn :perms="'personal_services_management_add_edit'"
                                                              @action="popup_view(item)">
                                                        <template slot-scope="{ access }">
                                                            <span class="btn-l" :class="access ? 'blue' : 'grey'"><i
                                                                class="fal fa-eye"></i></span>
                                                        </template>
                                                    </app-has-perm-action-btn>
                                                </span>

                                                <span>
                                                    <app-has-perm-action-btn :perms="'personal_services_management_add_edit'"
                                                              @action="popup_edit(index, item)">
                                                        <template slot-scope="{ access }">
                                                            <span class="btn-l" :class="access ? 'orange' : 'grey'"><i
                                                                class="fal fa-edit"></i></span>
                                                        </template>
                                                    </app-has-perm-action-btn>
                                                </span>

                                                <span>
                                                    <app-remove-btn :perms="'catalog_additional_services_personal_delete'" @action="popup_delete"></app-remove-btn>
                                                </span>
                                                <span v-if="item.count_not_synced_subscriber_connections">
                                                    <span class="btn-l green" @click="syncIPTVTrinitiSubscriberConnection(item.id)">
                                                        <i class="fal fa-sync-alt"></i>
                                                    </span>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal ADD window -->
        <tariff-triniti-add-popup :settings="add_popup" :crud="crud"></tariff-triniti-add-popup>

        <!-- Modal Edit window -->
        <tariff-triniti-edit-popup :settings="edit_popup" :crud="crud"></tariff-triniti-edit-popup>

        <!-- Modal View window -->
        <tariff-triniti-view-popup :settings="view_popup" :crud="crud"></tariff-triniti-view-popup>

        <!-- Modal Delete window -->
        <tariff-triniti-delete-popup :settings="delete_popup" ></tariff-triniti-delete-popup>
    </section>
</template>


<script>
    import TariffTrinitiAddPopup from './add.vue'
    import TariffTrinitiEditPopup from './edit.vue'
    import TariffTrinitiViewPopup from './view.vue'
    import TariffTrinitiDeletePopup from './delete.vue'
    import {User} from "../../../../../mixins/admin/user";
    import {CrudMixin} from "../../../../../mixins/admin/crud/crud_base";
    import {CrudListMixin} from "../../../../../mixins/admin/crud/crud_list";
    import {API} from "../../../../../api";

    export default {
        name: "tariff-triniti-list",
        components: {
            "tariff-triniti-add-popup": TariffTrinitiAddPopup,
            "tariff-triniti-edit-popup": TariffTrinitiEditPopup,
            "tariff-triniti-view-popup": TariffTrinitiViewPopup,
            "tariff-triniti-delete-popup": TariffTrinitiDeletePopup,
        },
        mixins: [User, CrudMixin, CrudListMixin, ],
        data: function () {
            return {
                crud: {
                    api: '/services/tariff-triniti/',
                }
            }
        },
        methods: {
            fetchUser() {
                if (this.has_perm('catalog_additional_services_personal_access')) {
                    var params = this.$route.query;
                    this.crud_get_list(params)
                }
            },
            syncIPTVTrinitiSubscriberConnection(tariffId) {
                var self = this;
                self.loader_show();

                API.post('/services/tariff-triniti/'+ tariffId +'/continue-sync/')
                    .then(function (response) {
                        // self.$set(self.crud.data.list, self.crud.data.index, data);
                    })
                    .catch(function (error) {
                        API.default_catch(self.crud, error)
                        if('non_field_errors' in self.errors) {
                            self.alert("Ошибка", self.errors['non_field_errors'][0]);
                        }
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            },
        },
        created() {
            this.$root.$on('search_hit_departments', (function(data){
                this.fetchUser()
            }).bind(this));

            this.$root.$on('search_clear_departments', (function(data){
                this.fetchUser()
            }).bind(this));

            this.fetchUser()
        },
    }
</script>

<style scoped>

</style>