<template>
    <popup :settings="settings">
        <div slot="title">Добавление улицы</div>
        <div slot="content">
             <div class="body-size">
                <div class="body-size__480">
                    <form action="" class="form-modal">
                        <div class="form-modal__group">
                            <fieldset class="form-modal__item">
                                <span class="form-modal__title">
                                <span class="form-modal__icon"><i class="fal fa-road"></i></span>
                                   Название улицы
                                </span>
                                <input type="text" v-model="name" class="form-modal__input" placeholder="Название улицы">
                                <form-error v-bind:value="errors.name"></form-error>
                            </fieldset>
                        </div>
                    </form>
                </div>
             </div>
        </div>
        <div slot="footer">
            <div class="btn-group">
                <span class="btn btn_grey" @click="popup_close">
                    <span class="btn__icon"><i class="fal fa-times-circle"></i></span>
                    <span class="btn__title">Отмена</span>
                </span>
                <span class="btn btn_blue" @click="save">
                    <span class="btn__icon"><i class="fal fa-save"></i></span>
                    <span class="btn__title">Сохранить</span>
                </span>
            </div>
        </div>
    </popup>
</template>

<script>
    import Popup from '../modal/popup.vue'
    import {PopupMixin} from "../../../../mixins/admin/popup";
    import FormErrors from '../form/errors.vue';
    import {API} from "../../../../api";

    export default {
        name: "street-add-popup",
        props: ["settings"],
        data: function(){
            return {
                name: "",
                errors: {}
            }
        },
        mixins: [PopupMixin],
        components: {
            "popup": Popup,
            "form-error": FormErrors
        },
        methods: {
            save: function () {
                var self = this;
                API.post('/address/street/', {name: this.name}).
                then(function (response) {
                    self.popup_close();
                    self.$emit('update_street', response.data.id);
                }).catch(function (error) {
                    API.default_catch(self, error);
                });
            }
        },
        watch: {
            settings: {
                handler(val) {
                    this.name = "";
                },
                deep: true
            }
        }
    }
</script>

<style scoped>

</style>