import {API} from "../../api";
import {loader} from "../../models/loader";

export var ShopsMixin = {
    methods: {
        load_shops: function (params) {
            var self = this;
            // loader.active = false;
            API.get('/shops/', params)
                .then(function (response) {
                    // loader.active = true;
                    self.shops = response.data.results;
                    self.count = response.data.count;
                })
                .catch(function (error) {
                    API.default_catch(self, error)
                })
                .then(function () {
                    API.default_always(self);
                })
        },
        load_shop(id, filter){
            var self = this;
            filter = filter || {};
            loader.active = false;
            API.get('/shops/'+id+"/", filter)
                .then(function (response) {
                    loader.active = true;
                    self.shop = response.data;
                })
        },
        loadAgentWithShops: function () {
            var self = this;
            // loader.active = false;
            API.get('/shops/agents-with-shops/')
                .then(function (response) {
                    // loader.active = true;
                    self.agents = response.data;
                })
                .catch(function (error) {
                    API.default_catch(self, error)
                })
                .then(function () {
                    API.default_always(self);
                })
        },
    },
};
