import {API} from "../../api";
import {loader} from "../../models/loader";

export var PermissionMixin = {
    methods: {
        has_perm_user_or_superuser_access: function () {
            return this.has_perm({
                    permissions: 'unicards_user_access,unicards_super_access',
                    comparison: 'OR'
                })
        },
        has_perm_super_unicards: function () {
            return this.has_perm('unicards_super_access')
        }
    },
};
