import {API} from "../../api";

export var PhonesMixin = {
    data: function () {
        return {
            item: {
                phone: "",
                phone_1: "",
                phone_2: "",
                phone_3: "",
                phone_4: "",
                phone_5: "",
            },
        }
    },
    methods: {
        set_phone_single: function (value) {
            this.item.phone = value;
        },
        set_phones_list: function (phones) {
            for(var i=0;i <5; i++){
                if(phones[i] !== undefined){
                    this.item['phone_' + (i+1)] = phones[i];
                } else {
                    this.item['phone_' + (i+1)] = "";
                }
            }
        }
    }
};
