<template>
  <section class="page">
    <div class="page__inner">
      <div class="container-width">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div class="page-header">
              <div class="row">
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                  <h1 v-if="is_main_type()" class="page-header__title"> Основные партии</h1>
                  <h1 v-if="is_shop_type() && !shop_id" class="page-header__title"> Магазинные партии</h1>
                  <h1 v-if="is_shop_type() && shop_id" class="page-header__title"> Партии магазина "{{
                      shop.name
                    }}"</h1>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                  <DropDown></DropDown>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div class="page-body">
              <!--FILTER BLOCK PAGE-->
              <FilterBlock @resetComponentFilters="resetComponentFilters">
                <filter-by-field v-model="unicard_status"
                                 title="Статус"
                                 view="label"
                                 :objects="unicard_batch_statuses"
                                 :add-social-tariff="false"
                                 :is-choices-filter="true"/>
                <template v-if="is_shop_type()">
                  <filter-by-field v-model="shop_param"
                                   title="Магазин"
                                   view="name"
                                   :objects="shop_list"
                                   :add-social-tariff="false"
                                   :is-choices-filter="false"/>
                </template>
                <filter-by-field v-model="series"
                                 title="Серию"
                                 view="label"
                                 :objects="series_list"
                                 :add-social-tariff="false"
                                 :is-choices-filter="true"/>
                <filter-by-field v-model="value"
                                 title="Номинал"
                                 view="label"
                                 :objects="values_list"
                                 :add-social-tariff="false"
                                 :is-choices-filter="true"/>
                <filter-by-field v-model="owner"
                                 :title="is_shop_type() ? 'Отправитель' : 'Получатель'"
                                 view="fio"
                                 :objects="owners_list"
                                 :add-social-tariff="false"/>
              </FilterBlock>
              <!--END FILTER BLOCK PAGE-->
              <!--                            <div class="page-body__header">-->
              <!--                                <div class="row">-->
              <!--                                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">-->
              <!--                                        <div class="selected">-->
              <!--                                            <select v-model="unicard_status" required>-->
              <!--                                                <option :value="undefined">Все партии</option>-->
              <!--                                                <option v-for="item in unicard_batch_statuses" :value="item.key">{{ item.label }}</option>-->
              <!--                                            </select>-->
              <!--                                        </div>-->
              <!--                                    </div>-->
              <!--                                </div>-->
              <!--                            </div>-->

              <div class="table-container">
                <table>
                  <thead>
                  <tr>
                    <th>#</th>
                    <th>Дата создания</th>
                    <th>Отправитель</th>
                    <th>
                      <template v-if="is_main_type()">Получатель</template>
                      <template v-else>Магазин</template>
                    </th>
                    <th>Серия</th>
                    <th>Диапазон</th>
                    <th>Кол-во</th>
                    <th>Номинал</th>
                    <th>Статус</th>
                    <th>Действия</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in items">
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.created_at | moment("DD.MM.YYYY HH:mm") }}</td>
                    <td>{{ item.owner_from_expand.fio }}</td>
                    <td>{{ get_owner(item) }}</td>
                    <td>{{ get_series(item) }}</td>
                    <td>{{ item.number_from_display }}-{{ item.number_to_display }}</td>
                    <td>{{ item.count_unicards }}</td>
                    <td>{{ get_face_value(item) }}</td>
                    <td>{{ getStatusUnicards(item) }}</td>
                    <td>
                      <div class="btn-group">
                        <router-link title="Карты" :to="get_cards_router_to(index)">
                          <span class="btn-l blue"><i class="fal fa-stream"></i></span>
                        </router-link>
                        <span class="btn-l orange" v-if="canReturn(item)" @click="popup_view(item)"><i
                            class="fal fa-undo"></i></span>
                        <span class="btn-l red" v-if="!isBlocked(item)" @click="block_unblock(item, index, 'block')"><i
                            class="fal fa-lock"></i></span>
                        <span class="btn-l green" v-if="isBlocked(item)" @click="block_unblock(item, index, 'unblock')"><i
                            class="fal fa-lock-open-alt"></i></span>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <!--PAGINATION BLOCK-->
              <Pagination @loadItems="fetchUser"
                          :options="limitOptions"
                          :limit-items="limit"
                          :page-items="page"
                          :count-items="count"
                          :add-null-option="true"/>
              <!--END PAGINATION BLOCK-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <Return :settings="return_popup" :type="type" :item="item" @update_list="fetchUser"></Return>
  </section>
</template>

<script>
import DropDown from "../dropdown/dropdown";
import Return from "./return";
import {User} from "../../../../../mixins/admin/user";
import {PermissionMixin} from "../../../../../mixins/admin/permission";
import {UnicardsMixin} from "../../../../../mixins/admin/unicards";
import {API} from "../../../../../api";
import {ShopsMixin} from "../../../../../mixins/admin/shops";
import {SearchMixin} from "../../../../../mixins/admin/search";
import {RouteHelperMixin} from "../../../../../mixins/admin/route_helper";
import {PaginationMixin} from "../../../../../mixins/admin/pagination";
import {FilterHelperMixin} from "../../../../../mixins/admin/filter_helper";
import FilterBlock from "../../filter-block/filter-block";
import Filter from "../../filter-block/sub_components/filter";
import Pagination from "../../pagination/pagination";
import {UnicardBatchParamsLoaderMixin} from "../../../../../mixins/admin/unicardBatchParamsLoaderMixin";
import {UnicardBatchShopParamsLoaderMixin} from "../../../../../mixins/admin/unicardBatchShopParamsLoaderMixin";

export default {
  name: "list",
  mixins: [User, PermissionMixin, UnicardsMixin, ShopsMixin, SearchMixin, RouteHelperMixin, PaginationMixin,
    FilterHelperMixin, UnicardBatchParamsLoaderMixin, UnicardBatchShopParamsLoaderMixin],
  props: ['type'],
  data() {
    return {
      items: [],
      item: {},
      unicards_batch: [],
      shop_batch: [],
      shop: {},
      shop_id: "",
      errors: [],
      return_popup: {
        visible: false
      },
      // options for pagination block
      limitOptions: [15, 25, 50, 100],
      limit: 15,
      page: 1,
      count: 0,
      // filter options for department filter
      unicard_status: undefined,
      unicard_batch_statuses: [],
      series: undefined,
      series_list: [],
      value: undefined,
      values_list: [],
      owner: undefined,
      owners_list: [],
      shop_param: undefined,
      shop_list: [],
    }
  },
  created: function () {
    this.pagination_init(this.page, this.limit);
    this.load_unicard_statuses_batch();
  },
  beforeDestroy: function () {
    this.destroy_search_listeners("unicard_batch");
    this.destroy_search_listeners("unicard_shop_batch");
  },
  mounted() {
    this.loadFilterOptions();
    this.fetchUser();
  },
  components: {
    FilterBlock,
    "filter-by-field": Filter,
    Pagination,
    DropDown,
    Return,
  },
  methods: {
    loadFilterOptions() {
      if (this.is_main_type()) {
        this.loadUnicardBatchSeries();
        this.loadUnicardBatchValues();
        this.loadUnicardBatchOwners();
      } else if (this.is_shop_type()) {
        this.loadUnicardBatchShopSeries();
        this.loadUnicardBatchShopValues();
        this.loadUnicardBatchShopOwners();
        this.loadUnicardBatchShopShopsList();
      }
    },
    fetchUser() {
      var self = this;

      var filter = Object.assign({}, self.$route.query,
          {
            page: !self.$route.query.page ? self.page : self.$route.query.page,
            limit: !self.$route.query.limit ? self.limit : self.$route.query.limit,
            offset: !self.$route.query.offset ? self.get_offset(self.page) : self.$route.query.offset,
          });

      self.$set(filter, 'status', self.unicard_status);
      self.$set(filter, 'series', self.series);
      self.$set(filter, 'value', self.value);
      self.$set(filter, 'owner', self.owner);

      if (this.is_main_type()) {
        this.load_unicards_batch(filter)
            .then(function (response) {
              self.unicards_batch = response.data.results;
              self.count = response.data.count;

              // update status filter
              self.updateRouterQuery('status', self.unicard_batch_statuses, self.unicard_status);
              self.updateRouterQuery('series', self.series_list, self.series);
              self.updateRouterQuery('value', self.values_list, self.value);
              self.updateRouterQuery('owner', self.owners_list, self.owner);

            })
            .catch(function (error) {
              API.default_catch(self, error)
            })
            .then(function () {
              API.default_always(self);
            })
      } else if (this.is_shop_type()) {

        if ("shop_id" in this.$route.params) {
          this.shop_id = this.$route.params.shop_id;

          self.$set(filter, 'shop', self.shop_id)

          this.load_shop(filter["shop"]);
        }
        self.$set(filter, 'shop', self.shop_param)

        this.load_unicards_shop_batch(filter)
            .then(function (response) {
              self.shop_batch = response.data.results;
              self.count = response.data.count;

              // update status filter
              self.updateRouterQuery('status', self.unicard_batch_statuses, self.unicard_status);
              self.updateRouterQuery('series', self.series_list, self.series);
              self.updateRouterQuery('value', self.values_list, self.value);
              self.updateRouterQuery('owner', self.owners_list, self.owner);
              self.updateRouterQuery('shop', self.shop_list, self.shop_param);
            })
            .catch(function (error) {
              API.default_catch(self, error)
            })
            .then(function () {
              API.default_always(self);
            })
      }
    },
    get_cards_router_to(index) {
      if (this.is_main_type()) {
        if (this.items[index].unicard_set_expand) {
          return {
            name: 'unicards-list-cards',
            query: {
              unicard_set: this.items[index].unicard_set_expand.id,
              unicard_batch: this.items[index].id
            }
          }
        }
      } else {
        if (this.items[index].unicard_batch_expand) {
          return {
            name: 'unicards-list-cards',
            query: {
              unicard_set: this.items[index].unicard_batch_expand.unicard_set,
              unicard_shop_batch: this.items[index].id
            }
          }
        }
      }
      return {
        name: 'unicards-list-cards',
        params: {id: "a"}
      }
    },
    resetComponentFilters() {
      this.resetFilters();
      this.fetchUser();
    },
    popup_view(item) {
      this.item = Object.assign({}, item);
      this.return_popup.visible = true;
    },
    isBlocked(item) {
      return item.status === 'blocked';
    },
    block_unblock(item, index, status) {
      var url = '', self = this;

      if (this.is_main_type()) {
        url = '/unicards/batch/' + item.id + '/';
      } else {
        url = '/unicards/shop-batch/' + item.id + '/';
      }

      // Block/Unblock
      url += status + '/';
      API.post(url)
          .then(function (response) {
            self.$set(self.items, index, response.data);
          })
          .catch(function (error) {
            API.default_catch(self, error);
            if ('non_field_errors' in self.errors) {
              self.alert("Внимание", self.errors['non_field_errors'][0]);
            }
          })
    },
  },
  watch: {
    shop_param(value) {
      if (value && value.length > 0) {
        this.fetchUser();
      }
    },
    series(value) {
      if (value && value.length > 0) {
        this.fetchUser();
      }
    },
    value(value) {
      if (value && value.length > 0) {
        this.fetchUser();
      }
    },
    owner(value) {
      if (value && value.length > 0) {
        this.fetchUser();
      }
    },
    unicards_batch() {
      this.items = this.unicards_batch;
    },
    shop_batch() {
      this.items = this.shop_batch;
    },
    unicard_status() {
      this.fetchUser();
    },
    type() {
      this.loadFilterOptions();
      this.fetchUser();
    }
  }
}
</script>

<style scoped>

</style>