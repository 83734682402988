<template>
  <tbody class="table-box__tbody">
    <tr class="table-box__tr table-box__tr_accordion table-data" :class="[showTable ? 'active' : 'deactivate']">
      <td class="table-box__td">
        <span class="table-data__text table-data__text_bold">{{ department.department_expand.name }}</span>
      </td>
      <td class="table-box__td table-box__td_center">
        <span class="table-data__text table-data__text_bold">
          <report-link :item="department" :field_name="linkPrefix+'_last_three_months'"></report-link>
        </span>
      </td>
      <td class="table-box__td table-box__td_center">
        <span class="table-data__text table-data__text_bold">
          <report-link :item="department" :field_name="linkPrefix+'_last_six_months'"></report-link>
        </span>
      </td>

      <td class="table-box__td table-box__td_center">
        <span class="table-data__text table-data__text_bold">
          <report-link :item="department" :field_name="linkPrefix+'_over_six_months'"></report-link>
        </span>
      </td>
      <td class="table-box__td table-box__td_160 table-box__td_right">
        <div class="btn-group">
          <span class="btn-b btn-b_blue" @click="$emit('showSettlement', index)">
            <span class="btn-b__icon"><i class="fal fa-eye"></i></span>
            <span class="btn-b__title">Подробнее</span>
          </span>
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script>
  import ReportLink from '../sub_components/report-link';

  export default {
    name: "subscribers-leak-report-department-info",
    props: ['department', 'index', 'showTable', 'linkPrefix'],
    components: {
      'report-link': ReportLink,
    }
  }
</script>

<style scoped>

</style>