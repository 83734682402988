<template>
  <section class="page">
    <div class="page__inner">
      <div class="container-width">
        <!--HEADER PAGE-->
        <div class="page__header">
          <h1 class="page__title">Добро пожаловать, Администратор</h1>
        </div>
        <!--END HEADER PAGE-->
        <!--DASHBOARD-->
        <div class="dashboard">
          <DashboardCount></DashboardCount>
          <DashboardTop></DashboardTop>
<!--          <DashboardTraffic></DashboardTraffic>-->
          <DashboardSubscribers></DashboardSubscribers>
        </div>
        <!--END DASHBOARD-->
      </div>
    </div>
  </section>
</template>

<script>

import DashboardCount from "./dashboard-count/dashboard-count";
import DashboardTop from "./dashboard-top/dashboard-top";
import DashboardTraffic from "./dashboard-traffic/dashboard-traffic";
import DashboardSubscribers from "./dashboard-subscribers/dashboard-subscribers";

export default {
  name: "dashboard",
  components: {
    DashboardCount,
    DashboardTop,
    DashboardTraffic,
    DashboardSubscribers
  },
}
</script>

<style scoped>

</style>