import {API} from "../../api";
import {loader} from "../../models/loader";

export var AddressMixin = {
    data() {
        return {
            streets: [],
            regions: [],
            settlements: [],
            districts: [],
        }
    },
    methods: {
        load_regions: function () {
            var self = this;
            loader.active = true;
            API.get('/address/region/')
                .then(function (response) {
                    self.regions = response.data.results;
                    loader.active = false;
                })
        },
        load_districts: function (region, get_first_district=true) {
            var self = this;

            if(region === "") { return }

            loader.active = true;
            API.get('/address/district/', {region: region})
                .then(function (response) {
                    self.districts = response.data.results;

                    // Select by default first
                    if(get_first_district) {
                        if((self.districts.length > 0)&&(self.district === "")) {
                            self.district = self.districts[0].id;
                        }
                    }
                    loader.active = false;
                })
        },
        load_settlements: function (district) {
            var self = this;
            if(district === ""){
                return
            }
            loader.active = true;
            API.get('/address/settlement/', {district: district})
                .then(function (response) {
                    self.settlements = response.data.results;
                    // console.log(self.settlements);
                    // Select by default first
                    if((self.settlements.length > 0) && (self.settlement === "")) {
                        self.settlement = self.settlements[0].id;
                    }
                    loader.active = false;
                })
        },
        load_streets: function () {
            var self = this;
            loader.active = true;
            API.get('/address/street/')
                .then(function (response) {
                    self.streets = response.data.results;
                    loader.active = false;
                })
        },
        update_settlement: function (settlement) {
            this.settlement = settlement;
            this.load_settlements(this.district);
        },

        update_street: function (street) {
            this.$set(this.subscriber_connection, "street", street);
            this.load_streets();
        },
        show_settlement_popup: function () {
            if ((this.region === "") || (this.district === "")) {
                this.alert("Внимание", "Район или область не выбраны")
            } else {
                this.settlement_popup.region = this.region;
                this.settlement_popup.district = this.district;
                this.settlement_popup.visible = true;
            }
        },
        show_street_popup: function () {
            this.street_popup.region = this.region;
            this.street_popup.district = this.district;
            this.street_popup.visible = true;
        },
    },
    watch: {
        region: function (val) {
            this.district = "";
            this.load_districts(val);
        },
        district: function (val) {
            this.settlement = "";
            this.settlements = [];
            this.load_settlements(val);
        }
    }
};
