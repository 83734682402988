<template>
    <div class="btn btn_green">
        <span class="btn__icon"><i class="fal fa-chart-area"></i></span>
        <span class="btn__title">Типы отчетов</span>
        <span class="btn__arrow"><i class="fal fa-chevron-down"></i></span>
        <div class="btn__dropdown">
            <div class="btn__body">
                <div class="c-dropdown-menu">
                    <template v-for="item in items">
                        <div class="c-dropdown-menu__item" v-if="showing_menu_item(item.name)">
                            <router-link :title="item.title" :to="{ name: item.name}" class="c-dropdown-menu__link" v-bind:class="[item === item.name === 'active ']">
                                <span class="c-dropdown-menu__link-icon"><i class="fal" v-bind:class="[item.icon]"></i></span>
                                <span class="c-dropdown-menu__link-title">{{ item.title }}</span>
                            </router-link>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {ReportsMixin} from "../../../../../mixins/admin/reports";

    export default {
        name: "report-menu",
        props: ['active_name'],
        mixins: [ReportsMixin],
        data() {
            return {
                report_page_perms: this.get_report_page_perms(),
                items: [
                    {
                        name: 'reports-page',
                        title: 'Отчеты',
                        icon: ['fa-analytics'],
                    },
                    {
                        name: 'reports-finance',
                        title: 'Финансы',
                        icon: ['fa-hryvnia'],
                    },
                    {
                        name: 'reports-subscribers',
                        title: 'Абоненты',
                        icon: ['fa-users'],
                    },
                    {
                        name: 'reports-nas',
                        title: 'NAS',
                        icon: ['fa-server'],
                    },
                    {
                        name: 'reports-settlements',
                        title: 'Населенные пункты',
                        icon: ['fa-map-marker-alt'],
                    },
                    {
                        name: 'reports-tariffs',
                        title: 'Тарифы',
                        icon: ['fa-briefcase'],
                    },
                    {
                        name: 'reports-departments',
                        title: 'Отделы',
                        icon: ['fa-user-tie'],
                    },
                    {
                        name: 'reports-services',
                        title: 'Услуги',
                        icon: ['fa-toolbox'],
                    },
                    {
                        name: 'reports-network',
                        title: 'Сети',
                        icon: ['fa-network-wired'],
                    },
                    {
                        name: 'reports-analytics',
                        title: 'Аналитика',
                        icon: ['fa-chart-pie'],
                    },
                ]
            }
        }
    }
</script>

<style scoped>

</style>