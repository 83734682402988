<template>
    <div class="grid">
        <div class="grid__col">
            <div class="grid__box">
                <div class="table-box">
                    <div class="table-box__header">
                        <div class="table-box__header-block">
                            <div class="vdate">
                                <date-range-picker
                                        :startDate="startDate"
                                        :endDate="endDate"
                                        @update="update_range_picker"
                                        :locale-data="locale">
                                </date-range-picker>
                            </div>
                        </div>
                    </div>
                    <table class="table-box__table">
                        <thead class="table-box__thead">
                        <tr class="table-box__thead-tr">
                            <!--Дата-->
                            <th class="table-box__th">
                                <span class="table-box__thead-item table-box__thead-item_center">
                                     <span class="table-box__thead-title">Дата</span>
                                </span>
                            </th>
                            <!--Сумма операции-->
                            <th class="table-box__th">
                                <span class="table-box__thead-item table-box__thead-item_center">
                                     <span class="table-box__thead-title">Сумма операции</span>
                                </span>
                            </th>
                            <!--Баланс до операции-->
                            <th class="table-box__th">
                                <span class="table-box__thead-item table-box__thead-item_center">
                                     <span class="table-box__thead-title">Баланс до операции</span>
                                </span>
                            </th>
                            <!--Текущий баланс-->
                            <th class="table-box__th">
                                <span class="table-box__thead-item table-box__thead-item_center">
                                     <span class="table-box__thead-title">Текущий баланс</span>
                                </span>
                            </th>
                            <!--Менеджер-->
                            <th class="table-box__th">
                                <span class="table-box__thead-item table-box__thead-item_center">
                                     <span class="table-box__thead-title">Менеджер</span>
                                </span>
                            </th>
                            <!--Номер транзакции-->
                            <th class="table-box__th">
                                <span class="table-box__thead-item table-box__thead-item_center">
                                     <span class="table-box__thead-title">Номер транзакции</span>
                                </span>
                            </th>
                            <!--Примечание-->
                            <th class="table-box__th">
                                <span class="table-box__thead-item table-box__thead-item_center">
                                     <span class="table-box__thead-title">Примечание</span>
                                </span>
                            </th>
                        </tr>
                        </thead>
                        <tbody class="table-box__tbody">
                        <tr class="table-box__tr table-data" v-for="record in records">
                            <!--Дата-->
                            <td class="table-box__td table-box__td_center">
                                    <span class="table-data__text table-data__text_bold">
                                       {{ record.date | moment("DD.MM.YYYY HH:mm") }}
                                    </span>
                            </td>
                            <!--Сумма операции-->
                            <td class="table-box__td table-box__td_center">
                                <template v-if="record.amount > 0">
                                    <span class="table-data__status table-data__status_active">
                                        + {{ record.amount }}
                                    </span>
                                </template>
                                <template v-else>
                                    <span class="table-data__status table-data__status_deactive">
                                       {{ record.amount }}
                                    </span>
                                </template>
                            </td>
                            <!--Баланс до операции-->
                            <td class="table-box__td table-box__td_center">
                                    <span class="table-data__text table-data__text_bold">
                                       {{ record.balance_before }}
                                    </span>
                            </td>
                            <!--Текущий баланс-->
                            <td class="table-box__td table-box__td_center">
                                    <span class="table-data__text table-data__text_bold">
                                       {{ record.balance_after }}
                                    </span>
                            </td>
                            <!--Менеджер-->
                            <td class="table-box__td table-box__td_center">
                                    <span class="table-data__text ">
                                       <template v-if="record.user_expand">
                                        {{ record.user_expand.fio }}
                                    </template>
                                    <template v-else>
                                        {{ record.user_system_expand }}
                                    </template>
                                    </span>
                            </td>
                            <!--Номер транзакции-->
                            <td class="table-box__td table-box__td_center">
                                    <span class="table-data__text">
                                        {{ record.id }}
                                    </span>
                            </td>
                            <!--Примечание-->
                            <td class="table-box__td table-box__td_center">
                                    <span class="table-data__text">
                                        {{ record.transaction_note }}
                                    </span>
                            </td>
                        </tr>
                        </tbody>

                    </table>
                </div>
            </div>
        </div>
    </div>


    <!--<accordion>-->
    <!--<div slot="title-accordion"><i class="fal fa-hryvnia"></i> История платежей</div>-->
    <!--<div slot="wrapper-accordion">-->
    <!--<div class="accordion__header">-->
    <!--<div class="row">-->
    <!--<div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">-->
    <!--<div class="filter">-->
    <!--<date-range-picker-->
    <!--:startDate="startDate"-->
    <!--:endDate="endDate"-->
    <!--@update="update_range_picker"-->
    <!--:locale-data="locale" >-->
    <!--</date-range-picker>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="table-container">-->
    <!--<table>-->
    <!--<thead>-->
    <!--<tr>-->
    <!--<th>Дата</th>-->
    <!--<th>Сумма операции</th>-->
    <!--<th>Баланс до операции</th>-->
    <!--<th>Текущий баланс</th>-->
    <!--<th>Менеджер</th>-->
    <!--<th>Номер транзакции</th>-->
    <!--<th>Примечание</th>-->
    <!--</tr>-->
    <!--</thead>-->
    <!--<tbody>-->
    <!--<tr v-for="record in records">-->
    <!--<td>{{ record.date | moment("DD.MM.YYYY HH:mm") }}</td>-->
    <!--<td><template v-if="record.amount > 0">+</template>{{ record.amount }}</td>-->
    <!--<td><i class="fal fa-hryvnia"></i> {{ record.balance_before }}</td>-->
    <!--<td><i class="fal fa-hryvnia"></i> {{ record.balance_after }}</td>-->
    <!--<td>-->
    <!--<template v-if="record.user_expand">-->
    <!--{{ record.user_expand.fio }}-->
    <!--</template>-->
    <!--<template v-else>-->
    <!--{{ record.user_system_expand }}-->
    <!--</template>-->
    <!--</td>-->
    <!--<td>{{ record.id }}</td>-->
    <!--<td>{{ record.transaction_note }}</td>-->
    <!--</tr>-->
    <!--</tbody>-->
    <!--</table>-->
    <!--</div>-->
    <!--</div>-->
    <!--</accordion>-->
</template>

<script>
    import {API} from "../../../../api";
    import {SubscriberConnectionMixin} from "../../../../mixins/admin/subscriber_connection";
    import {SubscriberConnectionAccordionMixin} from "../../../../mixins/admin/subscriber_connection_accordion";

    export default {
        name: "payment-history",
        props: ['subscriber_connection'],
        mixins: [SubscriberConnectionMixin, SubscriberConnectionAccordionMixin],
        data: function () {
            return {
                filter: {
                    subscriber_connection: "",
                    date_gte: "",
                    date_lte: "",
                },
                records: [],
                showSubAccordion: false,
            }
        },
        methods: {
            load() {
                var self = this;
                this.subscriber_connections_transactions(this.filter).then(function (response) {
                    self.records = response.data.results;
                })
                    .catch(function (error) {
                        API.default_catch(self, error);
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            },
            update_range_picker(date_range) {
                this.filter.date_gte = this.$moment(date_range.startDate).format("YYYY-MM-DD");
                this.filter.date_lte = this.$moment(date_range.endDate).format("YYYY-MM-DD");
            }
        },
        watch: {
            subscriber_connection: function (value) {
                this.filter.subscriber_connection = value.id;
                // If change other fields in subscriber_connection need load new data
                this.load();
            },
            filter: {
                handler(value) {
                    this.load();
                },
                deep: true
            }
        }
    }
</script>

<style scoped>

</style>