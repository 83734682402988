<template>
    <span class="table-data__text">
        <span v-if="item_service.group_expand">
            {{ item_service.group_expand.name }}
        </span>
        <span v-else>
            Все
        </span>
    </span>
</template>

<script>
    export default {
        name: "group",
        props: ['item_service']
    }
</script>