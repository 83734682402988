<template>
    <div class="input-error" v-show="value.length">
        <span class="input-error__icon"><i class="fal fa-exclamation-circle"></i></span>
        <span class="input-error__text"><template v-for="item in value">{{ item }}</template></span>
    </div>
</template>

<script>
    export default {
        name: "form-errors",
        props: {
            value: {
                type: Array,
                default: function () {
                    return []
                }
            },
        }
    }
</script>

<style scoped>

</style>