<template>
  <section class="page">
    <div class="page__inner">
      <div class="container-width">
        <!--HEADER PAGE-->
        <div class="page__header">
          <h1 class="page__title">Генерация карт</h1>
          <DropDown></DropDown>
        </div>
        <!--END HEADER PAGE-->
        <!--BODY PAGE-->
        <div class="page__body">
          <div class="grid">
            <div class="grid__col">
              <form action="" class="form-data">
                <div class="form-data__row">
                  <div class="form-data__section">
                    <!--Серия-->
                    <fieldset class="form-data__fieldset">
                      <div class="form-data__item">
                        <div class="form-data__label">
                          <span class="form-data__label-icon"><i class="fal fa-credit-card-blank"></i></span>
                          <span class="form-data__label-title">Серия:</span>
                          <span class="form-data__label-control"><i
                              class="fal fa-asterisk"></i></span>
                        </div>
                        <div class="form-data__value">
                          <input type="text" v-model="unicards.series" class="form-data__input" placeholder="ЕВ44"
                                 maxlength="4">
                        </div>
                      </div>
                      <form-error v-bind:value="errors.series"></form-error>
                    </fieldset>
                    <!--Номинал-->
                    <fieldset class="form-data__fieldset">
                      <div class="form-data__item">
                        <div class="form-data__label">
                          <span class="form-data__label-icon"><i class="fal fa-money-bill-wave-alt"></i></span>
                          <span class="form-data__label-title">Номинал:</span>
                          <span class="form-data__label-control"><i
                              class="fal fa-asterisk"></i></span>
                        </div>
                        <div class="form-data__value">
                          <input type="text" v-model="unicards.face_value" class="form-data__input" placeholder="000"
                                 maxlength="4">
                        </div>
                      </div>
                      <form-error v-bind:value="errors.face_value"></form-error>
                    </fieldset>
                    <!--Кол-во-->
                    <fieldset class="form-data__fieldset">
                      <div class="form-data__item">
                        <div class="form-data__label">
                          <span class="form-data__label-icon"><i class="fal fa-credit-card-blank"></i></span>
                          <span class="form-data__label-title">Кол-во:</span>
                          <span class="form-data__label-control"><i
                              class="fal fa-asterisk"></i></span>
                        </div>
                        <div class="form-data__value">
                          <input type="text" v-model="unicards.count_unicards" class="form-data__input"
                                 placeholder="000000">
                        </div>
                      </div>
                      <form-error v-bind:value="errors.count_unicards"></form-error>
                    </fieldset>
                  </div>
                  <div class="form-data__section">
                    <!--Диапазон-->
                    <fieldset class="form-data__fieldset">
                      <div class="form-data__item">
                        <div class="form-data__label">
                          <span class="form-data__label-icon"><i class="fal fa-abacus"></i></span>
                          <span class="form-data__label-title">Диапазон от:</span>
                          <span class="form-data__label-control"><i
                              class="fal fa-asterisk"></i></span>
                        </div>
                        <div class="form-data__value">
                          <input type="text" v-model="unicards.number_from" class="form-data__input"
                                 placeholder="00000">
                        </div>
                      </div>
                      <form-error v-bind:value="errors.number_from"></form-error>
                    </fieldset>
                    <!--Срок действия-->
                    <fieldset class="form-data__fieldset">
                      <div class="form-data__item">
                        <div class="form-data__label">
                          <span class="form-data__label-icon"><i class="fal fa-mouse-pointer"></i></span>
                          <span class="form-data__label-title">Срок действия:</span>
                          <span class="form-data__label-control"><i
                              class="fal fa-asterisk"></i></span>
                        </div>
                        <div class="form-data__value">
                          <date-picker v-model="expiration_date" :lang="'ru'" placeholder="Дата"
                                       :format="'DD-MM-YYYY'"></date-picker>
                        </div>
                      </div>
                      <form-error v-bind:value="errors.expiration_date"></form-error>
                    </fieldset>
                  </div>
                </div>
                <div class="form-data__footer form-data__footer_right">
                  <div class="btn-group">
                <span class="btn btn_blue" @click="generate()">
                  <span class="btn__icon"><i class="fal fa-plus"></i></span>
                  <span class="btn__title">Сгенерировать</span>
                </span>
                  </div>
                </div>
              </form>
            </div>
            <div class="grid__col grid__col_right">
              <div class="unicards-view">
                <div class="unicards-card">
                  <div class="unicards-card__logo">
                    <div class="unicard">Uni<span>card</span></div>
                    <div class="denomination">{{ unicards.face_value }}грн</div>
                  </div>
                  <div class="unicards-card__strip">{{ get_number_from() }} - {{
                      get_number_to()
                    }}
                  </div>
                  <div class="unicards-card__data">
                    <div class="series">Серия: <span>{{ unicards.series }}</span></div>
                    <div class="date">Действительно до: <span>{{
                        unicards.expiration_date | moment("DD.MM.YYYY")
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--END BODY PAGE-->
      </div>
    </div>
  </section>
</template>

<script>
import {UnicardsMixin} from "../../../../mixins/admin/unicards";
import DatePicker from 'vue2-datepicker';
import FormErrors from '../form/errors.vue';
import {API} from "../../../../api";
import {User} from "../../../../mixins/admin/user";
import {PermissionMixin} from "../../../../mixins/admin/permission";
import DropDown from "./dropdown/dropdown";

export default {
  name: "generation",
  mixins: [User, UnicardsMixin, PermissionMixin],
  data() {
    return {
      expiration_date: "",
      unicards: {
        "count_unicards": 0,
        "expiration_date": "",
        "series": "",
        "number_from": 0,
        "face_value": 0
      },
      errors: {},
    }
  },
  components: {
    "date-picker": DatePicker,
    "form-error": FormErrors,
    DropDown
  },
  methods: {
    fetchUser() {
      if (!this.has_perm_super_unicards()) {
        this.alert("Внимание", "У Вас недостаточно прав для этой страницы");
      }
    },
    toggleDropdown(event) {
      event.currentTarget.classList.toggle('is-active')
    },
    generate() {
      var self = this;
      API.post('/unicards/set/', this.unicards)
          .then(function (response) {
            self.$router.push({name: "unicards-list"});
          })
          .catch(function (error) {
            API.default_catch(self, error)
          })
          .then(function () {
            API.default_always(self);
          })
    },
    get_number_from() {
      return this.pad(this.unicards.number_from, 5, '0')
    },
    get_number_to() {
      var number_to = parseInt(this.unicards.number_from) + parseInt(this.unicards.count_unicards);
      return this.pad(number_to, 5, '0')
    },
  },
  watch: {
    'unicards.series': {
      handler: function () {
        this.unicards.series = this.unicards.series.toUpperCase();
      },
      deep: true
    },
    expiration_date(value) {
      this.unicards.expiration_date = this.$moment(value).format("YYYY-MM-DD");
    },
  }
}
</script>

<style scoped>

</style>