<template>
    <popup :settings="settings">
        <div slot="title">Добавление города</div>
        <div slot="content">
            <div class="body-size">
                <div class="body-size__480">
                    <!--<div class="view-modal">-->
                        <!--<div class="view-modal__group">-->
                            <!--<div class="view-modal__item">-->
                            <!--<span class="view-modal__title">-->
                                <!--<span class="view-modal__icon"><i class="fal fa-globe-europe"></i></span>-->
                                   <!--Область-->
                            <!--</span>-->
                                <!--<span class="view-modal__value">-->
                                <!--{{ region }}-->
                            <!--</span>-->
                            <!--</div>-->
                            <!--<div class="view-modal__item">-->
                            <!--<span class="view-modal__title">-->
                                <!--<span class="view-modal__icon"><i class="fal fa-map-marked-alt"></i></span>-->
                                   <!--Район-->
                            <!--</span>-->
                                <!--<span class="view-modal__value">-->
                                <!--{{ district }}-->
                            <!--</span>-->
                            <!--</div>-->
                        <!--</div>-->
                    <!--</div>-->
                    <form action="" class="form-modal">
                        <div class="form-modal__group">
                            <fieldset class="form-modal__item">
                                <span class="form-modal__title">
                                <span class="form-modal__icon"><i class="fal fa-map-marker-alt"></i></span>
                                   Название города
                                </span>
                                <input type="text" v-model="name" class="form-modal__input"
                                       placeholder="Название города">
                                <form-error v-bind:value="errors.name"></form-error>
                            </fieldset>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="btn-group">
                <span class="btn btn_grey" @click="popup_close">
                    <span class="btn__icon"><i class="fal fa-times-circle"></i></span>
                    <span class="btn__title">Отмена</span>
                </span>
                <span class="btn btn_blue" @click="save">
                    <span class="btn__icon"><i class="fal fa-save"></i></span>
                    <span class="btn__title">Сохранить</span>
                </span>
            </div>
        </div>
    </popup>
</template>

<script>
    import Popup from '../modal/popup.vue'
    import {PopupMixin} from "../../../../mixins/admin/popup";
    import FormErrors from '../form/errors.vue';
    import {API} from "../../../../api";

    export default {
        name: "settlement-add-popup",
        props: ["settings"],
        data: function () {
            return {
                name: "",
                region: "-",
                district: "-",
                errors: {}
            }
        },
        mixins: [PopupMixin],
        components: {
            "popup": Popup,
            "form-error": FormErrors
        },
        methods: {
            save: function () {
                var self = this;
                API.post('/address/settlement/', {
                    district: this.settings.district,
                    name: this.name
                }).then(function (response) {
                    self.popup_close();
                    self.$emit('update_settlement', response.data.id);
                }).catch(function (error) {
                    API.default_catch(self, error);
                });
            },
            load: function () {
                var self = this;
                API.get('/address/district/' + this.settings.district)
                    .then(function (response) {
                        console.log(response);
                        self.district = response.data.name;
                        self.region = response.data.region_expand.name;
                    })
            }
        },
        watch: {
            settings: {
                handler(val) {
                    this.load()
                },
                deep: true
            }
        }
    }
</script>

<style scoped>

</style>