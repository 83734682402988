<template>
    <span>
        <li v-for="(interval, index) in intervals">
            <span class="title"><template v-if="index === 0">{{ title }}:</template></span>{{ pad(interval.start) }} - {{ pad(interval.end) }} ({{ interval.count }} шт.)
        </li>
    </span>
</template>

<script>
    import {UnicardsMixin} from "../../../../../../mixins/admin/unicards";

    export default {
        name: "return_intervas",
        props: ["intervals", "title"],
        mixins: [
            UnicardsMixin
        ],
    }
</script>

<style scoped>

</style>