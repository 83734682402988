<template>
    <tr class="table-box__tr table-data">
        <!--Имя-->
        <td class="table-box__td table-box__td_center">
            <span class="table-data__text">День доверия</span>
        </td>
        <!--Доступность-->
        <td class="table-box__td table-box__td_center">
            <span v-if="service" class="table-data__text table-data__text_bold">{{ is_available_trust_day }}</span>
            <span v-else class="table-data__text table-data__text_bold">{{ is_available_trust_day }}</span>
        </td>
        <!--Активность-->
        <td class="table-box__td table-box__td_center">
            <template v-if="service">
                <span v-if="service.is_active"
                      class="table-data__status table-data__status_active">
                    Активно
                </span>
                <span v-else class="table-data__status table-data__status_deactive">
                    Неактивно
                </span>
            </template>
            <template v-else>
                <span class="table-data__status table-data__status_deactive">
                    Неактивно
                </span>
            </template>
        </td>
        <!--Управление-->
        <td class="table-box__td table-box__td_center">
            <div class="btn-group" v-if="has_perm('service_services_management_activate_deactivate')">
                <div class="btn-switch" v-if="service">
                    <input type="checkbox" v-model="service.is_active"
                           @change="$emit('change_is_active', service, index)"
                           class="btn-switch__input"
                           :id="serviceTrustDayId">
                    <label class="btn-switch__for" :for="serviceTrustDayId"></label>
                </div>
                <div class="btn-switch" v-else>
                    <input type="checkbox"
                           class="btn-switch__input"
                           v-model="checked"
                           :id="serviceTrustDayId">
                    <label class="btn-switch__for" :for="serviceTrustDayId"></label>
                </div>
            </div>
            <div class="btn-group" v-else>
                <div class="btn-switch" @click="permissions_error_show">
                    <input type="checkbox" class="btn-switch__input" disabled>
                    <label class="btn-switch__for" :for="serviceTrustDayId"></label>
                </div>
            </div>
        </td>
        <!--Действия-->
        <td class="table-box__td table-box__td_center">
            <div class="btn-group" v-if="service">
                <app-has-perm-action-btn :perms="'service_services_management_activate_deactivate'">
                     <template slot-scope="{ access }">

                        <span v-tooltip="TooltipText='Просмотр'" class="btn-l" :class="access ? 'btn-l_grey' : 'btn-l_grey'">
                            <span class="btn-l__icon"><i class="fal fa-eye"></i></span>
                        </span>

                     </template>
                </app-has-perm-action-btn>

                <app-has-perm-action-btn :perms="'service_services_management_activate_deactivate'">
                    <template slot-scope="{ access }">

                        <span v-tooltip="TooltipText='Параметры'" class="btn-l" :class="access ? 'btn-l_grey' : 'btn-l_grey'">
                            <span class="btn-l__icon"><i class="fal fa-cog"></i></span>
                        </span>

                     </template>
                </app-has-perm-action-btn>
            </div>
            <div class="btn-group" v-else>
                <span class="btn-l btn-l_grey">
                    <span class="btn-l__icon"><i class="fal fa-eye"></i></span>
                </span>
                <span class="btn-l btn-l_grey">
                    <span class="btn-l__icon"><i class="fal fa-cog"></i></span>
                </span>
            </div>
        </td>
        <!--Ошибки-->
        <form-error :value="errors.service_trust_day"></form-error>
    </tr>
</template>

<script>
    import FormErrors from "../../../form/errors";
    import {ServiceActivationMixin} from "../../../../../../mixins/admin/service_activation";
    import {API} from "../../../../../../api";
    import _ from 'lodash';

export default {
    name: "service-trust-day",
    props: ['subscriber_connection', 'service', 'index', 'errors'],
    components: {
        "form-error": FormErrors,
    },
    mixins: [ServiceActivationMixin, ],
    created() {
        this.get_trust_day_is_available();
    },
    data() {
        return {
            checked: false,
            service_type: "service_trust_day",
            trust_day_is_available: false,
            serviceTrustDayId: _.uniqueId('id_'),
        }
    },
    methods: {
        update_subscriber_connection() {
            this.$emit("update_subscriber_connection");
        },
        get_trust_day_is_available() {
            var self = this;
            API.get('/subscriber-connections/' + self.subscriber_connection.id + '/trust-day-is-available/')
            .then(function (response) {
                    self.trust_day_is_available = response.data.trust_day_is_available;
                })
                .catch(function (error) {
                    API.default_catch(self, error)
                })
                .then(function () {
                    API.default_always(self);
                })
        },
    },
    computed: {
        is_available_trust_day() {
            return this.trust_day_is_available ? "Доступно" : "Недоступно"
        },
    },
    watch: {
        checked(value) {
            if (value) {
                this.$emit('add_new_service_by_checkbox', this.service_type);
                this.checked = false;
            }
        },
        subscriber_connection: {
            handler() {
                this.get_trust_day_is_available();
            },
            deep: true
        },
    }
}
</script>

<style scoped>

</style>