<template>
    <div class="page-body">
        <div class="edit-block">
            <form action="">
                <div class="edit-block__head blue">
                    <div class="edit-block__head--title">
                        <i class="fal fa-power-off"></i> Данные включения
                    </div>
                    <div class="edit-block__head--action">
                        <a href="#save" @click="save" class="save"><i class="fal fa-save"></i></a>
                    </div>
                </div>
                <div class="information-block orange">
                    <div class="information-block__icon orange">
                        <i class="fal fa-engine-warning"></i>
                    </div>
                    <div class="information-block__title orange">
                        Состояние редактирования!
                    </div>
                    <div class="information-block__desc">
                        Будьте внимательны! Данные находятся в состояние редактирования, <strong>все изменения будут
                        безвозвратно
                        сохранены!</strong>
                    </div>
                    <div class="information-block__action">
                        <div class="btn btn-outline-orange"><i class="fal fa-check"></i> Ознакомлен</div>
                    </div>
                </div>
                <div class="edit-block__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                            <div class="edit-block__item--title">
                                <i class="fal fa-users-crown"></i> Тип абонента:
                                <span class="rec"><i class="fal fa-check"></i></span>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
                            <div class="edit-block__item--value" v-if="subscriber_connection_data.subscriber_expand">
                                {{ subscriber_connection_data.subscriber_expand.subscriber_type_expand.name_expand }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="edit-block__item" v-has-perm="'subscribers_add_edit'">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                            <div class="edit-block__item--title">
                                <i class="fal fa-folder"></i> Группа:
                                <span class="req"><i class="fas fa-star-of-life"></i></span>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
                            <div class="selected">
                                <select v-model="subscriber_connection_data.group">
                                    <option v-for="group in groups" v-bind:value="group.id">{{ group.name }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <form-error v-bind:value="errors.group"></form-error>
                </div>
                <div class="edit-block__item" v-has-perm="'subscribers_add_edit'">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                            <div class="edit-block__item--title">
                                <i class="fal fa-code-branch"></i> Отдел:
                                <span class="req"><i class="fas fa-star-of-life"></i></span>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
                            <div class="selected">
                                <select v-model="subscriber_connection_data.department">
                                    <option v-for="department in departments" v-bind:value="department.id">{{
                                        department.name
                                        }}
                                    </option>
                                </select>
                            </div>

                        </div>
                    </div>
                    <form-error v-bind:value="errors.department"></form-error>
                </div>
                <div class="edit-block__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                            <div class="edit-block__item--title">
                                <i class="fal fa-wrench"></i> Монтажник:
                                <span class="req"><i class="fas fa-star-of-life"></i></span>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
                            <div class="selected">
                                <select v-model="subscriber_connection_data.employee_mounter" required>
                                    <option value="" hidden>Выберите монтажника</option>
                                    <option v-for="employee in employees"
                                            v-bind:value="employee.id">
                                        {{ employee.fio }}
                                    </option>
                                </select>
                            </div>

                        </div>
                    </div>
                    <form-error v-bind:value="errors.employee_mounter"></form-error>
                </div>
                <div class="edit-block__head blue">
                    <div class="edit-block__head--title">
                        <i class="fal fa-ethernet"></i> Системные данные
                    </div>
                </div>
                <div class="edit-block__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                            <div class="edit-block__item--title">
                                <i class="fal fa-user"></i> Логин:
                                <span class="req"><i class="fas fa-star-of-life"></i></span>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
                            <template v-if="has_perm('subscriber_connection_edit_username_password')">
                                <input type="text" v-model="subscriber_connection_data.username">
                            </template>
                            <template v-else>
                                {{ subscriber_connection_data.username }}
                            </template>
                        </div>
                    </div>
                    <form-error v-bind:value="errors.username"></form-error>
                </div>
                <div class="edit-block__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                            <div class="edit-block__item--title">
                                <i class="fal fa-key"></i> Пароль:
                                <span class="req"><i class="fas fa-star-of-life"></i></span>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
                            <template v-if="has_perm('subscriber_connection_edit_username_password')">
                                <password
                                        size="9"
                                        auto="false"
                                        v-model="subscriber_connection_data.password"
                                        :disabled="!has_perm('subscriber_connection_edit_username_password')"
                                ></password>
                            </template>
                            <template v-else>
                                {{ subscriber_connection_data.password }}
                            </template>
                        </div>
                    </div>
                    <form-error v-bind:value="errors.password"></form-error>
                </div>
                <div class="edit-block__item" v-has-perm="'subscribers_access'">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                            <div class="edit-block__item--title">
                                <i class="fal fa-server"></i> NAS:
                                <span class="req"><i class="fas fa-star-of-life"></i></span>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
                            <div class="selected">
                                <select v-model="subscriber_connection_data.nas" required>
                                    <option value="" selected disabled hidden>Выберите NAS</option>
                                    <option v-for="item in nas" v-bind:value="item.id">{{ item.name }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <form-error v-bind:value="errors.nas"></form-error>
                </div>
                <div class="edit-block__item" v-has-perm="'subscribers_add_edit'">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                            <div class="edit-block__item--title">
                                <i class="fal fa-wifi"></i> Тип подключения:
                                <span class="req"><i class="fas fa-star-of-life"></i></span>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
                            <div class="selected">
                                <select v-model="subscriber_connection_data.connection_type" required>
                                    <option value="" selected disabled hidden>Выберите тип подключения</option>
                                    <option v-for="connection_type in connection_types"
                                            v-bind:value="connection_type.id">{{
                                        connection_type.label }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
<!--                TARIFF EDIT-->
                <div class="edit-block__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                            <div class="edit-block__item--title">
                                <i class="fal fa-briefcase"></i> Тариф:
                                <span class="req"><i class="fas fa-star-of-life"></i></span>
                            </div>
                        </div>
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                                <div class="selected">
                                    <select v-model="subscriber_connection_data.tariff" required>
                                        <option value="" hidden>Выберите тариф</option>
                                        <option v-for="tariff in tariffs"
                                                v-bind:value="tariff.id">
                                            {{ tariff.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2" v-if="showLeaveServiceBlock">
                                <p><i class="fal fa-user-lock"></i>Оставить доп.услуги тарифа</p>
                                <div class="switch-checkbox">
                                    <input type="checkbox" v-model="is_connected_services_leave" class="switch-input" id="switch-leave-services">
                                    <label class="switch-input__for" for="switch-leave-services"></label>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2" v-if="showIsNewAdditionalService === false">
                                <div class="row">
                                    Услуги в новом тарифе, не пересекаються с существующими.
                                    Выберите, что делать с доп. услугами нового тарифа:
                                </div>
                                <div class="form-modal__radio-group">
                                    <label class="form-modal__radio">
                                        <input type="radio"
                                               v-model="is_new_additional_service"
                                               v-bind:value="false" required/>
                                        <span class="form-modal__radio-title">Переключить существующие</span>
                                    </label>
                                    <label class="form-modal__radio">
                                        <input type="radio"
                                               v-model="is_new_additional_service"
                                               v-bind:value="true" required/>
                                        <span class="form-modal__radio-title">Добавить к существующим</span>
                                    </label>
                                </div>
                                <form-error v-bind:value="errors.is_new_additional_service"></form-error>
                        </div>
                    </div>
                    <form-error v-bind:value="errors.tariff"></form-error>
                </div>
<!--                END TARIFF EDIT-->
                <div class="edit-block__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                            <div class="edit-block__item--title">
                                <i class="fal fa-network-wired"></i> IP:
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
                            <div class="selected">
                                <select v-model="subscriber_connection_data.framed_ip" required>
                                    <option value="" hidden>Выберите IP</option>
                                    <option>46.175.185.120</option>
                                    <option>46.175.185.121</option>
                                    <option>46.175.185.122</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="edit-block__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                            <div class="edit-block__item--title">
                                <i class="fal fa-phone-laptop"></i> MAC:
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
                            <input type="text" v-model="subscriber_connection.mac_address" disabled="disabled">
                        </div>
                    </div>
                    <form-error v-bind:value="errors.mac_address"></form-error>
                </div>
<!--                created_at-->
                <div class="edit-block__item" v-if="has_perm('subscriber_connection_edit_created_at')">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                            <div class="edit-block__item--title">
                                <i class="fal fa-phone-laptop"></i> Дата создания:
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
                            <date-picker v-model="subscriber_connection_data.created_at"
                                         :lang="'ru'"
                                         placeholder="Дата(Необязательно)"
                                         :format="'YYYY-MM-DD'">
                            </date-picker>
                        </div>
                    </div>
                    <form-error v-bind:value="errors.created_at"></form-error>
                </div>
<!--                end created_at-->

                <div class="edit-block__head blue">
                    <div class="edit-block__head--title">
                        <i class="fal fa-cogs"> Технические данные</i>
                    </div>
                </div>

                <!-- Производитель -->
                <div class="edit-block__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                            <div class="edit-block__item--title">
                                <i class="fal fa-trademark"></i> Производитель:
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
                            <input type="text" v-model="subscriber_connection_data.device_manufacturer">
                        </div>
                    </div>
                    <form-error v-bind:value="errors.device_manufacturer"></form-error>
                </div>

                <!-- Модель -->
                <div class="edit-block__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                            <div class="edit-block__item--title">
                                <i class="fal fa-barcode"></i> Модель:
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
                            <input type="text" v-model="subscriber_connection_data.device_model">
                        </div>
                    </div>
                    <form-error v-bind:value="errors.device_model"></form-error>
                </div>

                <!-- MAC -->
                <div class="edit-block__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                            <div class="edit-block__item--title">
                                <i class="fal fa-phone-laptop"></i> MAC :
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
                            <input type="text" v-model="subscriber_connection_data.device_mac">
                        </div>
                    </div>
                    <form-error v-bind:value="errors.device_mac"></form-error>
                </div>

                <!-- Порт -->
                <div class="edit-block__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                            <div class="edit-block__item--title">
                                <i class="fal fa-brackets"></i> Порт:
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
                            <input type="text" v-model="subscriber_connection_data.device_port">
                        </div>
                    </div>
                    <form-error v-bind:value="errors.device_port"></form-error>
                </div>

                <div class="edit-block__head blue">
                    <div class="edit-block__head--title">
                        <i class="fal fa-map-marker-alt"></i> Адрес включения
                    </div>
                </div>
                <div v-has-perm="'subscribers_add_edit'">
                    <div class="edit-block__item">
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                                <div class="edit-block__item--title">
                                    <i class="fal fa-globe-europe"></i> Область:
                                    <span class="req"><i class="fas fa-star-of-life"></i></span>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
                                <div class="selected">
                                    <select v-model="region" required>
                                        <option value="" selected disabled hidden>Выберите область</option>
                                        <option v-for="item in regions" v-bind:value="item.id">{{ item.name }}</option>
                                    </select>
                                </div>

                            </div>
                        </div>
                        <form-error v-bind:value="errors.region"></form-error>
                    </div>
                    <div class="edit-block__item">
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                                <div class="edit-block__item--title">
                                    <i class="fal fa-map-marked-alt"></i> Район:
                                    <span class="req"><i class="fas fa-star-of-life"></i></span>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
                                <div class="selected">
                                    <select v-model="district" required>
                                        <option value="" selected disabled hidden>Выберите район</option>
                                        <option v-for="item in districts" v-bind:value="item.id">{{ item.name }}
                                        </option>
                                    </select>
                                </div>

                            </div>
                        </div>
                        <form-error v-bind:value="errors.district"></form-error>
                    </div>
                    <div class="edit-block__item">
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                                <div class="edit-block__item--title">
                                    <i class="fal fa-map-marker-alt"></i> Город:
                                    <span class="req"><i class="fas fa-star-of-life"></i></span>
                                </div>
                            </div>
                            <div class="col-xs-10 col-sm-11 col-md-11 col-lg-6 col-xl-6">
                                <div class="selected">
                                    <div class="selected">
                                        <select v-model="settlement" required>
                                            <option value="" selected disabled hidden>Выберите населенный пункт</option>
                                            <option v-for="item in settlements" v-bind:value="item.id">{{ item.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-2 col-sm-1 col-md-1 col-lg-1 col-xl-1">
                                <div class="edit-block__item--action" data-title="Добавить населенный пункт" @click="show_settlement_popup">
                                    <i class="fal fa-plus"></i>
                                </div>
                            </div>
                        </div>
                        <form-error v-bind:value="errors.settlement"></form-error>
                    </div>
                    <div class="edit-block__item">
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                                <div class="edit-block__item--title">
                                    <i class="fal fa-road"></i> Улица:
                                    <span class="req"><i class="fas fa-star-of-life"></i></span>
                                </div>
                            </div>
                            <div class="col-xs-10 col-sm-11 col-md-11 col-lg-6 col-xl-6">
                                <v-select v-model="subscriber_connection_data.street" label="name" :reduce="street => street.id" :options="streets"></v-select>
                            </div>
                            <div class="col-xs-2 col-sm-1 col-md-1 col-lg-1 col-xl-1">
                                <div class="edit-block__item--action" data-title="Добавить улицу" @click="show_street_popup">
                                    <i class="fal fa-plus"></i>
                                </div>
                            </div>
                        </div>
                        <form-error v-bind:value="errors.street"></form-error>
                    </div>
                    <div class="edit-block__item">
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                                <div class="edit-block__item--title">
                                    <i class="fal fa-home"></i> Дом:
                                    <span class="req"><i class="fas fa-star-of-life"></i></span>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
                                <input type="text" v-model="subscriber_connection_data.house_number" placeholder="№ Дома">

                            </div>
                        </div>
                        <form-error v-bind:value="errors.house_number"></form-error>
                    </div>
                    <div class="edit-block__item">
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                                <div class="edit-block__item--title">
                                    <i class="fal fa-building"></i> Квартира:
                                    <span class="req"><i class="fas fa-star-of-life"></i></span>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
                                <input type="text" v-model="subscriber_connection_data.apartment_number"
                                       placeholder="№ Квартиры">
                            </div>
                        </div>
                        <form-error v-bind:value="errors.apartment_number"></form-error>
                    </div>
                </div>
                <div class="edit-block__footer">
                    <div class="btn grey" @click="set_mode_view">Отмена</div>
                    <div class="btn blue" @click="save"><i class="fal fa-save"></i> Сохранить</div>
                </div>
            </form>
        </div>

        <add-settlement-popup :settings="settlement_popup" v-on:update_settlement="update_settlement"></add-settlement-popup>
        <add-street-popup :settings="street_popup" v-on:update_street="update_street"></add-street-popup>
    </div>
</template>

<script>
    import {API} from "../../../../api";
    import {SubscriberConnectionMixin} from "../../../../mixins/admin/subscriber_connection";
    import {SubscriberTypeMixin} from "../../../../mixins/admin/subscriber_type";
    import {SubscriberMixin} from "../../../../mixins/admin/subscriber";
    import {GroupMixin} from "../../../../mixins/admin/group";
    import {DepartmentMixin} from "../../../../mixins/admin/department";
    import {NasMixin} from "../../../../mixins/admin/nas";
    import {ConnectionTypeMixin} from "../../../../mixins/admin/connection_type";
    import PasswordGeneration from '../password/generation.vue';
    import FormErrors from '../form/errors.vue';
    import {AddressMixin} from "../../../../mixins/admin/address";
    import AddSettlement from './add-settlement.vue'
    import AddStreet from './add-street.vue'
    import {TariffMixin} from "../../../../mixins/admin/tariff";
    import {EmployeeMixin} from "../../../../mixins/admin/employee";
    import DatePicker from 'vue2-datepicker';
    import {TransferTariffMixin} from "../../../../mixins/admin/transfer_tariff";
    import {InitObjectHelper} from "../../../../mixins/admin/init_object_helper";

    export default {
        name: "subscribers-connection-edit",
        props: ['subscriber_connection'],
        data: function () {
            var settlement = "";
            var district = "";
            var region = "";
            var old_tariff = {};

            if (Object.keys(this.subscriber_connection).length && this.subscriber_connection.settlement_expand) {

                district = this.subscriber_connection.settlement_expand.district;
                region = this.subscriber_connection.settlement_expand.district_expand.region;
                settlement = this.subscriber_connection.settlement;
                old_tariff = this.subscriber_connection.tariff_expand;
            }

            return {
                region: region,
                district: district,
                settlement: settlement,
                old_tariff: old_tariff,
                new_tariff_obj: {},
                is_connected_services_leave: false,
                is_new_additional_service: null,
                errors: {},
                settlement_popup: {
                    region: "",
                    district: "",
                    visible: false
                },
                street_popup: {
                    visible: false
                },
                subscriber_connection_data: {
                    password: this.subscriber_connection.password,
                    username: this.subscriber_connection.username,
                    group: this.subscriber_connection.group,
                    department: this.subscriber_connection.department,
                    nas: this.subscriber_connection.nas,
                    device_manufacturer: this.subscriber_connection.device_manufacturer,
                    device_model: this.subscriber_connection.device_model,
                    device_mac: this.subscriber_connection.device_mac,
                    device_port: this.subscriber_connection.device_port,
                    tariff: this.subscriber_connection.tariff,
                    employee_mounter: this.subscriber_connection.employee_mounter,
                    connection_type: this.subscriber_connection.connection_type,
                    settlement: this.subscriber_connection.settlement,
                    street: this.subscriber_connection.street,
                    house_number: this.subscriber_connection.house_number,
                    apartment_number: this.subscriber_connection.apartment_number,
                    subscriber_type: this.subscriber_connection.subscriber_expand.subscriber_type,
                    created_at: this.subscriber_connection.created_at,
                    // TODO: framed_ip
                    // framed_ip: this.subscriber_connection.framed_ip,
                    subscriber_expand: this.subscriber_connection.subscriber_expand
                }
            }

        },
        components: {
            "password": PasswordGeneration,
            "form-error": FormErrors,
            "add-settlement-popup": AddSettlement,
            "add-street-popup": AddStreet,
            "date-picker": DatePicker,
        },
        mixins: [
            SubscriberMixin,
            SubscriberConnectionMixin,
            SubscriberTypeMixin,
            GroupMixin,
            DepartmentMixin,
            EmployeeMixin,
            NasMixin,
            ConnectionTypeMixin,
            AddressMixin,
            TariffMixin,
            TransferTariffMixin,
            InitObjectHelper
        ],
        created: function () {
            this.load_subscriber_types();
            this.load_groups();
            this.load_departments();
            this.load_nas_by_department_employee_permissions();
            this.load_connection_type();
            this.load_regions();
            this.load_districts(this.region);
            this.load_settlements(this.district);
            this.load_streets();
            this.load_tariff(this.subscriber_connection_data);
            this.load_employees();
        },
        methods: {
            save: function () {
                var self = this;
                // var subscriber_connection_data = {
                //     password: this.subscriber_connection.password,
                //     username: this.subscriber_connection.username,
                //     group: this.subscriber_connection.group,
                //     department: this.subscriber_connection.department,
                //     nas: this.subscriber_connection.nas,
                //     device_manufacturer: this.subscriber_connection.device_manufacturer,
                //     device_model: this.subscriber_connection.device_model,
                //     device_mac: this.subscriber_connection.device_mac,
                //     device_port: this.subscriber_connection.device_port,
                //     tariff: this.subscriber_connection.tariff,
                //     employee_mounter: this.subscriber_connection.employee_mounter,
                //     connection_type: this.subscriber_connection.connection_type,
                //     settlement: this.settlement,
                //     street: this.subscriber_connection.street,
                //     house_number: this.subscriber_connection.house_number,
                //     apartment_number: this.subscriber_connection.apartment_number,
                //     subscriber_type: this.subscriber_connection.subscriber_expand.subscriber_type,
                // };

                if (!this.has_perm('subscriber_connection_edit_created_at')) {
                    this.subscriber_connection_data['created_at'] = undefined;
                }

                if (!this.has_perm('subscriber_connection_edit_username_password')) {
                    this.subscriber_connection_data['username'] = undefined;
                    this.subscriber_connection_data['password'] = undefined;
                }

                this.subscriber_connection_data['is_connected_services_leave'] = this.is_connected_services_leave;
                this.subscriber_connection_data['is_new_additional_service'] = this.is_new_additional_service;

                // Edit Subscriber Connection
                this.edit_subscriber_connection(this.subscriber_connection.id, this.subscriber_connection_data).then(function (response) {
                    // self.save_state.subscriber_connection = true;
                    self.set_mode_view();
                    // self.save_subscriber();
                })
                    .catch(function (error) {
                        // API.default_catch(self, error);
                        self.errors = Object.assign({}, self.errors, API.convert_errors(API.get_errors(error)));
                    })
                    .then(function () {
                        API.default_always(self);
                    });
            },
            set_mode_view: function () {
                this.$emit('update_subscriber_connection');
                this.$emit('set_mode', 'view')
            },
        },
        watch: {
            'subscriber_connection_data.group': function (value) {
                this.load_tariff(this.subscriber_connection_data);
            },
            'subscriber_connection_data.department': function (value) {
                this.load_tariff(this.subscriber_connection_data);
            },
            settlement: function (value) {
                this.$set(this.subscriber_connection_data, "settlement", value);
                this.load_tariff(this.subscriber_connection_data);
            },
            'subscriber_connection_data.connection_type': function (value) {
                this.load_tariff(this.subscriber_connection_data);
            },
            'subscriber_connection_data.subscriber_expand.subscriber_type': function (value) {
                this.load_tariff(this.subscriber_connection_data);
            },
            'subscriber_connection_data.tariff': function (value) {
                this.showLeaveServiceBlock = false;

                if (value !== this.old_tariff.id) {
                    this.new_tariff_obj = this.initObjectByIdFromArray(value, this.tariffs);

                    this.hasTariffFromServicesAndTariffToHasNot(this.old_tariff, this.new_tariff_obj);

                    this.checkChangeTariffToNewAdditionalServices(
                        this.subscriber_connection.id,
                        this.old_tariff.id,
                        this.new_tariff_obj.id
                    );
                }
            },
        }
    }
</script>

<style scoped>

</style>