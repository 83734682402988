<template>
    <popup :settings="settings">
        <div slot="title">
            <template v-if="tariff_mass_id">Массовая смена тарифа</template>
            <template v-else>Смена тарифа</template>
        </div>
        <div slot="content">
            <div class="body-size">
                <div class="body-size__480">
                    <form class="form-modal">
                        <div class="modal-value">
                            <div class="modal-value__item">
                                <span class="modal-value__label">
                                    <span class="modal-value__label-title">Текущий тариф:</span>
                                </span>
                                <span class="modal-value__change">
                                    <span class="modal-value__change-price">{{ tariff.system_name }}</span>
                                </span>
                            </div>
                        </div>
                        <div class="form-modal__group">
                            <fieldset class="form-modal__item">
                                <span class="form-modal__title">
                                        <span class="form-modal__icon"><i class="fal fa-briefcase"></i></span>
                                         Тарифы
                                </span>
                                <div class="form-modal__select">
                                    <select v-model="item.tariff_to" required>
                                        <option value="" hidden>Выберите тариф</option>
                                        <option v-for="tariff_item in filtered_tariffs"
                                                v-bind:value="tariff_item.id">
                                            {{ tariff_item.system_name }}
                                        </option>
                                    </select>
                                </div>
                                <form-error v-bind:value="errors.tariff_to"></form-error>
                            </fieldset>
                        </div>
                        <div class="form-modal__group">
                            <fieldset class="popup-add__form--item">
                                <label class="checkbox"><input type="checkbox" v-model="item.is_planed_transfer">
                                    <span class="checkmark"></span> <span class="checkbox-title">Запланировать:</span>
                                </label>
                                <date-picker v-model="transfer_date" :lang="'ru'" placeholder="Дата"
                                             :format="'DD-MM-YYYY'"
                                             :disabled="item.is_instant_transfer"></date-picker>
                                <form-error v-bind:value="errors.transfer_date"></form-error>
                            </fieldset>
                        </div>
                        <!-- TARIFF CHANGE PRICE-->
                        <div class="form-modal__group" v-if="show_change_tariff_price()">
                            <fieldset class="modal-form__item" >
                                <div class="row">
                                    Стоимость смены тарифа {{ change_tariff_price }}грн
                                </div>
                                <div class="row" v-if="has_perm('financial_operations_change_tariff_free')">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <label class="checkbox"><input v-model="item.is_free_tariff_change" type="checkbox">
                                            <span class="checkmark"></span> <span class="checkbox-title">Бесплатная смена тарифа</span></label>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
<!--                        END TARIFF CHANGE PRICE-->
<!--                        LEAVE INSTALLED SERVICES-->
                        <div class="form-modal__group" v-if="showLeaveServiceBlock">
                            <fieldset class="modal-form__item" >
                                <div class="row">
                                    Оставить существующие доп. услуги? Они станут платными.
                                </div>
                                <div class="row">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <label class="checkbox"><input v-model="item.is_connected_services_leave"
                                                                       type="checkbox">
                                            <span class="checkmark"></span> <span class="checkbox-title">Оставить доп. услуги</span></label>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
<!--                        END LEAVE INSTALLED SERVICES-->
                        <div class="form-modal__group" v-if="showIsNewAdditionalService === false">
                            <fieldset class="modal-form__item" >
                                <div class="row">
                                    Услуги в новом тарифе, не пересекаються с существующими.
                                    Выберите, что делать с доп. услугами нового тарифа:
                                </div>
                                <div class="form-modal__radio-group">
                                    <label class="form-modal__radio">
                                        <input type="radio"
                                               v-model="item.is_new_additional_service"
                                               v-bind:value="false" required/>
                                        <span class="form-modal__radio-title">Переключить существующие</span>
                                    </label>
                                    <label class="form-modal__radio">
                                        <input type="radio"
                                               v-model="item.is_new_additional_service"
                                               v-bind:value="true" required/>
                                        <span class="form-modal__radio-title">Добавить к существующим</span>
                                    </label>
                                </div>
                                <form-error v-bind:value="errors.is_new_additional_service"></form-error>
                            </fieldset>
                        </div>
                        <!--Promotional tariff-->
                        <div class="form-modal__group" v-if="showPromotionalTariffNotificationBlock && tariff_to_obj">
                            <fieldset class="modal-form__item" >
                                <div class="row">
                                    ВАЖНО. Новый Тарифный план является акционным и закончит свое действие {{ tariff_to_obj.promotional_tariff_settings_expand.finish_date }}
                                    После окончания срока действия акционного тарифа - абонент будет переведен на тарифный план {{ tariff_to_obj.promotional_tariff_settings_expand.tariff_to_expand.name }}
                                </div>
                            </fieldset>
                        </div>
                        <!--End Promotional tariff-->
                        <!--Optional parameters for mass transfers-->
                        <template v-if="tariff_mass_id">
                            <div class="modal-divide">
                                <div class="modal-divide__title">
                                    <span class="modal-divide__title-icon">
                                        <i class="fal fa-cog"></i>
                                    </span>Дополнительные параметры
                                </div>
                            </div>
                            <div class="form-modal__group">
                                <fieldset class="form-modal__item form-modal__item_switch">
                                    <span class="form-modal__title">
                                    <span class="form-modal__icon"><i class="fal fa-map-marker-alt"></i></span>
                                        Населенный пункт
                                    </span>
                                    <input type="checkbox" class="form-modal__input form-modal__input_switch"
                                           v-model="choose_settlement" id="switch-settlement">
                                    <label class="form-modal__switch-for"
                                           for="switch-settlement"></label>
                                </fieldset>
                            </div>
                            <multiselect v-if="choose_settlement"
                                         v-model="settlements_list"
                                         :options="settlements"
                                         :multiple="true"
                                         :close-on-select="true"
                                         :clear-on-select="false"
                                         placeholder="Выберите НП"
                                         label="name"
                                         track-by="name">
                                <template slot="option" slot-scope="props">
                                    <option>{{ props.option.name }}</option>
                                </template>
                            </multiselect>
                        </template>
                    </form>
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="btn-group">
                <span class="btn btn_grey" @click="popup_close">
                    <span class="btn__icon"><i class="fal fa-times-circle"></i></span>
                    <span class="btn__title">Отмена</span>
                </span>
                <span class="btn btn_blue" @click="save()">
                    <span class="btn__icon"><i class="fal fa-check"></i></span>
                    <span class="btn__title">Установить</span>
                </span>
            </div>
        </div>
    </popup>
</template>

<script>
    import Multiselect from 'vue-multiselect';
    import Popup from '../modal/popup.vue'
    import FormErrors from '../form/errors.vue';
    import {TariffMixin} from "../../../../mixins/admin/tariff";
    import {CrudMixin} from "../../../../mixins/admin/crud/crud_base";
    import DatePicker from 'vue2-datepicker';
    import {API} from "../../../../api";
    import {TransferTariffMixin} from "../../../../mixins/admin/transfer_tariff";

    export default {
        name: "transfer-tariffs",
        props: ["settings", "subscriber_connection", "tariff_mass_id"],
        mixins: [
            TariffMixin, CrudMixin, TransferTariffMixin
        ],
        components: {
            "date-picker": DatePicker,
            "popup": Popup,
            "form-error": FormErrors,
            Multiselect,
        },
        data: function () {
            return {
                filtered_tariffs: [],
                transfer_date: "",
                change_tariff_price: 0,
                settlements: [],
                choose_settlement: false,
                settlements_list: null,
                item: {
                    tariff_from: null,
                    tariff_to: null,
                    transfer_date: null,
                    subscriber_connection: null,
                    is_planed_transfer: false,
                    is_instant_transfer: true,
                    is_free_tariff_change: false,
                    is_connected_services_leave: false,
                    is_new_additional_service: null
                },
                tariff_to_obj: null,
                errors: {}
            }
        },
        methods: {
            clear() {
                this.transfer_date = "";

                this.$set(this.item, "tariff_to", null);
                this.$set(this.item, "transfer_date", null);
                this.$set(this.item, "is_planed_transfer", false);
                this.$set(this.item, "is_free_tariff_change", false);
                this.$set(this.item, "is_connected_services_leave", false);
                this.$set(this.item, "is_new_additional_service", null);
                this.settlements_list = [];
                this.choose_settlement = false;
                this.showLeaveServiceBlock = false;
                this.showIsNewAdditionalService = undefined;
            },
            init_subscriber_connection() {
                if (!this.subscriber_connection) {
                    return
                }
                this.clear();
                this.item.subscriber_connection = this.subscriber_connection.id;

                this.get_tariff(this.subscriber_connection.tariff);

            },
            init_tariff_mass_id() {
                if (!this.tariff_mass_id) {
                    return
                }
                this.clear();
                this.item.tariff_from = this.tariff_mass_id;

                this.get_tariff(this.tariff_mass_id);
            },
            show_change_tariff_price() {
                // If Subscriber type is private && price > 0
                return this.subscriber_connection && this.subscriber_connection.subscriber_expand &&
                    this.subscriber_connection.subscriber_expand.subscriber_type_expand.name === 'private' &&
                    this.change_tariff_price;
            },
            save() {
                var self = this;
                self.errors = {};

                if (!self.tariff_mass_id) {
                    API.post('/subscriber-connections-tariff-transfer/', this.item)
                        .then(function (response) {
                            self.$emit('update_subscriber_connection');
                            self.$emit('update_tariff_transfer');
                            self.clear();
                            self.popup_close();
                        })
                        .catch(function (error) {
                            API.default_catch(self, error)
                        })
                        .then(function () {
                            API.default_always(self);
                        })
                } else {
                    let params = this.item;
                    if (this.settlements_list) {
                        params['settlements'] = this.settlements_list.map(el => el.id);
                    }

                    API.post('/tariffs-mass-transfer/', params)
                        .then(function (response) {
                            self.clear();
                            self.popup_close();
                            self.$emit('update_tariffs');
                        })
                        .catch(function (error) {
                            API.default_catch(self, error)
                        })
                        .then(function () {
                            API.default_always(self);
                        })
                }
            },
            loadTariffConnectionsSettlements() {
                var self = this;
                API.get('/tariffs/' + this.tariff_mass_id + '/tariff-connections-settlements/')
                    .then(function (response) {
                        self.settlements = response.data;
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            },
            getCorrectDate(value) {
                return this.$moment(value).format("YYYY-MM-DD");
            },
            getParamsAvailableTariffsForTransfer() {
              return {
                transfer_date: this.item.transfer_date,
                settlements: this.settlements_list.map(el => el.id)
              };
            }
        },
        watch: {
            choose_settlement: function (value) {
                if (!value) {
                    this.settlements_list = [];
                }
            },
            subscriber_connection: function (value) {
                this.init_subscriber_connection();
            },
            tariff_mass_id: function (value) {
                this.init_tariff_mass_id();
                this.loadTariffConnectionsSettlements();
            },
            tariff: function (value) {
                if (this.subscriber_connection) {
                    this.get_available_tariffs_by_id(this.subscriber_connection.id);
                } else {
                    this.get_available_tariffs_for_transfer(this.tariff_mass_id);
                }
            },
            tariffs: function (value) {
                var self = this;

                // Exclude current tariff
                this.filtered_tariffs = value.filter(function (el) {

                    if (self.subscriber_connection) {
                        return el.id !== self.subscriber_connection.tariff;

                    } else if (self.tariff_mass_id) {
                        return el.id !== self.tariff_mass_id;
                    }
                });

                this.item.tariff_to = null;

            },
            "item.is_planed_transfer": function (value) {
                this.item.is_instant_transfer = !value;

                if (this.item.is_instant_transfer === true) {
                    this.item.transfer_date = null;
                } else {
                    if (this.transfer_date) {
                      this.item.transfer_date = this.getCorrectDate(this.transfer_date);
                    }
                }
            },
            "item.tariff_to": function (value) {
                this.change_tariff_price = 0;
                if (value) {
                    for (var i in this.tariffs) {
                        if (this.item.tariff_to === this.tariffs[i].id) {
                            this.tariff_to_obj = this.tariffs[i];
                            this.change_tariff_price = this.tariffs[i].change_tariff_price;
                        }
                    }
                } else {
                    this.tariff_to_obj = null;
                }

                if (value) {
                    this.hasTariffFromServicesAndTariffToHasNot(this.tariff, this.tariff_to_obj);
                    this.tariffToIsPromotionalTariff(this.tariff_to_obj);

                    if(!this.tariff_mass_id) {
                        this.checkChangeTariffToNewAdditionalServices(
                            this.item.subscriber_connection,
                            this.item.tariff_from,
                            this.item.tariff_to
                        );
                    }
                }
            },
            'item.transfer_date': function (value) {
                let params = this.getParamsAvailableTariffsForTransfer();

                if (this.subscriber_connection) {
                    this.get_available_tariffs_by_id(this.subscriber_connection.id, params);
                } else {
                    this.get_available_tariffs_for_transfer(this.tariff_mass_id, params);
                }
            },
            transfer_date(value) {
                if (value) {
                    this.item.transfer_date = this.getCorrectDate(value);
                }
            },
            settlements_list(value) {
              if (value && this.tariff_mass_id) {
                let params = this.getParamsAvailableTariffsForTransfer();
                this.get_available_tariffs_for_transfer(this.tariff_mass_id, params);
              }
            }
        }
    }
</script>

<style scoped>

</style>