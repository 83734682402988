<template>
  <section class="page">
    <div class="page__inner">
      <div class="container-width">
        <!--HEADER PAGE-->
        <div class="page__header">
          <h1 class="page__title">Отчеты</h1>
          <report-menu :active_name="'reports-page'"></report-menu>
        </div>
        <!--END HEADER PAGE-->
        <!--REPORT GRID-->
        <div class="reports-grid">
          <div class="reports-grid__list">
            <router-link class="reports-grid__item" :to="{ name: 'reports-finance'}" v-if="showing_menu_item('reports-finance')">
              <div class="reports-grid__icon"><i class="fal fa-hryvnia"></i></div>
              <div class="reports-grid__info">
                <span class="reports-grid__info-name">Финансы</span>
                <span class="reports-grid__info-desc">Отчет по финансам</span>
              </div>
            </router-link>
            <router-link class="reports-grid__item" :to="{ name: 'reports-subscribers'}" v-if="showing_menu_item('reports-subscribers')">
              <div class="reports-grid__icon"><i class="fal fa-users"></i></div>
              <div class="reports-grid__info">
                <span class="reports-grid__info-name">Абоненты</span>
                <span class="reports-grid__info-desc">Отчет по абонентам</span>
              </div>
            </router-link>
            <router-link class="reports-grid__item" :to="{ name: 'reports-nas'}" v-if="showing_menu_item('reports-nas')">
              <div class="reports-grid__icon"><i class="fal fa-server"></i></div>
              <div class="reports-grid__info">
                <span class="reports-grid__info-name">NAS</span>
                <span class="reports-grid__info-desc">Отчет по NAS</span>
              </div>
            </router-link>
            <router-link class="reports-grid__item" :to="{ name: 'reports-settlements'}" v-if="showing_menu_item('reports-settlements')">
              <div class="reports-grid__icon"><i class="fal fa-map-marker-alt"></i></div>
              <div class="reports-grid__info">
                <span class="reports-grid__info-name">Населенные пункты</span>
                <span class="reports-grid__info-desc">Отчет по населенным пунктам</span>
              </div>
            </router-link>
            <router-link class="reports-grid__item" :to="{ name: 'reports-tariffs'}" v-if="showing_menu_item('reports-tariffs')">
              <div class="reports-grid__icon"><i class="fal fa-briefcase"></i></div>
              <div class="reports-grid__info">
                <span class="reports-grid__info-name">Тарифы</span>
                <span class="reports-grid__info-desc">Отчет по тарифам</span>
              </div>
            </router-link>
            <router-link class="reports-grid__item" :to="{ name: 'reports-departments'}" v-if="showing_menu_item('reports-departments')">
              <div class="reports-grid__icon"><i class="fal fa-user-tie"></i></div>
              <div class="reports-grid__info">
                <span class="reports-grid__info-name">Отделы</span>
                <span class="reports-grid__info-desc">Отчет по отделам</span>
              </div>
            </router-link>
            <router-link class="reports-grid__item" :to="{ name: 'reports-services'}" v-if="showing_menu_item('reports-services')">
              <div class="reports-grid__icon"><i class="fal fa-toolbox"></i></div>
              <div class="reports-grid__info">
                <span class="reports-grid__info-name">Услуги</span>
                <span class="reports-grid__info-desc">Отчет по услугам</span>
              </div>
            </router-link>
            <!--REPORT NETWORKS UNCOMMENT THIS WHEN NEED IT-->
<!--            <router-link class="reports-grid__item" :to="{ name: 'reports-network'}" v-if="showing_menu_item('reports-network')">-->
<!--              <div class="reports-grid__icon"><i class="fal fa-network-wired"></i></div>-->
<!--              <div class="reports-grid__info">-->
<!--                <span class="reports-grid__info-name">Сеть</span>-->
<!--                <span class="reports-grid__info-desc">Отчет по сети</span>-->
<!--              </div>-->
<!--            </router-link>-->
            <router-link class="reports-grid__item" :to="{ name: 'reports-subscribers-leak'}" v-if="showing_menu_item('reports-subscribers-leak')">
              <div class="reports-grid__icon"><i class="fal fa-network-wired"></i></div>
              <div class="reports-grid__info">
                <span class="reports-grid__info-name">Утечка абонентов</span>
                <span class="reports-grid__info-desc">Отчет по потерям абонентов за указаный период</span>
              </div>
            </router-link>
            <router-link class="reports-grid__item" :to="{ name: 'reports-payment-delay'}" v-if="showing_menu_item('reports-subscribers-leak')">
              <div class="reports-grid__icon"><i class="fal fa-network-wired"></i></div>
              <div class="reports-grid__info">
                <span class="reports-grid__info-name">Задержка оплат</span>
                <span class="reports-grid__info-desc">Отчет по задержам оплат за указаный период</span>
              </div>
            </router-link>
            <router-link class="reports-grid__item" :to="{ name: 'reports-analytics'}" v-if="showing_menu_item('reports-analytics')">
              <div class="reports-grid__icon"><i class="fal fa-chart-pie"></i></div>
              <div class="reports-grid__info">
                <span class="reports-grid__info-name">Аналитика</span>
                <span class="reports-grid__info-desc">Отчет по аналитике</span>
              </div>
            </router-link>
          </div>
        </div>
        <!--END REPORT GRID-->
      </div>
    </div>
  </section>
</template>


<script>
import ReportMenu from './menu/menu.vue';
import {ReportsMixin} from "../../../../mixins/admin/reports";

export default {
  name: "Report-page",
  components: {
    "report-menu": ReportMenu
  },
  mixins: [ReportsMixin, ],
  data() {
    return {
      report_page_perms: this.get_report_page_perms(),
    }
  }
}
</script>

<style scoped>

</style>