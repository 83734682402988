<template>
    <div class="notification orange" v-if="currentTariff.promotional_tariff_settings_expand && Object.keys(tariff).length > 0">
       <div class="notification__icon"><i class="fal fa-engine-warning"></i></div>
        <div class="notification__title">
            Тарифный план является акционным и закончится
            <b>{{ currentTariff.promotional_tariff_settings_expand.finish_date | moment("DD/MM/YYYY") }}</b>
        </div>
        <div class="notification__desc">
            После окончания срока действия акционного тарифа - абонент будет переведен на тарифный план <b>"{{ tariff.name }}"</b>
        </div>
       <div class="notification__action"></div>
    </div>
</template>

<script>
    import {TariffMixin} from "../../../../../mixins/admin/tariff";

    export default {
        name: "promotional-tariff",
        props: ["subscriber_connection"],
        mixins: [TariffMixin, ],
        data() {
            return {
                currentTariff: {},
            }
        },
        watch: {
            subscriber_connection: {
                handler(value) {
                    this.currentTariff = value.tariff_expand;
                },
                deep: true,
                immediate: true
            },
            currentTariff: {
                handler(value) {
                    if (value.promotional_tariff_settings_expand) {
                        this.get_tariff(value.promotional_tariff_settings_expand.tariff_to);
                    }
                },
                deep: true,
                immediate: true
            }
        }
    }
</script>

<style scoped>

</style>