<template>
  <label class="tr-list__checkbox tr-list__checkbox_all" id="select_all" >
      <input type="checkbox" v-model="select_all_check" @change="select_all(item)" >
      <span class="tr-list__checkmark"></span>
      <span class="tr-list__name tr-list__name_all">Отметить все</span>
  </label>
</template>

<script>
export default {
    name: "checker",
    props: ['item', 'tariff_region'],
    data() {
        return {
          select_all_check: false,
        }
    },
    methods: {
        select_all(value) {
            this.$emit("select_all", value, this.select_all_check);
        },
    },
    watch: {
        tariff_region: {
            handler() {
                this.select_all_check = false;
            },
            deep: true
        },
    }
}
</script>

<style scoped>

</style>