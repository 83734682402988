<template>
    <span>
        <template v-if="unicard.unicard_shop_batch">
            <template v-if="unicard.unicard_shop_batch_expand.shop_expand">
                {{ unicard.unicard_shop_batch_expand.shop_expand.name }}
            </template>
        </template>
        <template v-else>-</template>
    </span>
</template>

<script>
    export default {
        name: "field_owner_unicard_shop_batch",
        props: ['unicard']
    }
</script>

<style scoped>

</style>