<template>
     <popup :settings="settings">
        <div slot="title">Смена пакета TV Trinity</div>
        <div slot="content">
            <div class="body-size">
                <div class="body-size__480">
                    <form class="form-modal">
                        <div class="form-modal__group">
                            <fieldset class="form-modal__item">
                                <span class="form-modal__title">
                                <span class="form-modal__icon"><i class="fal fa-box"></i></span>
                                        Название пакета
                                </span>
                                <div class="form-modal__select">
                                    <select v-model="new_service_iptv_triniti" required>
                                        <option v-for="iptv_triniti_service in iptv_triniti_service_list"
                                                :value="iptv_triniti_service">{{ iptv_triniti_service.service_name }}</option>
                                    </select>
                                </div>
                                <form-error v-bind:value="errors.new_service_iptv_triniti_id"></form-error>
                            </fieldset>
                        </div>
                        <div class="form-service" v-if="Object.keys(new_service_iptv_triniti).length && new_service_iptv_triniti">
                            <div class="form-service__total">
                                <div class="form-service__total-title">Стоимость:</div>
                                <div class="form-service__total-price">
                                    <span class="form-service__summ">
                                        <span class="form-service__summ-currency">&#x20b4</span>
                                        <span class="form-service__summ-value">{{ new_service_iptv_triniti.total_cost_month }}</span>
                                        <span class="form-service__summ-period">/мес</span>
                                    </span>
                                    <span class="form-service__summ-divide"></span>
                                    <span class="form-service__summ" v-if="new_service_iptv_triniti.total_cost_day">
                                        <span class="form-service__summ-currency">&#x20b4</span>
                                        <span class="form-service__summ-value">{{ new_service_iptv_triniti.total_cost_day }}</span>
                                        <span class="form-service__summ-period">/день</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="btn-group">
                <span class="btn btn_grey" @click="popup_close">
                    <span class="btn__icon"><i class="fal fa-times-circle"></i></span>
                    <span class="btn__title">Я передумал</span>
                </span>
                <span class="btn btn_blue" @click="save">
                    <span class="btn__icon"><i class="fal fa-check"></i></span>
                    <span class="btn__title">Сохранить</span>
                </span>
            </div>
        </div>
    </popup>
</template>

<script>
    import Popup from '../../../../modal/popup.vue';
    import FormErrors from '../../../../form/errors.vue';
    import {CrudMixin} from "../../../../../../../mixins/admin/crud/crud_base";
    import {API} from "../../../../../../../api";

    export default {
        name: "tv-settings",
        props: ["settings", "subscriber_services", "service_tv"],
        components: {
            "popup": Popup,
            "form-error": FormErrors,
        },
         mixins: [
            CrudMixin,
        ],
        data() {
            return {
                iptv_triniti_service_list: [],
                new_service_iptv_triniti: {},
                errors: [],
            }
        },
        // created(){
        //     this.initIpTvTrinitiServiceList();
        // },
        methods: {
            save() {
                this.change_service_iptv_triniti();
            },
            initIpTvTrinitiServiceList() {
                // filter from prop "subscriber_services.available_service_list",
                // service where service_type = service_iptv_triniti
                if (Object.keys(this.subscriber_services.available_service_list).length > 0) {
                    this.iptv_triniti_service_list = this.subscriber_services.available_service_list.filter(
                        elm => (elm.service_type === 'service_iptv_triniti' && elm.id !== this.service_tv.services_service_id)
                    );
                }
            },
            change_service_iptv_triniti() {
                var self = this;

                API.post('/services/iptv-triniti-subscriber-connection/' +
                    self.service_tv.service_object_id + '/change-service-tariff/',{
                    new_service_iptv_triniti_id: self.new_service_iptv_triniti.id,
                })
                    .then(function (response) {
                        self.$emit('update_subscriber_connection');
                        self.popup_close();
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            },
        },
        watch: {
            service_tv(value) {
                if (value) {
                    this.initIpTvTrinitiServiceList();
                }
            }
        }
    }
</script>

<style scoped>

</style>