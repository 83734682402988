<template>
  <div class="filters">
    <div class="filters__body">
      <slot></slot>
      <div class="filters__actions">
        <div class="filters__btn" @click="$emit('resetComponentFilters')">
          <span class="filters__btn-icon"></span>
          <span class="filters__btn-title">Сбросить</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "filter-block",
}
</script>

<style scoped>

</style>