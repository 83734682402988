<template>
    <div class="table-data__price">
        <template v-if="item.charging_type === 'month'">
            <span class="table-data__price-icon">₴</span>
            <span class="table-data__price-value">{{ item.charging_amount_month }}</span>
        </template>
        <template v-if="item.charging_type === 'day'">
            <span class="table-data__price-item">
                <span class="table-data__price-icon">₴</span>
                <span class="table-data__price-value">{{ item.charging_amount_month }}</span>
            </span>
            <span class="table-data__price-divide"></span>
            <span class="table-data__price-item">
                <span class="table-data__price-icon">₴</span>
                <span class="table-data__price-value">{{ item.charging_amount_day }}</span>
            </span>
        </template>
        <template v-if="item.charging_type === 'hour'">
            <span class="table-data__price-icon">₴</span>
            <span class="table-data__price-value">{{ item.charging_amount_hour }}</span>
        </template>
    </div>
</template>

<script>
    export default {
        name: "tariffs-charging-type",
        props: ["item"]
    }
</script>

<style scoped>

</style>