<template>
        <popup :settings="settings">
        <div slot="title">Сброс корректировки</div>
        <div slot="content">
            <div class="body-size">
                <div class="body-size__480">
                    <div class="modal-value">
                        <div class="modal-value__item">
                            <span class="modal-value__label">
                                <span class="modal-value__label-title">Текущая корректировка:</span>
                            </span>
                            <span class="modal-value__change">
                                <span class="modal-value__change-icon">₴</span>
                                <span class="modal-value__change-price">{{ subscriber_connection.tariff_adjustment_amount }}</span>
                            </span>
                        </div>
                        <div class="modal-value__item">
                           <div class="modal-value__msg modal-value__msg_blue">
                               <span class="modal-value__msg-icon"><i class="fal fa-engine-warning"></i></span>
                               Вы действительно хотите сбросить корректировку?
                           </div>
                        </div>
                        <form-error v-bind:value="errors.non_field_errors"></form-error>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="btn-group">
                <span class="btn btn_grey" @click="popup_close">
                    <span class="btn__icon"><i class="fal fa-times-circle"></i></span>
                    <span class="btn__title">Я передумал</span>
                </span>
                <span class="btn btn_blue" @click="save()">
                    <span class="btn__icon"><i class="fal fa-check"></i></span>
                    <span class="btn__title">Сбросить</span>
                </span>
            </div>
        </div>
    </popup>
</template>

<script>

    import Popup from '../../modal/popup.vue'
    import {CrudMixin} from "../../../../../mixins/admin/crud/crud_base";
    import FormErrors from '../../form/errors.vue';
    import {API} from "../../../../../api";
    import {SubscriberConnectionMixin} from "../../../../../mixins/admin/subscriber_connection";

    export default {
        name: "balance-adjustment-reset",
        props: ["settings", "subscriber_connection"],
        mixins: [
            CrudMixin, SubscriberConnectionMixin
        ],
        components: {
            "popup": Popup,
            "form-error": FormErrors
        },
        data(){
          return {
              errors: {}
          }
        },
        methods:{
            save(){
                var self = this;
                this.reset_tariff_adjustment(this.subscriber_connection.id)
                    .then(function (response) {
                        self.$emit('update_subscriber_connection');
                        self.popup_close();
                    })
                    .catch(function (error) {
                        API.default_catch(self, error);
                    })
                    .then(function () {
                        API.default_always(self);
                    });
            }
        }
    }
</script>

<style scoped>

</style>