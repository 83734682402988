<template>
    <div>
        <div class="form-modal__group">
            <fieldset class="form-modal__item">
                <span class="form-modal__title">
                    <span class="form-modal__icon"><i class="fal fa-arrow-right"></i></span>
                    Пакет
                </span>
                <div class="form-modal__select">
                    <select v-model="service_iptv" required>
                        <option value="">Выберите пакет</option>
                        <option v-for="item in services_iptv_triniti" :value="item.id">{{ item.name }}</option>
                    </select>
                </div>
                <form-error v-bind:value="service_errors.service_iptv_triniti"></form-error>
            </fieldset>
        </div>
        <div class="form-service" v-if="Object.keys(service_iptv_obj).length && service_iptv_obj">
            <div class="form-service__total">
                <div class="form-service__total-title">Стоимость:</div>
                <div class="form-service__total-price">
                    <span class="form-service__summ">
                        <span class="form-service__summ-currency">&#x20b4</span>
                        <span class="form-service__summ-value">{{ service_iptv_obj.charging_amount_month }}</span>
                        <span class="form-service__summ-period">/мес</span>
                    </span>
                    <span class="form-service__summ-divide"></span>
                    <span class="form-service__summ" v-if="service_iptv_obj.charging_amount_day">
                        <span class="form-service__summ-currency">&#x20b4</span>
                        <span class="form-service__summ-value">{{ service_iptv_obj.charging_amount_day }}</span>
                        <span class="form-service__summ-period">/день</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {API} from "../../../../../../api";
    import {loader} from "../../../../../../models/loader";
    import FormErrors from '../../../form/errors.vue';

    export default {
        name: "service_tv",
        props: ["service", "tariff", "service_errors"],
        components: {
            "form-error": FormErrors,
        },
        data() {
            return {
                services_iptv_triniti: [],
                service_iptv: "",
                service_iptv_obj: {},
            }
        },
        mounted: function () {
            this.fetchUser()
        },
        methods: {
            fetchUser() {
                // tariff.department could be for all departments and equal to NULL
                if ((this.tariff.department || this.tariff.department === null) &&
                    this.tariff.subscriber_type &&
                    this.tariff.group &&
                    this.tariff.connection_type &&
                    this.tariff.charging_type) {

                    this.$delete(this.service_errors, 'service_iptv_triniti');
                    this.load_service_iptv_triniti();
                } else {
                    this.$set(this.service_errors, "service_iptv_triniti",
                        ["Для выбора услуги IPTV Trinity нужно указать 'Отдел', 'Тип абонента', 'Технология', 'Группа' и 'Тарификация'"])
                }
            },
            load_service_iptv_triniti() {
                var self = this;

                loader.active = false;
                API.get('/services/iptv-triniti/', {
                        group_ext: this.tariff.group,
                        department_ext: this.tariff.department,
                        subscriber_type_ext: this.tariff.subscriber_type,
                        connection_type_ext: this.tariff.connection_type,
                        charging_type: this.tariff.charging_type,
                    })
                    .then(function (response) {
                        self.services_iptv_triniti = response.data.results;
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                        loader.active = true;
                    })
            },
            emit_form() {
                this.$emit("set_form_data",
                    {"service_iptv_triniti": this.service_iptv, "service_iptv_triniti_expand": this.service_iptv_obj});
            },
        },
        watch: {
            service_iptv() {
                this.service_iptv_obj = {};
                for (var item of this.services_iptv_triniti) {
                    if (item.id === this.service_iptv) {
                        this.service_iptv_obj = item;
                    }
                }

                this.emit_form();
            }
        }
    }
</script>

<style scoped>

</style>