<template>
   <div v-show="settings.visible">
        <div class="modal-full">
            <div class="modal">
                <div class="modal__overlay" @click="popup_close"></div>
                <div class="modal__body">
                    <slot>
                        <span class="modal__close" @click="popup_close"></span>
                    </slot>
                    <div class="modal__header">
                        <div class="modal__title">
                            <slot name="title"></slot>
                        </div>
                    </div>
                    <div class="modal__content">
                        <slot name="content"></slot>
                    </div>
                    <div class="modal__footer">
                        <slot name="footer"></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'popup',
        props: ["settings"],
        methods: {
            popup_close() {
                this.settings.visible = false;
            }
        }
    }
</script>

<style scoped>

</style>