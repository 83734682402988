<template>
    <div class="password-generator">
       <input class="password-generator__input" :type="type" :placeholder="placeholder" :value="password" v-on:input="$emit('input', $event.target.value)" :disabled="disabled" />
       <span v-has-perm="'subscriber_connection_edit_username_password'" class="password-generator__btn" data-title="Сгенерировать пароль" @click="generate()"><i class="fal fa-sync"></i></span>
    </div>
</template>

<script>
    export default {
        name: "generation",
        props: {
            type: {
                type: String,
                default: 'text'
            },
            size: {
                type: String,
                default: '9'
            },
            characters: {
                type: String,
                default: '0-9'
            },
            placeholder: {
                type: String,
                default: 'Password'
            },
            disabled: {
                type: Boolean,
                default: true
            },
            auto: [String, Boolean],
            value: '',
        },
        data: function () {
            return {
                password: this.value
            }
        },
        // computed: {
        //     inputListeners: function () {
        //         var vm = this
        //         // `Object.assign` merges objects together to form a new object
        //         return Object.assign({},
        //             // We add all the listeners from the parent
        //             this.$listeners,
        //             // Then we can add custom listeners or override the
        //             // behavior of some listeners.
        //             {
        //                 // This ensures that the component works with v-model
        //                 input: function (event) {
        //                     vm.$emit('input', event.target.value)
        //                 }
        //             }
        //         )
        //     }
        // },
        mounted: function () {
            if (this.auto === 'true' || this.auto === 1) {
                this.generate(true);
            }
        },
        methods: {
            generate: function (mounted_hook=false) {
                if (!this.has_perm('subscriber_connection_edit_username_password') && mounted_hook === false) {
                    return
                }

                let charactersArray = this.characters.split(',');
                let CharacterSet = '';
                let password = '';

                if (charactersArray.indexOf('0-9') >= 0) {
                    CharacterSet += '0123456789';
                }

                for (let i = 0; i < this.size; i++) {
                    password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length));
                }
                this.password = password;
                this.$emit('input', this.password)
            }

        }
    };


</script>

<style scoped>

</style>