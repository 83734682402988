<template>
  <section class="page">
    <div class="page__inner">
      <div class="container-width">
        <!--HEADER PAGE-->
        <div class="page__header">
          <h1 class="page__title">Unicards</h1>
          <DropDown></DropDown>
        </div>
        <!--END HEADER PAGE-->
        <!--BODY PAGE-->
        <div class="page__body">
          <div class="table-box">
            <table class="table-box__table">
              <thead class="table-box__thead">
              <tr class="table-box__thead-tr">
                <!--Номер-->
                <th class="table-box__th">
                  <span class="table-box__thead-item table-box__thead-item_center">
                    <span class="table-box__thead-icon">#</span>
                    <span class="table-box__thead-title"></span>
                  </span>
                </th>
                <!--Дата создания-->
                <th class="table-box__th">
                  <span class="table-box__thead-item table-box__thead-item_center">
                    <span class="table-box__thead-icon"><i class="fal fa-calendar-alt"></i></span>
                    <span class="table-box__thead-title">Дата создания</span>
                  </span>
                </th>
                <!--Статус партии-->
                <th class="table-box__th" @click="set_ordering('status')">
                  <span class="table-box__thead-item table-box__thead-item_center">
                    <span class="table-box__thead-icon"><i class="fal fa-check"></i></span>
                    <span class="table-box__thead-title">Статус партии <i class="fas"
                                                                          :class="[get_class_ordering_direction('status')]"></i></span>
                  </span>
                </th>
                <!--Серия-->
                <th class="table-box__th" @click="set_ordering('series')">
                  <span class="table-box__thead-item table-box__thead-item_center">
                    <span class="table-box__thead-icon"><i class="fal fa-credit-card-blank"></i></span>
                    <span class="table-box__thead-title">Серия <i class="fas"
                                                                  :class="[get_class_ordering_direction('series')]"></i></span>
                  </span>
                </th>
                <!--Диапазон-->
                <th class="table-box__th">
                  <span class="table-box__thead-item table-box__thead-item_center">
                    <span class="table-box__thead-icon"><i class="fal fa-abacus"></i></span>
                    <span class="table-box__thead-title">Диапазон</span>
                  </span>
                </th>
                <!--Кол-во-->
                <th class="table-box__th">
                  <span class="table-box__thead-item table-box__thead-item_center">
                    <span class="table-box__thead-icon"><i class="fal fa-sigma"></i></span>
                    <span class="table-box__thead-title">Кол-во</span>
                  </span>
                </th>
                <!--Номинал-->
                <th class="table-box__th" @click="set_ordering('face_value')">
                  <span class="table-box__thead-item table-box__thead-item_center">
                    <span class="table-box__thead-icon"><i class="fal fa-money-bill-wave-alt"></i></span>
                    <span class="table-box__thead-title">Номинал <i class="fas"
                                                                    :class="[get_class_ordering_direction('face_value')]"></i></span>
                  </span>
                </th>
                <!--Действия-->
                <th class="table-box__th">
                  <span class="table-box__thead-item table-box__thead-item_center">
                    <span class="table-box__thead-icon"><i class="fal fa-mouse-pointer"></i></span>
                    <span class="table-box__thead-title">Срок действия</span>
                  </span>
                </th>
              </tr>
              </thead>
              <tbody class="table-box__tbody">
              <tr class="table-box__tr table-data" v-for="(unicard, index) in unicards_set">
                <!--Номер-->
                <td class="table-box__td table-box__td_center">
                  <span class="table-data__text">
                    {{ index + 1 }}
                  </span>
                </td>
                <!--Дата создания-->
                <td class="table-box__td table-box__td_center">
                  <span class="table-data__text">
                    {{ unicard.created_at | moment("DD.MM.YYYY HH:mm") }}
                  </span>
                </td>
                <!--Статус партии-->
                <td class="table-box__td table-box__td_center">
                  <span class="table-data__text">
                    {{ unicard.status_display }}
                  </span>
                </td>
                <!--Серия-->
                <td class="table-box__td table-box__td_center">
                  <span class="table-data__text">
                    {{ unicard.series }}
                  </span>
                </td>
                <!--Диапазон-->
                <td class="table-box__td table-box__td_center">
                  <span class="table-data__text">
                    {{ unicard.number_from_display }}-{{ unicard.number_to_display }}
                  </span>
                </td>
                <!--Кол-во-->
                <td class="table-box__td table-box__td_center">
                  <span class="table-data__text">
                    {{ unicard.count_unicards_expand }}
                  </span>
                </td>
                <!--Номинал-->
                <td class="table-box__td table-box__td_center">
                  <span class="table-data__text">
                    {{ unicard.face_value }}
                  </span>
                </td>
                <!--Действия-->
                <td class="table-box__td table-box__td_right">
                  <div class="btn-group">
                      <router-link v-tooltip="TooltipText='Список карт'" class="btn-l btn-l_blue"
                                   :to="{ name: 'unicards-list-cards', query:{unicard_set: unicard.id}}"
                                   v-if="!isBlockStatus(unicard)">
                        <span class="btn-l__icon"><i class="fal fa-stream"></i></span>
                      </router-link>

                    <!--Отправить в печать-->
                    <app-has-perm-action-btn :perms="{'permissions': 'unicards_user_access,unicards_super_access', 'comparison': 'OR'}"
                                                                 @action="printUnicards(unicard)">
                      <template slot-scope="{ access }">

                          <span v-tooltip="TooltipText='Отправить в печать'" class="btn-l" :class="access ? 'btn-l_orange' : 'btn-l_grey'"
                                v-if="isStatusCreated(unicard)">
                            <span class="btn-l__icon"><i class="fal fa-print"></i></span>
                          </span>

                      </template>
                    </app-has-perm-action-btn>

                    <!--Скачать-->
                    <app-has-perm-action-btn :perms="{'permissions': 'unicards_user_access,unicards_super_access', 'comparison': 'OR'}"
                                                                 @action="exportXls(unicard)">
                      <template slot-scope="{ access }">

                          <span v-tooltip="TooltipText='Скачать'" class="btn-l" :class="access ? 'btn-l_green' : 'btn-l_grey'"
                                v-if="!isBlockStatus(unicard)">
                            <span class="btn-l__icon"><i class="fal fa-file-excel"></i></span>
                          </span>

                      </template>
                    </app-has-perm-action-btn>

                    <!--Регенерация-->
                    <app-has-perm-action-btn :perms="{'permissions': 'unicards_user_access,unicards_super_access', 'comparison': 'OR'}"
                                                                 @action="tryRegenerateUnicardSet(unicard)">
                      <template slot-scope="{ access }">

                          <span v-tooltip="TooltipText='Перегенерировать'" class="btn-l" :class="access ? 'btn-l_green' : 'btn-l_grey'"
                                v-if="isStatusCreationError(unicard)">
                            <span class="btn-l__icon"><i class="fal fa-sync-alt"></i></span>
                          </span>

                      </template>
                    </app-has-perm-action-btn>

                    <!--Удалить пустую серию-->
                    <app-has-perm-action-btn :perms="{'permissions': 'unicards_user_access,unicards_super_access', 'comparison': 'OR'}"
                                                                 @action="deleteEmptyUnicardSet(unicard)">
                      <template slot-scope="{ access }">

                          <span v-tooltip="TooltipText='Удалить пустую серию'" class="btn-l" :class="access ? 'btn-l_red' : 'btn-l_grey'"
                                v-if="isStatusCreationError(unicard)">
                            <span class="btn-l__icon"><i class="fal fa-trash-alt"></i></span>
                          </span>

                      </template>
                    </app-has-perm-action-btn>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!--PAGINATION BLOCK-->
          <Pagination @loadItems="fetch"
                      :options="limitOptions"
                      :limit-items="limit"
                      :page-items="page"
                      :count-items="count"
                      :add-null-option="true"/>
          <!--END PAGINATION BLOCK-->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {User} from "../../../../mixins/admin/user";
import {UnicardsMixin} from "../../../../mixins/admin/unicards";
import {API} from "../../../../api";
import {PermissionMixin} from "../../../../mixins/admin/permission";
import DropDown from "./dropdown/dropdown";
import {RouteHelperMixin} from "../../../../mixins/admin/route_helper";
import {PaginationMixin} from "../../../../mixins/admin/pagination";
import {OrderMixin} from "../../../../mixins/admin/order";
import Pagination from "../pagination/pagination";


export default {
  name: "unicards-list",
  mixins: [User, UnicardsMixin, PermissionMixin, RouteHelperMixin, PaginationMixin, OrderMixin],
  data() {
    return {
      unicards_set: [],
      // options for ordering items
      ordering: "status",
      ordering_direction: 'ASC',
      // options for pagination block
      limitOptions: [15, 25, 50, 100],
      limit: 15,
      page: 1,
      count: 0,
      errors: {},
    }
  },
  created() {
    this.pagination_init(this.page, this.limit);

    this.set_data_from_route([
      {'name': 'ordering_direction', 'type': 'str'},
      {'name': 'ordering', 'type': 'str'}], this);
  },
  mounted() {
    this.fetch()
  },
  components: {
    Pagination,
    DropDown,
  },
  methods: {
    fetch() {
      var self = this;
        var params = Object.assign({}, self.$route.query,
            {
              page: !self.$route.query.page ? self.page : self.$route.query.page,
              limit: !self.$route.query.limit ? self.limit : self.$route.query.limit,
              offset: !self.$route.query.offset ? self.get_offset(self.page) : self.$route.query.offset,
            });

        // set ordering params to load ordered items
        params = self.setOrderingToParams(params);

        this.load_unicards_set(params)
            .then(function (response) {
              self.unicards_set = response.data.results;
              self.count = response.data.count;

              // update route ordering params
              self.updateRouteQueryOrderingParams();
            })
            .catch(function (error) {
              // API.default_catch(self, error)
            })
            .then(function () {
              // API.default_always(self);
            })

        // update route ordering params
        self.updateRouteQueryOrderingParams();
    },
    printUnicards(unicard) {
      let self = this;

      if(!self.isStatusCreated(unicard)) { return }

      API.post('/unicards/set/' + unicard.id + '/mark_printed/')
          .then(function (response) {
            self.fetch();
          })
    },
    deleteEmptyUnicardSet(unicardSet) {
      let self = this;

      if(!self.isStatusCreationError(unicardSet)) { return }

      self.loader_show();
      API.delete('/unicards/set/' + unicardSet.id + '/delete_empty_set/')
          .then(function (response) {
            self.fetch();
          })
          .catch(function (error) {
              API.default_catch(self, error)
              if (self.errors.non_field_errors) {
                    this.alert("Внимание", self.errors.non_field_errors[0]);
                }
          })
          .then(function () {
              API.default_always(self)
          })
    },
    tryRegenerateUnicardSet(unicardSet) {
      let self = this;

      if(!self.isStatusCreationError(unicardSet)) { return }

      self.loader_show();
      API.post('/unicards/set/' + unicardSet.id + '/regenerate/')
          .then(function (response) {
            self.fetch();
          })
          .catch(function (error) {
              API.default_catch(self, error)
              if (self.errors.non_field_errors) {
                    this.alert("Внимание", self.errors.non_field_errors[0]);
                }
          })
          .then(function () {
              API.default_always(self)
          })
    },
    exportXls(unicard) {
      var self = this;

      if(self.isBlockStatus(unicard)) { return }

      API.request('/unicards/set/' + unicard.id + '/export_xls/', {}, {}, 'POST', 'blob')
          .then(function (response) {
            const blob = new Blob([response.data], {type: response.data.type});
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            const contentDisposition = response.headers['content-disposition'];
            let fileName = "cards-" + unicard.series + "-" + unicard.number_from_display +
                "-" + unicard.number_to_display + ".xls";
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
          })
    },
    isStatusCreated(unicard) {
      return unicard.status === 'created';
    },
    isStatusInProgress(unicard) {
      return unicard.status === 'in_progress';
    },
    isStatusCreationError(unicard) {
      return unicard.status === 'creation_error';
    },   
    isBlockStatus(unicard) {
      let self = this;

      return self.isStatusCreationError(unicard) || self.isStatusInProgress(unicard);
    },
  },
  watch: {
    // watch multiple properties with single handler
    orderingAndOrderingName(value) {
      this.fetch();
    },
  }
}
</script>

<style scoped>


</style>