<template>
    <span>
        <span>
            {{ subscriber_connection.subscriber_expand.display_name }}
        </span>
<!--        <template v-if="isListPage">-->
<!--        </template>-->
<!--        <template v-else>-->
<!--            <span v-if="subscriber_connection.subscriber_expand.subscriber_type_expand">-->

<!--                <template v-if="check_subscriber_type(subscriber_connection.subscriber_expand, 'private')">-->
<!--                    {{ subscriber_connection.subscriber_expand.fio }}-->
<!--                </template>-->
<!--                <template v-if="check_subscriber_type(subscriber_connection.subscriber_expand, 'business')">-->
<!--                    <template v-if="subscriber_connection.subscriber_expand.subscriber_business_details">-->
<!--                        {{ subscriber_connection.subscriber_expand.subscriber_business_details.organizational_form }}-->
<!--                        {{ subscriber_connection.subscriber_expand.subscriber_business_details.company_name }}-->
<!--                    </template>-->
<!--                    <template v-else>-->
<!--                        {{ subscriber_connection.subscriber_expand.display_name }}-->
<!--                    </template>-->
<!--                </template>-->
<!--            </span>-->
<!--        </template>-->
    </span>
</template>

<script>
    import {SubscriberMixin} from "../../../../../mixins/admin/subscriber";

    export default {
        name: "Subscriber-name",
        props: ['subscriber_connection_input', 'subscriber_input', 'isListPage'],
        mixins: [SubscriberMixin],
        data(){
            return {
                subscriber: "",
                subscriber_connection: "",
            }
        },
        created(){
            if(this.subscriber_input) {
                this.subscriber_connection = {
                    subscriber_expand: this.subscriber_input
                }
            } else {
                this.subscriber_connection = this.subscriber_connection_input;
            }
        },
        watch: {
            subscriber_input(value){
                this.subscriber_connection = {
                    subscriber_expand: this.subscriber_input
                }
            },
            subscriber_connection_input(value){
                this.subscriber_connection = value;
            },
        }
    }
</script>

<style scoped>

</style>