<template>
    <div class="btn-group">
        <template v-for="item in items">
            <router-link :title="item.title" :to="{ name: item.name}" v-bind:class="[active_name === item.name ? 'active' : '']">
                <span class="btn" v-bind:class="[item.css]" >
                    <span class="btn__icon"><i class="fal" v-bind:class="[item.icon]"></i></span>
                    <span class="btn__title">{{ item.title }}</span>
                </span>
            </router-link>
        </template>
    </div>
</template>


<script>
    export default {
        name: "Report-menu",
        props: ['active_name'],
        data() {
            return {
                items: [
                    {
                        name: 'reports-page',
                        title: 'Отчеты',
                        css: ['btn_grey'],
                        icon: ['fa-analytics'],
                    },
                    {
                        name: 'reports-finance',
                        title: 'Финансы',
                        css: ['btn_green'],
                        icon: ['fa-hryvnia'],
                    },
                    {
                        name: 'reports-subscribers',
                        title: 'Абоненты',
                        css: ['btn_blue'],
                        icon: ['fa-users'],
                    },
                    {
                        name: 'reports-tariffs',
                        title: 'Тарифы',
                        css: ['btn_orange'],
                        icon: ['fa-briefcase'],
                    },
                    {
                        name: 'reports-departments',
                        title: 'Отделы',
                        css: ['btn_indigo'],
                        icon: ['fa-user-tie'],
                    },
                ]
            }
        }
    }
</script>

<style scoped>

</style>