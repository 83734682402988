<template>
    <popup :settings="settings">
        <div slot="title">Отмена с массовой смены тарифа</div>
        <div slot="content">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="modal__content">
                    <!--Information balance-->
                    <div class="modal-info">
                        <ul class="tariff">
                            <li class="tariff-label">Запланирована массовая смена тарифа 18-04-2019 с
                                <span class="tariff-name"><i class="fal fa-hryvnia"></i> Name tariff</span> на
                                <span class="tariff-name"><i class="fal fa-hryvnia"></i> Name tariff</span>
                            </li>
                            <li class="tariff-label">Вы действительно хотите отменить данную точку включения с массовой смены тарифа</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer">
            <a href="#" class="modal__btn">Отменить</a>
            <a href="#" @click="popup_close">Я передумал</a>
        </div>
    </popup>
</template>

<script>

    import Popup from '../modal/popup.vue'
    import {CrudMixin} from "../../../../mixins/admin/crud/crud_base";
    import FormErrors from '../form/errors.vue';

    export default {
        name: "cancel-mass-planing-tariff",
        props: ["settings", "crud"],
        mixins: [
            CrudMixin,
        ],
        components: {
            "popup": Popup,
            "form-error": FormErrors
        },
    }
</script>

<style scoped>

</style>