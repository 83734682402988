<template>
    <popup :settings="settings">
        <div slot="title">Добавление пакета IP-адрес</div>
        <div slot="content">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="popup-add">
                        <form-ip-address :initial="initial" @editing_form="editing_form" :errors="errors" ></form-ip-address>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer">
            <a href="#" @click="popup_close">Отмена</a>
            <a href="#" @click="save" class="modal__btn">Добавить</a>
        </div>
    </popup>
</template>

<script>
    import Popup from '../../modal/popup.vue';
    import {CrudMixin} from "../../../../../mixins/admin/crud/crud_base";
    import FormIpAddress from "./sub_components/form-ip-address";
    import {API} from "../../../../../api";

    export default {
        name: "add-ip-address",
        props: ["settings", "crud"],
        mixins: [CrudMixin],
        components: {
            FormIpAddress,
            "popup": Popup,
        },
        data(){
            return {
                initial: {
                    render: 1
                },
                form: {},
                errors: {}
            }
        },
        methods: {
            save(){
                var self = this;

                if (self.form.charging_type === 'month') {
                  delete self.form['charging_amount_day'];
                }

                API.post('/services/ip-address/', self.form)
                    .then(function (response) {
                        self.initial = {};
                        self.errors = {};
                        self.$emit("load_ip_address_service");
                        self.popup_close();
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            },
            editing_form(form){
                this.form = form;
            }
        }
    }
</script>

<style scoped>

</style>