<template>
    <span>
        <template v-if="has_perm(perms)">
            <span @click="$emit('action')" v-tooltip="TooltipText='Удалить в архив'">
                <slot :access="true">
                     <span class="btn-l red">
                        <span class="btn-l__icon"><i class="fal fa-trash-alt"></i></span>
                     </span>
                </slot>
            </span>
        </template>
        <template v-else>
            <slot :access="true">
                <span @click="permissions_error_show" v-tooltip="TooltipText='Удалить в архив'">
                    <span class="btn-l grey">
                        <span class="btn-l__icon"><i class="fal fa-trash-alt"></i></span>
                     </span>
                </span>
            </slot>
        </template>
    </span>
</template>

<script>
    export default {
        name: "app-remove-btn",
        props: ["perms", "title"]
    }
</script>
