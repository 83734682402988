<template>
    <popup :settings="settings">
        <div slot="title">Сбросить IP адрес</div>
        <div slot="content">
            <div class="body-size">
                <div class="body-size__480">
                    <div class="modal-inform modal-inform_red">
                        <div class="modal-inform__title">Сброс параметров услуги (IP адрес)</div>
                        <div class="modal-inform__desc">
                            Вы собираетесь сбросить параметры услуги <b>IP адрес</b> по-умолчанию. <br>
                            В случае согласия будет сброшен IP адресс.
                        </div>
                    </div>
                    <div class="modal-divide modal-divide_min">
                        <div class="modal-divide__title">
                            <span class="modal-divide__title-icon">
                                <i class="fal fa-database"></i>
                            </span>Данные для сброса
                        </div>
                    </div>
                    <div class="view-modal">
                        <div class="view-modal__item">
                            <span class="view-modal__title">
                                <span class="view-modal__icon"><i class="fal fa-briefcase"></i></span>
                                    Пакет:
                            </span>
                            <span class="view-modal__value">
                               {{ service_ip_address.service_name }}
                            </span>
                            <form-error v-bind:value="errors.ip_address_connection"></form-error>
                        </div>
                        <div class="view-modal__item">
                            <span class="view-modal__title">
                                <span class="view-modal__icon"><i class="fal fa-cog"></i></span>
                                       Характеристики:
                            </span>
                            <span class="view-modal__value view-modal__value_bold">
                                {{ service_ip_address.description }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="btn-group">
                <span class="btn btn_grey" @click="popup_close">
                    <span class="btn__icon"><i class="fal fa-times-circle"></i></span>
                    <span class="btn__title">Я передумал</span>
                </span>
                <span class="btn btn_red">
                    <span class="btn__icon"><i class="fal fa-check"></i></span>
                    <span class="btn__title" @click="save">Сбросить</span>
                </span>
            </div>
        </div>
    </popup>
</template>

<script>
    import Popup from '../../../../modal/popup.vue';
    import FormErrors from '../../../../form/errors.vue';
    import {CrudMixin} from "../../../../../../../mixins/admin/crud/crud_base";
    import {API} from "../../../../../../../api";

    export default {
        name: "ip-address-clear",
        props: ["settings", "service_ip_address"],
        components: {
            "popup": Popup,
            "form-error": FormErrors,
        },
        mixins: [
            CrudMixin,
        ],
        data() {
            return {
                errors: [],
            }
        },
        methods: {
            save() {
                this.clear_ip_address();
            },
            clear_ip_address() {
                var self = this;

                API.post('/services/ip-address-subscriber-connection-dropped/', {
                    ip_address_connection: self.service_ip_address.service_object_id,
                })
                    .then(function (response) {
                        self.$emit('update_subscriber_connection');
                        self.popup_close();
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            },
        },
    }
</script>

<style scoped>

</style>