<template>
    <div class="grid__box" v-has-perm="'subscribers_access'">
        <div class="view">
            <div class="view__row">
                <div class="view__section">
                    <!--Оборуование-->
                    <div class="view__header view__header_orange">
                        <div class="view__header-title">
                            <span class="view__header-icon"><i class="fal fa-cogs"></i></span>
                            <span class="view__header-text">Технические данные</span>
                        </div>
                    </div>
                    <!--NAS-->
                    <div class="view__item">
                        <div class="view__label">
                            <span class="view__label-icon"><i class="fal fa-server"></i> </span>
                            <span class="view__label-title">NAS:</span>
                        </div>
                        <div class="view__value">
                                <span class="view__value-text">
                                    <template
                                            v-if="Object.keys(subscriber_connection).length && subscriber_connection.nas_expand">
                                        {{ subscriber_connection.nas_expand.name }}
                                    </template>
                                    <template v-else>
                                        <i>Не указано</i>
                                    </template>
                                </span>
                        </div>
                    </div>
                    <!--Технология-->
                    <div class="view__item">
                        <div class="view__label">
                            <span class="view__label-icon"><i class="fal fa-ethernet"></i></span>
                            <span class="view__label-title">Технология:</span>
                        </div>
                        <div class="view__value">
                                <span class="view__value-text">
                                    <template
                                            v-if="Object.keys(subscriber_connection).length && subscriber_connection.connection_type_expand">
                                        {{ subscriber_connection.connection_type_expand.label }}
                                    </template>
                                    <template v-else>
                                        <i>Не указано</i>
                                    </template>
                                </span>
                        </div>
                    </div>
                    <!--Производитель-->
                    <div class="view__item">
                        <div class="view__label">
                            <span class="view__label-icon"><i class="fal fa-trademark"></i></span>
                            <span class="view__label-title">Производитель:</span>
                        </div>
                        <div class="view__value">
                                <span class="view__value-text">
                                    {{ subscriber_connection.device_manufacturer }}
                                </span>
                        </div>
                    </div>
                    <!--Модель-->
                    <div class="view__item">
                        <div class="view__label">
                            <span class="view__label-icon"><i class="fal fa-barcode"></i></span>
                            <span class="view__label-title">Модель:</span>
                        </div>
                        <div class="view__value">
                                <span class="view__value-text">
                                    {{ subscriber_connection.device_model }}
                                </span>
                        </div>
                    </div>
                    <!--MAC-->
                    <div class="view__item">
                        <div class="view__label">
                            <span class="view__label-icon"><i class="fal fa-phone-laptop"></i></span>
                            <span class="view__label-title">MAC:</span>
                        </div>
                        <div class="view__value">
                                <span class="view__value-text">
                                     {{ subscriber_connection.device_mac }}
                                </span>
                        </div>
                    </div>
                    <!--PORT-->
                    <div class="view__item">
                        <div class="view__label">
                            <span class="view__label-icon"><i class="fal fa-brackets"></i></span>
                            <span class="view__label-title">Порт:</span>
                        </div>
                        <div class="view__value">
                                <span class="view__value-text">
                                    {{ subscriber_connection.device_port }}
                                </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "system-block",
        props: ['subscriber_connection'],
        data: function () {
            return {
                password_visible: false
            }
        },
        methods: {
            set_mode_edit: function () {
                this.$emit('set_mode', 'edit')
            },
            set_password_visible: function (value) {
                this.password_visible = value;
            }
        }
    }
</script>

<style scoped>

</style>