<template>
    <div class="page-body">
        <form action="" class="add-data" >
            <fieldset class="add-data__group" v-has-perm="'subscribers_add_edit'">
                <div class="add-data__header">
                    <div class="row">
                        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                            <h3><i class="fal fa-id-badge"></i> Персональные данные</h3>
                        </div>
                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                <span class="action-link">
                                    <a href="#save" @click="save"><i class="fal fa-save"></i> Сохранить</a>
                                </span>
                        </div>
                    </div>
                </div>
                <div class="add-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                            <label><i class="fal fa-users-crown"></i> Тип абонента: <span class="control"><i
                                    class="fas fa-star-of-life"></i></span></label>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">
                            <template v-if="subscriber.subscriber_type_expand">
                            {{ subscriber.subscriber_type_expand.name_expand }}
                            </template>
                        </div>
                    </div>
                </div>

                <!--PRIVATE COMMON DATA EDIT-->
                <div v-if="is_subscriber_private()">

                    <div class="add-data__item">
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                                <label><i class="fal fa-user-crown"></i> ФИО: <span class="control"><i
                                        class="fas fa-star-of-life"></i></span></label>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">
                                <input type="text" v-model="subscriber.fio" placeholder="Фамилия имя отчество">
                            </div>
                            <div class="clearfix"></div>
                            <form-error v-bind:value="errors.fio"></form-error>
                        </div>
                    </div>
                    <phone-list
                            :phone="subscriber.phone"
                            :phone_1="subscriber.phone_1"
                            :phone_2="subscriber.phone_2"
                            :phone_3="subscriber.phone_3"
                            :phone_4="subscriber.phone_4"
                            :phone_5="subscriber.phone_5"
                            :errors="errors"
                            @set_phone_single="set_phone_single"
                            @set_phones_list="set_phones_list">
                    </phone-list>

                    <div class="add-data__item">
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                                <label><i class="fal fa-at"></i> E-mail:</label>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">
                                <input type="text" v-model="subscriber.subscriber_email" placeholder="Введите e-mail">
                                <form-error v-bind:value="errors.subscriber_email"></form-error>
                            </div>
                        </div>
                    </div>
                </div>
                <!--END PRIVATE COMMON DATA EDIT-->

                <!--BUSINESS EDIT DATA -->
                <business-fields v-if="is_subscriber_business()"
                                 :subscriber="subscriber"
                                 :errors="errors"
                                 @update_subscriber="update_subscriber" />
                <!--END BUSINESS EDIT DATA -->

            </fieldset>

            <!--PRIVATE PASSPORT DETAILS EDIT DATA -->
            <fieldset v-if="has_perm('subscriber_passport_details_edit') && is_subscriber_private()"
                      class="add-data__group">

                <private-passport-data :subscriber="subscriber"
                                       :errors="errors"
                                       @update_subscriber="update_subscriber" />

            </fieldset>
            <!--END PRIVATE PASSPORT DETAILS EDIT DATA -->

            <fieldset class="add-data__group">
                <div class="add-data__footer">
                    <div class="btn grey" @click="set_mode_view" >Отмена</div>
                    <span class="btn blue" @click="save"><i class="fal fa-save"></i> Сохранить</span>
                </div>
            </fieldset>
        </form>
    </div>

</template>

<script>
    import FormErrors from '../form/errors.vue';
    import {SubscriberMixin} from "../../../../mixins/admin/subscriber";
    import {API} from "../../../../api";
    import {SubscriberTypeMixin} from "../../../../mixins/admin/subscriber_type";
    import BusinessFields from "./sub_components/business_fields";
    import PrivatePassportData from "./sub_components/private_passport_data";
    import PhoneList from '../phones/list';

    export default {
        name: "subscribers-edit",
        props: ['subscriber_source'],
        data(){
            return {}
        },
        created: function () {
            this.load_subscriber_types();
        },
        mixins: [
            SubscriberMixin,
            SubscriberTypeMixin
        ],
        components: {
            PrivatePassportData,
            BusinessFields,
            "form-error": FormErrors,
            "phone-list": PhoneList
        },
        mounted(){
            this.subscriber = Object.assign({}, this.subscriber_source);

            if("user_passportdetails" in this.subscriber) {
                delete this.subscriber.user_passportdetails.user;
            }
            delete this.subscriber.username;
            delete this.subscriber.first_name;
            delete this.subscriber.last_name;
        },
        methods: {
            save: function () {
                var self = this;

                if (!self.has_perm('subscriber_passport_details_edit')) {
                    self.subscriber['user_passportdetails'] = undefined;
                }
                // Add Subscriber Connection
                this.edit_subscriber(this.subscriber.id, this.subscriber)
                    .then(function (response) {
                        self.set_mode_view()
                    })
                    .catch(function (error) {
                        self.subscriber_catch(self, error);
                    })
                    .then(function () {
                        API.default_always(self);
                    });
            },
            update_subscriber: function (value) {
                this.subscriber = value;
            },
            set_mode_view: function () {
                this.$emit('update_subscriber');
                this.$emit('set_mode', 'view')
            },
            set_phone_single: function (value) {
                this.subscriber.phone = value;
            },
            set_phones_list: function (phones) {
                for(var i=0;i <5; i++){
                    if((phones[i] !== "") && (phones[i] !== undefined)){
                        this.subscriber['phone_' + (i+1)] = phones[i];
                    } else {
                        this.subscriber['phone_' + (i+1)] = "";
                    }
                }
            },
        },
        watch: {
            'subscriber.user_passportdetails.private_subscriber_passport_details_file_expand': function (value) {
                this.subscriber.user_passportdetails['private_subscriber_passport_details_file'] = value;
            },
            'subscriber.user_passportdetails.private_subscriber_additional_details_file_expand': function (value) {
                this.subscriber.user_passportdetails['private_subscriber_additional_details_file'] = value;
            },
            'subscriber.subscriber_business_details.business_details_agreement_file_expand': function (value) {
                this.subscriber.subscriber_business_details['business_details_agreement_file'] = value;
            },
            'subscriber.subscriber_business_details.business_details_statutory_document_expand': function (value) {
                this.subscriber.subscriber_business_details['business_details_statutory_document'] = value;
            },
        }
    }
</script>

<style scoped>

</style>