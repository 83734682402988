<template>
    <transition name="loader">
        <div class="preloader">
            <div class="preloader__svg"></div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "loader-view"
    }
</script>

<style scoped>

</style>