<template>

    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" >
        <template v-if="has_perm('employees_view_passport_details')">
            <div class="add-data__header">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <h3><i class="fal fa-address-card"></i> Паспортные данные</h3>
                    </div>
                </div>
            </div>
            <div class="add-data__item">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                        <label><i class="fal fa-globe"></i> Гражданство: <span
                                class="control"><i
                                class="fas fa-star-of-life"></i></span></label>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">
                        <input type="text" v-model="passport.citizenship" placeholder="Гражданство" :disabled="!has_perm('employees_edit_passport_details')">
                    </div>
                    <div class="clearfix"></div>
                    <form-error v-bind:value="passport_errors.citizenship"></form-error>
                </div>
            </div>
            <div class="add-data__item">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                        <label><i class="fal fa-male"></i> Пол: <span
                                class="control"><i
                                class="fas fa-star-of-life"></i></span></label>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">
                        <label class="radio">
                            <input type="radio" v-model="passport.gender" v-bind:value="'M'" required :disabled="!has_perm('employees_edit_passport_details')"/>
                            <div class="radio__text">Мужчина</div>
                        </label>
                        <label class="radio">
                            <input type="radio" v-model="passport.gender" v-bind:value="'F'" required :disabled="!has_perm('employees_edit_passport_details')"/>
                            <div class="radio__text">Женщина</div>
                        </label>
                    </div>
                </div>
            </div>
            <div class="add-data__item">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                        <label><i class="fal fa-birthday-cake"></i> Дата рождения: <span
                                class="control"><i
                                class="fas fa-star-of-life"></i></span></label>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">
                        <!--<input type="text" v-model="passport.date_of_birth" placeholder="Дата рождения">-->
                        <date-picker v-model="date_of_birth" :lang="'ru'" :format="'DD-MM-YYYY'" :disabled="!has_perm('employees_edit_passport_details')"></date-picker>
                    </div>
                    <div class="clearfix"></div>
                    <form-error v-bind:value="passport_errors.date_of_birth"></form-error>
                </div>
            </div>
            <div class="add-data__item">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                        <label><i class="fal fa-flag"></i> Страна:<span
                                class="control"><i
                                class="fas fa-star-of-life"></i></span></label>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">
                        <input type="text" v-model="passport.country" placeholder="Страна" :disabled="!has_perm('employees_edit_passport_details')">
                    </div>
                    <div class="clearfix"></div>
                    <form-error v-bind:value="passport_errors.country"></form-error>
                </div>
            </div>
            <div class="add-data__item">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                        <label><i class="fal fa-map-marked"></i> Область: <span
                                class="control"><i
                                class="fas fa-star-of-life"></i></span></label>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">
                        <input type="text" v-model="passport.region" placeholder="Область" :disabled="!has_perm('employees_edit_passport_details')">
                    </div>
                    <div class="clearfix"></div>
                    <form-error v-bind:value="passport_errors.region"></form-error>
                </div>
            </div>
            <div class="add-data__item">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                        <label><i class="fal fa-location-circle"></i> Район: <span
                                class="control"><i
                                class="fas fa-star-of-life"></i></span></label>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">
                        <input type="text" v-model="passport.district" placeholder="Район" :disabled="!has_perm('employees_edit_passport_details')">
                    </div>
                    <div class="clearfix"></div>
                    <form-error v-bind:value="passport_errors.district"></form-error>
                </div>
            </div>
            <div class="add-data__item">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                        <label><i class="fal fa-city"></i> Населенный пункт: <span
                                class="control"><i
                                class="fas fa-star-of-life"></i></span></label>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">
                        <input type="text" v-model="passport.settlement" placeholder="Населенный пункт" :disabled="!has_perm('employees_edit_passport_details')">
                    </div>
                    <div class="clearfix"></div>
                    <form-error v-bind:value="passport_errors.settlement"></form-error>
                </div>
            </div>
            <div class="add-data__item">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                        <label><i class="fal fa-road"></i> Улица: <span
                                class="control"><i
                                class="fas fa-star-of-life"></i></span></label>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">
                        <input type="text" v-model="passport.street" placeholder="Улица" :disabled="!has_perm('employees_edit_passport_details')">
                    </div>
                    <div class="clearfix"></div>
                    <form-error v-bind:value="passport_errors.street"></form-error>
                </div>
            </div>
            <div class="add-data__item">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                        <label><i class="fal fa-home"></i> Дом: <span
                                class="control"><i
                                class="fas fa-star-of-life"></i></span></label>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">
                        <input type="text" v-model="passport.house_number" placeholder="Дом" :disabled="!has_perm('employees_edit_passport_details')">
                    </div>
                    <div class="clearfix"></div>
                    <form-error v-bind:value="passport_errors.house_number"></form-error>
                </div>
            </div>
            <div class="add-data__item">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                        <label><i class="fal fa-door-closed"></i>Квартира:</label>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">
                        <input type="text" v-model="passport.apartment_number" placeholder="Квартира" :disabled="!has_perm('employees_edit_passport_details')">
                        <form-error v-bind:value="passport_errors.apartment_number"></form-error>
                    </div>
                </div>
            </div>
            <div class="add-data__item">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                        <label><i class="fal fa-passport"></i> Серия и номер
                            паспорта: <span
                                    class="control"><i
                                    class="fas fa-star-of-life"></i></span></label>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">
                        <input type="text" v-model="passport.passport_series_number" placeholder="Серия и номер паспорта" :disabled="!has_perm('employees_edit_passport_details')">
                    </div>
                    <div class="clearfix"></div>
                    <form-error v-bind:value="passport_errors.passport_series_number"></form-error>
                </div>
            </div>
            <div class="add-data__item">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                        <label><i class="fal fa-passport"></i> Дата выдачи паспорта: <span
                                class="control"><i
                                class="fas fa-star-of-life"></i></span></label>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">
                        <!--<input type="text" v-model="passport.date_of_issue" placeholder="Дата выдачи паспорта">-->
                        <date-picker v-model="date_of_issue" :lang="'ru'" :format="'DD-MM-YYYY'" :disabled="!has_perm('employees_edit_passport_details')"></date-picker>
                    </div>
                    <div class="clearfix"></div>
                    <form-error v-bind:value="passport_errors.date_of_issue"></form-error>
                </div>
            </div>
            <div class="add-data__item">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                        <label><i class="fal fa-passport"></i> Кем выдан паспорт: <span
                                class="control"><i
                                class="fas fa-star-of-life"></i></span></label>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">
                        <input type="text" v-model="passport.issued_by" placeholder="Кем выдан паспорт" :disabled="!has_perm('employees_edit_passport_details')">
                    </div>
                    <div class="clearfix"></div>
                    <form-error v-bind:value="passport_errors.issued_by"></form-error>
                </div>
            </div>
            <div class="add-data__item">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                        <label><i class="fal fa-passport"></i> ИНН: <span
                                class="control"><i
                                class="fas fa-star-of-life"></i></span></label>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">
                        <input type="text" v-model="passport.identification_number" placeholder="ИНН" :disabled="!has_perm('employees_edit_passport_details')">
                    </div>
                    <div class="clearfix"></div>
                    <form-error v-bind:value="passport_errors.identification_number"></form-error>
                </div>
            </div>

            <file-multiupload :title="'Скан договоров'"
                              :files_input="passport.user_passport_details_file"
                              :errors="passport_errors.user_passport_details_file"
                              :readonly="!has_perm('employees_edit_passport_details')"
                              @set_files="set_docs" >
            </file-multiupload>
        </template>
    </div>

</template>

<script>
    import FormErrors from '../../form/errors';
    import DatePicker from 'vue2-datepicker';
    import FileMultiupload from '../../form/file-multiupload.vue';

    export default {
        name: "passportdetails-item",
        props: ["passportdetails", "errors"],
        data() {
            return {
                date_of_birth: this.$moment(this.passportdetails.date_of_birth).format("YYYY-MM-DD"),
                date_of_issue: this.$moment(this.passportdetails.date_of_issue).format("YYYY-MM-DD"),
                passport: Object.assign({}, this.passportdetails),
                passport_errors: {}
            }
        },
        components: {
            "form-error": FormErrors,
            "date-picker": DatePicker,
            "file-multiupload": FileMultiupload
        },
        methods: {
            set_docs(value) {
                this.passport.docs = value;
                this.$emit("set_passportdetails", this.passport);
            },
            // change_date_of_issue(value){
            //   this.passport.date_of_issue = this.$moment(value).format("YYYY-MM-DD");
            // }
        },
        watch: {
            passportdetails(value) {
                if(value !== null) {
                    this.passport = value;

                    this.passport.user_passport_details_file = this.passport.user_passport_details_file_expand;
                    this.date_of_birth = this.passport.date_of_birth;
                    this.date_of_issue = this.passport.date_of_issue;
                }
            },
            date_of_birth(value) {
                this.passport.date_of_birth = this.$moment(value).format("YYYY-MM-DD");
            },
            date_of_issue(value) {
                this.passport.date_of_issue = this.$moment(value).format("YYYY-MM-DD");
            },
            passport: {
                handler(value) {
                    this.$emit("set_passportdetails", value);
                },
                deep: true
            },
            errors: {
                handler(value) {
                    if (value && value !== undefined) {
                        this.passport_errors = value[0];
                    } else {
                        this.passport_errors = {}
                    }
                },
                deep: true
            }
        }
    }
</script>

<style scoped>

</style>