<template>
    <td class="table-subscribers__td table-subscribers__td_center" v-has-perm="'subscribers_access'">
        <router-link
                :to="{ name: 'subscribers-connections-item', params:{id: subscriber_connection.id }}"
                class="table-data__login">
            {{ subscriber_connection.username }}
        </router-link>
    </td>
</template>

<script>
    export default {
        name: "td_username",
        props: ["subscriber_connection"],
    }
</script>

<style scoped>

</style>