export var FilterHelperMixin = {
    methods: {
        resetFilters() {
            let query = Object.assign({}, this.$route.query);

            delete query.department;
            delete query.subscriber_type;
            delete query.connection_type;
            delete query.group;
            delete query.employee_department;
            delete query.agent;
            delete query.status;
            delete query.shop;
            delete query.owner;
            delete query.value;
            delete query.series;

            this['department'] = undefined;
            this['subscriber_type'] = undefined;
            this['connection_type'] = undefined;
            this['group'] = undefined;
            this['employee_department'] = undefined;
            this['agent'] = undefined;
            this['unicard_status'] = undefined;
            this['status'] = undefined;
            this['shop'] = undefined;
            this['owner'] = undefined;
            this['value'] = undefined;
            this['series'] = undefined;
            this['shop_param'] = undefined;
            this['destination'] = undefined;

            this.$router.replace({query});
        }
    }
}