import {API} from "../../api";
import {loader} from "../../models/loader";

export var ServiceMixin = {
    data: function () {
        return {
            nas: [],
            nas_item: {},
        }
    },
    methods: {
        load_services() {
            var self = this;
            loader.active = false;
            API.get('/services/all/')
                .then(function (response) {
                    self.services = response.data;
                })
                .catch(function (error) {
                    API.default_catch(self, error)
                })
                .then(function () {
                    API.default_always(self);
                    loader.active = true;
                })
        },
    }
};