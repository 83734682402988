<template>
    <section class="page-404">
        <h1>404</h1>
    </section>
</template>

<script>
    export default {
        name: 'hello',
    }
</script>

<style scoped>
    .page-404 {
        height: 100vh;
        width: 100%;
    }

    h1 {
        padding: 10% 0 0 0;
        text-align: center;
        font-size: 72px;
        vertical-align: middle;
        font-weight: 700;
    }
</style>