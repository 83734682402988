<template>
    <span>
        <span class="permissions__checked" v-bind:class="[access ? 'permissions__checked_active' : 'permissions__checked_deactive']">
            <i class="far" v-bind:class="[access ? 'fa-check': 'fa-times']"></i>
        </span>
        <span class="permissions__checkbox-title">{{ title }}</span>
    </span>
</template>

<script>
    export default {
        name: "employee-permission-readonly",
        props: ['access', 'title']
    }
</script>
