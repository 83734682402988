<template>
    <section class="page" v-has-perm="'subscribers_access'">
        <div class="page__inner">
            <div class="container-width">
                <!--HEADER PAGE-->
                <div class="page__header">
                    <h1 class="page__title"><subscriber-name :subscriber_input="subscriber"></subscriber-name></h1>
                    <div class="btn-group">

                        <app-has-perm-action-btn :perms="'subscriber_connection_send_notif_sms'">
                             <template slot-scope="{ access }">

                                 <span class="btn-l" :class="access ? 'btn-l_orange' : 'btn-l_grey'" >
                                    <span class="btn-l__icon"><i class="fal fa-sms"></i></span>
                                </span>

                             </template>
                        </app-has-perm-action-btn>

                        <app-has-perm-action-btn :perms="'subscriber_connection_send_notif_email'">
                             <template slot-scope="{ access }">

                                 <span class="btn-l" :class="access ? 'btn-l_blue' : 'btn-l_grey'" >
                                    <span class="btn-l__icon"><i class="fal fa-at"></i></span>
                                </span>

                             </template>
                        </app-has-perm-action-btn>

                        <Information :subscriber="subscriber" ></Information>
                    </div>
                </div>
                <!--END HEADER PAGE-->
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                        <template v-if="mode === 'view'">
                            <SubscribersView :subscriber_source="subscriber" v-on:set_mode="set_mode">
                            </SubscribersView>
                        </template>
                        <template v-else>
                            <SubscribersEdit :subscriber_source="subscriber" v-on:set_mode="set_mode"
                                             v-on:update_subscriber="update_subscriber"></SubscribersEdit>
                        </template>
                    </div>
                    <!-- Item points connection -->
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6" v-if="Object.keys(subscriber).length">
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <template v-for="subscriber_connection in subscriber.connections_expand">
                                    <SubscribersItemConnection
                                            :subscriber_connection="subscriber_connection"></SubscribersItemConnection>
                                </template>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" v-has-perm="'subscribers_add_edit'">
                                <router-link title="Добавить точку включения" class="add-service"
                                             :to="{ name: 'subscribers-connections-add', params: {id: subscriber.id}}">
                                    <i class="fal fa-plus-circle"></i>Добавить точку включения
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <!-- Components comments -->
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <NoteList :subscriber="subscriber" ></NoteList>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    // Components
    import SubscribersItemConnection from './item-connection.vue';
    import {SubscriberMixin} from "../../../../mixins/admin/subscriber";
    import SubscriberName from "../subscriber-connections/sub_components/subscriber_name";

    import SubscribersView from './view.vue';
    import SubscribersEdit from './edit.vue';
    import NoteList from '../note/list'
    import Information from './information';
    import {User} from "../../../../mixins/admin/user";

    export default {
        name: "subscriber-item",
        data: function () {
            return {
                id: "",
                subscriber: {},
                mode: "view",
            }
        },
        mixins: [User, SubscriberMixin],
        components: {
            SubscribersItemConnection,
            SubscribersView,
            SubscribersEdit,
            NoteList,
            Information,
            'subscriber-name': SubscriberName
        },
        mounted: function () {
            this.fetchUser()
        },
        methods: {
            fetchUser() {
                this.id = this.$route.params.id;
                this.load_subscriber(this.id)
            },
            set_mode: function (mode) {
                // Edit or View mode
                this.mode = mode;
            },
            update_subscriber: function () {
                this.load_subscriber(this.id);
            }
        },
        watch: {
            '$route' (route_to, route_from) {
                this.fetchUser();
            }
        }
    }
</script>

<style scoped>

</style>