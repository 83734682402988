import {API} from "../../api";

export var EmployeePermissionsInfoMixin = {
    data: function () {
        return {
            employee_permissions_info: [],
        }
    },
    methods: {
        load_employee_permissions_info: function (obj) {
            var self = this;
            API.get('/employee-permissions-info/')
                .then(function (response) {
                    self.employee_permissions_info = response.data;
                })
                .catch(function (error) {
                    // API.default_catch(self, error)
                })
                .then(function () {
                    // API.default_always(self);
                })
        }
    }
};
