<template>
    <section class="page">
        <div class="page__inner">
            <div class="container-width">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="page-header">
                            <div class="row">
                                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <h1 class="page-header__title" v-if="unicard.unicard_set_expand">Карта {{ unicard.unicard_set_expand.series }}-{{ unicard.number_display }}</h1>
                                </div>
                                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <DropDown></DropDown>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- CONTENT PAGE -->
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="page-body">
                            <div class="table-container">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Дата и время</th>
                                        <th>Пользователь</th>
                                        <th>Операция</th>
                                        <th>IP</th>
                                        <th>Информация о браузере</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(unicards_operation, index) in unicards_operations">
                                        <td>{{ index + 1 }}</td>
                                        <td>{{ unicards_operation.date | moment("DD/MM/YYYY hh:mm")}}</td>
                                        <td>
                                            <template v-if="unicards_operation.user_expand">
                                                {{ unicards_operation.user_expand.fio }}
                                            </template>
                                            <template v-if="unicards_operation.user_system_expand">
                                                {{ unicards_operation.user_system_expand }}
                                            </template>
                                        </td>
                                        <td>{{ unicards_operation.operation_note }}
                                            <span v-if="unicards_operation.type === 'used'">
                                                (Пополнил <router-link :to="{ name: 'subscribers-connections-item', params:{id: unicards_operation.used_in_subscriber_connection_id}}">
                                                            {{ unicards_operation.used_in_subscriber_connection_username }}
                                                          </router-link>)
                                            </span>
                                        </td>
                                        <td>{{ unicards_operation.from_ip }}</td>
                                        <td>{{ unicards_operation.browser_info }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import DropDown from "./dropdown/dropdown";
    import {User} from "../../../../mixins/admin/user";
    import {UnicardsMixin} from "../../../../mixins/admin/unicards";
    import {PermissionMixin} from "../../../../mixins/admin/permission";
    import {SearchMixin} from "../../../../mixins/admin/search";

    export default {
        name: "card-info",
        mixins: [User, UnicardsMixin, SearchMixin, PermissionMixin],
        components: {
             DropDown
        },
        data() {
            return {
                unicard: {},
                unicards_operations: []
            }
        },
        created: function () {
            this.$root.$on('search_hit_unicards', (function(data){
                this.fetchUser();
            }).bind(this));

            this.$root.$on('search_clear_unicards', (function(data){
                this.fetchUser();
            }).bind(this));

        },
        beforeDestroy: function () {
            this.destroy_search_listeners("unicards");
        },
        mounted() {
            this.fetchUser()
        },
        methods: {
            fetchUser() {
                    var unicard_id = this.$route.params.id;
                    this.unicards_operations = [];
                    this.get_unicard(unicard_id);

                    var filter = {};
                    filter['unicard'] = unicard_id;
                    this.load_unicards_operations(filter);
            },
        }
    }
</script>

<style scoped>

</style>