import {API} from "../../api";
import {loader} from "../../models/loader";

export var UnicardsMixin = {
    methods: {
        load_unicards_set: function (filter) {
            // filter = filter || {};
            // filter['limit'] = 1000;
            // this.unicards_set = [];
            // API.get_recursive('/unicards/set/', filter, this.unicards_set);
            return API.get('/unicards/set/', filter);
        },
        load_unicards: function(filter){
            // filter = filter || {};
            // filter['limit'] = 1000;
            // this.unicards = [];
            // API.get_recursive('/unicards/unicard/', filter, this.unicards);
            return API.get('/unicards/unicard/', filter);
        },
        load_unicards_batch: function (filter) {
            // filter = filter || {};
            // filter['limit'] = 1000;
            // this.unicards_batch = [];
            // API.get_recursive('/unicards/batch/', filter, this.unicards_batch);
            return API.get('/unicards/batch/', filter);
        },
        load_unicards_shop_batch: function (filter) {
            // filter = filter || {};
            // filter['limit'] = 1000;
            // this.shop_batch = [];
            return API.get('/unicards/shop-batch/', filter);
        },
        load_unicard_statuses(filter){
            // filter = filter || {};
            // filter['limit'] = 1000;
            // this.unicard_statuses = [];
            // API.get_recursive('/unicards/statuses/', filter, this.unicard_statuses);
            return API.get('/unicards/statuses/', filter);

        },
        load_unicard_statuses_batch(filter){
            filter = filter || {};
            filter['limit'] = 1000;
            this.unicard_batch_statuses = [];
            API.get_recursive('/unicards/statuses-batch/', filter, this.unicard_batch_statuses);
        },
        load_unicards_operations(filter){
            filter = filter || {};
            filter['limit'] = 1000;
            this.unicards_operations = [];
            API.get_recursive('/unicards/operations/', filter, this.unicards_operations);
        },
        get_unicard(id) {
            var self = this;
            loader.active = false;
            API.get('/unicards/unicard/'+id+"/")
                .then(function (response) {
                    loader.active = true;
                    self.unicard = response.data;
                })
        },
        get_unicard_set(id) {
            var self = this;
            loader.active = false;
            API.get('/unicards/set/'+id+"/")
                .then(function (response) {
                    loader.active = true;
                    self.unicard_set = response.data;
                })
        },
        get_unicard_batch(id) {
            var self = this;
            loader.active = false;
            API.get('/unicards/batch/' + id + "/")
                .then(function (response) {
                    loader.active = true;
                    self.unicard_batch = response.data;
                })
        },
        get_unicard_shop_batch(id) {
            var self = this;
            loader.active = false;
            API.get('/unicards/shop-batch/' + id + "/")
                .then(function (response) {
                    loader.active = true;
                    self.shop_batch = response.data;
                })
        },
        get_unicard_intervals(id){
            var url = '', self = this;

            if(this.is_main_type()){
                url = '/unicards/batch/'+id+'/with-details/';
            } else if(this.is_set_type()){
                url = '/unicards/set/'+id+'/with-details/';
            }else {
                url = '/unicards/shop-batch/'+id+'/with-details/';
            }

            API.get(url).then(function (response) {
                self.unicards_intervals = response.data.unicards_intervals;
            })
        },
        getStatusUnicards(unicard){
            var status = "";
            if(unicard.status === 'active'){
                status = "Активна";
            }else if(unicard.status === 'created'){
                status = "Создана";
            }else if(unicard.status === 'printed') {
                status = "Активна";
            }else if(unicard.status === 'blocked'){
                status = "Заблокирована";
            }else if(unicard.status === 'returned'){
                status = "Возращена";
            }else if(unicard.status === 'used'){
                status = "Использована";
            } else {
                status = unicard.status;
            }
            return status;
        },
        isBlocked(item){
            return item.status === 'blocked';
        },
        isReturned(item){
            return item.status === 'returned';
        },
        canReturn(item){
            return !this.isReturned(item) && !this.isBlocked(item)
        },
        pad(value, size, symbol) {
            size = size || 5;
            symbol = symbol || "0";
            value = String(value);
            while (value.length < (size || 2)) {value = symbol + value;}
            return value;
        },
        is_set_type(){
            return this.type === 'set';
        },
        is_main_type(){
            return this.type === 'main';
        },
        is_shop_type(){
            return this.type === 'shop';
        },
        get_series(item){
            if(this.is_main_type()) {
                if(item.unicard_set_expand) {
                    return item.unicard_set_expand.series;
                }
            }else{
                if(item.unicard_batch_expand) {
                    return item.unicard_batch_expand.unicard_set_expand.series;
                }
            }
        },
        get_owner(item){
            if(this.is_main_type()) {
                if(item.owner_expand) {
                    return item.owner_expand.fio;
                }
            }else{
                if(item.shop_expand) {
                    return item.shop_expand.name;
                }
            }
        },
        get_face_value(item){
            if(this.is_main_type()) {
                if(item.unicard_set_expand) {
                    return item.unicard_set_expand.face_value;
                }
            }else{
                if(item.unicard_batch_expand) {
                    return item.unicard_batch_expand.unicard_set_expand.face_value;
                }
            }
        },
        load_agents(filter) {
            var self = this;
            API.get('/unicards/report-agents/', filter).then(function (response) {
                self.agents = response.data.results;
            });
        },
        load_agent_amount(filter){
            var self = this;
            API.get('/unicards/report-agent-amount/', filter).then(function (response) {
                self.agent_amount = response.data.results;
            });
        },
        load_available_for_shop(){
            var self = this;
            API.get("/unicards/batch/available-for-shop/").then(function (response) {
                self.available_batch_series = response.data.results;
            });
        },
        load_available_for_batch(){
            var self = this;
            API.get("/unicards/set/available-for-batch/").then(function (response) {
                self.available_series = response.data.results;
            });
        }
    }
};
