<template>
  <div class="dashboard-main__item dashboard-main__item_nh">
    <div class="dashboard-main__head">
      <div class="dashboard-main__title">{{ blockItem.title }}</div>
      <div class="dashboard-main__filters" v-if="blockItem.options">
        <div class="dashboard-main__filters-select">
          <select required>
            <option v-for="(option, index) in blockItem.options"
                    :key="index"
                    :value="option.value">{{ option.key }}</option>
          </select>
        </div>
      </div>
    </div>
    <ul class="dashboard-list">
      <li class="dashboard-list__item" v-for="(item, index) in items" :key="index">
        <span class="dashboard-list__label">
          <span class="dashboard-list__label-icon"><i :class="blockItem.iconClass"></i></span>
          <span class="dashboard-list__label-title">{{ item.fio }}</span>
        </span>
        <span class="dashboard-list__value">
          <span class="dashboard-list__value-title">Кол-во подключений:</span>
          <p class="dashboard-list__value-count">{{ item.count_connections }}</p>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import {LinksMixin} from "../../../../../mixins/admin/links";
import {DashboardStatisticsMixin} from "../../../../../mixins/admin/DashboardStatistics";

export default {
  name: "dashboard-top-item",
  props: {
    blockItem: {
      type: Object,
      default: {
        title: '',
        iconClass: '',
        options: null,
        method: ''
      }
    }
  },
  mixins: [LinksMixin, DashboardStatisticsMixin],
  data() {
    return {
      items: [],
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData(){
      if (this.blockItem.method === 'getTopInstallerEmployees') {
        this.getTopInstallerEmployees();
      } else if (this.blockItem.method === 'getTopTechnicalSupportEmployees') {
        this.getTopTechnicalSupportEmployees();
      }
    }
  },
  watch: {
    'blockItem.method': {
      handler(value) {
        this.loadData();
      },
      deep: true
  }
  },
}
</script>

<style scoped>

</style>