<template>
  <section class="page">
    <div class="page__inner">
      <div class="container-width">
        <!--HEADER PAGE-->
        <div class="page__header" v-if="unicard_set">
          <h1 v-if="type === ''" class="page__title">Список карт</h1>
          <h1 v-if="isTypeSet()" class="page__title">Список партии {{ unicard_set.series }}</h1>
          <h1 v-if="isTypeMain() && unicard_batch.unicard_set_expand" class="page__title">Основная партия
            {{ unicard_batch.unicard_set_expand.series }}</h1>
          <h1 v-if="isTypeShop() && shop_batch.unicard_batch_expand" class="page__title">Магазинная партия
            {{ shop_batch.unicard_batch_expand.unicard_set_expand.series }}</h1>
          <DropDown></DropDown>
        </div>
        <!--END HEADER PAGE-->
        <!-- BODY PAGE -->
        <div class="page__body">
          <div class="page__head">
            <!--FILTER BLOCK PAGE-->
            <FilterBlock @resetComponentFilters="resetComponentFilters">
              <filter-by-field v-model="unicard_status"
                               title="Статус"
                               view="label"
                               :objects="unicard_statuses"
                               :add-social-tariff="false"
                               :is-choices-filter="true"/>
              <filter-by-field v-model="shop"
                               title="Магазин"
                               view="name"
                               :objects="shops"
                               :add-social-tariff="false"/>
              <filter-by-field v-model="agent"
                               title="Сотрудника"
                               view="fio"
                               :objects="agents"
                               :add-social-tariff="false"/>
            </FilterBlock>
            <!--END FILTER BLOCK PAGE-->
            <div class="page__head-right">
              <div class="btn-group">

                      <app-has-perm-action-btn :perms="{'permissions': 'unicards_user_access,unicards_super_access', 'comparison': 'OR'}"
                                                                 @action="bulk_update_block_unblock('blocked')">
                        <template slot-scope="{ access }">

                          <span class="btn-l" :class="access ? 'btn-l_red' : 'btn-l_grey'" v-tooltip="TooltipText='Блокировать'">
                            <span class="btn-l__icon"><i class="fal fa-lock"></i></span>
                          </span>

                        </template>

                       </app-has-perm-action-btn>


                      <app-has-perm-action-btn :perms="{'permissions': 'unicards_user_access,unicards_super_access', 'comparison': 'OR'}"
                                                                 @action="bulk_update_block_unblock('printed')">
                        <template slot-scope="{ access }">

                          <span class="btn-l" :class="access ? 'btn-l_green' : 'btn-l_grey'" v-tooltip="TooltipText='Блокировать'">
                              <span class="btn-l__icon"><i class="fal fa-unlock"></i></span>
                          </span>

                        </template>

                       </app-has-perm-action-btn>


                      <app-has-perm-action-btn :perms="{'permissions': 'unicards_user_access,unicards_super_access', 'comparison': 'OR'}"
                                                                 @action="bulk_update_return('printed')">
                        <template slot-scope="{ access }">

                          <span class="btn-l" :class="access ? 'btn-l_orange' : 'btn-l_grey'" v-tooltip="TooltipText='Блокировать'">
                            <span class="btn-l__icon"><i class="fal fa-undo"></i></span>
                          </span>

                        </template>

                       </app-has-perm-action-btn>

              </div>
            </div>
          </div>
          <!--TODO: Для компонента "Основные партии и магазинные партии" -->
          <template v-if="isTypeMain() || isTypeShop()">
            <InfoList :unicard_batch="unicard_batch" :shop_batch="shop_batch"></InfoList>
          </template>
          <div class="table-box">
            <table class="table-box__table">
              <thead class="table-box__thead">
              <tr class="table-box__thead-tr">
                <!--Выделить все-->
                <th class="table-box__th table-box__th_checked">
                  <label class="table-data__checkbox">
                    <input type="checkbox" v-model="select_all" id="select_all">
                    <span class="table-data__checkmark"></span>
                  </label>
                </th>
                <!--Номер-->
                <th class="table-box__th">
                  <span class="table-box__thead-item table-box__thead-item_center">
                    <span class="table-box__thead-icon"></span>
                    <span class="table-box__thead-title">#</span>
                  </span>
                </th>
                <!--Создан/Обновлен-->
                <th class="table-box__th">
                  <span class="table-box__thead-item table-box__thead-item_center">
                    <span class="table-box__thead-icon"></span>
                    <span class="table-box__thead-title">Создан/Обновлен</span>
                  </span>
                </th>
                <!--Серия-->
                <th class="table-box__th">
                  <span class="table-box__thead-item table-box__thead-item_center">
                    <span class="table-box__thead-icon"></span>
                    <span class="table-box__thead-title">Серия</span>
                  </span>
                </th>
                <!--Номер-->
                <th class="table-box__th">
                  <span class="table-box__thead-item table-box__thead-item_center">
                    <span class="table-box__thead-icon"></span>
                    <span class="table-box__thead-title">Номер</span>
                  </span>
                </th>
                <!--Действительно-->
                <th class="table-box__th">
                  <span class="table-box__thead-item table-box__thead-item_center">
                    <span class="table-box__thead-icon"></span>
                    <span class="table-box__thead-title">Действительно</span>
                  </span>
                </th>
                <!--Владелец-->
                <th class="table-box__th">
                  <span class="table-box__thead-item table-box__thead-item_center">
                    <span class="table-box__thead-icon"></span>
                    <span class="table-box__thead-title">Владелец</span>
                  </span>
                </th>
                <!--Получатель-->
                <th class="table-box__th" v-if="isTypeSet() || isTypeMain()">
                  <span class="table-box__thead-item table-box__thead-item_center">
                    <span class="table-box__thead-icon"></span>
                    <span class="table-box__thead-title">Получатель</span>
                  </span>
                </th>
                <!--Магазин-->
                <th class="table-box__th" v-if="isTypeSet() || isTypeShop()">
                  <span class="table-box__thead-item table-box__thead-item_center">
                    <span class="table-box__thead-icon"></span>
                    <span class="table-box__thead-title">Магазин</span>
                  </span>
                </th>
                <!--Статус-->
                <th class="table-box__th">
                  <span class="table-box__thead-item table-box__thead-item_center">
                    <span class="table-box__thead-icon"></span>
                    <span class="table-box__thead-title">Статус</span>
                  </span>
                </th>
                <!--Действия-->
                <th class="table-box__th">
                  <span class="table-box__thead-item table-box__thead-item_center">
                    <span class="table-box__thead-icon"></span>
                    <span class="table-box__thead-title">Действия</span>
                  </span>
                </th>
              </tr>
              </thead>
              <tbody class="table-box__tbody">
              <tr v-for="(unicard, index) in unicards">
                <!--Выделить все-->
                <td class="table-box__td table-box__td_center table-box__td_checked">
                  <label class="table-data__checkbox">
                    <input type="checkbox" v-model="record_checked" v-bind:value="unicard.id">
                    <span class="table-data__checkmark"></span>
                  </label>
                </td>
                <!--Номер-->
                <td class="table-box__td table-box__td_center">
                  <span class="table-data__text">
                    {{ index + 1 }}
                  </span>
                </td>
                <!--Создан/Обновлен-->
                <td class="table-box__td table-box__td_center">
                  <span class="table-data__text">
                    <field-created-modify :obj="unicard"></field-created-modify>
                  </span>
                </td>
                <!--Серия-->
                <td class="table-box__td table-box__td_center">
                  <span class="table-data__text">
                    <template v-if="unicard.unicard_set_expand">{{ unicard.unicard_set_expand.series }}</template>
                  </span>
                </td>
                <!--Номер-->
                <td class="table-box__td table-box__td_center">
                  <span class="table-data__text">
                    {{ unicard.number_display }}
                  </span>
                </td>
                <!--Действительно-->
                <td class="table-box__td table-box__td_center">
                  <span class="table-data__text">
                    {{ unicard.unicard_set_expand.expiration_date | moment("DD/MM/YYYY") }}
                  </span>
                </td>
                <!--Владелец-->
                <td class="table-box__td table-box__td_center">
                  <span class="table-data__text">
                    <field-owner-unicard-set :unicard="unicard"></field-owner-unicard-set>
                  </span>
                </td>
                <!--Получатель-->
                <td class="table-box__td table-box__td_center" v-if="isTypeSet() || isTypeMain()">
                  <span class="table-data__text">
                    <field-owner-unicard-batch :unicard="unicard"></field-owner-unicard-batch>
                  </span>
                </td>
                <!--Магазин-->
                <td class="table-box__td table-box__td_center" v-if="isTypeSet() || isTypeShop()">
                  <span class="table-data__text">
                     <field-owner-unicard-shop-batch :unicard="unicard"></field-owner-unicard-shop-batch>
                  </span>
                </td>
                <!--Статус-->
                <td class="table-box__td table-box__td_center">
                  <span class="table-data__text">
                     {{ unicard.status_display }}
                      <span v-if="unicard.status === 'used' && unicard.used_in_subscriber_connection_id">
                        (Пополнил <router-link
                          :to="{ name: 'subscribers-connections-item', params:{id: unicard.used_in_subscriber_connection_id}}">
                        {{ unicard.used_in_subscriber_connection_username }}
                        </router-link>)
                      </span>
                  </span>
                </td>
                <td class="table-box__td table-box__td_center">
                  <div class="btn-group">
                    <router-link v-tooltip="TooltipText='Информация'" class="btn-l btn-l_blue"
                                 :to="{ name: 'unicards-cards-info', params:{id: unicard.id}}">
                      <span class="btn-l__icon"><i class="fal fa-info"></i></span>
                    </router-link>
                    <template v-if="isTypeShop() || isTypeMain()">

                      <app-has-perm-action-btn :perms="{'permissions': 'unicards_user_access,unicards_super_access', 'comparison': 'OR'}"
                                                                 @action="popup_view(unicard)">
                        <template slot-scope="{ access }">

                          <span v-tooltip="TooltipText='Возврат'" class="btn-l" :class="access ? 'btn-l_orange' : 'btn-l_grey'"
                                v-if="canReturn(unicard)">
                            <span class="btn-l__icon"><i class="fal fa-undo"></i></span>
                          </span>

                        </template>
                      </app-has-perm-action-btn>


                       <app-has-perm-action-btn :perms="{'permissions': 'unicards_user_access,unicards_super_access', 'comparison': 'OR'}"
                                                                 @action="block_unblock(unicard, index, 'blocked')">
                        <template slot-scope="{ access }">

                          <span v-tooltip="TooltipText='Блокировать'" class="btn-l" :class="access ? 'btn-l_red' : 'btn-l_grey'" v-if="!isBlocked(unicard)">
                                <span class="btn-l__icon"><i class="fal fa-lock"></i></span>
                          </span>

                        </template>

                       </app-has-perm-action-btn>


                      <app-has-perm-action-btn :perms="{'permissions': 'unicards_user_access,unicards_super_access', 'comparison': 'OR'}"
                                                                 @action="block_unblock(unicard, index, 'printed')">
                        <template slot-scope="{ access }">

                          <span v-tooltip="TooltipText='Разблокировать'" class="btn-l" :class="access ? 'btn-l_green' : 'btn-l_grey'" v-if="isBlocked(unicard)">
                                  <span class="btn-l__icon"><i class="fal fa-lock-open-alt"></i></span>
                          </span>

                        </template>

                       </app-has-perm-action-btn>
                      
                    </template>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!--PAGINATION BLOCK-->
          <Pagination @loadItems="fetchUser"
                      :options="limitOptions"
                      :limit-items="limit"
                      :page-items="page"
                      :count-items="count"
                      :add-null-option="true"/>
          <!--END PAGINATION BLOCK-->
        </div>
        <!-- END BODY PAGE -->
      </div>
    </div>
    <Return :settings="return_popup" :type="type" :item="item" @update_list="fetchUser()"></Return>
  </section>
</template>


<script>
import {UnicardsMixin} from "../../../../mixins/admin/unicards";
import {PermissionMixin} from "../../../../mixins/admin/permission";
import FieldCreatedModify from "./sub_components/field_created_modify";
import DropDown from "./dropdown/dropdown";
import Return from "./return";
import InfoList from "./info";
import {API} from "../../../../api";
import FieldOwnerUnicardBatch from "./sub_components/field_owner_unicard_batch";
import FieldOwnerUnicardShopBatch from "./sub_components/field_owner_unicard_shop_batch";
import FieldOwnerUnicardSet from "./sub_components/field_owner_unicard_set";
import {SearchMixin} from "../../../../mixins/admin/search";
import {RouteHelperMixin} from "../../../../mixins/admin/route_helper";
import {PaginationMixin} from "../../../../mixins/admin/pagination";
import {FilterHelperMixin} from "../../../../mixins/admin/filter_helper";
import FilterBlock from "../filter-block/filter-block";
import Filter from "../filter-block/sub_components/filter";
import Pagination from "../pagination/pagination";

export default {
  name: "list-cards",
  mixins: [UnicardsMixin, PermissionMixin, SearchMixin, RouteHelperMixin, PaginationMixin, FilterHelperMixin],
  components: {
    FilterBlock,
    "filter-by-field": Filter,
    Pagination,
    FieldOwnerUnicardBatch,
    FieldOwnerUnicardShopBatch,
    FieldOwnerUnicardSet,
    FieldCreatedModify,
    DropDown,
    InfoList,
    Return
  },
  data() {
    return {
      type: 'set',
      item: {},
      unicard_set: {},
      unicard_set_id: undefined,
      unicard_batch: {},
      shop_batch: {},
      unicards: [],
      record_checked: [],
      select_all: false,
      return_popup: {
        visible: false
      },
      // options for pagination block
      limitOptions: [15, 25, 50, 100],
      limit: 15,
      page: 1,
      count: 0,
      // filter options for department filter
      unicard_status: undefined,
      unicard_statuses: [],
      shop: undefined,
      shops: [],
      agent: undefined,
      agents: []
    }
  },
  created: function () {
    this.pagination_init(this.page, this.limit);

    this.unicard_set_id = this.$route.query.unicard_set;

    for (var name of ['unicards', 'unicard_set', 'unicard_batch', 'unicard_shop_batch']) {
      this.$root.$on('search_hit_' + name, (function (data) {
        this.fetchUser();
      }).bind(this));

      this.$root.$on('search_clear_' + name, (function (data) {
        this.fetchUser();
      }).bind(this));
    }
  },
  beforeDestroy: function () {
    this.destroy_search_listeners("unicards");
    this.destroy_search_listeners("unicard_set");
    this.destroy_search_listeners("unicard_batch");
    this.destroy_search_listeners("unicard_shop_batch");
  },
  mounted() {
    this.loadFilterOptions();
    this.fetchUser();
  },
  methods: {
    loadFilterOptions() {
      this.loadUnicardStatuses();

      if (this.unicard_set_id) {
        this.loadCurrentUnicardSetAgents();
        this.loadCurrentUnicardSetShops();
      }
    },
    loadCurrentUnicardSetShops() {
      var self = this;
      API.get('/unicards/set/' + self.unicard_set_id + '/current-unicard-set-shops/')
          .then(responce => {
            self.shops = responce.data;
          })
          .catch(error => {
            API.default_catch(self, error)
          })
          .then(function () {
            API.default_always(self);
          })
    },
    loadCurrentUnicardSetAgents() {
      var self = this;
      API.get('/unicards/set/' + self.unicard_set_id + '/current-unicard-set-agents/')
          .then(responce => {
            self.agents = responce.data;
          })
          .catch(error => {
            API.default_catch(self, error)
          })
          .then(function () {
            API.default_always(self);
          })
    },
    fetchUser() {
        var self = this;

        var filter = Object.assign({}, self.$route.query,
            {
              page: !self.$route.query.page ? self.page : self.$route.query.page,
              limit: !self.$route.query.limit ? self.limit : self.$route.query.limit,
              offset: !self.$route.query.offset ? self.get_offset(self.page) : self.$route.query.offset,
            });

        var filter_fields = [
          'unicard_set',
          'unicard_batch',
          'unicard_shop_batch',
          'unicard_shop_batch__shop',
          'date_used__date_lte',
          'date_used__date_gte',
          'used_with_shop_agent_id',
          'sold_in_department',
          'used_in_department'
        ];

        this.setType();

        for (var field of filter_fields) {
          if (field in this.$route.query) {
            filter[field] = this.$route.query[field];
          }
        }
        self.$set(filter, 'status', self.unicard_status);
        self.$set(filter, 'agent', self.agent);
        self.$set(filter, 'shop', self.shop);

        if (this.isTypeMain()) {
          this.get_unicard_batch(filter['unicard_batch'])
        } else if (this.isTypeShop()) {
          this.get_unicard_shop_batch(filter['unicard_shop_batch'])
        } else if (this.isTypeSet()) {
          this.get_unicard_set(filter['unicard_set']);
        }

        this.loader_show();
        this.load_unicards(filter)
            .then(function (response) {
              self.unicards = response.data.results;
              self.count = response.data.count;

              // update status filter
              self.updateRouterQuery('status', self.unicard_statuses, self.unicard_status);
              self.updateRouterQuery('agent', self.agents, self.agent);
              self.updateRouterQuery('shop', self.shops, self.shop);
            })
            .catch(function (error) {
              API.default_catch(self, error)
            })
            .then(function () {
              API.default_always(self);
            })
    },
    loadUnicardStatuses() {
      var self = this;
      this.load_unicard_statuses()
          .then(function (response) {
            self.unicard_statuses = response.data.results;
          })
          .catch(function (error) {
            API.default_catch(self, error)
          })
          .then(function () {
            API.default_always(self);
          })

    },
    popup_view(item) {
      this.item = Object.assign({}, item);
      this.return_popup.visible = true;
    },
    setType() {
      this.type = '';
      if ('unicard_set' in this.$route.query) {
        this.type = 'set';
      }
      if ('unicard_batch' in this.$route.query) {
        this.type = 'main';
      }
      if ('unicard_shop_batch' in this.$route.query) {
        this.type = 'shop';
      }
    },
    isTypeSet() {
      return this.type === 'set';
    },
    isTypeMain() {
      return this.type === 'main';
    },
    isTypeShop() {
      return this.type === 'shop';
    },
    block_unblock(item, index, status) {
      var self = this;
      API.post("/unicards/unicard/" + item.id + "/block-unblock/", {status: status})
          .then(function (response) {
            self.$set(self.unicards, index, response.data);
          })
          .catch(function (error) {
            API.default_catch(self, error);
            if ('non_field_errors' in self.errors) {
              self.alert("Внимание", self.errors['non_field_errors'][0]);
            }
          })
    },
    bulk_update_block_unblock(status) {
      var self = this;
      if (this.record_checked.length > 0) {
        var data = {
          ids: this.record_checked,
          status: status
        };

        API.patch("/unicards/unicard/block-unblock-bulk/", data).then(function (response) {
          self.fetchUser();
          self.record_checked = [];
          self.select_all = false;
        }).catch(function (error) {
          API.default_catch(self, error);
          if ('non_field_errors' in self.errors) {
            self.alert("Внимание", self.errors['non_field_errors'][0]);
          }
        });
      } else {
        this.alert("Блокировка/Разблокировка", "Не выбраны unicards")
      }
    },
    bulk_update_return() {
      var self = this;
      if (this.record_checked.length > 0) {
        var data = {
          ids: this.record_checked,
          status: status
        };

        API.patch("/unicards/unicard/return-bulk/", data).then(function (response) {
          self.fetchUser();
          self.record_checked = [];
          self.select_all = false;
        }).catch(function (error) {
          API.default_catch(self, error);
          if ('non_field_errors' in self.errors) {
            self.alert("Внимание", self.errors['non_field_errors'][0]);
          }
        });
      } else {
        this.alert("Блокировка/Разблокировка", "Не выбраны unicards")
      }
    },
    resetComponentFilters() {
      this.resetFilters();
      this.fetchUser();
    },
  },
  watch: {
    select_all: function (val) {
      this.record_checked = [];
      if (val) {
        for (var unicard of this.unicards) {
          this.record_checked.push(unicard.id);
        }
      }
    },
    unicard_status(value) {
      this.fetchUser();
    },
    agent(value) {
      if (value && value.length > 0) {
        this.fetchUser();
      }
    },
    shop(value) {
      if (value && value.length > 0) {
        this.fetchUser();
      }
    }
  }
}
</script>

<style scoped>

</style>