<template>
    <div class="grid__box" v-has-perm="'subscribers_access'">
        <div class="view">
            <div class="view__row">
                <div class="view__section">
                    <div class="view__header">
                        <div class="view__header-title">
                            <span class="view__header-icon"><i class="fal fa-map-marker-alt"></i></span>
                            <span class="view__header-text">Адрес подключения</span>
                        </div>
                    </div>
                    <!--Область-->
                    <div class="view__item">
                        <div class="view__label">
                            <span class="view__label-icon"><i class="fal fa-globe-europe"></i></span>
                            <span class="view__label-title">Область:</span>
                        </div>
                        <div class="view__value">
                            <span class="view__value-text">
                                <template
                                    v-if="Object.keys(subscriber_connection).length && subscriber_connection.settlement_expand && subscriber_connection.settlement_expand.district_expand.region_expand">
                                    {{ subscriber_connection.settlement_expand.district_expand.region_expand.name }}
                                </template>
                                <template v-else>
                                    Не указано
                                </template>
                            </span>
                        </div>
                    </div>
                    <!--Район-->
                    <div class="view__item">
                        <div class="view__label">
                            <span class="view__label-icon"><i class="fal fa-map-marked-alt"></i></span>
                            <span class="view__label-title">Район:</span>
                        </div>
                        <div class="view__value">
                            <span class="view__value-text">
                                <template
                                v-if="Object.keys(subscriber_connection).length && subscriber_connection.settlement_expand && subscriber_connection.settlement_expand.district_expand">
                                    {{ subscriber_connection.settlement_expand.district_expand.name }}
                                </template>
                                <template v-else>
                                    Не указано
                                </template>
                            </span>
                        </div>
                    </div>
                    <!--Населенный пункт-->
                    <div class="view__item">
                        <div class="view__label">
                            <span class="view__label-icon"><i class="fal fa-map-marker-alt"></i></span>
                            <span class="view__label-title">Населенный пункт:</span>
                        </div>
                        <div class="view__value">
                            <span class="view__value-text">
                                <template
                                v-if="Object.keys(subscriber_connection).length && subscriber_connection.settlement_expand">
                                    {{ subscriber_connection.settlement_expand.name }}
                                </template>
                                <template v-else>
                                    Не указано
                                </template>
                            </span>
                        </div>
                    </div>
                    <!--Улица-->
                    <div class="view__item">
                        <div class="view__label">
                            <span class="view__label-icon"><i class="fal fa-map-marker-alt"></i></span>
                            <span class="view__label-title">Улица:</span>
                        </div>
                        <div class="view__value">
                            <span class="view__value-text">
                                 <template
                                v-if="Object.keys(subscriber_connection).length && subscriber_connection.street_expand">
                                    {{ subscriber_connection.street_expand.name }}
                                </template>
                                <template v-else>
                                    <i>Не задано</i>
                                </template>
                            </span>
                        </div>
                    </div>
                    <!--Дом-->
                    <div class="view__item">
                        <div class="view__label">
                            <span class="view__label-icon"><i class="fal fa-home"></i></span>
                            <span class="view__label-title">Дом:</span>
                        </div>
                        <div class="view__value">
                            <span class="view__value-text">
                                 {{ subscriber_connection.house_number }}
                            </span>
                        </div>
                    </div>
                    <!--Квартира-->
                    <div class="view__item">
                        <div class="view__label">
                            <span class="view__label-icon"><i class="fal fa-building"></i></span>
                            <span class="view__label-title">Квартира:</span>
                        </div>
                        <div class="view__value">
                            <span class="view__value-text">
                                 {{ subscriber_connection.apartment_number }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "address-block",
        props: ['subscriber_connection'],
    }
</script>

<style scoped>

</style>