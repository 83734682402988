<template>
    <div class="search">
        <form action="#" class="form">
            <i class="fal fa-search"></i>
            <input type="search"
                   autocomplete="off"
                   placeholder="Найдётся все..."
                   v-bind:class="[loading ? 'loading' : '']"
                   v-model="query"
                   @click="update"
                   @keydown.down="down"
                   @keydown.up="up"
                   @keydown.enter.prevent="hit"
                   @keydown.esc="reset"
                   @input="update">

        </form>
        <div class="search__btn">
            <span class="btn-filter" v-if="!showSubAccordion" @click="showSubAccordion = !showSubAccordion"><i
                    class="fal fa-sliders-h"></i></span>
            <span class="btn-filter active" v-else @click="showSubAccordion = !showSubAccordion"><i
                    class="fal fa-sliders-h"></i></span>
        </div>

        <!--SEARCH RESULT-->
        <div class="search__result" >
            <ul class="search__result-list">
                <li v-for="(item, $item) in items" :class="activeClass($item)" @mousedown="hit" @mousemove="setActive($item)">
                    <a href="#">Найдено <b>{{ item.count }}</b> <span class="tag">в разделе "{{ item.object_name }}"</span></a>
                </li>
            </ul>
        </div>

        <!--SEARCH FILTER-->
        <transition name="accordion" v-if="showSubAccordion">
            <SearchFilter @update_checked_filters="update_checked_filters" :checked_filters="checked_filters"></SearchFilter>
        </transition>
    </div>
</template>

<script>
    import SearchFilter from "./filter";
    import Accordion from '../accordion/accordion.vue';
    import VueTypeahead from 'vue-typeahead'
    import {API} from "../../../../api";
    import { util } from 'vue';
    import {loader} from "../../../../models/loader";
    import _ from 'lodash'
    var SearchDelay=1500;


    export default {
        name: "search",
        mixins: [VueTypeahead],
        components: {
            SearchFilter,
            "accordion": Accordion,
        },
        data: function () {
            return {
                showSubAccordion: false,
                src: API.get_url("/search/"),
                limit: 5,
                minChars: 2,
                selectFirst: false,
                queryParamName: 'q',
                errors: {},
                checked_filters: [],
            }
        },
        created() {
            this.$root.$on('search_clear', (function(data){
                this.reset();
                // Update router
                this.$router.replace({
                    query: Object.assign({}, this.$route.query, {q: this.query,
                        object_type: this.checked_filters.join()}),
                });
            }).bind(this));

            if('q' in this.$route.query) {
                // this if needed for get_subscribers_by_session_mac_address() function
                if ('type' in this.$route.query) {
                    this.query = this.$route.query;
                } else {
                    this.query = this.$route.query.q;
                }
            }

            if('object_type' in this.$route.query) {
                this.checked_filters = this.$route.query.object_type.split(',');
            }
        },
        methods: {
            prepareResponseData (data) {
              loader.active = true;
              return data
            },
            hit: function hit() {
                if (this.current !== -1) {
                    this.onHit(this.items[this.current]);
                }else {
                    this.onSearch();
                }
            },
            onSearch: function () {
                this.update()
            },
            update: _.debounce(function () {
                this.$super(VueTypeahead).update();
            }, SearchDelay),
            fetch () {
              loader.active = false;
              const src = this.queryParamName
                ? this.src
                : this.src + this.query;

              const params = this.queryParamName
                ? Object.assign({ [this.queryParamName]: this.query, ['object_type']: this.checked_filters.join() }, this.data)
                : this.data;

              let cancel = new Promise((resolve) => this.cancel = resolve);
              let request = this.$http.get(src, { params, headers: {
                    'Authorization': "JWT " + this.$session.get('access_token')
              }});

              return Promise.race([cancel, request])
            },
            onHit(obj){
                var route_name = "", params = {};
                var query = {q: this.query, object_type: this.checked_filters.join()};
                if(obj.object) {
                    params = {id: obj.object.id};
                }

                if(obj.object_type === 'subscribers') {
                    route_name = obj.object ? "subscribers-item" : "subscribers-list";

                }else if(obj.object_type === 'subscriber-connections'){
                    route_name = obj.object ? "subscribers-connections-item" : "subscribers-list";

                }else if(obj.object_type === 'subscribers_by_session_mac_address'){
                    route_name = obj.object ? "subscribers-item" : "subscribers-list";
                    query["type"] = 'subscribers_by_session_mac_address';

                } else if(obj.object_type === 'employees'){
                    route_name = obj.object ? "employees-edit" : "employees-list";

                }else if(obj.object_type === 'groups'){
                    route_name = obj.object ? "groups-list" : "groups-list";

                }else if(obj.object_type === 'departments'){
                    route_name = obj.object ? "departments-list" : "departments-list";

                }else if(obj.object_type === 'nas'){
                    route_name = obj.object ? "nas-edit" : "nas-list";

                }else if(obj.object_type === 'connection_type') {
                    route_name = obj.object ? "connection_types-list" : "connection_types-list";

                }else if(obj.object_type === 'tariffs'){
                    route_name = obj.object ? "tariffs-edit" : "tariffs-list";

                }else if(obj.object_type === 'unicards'){
                    route_name = obj.object ? "unicards-cards-info" : "unicards-list-cards";

                }else if(obj.object_type === 'unicard_set'){
                    route_name = obj.object ? "unicards-list-cards" : "unicards-list";

                }else if(obj.object_type === 'unicard_batch'){
                    route_name = obj.object ? "unicards-list-cards" : "unicards-consignment-main";
                    if(obj.object) {
                        query['unicard_batch'] = obj.object.id;
                    }
                }else if(obj.object_type === 'unicard_shop_batch'){
                    route_name = obj.object ? "unicards-list-cards" : "unicards-consignment-shop";
                    if(obj.object) {
                        query['unicard_shop_batch'] = obj.object.id;
                    }
                }

                if(obj.object) {
                    this.$router.push({name: route_name, params: params, query: query});
                } else {
                    // console.log(route_name);
                    // console.log(query);
                    this.$router.push({name: route_name, query: query});
                }
                // Hide results
                this.items = [];
                // console.log("search_hit_" + obj.object_type);
                this.$root.$emit("search_hit_" + obj.object_type, event);
            },
            update_checked_filters(object_types) {
                this.checked_filters = object_types;
            }
        },
        watch: {
            '$route.params.search': {
                handler: function() {
                    if(!("q" in this.$route.query)) {
                        this.query = "";
                    }
                    if(!("object_type" in this.$route.query)) {
                        this.checked_filters = [];
                    }
                },
                deep: true
            },
            '$route.path': {
                handler: function() {
                    this.items = [];
                },
                deep: true
            },
        }
    }
</script>

<style scoped>

</style>