<template>
    <span class="table-data__text">
        <span v-if="item_service.subscriber_type_expand">
            {{ item_service.subscriber_type_expand.name_expand }}
        </span>
        <span v-else>
            Все
        </span>
    </span>
</template>

<script>
    export default {
        name: "subscriber-type",
        props: ['item_service']
    }
</script>