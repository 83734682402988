<template>
    <div>
        <div class="form-modal__access">
            <template v-if="iptv_triniti_services.length > 0">
                <div class="form-modal__group">
                    <fieldset class="form-modal__item">
                        <span class="form-modal__title">
                            <span class="form-modal__icon">
                                <i class="fal fa-arrow-right"></i>
                            </span>
                            Пакет
                        </span>
                        <div class="form-modal__select">
                            <select v-model="service_iptv_triniti_id" required>
                                <option value="">Выберите пакет</option>
                                <option v-for="(iptv_triniti, index) in iptv_triniti_services"
                                        v-bind:value="iptv_triniti.id"
                                >{{ iptv_triniti.name }}
                                </option>
                            </select>
                        </div>
                        <form-error v-bind:value="service_errors.service_iptv_triniti"></form-error>
                    </fieldset>
                </div>
                <div class="form-modal__group">
                    <fieldset class="form-modal__item form-modal__item_line">
                                <span class="form-modal__title">
                                <span class="form-modal__icon"><i class="fal fa-users-medical"></i></span>
                                    Новый пользователь
                                </span>
                        <input type="checkbox" class="form-modal__input form-modal__input_switch"
                               v-model="is_new_triniti_subscriber" id="switch-newuser">
                        <label class="form-modal__switch-for"
                               for="switch-newuser"></label>
                    </fieldset>
                </div>
                <div class="form-modal__group" v-if="!is_new_triniti_subscriber">
                    <fieldset class="form-modal__item">
                        <input type="text" v-model="triniti_local_id" class="form-modal__input"
                               placeholder="Введите ID абонента">
                    </fieldset>
                </div>
            </template>
            <template v-else>
                <div class="form-modal__group">
                    <fieldset class="form-modal__item">
                        <span class="form-modal__title">
                            <span class="form-modal__icon"><i class="fal fa-arrow-right"></i></span>
                            Пакет
                        </span>
                        <div class="form-modal">
                            <span>Нет доступных пакетов</span>
                        </div>
                    </fieldset>
                </div>
            </template>
        </div>
        <div class="form-service" v-if="Object.keys(service_iptv_triniti_obj).length">
            <div class="form-service__total">
                <div class="form-service__total-title">Стоимость:</div>
                <div class="form-service__total-price">
                    <span class="form-service__summ">
                        <span class="form-service__summ-currency">&#x20b4</span>
                        <span class="form-service__summ-value">{{ service_iptv_triniti_obj.charging_amount_month }}</span>
                        <span class="form-service__summ-period">/мес</span>
                    </span>
                    <span class="form-service__summ-divide"></span>
                    <span class="form-service__summ" v-if="service_iptv_triniti_obj.charging_amount_day">
                        <span class="form-service__summ-currency">&#x20b4</span>
                        <span class="form-service__summ-value">{{ service_iptv_triniti_obj.charging_amount_day }}</span>
                        <span class="form-service__summ-period">/день</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {API} from "../../../../../../api";
    import {loader} from "../../../../../../models/loader";
    import FormErrors from '../../../form/errors.vue';

    export default {
        name: "service-iptv-triniti",
        props: ["service", "subscriber_connection", "service_errors"],
        components: {
            "form-error": FormErrors,
        },
        mounted: function () {
            this.fetchUser();
        },
        data() {
            return {
                triniti_local_id: "",
                is_new_triniti_subscriber: true,
                iptv_triniti_services: [],
                service_iptv_triniti_id: "",
                service_iptv_triniti_obj: {},
            }
        },
        methods: {
            fetchUser() {
                this.load_service_iptv_triniti();
            },
            load_service_iptv_triniti() {
                var self = this;

                loader.active = false;
                API.get('/services/iptv-triniti/', {
                    group_ext: this.subscriber_connection.group,
                    department_ext: this.subscriber_connection.department,
                    subscriber_type_ext: this.subscriber_connection.subscriber_expand.subscriber_type,
                    connection_type_ext: this.subscriber_connection.connection_type,
                    charging_type: this.subscriber_connection.tariff_expand.charging_type,
                })
                    .then(function (response) {
                        self.iptv_triniti_services = response.data.results;
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                        loader.active = true;
                    })
            },
            emit_form() {
                var form_data = {"service_iptv_triniti": this.service_iptv_triniti_id,};
                if (this.triniti_local_id.length > 0) {
                    form_data["triniti_local_id"] = this.triniti_local_id;
                }
                this.$emit("set_form_data", form_data);
            },
        },
        watch: {
            service_iptv_triniti_id(value) {
                for (var item of this.iptv_triniti_services) {
                    if (item.id === value) {
                        this.service_iptv_triniti_obj = item;
                    }
                }
                this.emit_form();
            },
            triniti_local_id(value) {
                this.emit_form();
            },
            is_new_triniti_subscriber(value) {
                if (value) {
                    this.triniti_local_id = "";
                }
            }
        }
    }
</script>

<style scoped>

</style>