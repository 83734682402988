<template>
    <div>
        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <label><i class="fal fa-server"></i>Серия:
                        <span class="control"><i class="fas fa-star-of-life"></i></span></label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <div class="selected">
                        <select v-model="unicard_set" required>

                            <!-- available_series -->
                            <option value="" disabled hidden>Выберите серию:</option>
                            <option v-for="item in available_series" v-bind:value="item.id">
                                Серия: {{ item.series }} | Номинал: {{ item.face_value }}грн | Диапазон: {{
                                item.number_from
                                }}-{{ item.number_to }} | Всего: {{ item.count_unicards }} | Остаток: {{
                                item.count_available_unicards }}
                            </option>

                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <label>
                        Номинал: <b>{{ face_value }} грн</b>
                    </label>
                </div>
            </div>
            <form-error v-bind:value="errors.unicard_set"></form-error>
        </div>
        <available-intervals :intervals="get_available_intervals()"></available-intervals>
    </div>

</template>

<script>
    import {UnicardsMixin} from "../../../../../../mixins/admin/unicards";
    import FormErrors from '../../../form/errors.vue';
    import AvailableIntervals from './available-intervals';

    export default {
        name: "select_unicard_set",
        props: ["available_series", "errors"],
        mixins: [UnicardsMixin],
        data() {
            return {
                type: "set",
                face_value: 0,
                unicard_set: "",
                unicards_intervals: []
            }
        },
        components: {
            "form-error": FormErrors,
            AvailableIntervals,
        },
        methods: {
            set_face_value() {
                for (var unicard_set of this.available_series) {
                    if (unicard_set.id === this.unicard_set) {
                        this.face_value = unicard_set.face_value;
                    }
                }
            },
            get_available_intervals() {
                return this.unicards_intervals.set_unicards_numbers
            },
            emit() {
                this.set_face_value();
                this.$emit("update_unicard_set", {
                    'unicard_set': this.unicard_set,
                });
            }
        },
        watch: {
            unicard_set(value) {
                this.emit();
                this.get_unicard_intervals(this.unicard_set)
            },
        }
    }
</script>

<style scoped>

</style>