<template>
    <section class="page">
        <div class="page__inner">
            <div class="container-width">
                <div class="row">
                    <!-- HEADING PAGE -->
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="page-header">
                            <div class="row">
                                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <h1 class="page-header__title"> Отчеты</h1>
                                </div>
                                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <DropDown></DropDown>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END HEADING PAGE -->
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="page-body">
                            <div class="page-body__header">
                                <div class="row">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div class="row">
                                            <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2">
                                                <date-range-picker
                                                        :startDate="startDate"
                                                        :endDate="endDate"
                                                        @update="update_range_picker"
                                                        :locale-data="locale">
                                                </date-range-picker>
                                            </div>
                                            <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2">
                                                <date-picker v-model="date_month" lang="ru" placeholder="Месяц"
                                                     format="MM-YYYY" type="month"></date-picker>
                                            </div>
                                            <div class="col-xs-12 col-sm-8 col-md-10 col-lg-10"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="view-data-reports">
                                <div class="row" v-if="agents.length" >
                                    <div v-for="agent in agents" class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                                        <div class="view-data-reports__item">
                                            <div class="view-data-reports__item-head-uni">
                                                <i class="fal fa-user-tie"></i>
                                                {{ agent.used_with_shop_agent__fio }}
                                            </div>
                                            <ul class="view-data-reports__item-list">
                                                <li>Проданное кол-во: <span class="value">{{ agent.sold_unicards_count }} шт.</span></li>
                                                <li>Проданно на сумму: <span class="value">{{ agent.sold_unicards_amount }}грн</span></li>
                                                <li>
                                                    <router-link title="Детальный отчет" :to="{ name: 'unicards-reports-more',
                                                                                            params: {id: agent.used_with_shop_agent_id },
                                                                                            query: {date_used__date_gte: form.date_used__date_gte,
                                                                                                    date_used__date_lte: form.date_used__date_lte}}">
                                                        Подробнее
                                                    </router-link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-else>
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div class="view-no-data">
                                            <div class="view-no-data__title">Нет данных за период</div>
                                            <div class="view-no-data__date"> {{ form.date_used__date_gte }} — {{ form.date_used__date_lte }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import DropDown from "../dropdown/dropdown";
    import DatePicker from 'vue2-datepicker';
    import {User} from "../../../../../mixins/admin/user";

    import {PermissionMixin} from "../../../../../mixins/admin/permission";
    import {DateRangePickerDefaults} from "../../../../../mixins/admin/date_range_picker_defaults";
    import {API} from "../../../../../api";
    import {UnicardsMixin} from "../../../../../mixins/admin/unicards";

    export default {
        name: "reports",
        mixins: [DateRangePickerDefaults, UnicardsMixin, User, PermissionMixin],
        data() {
            return {
                agents: [],
                startDate: this.$moment().startOf('month'),
                endDate: this.$moment().endOf('month'),
                date_month: this.$moment().startOf('month'),
                form: {
                    date_used__date_gte: "",
                    date_used__date_lte: "",
                },
            }
        },
        components: {
            DropDown,
            "date-picker": DatePicker,
        },
        mounted() {
            this.fetchUser();
        },
        methods: {
            fetchUser() {
                    this.update_range_picker({startDate: this.startDate, endDate: this.endDate});
                    var filter = Object.assign({}, this.form);
                    this.load_agents(filter)
            },
            update_range_picker(date_range) {
                this.form.date_used__date_gte = this.$moment(date_range.startDate).format("YYYY-MM-DD");
                this.form.date_used__date_lte = this.$moment(date_range.endDate).format("YYYY-MM-DD");
            }
        },
         watch: {
            form: {
                handler() {
                    var filter = Object.assign({}, this.form);
                    this.load_agents(filter)
                },
                deep: true
            },
            date_month(){
                this.form.date_used__date_gte = this.$moment(this.date_month).format("YYYY-MM-DD");
                this.form.date_used__date_lte = this.$moment(this.date_month).endOf('month').format("YYYY-MM-DD");
            }
        }
    }
</script>

<style scoped>

</style>