<template>
    <section class="page">
        <div class="page__inner">
            <div class="container-width">
                <!--HEADER PAGE-->
                <div class="page__header">
                    <h1 class="page__title">Добавление NAS</h1>
                    <div class="btn-group">
                        <router-link title="Добавление NAS" :to="{ name: 'nas-list'}">
                        <span class="btn btn_grey">
                            <span class="btn__icon"><i class="fal fa-undo"></i></span>
                            <span class="btn__title">Вернуться</span>
                        </span>
                        </router-link>
                        <!--<span class="btn btn_blue" @click.prevent="crud_create(item, 'nas-list')">-->
                        <!--<span class="btn__icon"><i class="fal fa-save"></i></span>-->
                        <!--<span class="btn__title">Сохранить</span>-->
                        <!--</span>-->
                    </div>
                </div>
                <!--END HEADER PAGE-->
                <!-- CONTENT PAGE -->
                <div class="page-body">
                    <form action="" class="form-data">
                        <div class="form-data__row">
                            <div class="form-data__section">
                                <!--Имя NAS-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-server"></i></span>
                                            <span class="form-data__label-title">Имя NAS:</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <input type="text" v-model="item.name" class="form-data__input" placeholder="Введите имя">
                                        </div>
                                    </div>
                                    <form-error v-bind:value="crud.errors.name"></form-error>
                                </fieldset>
                                <!--Отдел-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-code-branch"></i></span>
                                            <span class="form-data__label-title">Отдел:</span>
                                            <span class="form-data__label-control"><i class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <div class="form-data__select">
                                                <select v-model="item.department" required>
                                                    <option value="" hidden>Выберите отдел</option>
                                                    <option v-for="department in departments" v-bind:value="department.id">{{ department.name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <form-error v-bind:value="crud.errors.department"></form-error>
                                </fieldset>
                                <!--IP-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-database"></i></span>
                                            <span class="form-data__label-title">NAS IP</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <input type="text" v-model="item.ip" class="form-data__input" placeholder="127.0.0.0">
                                        </div>
                                    </div>
                                    <form-error v-bind:value="crud.errors.ip"></form-error>
                                </fieldset>
                                <!--Пользователь-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-user-cog"></i></span>
                                            <span class="form-data__label-title">Пользователь:</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <input type="text" v-model="item.username" class="form-data__input" placeholder="Имя пользователя">
                                        </div>
                                    </div>
                                    <form-error v-bind:value="crud.errors.username"></form-error>
                                </fieldset>
                                <!--Пароль-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-key"></i></span>
                                            <span class="form-data__label-title">Пароль:</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <input type="password" v-model="item.password" class="form-data__input" placeholder="Пароль">
                                        </div>
                                    </div>
                                    <form-error v-bind:value="crud.errors.password"></form-error>
                                </fieldset>
                            </div>
                            <div class="form-data__section">
                                <!--Port SSH-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-unlock"></i></span>
                                            <span class="form-data__label-title">Порт SSH:</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <input type="number" v-model="item.port_ssh" class="form-data__input" placeholder="SSH">
                                        </div>
                                    </div>
                                     <form-error v-bind:value="crud.errors.port_ssh"></form-error>
                                </fieldset>
                                <!--Port API-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-unlock"></i></span>
                                            <span class="form-data__label-title">Порт API:</span>
                                            <span class="form-data__label-control"><i class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <input type="number" v-model="item.api_port" class="form-data__input" placeholder="API">
                                        </div>
                                    </div>
                                    <form-error v-bind:value="crud.errors.api_port"></form-error>
                                </fieldset>
                                <!--SNMP Community-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-users-class"></i></span>
                                            <span class="form-data__label-title">SNMP Community:</span>
                                            <span class="form-data__label-control"><i class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <input type="text" v-model="item.snmp_community" class="form-data__input" placeholder="SNMP">
                                        </div>
                                    </div>
                                     <form-error v-bind:value="crud.errors.snmp_community"></form-error>
                                </fieldset>
                                <!--Адрес-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-map-marker-alt"></i></span>
                                            <span class="form-data__label-title">Адрес нахождения:</span>
                                            <span class="form-data__label-control"><i class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <input type="text" v-model="item.location_address" class="form-data__input" placeholder="Адрес">
                                        </div>
                                    </div>
                                    <form-error v-bind:value="crud.errors.location_address"></form-error>
                                </fieldset>
                                <!--Описание-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-comment"></i></span>
                                            <span class="form-data__label-title">Описание:</span>
                                            <span class="form-data__label-control"><i class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <input type="text" v-model="item.description" class="form-data__input" placeholder="Описание">
                                        </div>
                                    </div>
                                    <form-error v-bind:value="crud.errors.description"></form-error>
                                </fieldset>
                            </div>
                        </div>
                        <div class="form-data__footer form-data__footer_right">
                            <div class="btn-group">
                                <span class="btn btn_blue" @click.prevent="crud_create(item, 'nas-list')">
                                    <span class="btn__icon"><i class="fal fa-save"></i></span>
                                    <span class="btn__title">Сохранить</span>
                                </span>
                            </div>
                        </div>
                    </form>
                </div>
                <!-- END CONTENT PAGE -->
            </div>
        </div>
    </section>
</template>

<script>
    import {CrudMixin} from "../../../../mixins/admin/crud/crud_base";
    import {CrudListMixin} from "../../../../mixins/admin/crud/crud_list";
    import FormErrors from '../form/errors.vue';
    import {DepartmentMixin} from "../../../../mixins/admin/department";

    export default {
        name: "nas-add",
        mixins: [
            CrudMixin, CrudListMixin, DepartmentMixin
        ],
        data: function () {
            return {
                item: {
                    department: "",
                    name: "",
                    username: "",
                    password: "",
                    port_ssh: "",
                    snmp_community: "",
                    description: "",
                    location_address: "",
                    ip: "",
                    api_port: "",
                },
                crud: {
                    api: '/nas/',
                },
            }
        },
        components: {
            "form-error": FormErrors
        },
        created: function () {
            this.load_departments();
        }
    }
</script>

<style scoped>

</style>