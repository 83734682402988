<template>
    <div class="notification orange" v-if="subscriber_connection.trial_date_end || subscriber_connection.citizens_support_date_end">
      <template v-if="subscriber_connection.trial_date_end">
       <div class="notification__icon"><i class="fal fa-engine-warning"></i></div>
       <div class="notification__title">Тестовый период до {{ subscriber_connection.trial_date_end | moment("DD/MM/YYYY") }}!</div>
       <div class="notification__desc">
         В точке включения задан тестовый период до
         <b>{{ subscriber_connection.trial_date_end | moment("DD/MM/YYYY") }}</b>
         Тестовый период истекает <b>{{ get_days_remaining(subscriber_connection.trial_date_end) }}</b>
       </div>
       <div class="notification__action"></div>
      </template>
      <template v-if="subscriber_connection.citizens_support_date_end">
       <div class="notification__icon"><i class="fal fa-engine-warning"></i></div>
       <div class="notification__title">Активирован бесплатный 7-ми дневный период до {{ subscriber_connection.citizens_support_date_end | moment("DD/MM/YYYY") }} включительно!</div>
       <div class="notification__desc">
         В точке включения активирован 7-ми дневный период, который истекает
         <b>{{ get_days_remaining(subscriber_connection.citizens_support_date_end) }}</b>
       </div>
       <div class="notification__action"></div>
      </template>
    </div>
</template>

<script>
    import {pluralize} from "../../../../../helpers/pluralize";

    export default {
        name: "trial-period",
        props: ["subscriber_connection"],
        methods: {
            get_days_remaining(date_end){
                var now = this.$moment();
                var service_date_end = this.$moment(date_end);

                var days = service_date_end.diff(now, 'days');
                if(days > 0) {
                    return "через " + days + " " + pluralize(days, ['день', 'дня', 'дней'])
                }else{
                    return "сегодня"
                }
            }
        }
    }
</script>

<style scoped>

</style>