<template>
  <section class="page">
    <div class="page__inner">
      <div class="container-width">
        <!--HEADER PAGE-->
        <div class="page__header">
          <h1 class="page__title">ОТДЕЛЫ</h1>
          <div class="btn-group" v-has-perm="'catalog_departments_access'">
            <span class="btn btn_grey">
              <span class="btn__icon"><i class="fal fa-archive"></i></span>
              <span class="btn__title">Архив</span>
            </span>
            <app-has-perm-action-btn :perms="'catalog_departments_add_edit'"
                                      @action="popup_add">
                <template slot-scope="{ access }">
                    <span class="page-header__btn-group">
                        <span class="btn" :class="access ? 'green' : 'grey'">
                            <i class="fal fa-plus"></i> Добавить отдел
                        </span>
                    </span>
                </template>
            </app-has-perm-action-btn>
          </div>
        </div>
        <!--END HEADER PAGE-->
        <!--BODY PAGE-->
        <div class="page__body">
          <div class="table-box">
            <table class="table-box__table">
              <thead class="table-box__thead">
              <tr class="table-box__thead-tr">
                <!--Название отдела-->
                <th class="table-box__th">
                  <span class="table-box__thead-item table-box__thead-item_center">
                    <span class="table-box__thead-icon"><i class="fal fa-code-branch"></i></span>
                    <span class="table-box__thead-title">Название отдела</span>
                  </span>
                </th>
                <!--Описание-->
                <th class="table-box__th">
                  <span class="table-box__thead-item table-box__thead-item_center">
                    <span class="table-box__thead-icon"><i class="fal fa-comment-exclamation"></i></span>
                    <span class="table-box__thead-title">Описание</span>
                  </span>
                </th>
                <!--Действия-->
                <th class="table-box__th">
                  <span class="table-box__thead-item table-box__thead-item_center">
                    <span class="table-box__thead-icon"><i class="fal fa-mouse-pointer"></i></span>
                    <span class="table-box__thead-title">Действия</span>
                  </span>
                </th>
              </tr>
              </thead>
              <tbody class="table-box__tbody">
              <tr class="table-box__tr table-data" v-for="(item, index) in crud.data.list">
                <!--Название группы-->
                <td class="table-box__td">
                  <span class="table-data__text">
                    {{ item.name }}
                  </span>
                </td>
                <!--Описание-->
                <td class="table-box__td">
                  <span class="table-data__text">
                    {{ item.description }}
                  </span>
                </td>
                <!--Действия-->
                <td class="table-box__td table-box__td_right">
                  <div class="btn-group">
                    <!--Просмотр-->
                    <span v-tooltip="TooltipText='Просмотр'" class="btn-l btn-l_blue" @click="popup_view(item)">
                      <span class="btn-l__icon"><i class="fal fa-eye"></i></span>
                    </span>

                    <app-has-perm-action-btn :perms="'catalog_departments_add_edit'"
                                             @action="popup_edit(index, item)">
                        <template slot-scope="{ access }">
                            <span v-tooltip="TooltipText='Редактирование'"  class="btn-l" :class="access ? 'orange' : 'grey'">
                                <span class="btn-l__icon"><i class="fal fa-edit"></i></span>
                            </span>
                        </template>
                    </app-has-perm-action-btn>
                    <!--END EDIT DEPARTMENT-->
                    <!--DELETE DEPARTMENT-->
                      <!--TODO v-tooltip="TooltipText='Удалить в архив'" -->
                    <app-remove-btn :perms="'catalog_departments_delete'" ></app-remove-btn>
                    <!--END DELETE DEPARTMENT-->
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!--END BODY PAGE-->
      </div>
    </div>
    <!-- Modal ADD window -->
    <departments-add-popup :settings="add_popup" :crud="crud"></departments-add-popup>

    <!-- Modal Edit window -->
    <departments-edit-popup :settings="edit_popup" :crud="crud"></departments-edit-popup>

    <!-- Modal View window -->
    <departments-view-popup :settings="view_popup" :crud="crud"></departments-view-popup>

    <!-- Modal Delete window -->
    <departments-delete-popup :settings="delete_popup"></departments-delete-popup>
  </section>
</template>


<script>
import {CrudMixin} from "../../../../mixins/admin/crud/crud_base";
import {CrudListMixin} from "../../../../mixins/admin/crud/crud_list";

import DepartmentsAddPopup from './add.vue'
import DepartmentsEditPopup from './edit.vue'
import DepartmentsViewPopup from './view.vue'
import DepartmentsDeletePopup from './delete.vue'
import {User} from "../../../../mixins/admin/user";
import {SearchMixin} from "../../../../mixins/admin/search";

export default {
  components: {
    "departments-add-popup": DepartmentsAddPopup,
    "departments-edit-popup": DepartmentsEditPopup,
    "departments-view-popup": DepartmentsViewPopup,
    "departments-delete-popup": DepartmentsDeletePopup,
  },
  mixins: [User, CrudMixin, CrudListMixin, SearchMixin],
  data: function () {
    return {
      crud: {
        api: '/departments/',
      }
    }
  },
  methods: {
    fetchUser() {
        var params = this.$route.query;
        this.crud_get_list(params)
    }
  },
  created() {
    this.$root.$on('search_hit_departments', (function (data) {
      this.fetchUser()
    }).bind(this));

    this.$root.$on('search_clear_departments', (function (data) {
      this.fetchUser()
    }).bind(this));

    this.fetchUser()
  },
  beforeDestroy: function () {
    this.destroy_search_listeners("departments");
  }
}
</script>

<style scoped>

</style>