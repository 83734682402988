<template>
    <section class="page">
        <div class="page__inner">
            <div class="container-width">
                <div class="row">
                    <!-- HEADING PAGE -->
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="page-header">
                            <div class="row">
                                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <h1 class="page-header__title"> Отчет по номиналам</h1>
                                </div>
                                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <DropDown></DropDown>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END HEADING PAGE -->
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="page-body">
                            <div class="view-data-reports">
                                <div class="row">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div class="view-data-reports__header">
                                            <div class="title">{{ agent.used_with_shop_agent__fio }}</div>
                                            <div class="desc">За период: {{ date_used__date_gte | moment("DD.MM.YYYY") }} - {{ date_used__date_lte | moment("DD.MM.YYYY") }} </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div class="view-data-reports__item">
                                            <div class="view-data-reports__item-head-uni">
                                                <i class="fal fa-wallet"></i> Продажи номиналов
                                                <span class="explanation">Всего проданно: <b>{{ agent.sold_unicards_count }} шт.</b> | Проданно на сумму: <b>{{ agent.sold_unicards_amount }} грн</b></span>
                                            </div>
                                            <div class="table-container">
                                                <table>
                                                    <thead>
                                                    <tr>
                                                        <th v-for="item in agent_amount" ><i class="fal fa-hryvnia"></i> {{ item.unicard_set__face_value }}</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td v-for="item in agent_amount" >
                                                            <router-link title="Абоненты" class="link" :to="generate_link(item.link_object_type, item.link_params)">
                                                                {{ item.sold_unicards_count }} шт.
                                                            </router-link>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td v-for="item in agent_amount"  >
                                                            <i class="fal fa-hryvnia"></i> {{ item.sold_unicards_amount }} грн
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import DropDown from "../dropdown/dropdown";
    import {User} from "../../../../../mixins/admin/user";
    import {PermissionMixin} from "../../../../../mixins/admin/permission";
    import {UnicardsMixin} from "../../../../../mixins/admin/unicards";
    import {LinksMixin} from "../../../../../mixins/admin/links";

    export default {
        name: "more",
        mixins: [User, UnicardsMixin, PermissionMixin, LinksMixin],
        data(){
            return {
                agent_id: "",
                agents: [],
                agent: {},
                agent_amount: [],
                date_used__date_gte: "",
                date_used__date_lte: "",
            }
        },
        components: {
            DropDown,
        },
        mounted(){
            this.agent_id = this.$route.params.id;
            this.date_used__date_gte = this.$route.query.date_used__date_gte;
            this.date_used__date_lte = this.$route.query.date_used__date_lte;
            this.fetchUser();
        },
        methods: {
            fetchUser() {
                var filter = {
                    used_with_shop_agent_id: this.agent_id,
                    date_used__date_gte: this.date_used__date_gte,
                    date_used__date_lte: this.date_used__date_lte,
                };
                this.load_agents(filter);
                this.load_agent_amount(filter)
            }
        },
        watch: {
            agents(){
                this.agent = this.agents[0];
            }
        }
    }
</script>

<style scoped>

</style>