import {API} from "../../api";

export var ServiceIPTVTrinitiMixin = {
    data: function () {
        return {
            iptv_triniti_charging_types: [],
            iptv_triniti_tariffs: [],
        }
    },
    methods: {
        load_iptv_triniti_charging_types: function () {
            var self = this;
            API.get('/services/iptv-triniti-charging-type/')
                .then(function (response) {
                    self.iptv_triniti_charging_types = response.data.results;
                })
                .catch(function (error) {
                    API.default_catch(self, error)
                })
                .then(function () {
                    API.default_always(self);
                })
        },
        load_iptv_triniti_tariffs: function () {
            var self = this;
            API.get('/services/tariff-triniti/')
                .then(function (response) {
                    self.iptv_triniti_tariffs = response.data.results;
                })
                .catch(function (error) {
                    API.default_catch(self, error)
                })
                .then(function () {
                    API.default_always(self);
                })
        },
    }
};
