<template>
    <div class="view-data-reports__item">
        <div class="view-data-reports__item-head"><i class="fal fa-server"></i>
            Пополнение <span class="explanation">{{ title }}</span></div>
        <ul class="view-data-reports__item-list">

            <li v-for="agent in data.agents" >{{ agent.used_with_shop_agent__fio }}:
                <span class="value"><router-link :to="generate_link(agent.link_object_type, agent.link_params, {from_url: current_url()})" >{{ agent.sold_unicards_amount }}грн ({{ agent.sold_unicards_count }} шт)</router-link></span>
            </li>
            <li>Итого: <span class="value">
                <span v-for="(agent, index) in data.agents">{{ agent.sold_unicards_amount }}грн <template v-if="is_last_iteration(index)">+&nbsp;</template><template v-else>=</template></span>
                <router-link :to="generate_link(data.link_object_type, data.link_params, {from_url: current_url()})" >{{ data.total_amount }}грн ({{ data.total_unicards }} шт)</router-link></span></li>
        </ul>
    </div>
</template>

<script>
    import {LinksMixin} from "../../../../../mixins/admin/links";

    export default {
        name: "departments-report-recharge",
        props: ['title', 'data'],
        mixins: [LinksMixin],
        methods: {
            is_last_iteration(index){
                return index + 1 < this.data.agents.length;
            }
        }
    }
</script>

<style scoped>

</style>