<template>
    <td class="table-subscribers__td table-subscribers__td_center" v-has-perm="'subscribers_access'">
        <template v-if="(subscriber_connection.balance > -0.1 )">
            <span class="table-data__balance table-data__balance_active">
                ₴ {{ subscriber_connection.balance }}
            </span>
        </template>
        <template v-else>
            <span class="table-data__balance table-data__balance_deactive">
                ₴ {{ subscriber_connection.balance }}
            </span>
        </template>
    </td>
</template>

<script>
    export default {
        name: "td_balance",
        props: ["subscriber_connection"],
    }
</script>

<style scoped>

</style>