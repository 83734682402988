<template>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="page-body">
            <div class="view-data-reports">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="view-data-reports__header">
                            <div class="title">Отправка отчета</div>
                            <div class="desc">За период: {{ date_from | moment("DD.MM.YYYY") }} - {{ date_to | moment("DD.MM.YYYY") }}</div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="view-data-reports__item">
                            <div class="view-data-reports__item-head"><i class="fal fa-server"></i>
                                Получатели
                                <span class="explanation">Добавьте и выберите получателей отчета</span>
                            </div>
                            <ul class="view-data-reports__item-list email-list">
                                <li v-for="recipient in recipients">
                                    <label class="checkbox">
                                    <input type="checkbox" v-model="form.email_employee_ids" v-bind:value="recipient.id">
                                    <span class="checkmark"></span>
                                    {{ recipient.fio }} <span
                                        class="value">{{ recipient.email }}</span>
                                    </label>
                                </li>
                            </ul>
                            <div class="add-data">
                                <div class="add-data__item">
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                            <label><i class="fal fa-server"></i> Получатель: <span
                                                    class="control"><i
                                                    class="fas fa-star-of-life"></i></span></label>
                                        </div>
                                        <div class="col-xs-10 col-sm-11 col-md-5 col-lg-8">
<!--                                           <input type="email" placeholder="Введите email получателя">-->
                                            <div class="selected">
                                                <select v-model="employee" required>
                                                    <option value="" >Выберите сотрудника</option>
                                                    <option v-for="employee in employees"
                                                            v-bind:value="employee.id">
                                                        {{ employee.fio }}
                                                    </option>
                                                </select>
                                            </div>
                                            <form-error v-bind:value="errors.employee"></form-error>
                                        </div>
                                        <div class="col-xs-2 col-sm-1 col-md-1 col-lg-1">
                                            <span class="add-btn" @click="addRecipient()" ><i class="fal fa-plus"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <form class="add-data">
                                <div class="add-data__item">
                                    <textarea v-model="form.report_note" placeholder="Примечания к отчету"></textarea>
                                    <form-error v-bind:value="errors.report_note"></form-error>
                                </div>
                                <div class="add-data__footer padding">
                                    <span class="btn green" @click="send()" ><i class="fal fa-paper-plane"></i> Отправить</span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {EmployeeMixin} from "../../../../../mixins/admin/employee";
    import {User} from "../../../../../mixins/admin/user";
    import FormErrors from '../../form/errors.vue';
    import {API} from "../../../../../api";

    export default {
        name: "departments-form-send",
        mixins: [EmployeeMixin, User],
        props: ['department_form', 'department', 'date_from', 'date_to'],
        data(){
            return {
                employee: "",
                employees: [],
                employees_in_department: [],
                recipients: [],
                form: {
                    email_employee_ids: [],
                    report_note: ""
                },
                errors: {}
            };
        },
        components: {
            "form-error": FormErrors,
        },
        mounted(){
            this.fetchUser();
        },
        methods: {
            fetchUser(){
                this.recipients = [];

                this.load_employees();
                this.load_employees_by_department(this.department.id);
                this.setupRecipients();
            },
            setupRecipients(){
                // Set up Recipients, user from session
                if(!this.isset_recipients(this.user)) {
                    this.recipients.push(this.user);
                    this.form.email_employee_ids.push(this.user.id);
                }
            },
            addRecipient(){
                var self = this;
                this.errors.employee = [];
                for(var employee of this.employees){
                    if(employee.id === this.employee){
                        if(!this.isset_recipients(employee)) {
                            this.recipients.push(employee);
                            this.form.email_employee_ids.push(employee.id);
                        } else {
                            this.$set(this, "errors", {employee: ['Получатель "'+employee.fio+'" уже добавлен в список']})
                        }
                    }
                }
            },
            isset_recipients(employee){
                return this.recipients.some(function(el) {
                            return el.id === employee.id;
                          })
            },
            send(){
                var self = this;
                var data = Object.assign({}, this.form, this.department_form);
                API.post('/report/department-send-email/', data)
                    .then(function (response) {
                        self.results = response.data;
                        self.alert("Отправка отчета", "Отчет успешно отправлен")
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            }
        },
        watch: {
            employees_in_department(){
                for(var employees_in_department of this.employees_in_department) {
                    if (!this.isset_recipients(employees_in_department)) {
                        this.recipients.push(employees_in_department);
                        this.form.email_employee_ids.push(employees_in_department.id);
                    }
                }
            }
        }

    }
</script>

<style scoped>

</style>