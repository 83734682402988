<template>
    <section class="page">
        <div class="page__inner">
            <div class="container-width">
                <!--HEADER PAGE-->
                <div class="page__header">
                    <h1 class="page__title">IP Pools</h1>
                    <div class="btn-group">
                        <span class="btn btn_grey">
                            <span class="btn__icon"><i class="fal fa-archive"></i></span>
                            <span class="btn__title">Архив</span>
                        </span>
                        <!--ADD NEW POOL-->
                        <app-has-perm-action-btn :perms="'catalog_ip_address_poll_add_edit'"
                                                 @action="popup_add">
                            <template slot-scope="{ access }">
                                <span class="btn" :class="access ? 'btn_green' : 'btn_grey'">
                                    <span class="btn__icon"><i class="fal fa-plus"></i></span>
                                    <span class="btn__title">Добавить Pool</span>
                                </span>
                            </template>
                        </app-has-perm-action-btn>
                        <!--END ADD NEW POOL-->
                    </div>
                </div>
                <!--END HEADER PAGE-->

                <!--BODY PAGE-->
                <div class="page-body">
                    <!--PAGE-TABLE-->
                    <div class="table-box">
                        <table class="table-box__table">
                            <thead class="table-box__thead">
                            <tr class="table-box__thead-tr">
                                <th class="table-box__th">
                                    <span class="table-box__thead-item">
                                        <span class="table-box__thead-icon"><i class="fal fa-sort-numeric-down"></i></span>
                                        <!--<span class="table-box__thead-title">Регионы</span>-->
                                    </span>
                                </th>
                                <th class="table-box__th">
                                    <span class="table-box__thead-item">
                                        <!--<span class="table-box__thead-icon"><i class="fal fa-sort-numeric-down"></i></span>-->
                                        <span class="table-box__thead-title">Network</span>
                                    </span>
                                </th>
                                <th class="table-box__th">
                                    <span class="table-box__thead-item">
                                        <!--<span class="table-box__thead-icon"><i class="fal fa-sort-numeric-down"></i></span>-->
                                        <span class="table-box__thead-title">Netmask</span>
                                    </span>
                                </th>
                                <th class="table-box__th">
                                    <span class="table-box__thead-item">
                                        <!--<span class="table-box__thead-icon"><i class="fal fa-sort-numeric-down"></i></span>-->
                                        <span class="table-box__thead-title">Broadcast</span>
                                    </span>
                                </th>
                                <th class="table-box__th">
                                    <span class="table-box__thead-item">
                                        <!--<span class="table-box__thead-icon"><i class="fal fa-sort-numeric-down"></i></span>-->
                                        <span class="table-box__thead-title">Hostmin-Hostmax</span>
                                    </span>
                                </th>
                                <th class="table-box__th">
                                    <span class="table-box__thead-item">
                                        <!--<span class="table-box__thead-icon"><i class="fal fa-sort-numeric-down"></i></span>-->
                                        <span class="table-box__thead-title">Hosts/Net</span>
                                    </span>
                                </th>
                                <th class="table-box__th">
                                    <span class="table-box__thead-item">
                                        <!--<span class="table-box__thead-icon"><i class="fal fa-sort-numeric-down"></i></span>-->
                                        <span class="table-box__thead-title">Примечание</span>
                                    </span>
                                </th>
                                <th class="table-box__th">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-mouse-pointer"></i></span>
                                        <span class="table-box__thead-title">Действия</span>
                                    </span>
                                </th>
                            </tr>
                            </thead>
                            <tbody class="table-box__tbody">
                                <tr class="table-box__tr table-data" v-for="(ip, index) in ip_pool">
                                    <td class="table-box__td">
                                        <span class="table-data__text">{{ index + 1 }}</span>
                                    </td>
                                    <td class="table-box__td">
                                        <span class="table-data__text">{{ ip.network }}</span>
                                    </td>
                                    <td class="table-box__td">
                                        <span class="table-data__text">/{{ ip.netmask }}</span>
                                    </td>
                                    <td class="table-box__td">
                                        <span class="table-data__text">{{ ip.broadcast }}</span>
                                    </td>
                                    <td class="table-box__td">
                                        <span class="table-data__text">{{ ip.host_min }} — {{ ip.host_max }}</span>
                                    </td>
                                    <td class="table-box__td">
                                        <span class="table-data__text">{{ ip.count_hosts_net }}</span>
                                    </td>
                                    <td class="table-box__td">
                                        <span class="table-data__text">{{ ip.note }}</span>
                                    </td>
                                    <td class="table-box__td table-box__td_right">
                                        <div class="btn-group">
                                            <span>
                                                <!--EDIT IP-POOL-ADDRESS-->
                                                <app-has-perm-action-btn :perms="'catalog_ip_address_poll_add_edit'"
                                                                         @action="popup_edit(index, ip)">
                                                    <template slot-scope="{ access }">
                                                        <span class="btn-l" :class="access ? 'btn-l_orange' : 'btn-l_grey'">
                                                            <span class="btn-l__icon"><i class="fal fa-edit"></i></span>
                                                        </span>
                                                    </template>
                                                </app-has-perm-action-btn>
                                                <!--END EDIT IP-POOL-ADDRESS-->
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!--END PAGE-TABLE-->
                </div>
                <!--END BODY PAGE-->
            </div>
        </div>
        <!-- Add-pool popup -->
        <add-pool-popup :settings="add_popup" @update_pools="updatePools" ></add-pool-popup>
        <!-- Edit-pool popup -->
        <edit-pool-popup :settings="edit_popup" :crud="crud" @update_pools="updatePools" ></edit-pool-popup>
    </section>
</template>

<script>
    import {CrudMixin} from "../../../../../mixins/admin/crud/crud_base";
    import {CrudListMixin} from "../../../../../mixins/admin/crud/crud_list";

    import AddPool from './add-pool.vue';
    import EditPool from './edit-pool.vue';
    import {API} from "../../../../../api";

    export default {
        name: "pools",
        components: {
            "add-pool-popup": AddPool,
            "edit-pool-popup": EditPool,
        },
        data(){
            return {
                ip_pool: []
            }
        },
        mounted(){
            this.fetchUser();
        },
        mixins: [CrudMixin, CrudListMixin],
        methods: {
            fetchUser(){
                this.updatePools()
            },
            updatePools(){
                this.ip_pool = [];
                API.get_recursive('/ip-address/ip-pool/', {}, this.ip_pool);
            }
        }
    }
</script>

<style scoped>

</style>