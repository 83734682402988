export const monthsFilter = [
    {
      key: 1,
      label: 'Январь'
    },
    {
      key: 2,
      label: 'Февраль'
    },
    {
      key: 3,
      label: 'Март'
    },
    {
      key: 4,
      label: 'Апрель'
    },
    {
      key: 5,
      label: 'Май'
    },
    {
      key: 6,
      label: 'Июнь'
    },
    {
      key: 7,
      label: 'Июль'
    },
    {
      key: 8,
      label: 'Август'
    },
    {
      key: 9,
      label: 'Сентябрь'
    },
    {
      key: 10,
      label: 'Октябрь'
    },
    {
      key: 11,
      label: 'Ноябрь'
    },
    {
      key: 12,
      label: 'Декабрь'
    },
]