<template>
    <div class="page-body">
        <div class="view-data">
            <div class="view-data__header">
                <div class="row">
                    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                        <h3><i class="fal fa-id-badge"></i> Персональные данные</h3>
                    </div>
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                        <span v-has-perm="'subscribers_add_edit'">
                            <span class="action-link">
                                <!--EDIT BUTTON FOR PRIVATE SUBSCRIBER-->
                                <app-has-perm-action-btn v-if="is_subscriber_private()"
                                                         :perms="'subscribers_add_edit'"
                                                         @action="set_mode_edit">
                                    <template slot-scope="{ access }">
                                        <template v-if="access">
                                            <a href="#edit" ><i class="fal fa-user-edit"></i> Редактировать</a>
                                        </template>
                                        <template v-else>
                                            <span><i class="fal fa-user-edit"></i> Редактировать</span>
                                        </template>
                                    </template>
                                </app-has-perm-action-btn>
                                <!--EDIT BUTTON FOR BUSINESS SUBSCRIBER-->
                                <app-has-perm-action-btn v-if="is_subscriber_business()"
                                                         :perms="'subscriber_business_details_edit'"
                                                         @action="set_mode_edit">
                                    <template slot-scope="{ access }">
                                        <template v-if="access">
                                            <a href="#edit" ><i class="fal fa-user-edit"></i> Редактировать</a>
                                        </template>
                                        <template v-else>
                                            <span><i class="fal fa-user-edit"></i> Редактировать</span>
                                        </template>
                                    </template>
                                </app-has-perm-action-btn>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="view-data__item">
                <div class="row">
                    <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                        <div class="label"><i class="fal fa-users-crown"></i> Тип абонента:</div>
                    </div>
                    <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                        <div class="value">
                            <template
                                    v-if="Object.keys(subscriber).length && subscriber.subscriber_type_expand">
                                {{ subscriber.subscriber_type_expand.name_expand }}
                            </template>
                            <template v-else>
                                <i>Не задано</i>
                            </template>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="is_subscriber_private()">
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-user-crown"></i> ФИО:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">{{ subscriber.fio }}</div>
                        </div>
                    </div>
                </div>
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-headset"></i> Телефон:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">{{ subscriber.phone }}</div>
                        </div>
                    </div>
                </div>

                <template v-for="(phone, index) in [subscriber.phone_1, subscriber.phone_2, subscriber.phone_3, subscriber.phone_4, subscriber.phone_5]" >
                    <div class="view-data__item" v-if="phone">
                        <div class="row">
                            <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                                <div class="label"><i class="fal fa-phone-plus"></i> Доп. телефон #{{ index + 1 }}:</div>
                            </div>
                            <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                                <div class="value">{{ phone }}</div>
                            </div>
                        </div>
                    </div>
                </template>

                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-at"></i> E-mail:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">{{ subscriber.subscriber_email }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="has_perm('subscriber_business_details_view') && is_subscriber_business()">
                <!--Поля юр лицо-->
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-at"></i> Организационная форма:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">{{ subscriber.subscriber_business_details.organizational_form }}</div>
                        </div>
                    </div>
                </div>
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-at"></i> Название предприятия:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">{{ subscriber.subscriber_business_details.company_name }}</div>
                        </div>
                    </div>
                </div>
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-at"></i> Код ЕРДПОУ:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">{{ subscriber.subscriber_business_details.registry_code }}</div>
                        </div>
                    </div>
                </div>
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-at"></i> ИНН:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">{{ subscriber.subscriber_business_details.taxpayer_id_number }}</div>
                        </div>
                    </div>
                </div>
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-at"></i> Плательщик Плательщик НДС:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">
                                <i class="far" :class="[subscriber.subscriber_business_details.is_vat_payer ? 'fa-check': 'fa-times']"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <template v-if="subscriber.subscriber_business_details.is_vat_payer">
                    <div class="view-data__item">
                        <div class="row">
                            <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                                <div class="label"><i class="fal fa-at"></i> № свидетельства НДС:</div>
                            </div>
                            <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                                <div class="value">{{ subscriber.subscriber_business_details.vat_certificate_number }}</div>
                            </div>
                        </div>
                    </div>
                </template>

                <div class="view-data__header">
                        <h3><i class="fal fa-passport"></i> Юридический адрес</h3>
                </div>
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-at"></i> Область:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">{{ subscriber.subscriber_business_details.business_address_region }}</div>
                        </div>
                    </div>
                </div>
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-at"></i> Район:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">{{ subscriber.subscriber_business_details.business_address_district }}</div>
                        </div>
                    </div>
                </div>
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-at"></i> Населенный пункт:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">{{ subscriber.subscriber_business_details.business_address_settlement }}</div>
                        </div>
                    </div>
                </div>
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-at"></i> Улица:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">{{ subscriber.subscriber_business_details.business_address_street }}</div>
                        </div>
                    </div>
                </div>
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-at"></i> Дом:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">{{ subscriber.subscriber_business_details.business_address_house_number }}</div>
                        </div>
                    </div>
                </div>
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-at"></i> Квартира/Офис:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">{{ subscriber.subscriber_business_details.business_address_apartment_number }}</div>
                        </div>
                    </div>
                </div>
                <div class="view-data__header">
                        <h3><i class="fal fa-passport"></i> Фактический адрес</h3>
                </div>
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-at"></i> Область:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">{{ subscriber.subscriber_business_details.actual_address_region }}</div>
                        </div>
                    </div>
                </div>
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-at"></i> Район:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">{{ subscriber.subscriber_business_details.actual_address_district }}</div>
                        </div>
                    </div>
                </div>
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-at"></i> Населенный пункт:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">{{ subscriber.subscriber_business_details.actual_address_settlement }}</div>
                        </div>
                    </div>
                </div>
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-at"></i> Улица:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">{{ subscriber.subscriber_business_details.actual_address_street }}</div>
                        </div>
                    </div>
                </div>
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-at"></i> Дом:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">{{ subscriber.subscriber_business_details.actual_address_house_number }}</div>
                        </div>
                    </div>
                </div>
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-at"></i> Квартира/Офис:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">{{ subscriber.subscriber_business_details.actual_address_apartment_number }}</div>
                        </div>
                    </div>
                </div>
                <div class="view-data__header">
                        <h3><i class="fal fa-passport"></i> Банковские данные:</h3>
                </div>
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-at"></i> Р/С:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">{{ subscriber.subscriber_business_details.bank_payment_account }}</div>
                        </div>
                    </div>
                </div>
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-at"></i> Название Банка:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">{{ subscriber.subscriber_business_details.bank_name }}</div>
                        </div>
                    </div>
                </div>
<!--                <div class="view-data__item">-->
<!--                    <div class="row">-->
<!--                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">-->
<!--                            <div class="label"><i class="fal fa-at"></i>МФО Банка:</div>-->
<!--                        </div>-->
<!--                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">-->
<!--                            <div class="value">{{ subscriber.subscriber_business_details.bank_code }}</div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
                <div class="view-data__header">
                        <h3><i class="fal fa-passport"></i> Контактные данные:</h3>
                </div>
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-at"></i> ФИО:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">{{ subscriber.subscriber_business_details.contact_full_name }}</div>
                        </div>
                    </div>
                </div>
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-at"></i> Должность:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">{{ subscriber.subscriber_business_details.contact_position }}</div>
                        </div>
                    </div>
                </div>
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-at"></i> Телефон:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">{{ subscriber.subscriber_business_details.contact_phone }}</div>
                        </div>
                    </div>
                </div>
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-at"></i> Email:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">{{ subscriber.subscriber_business_details.contact_email }}</div>
                        </div>
                    </div>
                </div>
                 <div class="view-data__header">
                        <h3><i class="fal fa-passport"></i> Представители:</h3>
                </div>

                <template v-if="subscriber.subscriber_business_details.business_details_members_expand"
                          v-for="member in subscriber.subscriber_business_details.business_details_members_expand">
                    <div class="view-data__item">
                        <div class="row">
                            <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                                <div class="label"><i class="fal fa-at"></i> Представитель по умолчанию:</div>
                            </div>
                            <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                                <div class="value">
                                    <i class="far fa-check" v-if="member.is_default" ></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="view-data__item">
                        <div class="row">
                            <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                                <div class="label"><i class="fal fa-at"></i> ФИО:</div>
                            </div>
                            <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                                <div class="value">{{ member.fio }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="view-data__item">
                        <div class="row">
                            <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                                <div class="label"><i class="fal fa-at"></i> Должность:</div>
                            </div>
                            <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                                <div class="value">{{ member.position }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="view-data__item">
                        <div class="row">
                            <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                                <div class="label"><i class="fal fa-at"></i> Телефон:</div>
                            </div>
                            <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                                <div class="value">{{ member.phone }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="view-data__item">
                        <div class="row">
                            <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                                <div class="label"><i class="fal fa-at"></i> Email:</div>
                            </div>
                            <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                                <div class="value">{{ member.email }}</div>
                            </div>
                        </div>
                    </div>

                </template>

                <div class="view-data__header">
                        <h3><i class="fal fa-passport"></i> Уставные документы:</h3>
                </div>

                <template v-for="file in subscriber.subscriber_business_details.business_details_statutory_document_expand">
                    <a :href="file.file_expand" target="_blank">{{ file.name }}</a> <br>
                </template>

                <div class="view-data__header">
                        <h3><i class="fal fa-passport"></i> Договор:</h3>
                </div>
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-at"></i> Название:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">{{ subscriber.subscriber_business_details.agreement_name }}</div>
                        </div>
                    </div>
                </div>
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-at"></i> № Договора:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">{{ subscriber.subscriber_business_details.agreement_number }}</div>
                        </div>
                    </div>
                </div>
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-at"></i> Дата заключения:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">{{ subscriber.subscriber_business_details.agreement_conclusion_date | moment("DD/MM/YYYY") }}</div>
                        </div>
                    </div>
                </div>
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-at"></i> Дата окончания:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">{{ subscriber.subscriber_business_details.agreement_expiration_date | moment("DD/MM/YYYY") }}</div>
                        </div>
                    </div>
                </div>
                <div class="view-data__item">
                        <div class="row">
                            <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                                <div class="label"><i class="fal fa-at"></i>Автоматическая пролонгация договора: </div>
                            </div>
                            <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                                <div class="value">
                                    <i class="far fa-check" v-if="subscriber.subscriber_business_details.automatic_extension" ></i>
                                </div>
                            </div>
                        </div>
                    </div>
                <div class="view-data__header">
                    <h3><i class="fal fa-passport"></i> Скан договоров:</h3>
                </div>

                <template v-for="file in subscriber.subscriber_business_details.business_details_agreement_file_expand">
                    <a :href="file.file_expand" target="_blank">{{ file.name }}</a><br>
                </template>
            </div>


            <div v-if="has_perm('subscriber_passport_details_view')" v-show="is_subscriber_private()">
                <div class="view-data__header">
                    <h3><i class="fal fa-passport"></i> Паспортные данные</h3>
                </div>
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-globe"></i> Страна:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">
                                <template v-if="subscriber.user_passportdetails">
                                    {{ subscriber.user_passportdetails.country }}
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-map-marker-alt"></i> Область:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">
                                <template v-if="subscriber.user_passportdetails">
                                    {{ subscriber.user_passportdetails.region }}
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-map-marker-alt"></i> Район:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">
                                <template v-if="subscriber.user_passportdetails">
                                    {{ subscriber.user_passportdetails.district }}
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-map-marker-alt"></i> Населенный пункт:
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">
                                <template v-if="subscriber.user_passportdetails">{{
                                    subscriber.user_passportdetails.settlement }}
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-road"></i> Улица:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">
                                <template v-if="subscriber.user_passportdetails">{{
                                    subscriber.user_passportdetails.street }}
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-home"></i> Дом:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">
                                <template v-if="subscriber.user_passportdetails">{{
                                    subscriber.user_passportdetails.house_number }}
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-building"></i> Квартира:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">
                                <template v-if="subscriber.user_passportdetails">{{
                                    subscriber.user_passportdetails.apartment_number }}
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-id-card"></i> Серия/Номер:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">
                                <template v-if="subscriber.user_passportdetails">{{
                                    subscriber.user_passportdetails.passport_series_number }}
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-calendar-alt"></i> Дата выдачи:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">
                                <template v-if="subscriber.user_passportdetails">{{
                                    subscriber.user_passportdetails.date_of_issue }}
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-user-secret"></i> Кем выдан:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">
                                <template v-if="subscriber.user_passportdetails">{{
                                    subscriber.user_passportdetails.issued_by }}
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="view-data__item">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div class="label"><i class="fal fa-file"></i> ИНН:</div>
                        </div>
                        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <div class="value">
                                <template v-if="subscriber.user_passportdetails">{{
                                    subscriber.user_passportdetails.identification_number }}
                                </template>
                            </div>
                        </div>
                    </div>
                </div>

                <template v-if="subscriber.user_passportdetails">
                    <!--PRIVATE SUBSCRIBER PASSPORTS FILES-->
                    <div class="view-data__header">
                        <h3><i class="fal fa-passport"></i>Документы удостоверяющие личность:</h3>
                    </div>

                    <template v-for="file in subscriber.user_passportdetails.private_subscriber_passport_details_file_expand">
                        <a :href="file.file_expand" target="_blank">{{ file.name }}</a> <br>
                    </template>

                    <!--PRIVATE SUBSCRIBER ADDITIONAL FILES-->
                    <div class="view-data__header">
                        <h3><i class="fal fa-passport"></i>Дополнительные документы:</h3>
                    </div>

                    <template v-for="file in subscriber.user_passportdetails.private_subscriber_additional_details_file_expand">
                        <a :href="file.file_expand" target="_blank">{{ file.name }}</a> <br>
                    </template>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import {SubscriberMixin} from "../../../../mixins/admin/subscriber";
    import {SubscriberTypeMixin} from "../../../../mixins/admin/subscriber_type";

    export default {
        name: "subscribers-view",
        props: ['subscriber_source'],
        mixins: [SubscriberMixin, SubscriberTypeMixin],
        methods: {
            set_mode_edit: function () {
                this.$emit('set_mode', 'edit')
            },
        },
        created: function () {
            this.load_subscriber_types();
        },
        watch:{
            subscriber_source(value){
                this.$set(this, 'subscriber', Object.assign({}, value));
            }
        }
    }
</script>

<style scoped>

</style>