<template>
    <td class="table-subscribers__td table-subscribers__td_right">
        <div class="btn-group">

            <app-has-perm-action-btn :perms="'subscriber_connection_send_notif_sms'">
                 <template slot-scope="{ access }">

                     <span v-tooltip="TooltipText='Отправить СМС'" class="btn-l" :class="access ? 'btn-l_orange' : 'btn-l_grey'" >
                        <span class="btn-l__icon"><i class="fal fa-sms"></i></span>
                    </span>

                 </template>
            </app-has-perm-action-btn>

            <app-has-perm-action-btn :perms="'subscriber_connection_send_notif_email'">
                 <template slot-scope="{ access }">

                     <span v-tooltip="TooltipText='Отправить E-mail'" class="btn-l" :class="access ? 'btn-l_blue' : 'btn-l_grey'" >
                        <span class="btn-l__icon"><i class="fal fa-at"></i></span>
                    </span>

                 </template>
            </app-has-perm-action-btn>

            <template v-if="subscriber_connection">

                <app-has-perm-action-btn :perms="'subscriber_connection_blocking'"
                                         @action="update_block_unblock(subscriber_connection, index, subscriber_connection.cls_list === 'CLS_AUTH'?'CLS_DENY':'CLS_AUTH')">
                     <template slot-scope="{ access }">

                         <template v-if="subscriber_connection.cls_list === 'CLS_AUTH'">
                            <span v-tooltip="TooltipText='Заблокировать'" class="btn-l" :class="access ? 'btn-l_red' : 'btn-l_grey'"><i
                                    class="fal fa-lock"></i></span>
                        </template>
                        <template v-else>
                            <span v-tooltip="TooltipText='Разблокировать'" class="btn-l" :class="access ? 'btn-l_green' : 'btn-l_grey'"><i
                                    class="fal fa-unlock"></i></span>
                        </template>

                     </template>
                </app-has-perm-action-btn>

            </template>

            <app-remove-btn :perms="'subscribers_delete'" ></app-remove-btn>
        </div>
    </td>
</template>

<script>
    import {SubscriberConnectionMixin} from "../../../../../../mixins/admin/subscriber_connection";

    export default {
        name: "td_actions",
        mixins: [SubscriberConnectionMixin],
        props: ['subscriber', 'subscriber_connection', 'index'],
        methods: {
            update_block_unblock(subscriber_connection, index, value) {
                var self = this;
                var data = {
                    cls_list: value
                };

                this.edit_subscriber_connection(subscriber_connection.id, data)
                    .then(function (response) {
                        // self.load_subscriber_connection(subscriber_connection.id);
                        self.$emit("load_subscribers");
                    })
            },
        }
    }
</script>

<style scoped>

</style>