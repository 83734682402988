<template>
  <div class="dashboard-main__item" v-if="blockItem">
    <div class="dashboard-main__head">
      <div class="dashboard-main__title">{{ blockItem.title }}</div>
      <div class="dashboard-main__filters" v-if="blockItem.options">
        <div class="dashboard-main__filters-select">
          <select required>
            <option v-for="(option, index) in blockItem.options"
                    :key="index"
                    :value="option.value">{{ option.key }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="dashboard-main__body">
      <div class="dashboard-main__icon"><i :class="blockItem.iconClass"></i></div>
      <router-link class="dashboard-main__count" v-if="linkObjectType && linkParams"
                   :to="generate_link(linkObjectType, linkParams, {from_url: current_url()})">
        {{ count }}
      </router-link>
    </div>
  </div>
</template>

<script>
import {LinksMixin} from "../../../../../mixins/admin/links";
import {DashboardStatisticsMixin} from "../../../../../mixins/admin/DashboardStatistics";

export default {
  name: "dashboard-count-item",
  props: {
    blockItem: {
      type: Object,
      default: {
        title: '',
        iconClass: '',
        options: null,
        method: ''
      }
    }
  },
  mixins: [LinksMixin, DashboardStatisticsMixin],
  data() {
    return {
      count: NaN,
      linkObjectType: '',
      linkParams: '',
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData(){
      if (this.blockItem.method === 'getTotalSubscriberConnectionsOnline') {
        this.getTotalSubscriberConnectionsOnline();
      } else if (this.blockItem.method === 'getTotalCountCreatedSubscriberConnectionsYesterday') {
        this.getTotalCountCreatedSubscriberConnectionsYesterday();
      } else if (this.blockItem.method === 'getTotalActivateIPTVTrinitiSubscriberConnection') {
        this.getTotalActivateIPTVTrinitiSubscriberConnection();
      } else if (this.blockItem.method === 'getTotalActivateIPAddressSubscriberConnection') {
        this.getTotalActivateIPAddressSubscriberConnection();
      }
    }
  },
  watch: {
    'blockItem.method': {
      handler(value) {
        this.loadData();
      },
      deep: true
  }
  },
}
</script>

<style scoped>

</style>