import {API} from "../../api";

export var EmployeeMixin = {
    data: function () {
        return {
            employee: {},
            employees: [],
        }
    },
    methods: {
        get_employee: function (id) {
            var self = this;
            API.get('/employee/'+id+'/')
                .then(function (response) {
                    self.employee = response.data;
                })
                .catch(function (error) {
                    // API.default_catch(self, error)
                })
                .then(function () {
                    // API.default_always(self);
                })
        },
        load_employees: function (obj) {
            var self = this;
            API.get('/employee/')
                .then(function (response) {
                    self.employees = response.data.results;
                })
                .catch(function (error) {
                    // API.default_catch(self, error)
                })
                .then(function () {
                    // API.default_always(self);
                })
        },
        load_employees_by_department: function (department_id) {
            var self = this;
            API.get("/employee-in-department/with-report-access/", {department: department_id})
                .then(function (response) {
                    self.employees_in_department = response.data.results;
                })
                .catch(function (error) {
                    // API.default_catch(self, error)
                })
                .then(function () {
                    // API.default_always(self);
                })
        }
    }
};
