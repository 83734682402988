<template>
  <section class="page">
    <div class="page__inner">
      <div class="container-width">
        <!--HEADER PAGE-->
        <div class="page__header">
          <h1 class="page__title">Отчет по аналитике</h1>
          <report-menu :active_name="'reports-page'"></report-menu>
        </div>
        <!--END HEADER PAGE-->
        <!-- BODY PAGE -->
        <div class="page__body">
          <div class="filters">
            <div class="filters__body">
              <div class="filters__select">
                <select v-model="reportData.year" required>
                  <option disabled :value="undefined">Год</option>
                  <option v-for="year in years" :value="year.year">{{ year.year }}</option>
                </select>
              </div>
              <div class="filters__select">
                <select v-model="reportData.month" required>
                  <option disabled :value="undefined">Месяц</option>
                  <option :value="null">Все месяца</option>
                  <option v-for="month in monthsFilter" :value="month.key" :key="month.key">{{ month.label }}</option>
                </select>
              </div>
              <div class="filters__select">
                <select v-model="reportData.region" required>
                  <option disabled :value="undefined">Область</option>
                  <option :value="''">Все области</option>
                  <option v-for="region in regions" :value="region.id">{{ region.name }}</option>
                </select>
              </div>
              <div class="filters__select" v-show="reportData.region">
                <select v-model="reportData.district" required>
                  <option disabled :value="undefined">Район</option>
                  <option :value="''">Все районы</option>
                  <option v-for="district in districts" :value="district.id">{{ district.name }}</option>
                </select>
              </div>
              <div class="filters__select" v-show="reportData.district">
                <select v-model="reportData.settlement" required>
                  <option disabled :value="undefined">Населенный пункт</option>
                  <option :value="''">Все</option>
                  <option v-for="settlement in settlements" :value="settlement.id">{{ settlement.name }}</option>
                </select>
              </div>
              <div class="filters__select">
                <select v-model="reportData.payment_type" required>
                  <option disabled :value="undefined">Тип платежей</option>
                  <option :value="null">Все платежи</option>
                  <option v-for="paymentType in paymentTypes.results" :value="paymentType.key">{{ paymentType.label }}</option>
                </select>
              </div>
              <div class="filters__select">
                <select v-model="reportData.count_solvency" required>
                  <option disabled :value="undefined">Платежность</option>
                  <option :value="true">Да</option>
                  <option :value="false">Нет</option>
                </select>
              </div>
              <div class="filters__actions">
                <div class="filters__btn filters__btn_green" @click="generateAnalyticReport()">
                  <span class="filters__btn-icon"></span>
                  <span class="filters__btn-title">Сгенерировать</span>
                </div>
              </div>
            </div>
          </div>
          <div class="table-box">
            <div class="table-box__header">
              <div class="table-box__header-block">
                <div class="table-filter"></div>
              </div>
            </div>
            <template v-if="items && items.items && items.items.length">
              <table class="table-box__table">
                <thead class="table-box__thead">
                <tr class="table-box__thead-tr">
                  <template v-for="col in baseCols">
                    <th class="table-box__th">
                      <span class="table-box__thead-item table-box__thead-item_center">
                        <span class="table-box__thead-title">
                          {{ col.label }}
                        </span>
                      </span>
                    </th>
                  </template>
                  <template v-for="col in monthsFilter">
                    <th class="table-box__th" v-if="renderMonth.includes(col.key)">
                      <span class="table-box__thead-item table-box__thead-item_center">
                        <span class="table-box__thead-title">
                          {{ col.label }}
                        </span>
                      </span>
                    </th>
                  </template>
                  <th class="table-box__th">
                    <span class="table-box__thead-item table-box__thead-item_center">
                      <span class="table-box__thead-title">
                        Общий
                      </span>
                    </span>
                  </th>
                </tr>
                </thead>
                <tbody class="table-box__tbody">
                  <tr v-for="item in items.items" :key="item.settlement.id" class="table-box__tr table-data">
                    <td class="table-box__td table-box__td_center">
                      <span class="table-data__text">
                        {{ items.year }}
                      </span>
                    </td>
                    <td class="table-box__td table-box__td_center">
                      <span class="table-data__text">
                        {{ item.settlement.district_expand.region_expand.name }}
                      </span>
                    </td>
                    <td class="table-box__td table-box__td_center">
                      <span class="table-data__text">
                        {{ item.settlement.district_expand.name }}
                      </span>
                    </td>
                    <td class="table-box__td table-box__td_center">
                      <span class="table-data__text">
                        {{ item.settlement.name }}
                      </span>
                    </td>
                    <template v-for="month in monthsFields">
                      <td class="table-box__td table-box__td_center" v-if="renderMonth.includes(month.label)">
                        <span class="table-data__result">
                          <span class="table-data__result-bracket">{</span>
                          <span class="table-data__result-icon">₴</span>
                          <span class="table-data__result-value">{{ getItemMonth(item, month.label).month_amount }}</span>
                          <span class="table-data__result-divide"></span>
                          <span class="table-data__result-icon"><i class="fal fa-users"></i></span>
                          <template v-if="getItemMonth(item, month.label).subscriber_connections_count > 0">
                            <router-link :to="generate_link(
                                getAnalyticReportLinkObjectType(),
                                getAnalyticReportLinkParams(item, month.key, 'settlementMonthItem'))">
                              <span class="table-data__result-value table-data__link">
                                {{ getItemMonth(item, month.label).subscriber_connections_count }}
                              </span>
                            </router-link>
                          </template>
                          <template v-else>
                            <span class="table-data__result-value">
                              {{ getItemMonth(item, month.label).subscriber_connections_count }}
                            </span>
                          </template>
                          <span class="table-data__result-divide"></span>
                          <span class="table-data__result-icon"><i class="fal fa-wallet"></i></span>
                          <template v-if="getItemMonth(item, month.label).paid_subscriber_connections_count > 0">
                            <router-link :to="generate_link(
                                getAnalyticReportLinkObjectType(),
                                getAnalyticReportLinkParams(item, month.key, 'settlementMonthItem', true))">
                              <span class="table-data__result-value table-data__link">
                                {{ getItemMonth(item, month.label).paid_subscriber_connections_count }}
                              </span>
                            </router-link>
                          </template>
                          <template v-else>
                            <span class="table-data__result-value">
                              {{ getItemMonth(item, month.label).paid_subscriber_connections_count }}
                            </span>
                          </template>
                          <template v-if="getItemMonth(item, month.label).hasOwnProperty('solvency')">
                            <span class="table-data__result-divide"></span>
                            <span class="table-data__result-icon"><i class="fal fa-percent"></i></span>
                            <span class="table-data__result-value">{{ getItemMonth(item, month.label).solvency }}</span>
                          </template>
                          <span class="table-data__result-bracket">}</span>
                        </span>
                      </td>
                    </template>
                    <!--Общий-->
                    <td class="table-box__td table-box__td_center">
                      <span class="table-data__result">
                        <span class="table-data__result-bracket">{</span>
                        <span class="table-data__result-icon">₴</span>
                        <span class="table-data__result-value">{{ item.total.total_month_amount }}</span>
                        <template v-if="item.total.hasOwnProperty('total_solvency')">
                          <span class="table-data__result-divide"></span>
                          <span class="table-data__result-icon"><i class="fal fa-percent"></i></span>
                          <span class="table-data__result-value">{{ item.total.total_solvency }}</span>
                        </template>
                        <span class="table-data__result-bracket">}</span>
                      </span>
                    </td>
                    <!--Общий-->
                  </tr>
                  <tr class="table-box__tr table-data">
                  <td class="table-box__td">
                    <span class="table-data__text table-data__text_bold">
                      Итого:
                    </span>
                  </td>
                  <td class="table-box__td table-box__td_center">
                    <span class="table-data__text">
                      Все
                    </span>
                  </td>
                  <td class="table-box__td table-box__td_center">
                    <span class="table-data__text">
                      Все
                    </span>
                  </td>
                  <td class="table-box__td table-box__td_center">
                    <span class="table-data__text">
                      Все
                    </span>
                  </td>
                  <template v-for="totalMonth in totalMonthsFields">
                    <td class="table-box__td table-box__td_center" v-if="renderMonth.includes(totalMonth.label)">
                      <span class="table-data__result">
                        <span class="table-data__result-bracket">{</span>
                        <span class="table-data__result-icon">₴</span>
                        <span class="table-data__result-value">{{ getItemMonth(items, totalMonth.label).total_month_amount }}</span>
                        <span class="table-data__result-divide"></span>
                        <span class="table-data__result-icon"><i class="fal fa-users"></i></span>
                        <template v-if="getItemMonth(items, totalMonth.label).total_subscriber_connections_count > 0">
                            <router-link :to="generate_link(
                                getAnalyticReportLinkObjectType(),
                                getAnalyticReportLinkParams(items, totalMonth.key, 'totalMonthItem'))">
                              <span class="table-data__result-value table-data__link">
                                {{ getItemMonth(items, totalMonth.label).total_subscriber_connections_count }}
                              </span>
                            </router-link>
                          </template>
                        <template v-else>
                          <span class="table-data__result-value">
                            {{ getItemMonth(items, totalMonth.label).total_subscriber_connections_count }}
                          </span>
                        </template>
                        <span class="table-data__result-divide"></span>
                        <span class="table-data__result-icon"><i class="fal fa-wallet"></i></span>
                        <template v-if="getItemMonth(items, totalMonth.label).total_paid_subscriber_connections_count > 0">
                          <router-link :to="generate_link(
                              getAnalyticReportLinkObjectType(),
                              getAnalyticReportLinkParams(items, totalMonth.key, 'totalMonthItem', true))">
                            <span class="table-data__result-value table-data__link">
                              {{ getItemMonth(items, totalMonth.label).total_paid_subscriber_connections_count }}
                            </span>
                          </router-link>
                        </template>
                        <template v-else>
                          <span class="table-data__result-value">
                            {{ getItemMonth(items, totalMonth.label).total_paid_subscriber_connections_count }}
                          </span>
                        </template>
                        <template v-if="getItemMonth(items, totalMonth.label).hasOwnProperty('total_solvency')">
                          <span class="table-data__result-divide"></span>
                          <span class="table-data__result-icon"><i class="fal fa-percent"></i></span>
                          <span class="table-data__result-value">{{ getItemMonth(items, totalMonth.label).total_solvency }}</span>
                        </template>
                        <span class="table-data__result-bracket">}</span>
                      </span>
                    </td>
                  </template>
                  <!--Общий-->
                  <td class="table-box__td table-box__td_center">
                    <span class="table-data__result">
                      <span class="table-data__result-bracket">{</span>
                      <span class="table-data__result-icon">₴</span>
                      <span class="table-data__result-value">{{ items.total.total_month_amount }}</span>
                      <template v-if="items.total.hasOwnProperty('total_solvency')">
                        <span class="table-data__result-divide"></span>
                        <span class="table-data__result-icon"><i class="fal fa-percent"></i></span>
                        <span class="table-data__result-value">{{ items.total.total_solvency }}</span>
                      </template>
                      <span class="table-data__result-bracket">}</span>
                    </span>
                  </td>
                  <!--Общий-->
                </tr>
                </tbody>
              </table>
            </template>
            <!--<form-error v-bind:value="errors.non_field_errors"></form-error>-->
          </div>
        </div>
        <!--END BODY PAGE -->
      </div>
    </div>
  </section>
</template>

<script>
import ReportMenu from './menu/menu.vue';
import ReportLink from "./sub_components/report-link";
import {API} from "../../../../api";
import {RouteHelperMixin} from "../../../../mixins/admin/route_helper";
import {AddressMixin} from "../../../../mixins/admin/address";
import FormErrors from "../form/errors";
import {LinksMixin} from "../../../../mixins/admin/links";
import {monthsFilter} from "../../../../../config/constants";

export default {
  name: "analytics",
  components: {
    ReportLink,
    "report-menu": ReportMenu,
    "form-error": FormErrors,
  },
  mixins: [RouteHelperMixin, AddressMixin, LinksMixin, ],
  data() {
    return {
      baseCols: [
        {
          field: 'year_number',
          label: 'Год'
        },
        {
          field: 'region_name',
          label: 'Область'
        },
        {
          field: 'district_name',
          label: 'Район'
        },
        {
          field: 'settlement_name',
          label: 'Населенный пункт'
        },
      ],
      monthsFilter: monthsFilter,
      years: [],
      paymentTypes: [],
      reportData: {
        year: null,
        month: null,
        region: "",
        district: "",
        settlement: "",
        payment_type: null,
        count_solvency: true,
      },
      items: [],
      renderMonth: [],
      errors: {}
    }
  },
  created() {
    this.loader_show();
    this.loadAnalyticReportYears();
    this.loadAnalyticReportPaymentTypes();
    this.load_regions();
  },
  computed: {
    monthsFields() {
      let prefix = "month_";
      let months = [];
      for (let number of [1,2,3,4,5,6,7,8,9,10,11,12]) {
          let month = {
            key: number,
            label: prefix + number,
          };
          months.push(month);
      }
      return months
    },
    totalMonthsFields() {
      let prefix = "total_month_";
      let months = [];
      for (let number of [1,2,3,4,5,6,7,8,9,10,11,12]) {
          let month = {
            key: number,
            label: prefix + number,
          };
          months.push(month);
      }
      return months
    },
  },
  methods: {
    getAnalyticReportLinkObjectType() {
      return "subscribers"
    },
    getAnalyticReportLinkParams(item, month, linkType, isServicePaid=false) {
      let self = this;
      let baseParams = "?analytic_report=true"

      if(linkType === "settlementMonthItem") {
        // year + month + settlement + isServicePaid(required=false) + payment_types
        baseParams += "&analytic_report_year=" + self.items.year +
            "&analytic_report_month=" + month +
            "&analytic_report_settlement=" + item.settlement.id +
            "&analytic_report_is_service_paid=" + isServicePaid

        if(self.reportData.payment_types && self.reportData.payment_types.length > 0){
          baseParams += "&analytic_report_payment_types=" + self.reportData.payment_types
        }
      } else if(linkType === "totalMonthItem") {
        // year + total + month + isServicePaid(required=false) + payment_types + region + district + settlement
        baseParams += "&analytic_report_year=" + self.items.year +
            "&analytic_report_month=" + month +
            "&analytic_report_is_service_paid=" + isServicePaid

        if(self.reportData.payment_types && self.reportData.payment_types.length > 0){
          baseParams += "&analytic_report_payment_types=" + self.reportData.payment_types
        }
        if(self.reportData.region){
          baseParams += "&analytic_report_region=" + self.reportData.region
          if(self.reportData.district){
            baseParams += "&analytic_report_district=" + self.reportData.district
            if(self.reportData.settlement){
              baseParams += "&analytic_report_settlement=" + self.reportData.settlement
            }
          }
        }
      }

      return baseParams
    },
    renderMonths() {
      let self = this;
      self.renderMonth = [];

      if(self.reportData.month) {
        self.renderMonth.push(self.reportData.month);
        self.renderMonth.push("month_" + self.reportData.month);
        self.renderMonth.push("total_month_" + self.reportData.month);
      } else {
        for (let number of [1,2,3,4,5,6,7,8,9,10,11,12]) {
          self.renderMonth.push(number);
          self.renderMonth.push("month_" + number);
          self.renderMonth.push("total_month_" + number);
        }
      }
    },
    getItemMonth(item, month) {
      let self = this;
      if (item[month]) {
        return item[month]
      } else {
        let defaultMonthItem = {
          month_amount: 0,
          subscriber_connections_count: 0,
          paid_subscriber_connections_count: 0,
          total_month_amount: 0,
          total_subscriber_connections_count: 0,
          total_paid_subscriber_connections_count: 0,
        }

        if (self.reportData.count_solvency) {
          defaultMonthItem['solvency'] = 0;
          defaultMonthItem['total_solvency'] = 0;
        }

        return defaultMonthItem
      }
    },
    loadAnalyticReportYears() {
      let self = this;

      API.get('/report/analytic/years/')
        .then(function (response) {
          self.years = response.data;
        })
        .catch(function (error) {
          API.default_catch(self, error)
        })
        .then(function () {
          API.default_always(self);
        })
    },
    loadAnalyticReportPaymentTypes() {
      let self = this;

      API.get('/report/analytic/payment-types/')
        .then(function (response) {
          self.paymentTypes = response.data;
        })
        .catch(function (error) {
          API.default_catch(self, error)
        })
        .then(function () {
          API.default_always(self);
        })
    },
    generateAnalyticReport() {
      let self = this;
      self.loader_show();
      self.errors = {};
      self.items = [];

      let body = self.reportData;
      self.renderMonths();

      body["months"] = [];
      if(body["month"]) {
        body["months"].push(body["month"]);
      }

      body["payment_types"] = []
      if(body["payment_type"]) {
        body["payment_types"].push(body["payment_type"]);
      }

      API.post('/report/analytic/', body)
        .then(function (response) {
          self.items = response.data;
        })
        .catch(function (error) {
          API.default_catch(self, error)
          if('non_field_errors' in self.errors) {
            self.alert("Ошибка", self.errors['non_field_errors'][0]);
          }
        })
        .then(function () {
          API.default_always(self);
        })
    }
  },
  watch: {
    'reportData.region': {
      handler: function(value) {
        if(value === "") {
          this.reportData.district = "";
          this.reportData.settlement = "";
        }
        this.load_districts(value, false);
      },
    },
    'reportData.district': {
      handler: function(value) {
        if(value === "") {
          this.reportData.settlement = "";
        }
        this.load_settlements(value);
      },
    },
    'years': {
      handler: function(value) {
        this.reportData.year = value[0].year;
      },
    },
  }
}
</script>

<style scoped>

</style>