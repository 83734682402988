<template>
  <div class="dashboard-main">
    <div class="dashboard-main__item dashboard-main__item_charts dashboard-main__item_nh">
      <div class="dashboard-main__head">
        <div class="dashboard-main__title">Диманика трафика сети</div>
        <div class="dashboard-main__filters">
          <div class="dashboard-main__filters-group">
            <div class="dashboard-main__filters-select">
              <select required>
                <option>Все отделы</option>
                <option>AGRONET</option>
                <option>ARNET</option>
                <option>BELNET</option>
              </select>
            </div>
            <div class="dashboard-main__filters-select">
              <select required>
                <option>24 часа</option>
                <option>7 дней</option>
                <option>30 дней</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="dashboard-charts">
        <div class="dashboard-charts__set">
          vue-chartjs
        </div>
        <div class="dashboard-charts__result">
          <div class="dashboard-charts__head">
            <div class="dashboard-charts__item dashboard-charts__item_h">
              <span class="dashboard-charts__title dashboard-charts__title_h">Трафик по всей сети:</span>
                  <span class="dashboard-charts__total dashboard-charts__total_h">
                  <span class="dashboard-charts__total-icon"><i class="fal fa-arrow-to-bottom"></i></span>
                  <span class="dashboard-charts__total-count">250 GB</span>
                  <span class="dashboard-charts__total-divide"></span>
                  <span class="dashboard-charts__total-icon"><i class="fal fa-arrow-from-bottom"></i></span>
                  <span class="dashboard-charts__total-count">125 GB</span>
              </span>
            </div>
          </div>
          <ul class="dashboard-charts__list">
            <li class="dashboard-charts__item">
              <span class="dashboard-charts__title">AGRONET</span>
              <span class="dashboard-charts__total">
                <span class="dashboard-charts__total-icon"><i class="fal fa-arrow-to-bottom"></i></span>
                <span class="dashboard-charts__total-count">250 GB</span>
                <span class="dashboard-charts__total-divide"></span>
                <span class="dashboard-charts__total-icon"><i class="fal fa-arrow-from-bottom"></i></span>
                <span class="dashboard-charts__total-count">125 GB</span>
              </span>
            </li>
            <li class="dashboard-charts__item">
              <span class="dashboard-charts__title">AGRONET</span>
              <span class="dashboard-charts__total">
                <span class="dashboard-charts__total-icon"><i class="fal fa-arrow-to-bottom"></i></span>
                <span class="dashboard-charts__total-count">250 GB</span>
                <span class="dashboard-charts__total-divide"></span>
                <span class="dashboard-charts__total-icon"><i class="fal fa-arrow-from-bottom"></i></span>
                <span class="dashboard-charts__total-count">125 GB</span>
              </span>
            </li>
            <li class="dashboard-charts__item">
              <span class="dashboard-charts__title">AGRONET</span>
              <span class="dashboard-charts__total">
                <span class="dashboard-charts__total-icon"><i class="fal fa-arrow-to-bottom"></i></span>
                <span class="dashboard-charts__total-count">250 GB</span>
                <span class="dashboard-charts__total-divide"></span>
                <span class="dashboard-charts__total-icon"><i class="fal fa-arrow-from-bottom"></i></span>
                <span class="dashboard-charts__total-count">125 GB</span>
              </span>
            </li>
            <li class="dashboard-charts__item">
              <span class="dashboard-charts__title">AGRONET</span>
              <span class="dashboard-charts__total">
                <span class="dashboard-charts__total-icon"><i class="fal fa-arrow-to-bottom"></i></span>
                <span class="dashboard-charts__total-count">250 GB</span>
                <span class="dashboard-charts__total-divide"></span>
                <span class="dashboard-charts__total-icon"><i class="fal fa-arrow-from-bottom"></i></span>
                <span class="dashboard-charts__total-count">125 GB</span>
              </span>
            </li>
            <li class="dashboard-charts__item">
              <span class="dashboard-charts__title">AGRONET</span>
              <span class="dashboard-charts__total">
                <span class="dashboard-charts__total-icon"><i class="fal fa-arrow-to-bottom"></i></span>
                <span class="dashboard-charts__total-count">250 GB</span>
                <span class="dashboard-charts__total-divide"></span>
                <span class="dashboard-charts__total-icon"><i class="fal fa-arrow-from-bottom"></i></span>
                <span class="dashboard-charts__total-count">125 GB</span>
              </span>
            </li>
            <li class="dashboard-charts__item">
              <span class="dashboard-charts__title">AGRONET</span>
              <span class="dashboard-charts__total">
                <span class="dashboard-charts__total-icon"><i class="fal fa-arrow-to-bottom"></i></span>
                <span class="dashboard-charts__total-count">250 GB</span>
                <span class="dashboard-charts__total-divide"></span>
                <span class="dashboard-charts__total-icon"><i class="fal fa-arrow-from-bottom"></i></span>
                <span class="dashboard-charts__total-count">125 GB</span>
              </span>
            </li>
            <li class="dashboard-charts__item">
              <span class="dashboard-charts__title">AGRONET</span>
              <span class="dashboard-charts__total">
                <span class="dashboard-charts__total-icon"><i class="fal fa-arrow-to-bottom"></i></span>
                <span class="dashboard-charts__total-count">250 GB</span>
                <span class="dashboard-charts__total-divide"></span>
                <span class="dashboard-charts__total-icon"><i class="fal fa-arrow-from-bottom"></i></span>
                <span class="dashboard-charts__total-count">125 GB</span>
              </span>
            </li>
            <li class="dashboard-charts__item">
              <span class="dashboard-charts__title">AGRONET</span>
              <span class="dashboard-charts__total">
                <span class="dashboard-charts__total-icon"><i class="fal fa-arrow-to-bottom"></i></span>
                <span class="dashboard-charts__total-count">250 GB</span>
                <span class="dashboard-charts__total-divide"></span>
                <span class="dashboard-charts__total-icon"><i class="fal fa-arrow-from-bottom"></i></span>
                <span class="dashboard-charts__total-count">125 GB</span>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "dashboard-traffic"
}
</script>

<style scoped>

</style>