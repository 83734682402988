<template>
    <popup :settings="settings">
        <div slot="title">Удалить NAS?</div>
        <div slot="content">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="modal__content">
                    <div class="info-alarm">
                        <i class="fal fa-exclamation-circle"></i>
                        <p>
                            Вы собираетесь удалить NAS. После удаление данный NAS будет недоступен! Вы даете отчет
                            своим действиям?
                            Также помните! Все ваши действия фиксируются в системе!
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer">
            <a href="#" class="modal__btn">Удалить</a>
            <a href="#" @click="popup_close">Отмена</a>
        </div>
    </popup>
</template>

<script>
    import Popup from '../modal/popup.vue'
    import {PopupMixin} from "../../../../mixins/admin/popup";

    export default {
        name: "nas-delete-popup",
        props: ["settings"],
        mixins: [
            PopupMixin
        ],
        components: {
            "popup": Popup
        },
    }
</script>

<style scoped>

</style>