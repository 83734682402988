export var ServiceListMixin = {
    methods: {
        has_perm_count_subscriber_connections(item) {
            var department = "";

            if(item.department != null){
                department = item.department;
            }

            // return (this.has_perm('subscribers_access') && this.has_perm_departments(department, "is_department_access"))
            return this.has_perm('subscribers_access');
        },
    }
};