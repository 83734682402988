<template>
    <div>
        <template v-for="(children_item, index) in children_list">
            <div class="add-data__item">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                        <label><i class="fal fa-birthday-cake"></i> Дата рождения ребенка: <span
                                class="control"><i
                                class="fas fa-star-of-life"></i></span></label>
                    </div>
                    <div class="col-xs-11 col-sm-11 col-md-5 col-lg-7">
                        <!--<input type="text" v-model="children_list[index]" placeholder="10.10.1990">-->
                        <date-picker v-model="children_list[index]" :lang="'ru'" :format="'DD-MM-YYYY'" :disabled="readonly"></date-picker>
                    </div>
                    <template v-if="index === 0">
                        <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1" v-if="!readonly">
                            <span class="add-btn" @click="add_children"><i class="fal fa-plus"></i></span>
                        </div>
                    </template>
                    <template v-else>
                        <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1" v-if="!readonly">
                            <span class="remove-btn" @click="remove_children(index)"><i class="fal fa-minus"></i></span>
                        </div>
                    </template>
                    <div class="clearfix"></div>
                    <form-error v-bind:value="errors"></form-error>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import FormErrors from '../../form/errors.vue';
    import DatePicker from 'vue2-datepicker';

    export default {
        name: "children-item",
        props: ["children_input", "errors", "readonly"],
        data() {
            var children_list = this.convert_dates(this.children_input);
            // Add first element
            // children_list = this.add_first(children_list);

            return {
                loaded: false,
                children_list: children_list
            }
        },
        methods: {
            // set_children() {
            //     var children_list = [];
            //     for (var item in this.children_input) {
            //         if (this.children_input !== "") {
            //             children_list.push(Object.assign("", this.children_input[item]))
            //         }
            //     }
            // },
            add_children() {
                this.children_list.push("");
            },
            remove_children(key) {
                this.children_list.splice(key, 1);
            },
            convert_dates(value){
                var children_list = [];
                for (var key in value) {
                    if(value[key] !== "") {
                        children_list[key] = this.$moment(value[key]).format("YYYY-MM-DD");
                    }
                }
                children_list = this.add_first(children_list);
                return children_list
            },
            add_first(children_list){
                if (children_list.length === 0) {
                    children_list = [""]
                }
                return children_list
            }
        },
        components: {
            "form-error": FormErrors,
            "date-picker": DatePicker,
        },
        watch: {
            children_input: function (value) {
                if(value !== null && !this.loaded) {
                    this.children_list = this.convert_dates(value);
                    // this.children_list = this.add_first(this.children_list);
                    this.loaded = true;
                }
            },
            children_list: function (value) {
                this.$emit("set_children", this.convert_dates(value));
            }
        }
    }
</script>

<style scoped>

</style>