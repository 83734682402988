<template>
    <div class="view-data-reports__item">
        <div class="view-data-reports__item-head"><i class="fal fa-server"></i>
            {{ title }} <span class="explanation">Ваших абонентов</span></div>

        <ul class="view-data-reports__item-list">
            <li v-for="agent in data.by_managers" >{{ agent.user__fio }}:
                <span class="value"><router-link :to="generate_link(agent.link_object_type, agent.link_params, {from_url: current_url()})" >
                    {{ agent.total_amount }}грн ({{ agent.total_count }} шт)</router-link>
                </span>
            </li>
            <li>Пополнение ваших абонентов: <span class="value">
                <router-link :to="generate_link(data.link_object_type, data.link_params, {from_url: current_url()})" >{{ data.total_amount }}грн ({{ data.total_subscribers }} абонентов)</router-link>
            </span>
            </li>
        </ul>
    </div>
</template>

<script>
    import {LinksMixin} from "../../../../../mixins/admin/links";

    export default {
        name: "departments-report-refill",
        props: ['title', 'data'],
        mixins: [LinksMixin]
    }
</script>

<style scoped>

</style>