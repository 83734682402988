<template>
    <span>
        <template v-if="check()">
            <router-link :to="generate_link(get_link_object_type(), get_link_params(), {from_url: current_url()})">
                <span class="table-data__link">
                    {{ item[field_name] }}
                </span>
            </router-link>
        </template>
        <template v-else>
            <span class="table-data__text">
            {{ item[field_name] }}
            </span>
        </template>
    </span>
</template>

<script>
    import {LinksMixin} from "../../../../../mixins/admin/links";

    export default {
        name: "report-link",
        props: ['item', 'field_name', 'link_object_type', 'link_params'],
        mixins: [LinksMixin],
        methods: {
            check(){
                //custom logic for some reports type
                if(['average_payment', 'arpu', 'arpu_physical'].includes(this.field_name)){ return false }

                var value = this.item[this.field_name];

                if(Number(value) === value){
                    if(value === 0) {
                        return false
                    }
                }
                return true
            },
            get_link_object_type(){
                if(this.link_object_type){
                    return this.link_object_type
                } else if(this.item[this.field_name + '_link_object_type']) {
                    return this.item[this.field_name + '_link_object_type']
                } else {
                    return this.item['link_object_type']
                }
            },
            get_link_params(){
                let params = "";

                if (this.field_name === 'department_name') {
                    params += '?department=' + this.item.department
                    return params
                }
                if (this.item[this.field_name + '_link_params']) {
                    params = this.item[this.field_name + '_link_params']
                }
                if(this.link_params){
                    params += this.link_params
                }
                return params
            }
        }
    }
</script>

<style scoped>

</style>