<template>
    <div class="grid">
        <div class="grid__col">
            <div class="grid__box">
                <div class="table-box">
                    <div class="table-box__header">
                        <div class="table-box__header-block">
                            <div class="vdate">
                                <date-range-picker
                                    :startDate="startDate"
                                    :endDate="endDate"
                                    @update="update_range_picker"
                                    :locale-data="locale" >
                                </date-range-picker>
                            </div>
                        </div>
                    </div>
                    <table class="table-box__table">
                        <thead class="table-box__thead">
                        <tr class="table-box__thead-tr">
                            <!--Номер-->
                            <th class="table-box__th">
                                <span class="table-box__thead-item table-box__thead-item_center">
                                     <span class="table-box__thead-title">№</span>
                                </span>
                            </th>
                            <!--Логин-->
                            <th class="table-box__th">
                                <span class="table-box__thead-item table-box__thead-item_center">
                                     <span class="table-box__thead-title">Логин</span>
                                </span>
                            </th>
                            <!--NAS IP-->
                            <th class="table-box__th">
                                <span class="table-box__thead-item table-box__thead-item_center">
                                     <span class="table-box__thead-title">NAS IP</span>
                                </span>
                            </th>
                            <!--Start & Stop Time-->
                            <th class="table-box__th">
                                <span class="table-box__thead-item table-box__thead-item_center">
                                     <span class="table-box__thead-title">Start & Stop Time</span>
                                </span>
                            </th>
                            <!--Session Time-->
                            <th class="table-box__th">
                                <span class="table-box__thead-item table-box__thead-item_center">
                                     <span class="table-box__thead-title">Session Time</span>
                                </span>
                            </th>
                            <!--Calling Mac Address-->
                            <th class="table-box__th">
                                <span class="table-box__thead-item table-box__thead-item_center">
                                     <span class="table-box__thead-title">Calling Mac Address</span>
                                </span>
                            </th>
                            <!--PPPoE Server-->
                            <th class="table-box__th">
                                <span class="table-box__thead-item table-box__thead-item_center">
                                     <span class="table-box__thead-title">PPPoE Server</span>
                                </span>
                            </th>
                            <!--Framed Ip-->
                            <th class="table-box__th">
                                <span class="table-box__thead-item table-box__thead-item_center">
                                     <span class="table-box__thead-title">Framed Ip</span>
                                </span>
                            </th>
                            <!--Terminate Cause-->
                            <th class="table-box__th">
                                <span class="table-box__thead-item table-box__thead-item_center">
                                     <span class="table-box__thead-title">Terminate Cause</span>
                                </span>
                            </th>
                            <!--Download & Upload-->
                            <th class="table-box__th">
                                <span class="table-box__thead-item table-box__thead-item_center">
                                     <span class="table-box__thead-title">Download & Upload</span>
                                </span>
                            </th>
                        </tr>
                        </thead>
                        <tbody class="table-box__tbody">
                        <tr class="table-box__tr table-data" v-for="record in records">
                            <!--Номер-->
                            <td class="table-box__td table-box__td_center">
                                    <span class="table-data__text">
                                       {{ record.radius_number }}
                                    </span>
                            </td>
                            <!--Логин-->
                            <td class="table-box__td table-box__td_center">
                                    <span class="table-data__text">
                                       {{ record.login }}
                                    </span>
                            </td>
                            <!--NAS IP-->
                            <td class="table-box__td table-box__td_center">
                                    <span class="table-data__text">
                                       {{ record.nas_ip }}
                                    </span>
                            </td>
                            <!--Start & Stop Time-->
                            <td class="table-box__td table-box__td_center">
                                    <span class="table-data__text">
                                       {{ record.date_start | moment("DD.MM.YYYY HH:mm:ss") }}<br>{{ record.date_end | moment("DD.MM.YYYY HH:mm:ss") }}
                                    </span>
                            </td>
                            <!--Session Time-->
                            <td class="table-box__td table-box__td_center">
                                    <span class="table-data__text">
                                       {{ record.session_time }}
                                    </span>
                            </td>
                            <!--Calling Mac Address-->
                            <td class="table-box__td table-box__td_center">
                                    <span class="table-data__text">
                                       {{ record.calling_mac_address }}
                                    </span>
                            </td>
                            <!--PPPoE Server-->
                            <td class="table-box__td table-box__td_center">
                                    <span class="table-data__text">
                                       {{ record.pppoe_server }}
                                    </span>
                            </td>
                            <!--Framed Ip-->
                            <td class="table-box__td table-box__td_center">
                                    <span class="table-data__text">
                                       {{ record.framed_ip }}
                                    </span>
                            </td>
                            <!--Terminate Cause-->
                            <td class="table-box__td table-box__td_center">
                                    <span class="table-data__text">
                                       {{ record.terminate_cause }}
                                    </span>
                            </td>
                            <!--Download & Upload-->
                            <td class="table-box__td table-box__td_center">
                                    <span class="table-data__text">
                                       {{ record.download | prettySize(2) }} | {{ record.upload | prettySize(2) }}
                                    </span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <!--<accordion>-->
        <!--<div slot="title-accordion"><i class="fal fa-server"></i> История активности</div>-->
        <!--<div slot="wrapper-accordion">-->
            <!--<div class="accordion__header">-->
                <!--<div class="row">-->
                    <!--<div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">-->
                        <!--<div class="filter">-->
                            <!--<date-range-picker-->
                                    <!--:startDate="startDate"-->
                                    <!--:endDate="endDate"-->
                                    <!--@update="update_range_picker"-->
                                    <!--:locale-data="locale" >-->
                            <!--</date-range-picker>-->
                        <!--</div>-->
                    <!--</div>-->
                <!--</div>-->
            <!--</div>-->
            <!--<div class="table-container">-->
               <!--<table>-->
                    <!--<thead>-->
                    <!--<tr>-->
                        <!--<th>№</th>-->
                        <!--<th>Login</th>-->
                        <!--<th>Nas IP</th>-->
                        <!--<th>Start & Stop Time</th>-->
                        <!--<th>Session Time</th>-->
                        <!--<th>Calling Mac Address</th>-->
                        <!--<th>PPPoE Server</th>-->
                        <!--<th>Framed Ip</th>-->
                        <!--<th>Terminate Cause</th>-->
                        <!--<th>Download & Upload</th>-->
                    <!--</tr>-->
                    <!--</thead>-->
                    <!--<tbody>-->
                    <!--<tr v-for="record in records">-->
                        <!--<td>{{ record.radius_number }}</td>-->
                        <!--<td>{{ record.login }}</td>-->
                        <!--<td>{{ record.nas_ip }}</td>-->
                        <!--<td>{{ record.date_start | moment("DD.MM.YYYY HH:mm:ss") }}<br>{{ record.date_end | moment("DD.MM.YYYY HH:mm:ss") }}</td>-->
                        <!--<td>{{ record.session_time }}</td>-->
                        <!--<td>{{ record.calling_mac_address }}</td>-->
                        <!--<td>{{ record.pppoe_server }}</td>-->
                        <!--<td>{{ record.framed_ip }}</td>-->
                        <!--<td>{{ record.terminate_cause }}</td>-->
                        <!--<td>{{ record.download | prettySize(2) }} | {{ record.upload | prettySize(2) }} </td>-->
                    <!--</tr>-->
                    <!--</tbody>-->
                <!--</table>-->
            <!--</div>-->
        <!--</div>-->
    <!--</accordion>-->
</template>

<script>
    import {API} from "../../../../api";
    import {SubscriberConnectionMixin} from "../../../../mixins/admin/subscriber_connection";
    import {SubscriberConnectionAccordionMixin} from "../../../../mixins/admin/subscriber_connection_accordion";

    export default {
        name: "activity-history",
        props: ['subscriber_connection'],
        mixins: [SubscriberConnectionMixin, SubscriberConnectionAccordionMixin],
        data: function () {
            return {
                filter: {
                    subscriber_connection: "",
                    date_start_gte: "",
                    date_start_lte: "",
                },
                records: [],
                showSubAccordion: false,
            }
        },
        methods: {
            load() {
                var self = this;

                this.subscriber_connections_sessions(this.filter).
                    then(function (response) {
                        self.records = response.data.results;
                    })
                    .catch(function (error) {
                        API.default_catch(self, error);
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            },
            update_range_picker(date_range){
                this.filter.date_start_gte = this.$moment(date_range.startDate).format("YYYY-MM-DD");
                this.filter.date_start_lte = this.$moment(date_range.endDate).format("YYYY-MM-DD");
            }
        },
        watch: {
            subscriber_connection: function (value) {
                this.filter.subscriber_connection = value.id;
            },
            filter: {
                handler(value) {
                    this.load();
                },
                deep: true
            }
        }
    }
</script>

<style scoped>

</style>