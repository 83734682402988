<template>
    <div>
        <div class="add-data__header">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h3>Представители:</h3>
                </div>
            </div>
        </div>
        <template v-for="(member, index) in members">

            <div class="add-data__item" v-if="index !== 0">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-10 col-lg-11">
                        <label>Удалить представителя: </label>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1">
                        <span class="add-btn" @click="remove_member()"><i class="fal fa-minus"></i></span>
                    </div>

                    <div class="clearfix"></div>
                </div>
            </div>

            <div class="add-data__item">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                        <label>Представитель по умолчанию: </label>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                        <label class="checkbox">
                            <input v-model="member.is_default" @change="is_default_change(index)" type="checkbox">
                            <span class="checkmark"></span>
                        </label>
                    </div>

                    <div class="clearfix"></div>
                </div>
            </div>
            <div class="add-data__item">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                        <label>ФИО: <span class="control"><i class="fas fa-star-of-life"></i></span></label>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                        <input type="text" v-model="member.fio" placeholder="ФИО">
                    </div>
                    <div class="clearfix"></div>
                    <form-error v-bind:value="get_error(index, 'fio')"></form-error>
                </div>
            </div>
            <div class="add-data__item">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                        <label>Должность: <span class="control"><i class="fas fa-star-of-life"></i></span></label>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                        <input type="text" v-model="member.position" placeholder="Должность">
                    </div>

                    <div class="clearfix"></div>
                    <form-error v-bind:value="get_error(index, 'position')"></form-error>
                </div>
            </div>
            <div class="add-data__item">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                        <label>Телефон: <span
                                class="control"><i
                                class="fas fa-star-of-life"></i></span></label>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                        <input type="text" v-model="member.phone" placeholder="Телефон">
                    </div>

                    <div class="clearfix"></div>
                    <form-error v-bind:value="get_error(index, 'phone')"></form-error>
                </div>
            </div>
            <div class="add-data__item">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                        <label>Email: <span
                                class="control"><i
                                class="fas fa-star-of-life"></i></span></label>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                        <input type="text" v-model="member.email" placeholder="email">
                    </div>

                    <div class="clearfix"></div>
                    <form-error v-bind:value="get_error(index, 'email')"></form-error>
                </div>
            </div>
        </template>

        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-10 col-lg-11">
                    <label>Добавить представителя: </label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1">
                    <span class="add-btn" @click="add_member()"><i class="fal fa-plus"></i></span>
                </div>

                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import FormErrors from '../../form/errors.vue';

    export default {
        name: "business-details-members",
        props: ['business_details_members', 'errors'],
        data(){
            return {
                members: [],
                member: {
                    fio: "",
                    position: "",
                    phone: "",
                    email: "",
                    is_default: false,
                }
            }
        },
        mounted(){
            if(this.business_details_members !== undefined){
                this.members = this.business_details_members;
            } else {
                this.members = [];
                this.add_member();
                // Set by default first checkbox
                this.members[0].is_default = true;
            }
        },
        components: {
            "form-error": FormErrors
        },
        methods: {
            is_default_change(index){
                if(this.members[index].is_default){
                    for(var i in this.members){
                        if(parseInt(i) !== parseInt(index)){
                            this.members[i].is_default = false;
                        }
                    }
                }
            },
            get_error(index, name){
                if(this.errors.subscriber_business_details.business_details_members &&
                   this.errors.subscriber_business_details.business_details_members[index]) {
                    return this.errors.subscriber_business_details.business_details_members[index][name];
                }
            },
            add_member() {
                this.members.push(Object.assign({}, this.member));
            },
            remove_member(key) {
                if(this.members.length !== 1) {
                    this.members.splice(key, 1);
                }
            }
        },
        watch: {
            members: {
                handler(value) {
                    this.$emit("update_members", value);
                },
                deep: true
            },
            business_details_members: {
                handler(value) {
                    if(this.business_details_members !== undefined){
                        this.members = this.business_details_members;
                    }
                },
                deep: true
            },
        }
    }
</script>

<style scoped>

</style>