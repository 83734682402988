<template>
    <td class="table-subscribers__td">
        <label class="checkbox">
            <input type="checkbox" v-bind:value="get_value()" v-on:change="$emit('input', $event.target.value)">
            <span class="checkmark"></span>
        </label>
    </td>
</template>

<script>
    export default {
        name: "td_record_check",
        props: ['value'],
        // props: ["subscriber", "subscriber_connection"],
        // data(){
        //     return {
        //         "checked": false
        //     }
        // },
        methods: {
            get_value(){
                var data = {subscriber_id: undefined, subscriber_connection_id: undefined};
                if(this.subscriber){
                    data.subscriber_id = this.subscriber.id;
                }
                if(this.subscriber_connection){
                    data.subscriber_connection_id = this.subscriber_connection.id;
                }
                return data
            }
        },
        watch: {
            // checked(value){
            //     if(value) {
            //         this.$emit("add_record_checked", value);
            //     } else {
            //         this.$emit("remove_record_checked", value);
            //     }
            // }
        }

    }
</script>

<style scoped>

</style>