export var ReportsMixin = {
    methods: {
        get_report_page_perms(){
            return {
                'reports-page': {'perms': 'reports_access'},
                'reports-finance': {'perms': 'reports_finances_access'},
                'reports-subscribers': {'perms_departments': 'is_report_subscriber'},
                'reports-nas': {'perms': 'reports_nas_access'},
                'reports-settlements': {'perms': 'reports_settlements_access'},
                'reports-tariffs': {'perms': 'reports_tariffs_access'},
                'reports-departments': {'perms_departments': 'is_report_department_partial,is_report_department_full'},
                'reports-services': {'perms_departments': 'is_report_service'},
                'reports-network': {'perms_departments': 'is_report_network'},
                'reports-subscribers-leak': {'perms_departments': 'is_report_subscribers_leak'},
                'reports-analytics': {'perms': 'reports_analytic_access'},
            }
        },
        showing_menu_item(page_name){
            // perms key means there isn't ability to generate report with
            // user's department accessibility
            // perms_departments key means vice versa logic

            if(this.report_page_perms[page_name].perms) {
                return this.has_perm(this.report_page_perms[page_name].perms)
            }

            if(this.report_page_perms[page_name].perms_departments) {
                return this.has_perm_departments("", this.report_page_perms[page_name].perms_departments);
            }
        }
    }
};