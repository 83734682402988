<template>
  <div class="page-login">
    <div class="page-login__overlay"></div>
    <div class="page-login__box">
      <div class="page-login__container">
        <div class="page-login__logo">
          <span class="logo-logo-bennu-big"></span>
        </div>
        <div class="page-login__action">
            <template v-if="!is_auth()">
              <g-signin-button
                      :params="googleSignInParams"
                      @success="onGoogleSignInSuccess"
                      @error="onSignInError">
                      <span class="button"><i class="fal fa-sign-in"></i> Вход в систему</span>
              </g-signin-button>
            </template>
            <template v-else>
                Вы авторизованы как `{{ user.fio }}`, <a href="#" @click="logout" >выйти?</a> или <router-link :to="{ name: 'dashboard-page' }">продолжить работу</router-link>
            </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import {SOCIAL_ACCOUNTS} from '../../../../config';

    export default {
        data: function () {
            return {
                need_login: true,
                googleSignInParams: {
                  client_id: SOCIAL_ACCOUNTS['providers']['google']['clientId'],
                  // prompt: "select_account",
                }
            };
        },
        methods: {
            fetchUser(){
                if(this.is_auth()){
                    this.$router.push({name: 'dashboard-page'});
                }
            },
            onGoogleSignInSuccess(resp) {
                var user = resp.getAuthResponse(true);
                this.$session.set('google_token', user.access_token);
                this.login_google()
            },
            onSignInError(error) {
                // `error` contains any error occurred.
                this.alert("Ошибка google авторизации", "Произошла ошибка авторизации, попробуйте повторить попытку");
            }
        },
    }
</script>