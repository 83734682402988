<template>
    <section class="page">
        <div class="page__inner">
            <div class="container-width">
                <div class="row">
                    <!-- Heading page -->
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="page-header">
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                                    <h1 class="page-header__title">
                                        <template v-if="item.id">
                                            Редактирование ( {{ item.fio }} )
                                        </template>
                                        <template v-else>
                                            Добавление сотрудника
                                        </template>
                                    </h1>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                    <div class="page-header__btn-group">
                                        <router-link title="Отмена"
                                                     :to="{ name: 'employees-list'}">
                                            <span class="btn grey"><i class="fal fa-times"></i> <span
                                                    class="title">Отмена</span></span>
                                        </router-link>

                                        <span class="btn blue"
                                              @click.prevent="save()"><i class="fal fa-save"></i> <span
                                                class="title">Сохранить</span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Content page -->
                    <form @submit.prevent="save()" class="add-data">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div class="page-body">
                                <div class="row">
                                    <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                                        <!--Фото-->
                                        <div class="add-data__photo">
                                            <file-base64
                                                    placeholder="Выберите фото"
                                                    class="avatar"
                                                    accept="image/png,image/jpeg,image/pjpeg"
                                                    image-class="v1-image"
                                                    input-class="v1-image"
                                                    :disableLink="true"
                                                    :defaultPreview="item.photo_expand"
                                                    :max-size="photoImageMaxSize"
                                                    @size-exceeded="onSizeExceeded"
                                                    @load="onPhotoLoad"/>
                                            <form-error v-bind:value="crud.errors.photo"></form-error>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
                                        <div class="add-data__header">
                                            <h3><i class="fal fa-address-card"></i> Персональные данные</h3>
                                        </div>
                                        <!--Добавление в отчетность-->
                                        <div class="add-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                                    <label><i class="fal fa-chart-bar"></i> В отчетности: </label>
                                                </div>
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                                    <label class="checkbox">
                                                        <input type="checkbox" v-model="item.add_to_reporting">
                                                        <span class="checkmark"></span>
                                                    </label>
                                                    <form-error
                                                            v-bind:value="crud.errors.add_to_reporting"></form-error>
                                                </div>
                                            </div>
                                        </div>
                                        <!--ФИО-->
                                        <div class="add-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                                    <label>
                                                        <i class="fal fa-user-tie"></i> ФИО:
                                                        <span class="control"><i
                                                                class="fas fa-star-of-life"></i></span>
                                                    </label>
                                                </div>
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                                    <input type="text" v-model="item.fio" placeholder="Введите ФИО">
                                                </div>
                                                <div class="clearfix"></div>
                                                <form-error v-bind:value="crud.errors.fio"></form-error>
                                            </div>
                                        </div>
                                        <!--Отдел-->
                                        <div class="add-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                                    <label>
                                                        <i class="fal fa-code-branch"></i> Отдел:
                                                        <span class="control"><i
                                                                class="fas fa-star-of-life"></i></span>
                                                    </label>
                                                </div>
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                                    <div class="selected">
                                                        <select v-model="item.employee_department" required>
                                                            <option value="" disabled hidden>Выберите отдел</option>
                                                            <option v-for="department in departments"
                                                                    v-bind:value="department.id">
                                                                {{ department.name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="clearfix"></div>
                                                <form-error v-bind:value="crud.errors.employee_department"></form-error>
                                            </div>
                                        </div>
                                        <!--Должность-->
                                        <div class="add-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                                    <label>
                                                        <i class="fal fa-industry-alt"></i> Должность:
                                                        <span class="control"><i
                                                                class="fas fa-star-of-life"></i></span>
                                                    </label>
                                                </div>
                                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                                    <div class="selected">
                                                        <select v-model="item.employee_position"
                                                                @change="employee_position_change" required>

                                                            <option value="" disabled hidden>Выберите должность
                                                            </option>
                                                            <option v-for="employee_position in employee_positions"
                                                                    v-bind:value="employee_position.id">
                                                                {{ employee_position.name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="clearfix"></div>
                                                <form-error v-bind:value="crud.errors.employee_position"></form-error>
                                            </div>
                                        </div>
                                        <!--Телефоны-->
                                        <phone-list
                                                :phone="item.phone"
                                                :phone_1="item.phone_1"
                                                :phone_2="item.phone_2"
                                                :phone_3="item.phone_3"
                                                :phone_4="item.phone_4"
                                                :phone_5="item.phone_5"
                                                :errors="crud.errors"
                                                @set_phone_single="set_phone_single"
                                                @set_phones_list="set_phones_list">
                                        </phone-list>
                                        <!--Адрес почты-->
                                        <div class="add-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                                                    <label>
                                                        <i class="fal fa-at"></i> Email:
                                                        <span class="control"><i
                                                                class="fas fa-star-of-life"></i></span>
                                                    </label>
                                                </div>
                                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                                                    <input type="text" v-model="item.email"
                                                           placeholder="Введите email">

                                                </div>
                                                <div class="clearfix"></div>
                                                <form-error v-bind:value="crud.errors.email"></form-error>
                                            </div>
                                        </div>
                                        <!--Дополнительный адрес почты-->
                                        <div class="add-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                                                    <label>
                                                        <i class="fal fa-at"></i> Доп email:

                                                    </label>
                                                </div>
                                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                                                    <input type="text" v-model="item.email_additional"
                                                           placeholder="Введите email">
                                                </div>
                                                <div class="clearfix"></div>
                                                <form-error v-bind:value="crud.errors.email_additional"></form-error>
                                            </div>
                                        </div>
                                        <!--Дата принятия на работу -->
                                        <div class="add-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                                                    <label>
                                                        <i class="fal fa-calendar-alt"></i> Принятия на работу:
                                                        <span class="control"><i
                                                                class="fas fa-star-of-life"></i></span>
                                                    </label>
                                                </div>
                                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                                                    <!--<input type="text" v-model="item.date_of_employment" placeholder="Дата">-->
                                                    <date-picker v-model="date_of_employment" :lang="'ru'"
                                                                 :format="'DD-MM-YYYY'"></date-picker>
                                                </div>
                                                <div class="clearfix"></div>
                                                <form-error v-bind:value="crud.errors.date_of_employment"></form-error>
                                            </div>
                                        </div>
                                    </div>
                                    <!--Фактический адрес проживания-->
                                    <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
                                        <div class="add-data__header">
                                            <h3><i class="fal fa-home"></i> Фактический адрес проживания</h3>
                                        </div>
                                        <!--Область-->
                                        <div class="add-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                                                    <label>
                                                        <i class="fal fa-flag"></i> Область: <span
                                                            class="control"><i
                                                            class="fas fa-star-of-life"></i></span>
                                                    </label>
                                                </div>
                                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                                                    <input type="text" v-model="item.address_region"
                                                           placeholder="Укажите область">
                                                </div>
                                                <div class="clearfix"></div>
                                                <form-error v-bind:value="crud.errors.address_region"></form-error>
                                            </div>
                                        </div>
                                        <!--Район-->
                                        <div class="add-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                                                    <label><i class="fal fa-flag"></i> Район: <span
                                                            class="control"><i
                                                            class="fas fa-star-of-life"></i></span></label>
                                                </div>
                                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                                                    <input type="text" v-model="item.address_district"
                                                           placeholder="Укажите район">
                                                </div>
                                                <div class="clearfix"></div>
                                                <form-error v-bind:value="crud.errors.address_district"></form-error>
                                            </div>
                                        </div>
                                        <!--Населенный пункт-->
                                        <div class="add-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                                                    <label><i class="fal fa-flag"></i> Населенный пункт: <span
                                                            class="control"><i
                                                            class="fas fa-star-of-life"></i></span></label>
                                                </div>
                                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                                                    <input type="text" v-model="item.address_settlement"
                                                           placeholder="Укажите населенный пункт">

                                                </div>
                                                <div class="clearfix"></div>
                                                <form-error v-bind:value="crud.errors.address_settlement"></form-error>
                                            </div>
                                        </div>
                                        <!--Улица-->
                                        <div class="add-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                                                    <label><i class="fal fa-flag"></i>Улица: <span
                                                            class="control"><i
                                                            class="fas fa-star-of-life"></i></span></label>
                                                </div>
                                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                                                    <input type="text" v-model="item.address_street"
                                                           placeholder="Укажите улицу">
                                                </div>
                                                <div class="clearfix"></div>
                                                <form-error v-bind:value="crud.errors.address_street"></form-error>
                                            </div>
                                        </div>
                                        <!--Дом-->
                                        <div class="add-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                                                    <label><i class="fal fa-flag"></i>Дом: <span
                                                            class="control"><i
                                                            class="fas fa-star-of-life"></i></span></label>
                                                </div>
                                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                                                    <input type="text" v-model="item.address_house_number"
                                                           placeholder="Укажите номер дома">
                                                </div>
                                                <div class="clearfix"></div>
                                                <form-error
                                                        v-bind:value="crud.errors.address_house_number"></form-error>
                                            </div>
                                        </div>
                                        <!--Квартира-->
                                        <div class="add-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                                                    <label><i class="fal fa-flag"></i>Квартира:</label>
                                                </div>
                                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                                                    <input type="text" v-model="item.address_apartment_number"
                                                           placeholder="Укажите номер квартиры">
                                                </div>
                                                <div class="clearfix"></div>
                                                <form-error
                                                        v-bind:value="crud.errors.address_apartment_number"></form-error>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <!--<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">-->
                        <!--<div class="page-body">-->
                        <!--<div class="row">-->
                        <!--&lt;!&ndash;Права доступа&ndash;&gt;-->
                        <!--&lt;!&ndash;<employee-permissions&ndash;&gt;-->
                        <!--&lt;!&ndash;v-bind:class="{'hide': item.is_superuser }"&ndash;&gt;-->
                        <!--&lt;!&ndash;:permissions="permissions"&ndash;&gt;-->
                        <!--&lt;!&ndash;@set_permissions_checked="set_permissions_checked"&ndash;&gt;-->
                        <!--&lt;!&ndash;&gt;</employee-permissions>&ndash;&gt;-->

                        <!--&lt;!&ndash;&lt;!&ndash;Действия&ndash;&gt;&ndash;&gt;-->
                        <!--&lt;!&ndash;<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">&ndash;&gt;-->
                        <!--&lt;!&ndash;<div class="add-data__footer">&ndash;&gt;-->
                        <!--&lt;!&ndash;<span class="btn grey"><i class="fal fa-times"></i> Отмена</span>&ndash;&gt;-->
                        <!--&lt;!&ndash;<span class="btn blue" @click.prevent="save()"><i&ndash;&gt;-->
                        <!--&lt;!&ndash;class="fal fa-save"></i> Сохранить</span>&ndash;&gt;-->
                        <!--&lt;!&ndash;</div>&ndash;&gt;-->
                        <!--&lt;!&ndash;</div>&ndash;&gt;-->
                        <!--</div>-->
                        <!--</div>-->
                        <!--</div>-->
                    </form>
                </div>


                <!--PAGE-->
                <div class="tabs-sc" id="tabs-sc">
                    <ul class="tabs-sc__nav">
                        <li class="tabs-sc__link" v-bind:class="{ 'is-active': isActive === 'personaldata' }"
                            v-on:click="isActive = 'personaldata'">
                            <span class="tabs-sc__link-icon"><i class="fal fa-user-tie"></i></span>
                            <span class="tabs-sc__link-title">Персональные данные</span>
                        </li>
                        <li class="tabs-sc__link" v-bind:class="{ 'is-active': isActive === 'permissions' }"
                            v-on:click="isActive = 'permissions'">
                            <span class="tabs-sc__link-icon"><i class="fal fa-fingerprint"></i></span>
                            <span class="tabs-sc__link-title">Права доступа</span>
                        </li>
                        <li class="tabs-sc__link" v-bind:class="{ 'is-active': isActive === 'documents' }"
                            v-on:click="isActive = 'documents'">
                            <span class="tabs-sc__link-icon"><i class="fal fa-file-alt"></i></span>
                            <span class="tabs-sc__link-title">Документы</span>
                        </li>
                    </ul>
                    <div class="tabs-sc__container">
                        <!--Personal date-->
                        <div class="tabs-sc__item" v-bind:class="{ 'is-active': isActive === 'personaldata' }">
                            <div class="grid">
                                <div class="grid__col">
                                    <div class="grid__box">
                                        <div class="row">
                                            <!--Паспортные данные-->
                                            <passport-details
                                                    :passportdetails="item.user_passportdetails"
                                                    :errors="crud.errors.user_passportdetails"
                                                    @set_passportdetails="set_passportdetails">
                                            </passport-details>
                                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6"
                                                 v-if="has_perm('family_status_view')">
                                                <div class="add-data__header">
                                                    <h3><i class="fal fa-child"></i> Семейное положение
                                                    </h3>
                                                </div>
                                                <div class="add-data__item">
                                                    <div class="row">
                                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                                                            <label><i class="fal fa-badge-check"></i>
                                                                Статус
                                                                СП: <span
                                                                        class="control"><i
                                                                        class="fas fa-star-of-life"></i></span></label>
                                                        </div>
                                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">
                                                            <label class="radio">
                                                                <input type="radio"
                                                                       v-model="item.family_is_married"
                                                                       :disabled="!has_perm('family_status_edit')"
                                                                       v-bind:value="true" required/>
                                                                <div class="radio__text">Да</div>
                                                            </label>
                                                            <label class="radio">
                                                                <input type="radio"
                                                                       v-model="item.family_is_married"
                                                                       :disabled="!has_perm('family_status_edit')"
                                                                       v-bind:value="false" required/>
                                                                <div class="radio__text">Нет</div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <template v-if="item.family_is_married">
                                                    <div class="add-data__item">
                                                        <div class="row">
                                                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                                                                <label><i class="fal fa-user-crown"></i>
                                                                    ФИО
                                                                    супруга(и): <span
                                                                            class="control"><i
                                                                            class="fas fa-star-of-life"></i></span></label>
                                                            </div>
                                                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">
                                                                <input type="text"
                                                                       v-model="item.family_partner_fio"
                                                                       :disabled="!has_perm('family_status_edit')"
                                                                       placeholder="Введите ФИО супруга (и)">
                                                            </div>
                                                            <div class="clearfix"></div>
                                                            <form-error
                                                                    v-bind:value="crud.errors.family_partner_fio"></form-error>
                                                        </div>
                                                    </div>
                                                    <div class="add-data__item">
                                                        <div class="row">
                                                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                                                                <label><i
                                                                        class="fal fa-birthday-cake"></i>
                                                                    Дата рождения
                                                                    супруга(и): <span
                                                                            class="control"><i
                                                                            class="fas fa-star-of-life"></i></span></label>
                                                            </div>
                                                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">
                                                                <!--<input type="text" v-model="item.family_partner_date_of_birth" placeholder="10.10.1990">-->
                                                                <date-picker
                                                                        v-model="family_partner_date_of_birth"
                                                                        :lang="'ru'"
                                                                        :disabled="!has_perm('family_status_edit')"
                                                                        :format="'DD-MM-YYYY'"></date-picker>
                                                            </div>
                                                            <div class="clearfix"></div>
                                                            <form-error
                                                                    v-bind:value="crud.errors.family_partner_date_of_birth"></form-error>
                                                        </div>
                                                    </div>
                                                    <div class="add-data__item">
                                                        <div class="row">
                                                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                                                                <label><i class="fal fa-phone"></i>
                                                                    Телефон
                                                                    супруга(и): <span
                                                                            class="control"><i
                                                                            class="fas fa-star-of-life"></i></span></label>
                                                            </div>
                                                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">
                                                                <input type="text"
                                                                       v-model="item.family_partner_phone"
                                                                       :disabled="!has_perm('family_status_edit')"
                                                                       placeholder="+380XXXXXXXXX">
                                                            </div>
                                                            <div class="clearfix"></div>
                                                            <form-error
                                                                    v-bind:value="crud.errors.family_partner_phone"></form-error>
                                                        </div>
                                                    </div>
                                                </template>
                                                <div class="add-data__item">
                                                    <div class="row">
                                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                                                            <label><i class="fal fa-smile"></i> Дети:
                                                                <span
                                                                        class="control"><i
                                                                        class="fas fa-star-of-life"></i></span></label>
                                                        </div>
                                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">
                                                            <label class="radio">
                                                                <input type="radio"
                                                                       v-model="item.family_is_have_children"
                                                                       :disabled="!has_perm('family_status_edit')"
                                                                       v-bind:value="true" required/>
                                                                <div class="radio__text">Да</div>
                                                            </label>
                                                            <label class="radio">
                                                                <input type="radio"
                                                                       v-model="item.family_is_have_children"
                                                                       :disabled="!has_perm('family_status_edit')"
                                                                       v-bind:value="false" required/>
                                                                <div class="radio__text">Нет</div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                        <template v-if="item.family_is_have_children">
                                            <children
                                                    :readonly="!has_perm('family_status_edit')"
                                                    :children_input="item.family_childrens"
                                                    :errors="crud.errors.family_childrens"
                                                    @set_children="set_children"
                                            ></children>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                            </div>
                        </div>
                        <!--persmissions-->
                        <div class="tabs-sc__item" v-bind:class="{ 'is-active': isActive === 'permissions' }">
                            <permissions-department
                                    :departments="departments"
                                    :permissions_department="department_permissions_objects"
                                    :readonly="!has_perm('employees_reports_access_edit')"
                                    @set_department_permissions="set_department_permissions">
                            </permissions-department>

                            <permissions :permissions="permissions"
                                         :permissions_department="department_permissions_objects"
                                         :readonly="!has_perm('employees_access_permissions_edit')"
                                         v-bind:class="{'hide': item.is_superuser }"
                                         @set_permissions_checked="set_permissions_checked">
                            </permissions>

                        </div>
                        <!--documents-->
                        <div class="tabs-sc__item" v-bind:class="{ 'is-active': isActive === 'documents' }">

                        </div>
                    </div>
                </div>
                <!--END PAGE-->

                <div v-if="user.is_superuser"
                     class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <ul class="access-rights">
                        <li class="access-rights__super-user">
                            <p>
                                <i class="fal fa-question-circle"
                                   data-title="Права супер-администратора, снимают все ограничения и открывает доступ ко всей системе без исключения!"></i>
                                Задать права супер-администратора системы:
                            </p>
                            <label class="checkbox">
                                <input type="checkbox"
                                       v-model="item.is_superuser"><span
                                    class="checkmark"></span>
                            </label>
                        </li>
                    </ul>
                    <div class="clearfix"></div>
                    <form-error
                            v-bind:value="crud.errors.is_superuser"></form-error>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" v-if="has_perm('catalog_employees_block_access')">
                    <ul class="access-rights">
                        <li class="access-rights__super-user">
                            <p>
                                <i class="fal fa-question-circle"
                                   data-title="Блокировка доступа к биллингу, запретит доступ к системе"></i>
                                Заблокировать доступ к биллингу:
                            </p>
                            <label class="checkbox">
                                <input type="checkbox"
                                       v-model="item.is_blocked"><span
                                    class="checkmark"></span>
                            </label>
                        </li>
                    </ul>
                    <div class="clearfix"></div>
                    <form-error
                            v-bind:value="crud.errors.is_active"></form-error>
                </div>

                <div class="form-data__footer form-data__footer_right">
                    <div class="btn-group">
                        <span class="btn btn_blue" @click.prevent="save()">
                            <span class="btn__icon"><i class="fal fa-save"></i></span>
                            <span class="btn__title">Сохранить</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import {DepartmentMixin} from "../../../../mixins/admin/department";
    import {EmployeePositionsMixin} from "../../../../mixins/admin/employee_positions";
    import {PhonesMixin} from "../../../../mixins/admin/phones";


    // Components
    import EmployeePermissions from '../employee_permissions/list';
    import PermissionsDepartment from './permissions/department';
    import PhoneList from '../phones/list';
    import PassportDetails from './passportdetails/item';
    import Children from './childrens/item';
    import FileBase64 from '../file_base64/item';
    import DatePicker from 'vue2-datepicker';

    import FormErrors from '../form/errors.vue';
    import {API} from "../../../../api";
    import {EmployeeMixin} from "../../../../mixins/admin/employee";
    import {User} from "../../../../mixins/admin/user";
    import {SearchMixin} from "../../../../mixins/admin/search";
    import Permissions from './permissions/list'

    export default {
        name: 'employees-add',
        mixins: [User, EmployeeMixin, DepartmentMixin, EmployeePositionsMixin, PhonesMixin, SearchMixin],
        data: function () {
            return {
                isActive: 'personaldata',
                date_of_employment: "",
                family_partner_date_of_birth: "",
                photoImageMaxSize: 5,
                permissions: [],
                auto_change_permission: true,
                item: {
                    id: null,
                    is_superuser: false,
                    is_blocked: false,
                    is_active: true,
                    add_to_reporting: false,
                    fio: "",
                    photo: "",
                    employee_department: "",
                    employee_position: "",
                    email: "",
                    email_additional: "",
                    date_of_employment: "",
                    address_region: "",
                    address_district: "",
                    address_settlement: "",
                    address_street: "",
                    address_house_number: "",
                    address_apartment_number: "",
                    family_is_married: false,
                    family_partner_fio: "",
                    family_partner_date_of_birth: "",
                    family_partner_phone: "",
                    family_is_have_children: false,
                    family_childrens: [],
                    user_passportdetails: {
                        user_passport_details_file: [],
                        country: "",
                        region: "",
                        district: "",
                        settlement: "",
                        street: "",
                        house_number: "",
                        apartment_number: "",
                        passport_series_number: "",
                        date_of_issue: "",
                        issued_by: "",
                        identification_number: "",
                        citizenship: "",
                        gender: "M",
                        date_of_birth: ""
                    },
                    position_permissions: [],
                    department_permissions: [],
                },
                department_permissions_objects: [],
                crud: {
                    api: '/employee/',
                    errors: {
                        user_passportdetails: {
                            docs: []
                        },
                    }
                },
            }
        },
        components: {
            "form-error": FormErrors,
            "phone-list": PhoneList,
            "employee-permissions": EmployeePermissions,
            "passport-details": PassportDetails,
            "children": Children,
            "file-base64": FileBase64,
            "date-picker": DatePicker,
            "permissions": Permissions,
            "permissions-department": PermissionsDepartment,
        },
        created: function () {
            this.load();

            this.$root.$on('search_hit_employees', (function (data) {
                this.load();
            }).bind(this));

            this.$root.$on('search_clear_employees', (function (data) {
                this.load();
            }).bind(this));
        },
        beforeDestroy: function () {
            this.destroy_search_listeners('employees');
        },
        methods: {
            load() {
                if (this.$route.params.id) {
                    this.item.id = this.$route.params.id;
                    this.get_employee(this.item.id);
                    this.auto_change_permission = false;
                }

                this.load_departments();
                this.load_employee_positions();
            },
            save: function () {
                var self = this, data;
                self.loader_show();
                data = Object.assign({}, self.item);

                if (!self.user.is_superuser) {
                    delete data.is_superuser;
                }

                if(!self.has_perm('family_status_edit')){
                    delete data.family_is_married;
                    delete data.family_partner_fio;
                    delete data.family_partner_date_of_birth;
                    delete data.family_partner_phone;
                    delete data.family_is_have_children;
                    delete data.family_childrens;
                }

                if(!self.has_perm('employees_access_permissions_edit')){
                    delete data.position_permissions;
                }

                if(!self.has_perm('employees_edit_passport_details')){
                    delete data.user_passportdetails;
                }

                if (self.item.id) {
                    // Edit employee by id
                    API.patch(self.crud.api + self.item.id + '/', data).then(function (response) {

                        if (self.item.id === self.user.id) {
                            // Update user info
                            self.set_user();
                        }
                        self.$router.push({name: 'employees-list'});

                    })
                        .catch(function (error) {
                            API.default_catch(self.crud, error)
                            if (self.crud.errors.position_permissions) {
                              self.alert('Ошибка', self.crud.errors.position_permissions[0]);
                            }
                        })
                        .then(function () {
                            API.default_always(self);
                        })
                } else {
                    // Create new employee
                    API.post(self.crud.api, data)
                        .then(function (response) {
                            self.item = {};
                            self.$router.push({name: 'employees-list'});
                        })
                        .catch(function (error) {
                            API.default_catch(self.crud, error)
                        })
                        .then(function () {
                            API.default_always(self);
                        })
                }
            },
            get_employee: function (id) {
                var self = this;
                API.get('/employee/' + id + '/')
                    .then(function (response) {
                        var employee = response.data;
                        // self.item = Object.assign({}, self.item, employee);
                        self.$set(self, 'item', Object.assign({}, self.item, employee));
                        if (employee.family_childrens_expand !== null) {
                            self.$set(self.item, 'family_childrens', employee.family_childrens_expand);
                        }
                        if (employee.position_permissions_expand !== null) {
                            self.$set(self, 'permissions', employee.position_permissions_expand);
                        }
                        if (employee.department_permissions_expand !== null) {
                            self.$set(self, 'department_permissions_objects', employee.department_permissions_expand);
                        }

                        if (self.item.user_passportdetails == null) {
                            self.item.user_passportdetails = {
                                docs: [],
                                country: "",
                                region: "",
                                district: "",
                                settlement: "",
                                street: "",
                                house_number: "",
                                apartment_number: "",
                                passport_series_number: "",
                                date_of_issue: "",
                                issued_by: "",
                                identification_number: "",
                                citizenship: "",
                                gender: "M",
                                date_of_birth: ""
                            };
                        }

                        if (self.item.date_of_employment !== null) {
                            self.date_of_employment = self.item.date_of_employment;
                        } else {
                            self.item.date_of_employment = "";
                        }

                        if (self.item.family_partner_date_of_birth !== null) {
                            self.family_partner_date_of_birth = self.item.family_partner_date_of_birth;
                        } else {
                            self.item.family_partner_date_of_birth = "";
                        }
                    })
                    .catch(function (error) {
                        // API.default_catch(self, error)
                    })
                    .then(function () {
                        // API.default_always(self);
                    })
            },
            employee_position_change(value) {
                if (this.auto_change_permission === false) {
                    this.auto_change_permission = true;
                    this.alert("Права доступа", "Внимание, были изменены права доступа")
                }
            },
            onPhotoLoad(dataUri, file, index) {
                this.item.photo = dataUri;
            },
            onSizeExceeded(size) {
                this.alert('Ошибка', `Размер загружаемого файла ${size}Mb превышает максимально возможный ${this.photoImageMaxSize}Mb!`);
            },
            set_permissions_checked: function (value) {
                this.item.position_permissions = value;
            },
            set_department_permissions: function (value) {
                this.item.department_permissions = value;
                this.department_permissions_objects = value;
            },
            set_passportdetails: function (value) {
                this.item.user_passportdetails = value;
            },
            set_children: function (value) {
                this.item.family_childrens = value;
            }
        },
        watch: {
            family_partner_date_of_birth(value) {
                this.item.family_partner_date_of_birth = this.$moment(value).format("YYYY-MM-DD");
            },
            date_of_employment(value) {
                this.item.date_of_employment = this.$moment(value).format("YYYY-MM-DD");
            },
            'item.employee_position': function (value) {
                var self = this;
                if (this.auto_change_permission) {
                    if (self.employee_positions.length > 0) {
                        self.permissions = self.get_permission_by_position(value);
                    } else {
                        API.get('/employee-positions/')
                            .then(function (response) {
                                self.employee_positions = response.data.results;
                                self.permissions = self.get_permission_by_position(value);
                            })
                    }
                }
            },
            'item.family_is_have_children': function (value) {
                if (!value) {
                    this.item.family_childrens = [];
                }
            },
            'item.family_is_married': function (value) {
                if (!value) {
                    this.item.family_partner_fio = '';
                    this.item.family_partner_date_of_birth = '';
                    this.item.family_partner_phone = '';
                }
            },
            'item.is_blocked': function (value) {
                this.item.is_active = !value;
            },
            'item.is_active': function (value) {
                this.item.is_blocked = !value;
            }
        }
    }
</script>

<style scoped>

</style>