<template>
    <section class="page">
        <div class="page__inner">
            <div class="container-width">
                <!--HEADER PAGE-->
                <div class="page__header">
                    <h1 class="page__title">Отчет по финансам</h1>
                    <report-menu :active_name="'reports-page'"></report-menu>
                </div>
                <!--END HEADER PAGE-->
                <!-- BODY PAGE -->
                <div class="page__body">
                    <div class="table-box">
                        <div class="table-box__header">
                            <div class="table-box__header-block">
                                <div class="table-filter">
                                    <year-filter v-model="year" :years="years"></year-filter>
                                </div>
                            </div>
                        </div>
                        <table class="table-box__table">
                            <thead class="table-box__thead">
                            <tr class="table-box__thead-tr">
                                <template v-for="col in cols">
                                    <th @click="set_ordering(col.field)" class="table-box__th">
                                        <span class="table-box__thead-item table-box__thead-item_center">
                                            <span class="table-box__thead-title">
                                                {{ col.label }}
                                                <i class="fal" :class="[get_class_ordering_direction(col.field)]"></i>
                                            </span>
                                        </span>
                                    </th>
                                </template>
                            </tr>
                            </thead>
                            <tbody class="table-box__tbody">
                            <tr class="table-box__tr table-data" v-for="item in finance">
                                <td class="table-box__td table-box__td_center">
                                        <span class="table-data__text">
                                            {{ item.month_transaction_expand }}
                                        </span>
                                </td>
                                <td class="table-box__td table-box__td_center">
<!--                                    <span class="table-data__currency">₴</span>-->
                                    <report-link :item="item" :field_name="'total_amount'"></report-link>
                                </td>
                                <td class="table-box__td table-box__td_center">
<!--                                    <span class="table-data__currency">₴</span>-->
                                    <report-link :item="item" :field_name="'unicards'"></report-link>
                                </td>
                                <td class="table-box__td table-box__td_center">
<!--                                    <span class="table-data__currency">₴</span>-->
                                    <report-link :item="item" :field_name="'easypay'"></report-link>
                                </td>
                                <td class="table-box__td table-box__td_center">
<!--                                    <span class="table-data__currency">₴</span>-->
                                    <report-link :item="item" :field_name="'manual_refill'"></report-link>
                                </td>
                                <td class="table-box__td table-box__td_center">
<!--                                    <span class="table-data__currency">₴</span>-->
                                    <report-link :item="item" :field_name="'private_24'"></report-link>
                                </td>
                                <td class="table-box__td table-box__td_center">
<!--                                    <span class="table-data__currency">₴</span>-->
                                    <report-link :item="item" :field_name="'liqpay'"></report-link>
                                </td>
                                <td class="table-box__td table-box__td_center">
<!--                                    <span class="table-data__currency">₴</span>-->
                                    <report-link :item="item" :field_name="'family_bank'"></report-link>
                                </td>
                                <td class="table-box__td table-box__td_center">
<!--                                    <span class="table-data__currency">₴</span>-->
                                    <report-link :item="item" :field_name="'manual_cashless'"></report-link>
                                </td>
                                <td class="table-box__td table-box__td_center">
<!--                                    <span class="table-data__currency">₴</span>-->
                                    <report-link :item="item" :field_name="'recalculate'"></report-link>
                                </td>
                                <td class="table-box__td table-box__td_center">
<!--                                    <span class="table-data__currency">₴</span>-->
                                    <report-link :item="item" :field_name="'business_manual'"></report-link>
                                </td>
                                <td class="table-box__td table-box__td_center">
<!--                                    <span class="table-data__currency">₴</span>-->
                                    <report-link :item="item" :field_name="'bonus_balance'"></report-link>
                                </td>
                                <td class="table-box__td table-box__td_center">
<!--                                    <span class="table-data__currency">₴</span>-->
                                    <report-link :item="item" :field_name="'set_balance'"></report-link>
                                </td>
                                <td class="table-box__td table-box__td_center">
<!--                                    <span class="table-data__currency">₴</span>-->
                                    <report-link :item="item" :field_name="'total_cash'"></report-link>
                                </td>
                                <td class="table-box__td table-box__td_center">
<!--                                    <span class="table-data__currency">₴</span>-->
                                    <report-link :item="item" :field_name="'total_cashless'"></report-link>
                                </td>
                                <td class="table-box__td table-box__td_center">
<!--                                    <span class="table-data__currency">₴</span>-->
                                    <report-link :item="item" :field_name="'average_payment'"></report-link>
                                </td>
                                <td class="table-box__td table-box__td_center">
<!--                                    <span class="table-data__currency">₴</span>-->
                                    <report-link :item="item" :field_name="'arpu'"></report-link>
                                </td>
                                <td class="table-box__td table-box__td_center">
<!--                                    <span class="table-data__currency">₴</span>-->
                                    <report-link :item="item" :field_name="'arpu_physical'"></report-link>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- END BODY PAGE -->
            </div>
        </div>
    </section>
</template>

<script>
    import ReportMenu from './menu/menu.vue';
    import YearFilter from './sub_components/year_filter';
    import {API} from "../../../../api";
    import {OrderMixin} from "../../../../mixins/admin/order";
    import {RouteHelperMixin} from "../../../../mixins/admin/route_helper";
    import {LinksMixin} from "../../../../mixins/admin/links";
    import ReportLink from "./sub_components/report-link";

    export default {
        name: "Finance",
        components: {
            ReportLink,
            "year-filter": YearFilter,
            "report-menu": ReportMenu
        },
        data() {
            return {
                cols: [
                    {
                        field: 'date_transaction',
                        label: 'Месяц'
                    },
                    {
                        field: 'total_amount',
                        label: 'Сумма всего'
                    },
                    {
                        field: 'unicards',
                        label: 'Unicards'
                    },
                    // {
                    //     field: 'nonstop_24',
                    //     label: '24 NonStop'
                    // },
                    {
                        field: 'easypay',
                        label: 'EasyPay'
                    },
                    {
                        field: 'manual_refill',
                        label: 'Руч. пополнение'
                    },
                    {
                        field: 'private_24',
                        label: 'Приват24'
                    },
                    {
                        field: 'liqpay',
                        label: 'LiqPay'
                    },
                    {
                        field: 'family_bank',
                        label: 'Банк Фамильный'
                    },
                    // {
                    //     field: 'acquiring',
                    //     label: 'Эквайринг'
                    // },
                    {
                        field: 'manual_cashless',
                        label: 'Руч. безнал'
                    },
                    {
                        field: 'recalculate',
                        label: 'Перерасчёт'
                    },
                    {
                        field: 'business_manual',
                        label: 'Руч. юрилицо'
                    },
                    // {
                    //     field: 'terminal',
                    //     label: 'Терминал'
                    // },
                    // {
                    //     field: 'manual_private_24',
                    //     label: 'Ручной Приват24'
                    // },
                    // {
                    //     field: 'manual_acquiring',
                    //     label: 'Ручной эквайринг'
                    // },
                    {
                        field: 'bonus_balance',
                        label: 'Бонус'
                    },
                    {
                        field: 'set_balance',
                        label: 'SET'
                    },
                    {
                        field: 'total_cash',
                        label: 'Итого нал'
                    },
                    {
                        field: 'total_cashless',
                        label: 'Итого бн'
                    },
                    {
                        field: 'average_payment',
                        label: 'Ср. платеж'
                    },
                    {
                        field: 'arpu',
                        label: 'ARPU'
                    },
                    {
                        field: 'arpu_physical',
                        label: 'ARPU ФИЗ'
                    },
                ],
                year: this.$moment().year(),
                ordering: "date_transaction",
                ordering_direction: 'ASC',
                years: [],
                finance: [],
                errors: []
            }
        },
        mixins: [OrderMixin, RouteHelperMixin, LinksMixin],
        created() {
            this.set_data_from_route([
                {'name': 'ordering_direction', 'type': 'str'},
                {'name': 'year', 'type': 'int'},
                {'name': 'ordering', 'type': 'str'}], this);

            this.loader_show();
            this.load_years();
            this.load_finance();
        },
        methods: {
            load_finance() {
                var self = this;
                var params = {
                    year: this.year,
                    ordering: this.ordering_direction === "ASC" ? this.ordering : "-" + this.ordering
                };

                API.get('/report/finance/', params)
                    .then(function (response) {
                        self.finance = response.data.results;

                        // Update router
                        self.$router.replace({
                            query: Object.assign({}, self.$route.query, {
                                year: self.year,
                                ordering: self.ordering,
                                ordering_direction: self.ordering_direction
                            }),
                        });
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            },
            load_years() {
                var self = this;

                API.get('/report/finance/years/')
                    .then(function (response) {
                        self.years = response.data;
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            },
        },
        watch: {
            year(value) {
                this.load_finance();
            },
            ordering(value) {
                this.load_finance();
            },
            ordering_direction(value) {
                this.load_finance();
            }
        }
    }
</script>

<style scoped>

</style>