<template>
    <div class="service-list-item-status">
        <template v-if="service">
            <template v-if="subscriber_connection.cls_list === 'CLS_DENY'">
                <span class="table-data__status table-data__status_unavailable">
                    Недоступно
                </span>
            </template>
            <template v-else>
                <span v-if="service.is_active"
                      class="table-data__status table-data__status_active">
                    Активно
                </span>
                <span v-else class="table-data__status table-data__status_deactive">
                    Неактивно
                </span>
            </template>
        </template>
        <template v-else>
            <span class="table-data__status table-data__status_deactive">Не подключено</span>
        </template>
    </div>
</template>

<script>
export default {
    name: "service-list-item-status",
    props: {
        service: {
            required: false,
            type: Object
        },
        subscriber_connection: {
            required: true,
            type: Object
        }
    }
}
</script>

<style scoped>

</style>