<template>
    <tr class="table-box__tr table-data">
        <td class="table-box__td table-box__td_center">
            <span class="table-data__text">IP адресс</span>
        </td>
        <!--Пакет-->
        <td class="table-box__td table-box__td_center">
            <span v-if="service" class="table-data__text">{{ service.service_name }}</span>
            <span v-else class="table-data__text">Не подключено</span>
        </td>
        <!--Характеристика-->
        <td class="table-box__td table-box__td_center">
            <span v-if="service" class="table-data__text">{{ service.description }}</span>
            <span v-else class="table-data__text">Не подключено</span>
        </td>
        <!--Стоимость-->
        <td class="table-box__td table-box__td_center">
            <template v-if="service">
                <div class="table-data__price" v-if="!service.is_tariff_service_amount">
                    <span class="table-data__price-item">
                        <span class="table-data__price-icon">₴</span>
                        <span class="table-data__price-value">{{ service.total_cost_month }}</span>
                    </span>
                    <span class="table-data__price-divide"></span>
                    <span class="table-data__price-item">
                        <span class="table-data__price-icon">₴</span>
                        <span class="table-data__price-value">{{ service.total_cost_day }}</span>
                    </span>
                </div>
                <div class="table-data__price" v-else>
                    Входят в тариф
                </div>
            </template>
            <template v-else>
                <div class="table-data__price">
                    Не подключено
                </div>
            </template>
        </td>
        <!--Статус-->
        <td class="table-box__td table-box__td_center">
            <item-status :service="service" :subscriber_connection="subscriber_connection"/>
        </td>
        <!--Управление-->
        <td class="table-box__td table-box__td_center">
            <div class="btn-group" v-if="has_perm('personal_services_management_activate_deactivate')">
                <div class="btn-switch" v-if="service">
                    <input type="checkbox" v-model="service.is_active"
                           @change="$emit('change_is_active', service, index)"
                           class="btn-switch__input"
                           :id="serviceIpAddressId">
                    <label class="btn-switch__for" :for="serviceIpAddressId"></label>
                </div>
                <div class="btn-switch" v-else>
                    <input type="checkbox" class="btn-switch__input"
                           v-model="checked"
                           :id="serviceIpAddressId">
                    <label class="btn-switch__for" :for="serviceIpAddressId"></label>
                </div>
            </div>
            <div class="btn-group" v-else>
                <div class="btn-switch" @click="permissions_error_show">
                    <input type="checkbox" class="btn-switch__input" disabled>
                    <label class="btn-switch__for" :for="serviceIpAddressId"></label>
                </div>
            </div>
        </td>
        <!--Действия-->
        <td class="table-box__td table-box__td_center">
            <div class="btn-group" v-if="service">
                <app-has-perm-action-btn :perms="'personal_services_management_activate_deactivate'"
                                         @action="$emit('popup_ip_address_view', service)">
                     <template slot-scope="{ access }">

                        <span class="btn-l" :class="access ? 'btn-l_blue' : 'btn-l_grey'" v-tooltip="TooltipText='Просмотр'">
                            <span class="btn-l__icon"><i class="fal fa-eye"></i></span>
                        </span>

                     </template>
                </app-has-perm-action-btn>

                <app-has-perm-action-btn :perms="'personal_services_management_activate_deactivate'"
                                         @action="$emit('popup_ip_address_settings', service)">
                     <template slot-scope="{ access }">

                        <span class="btn-l" :class="access ? 'btn-l_orange' : 'btn-l_grey'" v-tooltip="TooltipText='Параметры'">
                            <span class="btn-l__icon"><i class="fal fa-cog"></i></span>
                        </span>

                     </template>
                </app-has-perm-action-btn>

                <!-- Check on ip_address-->
                <app-has-perm-action-btn :perms="'personal_services_management_activate_deactivate'"
                                         @action="$emit('popup_ip_address_clear', service)">
                     <template slot-scope="{ access }">

                        <span class="btn-l" :class="access ? 'btn-l_red' : 'btn-l_grey'" v-tooltip="TooltipText='Восстановить <br> по-умолчанию'" >
                            <span class="btn-l__icon"><i class="fal fa-eraser"></i></span>
                        </span>

                     </template>
                </app-has-perm-action-btn>

            </div>
            <div class="btn-group table-box__td_center" v-else>
                <span v-tooltip="TooltipText='Просмотр'" class="btn-l btn-l_grey">
                    <span class="btn-l__icon" ><i class="fal fa-eye"></i></span>
                </span>
                <span v-tooltip="TooltipText='Параметры'" class="btn-l btn-l_grey">
                    <span class="btn-l__icon"><i class="fal fa-cog"></i></span>
                </span>
                <span v-tooltip="TooltipText='Восстановить <br> по-умолчанию'" class="btn-l btn-l_grey">
                    <span class="btn-l__icon"><i class="fal fa-eraser"></i></span>
                </span>
            </div>
        </td>
        <form-error v-bind:value="errors.ip_address_connection"></form-error>
    </tr>
</template>

<script>
    import FormErrors from "../../../form/errors";
    import _ from 'lodash';
    import ItemStatus from './service-list-item-status';

    export default {
        name: "service-ip-address-list-item",
        props: ['subscriber_connection', 'service', 'index', 'errors'],
        components: {
            "form-error": FormErrors,
            "item-status": ItemStatus
        },
        data() {
            return {
                checked: false,
                service_type: "service_ip_address",
                serviceIpAddressId: _.uniqueId('id_'),
            }
        },
        watch: {
            checked(value) {
                if (value) {
                    this.$emit('add_new_service_by_checkbox', this.service_type);
                    this.checked = false;
                }
            }
        }

    }
</script>

<style scoped>

</style>