<template>
    <div class="grid__box" v-has-perm="'subscribers_access'">
        <div class="view">
            <div class="view__row">
                <div class="view__section">
                    <div class="view__header">
                        <div class="view__header-title">
                            <span class="view__header-icon"><i class="fal fa-user"></i></span>
                            <span class="view__header-text">Личный кабинет</span>
                        </div>
                    </div>
                    <!--Вход в ЛК как абонент-->
                    <div class="view__item">
                        <div class="view__label">
                            <span class="view__label-icon"><i class="fal fa-low-vision"></i></span>
                            <span class="view__label-title">Войти в ЛК абонента:</span>
                        </div>
                        <div class="view__value">
                            <a :href="getAuthLink()" target="_blank" class="view__value-link">
                                <span class="view__value-link_icon"><i class="fal fa-sign-in"></i></span>
                                <span class="view__value-link_title">Войти</span>
                            </a>
                        </div>
                    </div>
                    <!--Доступ в ЛК-->
                    <div class="view__item">
                        <div class="view__label">
                            <span class="view__label-icon"><i class="fal fa-user-lock"></i></span>
                            <span class="view__label-title">Доступ в ЛК:</span>
                        </div>
                        <div class="view__value">
                            <div class="view__group-value">
                                <!--Запрещен доступ в ЛК-->
                                <template v-if="block_login_status">
                                    <div class="view__value-status view__value-status_deactive">
                                        <i class="fal fa-lock"></i> Запрещен
                                    </div>
                                </template>
                                <!--Разрешен доступ в ЛК-->
                                <template v-else>
                                    <div class="view__value-status view__value-status_active">
                                       <i class="fal fa-unlock"></i> Разрешен
                                    </div>
                                </template>
                                <!--Переключатель-->
                                <template v-if="has_perm('subscriber_connection_block_login_status')">
                                    <div class="view__switch">
                                        <input type="checkbox" v-model="block_login_status" class="view__input_switch"
                                               id="switch-access">
                                        <label class="view__switch-for" for="switch-access"></label>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="view__switch" @click="permissions_error_show">
                                        <input type="checkbox" class="view__input_switch" disabled>
                                        <label class="view__switch-for" for="switch-access"></label>
                                    </div>
                                </template>

                            </div>
                        </div>
                    </div>
                    <!--Тип абонента-->
                    <div class="view__item">
                        <div class="view__label">
                            <span class="view__label-icon"><i class="fal fa-users-crown"></i></span>
                            <span class="view__label-title">Тип абонента:</span>
                        </div>
                        <div class="view__value">
                                <span class="view__value-text">
                                    <template
                                            v-if="Object.keys(subscriber_connection).length && subscriber_connection.subscriber_expand.subscriber_type_expand">
                                        {{ subscriber_connection.subscriber_expand.subscriber_type_expand.name_expand }}
                                    </template>
                                    <template v-else>
                                        Не указано
                                    </template>
                                </span>
                        </div>
                    </div>
                    <!--Группа-->
                    <div class="view__item">
                        <div class="view__label">
                            <span class="view__label-icon"><i class="fal fa-folder"></i></span>
                            <span class="view__label-title">Группа:</span>
                        </div>
                        <div class="view__value">
                                <span class="view__value-text">
                                    <template
                                            v-if="Object.keys(subscriber_connection).length && subscriber_connection.group_expand">
                                        {{ subscriber_connection.group_expand.name }}
                                    </template>
                                    <template v-else>
                                        <i>Не указано</i>
                                    </template>
                                </span>
                        </div>
                    </div>
                    <!--Отдел-->
                    <div class="view__item">
                        <div class="view__label">
                            <span class="view__label-icon"><i class="fal fa-code-branch"></i> </span>
                            <span class="view__label-title">Отдел:</span>
                        </div>
                        <div class="view__value">
                                <span class="view__value-text">
                                    <template
                                            v-if="Object.keys(subscriber_connection).length && subscriber_connection.department_expand">
                                        {{ subscriber_connection.department_expand.name }}
                                    </template>
                                    <template v-else>
                                        <i>Не указано</i>
                                    </template>
                                </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {API} from "../../../../../api";
    import {EMPLOYEE_AUTH_LINK} from "../../../../../config";

    export default {
        name: "action-blocks",
        props: ["subscriber_connection"],
        data() {
            return {
                block_login_status: false
            }
        },
        methods: {
            getAuthLink() {
                var token = this.subscriber_connection.auth_token;
                var base_url = EMPLOYEE_AUTH_LINK;
                return base_url + "?token=" + token;
            }
        },
        watch: {
            block_login_status: function (value) {
                var extra_data = this.subscriber_connection.subscriber_connection_extra_data;
                var data = {};

                if (this.block_login_status) {
                    if (extra_data.block_login_status === 'manual_blocked') {
                        data['block_login_status'] = 'manual_blocked'
                    } else if (extra_data.block_login_status === 'invalid_password_blocked') {
                        data['block_login_status'] = 'invalid_password_blocked'
                    } else {
                        data['block_login_status'] = 'manual_blocked'
                    }
                } else {
                    data['block_login_status'] = 'active'
                }

                API.post('/subscriber-connections/' + this.subscriber_connection.id + '/block-unblock-login/', data)
            },
            subscriber_connection: function (value) {
                var extra_data = this.subscriber_connection.subscriber_connection_extra_data;

                if (extra_data) {
                    if (extra_data.block_login_status === 'manual_blocked') {
                        this.block_login_status = true;
                    } else if (extra_data.block_login_status === 'invalid_password_blocked') {
                        this.block_login_status = true;
                    } else {
                        this.block_login_status = false;
                    }
                }
            },
        }
    }
</script>

<style scoped>

</style>