<template>
    <th class="table-box__th">
        <span class="table-box__thead-item table-box__thead-item_center">
            <span class="table-box__thead-icon"><i class="fal fa-toolbox"></i></span>
            <span class="table-box__thead-title">
                <slot name="title-text"></slot>
            </span>
        </span>
    </th>
</template>

<script>
    export default {
        name: "table-th"
    }
</script>

<style scoped>

</style>