<template>
    <div class="filters__select">
        <select :value="value" v-on="inputListeners" required>
            <option value="" selected disabled hidden>{{ title }}</option>
            <!--COMMON TARIFF-->
            <option v-if="title==='Отдел' && addSocialTariff" :value="'common_tariff'">Все отделы</option>
            <template v-if="!isChoicesFilter">
                <option v-for="object in objects" :value="object.id">
                    <!--CONDITION FOR CORRECT RENDER NAME-->
                    <span v-if="view==='name'">{{ object.name }}</span>
                    <span v-else-if="view==='fio'">{{ object.fio }}</span>
                    <span v-else-if="view==='label'">{{ object.label }}</span>
                    <span v-else-if="view==='name_expand'">{{ object.name_expand }}</span>
                    <span v-else-if="view==='status_display'">{{ object.status_display }}</span>
                </option>
            </template>
            <template v-else>
                <!--<option v-if="title==='Статус' && isChoicesFilter" :value="undefined">Все карты</option>-->
                <option v-for="object in objects" :value="object.key">
                    <!--CONDITION FOR CORRECT RENDER NAME-->
                    <span v-if="view==='label'">{{ object.label }}</span>
                </option>
            </template>
        </select>
    </div>
</template>

<script>
    export default {
        name: "filter-by-field",
        props: ['value', 'title', 'objects', 'view', 'addSocialTariff', 'isChoicesFilter'],
        data(){
            return {
            }
        },
        computed: {
          inputListeners: function () {
            var self = this;
            // `Object.assign` объединяет объекты вместе, чтобы получить новый объект
            return Object.assign({},
              // Мы добавляем все слушатели из родителя
              self.$listeners,
              // Затем мы можем добавить собственные слушатели или перезаписать поведение некоторых существующих.
              {
                // Это обеспечит, что будет работать v-model на компоненте
                input: function (event) {
                  self.$root.$emit('resetQueryPaginationFilters');
                  self.$emit('input', event.target.value);
                }
              }
            )
          }
        },
    }
</script>

<style scoped>

</style>