<template>
    <accordion>
        <div slot="title-accordion"><i class="fal fa-comments-alt"></i> Примечания</div>
        <div slot="wrapper-accordion">
            <div class="accordion__header">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                        <div class="filter">
                            <date-range-picker
                                    :startDate="startDate"
                                    :endDate="endDate"
                                    @update="update_range_picker"
                                    :locale-data="locale" >
                            </date-range-picker>

                            <!--<input type="date" v-model="filter.created_at_gte" placeholder="Дата">-->
                            <!--<input type="date" v-model="filter.created_at_lte" placeholder="Дата">-->

                            <template v-if="page_type === 'subscriber'">
                                <select v-model="filter.note_type">
                                    <option value="">Все</option>
                                    <option value="subscriber">Карточка абонента</option>
                                    <option value="subscriber_connection">Подключение</option>
                                </select>
                            </template>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <div class="btn-group">

                            <app-has-perm-action-btn :perms="'subscribers_add_edit'" @action="showSubAccordion = !showSubAccordion">
                                 <template slot-scope="{ access }">
                                    <span class="btn" :class="access ? 'btn-outline-green' : 'grey'">
                                        <i class="fal" :class="!showSubAccordion ? 'fa-plus' : 'fa-minus'" ></i>
                                    </span>
                                </template>
                             </app-has-perm-action-btn>

                            <app-has-perm-action-btn :perms="'subscribers_add_edit'" @action="">
                                 <template slot-scope="{ access }">
                                    <span class="btn" :class="access ? 'btn-outline-red' : 'grey'">
                                        <i class="fal fa-trash-alt"></i>
                                    </span>
                                 </template>
                             </app-has-perm-action-btn>

                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <transition name="accordion">
                            <form @submit.prevent="has_perm('subscribers_add_edit')?add():null">
                                <div class="sub-accordion" v-if="showSubAccordion">
                                    <textarea placeholder="Напишите примечание" v-model="text"></textarea>
                                    <form-error v-bind:value="errors.text"></form-error>

                                    <span class="btn grey" @click="cancel()">Отмена</span>

                                    <app-has-perm-action-btn :perms="'subscribers_add_edit'"
                                                              @action="add()">
                                         <template slot-scope="{ access }">
                                             <div class="btn-group">
                                                <span class="btn" :class="access ? 'blue' : 'grey'"><i class="fal fa-comments-alt"></i> Добавить</span>
                                            </div>
                                         </template>
                                     </app-has-perm-action-btn>

                                </div>
                            </form>
                        </transition>
                    </div>
                </div>
            </div>
            <div class="table-container">
                <table>
                    <thead>
                    <tr>
                        <th>
                            <label class="checkbox">
                                <input type="checkbox" v-model="select_all" id="select_all">
                                <span class="checkmark"></span>
                            </label>
                        </th>
                        <th><i class="fal fa-calendar-alt"></i>Дата</th>
                        <th v-show="page_type === 'subscriber'"><i class="fal fa-sign-in"></i>Направление</th>
                        <th><i class="fal fa-user-tie"></i>Автор</th>
                        <th><i class="fal fa-comment"></i>Примечание</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="note in notes">
                        <td>
                            <label class="checkbox">
                                <input type="checkbox" v-model="record_checked">
                                <span class="checkmark"></span>
                            </label>
                        </td>
                        <td>{{ note.created_at | moment("DD/MM/YYYY hh:mm") }}</td>
                        <td v-show="page_type === 'subscriber'">
                            <template v-if="note.note_type === 'subscriber'">
                                Карточка абонента
                            </template>
                            <template v-else>
                                Подключение
                            </template>
                        </td>
                        <td>{{ note.author_expand.fio }}</td>
                        <td>{{ note.text }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </accordion>
</template>

<script>
    import DateRangePicker from 'vue2-daterange-picker';

    import Accordion from '../accordion/accordion.vue';
    import {NoteMixin} from "../../../../mixins/admin/note";
    import {API} from "../../../../api";
    import FormErrors from '../form/errors.vue';
    import {SubscriberConnectionAccordionMixin} from "../../../../mixins/admin/subscriber_connection_accordion";

    export default {
        name: "note-list",
        props: ["subscriber", "subscriber_connection", 'add-delete-perms'],
        mixins: [NoteMixin, SubscriberConnectionAccordionMixin],
        components: {
            "form-error": FormErrors,
        },
        data: function () {
            return {
                page_type: "",
                text: "",
                errors: {},
                filter: {
                    note_type: "",
                    created_at_gte: "",
                    created_at_lte: "",
                },
                showSubAccordion: false
            }
        },
        methods: {
            add() {
                var self = this;
                var data = {text: this.text};
                if (this.subscriber) {
                    data['subscriber'] = this.subscriber.id;
                }
                if (this.subscriber_connection) {
                    data['subscriber_connection'] = this.subscriber_connection.id;
                }

                this.add_note(data)
                    .then(function (response) {
                        self.load_notes(self.filter);
                        self.text = "";
                        self.showSubAccordion = !self.showSubAccordion;
                    })
                    .catch(function (error) {
                        API.default_catch(self, error);
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            },
            cancel(){
                this.text = "";
                this.showSubAccordion=false;
            },
            update_range_picker(date_range){
                this.filter.created_at_gte = this.$moment(date_range.startDate).format("YYYY-MM-DD");
                this.filter.created_at_lte = this.$moment(date_range.endDate).format("YYYY-MM-DD");
            }
        },
        watch: {
            subscriber: function (value) {
                this.filter['subscriber'] = this.subscriber.id;
                this.page_type = 'subscriber';
                this.load_notes(this.filter);
            },
            subscriber_connection: function (value) {
                this.filter['subscriber_connection'] = this.subscriber_connection.id;
                this.page_type = 'subscriber_connection';
                this.load_notes(this.filter);
            },
            filter: {
                handler(value) {
                    this.load_notes(this.filter);
                },
                deep: true
            }
        }
    }

</script>


<style scoped>

</style>