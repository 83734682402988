<template>
  <div class="dashboard-main">
    <div class="dashboard-main__item dashboard-main__item_charts dashboard-main__item_nh">
      <div class="dashboard-main__head">
        <div class="dashboard-main__title">ТОП-5 абонентов по трафику за неделю</div>
<!--        <div class="dashboard-main__filters">-->
<!--          <div class="dashboard-main__filters-select">-->
<!--            <select required>-->
<!--              <option>24 часа</option>-->
<!--              <option>7 дней</option>-->
<!--              <option>30 дней</option>-->
<!--            </select>-->
<!--          </div>-->
<!--        </div>-->
      </div>
      <div class="dashboard-table">
        <div class="table-box">
          <table class="table-box__table">
            <tbody class="table-box__tbody">
            <tr class="table-box__tr table-box__tr_nh dashboard-table" v-for="(item, index) in items" :key="index">
              <td class="table-box__td table-box__td_np">
                <span class="dashboard-table__user">
                  <span class="dashboard-table__user-icon"><i class="fal fa-user"></i></span>
                  <span class="dashboard-table__user-name">{{ item.subscriber_expand.display_name }}</span>
                </span>
              </td>
              <td class="table-box__td table-box__td_np table-box__td_center">
                <router-link class="dashboard-table__login"
                             :to="{name: 'subscribers-connections-item', params:{id: item.id }}">
                  {{ item.username }}
                </router-link>
              </td>
              <td class="table-box__td table-box__td_np">
                <span class="dashboard-table__param">
                  <span class="dashboard-table__param-icon"><i class="fal fa-briefcase"></i></span>
                  <span class="dashboard-table__param-title">{{ item.tariff_expand.name }}</span>
                </span>
              </td>
              <td class="table-box__td table-box__td_np">
                <span class="dashboard-table__param">
                  <span class="dashboard-table__param-icon"><i class="fal fa-map-marker-alt"></i></span>
                  <span class="dashboard-table__param-title">{{ item.settlement_expand.name }}</span>
                </span>
              </td>
              <td class="table-box__td table-box__td_np">
                <span class="dashboard-table__param">
                  <span class="dashboard-table__param-icon"><i class="fal fa-code-branch"></i></span>
                  <span class="dashboard-table__param-title">{{ item.department_expand.name }}</span>
                </span>
              </td>
              <td class="table-box__td table-box__td_np table-box__td_right">
                <span class="dashboard-table__traffic">
                  <span class="dashboard-table__traffic-icon"><i class="fal fa-arrow-to-bottom"></i></span>
                  <span class="dashboard-table__traffic-count">{{ item.download | prettySize(2) }}</span>
                  <span class="dashboard-table__traffic-divide"> </span>
                  <span class="dashboard-table__traffic-icon"><i class="fal fa-arrow-from-bottom"></i></span>
                  <span class="dashboard-table__traffic-count">{{ item.upload | prettySize(2) }}</span>
                </span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {DashboardStatisticsMixin} from "../../../../../mixins/admin/DashboardStatistics";

export default {
  name: "dashboard-subscribers",
  mixins: [DashboardStatisticsMixin, ],
  data() {
    return {
      items: [],
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.getSubscriberConnectionsTopTraffic();
    }
  }
}
</script>

<style scoped>

</style>