<template>
    <div class="tariffs-services">
        <TurningComponent :checkboxProp="use_service"
                          :iconPropClass="'fal fa-toolbox'"
                          @checkbox-changed="use_service = !use_service">
            <span slot="title">
                Подключить услуги к тарифу
            </span>
        </TurningComponent>
        <AdditionalServicesList v-if="use_service"
                                :tariff="tariff"
                                @added_service="added_service"
                                @deleted_service="deleted_service"
        ></AdditionalServicesList>
    </div>
</template>

<script>

    import AdditionalServicesList from "./services-list";
    import TurningComponent from "../turning-component";

    export default {
        name: "services",
        props: ["tariff"],
        components: {
            AdditionalServicesList,
            TurningComponent,
        },
        data() {
            return {
                use_service: false,
            }
        },
        methods: {
            added_service(value) {
                this.$emit("added_service", value);
            },
            deleted_service(value) {
                this.$emit("deleted_service", value);
            },
        },
        watch: {
            'tariff.services': function (value){
                if (value.length !== 0) {
                    this.use_service = true;
                }
            }
        }
    }
</script>

<style scoped>

</style>