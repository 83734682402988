<template>
    <popup :settings="settings">
        <div slot="title">IP адрес</div>
        <div slot="content">
            <div class="body-size">
                <div class="body-size__650">
                    <div class="view-modal">
<!--                        <div class="view-modal__item">-->
<!--                            <span class="view-modal__title">-->
<!--                                <span class="view-modal__icon"><i class="fal fa-calendar-check"></i></span>-->
<!--                                       Дата активации:-->
<!--                                </span>-->
<!--                                <span class="view-modal__value">-->
<!--                                    09.10.2020-->
<!--                                </span>-->
<!--                        </div>-->
                        <div class="view-modal__item">
                            <span class="view-modal__title">
                                <span class="view-modal__icon"><i class="fal fa-briefcase"></i></span>
                                    Пакет:
                            </span>
                            <span class="view-modal__value">
                               {{ service_ip_address.service_name }}
                            </span>
                        </div>
                        <div class="view-modal__item">
                            <span class="view-modal__title">
                                <span class="view-modal__icon"><i class="fal fa-cog"></i></span>
                                       Характеристики:
                            </span>
                            <span class="view-modal__value view-modal__value_bold">
                                {{ service_ip_address.description }}
                            </span>
                        </div>
                        <div v-if="Object.keys(ip_address_subscriber_connection).length &&
                                    ip_address_subscriber_connection.service_ip_address_expand" class="view-modal__item">
                            <span class="view-modal__title">
                                <span class="view-modal__icon"><i class="fal fa-calendar-alt"></i></span>
                                       Тарификация:
                            </span>
                            <span class="view-modal__value">
                                <template v-if="Object.keys(ip_address_subscriber_connection).length &&
                                    ip_address_subscriber_connection.service_ip_address_expand.charging_type === 'day'">
                                    <span class="view-modal__price">
                                        <span class="view-modal__price-item">
                                            <span class="view-modal__price-icon">₴</span>
                                            <span class="view-modal__price-value">{{ ip_address_subscriber_connection.service_ip_address_expand.charging_amount_month }}</span>
                                        </span>
                                        <span class="view-modal__price-divide"></span>
                                        <span class="view-modal__price-item">
                                            <span class="view-modal__price-icon">₴</span>
                                            <span class="view-modal__price-value">{{ ip_address_subscriber_connection.service_ip_address_expand.charging_amount_day }}</span>
                                        </span>
                                    </span>
                                </template>
                                <template v-if="Object.keys(ip_address_subscriber_connection).length &&
                                          ip_address_subscriber_connection.service_ip_address_expand.charging_type === 'month'">
                                    <span class="view-modal__price">
                                        <span class="view-modal__price-item">
                                            <span class="view-modal__price-icon">₴</span>
                                            <span class="view-modal__price-value">{{ ip_address_subscriber_connection.service_ip_address_expand.charging_amount_month }}</span>
                                        </span>
                                    </span>
                                </template>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="btn-group">
                <span class="btn btn_green" @click="popup_close">
                    <span class="btn__icon"><i class="fal fa-check"></i></span>
                    <span class="btn__title">Ознакомлен</span>
                </span>
            </div>
        </div>
    </popup>
</template>

<script>
    import Popup from '../../../../modal/popup.vue';
    import FormErrors from '../../../../form/errors.vue';
    import {CrudMixin} from "../../../../../../../mixins/admin/crud/crud_base";
    import {API} from "../../../../../../../api";

    export default {
        name: "ip-address-view",
        props: ["settings", "service_ip_address"],
        components: {
            "popup": Popup,
            "form-error": FormErrors,
        },
        mixins: [
            CrudMixin,
        ],
        data() {
            return {
                ip_address_subscriber_connection: {},
            }
        },
        methods: {
            get_service_ip_address() {
                var self = this;
                API.get('/services/ip-address-subscriber-connection/'+ self.service_ip_address.service_object_id + "/")
                    .then(function (response) {
                        self.ip_address_subscriber_connection = response.data;
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            }
        },
        watch: {
            service_ip_address(value) {
                if (this.settings.visible && value){
                    this.get_service_ip_address();
                }
            },
        }
    }
</script>

<style scoped>

</style>