<template>
    <section class="page">
        <div class="page__inner">
            <div class="container-width">
                <!--HEADER PAGE-->
                <div class="page__header">
                    <h1 class="page__title">
                        <template v-if="item.id">
                            Редактирование тарифа ({{ item.system_name }})
                        </template>
                        <template v-else>
                            Добавление тарифа
                        </template>
                    </h1>
                    <div class="btn-group">
                        <router-link title="Вернуться" :to="{ name: 'tariffs-list'}">
                        <span class="btn btn_grey">
                            <span class="btn__icon"><i class="fal fa-undo"></i></span>
                            <span class="btn__title">Вернуться</span>
                        </span>
                        </router-link>
                        <!--<span class="btn btn_blue" @click="save()">-->
                            <!--<span class="btn__icon"><i class="fal fa-save"></i></span>-->
                            <!--<span class="btn__title">Сохранить</span>-->
                        <!--</span>-->
                    </div>
                </div>
                <!--END HEADER PAGE-->
                <!-- CONTENT PAGE -->
                <div class="page-body">
                    <form action="" class="form-data" @submit.prevent="save()">
                        <div class="form-data__row">
                            <div class="form-data__section">
                                <!--Системное имя-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-toolbox"></i></span>
                                            <span class="form-data__label-title">Системное имя:</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <input type="text" v-model="item.system_name" class="form-data__input"
                                                   placeholder="Введите системное имя">
                                        </div>
                                    </div>
                                    <form-error v-bind:value="crud.errors.system_name"></form-error>
                                </fieldset>

                                <!--Название-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-briefcase"></i></span>
                                            <span class="form-data__label-title">Название:</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <input type="text" v-model="item.name" class="form-data__input"
                                                   placeholder="Введите название">
                                        </div>
                                    </div>
                                    <form-error v-bind:value="crud.errors.name"></form-error>
                                </fieldset>
                                <!--Тип абонента-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i
                                                    class="fal fa-users-crown"></i></span>
                                            <span class="form-data__label-title">Тип абонента:</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <div class="form-data__select">
                                                <select v-model="item.subscriber_type" required>
                                                    <option :value="null" hidden>Тип абонента</option>
                                                    <option v-for="subscriber_type in subscriber_types"
                                                            v-bind:value="subscriber_type.id">
                                                        {{ subscriber_type.name_expand }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <form-error v-bind:value="crud.errors.subscriber_type"></form-error>
                                </fieldset>
                                <!--Общий тариф-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-code-branch"></i></span>
                                            <span class="form-data__label-title">Все отделы:</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <div class="form-data__switch">
                                                <input type="checkbox" v-model="item.is_common_tariff"
                                                       class="form-data__input form-data__input_switch"
                                                       id="switch-common-tariff">
                                                <label class="form-data__switch-for" for="switch-common-tariff"></label>
                                                <span class="form-data__switch-status"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <form-error v-bind:value="crud.errors.is_common_tariff"></form-error>
                                </fieldset>
                                <!--Отдел-->
                                    <fieldset class="form-data__fieldset" v-if="!item.is_common_tariff">
                                        <div class="form-data__item">
                                            <div class="form-data__label">
                                                <span class="form-data__label-icon"><i
                                                        class="fal fa-code-branch"></i></span>
                                                <span class="form-data__label-title">Отдел:</span>
                                                <span class="form-data__label-control"><i
                                                        class="fal fa-asterisk"></i></span>
                                            </div>
                                            <div class="form-data__value">
                                                <div class="form-data__select">
                                                    <select v-model="item.department" required>
                                                        <option :value="null" disabled hidden>Отдел</option>
                                                        <option v-for="department in departments"
                                                                v-bind:value="department.id">
                                                            {{ department.name }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <form-error v-bind:value="crud.errors.department"></form-error>
                                    </fieldset>
                                <!--Группа-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-folder"></i></span>
                                            <span class="form-data__label-title">Группа:</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <div class="form-data__select">
                                                <select v-model="item.group" required>
                                                    <option :value="null" disabled hidden>Группа</option>
                                                    <option v-for="group in groups" v-bind:value="group.id">
                                                        {{ group.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <form-error v-bind:value="crud.errors.group"></form-error>
                                </fieldset>
                                <!--Скрыть тариф-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-eye-slash"></i></span>
                                            <span class="form-data__label-title">Скрыть тариф у абонента:</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <div class="form-data__switch">
                                                <input type="checkbox" v-model="item.is_hidden_for_subscriber"
                                                       class="form-data__input form-data__input_switch"
                                                       id="switch-hi">
                                                <label class="form-data__switch-for" for="switch-hi"></label>
                                                <span class="form-data__switch-status"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <form-error v-bind:value="crud.errors.is_hidden_for_subscriber"></form-error>
                                </fieldset>
                            </div>
                            <div class="form-data__section">
                                <!--Технология-->
                                <template v-for="(connection_type_item, index) in item.connection_type">
                                    <fieldset class="form-data__fieldset">
                                        <div class="form-data__item">
                                            <div class="form-data__label">
                                                <span class="form-data__label-icon"><i class="fal fa-ethernet"></i></span>
                                                <span class="form-data__label-title">Технология #{{ index + 1 }}:</span>
                                                <span class="form-data__label-control"><i
                                                        class="fal fa-asterisk"></i></span>
                                            </div>
                                            <div class="form-data__value">
                                                <div class="form-data__select">

                                                    <select v-model="item.connection_type[index]" required>
                                                        <option :value="undefined" hidden>Технология</option>
                                                        <option v-for="subscriber_type in get_connection_types(index)"
                                                                v-bind:value="subscriber_type.id">
                                                            {{ subscriber_type.label }}
                                                        </option>
                                                    </select>

                                                </div>
                                                <template v-if="index !== 0">
                                                    <div class="form-data__add-input" @click.prevent="remove_connection_type(index)"
                                                         data-title="Удалить технологию">
                                                        <i class="fal fa-minus"></i>
                                                    </div>
                                                </template>
                                                <template v-else >
                                                    <span class="form-data__add-input" @click.prevent="add_connection_type()"
                                                          data-title="Добавить технологию">
                                                        <i class="fal fa-plus-circle"></i>
                                                    </span>
                                                </template>
                                            </div>
                                        </div>
                                        <form-error v-if="crud.errors.connection_type && crud.errors.connection_type.length" v-bind:value="crud.errors.connection_type[index].id"></form-error>
                                    </fieldset>
                                </template>

                                <!--Тарификация-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i
                                                    class="fal fa-calendar-alt"></i></span>
                                            <span class="form-data__label-title">Тарификация:</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <div class="form-data__select">
                                                <select v-model="item.charging_type" required>
                                                    <option :value="null" hidden>Тарификация</option>
                                                    <option v-for="tariffs_charging_type in tariffs_charging_types"
                                                            v-bind:value="tariffs_charging_type.key">
                                                        {{ tariffs_charging_type.label }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <form-error :value="crud.errors.charging_type"></form-error>
                                    </div>
                                </fieldset>
                                <!--Стоимость-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-wallet"></i></span>
                                            <span class="form-data__label-title">Стоимость:</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <template v-if="item.charging_type === 'month'">
                                            <div class="form-data__value">
                                                <div class="form-data__input-money">
                                                    <span class="form-data__input-subtitle">Месяц</span>
                                                    <input type="text" v-model="item.charging_amount_month"
                                                           class="form-data__input form-data__input_money"
                                                           placeholder="0.00">
                                                    <form-error
                                                            v-bind:value="crud.errors.charging_amount_month"></form-error>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-if="item.charging_type === 'day'">
                                            <div class="form-data__value">
                                                <div class="form-data__input-money">
                                                    <span class="form-data__input-subtitle">Месяц</span>
                                                    <input type="text" v-model="item.charging_amount_month"
                                                           class="form-data__input form-data__input_money"
                                                           placeholder="0.00">
                                                    <form-error
                                                            v-bind:value="crud.errors.charging_amount_month"></form-error>
                                                </div>
                                                <div class="form-data__input-money">
                                                    <span class="form-data__input-subtitle">День</span>
                                                    <input type="text" v-model="item.charging_amount_day"
                                                           class="form-data__input form-data__input_money"
                                                           placeholder="0.00">
                                                    <form-error
                                                            v-bind:value="crud.errors.charging_amount_day"></form-error>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-if="item.charging_type === 'hour'">
                                            <div class="form-data__value">
                                                <div class="form-data__input-money">
                                                    <span class="form-data__input-subtitle">Час</span>
                                                    <input type="text" v-model="item.charging_amount_hour"
                                                           class="form-data__input form-data__input_money"
                                                           placeholder="0.00">
                                                    <form-error
                                                            v-bind:value="crud.errors.charging_amount_hour"></form-error>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </fieldset>
                                <!--Скорость-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i
                                                    class="fal fa-tachometer-alt-fastest"></i></span>
                                            <span class="form-data__label-title">Скорость (Kbit/s):</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <div class="form-data__input-speed">
                                                <span class="form-data__input-subtitle">TX</span>
                                                <input type="text" v-model="item.speed_tx"
                                                       class="form-data__input form-data__input_speed" placeholder="TX">
                                                <form-error v-bind:value="crud.errors.speed_tx"></form-error>
                                            </div>
                                            <div class="form-data__input-speed">
                                                <span class="form-data__input-subtitle">RX</span>
                                                <input type="text" v-model="item.speed_rx"
                                                       class="form-data__input form-data__input_speed" placeholder="RX">
                                                <form-error v-bind:value="crud.errors.speed_rx"></form-error>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>

                                <!--Burst-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-rocket"></i></span>
                                            <span class="form-data__label-title">Burst (Kbit/s):</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <div class="form-data__input-speed">
                                                <span class="form-data__input-subtitle">TX</span>
                                                <input type="text" v-model="item.burst_speed_tx"
                                                       class="form-data__input form-data__input_speed"
                                                       placeholder="TX">
                                                <form-error v-bind:value="crud.errors.burst_speed_tx"></form-error>
                                            </div>
                                            <div class="form-data__input-speed">
                                                <span class="form-data__input-subtitle">RX</span>
                                                <input type="text" v-model="item.burst_speed_rx"
                                                       class="form-data__input form-data__input_speed"
                                                       placeholder="RX">
                                                <form-error v-bind:value="crud.errors.burst_speed_rx"></form-error>
                                            </div>
                                            <div class="form-data__input-time">
                                                <span class="form-data__input-subtitle">Time/s</span>
                                                <input type="text" v-model="item.burst_time"
                                                       class="form-data__input form-data__input_time"
                                                       placeholder="Time">
                                                <form-error v-bind:value="crud.errors.burst_time"></form-error>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <!--Лимит трафика-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i
                                                    class="fal fa-hourglass-half"></i></span>
                                            <span class="form-data__label-title">Лимит трафика (Kbit/s):</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <div class="form-data__input-speed">
                                                <span class="form-data__input-subtitle">Download</span>
                                                <input type="text" v-model="item.traffic_limit_tx"
                                                       class="form-data__input form-data__input_speed"
                                                       placeholder="Download">
                                                <form-error v-bind:value="crud.errors.traffic_limit_tx"></form-error>
                                            </div>
                                            <div class="form-data__input-speed">
                                                <span class="form-data__input-subtitle">Upload</span>
                                                <input type="text" v-model="item.traffic_limit_rx"
                                                       class="form-data__input form-data__input_speed"
                                                       placeholder="Upload">
                                                <form-error v-bind:value="crud.errors.traffic_limit_rx"></form-error>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <!--Списывать деньги, если была сессия-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-power-off"></i></span>
                                            <span class="form-data__label-title">Списывать средства при сессии:</span>
                                            <span class="form-data__label-control"><i class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <div class="form-data__switch">
                                                <input type="checkbox" v-model="item.withdraw_if_was_session"
                                                       class="form-data__input form-data__input_switch"
                                                       id="switch-was-session">
                                                <label class="form-data__switch-for" for="switch-was-session"></label>
                                                <span class="form-data__switch-status"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <form-error v-bind:value="crud.errors.withdraw_if_was_session"></form-error>
                                </fieldset>
                            </div>
                        </div>
                        <!--Вывод настройки акционного тарифа-->
                        <Settings :tariff="item"
                                  :errors="crud.errors.promotional_tariff_settings"
                                  @addPromotionalSettings="addPromotionalSettings"
                                  @deletePromotionalSettings="deletePromotionalSettings" />

                        <!--Вывод дополнительных услуг-->
                        <Services :tariff="item"
                                  @added_service="added_service"
                                  @deleted_service="deleted_service" />

                        <!--Вывод регионов-->
                        <Regions :tariff="item" @set_settlements="set_settlements" />

                        <div class="form-data__footer form-data__footer_right">
                            <div class="btn-group">
                                <span class="btn btn_blue" @click="save()">
                                    <span class="btn__icon"><i class="fal fa-save"></i></span>
                                    <span class="btn__title">Сохранить</span>
                                </span>
                            </div>
                        </div>
                    </form>
                </div>
                <!-- END CONTENT PAGE -->
            </div>
        </div>
    </section>
</template>

<script>
    import {DepartmentMixin} from "../../../../mixins/admin/department";
    import FormErrors from '../form/errors.vue';
    import {API} from "../../../../api";
    import {EmployeeMixin} from "../../../../mixins/admin/employee";
    import {User} from "../../../../mixins/admin/user";
    import {TariffMixin} from "../../../../mixins/admin/tariff";
    import {GroupMixin} from "../../../../mixins/admin/group";
    import {SubscriberTypeMixin} from "../../../../mixins/admin/subscriber_type";
    import {ConnectionTypeMixin} from "../../../../mixins/admin/connection_type";
    import {TariffChargingTypeMixin} from "../../../../mixins/admin/tariff_charging_types";
    import _ from 'lodash';
    import {loader} from "../../../../models/loader";
    import {SearchMixin} from "../../../../mixins/admin/search";
    import Regions from "./regions/regions";
    import Services from "./services/services";
    import {ServiceMixin} from "../../../../mixins/admin/services";
    import Settings from "./promotional_tariff_settings/settings";

    export default {
        name: 'tariffs-add-edit',
        mixins: [
            TariffMixin,
            GroupMixin,
            EmployeeMixin,
            ConnectionTypeMixin,
            DepartmentMixin,
            SubscriberTypeMixin,
            TariffChargingTypeMixin,
            ServiceMixin,
            SearchMixin],
        data: function () {
            return {
                item: {
                    id: null,
                    system_name: "",
                    name: "",
                    is_hidden_for_subscriber: false,
                    withdraw_if_was_session: false,
                    is_common_tariff: false,
                    department: null,
                    group: null,
                    subscriber_type: null,
                    charging_type: null,
                    charging_amount_month: 0,
                    charging_amount_day: 0,
                    charging_amount_hour: 0,
                    traffic_limit_tx: 0,
                    traffic_limit_rx: 0,
                    connection_type: [],
                    speed_tx: 0,
                    speed_rx: 0,
                    burst_speed_tx: 0,
                    burst_speed_rx: 0,
                    burst_time: 0,
                    settlements: [],
                    services: [],
                    promotional_tariff_settings: null,
                },
                crud: {
                    api: '/tariffs/',
                    errors: {
                        promotional_tariff_settings: [
                            {
                                start_date: [],
                                finish_date: [],
                                tariff_to: [],
                                finish_connect_date: [],
                            },
                        ]
                    }
                },
                services: [],
            }
        },
        components: {
            Settings,
            "form-error": FormErrors,
            Regions,
            Services,
        },
        created: function () {
            this.$root.$on('search_hit_tariffs', (function (data) {
                this.fetchUser();
            }).bind(this));

            this.$root.$on('search_clear_tariffs', (function (data) {
                this.fetchUser();
            }).bind(this));

            this.fetchUser();
        },
        beforeDestroy: function () {
            this.destroy_search_listeners('tariffs');
        },
        methods: {
            fetchUser() {
                if (this.$route.params.id) {
                    this.item.id = this.$route.params.id;
                    this.get_tariff(this.item.id);
                }

                this.load_departments();
                this.load_groups();
                this.load_subscriber_types();
                this.load_connection_type();
                this.load_charging_types();
                this.load_services();
            },
            save: function () {
                var self = this;
                self.loader_show();
                let data = Object.assign({}, self.item);

                var connection_types = [];
                for(var connection_type_id of data['connection_type']){
                    connection_types.push({"id": connection_type_id})
                }
                data['connection_type'] = connection_types;

                if (data['promotional_tariff_settings']) {
                    let promotional_tariff_settings = data['promotional_tariff_settings'];

                    this.set_correct_date(data, promotional_tariff_settings, 'start_date')
                    this.set_correct_date(data, promotional_tariff_settings, 'finish_date')
                    this.set_correct_date(data, promotional_tariff_settings, 'finish_connect_date')
                }

                if (self.item.id) {
                    // Edit by id
                    API.patch(self.crud.api + self.item.id + '/', data).then(function (response) {
                        self.$router.push({name: 'tariffs-list'});
                    })
                        .catch(function (error) {
                            API.default_catch(self.crud, error);
                            self.catch_non_field_error();
                        })
                        .then(function () {
                            API.default_always(self);
                        })
                } else {
                    // Create new
                    API.post(self.crud.api, data)
                        .then(function (response) {
                            self.item = {};
                            self.$router.push({name: 'tariffs-list'});
                        })
                        .catch(function (error) {
                            API.default_catch(self.crud, error);
                            self.catch_non_field_error();
                        })
                        .then(function () {
                            API.default_always(self);
                        })
                }
            },
            set_correct_date(data, promotional_tariff_settings, field) {
                if (promotional_tariff_settings[field]) {
                    data['promotional_tariff_settings'][field] =
                        this.$moment(promotional_tariff_settings[field]).format("YYYY-MM-DD");
                } else {
                    data['promotional_tariff_settings'][field] = null
                }
            },
            set_settlements(settlements){
                // console.log("set_settlements ")
                this.item.settlements = settlements;
            },
            catch_non_field_error() {
                var self = this;
                if (self.crud.errors.non_field_errors) {
                    this.alert("Внимание", self.crud.errors.non_field_errors[0]);
                }
            },
            calculate_charging_amount_day: _.throttle((self) => {
                if (self.item.charging_type === 'day') {
                    loader.active = false;
                    self.tariffs_calc_day_by_month(self.item.charging_amount_month).then(function (response) {
                        self.item.charging_amount_day = response.data.charging_amount_day;
                        loader.active = true;
                    })
                }
            }, 1000),
            initial_connection_type_list(){
                if(this.item.id) {
                    this.item.connection_type = [];
                    if(this.tariff.connection_type_expand) {
                        for (var connection_type of this.tariff.connection_type_expand) {
                            this.item.connection_type.push(connection_type.id);
                        }
                    }
                } else {
                    if (this.item.connection_type.length <= 0) {
                        this.add_connection_type();
                    }
                }
            },
            get_connection_types(index){
                var connection_types = [];
                for(var item of this.connection_types){
                    var item_index = this.item.connection_type.indexOf(item.id);
                    if(item_index === -1 || item_index === index){
                        connection_types.push(item);
                    }
                }

                return connection_types;
            },
            add_connection_type() {
                if (this.item.connection_type.length < this.connection_types.length) {
                    this.item.connection_type.push("");
                } else {
                    // this.$set(this.phone_errors, 'phone', ["Максимальное кол-во телфонных номеров"]);
                }
            },
            remove_connection_type(key) {
                this.item.connection_type.splice(key, 1);
                // this.$delete(this.phone_errors, 'phone');
            },
            addPromotionalSettings(settings) {
                this.item.promotional_tariff_settings = settings;
            },
            deletePromotionalSettings() {
                this.item.promotional_tariff_settings = null;
            },
            added_service(service) {
                var find = false;
                for(var item of this.item.services) {
                    console.log(item.object_type, "===", service.object_type)

                    if(item.object_type === service.object_type) {
                        find = true;
                    }
                }

                if(!find) {
                    this.item.services.push(service);
                } else {
                    this.alert("Внимание", "Сервис `" + service.object_name +"` уже добавлен, " +
                        "сначала нужно удалить текущий");
                }
            },
            deleted_service(service){
                for(var item of this.item.services) {
                    console.log(item.object_type, "===", service.object_type)

                    if(item.object_type === service.object_type) {
                        this.item.services.splice(this.item.services.indexOf(item))
                    }
                }
            },
        },
        watch: {
            tariff(value) {
                this.$set(this, 'item', Object.assign({}, this.item, value));
                this.initial_connection_type_list();
                // console.log(value)
                this.item.services = value.services_expand;
                this.item.promotional_tariff_settings = value.promotional_tariff_settings_expand;
            },
            connection_types(value) {
                this.initial_connection_type_list();
            },
            'item.charging_type': function (value) {
                this.calculate_charging_amount_day(this);
            },
            'item.charging_amount_month': function (value) {
                this.calculate_charging_amount_day(this);
            },
            'item.is_common_tariff': function (value) {
                if (value) {
                  this.item.department = null;
                }
            },
        }
    }
</script>

<style scoped>

</style>