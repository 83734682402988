<template>
    <div>
        <div class="grid">
            <div class="grid__col">
                <div class="grid__box">
                    <div class="services">
                        <div class="services__head">
                            <div class="services__title">Дополнительные услуги</div>
                        </div>
                        <div class="table-box">
                            <table class="table-box__table">
                                <thead class="table-box__thead">
                                <tr class="table-box__thead-tr">
                                    <th class="table-box__th">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-toolbox"></i></span>
                                        <span class="table-box__thead-title">Название услуги</span>
                                    </span>
                                    </th>
                                    <th class="table-box__th">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-briefcase"></i></span>
                                        <span class="table-box__thead-title">Пакет</span>
                                    </span>
                                    </th>
                                    <th class="table-box__th">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-cog"></i></span>
                                        <span class="table-box__thead-title">Характеристики</span>
                                    </span>
                                    </th>
                                    <th class="table-box__th">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-calendar-alt"></i></span>
                                        <span class="table-box__thead-title">Тарификация</span>
                                    </span>
                                    </th>
                                    <th class="table-box__th">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-check-double"></i></span>
                                        <span class="table-box__thead-title">Статус</span>
                                    </span>
                                    </th>
                                    <th class="table-box__th">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-toggle-off"></i></span>
                                        <span class="table-box__thead-title">Управление</span>
                                    </span>
                                    </th>
                                    <th class="table-box__th">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-mouse-pointer"></i></span>
                                        <span class="table-box__thead-title">Действия</span>
                                    </span>
                                    </th>
                                </tr>
                                </thead>
                                <tbody class="table-box__tbody">
                                <!--IPTV-triniti-->
                                <template v-if="iptv_triniti_services.length > 0">
                                    <service-iptv-triniti-list-item v-for="(service, index) in iptv_triniti_services"
                                                                    :subscriber_connection="subscriber_connection"
                                                                    :errors="errors"
                                                                    :service="service"
                                                                    :index="subscriber_services.service_list.additional_services.indexOf(service)"
                                                                    @popup_tv_view="popup_tv_view"
                                                                    @popup_tv_device="popup_tv_device"
                                                                    @popup_tv_settings="popup_tv_settings"
                                                                    @change_is_active="change_is_active">
                                    </service-iptv-triniti-list-item>
                                </template>
                                <template v-else>
                                    <service-iptv-triniti-list-item :subscriber_connection="subscriber_connection"
                                                                    :errors="errors"
                                                                    @add_new_service_by_checkbox="add_new_service_by_checkbox">
                                    </service-iptv-triniti-list-item>
                                </template>
                                <!--IP-address-->
                                <template v-if="ip_address_services.length > 0">
                                    <service-ip-address-list-item v-for="(service, index) in ip_address_services"
                                                                    :subscriber_connection="subscriber_connection"
                                                                    :errors="errors"
                                                                    :service="service"
                                                                    :index="subscriber_services.service_list.additional_services.indexOf(service)"
                                                                    @popup_ip_address_view="popup_ip_address_view"
                                                                    @popup_ip_address_clear="popup_ip_address_clear"
                                                                    @popup_ip_address_settings="popup_ip_address_settings"
                                                                    @change_is_active="change_is_active">
                                    </service-ip-address-list-item>
                                </template>
                                <template v-else>
                                    <service-ip-address-list-item :subscriber_connection="subscriber_connection"
                                                                  :errors="errors"
                                                                  @add_new_service_by_checkbox="add_new_service_by_checkbox">
                                    </service-ip-address-list-item>
                                </template>
                                </tbody>
                            </table>
                        </div>
                        <!--Подсчет суммы услуг-->
                        <total-cost-services :subscriber_connection="subscriber_connection"
                                             :subscriber_services="subscriber_services">
                        </total-cost-services>

                        <template v-if="has_perm('personal_services_management_activate_deactivate')">
                            <!--Действие "Добавить услугу"-->
                            <div class="services__action">

                                <app-has-perm-action-btn :perms="'personal_services_management_activate_deactivate'"
                                                         @action="popupAddHandler">
                                     <template slot-scope="{ access }">

                                         <div class="services__add">
                                            <span :class="access ? 'services__add-icon' : 'btn-l_grey'"><i
                                                            class="fal fa-plus"></i></span>
                                            <span class="services__add-title">Подключить услугу</span>
                                        </div>

                                     </template>
                                 </app-has-perm-action-btn>

                            </div>
                        </template>
                            <!-- Add-serivce popup -->
                            <add-services-popup :subscriber_connection="subscriber_connection"
                                                @update_subscriber_connection="update_subscriber_connection"
                                                @load_subscriber_services="load_subscriber_services"
                                                @clear_service_add_by_checkbox='service_type_added_by_checkbox = ""'
                                                :settings="add_popup"
                                                :subscriber_services="subscriber_services"
                                                :service_type_added_by_checkbox="service_type_added_by_checkbox">
                            </add-services-popup>
                            <tv-settings :settings="tv_settings_popup"
                                         :subscriber_services="subscriber_services"
                                         :service_tv="subscriber_services.service_list.additional_services[service_index]"
                                         @update_subscriber_connection="update_subscriber_connection"></tv-settings>
                            <tv-device v-if="tv_device_popup.visible"
                                       :settings="tv_device_popup"
                                       :service_tv="subscriber_services.service_list.additional_services[service_index]"
                                       @update_subscriber_connection="update_subscriber_connection"></tv-device>
                            <tv-view v-if="tv_view_popup.visible"
                                     :settings="tv_view_popup"
                                     :service_tv="subscriber_services.service_list.additional_services[service_index]"></tv-view>
                            <tv-clear :settings="tv_clear_popup"></tv-clear>
                            <ip-address-view :settings="ip_address_view_popup"
                                             :service_ip_address="service_ip_address_obj"></ip-address-view>
                            <ip-address-settings :settings="ip_address_settings_popup"
                                                 :subscriber_services="subscriber_services"
                                                 :service_ip_address="service_ip_address_obj"
                                                 @update_subscriber_connection="update_subscriber_connection"></ip-address-settings>
                            <ip-address-clear :settings="ip_address_clear_popup"
                                              :service_ip_address="service_ip_address_obj"
                                              @update_subscriber_connection="update_subscriber_connection"></ip-address-clear>
                    </div>
                </div>
            </div>
        </div>
        <!--Сервисные услуги-->
        <template v-if="is_subscriber_private">
            <div class="tabs-sc__header">
                <div class="tabs-sc__title">
                    <span class="tabs-sc__action-icon"><i class="fal fa-toolbox"></i></span>
                    <span class="tabs-sc__action-title">Сервисные услуги</span>
                </div>
            </div>

            <loyalty-services :loyalty_services="subscriber_services.service_list.loyalty_services"
                              :subscriber_connection="subscriber_connection"
                              :errors="errors"
                              @update_subscriber_connection="update_subscriber_connection"
                              @change_is_active="change_is_active"
                              @add_new_service_by_checkbox="add_new_service_by_checkbox">
            </loyalty-services>
        </template>
    </div>
</template>

<script>
    import {CrudMixin} from "../../../../../mixins/admin/crud/crud_base";
    import {CrudListMixin} from "../../../../../mixins/admin/crud/crud_list";
    import ServicesList from "./services__list";
    import FormErrors from '../../form/errors.vue';

    import AddServices from "./add-services-popup";

    import TvView from "./service_forms/tv/tv-view"
    import TvDevice from "./service_forms/tv/tv-device"
    import TvSettings from "./service_forms/tv/tv-settings"
    import TvClear from "./service_forms/tv/tv-clear"

    import IPAddressView from "./service_forms/ip-address/ip-address-view"
    import IPAddressSettings from "./service_forms/ip-address/ip-address-settings"
    import IPAddressClear from "./service_forms/ip-address/ip-address-clear"
    import ServiceIPTVTrinitiListItem from "./additional_services/service-iptv-triniti-list-item"
    import LoyaltyServices from './loyalty_services'

    import {API} from "../../../../../api";
    import ServiceIpAddressListItem from "./additional_services/service-ip-address-list-item";
    import TotalCostServices from "./total_cost_services"
    import {ServiceActivationMixin} from "../../../../../mixins/admin/service_activation";

    export default {
        name: "services",
        props: ['subscriber_connection'],
        data() {
            return {
                is_active: {},
                subscriber_services: {
                    service_list: {
                        additional_services: [],
                        available_service_list: []
                    }
                },
                iptv_triniti_services: [],
                ip_address_services: [],
                tv_view_popup: {
                    visible: false
                },
                tv_device_popup: {
                    visible: false
                },
                tv_settings_popup: {
                    visible: false
                },
                tv_clear_popup: {
                    visible: false
                },
                ip_address_view_popup: {
                    visible: false
                },
                ip_address_settings_popup: {
                    visible: false
                },
                service_index: null,
                ip_address_clear_popup: {
                    visible: false
                },
                service_ip_address_obj: {},
                service_type_added_by_checkbox: "",
                is_subscriber_private: false,
                errors: {},
            }
        },
        components: {
            ServicesList,
            "add-services-popup": AddServices,
            "tv-view": TvView,
            "tv-device": TvDevice,
            "tv-settings": TvSettings,
            "tv-clear": TvClear,
            "ip-address-view": IPAddressView,
            "ip-address-settings": IPAddressSettings,
            "ip-address-clear": IPAddressClear,
            "form-error": FormErrors,
            "service-iptv-triniti-list-item": ServiceIPTVTrinitiListItem,
            "service-ip-address-list-item": ServiceIpAddressListItem,
            "loyalty-services": LoyaltyServices,
            "total-cost-services": TotalCostServices,
        },
        mixins: [CrudMixin, CrudListMixin, ServiceActivationMixin],
        methods: {
            load_subscriber_services() {
                var self = this;

                self.clearServicesData();
                API.get('/subscriber-connections/' + this.subscriber_connection.id + '/services/')
                    .then(function (response) {
                        self.subscriber_services = response.data;
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            },
            clearServicesData() {
              this.iptv_triniti_services = [];
              this.ip_address_services = [];
            },
            update_subscriber_connection() {
                this.$emit("update_subscriber_connection");
            },
            change_is_active(service, index) {

                if (service.service_type === "service_ip_address") {
                    // if service isn't set in connection open add popup else active/deactive service
                    if (!service.is_established_in_connection) {
                        this.popup_ip_address_settings(service);
                    } else {
                        this.change_ip_address_is_active(service, index);
                    }
                } else if (service.service_type === "service_iptv_triniti") {
                    // if service isn't set in connection open add popup else active/deactive service
                    if (!service.is_established_in_connection) {
                        // this.popup_ip_address_settings(service);
                    } else {
                        this.change_iptv_triniti_is_active(service, index);
                    }
                } else if (service.service_type === "service_trust_day") {
                    this.change_trust_day_is_active(service, index);
                }
            },
            popup_tv_settings(index) {
                this.tv_settings_popup.visible = true;
                this.crud.errors = {};
                this.service_index = index;
            },
            popup_tv_device(index) {
                this.tv_device_popup.visible = true;
                this.crud.errors = {};
                this.service_index = index;
            },
            popup_tv_view(index) {
                this.tv_view_popup.visible = true;
                this.crud.errors = {};
                this.service_index = index;
            },
            popup_ip_address_view(service) {
                this.ip_address_view_popup.visible = true;
                this.crud.errors = {};
                this.service_ip_address_obj = service;
            },
            popup_ip_address_settings(service) {
                this.ip_address_settings_popup.visible = true;
                this.crud.errors = {};
                this.service_ip_address_obj = service;
            },
            popup_ip_address_clear(service) {
                this.ip_address_clear_popup.visible = true;
                this.crud.errors = {};
                this.service_ip_address_obj = service;
            },
            add_new_service_by_checkbox(service_type) {
                this.service_type_added_by_checkbox = service_type;
                this.popupAddHandler(service_type);
            },
            popupAddHandler(service_type) {
                if (this.subscriber_connection.balance < 0 && service_type !== 'service_trust_day') {
                    this.alert("Услуга недоступна", "Ваш баланс отрицательный");
                } else {
                    this.popup_add();
                }
            }
        },
        watch: {
            subscriber_connection(value) {
                this.is_subscriber_private = value.subscriber_expand.subscriber_type_expand.name === 'private';
                this.load_subscriber_services();
            },
            subscriber_services(values) {
                if (values.service_list.additional_services.length > 0) {
                    this.iptv_triniti_services = values.service_list.additional_services.filter(elm => elm.service_type === 'service_iptv_triniti');
                    this.ip_address_services = values.service_list.additional_services.filter(elm => elm.service_type === 'service_ip_address');
                }
            }
        }
    }
</script>

<style scoped>

</style>