<template>
    <div>
        <!--Поля для юр лиц -->
        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label>Организационная форма: <span
                            class="control"><i
                            class="fas fa-star-of-life"></i></span></label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                    <input type="text" v-model="subscriber.subscriber_business_details.organizational_form" placeholder="Организационная форма">
                </div>
                <div class="clearfix"></div>
                <form-error v-bind:value="errors.subscriber_business_details.organizational_form"></form-error>
            </div>
        </div>
        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label>Название предприятия: <span
                            class="control"><i
                            class="fas fa-star-of-life"></i></span></label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                    <input type="text" v-model="subscriber.subscriber_business_details.company_name" placeholder="Название предприятия">
                </div>
                <div class="clearfix"></div>
                <form-error v-bind:value="errors.subscriber_business_details.company_name"></form-error>
            </div>
        </div>
        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label>Код ЕРДПОУ: <span
                            class="control"><i
                            class="fas fa-star-of-life"></i></span></label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                    <input type="text" v-model="subscriber.subscriber_business_details.registry_code" placeholder="ЕРДПОУ">
                </div>
                <div class="clearfix"></div>
                <form-error v-bind:value="errors.subscriber_business_details.registry_code"></form-error>
            </div>
        </div>

        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label>ИНН: <span
                            class="control"><i
                            class="fas fa-star-of-life"></i></span></label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                    <input type="text" v-model="subscriber.subscriber_business_details.taxpayer_id_number" placeholder="ИНН">
                </div>
                <div class="clearfix"></div>
                <form-error v-bind:value="errors.subscriber_business_details.taxpayer_id_number"></form-error>
            </div>
        </div>

        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label>Плательщик Плательщик НДС: <span
                            class="control"><i
                            class="fas fa-star-of-life"></i></span></label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                    <label class="radio">
                        <input type="radio" v-model="subscriber.subscriber_business_details.is_vat_payer" :value="false" required/>
                        <div class="radio__text">Нет</div>
                    </label>
                    <label class="radio">
                        <input type="radio" v-model="subscriber.subscriber_business_details.is_vat_payer" :value="true" required/>
                        <div class="radio__text">Да</div>
                    </label>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>

        <template v-if="subscriber.subscriber_business_details.is_vat_payer">

            <div class="add-data__item">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                        <label>№ свидетельства НДС: <span
                                class="control"><i
                                class="fas fa-star-of-life"></i></span></label>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                        <input type="text" v-model="subscriber.subscriber_business_details.vat_certificate_number" placeholder="№ свидетельства НДС">
                    </div>
                    <div class="clearfix"></div>
                    <form-error v-bind:value="errors.subscriber_business_details.vat_certificate_number"></form-error>
                </div>
            </div>

        </template>

        <div class="add-data__header">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h3>Юридический адрес:</h3>
                </div>
            </div>
        </div>
        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label>Область: <span
                            class="control"><i
                            class="fas fa-star-of-life"></i></span></label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                    <input type="text" v-model="subscriber.subscriber_business_details.business_address_region" placeholder="Область">
                </div>
                <div class="clearfix"></div>
                <form-error v-bind:value="errors.subscriber_business_details.business_address_region"></form-error>
            </div>
        </div>
        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label>Район: <span
                            class="control"><i
                            class="fas fa-star-of-life"></i></span></label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                    <input type="text" v-model="subscriber.subscriber_business_details.business_address_district" placeholder="Район">
                </div>

                <div class="clearfix"></div>
                <form-error v-bind:value="errors.subscriber_business_details.business_address_district"></form-error>
            </div>
        </div>
        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label>Населенный пункт: <span
                            class="control"><i
                            class="fas fa-star-of-life"></i></span></label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                    <input type="text" v-model="subscriber.subscriber_business_details.business_address_settlement" placeholder="Населенный пункт">
                </div>

                <div class="clearfix"></div>
                <form-error v-bind:value="errors.subscriber_business_details.business_address_settlement"></form-error>
            </div>
        </div>
        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label>Улица: <span
                            class="control"><i
                            class="fas fa-star-of-life"></i></span></label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                    <input type="text" v-model="subscriber.subscriber_business_details.business_address_street" placeholder="Улица">
                </div>

                <div class="clearfix"></div>
                <form-error v-bind:value="errors.subscriber_business_details.business_address_street"></form-error>
            </div>
        </div>
        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label>Дом: <span
                            class="control"><i
                            class="fas fa-star-of-life"></i></span></label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                    <input type="text" v-model="subscriber.subscriber_business_details.business_address_house_number" placeholder="Дом">
                </div>

                <div class="clearfix"></div>
                <form-error v-bind:value="errors.subscriber_business_details.business_address_house_number"></form-error>
            </div>
        </div>
        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label>Квартира/Офис: <span
                            class="control"><i
                            class="fas fa-star-of-life"></i></span></label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                    <input type="text" v-model="subscriber.subscriber_business_details.business_address_apartment_number" placeholder="Квартира/Офис">
                </div>

                <div class="clearfix"></div>
                <form-error v-bind:value="errors.subscriber_business_details.business_address_apartment_number"></form-error>
            </div>
        </div>
        <div class="add-data__header">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h3>Фактический адрес:</h3>
                </div>
            </div>
        </div>
        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label>Область: <span
                            class="control"><i
                            class="fas fa-star-of-life"></i></span></label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                    <input type="text" v-model="subscriber.subscriber_business_details.actual_address_region" placeholder="Область">
                </div>

                <div class="clearfix"></div>
                <form-error v-bind:value="errors.subscriber_business_details.actual_address_region"></form-error>
            </div>
        </div>
        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label>Район: <span
                            class="control"><i
                            class="fas fa-star-of-life"></i></span></label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                    <input type="text" v-model="subscriber.subscriber_business_details.actual_address_district" placeholder="Район">
                </div>

                <div class="clearfix"></div>
                <form-error v-bind:value="errors.subscriber_business_details.actual_address_district"></form-error>
            </div>
        </div>
        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label>Населенный пункт: <span
                            class="control"><i
                            class="fas fa-star-of-life"></i></span></label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                    <input type="text" v-model="subscriber.subscriber_business_details.actual_address_settlement" placeholder="Населенный пункт">
                </div>

                <div class="clearfix"></div>
                <form-error v-bind:value="errors.subscriber_business_details.actual_address_settlement"></form-error>
            </div>
        </div>
        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label>Улица: <span
                            class="control"><i
                            class="fas fa-star-of-life"></i></span></label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                    <input type="text" v-model="subscriber.subscriber_business_details.actual_address_street" placeholder="Улица">
                </div>

                <div class="clearfix"></div>
                <form-error v-bind:value="errors.subscriber_business_details.actual_address_street"></form-error>
            </div>
        </div>
        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label>Дом: <span
                            class="control"><i
                            class="fas fa-star-of-life"></i></span></label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                    <input type="text" v-model="subscriber.subscriber_business_details.actual_address_house_number" placeholder="Дом">
                </div>

                <div class="clearfix"></div>
                <form-error v-bind:value="errors.subscriber_business_details.actual_address_house_number"></form-error>
            </div>
        </div>
        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label>Квартира/Офис: <span
                            class="control"><i
                            class="fas fa-star-of-life"></i></span></label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                    <input type="text" v-model="subscriber.subscriber_business_details.actual_address_apartment_number" placeholder="Квартира/Офис">
                </div>

                <div class="clearfix"></div>
                <form-error v-bind:value="errors.subscriber_business_details.actual_address_apartment_number"></form-error>
            </div>
        </div>

        <div class="add-data__header">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h3>Банковские данные:</h3>
                </div>
            </div>
        </div>
        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label>Р/С: <span
                            class="control"><i
                            class="fas fa-star-of-life"></i></span></label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                    <input type="text" v-model="subscriber.subscriber_business_details.bank_payment_account" placeholder="Р/С">
                </div>

                <div class="clearfix"></div>
                <form-error v-bind:value="errors.subscriber_business_details.bank_payment_account"></form-error>
            </div>
        </div>
        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label>Название Банка: <span
                            class="control"><i
                            class="fas fa-star-of-life"></i></span></label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                    <input type="text" v-model="subscriber.subscriber_business_details.bank_name" placeholder="Название Банка">
                </div>

                <div class="clearfix"></div>
                <form-error v-bind:value="errors.subscriber_business_details.bank_name"></form-error>
            </div>
        </div>
<!--        <div class="add-data__item">-->
<!--            <div class="row">-->
<!--                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">-->
<!--                    <label>МФО Банка: <span-->
<!--                            class="control"><i-->
<!--                            class="fas fa-star-of-life"></i></span></label>-->
<!--                </div>-->
<!--                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">-->
<!--                    <input type="text" v-model="subscriber.subscriber_business_details.bank_code" placeholder="МФО Банка">-->
<!--                </div>-->

<!--                <div class="clearfix"></div>-->
<!--                <form-error v-bind:value="errors.subscriber_business_details.bank_code"></form-error>-->
<!--            </div>-->
<!--        </div>-->
        <div class="add-data__header">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h3>Контактные данные:</h3>
                </div>
            </div>
        </div>
        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label>ФИО: <span
                            class="control"><i
                            class="fas fa-star-of-life"></i></span></label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                    <input type="text" v-model="subscriber.subscriber_business_details.contact_full_name" placeholder="ФИО">
                </div>

                <div class="clearfix"></div>
                <form-error v-bind:value="errors.subscriber_business_details.contact_full_name"></form-error>
            </div>
        </div>
        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label>Должность: <span
                            class="control"><i
                            class="fas fa-star-of-life"></i></span></label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                    <input type="text" v-model="subscriber.subscriber_business_details.contact_position" placeholder="должность">
                </div>

                <div class="clearfix"></div>
                <form-error v-bind:value="errors.subscriber_business_details.contact_position"></form-error>
            </div>
        </div>
        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label>Телефон: <span
                            class="control"><i
                            class="fas fa-star-of-life"></i></span></label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                    <input type="text" v-model="subscriber.subscriber_business_details.contact_phone" placeholder="телефон">
                </div>

                <div class="clearfix"></div>
                <form-error v-bind:value="errors.subscriber_business_details.contact_phone"></form-error>
            </div>
        </div>
        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label>Email: <span
                            class="control"><i
                            class="fas fa-star-of-life"></i></span></label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                    <input type="text" v-model="subscriber.subscriber_business_details.contact_email" placeholder="email">
                </div>

                <div class="clearfix"></div>
                <form-error v-bind:value="errors.subscriber_business_details.contact_email"></form-error>
            </div>
        </div>

        <business-details-members :business_details_members="subscriber.subscriber_business_details.business_details_members_expand"
                                  :errors="errors"
                                  @update_members="update_members"
        ></business-details-members>

        <file-multiupload :title="'Уставные документы'"
                          :files_input="subscriber.subscriber_business_details.business_details_statutory_document_expand"
                          :errors="errors.subscriber_business_details.business_details_statutory_document"
                          @set_files="set_statutory_documents" >
        </file-multiupload>

        <div class="add-data__header">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h3>Договор:</h3>
                </div>
            </div>
        </div>
        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label>Название: <span
                            class="control"><i
                            class="fas fa-star-of-life"></i></span></label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                    <input type="text" v-model="subscriber.subscriber_business_details.agreement_name" placeholder="Название договора">
                </div>

                <div class="clearfix"></div>
                <form-error v-bind:value="errors.subscriber_business_details.agreement_name"></form-error>
            </div>
        </div>
        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label>№ Договора: <span
                            class="control"><i
                            class="fas fa-star-of-life"></i></span></label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                    <input type="text" v-model="subscriber.subscriber_business_details.agreement_number" placeholder="№ договора">
                </div>

                <div class="clearfix"></div>
                <form-error v-bind:value="errors.subscriber_business_details.agreement_number"></form-error>
            </div>
        </div>
        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label>Дата заключения: <span
                            class="control"><i
                            class="fas fa-star-of-life"></i></span></label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                    <!--<input type="text" v-model="agreement_conclusion_date" placeholder="Дата заключения">-->
                    <date-picker v-model="agreement_conclusion_date" placeholder="Дата заключения" :lang="'ru'" :format="'DD-MM-YYYY'"></date-picker>
                </div>

                <div class="clearfix"></div>
                <form-error v-bind:value="errors.subscriber_business_details.agreement_conclusion_date"></form-error>
            </div>
        </div>

        <div class="add-data__item">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label>Дата окончания: <span
                            class="control"><i
                            class="fas fa-star-of-life"></i></span></label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                    <date-picker v-model="agreement_expiration_date" placeholder="Дата окончания" :lang="'ru'" :format="'DD-MM-YYYY'"></date-picker>
                </div>

                <div class="clearfix"></div>
                <form-error v-bind:value="errors.subscriber_business_details.agreement_expiration_date"></form-error>
            </div>
        </div>

        <div class="add-data__item">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                        <label>Автоматическая пролонгация договора: </label>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                        <label class="checkbox">
                            <input v-model="subscriber.subscriber_business_details.automatic_extension" type="checkbox">
                            <span class="checkmark"></span>
                        </label>
                    </div>

                    <div class="clearfix"></div>
                </div>
            </div>

        <file-multiupload :title="'Скан договоров'"
                          :files_input="subscriber.subscriber_business_details.business_details_agreement_file_expand"
                          :errors="errors.subscriber_business_details.business_details_agreement_file"
                          @set_files="set_agreement_files" >
        </file-multiupload>

    </div>
</template>

<script>
    import FormErrors from '../../form/errors.vue';
    import DatePicker from 'vue2-datepicker';
    import FileMultiupload from '../../form/file-multiupload.vue';
    import BusinessDetailsMembers from './business_details_members.vue'

    export default {
        name: "business-fields",
        props: ["subscriber", "errors"],
        data: function(){
            var agreement_conclusion_date = this.subscriber.subscriber_business_details.agreement_conclusion_date;
            var agreement_expiration_date = this.subscriber.subscriber_business_details.agreement_expiration_date;

            return {
                agreement_conclusion_date: this.$moment(agreement_conclusion_date).format("YYYY-MM-DD"),
                agreement_expiration_date: this.$moment(agreement_expiration_date).format("YYYY-MM-DD"),
            }
        },
        components: {
            "form-error": FormErrors,
            "date-picker": DatePicker,
            "business-details-members": BusinessDetailsMembers,
            "file-multiupload": FileMultiupload,
        },
        methods: {
            update_members: function (value) {
                this.subscriber.subscriber_business_details.business_details_members = value;
            },
            set_agreement_files(value){
                this.subscriber.subscriber_business_details.business_details_agreement_file = value;
            },
            set_statutory_documents(value){
                this.subscriber.subscriber_business_details.business_details_statutory_document = value;
            }
        },
        watch: {
            subscriber: {
                handler(value) {
                    this.agreement_conclusion_date = this.$moment(this.subscriber.subscriber_business_details.agreement_conclusion_date).format("YYYY-MM-DD");
                    this.agreement_expiration_date = this.$moment(this.subscriber.subscriber_business_details.agreement_expiration_date).format("YYYY-MM-DD");
                    this.$emit("update_subscriber", value);
                },
                deep: true
            },
            agreement_conclusion_date(value) {
                this.subscriber.subscriber_business_details.agreement_conclusion_date = this.$moment(value).format("YYYY-MM-DD");
            },
            agreement_expiration_date(value) {
                this.subscriber.subscriber_business_details.agreement_expiration_date = this.$moment(value).format("YYYY-MM-DD");
            },
            'subscriber.subscriber_business_details.is_vat_payer': function (value) {
                if (!value) {
                  this.subscriber.subscriber_business_details.vat_certificate_number = "";
                }
            }
        }
    }
</script>

<style scoped>

</style>