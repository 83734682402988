<template>
    <popup :settings="settings">
        <div slot="title">
            Массовая смена NAS
        </div>
        <div slot="content">
            <div class="body-size">
                <div class="body-size__480">
                    <div class="modal-value">
                        <div class="modal-value__item">
                            <span class="modal-value__label">
                                <span class="modal-value__label-title">Текущий NAS:</span>
                            </span>
                            <span class="modal-value__change">
                                <span class="modal-value__change-icon"></span>
                                <span class="modal-value__change-price">{{ nas_item.name }}</span>
                            </span>
                        </div>
                    </div>
                    <form class="form-modal">
                        <div class="form-modal__group">
                            <fieldset class="form-modal__item">
                                <span class="form-modal__title">
                                        <span class="form-modal__icon"><i class="fal fa-server"></i></span>
                                        Трансфер NAS
                                </span>
                                <div class="form-modal__select">
                                    <select v-model="item.nas_to" required>
                                        <option value="" hidden>Выберите NAS</option>
                                        <option v-for="filter_nas in filtered_nases"
                                                v-bind:value="filter_nas.id">
                                            {{ filter_nas.name }}
                                        </option>
                                    </select>
                                </div>
                                <form-error v-bind:value="errors.nas_to"></form-error>
                            </fieldset>
                        </div>
                        <div class="modal-divide">
                            <div class="modal-divide__title">
                                <span class="modal-divide__title-icon">
                                    <i class="fal fa-cog"></i>
                                </span>Дополнительные параметры
                            </div>
                        </div>
                        <div class="form-modal__group">
                            <fieldset class="form-modal__item form-modal__item_switch">
                                <span class="form-modal__title">
                                <span class="form-modal__icon"><i class="fal fa-map-marker-alt"></i></span>
                                    Населенный пункт
                                </span>
                                <input type="checkbox" class="form-modal__input form-modal__input_switch"
                                       v-model="choose_settlement" id="switch-settlement">
                                <label class="form-modal__switch-for"
                                       for="switch-settlement"></label>
                            </fieldset>
                            <fieldset class="form-modal__item form-modal__item_switch">
                                <span class="form-modal__title">
                                <span class="form-modal__icon"><i class="fal fa-wifi"></i></span>
                                    Тип подключения
                                </span>
                                <input type="checkbox" class="form-modal__input form-modal__input_switch"
                                       v-model="choose_connection_type" id="switch-connection">
                                <label class="form-modal__switch-for"
                                       for="switch-connection"></label>
                            </fieldset>
                        </div>
                        <multiselect v-if="choose_settlement"
                                     v-model="settlements_list"
                                     :options="settlements_by_nas"
                                     :multiple="true"
                                     :close-on-select="true"
                                     :clear-on-select="false"
                                     placeholder="Выберите НП"
                                     label="name"
                                     track-by="name">
                            <template slot="option" slot-scope="props">
                                <option>{{ props.option.name }}</option>
                            </template>
                        </multiselect>
                        <multiselect v-if="choose_connection_type"
                                     v-model="connection_types_list"
                                     :options="connection_types"
                                     :multiple="true"
                                     :close-on-select="true"
                                     :clear-on-select="false"
                                     placeholder="Выберите Тип подключения"
                                     label="label"
                                     track-by="label">
                            <template slot="option" slot-scope="props">
                                <option>{{ props.option.label }}</option>
                            </template>
                        </multiselect>
                    </form>
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="btn-group">
                <span class="btn btn_grey" @click="popup_close">
                    <span class="btn__icon"><i class="fal fa-times-circle"></i></span>
                    <span class="btn__title">Отмена</span>
                </span>
                <span class="btn btn_blue" @click="save">
                    <span class="btn__icon"><i class="fal fa-save"></i></span>
                    <span class="btn__title">Сохранить</span>
                </span>
            </div>
        </div>
    </popup>
</template>

<script>
    import Multiselect from 'vue-multiselect';
    import Popup from '../modal/popup.vue';
    import FormErrors from '../form/errors.vue';
    import {NasMixin} from "../../../../mixins/admin/nas";
    import {CrudMixin} from "../../../../mixins/admin/crud/crud_base";
    import {API} from "../../../../api";
    import {ConnectionTypeMixin} from "../../../../mixins/admin/connection_type";

    export default {
        name: "transfer-nases",
        props: ["settings", "nas_mass_id"],
        mixins: [
            NasMixin, CrudMixin, ConnectionTypeMixin
        ],
        components: {
            "popup": Popup,
            "form-error": FormErrors,
            Multiselect,
        },
        data: function () {
            return {
                choose_settlement: false,
                choose_connection_type: false,
                filtered_nases: [],
                settlements_by_nas: [],
                item: {
                    nas_from: "",
                    nas_to: "",
                },
                settlements_list: [],
                connection_types_list: [],
                errors: {},
            }
        },
        created() {
            this.load_connection_type();
        },
        methods: {
            clear() {
                this.$set(this.item, "nas_to", "");
                this.choose_settlement = false;
                this.choose_connection_type = false;
            },
            init_nas_mass_id() {
                this.clear();
                this.item.nas_from = this.nas_mass_id;
                this.get_nas(this.nas_mass_id);
            },
            updateSettlements(settlements) {
                let settlements_list = [];
                settlements.forEach((settlement) => {
                    settlements_list.push(settlement.id);
                });
                return settlements_list;
            },
            updateConnectionTypes(connection_types) {
                let connection_types_list = [];
                connection_types.forEach((settlement) => {
                    connection_types_list.push(settlement.id);
                });
                return connection_types_list;
            },
            save() {
                var self = this;
                var params = this.item;
                self.errors = {};

                params['settlements'] = this.updateSettlements(this.settlements_list);
                params['connection_types'] = this.updateConnectionTypes(this.connection_types_list);

                API.post('/nases-mass-transfer/', params)
                    .then(function (response) {
                        self.clear();
                        self.popup_close();
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            },
            get_settlement_by_nas_mass_transfer: function (obj) {
                var self = this;
                var params = {
                    nas_from: this.nas_mass_id
                };
                API.post('/settlements-by-nas/', params)
                    .then(function (response) {
                        self.settlements_by_nas = response.data;
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            }
        },
        watch: {
            nas_mass_id: function (value) {
                this.init_nas_mass_id();
                this.get_settlement_by_nas_mass_transfer();
            },
            nas_item: function (value) {
                this.load_nas();
            },
            nas: function (value) {
                var self = this;

                // Exclude current nas
                this.filtered_nases = value.filter(function (el) {
                    if (self.nas_mass_id) {
                        return el.id !== self.nas_mass_id;
                    }
                });

            },
            choose_settlement: function (value) {
                if (value === false) {
                    this.settlements_list = [];
                }
            },
            choose_connection_type: function (value) {
                if (value === false) {
                    this.connection_types_list = [];
                }
            },
        }
    }
</script>

<style scoped>

</style>