<template>
    <section class="page">
        <div class="page__inner">
            <div class="container-width">
                <!--HEADER PAGE-->
                <div class="page__header">
                    <h1 class="page__title">Редактирование должности ({{ crud.data.item_view.name }})</h1>
                    <div class="btn-group">
                        <div class="page__group-btn">
                            <router-link title="Отмена" :to="{ name: 'employees-position'}">
                                <span class="btn btn_grey">
                                    <span class="btn__icon"><i class="fal fa-undo"></i></span>
                                    <span class="btn__title">Вернуться</span>
                                </span>
                            </router-link>
                            <!--<span class="btn btn_blue" @click.prevent="crud_edit(crud.data.item.id, crud.data.item, 'employees-position')">-->
                                <!--<span class="btn__icon"><i class="fal fa-save"></i></span>-->
                                <!--<span class="btn__title">Сохранить</span>-->
                            <!--</span>-->
                        </div>
                    </div>
                </div>
                <!--END HEADER PAGE-->
                <!-- CONTENT PAGE -->
                <div class="page-body">
                    <form action="" class="form-data">
                        <div class="form-data__row">
                            <div class="form-data__section">
                                <!-- Название должности -->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i
                                                    class="fal fa-industry-alt"></i></span>
                                            <span class="form-data__label-title">Название должности:</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <input type="text" v-model="crud.data.item.name" class="form-data__input"
                                                   placeholder="Введите название">
                                        </div>
                                    </div>
                                    <form-error v-bind:value="crud.errors.name"></form-error>
                                </fieldset>
                            </div>
                            <div class="form-data__section">
                                <!-- Примечание -->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i
                                                    class="fal fa-comments-alt"></i></span>
                                            <span class="form-data__label-title">Примечания:</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <input type="text" v-model="crud.data.item.description" class="form-data__input"
                                                   placeholder="Введите премечание">
                                        </div>
                                    </div>
                                    <form-error v-bind:value="crud.errors.description"></form-error>
                                </fieldset>
                            </div>
                        </div>
                    </form>
                </div>
                <!-- END CONTENT PAGE -->
                <permissions :permissions="crud.data.item.position_permissions_expand" @set_permissions_checked="set_permissions_checked"></permissions>
                <div class="form-data__footer form-data__footer_right">
                    <div class="btn-group">
                        <span class="btn btn_blue" @click.prevent="crud_edit(crud.data.item.id, crud.data.item, 'employees-position')">
                            <span class="btn__icon"><i class="fal fa-save"></i></span>
                            <span class="btn__title">Сохранить</span>
                        </span>
                    </div>
                </div>
                <!--<employee-permissions :permissions="crud.data.item.position_permissions_expand" @set_permissions_checked="set_permissions_checked" ></employee-permissions>-->
            </div>
        </div>
    </section>
</template>

<script>
    import {CrudMixin} from "../../../../../mixins/admin/crud/crud_base";
    import {CrudListMixin} from "../../../../../mixins/admin/crud/crud_list";

    import FormErrors from '../../form/errors.vue';
    import EmployeePermissions from '../../employee_permissions/list';
    import Permissions from '../permissions/list'

    export default {
        name: "position-edit",
        data: function () {
            return {
                crud: {
                    api: '/employee-positions/',
                }
            }
        },
        computed: {
            id(){
                return this.$route.params.id;
            }
        },
        mixins: [CrudMixin, CrudListMixin],
        components: {
            "employee-permissions": EmployeePermissions,
            "form-error": FormErrors,
            "permissions": Permissions
        },

        created: function(){
            this.crud_get_item(this.id);
        },

        methods: {
            set_permissions_checked: function (value) {
                this.crud.data.item.position_permissions = value;
            }
        }
    }
</script>

<style scoped>

</style>