<template>
    <popup :settings="settings">
        <div slot="title">Управление IP адресами</div>
        <div slot="content">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="add-ippool">
<!--                        <div class="add-ippool__view">-->
<!--                            <ul>-->
<!--                                <li>HostMin: <span class="value"> 46.175.184.1</span></li>-->
<!--                                <li>HostMax: <span class="value"> 46.175.191.254</span></li>-->
<!--                                <li>Hosts/Net: <span class="value"> 2046</span></li>-->
<!--                            </ul>-->
<!--                        </div>-->
                        <div class="add-ippool__view">
                            <ul>
                                <li v-if="record_checked.length">Hosts: <template v-for="item in record_checked" >
                                    <span class="value"> {{ item.ip_address }}</span> ({{ item.destination_display }}),</template>
                                </li>
                                <li v-else>
                                    Не выбраны Hosts
                                </li>
                            </ul>
                        </div>
                        <form action="" class="add-ippool__form">
                            <fieldset class="add-ippool__form--item">
                                <template v-if="is_destination_rent()">
                                    {{ get_destination_label() }}
                                </template>
                                <template v-else>
                                    <div class="selected">
                                        <select v-model="destination" required>
                                                <option value="" hidden>Выберите назначение</option>
                                                <option v-for="item in destinations_filtered" v-bind:value="item.key">{{ item.label }}</option>
                                        </select>
                                    </div>
                                </template>
                                <form-error v-bind:value="errors.destination"></form-error>
                            </fieldset>
                            <fieldset class="add-ippool__form--item">
                                <textarea name="desc" v-model="note" placeholder="Примечание"></textarea>
                                <form-error v-bind:value="errors.note"></form-error>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer">
            <a href="#" @click="popup_close">Отмена</a>
            <a href="#" @click="save()" class="modal__btn">Сохранить</a>
        </div>
    </popup>
</template>

<script>
    import Popup from '../modal/popup.vue';
    import {CrudMixin} from "../../../../mixins/admin/crud/crud_base";
    import {IpAddressMixin} from "../../../../mixins/admin/ip_address";
    import FormErrors from '../form/errors.vue';
    import {API} from "../../../../api";

    export default {
        name: "edit-all",
        props: ["settings", "crud", 'record_checked', 'destinations'],
        mixins: [CrudMixin, IpAddressMixin],
        components: {
            "popup": Popup,
            "form-error": FormErrors,
        },
        computed: {
            ids(){
                var value_list = "";
                for(var i in this.record_checked) {
                    value_list += this.record_checked[i].id + ",";
                }
                return value_list.slice(0,-1)
            }
        },
        data(){
            return {
                destination: "",
                note: "",
                errors: {}
            }
        },
        methods: {
            save(){
                var self = this;
                self.errors = {};
                var form = {note: this.note};
                if(!this.is_destination_rent()){
                    form['destination'] = this.destination;
                }
                if(!this.ids){
                    this.popup_close();
                    this.alert("Внимание", "Не выбраны hosts");
                    return
                }

                API.patch('/ip-address/ip-address/bulk_update/?ids='+this.ids, [form])
                    .then(function (response) {
                        self.$emit('fetchUser');
                        self.popup_close();
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            }
        }
    }
</script>

<style scoped>

</style>