<template>
  <section class="page">
    <div class="page__inner">
      <div class="container-width">
        <!--HEADER PAGE-->
        <div class="page__header">
          <h1 class="page__title">{{ reportTitle }}</h1>
          <report-menu :active_name="'reports-page'"></report-menu>
        </div>
        <!-- END HEADER PAGE -->
        <!-- BODY PAGE -->
        <div class="page__body">
          <div class="filters">
            <div class="filters__body">
              <div class="filters__select">
                <select v-model="reportData.year" required>
                  <option disabled :value="undefined">Год</option>
                  <option v-for="year in years" :value="year">{{ year }}</option>
                </select>
              </div>
              <div class="filters__select">
                <select v-model="reportData.month" required>
                  <option disabled :value="undefined">Месяц</option>
                  <option v-for="month in monthsFilter" :value="month.key" :key="month.key">{{ month.label }}</option>
                </select>
              </div>
              <div class="filters__actions">
                <div class="filters__btn filters__btn_green" @click="generateReport()">
                  <span class="filters__btn-icon"></span>
                  <span class="filters__btn-title">Сгенерировать</span>
                </div>
              </div>
            </div>
          </div>
          <div class="table-box">
            <div class="table-box__header">
              <div class="table-box__header-block">
                <div class="table-filter">
                </div>
              </div>
            </div>
            <table class="table-box__table">
              <thead class="table-box__thead">
              <th class="table-box__th">
                <span class="table-box__thead-item">
                  <span class="table-box__thead-title">Отделы</span>
                </span>
              </th>
              <th class="table-box__th">
                <span class="table-box__thead-item table-box__thead-item_center">
                  <span class="table-box__thead-title" v-if="reportName === 'payment-delay'">1-3 мес</span>
                  <span class="table-box__thead-title" v-if="reportName === 'subscribers-leak'">1+ мес</span>
                </span>
              </th>
              <th class="table-box__th">
                <span class="table-box__thead-item table-box__thead-item_center">
                  <span class="table-box__thead-title" v-if="reportName === 'payment-delay'">3-6 мес</span>
                  <span class="table-box__thead-title" v-if="reportName === 'subscribers-leak'">3+ мес</span>
                </span>
              </th>
              <th class="table-box__th">
                <span class="table-box__thead-item table-box__thead-item_center">
                  <span class="table-box__thead-title" v-if="reportName === 'payment-delay'">Более 6 мес</span>
                  <span class="table-box__thead-title" v-if="reportName === 'subscribers-leak'">6+ мес</span>
                </span>
              </th>
              <th class="table-box__th">
                <span class="table-box__thead-item table-box__thead-item_center">
                  <span class="table-box__thead-title">Детали</span>
                </span>
              </th>
              </thead>
              <template v-for="(department, index) in departments">
                <SubscribersLeakReportDepartmentInfo :department="department"
                                                     :key="index"
                                                     :index="index"
                                                     :show-table="showTables[index].isVisible"
                                                     :link-prefix="linkPrefix"
                                                     @showSettlement="showDepartmentSettlements(index)"/>
                <template v-if="showTables[index].isVisible">
                  <SubscribersLeakReportSettlementInfo v-for="settlement in department.settlements"
                                                       :settlement="settlement"
                                                       :link-prefix="linkPrefix"/>
                </template>
              </template>
            </table>
          </div>
        </div>
        <!-- END BODY PAGE -->
      </div>
    </div>
  </section>

</template>

<script>
import ReportMenu from './../menu/menu';
import {API} from "../../../../../api";
import SubscribersLeakReportDepartmentInfo from "./subscribers-leak-report-department-info";
import SubscribersLeakReportSettlementInfo from "./subscribers-leak-report-settlement-info";
import {monthsFilter} from '../../../../../../config/constants';

export default {
  name: "subscribers-leak",
  components: {
    SubscribersLeakReportDepartmentInfo,
    SubscribersLeakReportSettlementInfo,
    'report-menu': ReportMenu,
  },
  props: ["reportName"],
  data() {
    return {
      years_and_months: [],
      years: [],
      departments: [],
      showTables: [],
      monthsFilter: [],
      reportData: {
        year: undefined,
        month: new Date().getMonth(),
      },
    }
  },
  mounted() {
    this.loader_show();
    this.fetchYearsAndMonths();
  },
  computed: {
    reportTitle() {
      let self = this;
      if (self.reportName === "subscribers-leak") {
        return 'Отчет "Утечка абонентов"'
      } else if (self.reportName === "payment-delay") {
        return 'Отчет "Задержка оплат"'
      }
    },
    linkPrefix() {
      let self = this;
      if (self.reportName === "subscribers-leak") {
        return "leak_of"
      } else if (self.reportName === "payment-delay") {
        return 'delay_of'
      }
    },
  },
  methods: {
    getYearsList() {
      let self = this;
      let years = [];
      for (let year_and_months of self.years_and_months) {
        years.push(year_and_months.year);
      }
      self.years = years;
      self.reportData.year = years[0];
    },
    fetchYearsAndMonths(){
      var self = this;

      API.get('/report/subscribers-leak/years-and-months')
        .then(res => {
          self.years_and_months = res.data.years_and_months;
          self.getYearsList();
        })
        .catch(err => {
          API.default_catch(self, err);
        })
        .then(() => {
          API.default_always(self);
        })
    },
    generateReport() {
      this.loadReportData();
    },
    showDepartmentSettlements(index) {
      this.showTables[index].isVisible = !this.showTables[index].isVisible;
    },
    loadReportData() {
      var self = this;
      self.loader_show();

      self.departments = [];
      API.post('/report/'+self.reportName+'/', self.reportData)
        .then(res => {
          self.departments = res.data;
          self.initShowTables();
        })
        .catch(err => {
          API.default_catch(self, err);
          if('non_field_errors' in self.errors) {
            self.alert("Ошибка", self.errors['non_field_errors'][0]);
          }
        })
        .then(() => {
          API.default_always(self);
        })
    },
    initShowTables() {
      var arr = [];

      this.departments.forEach((value, index, array) => {
        let obj = {};
        obj['isVisible'] = false;
        arr.push(obj);
      })
      this.showTables = arr;
    },
  },
  watch: {
    "reportData.year": {
      handler(value) {
        let self = this;
        let monthsOfSelectedYear = [];
        for (let year of self.years_and_months) {
          if (year.year === value) {
            monthsOfSelectedYear = year.months;
          }
        }
        let filters = [];
        for (let month of monthsFilter) {
          let item = monthsOfSelectedYear.find((el) => el === month.key);
          if (item !== undefined) {
            filters.push(month);
          }
        }
        self.monthsFilter = filters;
        self.reportData.month = filters[filters.length-1].key;
      },
    },
  },
}
</script>

<style scoped>

</style>