import DateRangePicker from 'vue2-daterange-picker';


export var DateRangePickerDefaults = {
    components: {
        DateRangePicker,
    },
    data: function () {
        return {
            startDate: this.$moment().subtract(1, 'month'),
            endDate: this.$moment(),
            locale: {
                direction: 'ltr', //direction of text
                format: 'DD-MM-YYYY', //fomart of the dates displayed
                separator: ' - ', //separator between the two ranges
                applyLabel: 'Применить',
                cancelLabel: 'Отмена',
                weekLabel: 'W',
                customRangeLabel: 'Custom Range',
                daysOfWeek: this.$moment.weekdaysMin(), //array of days - see moment documenations for details
                monthNames: this.$moment.monthsShort(), //array of month names - see moment documenations for details
                firstDay: 1 //ISO first day of week - see moment documenations for details
            },
        }
    }
}