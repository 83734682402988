/* Generated automatic */

export const IMG_PATH = '/images/';

// Route redirect
export const LOGIN_REQUIRED_ROUTES = ['settings', 'tutorial-create', 'favorites'];
export const LOGIN_REDIRECT_TO = 'login';

// API URL
export const API_URL = 'https://bennu.bel.net.ua';
export const EMPLOYEE_AUTH_LINK = 'https://my.bel.net.ua/';

// Social Accounts: facebook & google+
export const SOCIAL_ACCOUNTS = {
    baseUrl: 'https://bennu.bel.net.ua',
    providers: {
        google: {
            url: '/auth/google/',
            clientId: '501379515030-0lsli3ralo8odf9bolc3d6lj677c3441.apps.googleusercontent.com',
            redirectUri: 'https://bennu.bel.net.ua/api/v1/auth/google/complete/google-oauth2/'
        },
    }
};
