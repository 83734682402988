import {API} from "../../api";

export var DepartmentMixin = {
    data: function () {
        return {
            departments: [],
        }
    },
    methods: {
        load_departments: function (obj) {
            var self = this;
            API.get('/departments/')
                .then(function (response) {
                    self.departments = response.data.results;
                })
                .catch(function (error) {
                    // API.default_catch(self, error)
                })
                .then(function () {
                    // API.default_always(self);
                })
        },
        load_departments_employee_persmissions: function (obj) {
            var self = this;
            API.get('/departments-employee-permissions/')
                .then(function (response) {
                    self.departments = response.data.results;
                })
                .catch(function (error) {
                    // API.default_catch(self, error)
                })
                .then(function () {
                    // API.default_always(self);
                })
        }
    }
};
