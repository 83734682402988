
export const UserModel = {
    data: {
        id: '',
        username: '',
        email: '',
        fio: '',
        last_login: '',
        is_auth: false,
    }
}