<template>
    <div class="view-data-reports__item">
        <div class="view-data-reports__item-head"><i class="fal fa-server"></i>
            Всего <span class="explanation">Платежи в биллинге</span></div>
        <div class="table-container">
            <table>
                <thead>
                <tr>
                    <template v-for="row in rows" >
                        <th v-if="results.total_in_billing[row.field] !== undefined">{{ row.title }}:</th>
                    </template>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <template v-for="row in rows" >
                        <td v-if="results.total_in_billing[row.field] !== undefined">
                         <!-- TODO: Fix error -->
                            <router-link title="Пополненые абоненты UNICARD"
                                         :to="generate_link(results.total_in_billing.link_object_type, results.total_in_billing[row.field+'_link_params'], {from_url: current_url()})">
                                {{ results.total_in_billing[row.field] }} грн
                            </router-link>
                        </td>
                    </template>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import {LinksMixin} from "../../../../../mixins/admin/links";

    export default {
        name: "departments-report-total",
        props: ["results"],
        mixins: [LinksMixin],
        data(){
            return {
                rows: [
                    {title: "UNICARD", field: "unicard"},
                    {title: "EasyPay", field: "easypay"},
                    {title: "Ручное пополнение", field: "manual_refill"},
                    {title: "SET", field: "set_balance"},
                    {title: "Приват24", field: "privat24"},
                    {title: "Liqpay", field: "liqpay"},
                    {title: "Банк Фамильный", field: "family_bank"},
                    {title: "Перерасчет", field: "recalculate_balance"},
                    {title: "Безналичный физ. лицо", field: "cashless_physical"},
                    {title: "Безналичный юр. лицо", field: "cashless_business"},
                    {title: "Безналичный терминал", field: "cashless_terminal"},
                    {title: "Безналичный Приват24", field: "cashless_privat24"},
                    {title: "Всего", field: "total_amount"},
                ]
            }
        }
    }
</script>

<style scoped>

</style>