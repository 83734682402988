<template>
    <form class="form-modal">
        <div class="form-modal__group">
            <fieldset class="form-modal__item">
                        <span class="form-modal__title">
                        <span class="form-modal__icon"><i class="fal fa-road"></i></span>
                        Название улицы
                        </span>
                        <input type="text" v-model="name" class="form-modal__input" placeholder="Введите название улицы">
                        <form-error v-bind:value="errors.name"></form-error>
            </fieldset>
        </div>
        <div class="form-modal__group form-modal__group_right">
            <div class="btn-group">
                <span class="btn btn_blue" v-on:click="save">
                    <span class="btn__icon"><i class="fal fa-plus"></i></span>
                    <span class="btn__title">Добавить</span>
                </span>
            </div>
        </div>
    </form>
</template>

<script>
    import {API} from "../../../../../../api";
    import FormErrors from '../../../form/errors.vue';

    export default {
        name: "street-add",
        components: {
            "form-error": FormErrors,
        },
        data(){
            return {
                name: "",
                errors: {}
            }
        },
        methods: {
            save(){
                var self = this;
                self.loader_show();

                API.post("/address/street/", {name: this.name})
                    .then(function (response) {
                        self.name = "";
                        self.errors = {};
                        self.$emit("update_streets");
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })

            }
        }
    }
</script>

<style scoped>

</style>