<template>
    <section class="page">
        <div class="page__inner">
            <div class="container-width">
                <div class="row">
                    <!--HEADER PAGE-->
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="page-header">
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                                    <h1 class="page-header__title">Пакеты IP-адрес</h1>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                    <div class="page-header__btn-group">
                                        <span class="btn grey"><i class="fal fa-archive"></i> <span
                                                class="title">Архив</span></span>

                                        <app-has-perm-action-btn :perms="'personal_services_management_add_edit'"
                                                              @action="popup_add">
                                            <template slot-scope="{ access }">
                                                <span class="page-header__btn-group">
                                                    <span class="btn" :class="access ? 'green' : 'grey'">
                                                        <i class="fal fa-plus"></i> Добавить пакет
                                                    </span>
                                                </span>
                                            </template>
                                        </app-has-perm-action-btn>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--END HEADER PAGE-->
                    <!--PAGE BODY-->
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="page-body">
                            <!--PAGE-TABLE-->
                            <div class="table-container">
                                <table>
                                    <thead>
                                    <tr>
                                        <th><i class="fal fa-box"></i> Название пакета</th>
                                        <th><i class="fal fa-user-tie"></i> Тип абонента</th>
                                        <th><i class="fal fa-code-branch"></i> Отдел</th>
                                        <th><i class="fal fa-wifi"></i> Тип подключения</th>
                                        <th><i class="fal fa-wifi"></i> Группа</th>
                                        <th><i class="fal fa-calendar-alt"></i>Тарификация</th>
                                        <th><i class="fal fa-hryvnia"></i> Стоимость услуги</th>
                                        <th><i class="fal fa-chart-area"></i> Статистика</th>
                                        <th><i class="fal fa-comment-lines"></i> Примечания</th>
                                        <th><i class="fal fa-user-lock"></i> Доступ с ЛК</th>
                                        <th><i class="fal fa-cogs"></i> Действие</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(ip_address_service, index) in ip_address_services">
                                        <td>{{ ip_address_service.name }}</td>
                                        <td>
                                            <SubscriberType :item_service="ip_address_service"></SubscriberType>
                                        </td>
                                        <td>
                                            <Department :item_service="ip_address_service"></Department>
                                        </td>
                                        <td>
                                            <span v-if="ip_address_service.connection_type_expand">
                                                {{ ip_address_service.connection_type_expand.label }}
                                            </span>
                                            <span v-else>
                                                Все
                                            </span>
                                        </td>
                                        <td>
                                            <Group :item_service="ip_address_service"></Group>
                                        </td>
                                        <td>{{ ip_address_service.charging_type_expand }}</td>
                                        <td>
                                            <ChargingType :item_service="ip_address_service"></ChargingType>
                                        </td>
                                        <td>
                                            <span v-if="ip_address_service.count_subscriber_connections > 0">
                                                <span v-if="has_perm_count_subscriber_connections(ip_address_service)" class="table-data__link">
                                                    <router-link :to="generate_link(ip_address_service.link_object_type, ip_address_service.count_subscriber_connections_params, {from_url: current_url()})" >
                                                        {{ ip_address_service.count_subscriber_connections }}
                                                    </router-link>
                                                </span>
                                                <span v-else>{{ ip_address_service.count_subscriber_connections }}</span>
                                            </span>
                                            <span v-else>-</span>
                                        </td>
                                        <td>{{ ip_address_service.note }}</td>
                                        <td>
                                            <span v-if="ip_address_service.is_available_in_profile" class="enable"><i class="fal fa-unlock-alt"></i></span>
                                            <span v-else class="disabled"><i class="fal fa-lock-alt"></i></span>
                                        </td>
                                        <td>
                                            <div class="btn-group">
                                             <span>
                                                 <app-has-perm-action-btn :perms="'personal_services_management_add_edit'"
                                                              @action="popup_edit(index, ip_address_service)">
                                                    <template slot-scope="{ access }">
                                                        <span class="page-header__btn-group">
                                                            <span class="btn-l" :class="access ? 'orange' : 'grey'">
                                                                <i class="fal fa-edit"></i>
                                                            </span>
                                                        </span>
                                                    </template>
                                                </app-has-perm-action-btn>

                                                 <app-remove-btn :perms="'catalog_ip_address_poll_add_edit'" @action=""></app-remove-btn>
                                             </span>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!--END PAGE-TABLE-->
                        </div>
                    </div>
                    <!--END PAGE BODY-->
                </div>
            </div>
        </div>
        <!-- Add-serivce-ip-address popup -->
        <add-ip-service-popup :settings="add_popup" @load_ip_address_service="load_ip_address_service" ></add-ip-service-popup>
        <!-- Edit-serivce-ip-address popup -->
        <edit-ip-service-popup :settings="edit_popup" :crud="crud" @load_ip_address_service="load_ip_address_service" ></edit-ip-service-popup>
    </section>
</template>

<script>
    import {CrudMixin} from "../../../../../mixins/admin/crud/crud_base";
    import {CrudListMixin} from "../../../../../mixins/admin/crud/crud_list";
    import {LinksMixin} from "../../../../../mixins/admin/links";

    import AddServiceIP from './add-ip-address.vue';
    import EditServiceIP from './edit-ip-address.vue';
    import Edit from "../../ip_address/edit";
    import {API} from "../../../../../api";
    import SubscriberType from "../components/subscriber_type";
    import Department from "../components/department";
    import ChargingType from "../components/charging_type";
    import {ServiceListMixin} from "../../../../../mixins/admin/services_list";
    import Group from "../components/group";

    export default {
        name: "list-ip-address",
        components: {
            Group,
            Edit,
            "add-ip-service-popup": AddServiceIP,
            "edit-ip-service-popup": EditServiceIP,
            SubscriberType,
            Department,
            ChargingType,
        },
        mixins: [CrudMixin, CrudListMixin, LinksMixin, ServiceListMixin],
        data(){
            return {
                ip_address_services: []
            }
        },
        mounted: function () {
            this.fetchUser()
        },
        methods: {
            fetchUser() {
                // if (this.has_perm({
                //     permissions: 'subscribers_access',
                //     comparison: 'OR'
                // })) {
                    this.load_ip_address_service(this.$route.params.id)
                // }
            },
            load_ip_address_service: function () {
                var self = this;

                self.loader_show();
                API.get('/services/ip-address/')
                    .then(function (response) {
                        self.ip_address_services = response.data.results;
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            }
        },
    }
</script>

<style scoped>

</style>