<template>
        <popup :settings="settings">
        <div slot="title">Задать баланс</div>
        <div slot="content">
            <div class="body-size">
                <div class="body-size__480">
                    <div class="modal-value" v-if="subscriber_connection">
                        <div class="modal-value__item">
                            <span class="modal-value__label">
                                <span class="modal-value__label-title">Текущий баланс:</span>
                            </span>
                            <span class="modal-value__change">
                                <span class="modal-value__change-icon">₴</span>
                                <span class="modal-value__change-price">{{ subscriber_connection.balance }}</span>
                            </span>
                        </div>
                    </div>
                    <form class="form-modal">
                        <div class="form-modal__group">
                            <fieldset class="form-modal__item">
                                <span class="form-modal__title">
                                <span class="form-modal__icon"><i class="fal fa-wallet"></i></span>
                                    Сумма баланса
                                </span>
                                <input type="text" v-model="form.balance" class="form-modal__input"
                                       placeholder="0">
                                <form-error v-bind:value="errors.balance"></form-error>
                            </fieldset>
                        </div>
                        <div class="form-modal__group">
                            <fieldset class="form-modal__item">
                                <span class="form-modal__title">
                                <span class="form-modal__icon"><i class="fal fa-comments"></i></span>
                                    Примечание
                                </span>
                                <textarea v-model="form.comment" class="form-modal__textarea"  placeholder="Примечание"></textarea>
                                <form-error v-bind:value="errors.comment"></form-error>
                            </fieldset>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="btn-group">
                <span class="btn btn_grey" @click="popup_close">
                    <span class="btn__icon"><i class="fal fa-times-circle"></i></span>
                    <span class="btn__title">Отмена</span>
                </span>
                <span class="btn btn_blue" @click="save()">
                    <span class="btn__icon"><i class="fal fa-check"></i></span>
                    <span class="btn__title">Задать</span>
                </span>
            </div>
        </div>
    </popup>
</template>

<script>
    import {SubscriberConnectionMixin} from "../../../../../mixins/admin/subscriber_connection";
    import Popup from '../../modal/popup.vue'
    import {CrudMixin} from "../../../../../mixins/admin/crud/crud_base";
    import FormErrors from '../../form/errors.vue';
    import {API} from "../../../../../api";

    export default {
        name: "balance-set",
        props: ["settings", "subscriber_connection"],
        data(){
          return {
              form: this.form_data(),
              errors: {}
          }
        },
        mixins: [
            CrudMixin, SubscriberConnectionMixin
        ],
        components: {
            "popup": Popup,
            "form-error": FormErrors
        },
        methods:{
            form_data(){
              return {
                  balance: 0,
                  comment: ""
              }
            },
            save(){
                var self = this;
                this.set_balance(this.subscriber_connection.id, this.form)
                    .then(function (response) {
                        self.$emit('update_subscriber_connection');
                        self.form = self.form_data();
                        self.popup_close();
                    })
                    .catch(function (error) {
                        API.default_catch(self, error);
                    })
                    .then(function () {
                        API.default_always(self);
                    });
            }
        }
    }
</script>

<style scoped>

</style>