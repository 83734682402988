

export var CrudListMixin = {
    data: function () {
        return {
            crud: {
                api: '',
                errors: {},
                data: {
                    index: 0,
                    list: [],
                    list_count: 0,
                    item: {},
                    item_view: {}
                }
            },
            add_popup: {
                visible: false
            },
            edit_popup: {
                visible: false
            },
            view_popup: {
                visible: false
            },
            delete_popup: {
                visible: false
            },
            change_popup: {
                visible: false
            }
        }
    },

}