<template>
    <div class="turning-component">
        <div class="turning-component__label">
            <span class="turning-component__label-icon"><i :class="iconPropClass"></i></span>
            <span class="turning-component__label-title">
                <slot name="title"></slot>
            </span>
        </div>
        <div class="turning-component__switch">
            <input type="checkbox" v-model="checkbox" @change="checkboxChanged" class="turning-component__switch-input" :id="uniqueId">
            <label class="turning-component__switch-for" :for="uniqueId"></label>
        </div>
    </div>
</template>

<script>
    import _ from "lodash";

    export default {
        name: "turning-component",
        props: ['iconPropClass', 'checkboxProp'],
        data() {
            return {
                checkbox: false,
                uniqueId: _.uniqueId('id_'),
            }
        },
        methods: {
            checkboxChanged() {
                this.$emit('checkbox-changed', this.checkbox);
            }
        },
        watch: {
            checkboxProp(value){
                this.checkbox = value;
            },
        }
    }
</script>

<style scoped>

</style>