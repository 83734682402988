<template>
    <div class="h-notification">
        <div class="h-notification__btn">
            <div class="h-notification__btn-icon"></div>
            <!--<span class="h-notification__btn-count">10</span>-->
        </div>
    </div>
</template>

<script>
    export default {
        name: "h-notification"
    }
</script>

<style scoped>

</style>