<template>
    <section class="page">
        <div class="page__inner">
            <div class="container-width">
                <!--HEADER PAGE-->
                <div class="page__header">
                    <h1 class="page__title">Редактирование NAS ({{ crud.data.item_view.name }})</h1>
                    <div class="btn-group">
                        <router-link title="Добавление NAS" :to="{ name: 'nas-list'}">
                        <span class="btn btn_grey">
                            <span class="btn__icon"><i class="fal fa-undo"></i></span>
                            <span class="btn__title">Вернуться</span>
                        </span>
                        </router-link>
                        <!--<span class="btn btn_blue" @click.prevent="crud_edit(crud.data.item.id, crud.data.item, 'nas-list')">-->
                        <!--<span class="btn__icon"><i class="fal fa-save"></i></span>-->
                        <!--<span class="btn__title">Сохранить</span>-->
                        <!--</span>-->
                    </div>
                </div>
                <!--END HEADER PAGE-->
                <!-- CONTENT PAGE -->
                <div class="page-body">
                    <form action="" class="form-data">
                        <div class="form-data__row">
                            <div class="form-data__section">
                                <!--Имя NAS-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-server"></i></span>
                                            <span class="form-data__label-title">Имя NAS:</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <input type="text" v-model="crud.data.item.name" class="form-data__input"
                                                   placeholder="Введите имя">
                                        </div>
                                    </div>
                                    <form-error v-bind:value="crud.errors.name"></form-error>
                                </fieldset>
                                <!--Отдел-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i
                                                    class="fal fa-code-branch"></i></span>
                                            <span class="form-data__label-title">Отдел:</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <div class="form-data__select">
                                                <select v-model="crud.data.item.department">
                                                    <option v-for="department in departments"
                                                            v-bind:value="department.id">{{ department.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <form-error v-bind:value="crud.errors.department"></form-error>
                                </fieldset>
                                <!--IP-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-database"></i></span>
                                            <span class="form-data__label-title">NAS IP</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <input type="text" v-model="crud.data.item.ip" class="form-data__input"
                                                   placeholder="127.0.0.0">
                                        </div>
                                    </div>
                                    <form-error v-bind:value="crud.errors.ip"></form-error>
                                </fieldset>
                                <!--Пользователь-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-user-cog"></i></span>
                                            <span class="form-data__label-title">Пользователь:</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <input type="text" v-model="crud.data.item.username"
                                                   class="form-data__input" placeholder="Имя пользователя">
                                        </div>
                                    </div>
                                    <form-error v-bind:value="crud.errors.username"></form-error>
                                </fieldset>
                                <!--Пароль-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-key"></i></span>
                                            <span class="form-data__label-title">Пароль:</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <input type="password" v-model="crud.data.item.password"
                                                   class="form-data__input" placeholder="Пароль">
                                        </div>
                                    </div>
                                    <form-error v-bind:value="crud.errors.password"></form-error>
                                </fieldset>
                            </div>
                            <div class="form-data__section">
                                <!--Port SSH-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-unlock"></i></span>
                                            <span class="form-data__label-title">Порт SSH:</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <input type="number" v-model="crud.data.item.port_ssh"
                                                   class="form-data__input" placeholder="SSH">
                                        </div>
                                    </div>
                                    <form-error v-bind:value="crud.errors.port_ssh"></form-error>
                                </fieldset>
                                <!--Port API-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-unlock"></i></span>
                                            <span class="form-data__label-title">Порт API:</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <input type="number" v-model="crud.data.item.api_port"
                                                   class="form-data__input" placeholder="API">
                                        </div>
                                    </div>
                                    <form-error v-bind:value="crud.errors.api_port"></form-error>
                                </fieldset>
                                <!--SNMP Community-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i
                                                    class="fal fa-users-class"></i></span>
                                            <span class="form-data__label-title">SNMP Community:</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <input type="text" v-model="crud.data.item.snmp_community"
                                                   class="form-data__input" placeholder="SNMP">
                                        </div>
                                    </div>
                                    <form-error v-bind:value="crud.errors.snmp_community"></form-error>
                                </fieldset>
                                <!--Адрес-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i
                                                    class="fal fa-map-marker-alt"></i></span>
                                            <span class="form-data__label-title">Адрес нахождения:</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <input type="text" v-model="crud.data.item.location_address"
                                                   class="form-data__input" placeholder="Адрес">
                                        </div>
                                    </div>
                                    <form-error v-bind:value="crud.errors.location_address"></form-error>
                                </fieldset>
                                <!--Описание-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-comment"></i></span>
                                            <span class="form-data__label-title">Описание:</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <input type="text" v-model="crud.data.item.description"
                                                   class="form-data__input" placeholder="Описание">
                                        </div>
                                    </div>
                                    <form-error v-bind:value="crud.errors.description"></form-error>
                                </fieldset>
                            </div>
                        </div>
                        <div class="form-data__footer form-data__footer_right">
                            <div class="btn-group">
                                <span class="btn btn_blue"
                                      @click.prevent="crud_edit(crud.data.item.id, crud.data.item, 'nas-list')">
                                    <span class="btn__icon"><i class="fal fa-save"></i></span>
                                    <span class="btn__title">Сохранить</span>
                                </span>
                            </div>
                        </div>
                    </form>
                </div>
                <!-- END CONTENT PAGE -->


                <!--<div class="row">-->
                <!--&lt;!&ndash; HEADING PAGE &ndash;&gt;-->
                <!--<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">-->
                <!--<div class="page-header">-->
                <!--<div class="row">-->
                <!--<div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">-->
                <!--<h1 class="page-header__title"> Редактирование NAS ({{ crud.data.item_view.name-->
                <!--}})</h1>-->
                <!--</div>-->
                <!--<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">-->
                <!--<div class="page-header__btn-group">-->
                <!--<router-link title="Назад" :to="{ name: 'nas-list'}">-->
                <!--<span class="btn grey"><i class="fal fa-undo"></i> <span class="title">Вернуться к списку</span></span>-->
                <!--</router-link>-->
                <!--<span class="btn blue"-->
                <!--@click.prevent="crud_edit(crud.data.item.id, crud.data.item, 'nas-list')"><i-->
                <!--class="fal fa-user-edit"></i> <span-->
                <!--class="title">Сохранить</span></span>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--&lt;!&ndash; END HEADING PAGE &ndash;&gt;-->
                <!--&lt;!&ndash; CONTENT PAGE &ndash;&gt;-->
                <!--<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">-->
                <!--<div class="page-body">-->
                <!--<div class="row">-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">-->
                <!--<form action="" class="add-data">-->
                <!--<div class="add-data__header">-->
                <!--<div class="row">-->
                <!--<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">-->
                <!--<h3><i class="fal fa-user-edit"></i> Редактирование</h3>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--<div class="add-data__item">-->
                <!--<div class="row">-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">-->
                <!--<label><i class="fal fa-code-branch"></i> Отдел NAS: <span-->
                <!--class="control"><i-->
                <!--class="fas fa-star-of-life"></i></span></label>-->
                <!--</div>-->
                <!--<div class="selected">-->
                <!--<select v-model="crud.data.item.department">-->
                <!--<option v-for="department in departments" v-bind:value="department.id">{{ department.name }}-->
                <!--</option>-->
                <!--</select>-->
                <!--</div>-->
                <!--<form-error v-bind:value="crud.errors.department"></form-error>-->
                <!--</div>-->
                <!--</div>-->
                <!--<div class="add-data__item">-->
                <!--<div class="row">-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">-->
                <!--<label><i class="fal fa-server"></i> Имя NAS: <span class="control"><i-->
                <!--class="fas fa-star-of-life"></i></span></label>-->
                <!--</div>-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">-->
                <!--<input type="text" v-model="crud.data.item.name"-->
                <!--placeholder="Введите имя NAS">-->
                <!--<form-error v-bind:value="crud.errors.name"></form-error>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--<div class="add-data__item">-->
                <!--<div class="row">-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">-->
                <!--<label><i class="fal fa-map-pin"></i> NAS IP: <span class="control"><i-->
                <!--class="fas fa-star-of-life"></i></span></label>-->
                <!--</div>-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">-->
                <!--<input type="text" v-model="crud.data.item.ip"-->
                <!--placeholder="127.0.0.0">-->
                <!--<form-error v-bind:value="crud.errors.ip"></form-error>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--<div class="add-data__item">-->
                <!--<div class="row">-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">-->
                <!--<label><i class="fal fa-user-cog"></i> Username: <span-->
                <!--class="control"><i-->
                <!--class="fas fa-star-of-life"></i></span></label>-->
                <!--</div>-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">-->
                <!--<input type="text" v-model="crud.data.item.username"-->
                <!--placeholder="Введите username">-->
                <!--<form-error v-bind:value="crud.errors.username"></form-error>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--<div class="add-data__item">-->
                <!--<div class="row">-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">-->
                <!--<label><i class="fal fa-key"></i> Password: <span class="control"><i-->
                <!--class="fas fa-star-of-life"></i></span></label>-->
                <!--</div>-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">-->
                <!--<input type="password" v-model="crud.data.item.password"-->
                <!--placeholder="Введите password">-->
                <!--<form-error v-bind:value="crud.errors.password"></form-error>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--<div class="add-data__item">-->
                <!--<div class="row">-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">-->
                <!--<label><i class="fal fa-unlock"></i> Port SSH: <span-->
                <!--class="control"><i-->
                <!--class="fas fa-star-of-life"></i></span></label>-->
                <!--</div>-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">-->
                <!--<input type="number" v-model="crud.data.item.port_ssh"-->
                <!--placeholder="Введите port ssh">-->
                <!--<form-error v-bind:value="crud.errors.port_ssh"></form-error>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--<div class="add-data__item">-->
                <!--<div class="row">-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">-->
                <!--<label><i class="fal fa-unlock"></i> Api Port: <span-->
                <!--class="control"><i-->
                <!--class="fas fa-star-of-life"></i></span></label>-->
                <!--</div>-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">-->
                <!--<input type="number" v-model="crud.data.item.api_port"-->
                <!--placeholder="Введите api port">-->
                <!--<form-error v-bind:value="crud.errors.api_port"></form-error>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--<div class="add-data__item">-->
                <!--<div class="row">-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">-->
                <!--<label><i class="fal fa-users-class"></i> SNMP-->
                <!--Community:-->
                <!--</label>-->
                <!--</div>-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">-->
                <!--<input type="text" v-model="crud.data.item.snmp_community"-->
                <!--placeholder="Введите SNMP community">-->
                <!--<form-error v-bind:value="crud.errors.snmp_community"></form-error>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--<div class="add-data__item">-->
                <!--<div class="row">-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">-->
                <!--<label><i class="fal fa-map-marker-alt"></i> Адрес:</label>-->
                <!--</div>-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">-->
                <!--<input type="text" v-model="crud.data.item.location_address"-->
                <!--placeholder="Населенный пункт, улица, дом">-->
                <!--<form-error-->
                <!--v-bind:value="crud.errors.location_address"></form-error>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--<div class="add-data__item">-->
                <!--<div class="row">-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">-->
                <!--<label><i class="fal fa-comment"></i> Описание:</label>-->
                <!--</div>-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">-->
                <!--<input type="text" v-model="crud.data.item.description"-->
                <!--placeholder="Введите Описание">-->
                <!--<form-error v-bind:value="crud.errors.description"></form-error>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--</form>-->
                <!--</div>-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">-->
                <!--<div class="comparison-data">-->
                <!--<div class="comparison-data__header">-->
                <!--<div class="row">-->
                <!--<div class="col-xs-8 col-sm-8 col-md-12 col-lg-12">-->
                <!--<h3><i class="fal fa-eye"></i> Текущие данные</h3>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--<div class="comparison-data__item">-->
                <!--<div class="row">-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">-->
                <!--<div class="label"><i class="fal fa-server"></i> Имя NAS:</div>-->
                <!--</div>-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">-->
                <!--<div class="value"> {{ crud.data.item_view.name }}</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--<div class="comparison-data__item">-->
                <!--<div class="row">-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">-->
                <!--<div class="label"><i class="fal fa-map-pin"></i> Framed IP:</div>-->
                <!--</div>-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">-->
                <!--<div class="value">{{ crud.data.item_view.ip }}</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--<div class="comparison-data__item">-->
                <!--<div class="row">-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">-->
                <!--<div class="label"><i class="fal fa-user-cog"></i> Username:</div>-->
                <!--</div>-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">-->
                <!--<div class="value">{{ crud.data.item_view.username }}</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--<div class="comparison-data__item">-->
                <!--<div class="row">-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">-->
                <!--<div class="label"><i class="fal fa-key"></i> Password:</div>-->
                <!--</div>-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">-->
                <!--<div class="value">-->
                <!--<template v-if="password_visible">-->
                <!--{{ crud.data.item_view.password }}-->
                <!--<span data-title="Скрыть пароль" class="password"-->
                <!--@click="set_password_visible(0)"><i-->
                <!--class="fal fa-eye-slash"></i></span>-->
                <!--</template>-->
                <!--<template v-else>-->
                <!--<span data-title="Показать пароль" class="password"-->
                <!--@click="set_password_visible(1)"><i-->
                <!--class="fal fa-eye"></i></span>-->
                <!--</template>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--<div class="comparison-data__item">-->
                <!--<div class="row">-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">-->
                <!--<div class="label"><i class="fal fa-unlock"></i> Port SSH:</div>-->
                <!--</div>-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">-->
                <!--<div class="value">{{ crud.data.item_view.port_ssh }}</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--<div class="comparison-data__item">-->
                <!--<div class="row">-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">-->
                <!--<div class="label"><i class="fal fa-unlock"></i> Api Port:</div>-->
                <!--</div>-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">-->
                <!--<div class="value">{{ crud.data.item_view.api_port }}</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--<div class="comparison-data__item">-->
                <!--<div class="row">-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">-->
                <!--<div class="label"><i class="fal fa-users-class"></i> SNMP-->
                <!--Community:-->
                <!--</div>-->
                <!--</div>-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">-->
                <!--<div class="value">{{ crud.data.item_view.snmp_community }}</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--<div class="comparison-data__item">-->
                <!--<div class="row">-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">-->
                <!--<div class="label"><i class="fal fa-map-marker-alt"></i> Адрес:-->
                <!--</div>-->
                <!--</div>-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">-->
                <!--<div class="value">{{ crud.data.item_view.location_address }}</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--<div class="comparison-data__item">-->
                <!--<div class="row">-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">-->
                <!--<div class="label"><i class="fal fa-comment"></i> Описание:</div>-->
                <!--</div>-->
                <!--<div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">-->
                <!--<div class="value">{{ crud.data.item_view.description }}</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!-- CONTENT PAGE -->
                <!--</div>-->
            </div>
        </div>
    </section>
</template>

<script>
    import {CrudMixin} from "../../../../mixins/admin/crud/crud_base";
    import {CrudListMixin} from "../../../../mixins/admin/crud/crud_list";
    import FormErrors from '../form/errors.vue';
    import {SearchMixin} from "../../../../mixins/admin/search";
    import {DepartmentMixin} from "../../../../mixins/admin/department";

    export default {
        name: "nas-edit",
        data: function () {
            return {
                password_visible: false,
                crud: {
                    api: '/nas/',
                },
                item: {
                    department: "",
                    name: "",
                    description: "",
                    label: "",
                    ip: "",
                },
            }
        },
        mixins: [CrudMixin, CrudListMixin, SearchMixin, DepartmentMixin,],
        components: {
            "form-error": FormErrors
        },
        created: function () {
            this.load();
            this.load_departments();

            this.$root.$on('search_hit_nas', (function (data) {
                this.load();
            }).bind(this));

            this.$root.$on('search_clear_nas', (function (data) {
                this.load();
            }).bind(this));
        },
        beforeDestroy: function () {
            this.destroy_search_listeners("nas");
        },
        methods: {
            load() {
                this.crud_get_item(this.$route.params.id);
            },
            set_password_visible: function (value) {
                this.password_visible = value;
            }
        }
    }
</script>

<style scoped>

</style>