<template>
    <popup :settings="settings">
        <div slot="title">Недостаточно прав доступа</div>
        <div slot="content">
            <div class="modal-inform">
                <div class="modal-inform__icon"><i class="fal fa-user-lock"></i></div>
                <div class="modal-inform__title">Недостаточно прав доступа!</div>
            </div>
        </div>
        <div slot="footer">
            <div class="btn-group">
                <span class="btn btn_grey" @click="popup_close">
                    <span class="btn__icon"><i class="fal fa-frown"></i></span>
                    <span class="btn__title">Жаль</span>
                </span>
            </div>
        </div>
    </popup>
</template>

<script>
    import Popup from '../../modal/popup.vue'
    import {CrudMixin} from "../../../../../mixins/admin/crud/crud_base";

    export default {
        name: "no-access",
        props: ["settings", "crud"],
        mixins: [
            CrudMixin
        ],
        components: {
            "popup": Popup,
        },
    }
</script>

<style scoped>

</style>