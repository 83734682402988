<template>
  <div class="grid">
    <div class="grid__col">
      <div class="grid__box">
        <!--Програма лояльности-->
        <div class="page-set">
          <form class="form-set">
            <div class="form-set__row">
              <div class="form-set__col">
                <div class="form-set__item">
                  <div class="form-set__label">
                    <span class="form-set__label-icon"><i class="fal fa-tools"></i></span>
                    <span class="form-set__label-title">Тип программы лояльности</span>
                    <span class="form-set__label-help"
                          v-tooltip.right="TooltipText='Выберите одну из программ лояльности'"><i
                        class="fal fa-info-circle"></i></span>
                  </div>
                  <div class="form-set__value">
                    <div class="form-set__select">
                      <select required>
                        <option>Выключено </option>
                        <option>Проценты при оплате</option>
                        <option>Бонус при оплате от 3х месяцев</option>
                        <option>Кешбэк</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "bonus"
}
</script>

<style scoped>

</style>