<template>
  <section class="page">
    <div class="page__inner">
      <div class="container-width">
        <!--HEADER PAGE-->
        <div class="page__header">
          <h1 class="page__title">Отчет по тарифам</h1>
          <report-menu :active_name="'reports-page'"></report-menu>
        </div>

        <!--END HEADER PAGE-->
        <!-- BODY PAGE -->
        <div class="page__body">
          <!--FILTER BLOCK PAGE-->
          <FilterBlock @resetComponentFilters="resetComponentFilters">
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
              <filter-by-field v-model="department"
                               title="Отдел"
                               view="name"
                               :objects="departments"
                               :add-social-tariff="true"/>
            </div>
          </FilterBlock>
          <!--END FILTER BLOCK PAGE-->
          <div class="table-box">
            <table class="table-box__table">
              <thead class="table-box__thead">
              <tr class="table-box__thead-tr">
                <template v-for="col in cols">
                  <th @click="set_ordering(col.field)" class="table-box__th">
                                        <span class="table-box__thead-item table-box__thead-item_center">
                                            <span class="table-box__thead-title">
                                                {{ col.label }}
                                                <i class="fal" :class="[get_class_ordering_direction(col.field)]"></i>
                                            </span>
                                        </span>
                  </th>
                </template>
              </tr>
              </thead>
              <tbody class="table-box__tbody">
              <tr class="table-box__tr table-data" v-for="item in tariffs">
                <td class="table-box__td table-box__td_center">
                                    <span class="table-data__text" v-if="item.is_common_tariff">
                                        Все отделы
                                    </span>
                  <span class="table-data__text" v-else>
                                        {{ item.department_expand.name }}
                                    </span>
                </td>
                <td class="table-box__td table-box__td_center">
                                    <span class="table-data__text">
                                        {{ item.name }} ({{ item.system_name }})
                                    </span>
                </td>
                <td class="table-box__td table-box__td_center">
                  <!--<span class="table-data__text">-->
                  <!--{{ item.charging_amount_month }} ({{ item.charging_amount_day }})-->
                  <!--</span>-->

                  <div class="table-data__price">
                    <template v-if="item.charging_type === 'month'">
                      <span class="table-data__price-icon">₴</span>
                      <span class="table-data__price-value">{{ item.charging_amount_month }}</span>
                    </template>
                    <template v-if="item.charging_type === 'day'">
                                            <span class="table-data__price-item">
                                                <span class="table-data__price-icon">₴</span>
                                                <span class="table-data__price-value">{{
                                                    item.charging_amount_month
                                                  }}</span>
                                            </span>
                      <span class="table-data__price-divide"></span>
                      <span class="table-data__price-item">
                                                <span class="table-data__price-icon">₴</span>
                                                <span class="table-data__price-value">{{
                                                    item.charging_amount_day
                                                  }}</span>
                                            </span>
                    </template>
                    <template v-if="item.charging_type === 'hour'">
                      <span class="table-data__price-icon">₴</span>
                      <span class="table-data__price-value">{{ item.charging_amount_hour }} ч.</span>
                    </template>
                  </div>
                </td>
                <td class="table-box__td table-box__td_center">
                  <div class="table-data__speed">
                                         <span class="table-data__speed-item">
                                            <span class="table-data__speed-icon"><i
                                                class="fal fa-cloud-download"></i></span>
                                            <span class="table-data__speed-value">{{
                                                item.speed_tx | prettyMbit(1)
                                              }}</span>
                                         </span>
                    <span class="table-data__speed-divide"><i class="fal fa-arrows-h"></i></span>
                    <span class="table-data__speed-item">
                                             <span class="table-data__speed-icon"><i
                                                 class="fal fa-cloud-upload"></i> </span>
                                             <span class="table-data__speed-value">{{
                                                 item.speed_rx | prettyMbit(1)
                                               }}</span>
                                         </span>
                  </div>
                </td>
                <td class="table-box__td table-box__td_center">
                  <span class="table-data__currency"><i class="fal fa-users"></i></span>
                  <report-link :item="item" :field_name="'count_subscribers'"></report-link>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!--PAGINATION BLOCK-->
          <Pagination @loadItems="load_tariffs"
                      :options="limitOptions"
                      :limit-items="limit"
                      :page-items="page"
                      :count-items="count"
                      :add-null-option="true"/>
          <!--END PAGINATION BLOCK-->
        </div>
        <!-- END BODY PAGE -->
      </div>
    </div>
  </section>
</template>

<script>
import ReportMenu from './menu/menu.vue';
import {API} from "../../../../api";
import {OrderMixin} from "../../../../mixins/admin/order";
import ReportLink from "./sub_components/report-link";
import {DepartmentMixin} from "../../../../mixins/admin/department";
import {RouteHelperMixin} from "../../../../mixins/admin/route_helper";
import {PaginationMixin} from "../../../../mixins/admin/pagination";
import {FilterHelperMixin} from "../../../../mixins/admin/filter_helper";
import FilterBlock from "../filter-block/filter-block";
import Filter from "../filter-block/sub_components/filter";
import Pagination from "../pagination/pagination";

export default {
  name: "tariffs",
  components: {
    FilterBlock,
    "filter-by-field": Filter,
    Pagination,
    ReportLink,
    "report-menu": ReportMenu,
  },
  mixins: [OrderMixin, RouteHelperMixin, PaginationMixin, DepartmentMixin, FilterHelperMixin, OrderMixin],
  data() {
    return {
      cols: [
        {
          field: 'department__name',
          label: 'Отдел'
        },
        {
          field: 'name',
          label: 'Название тарифа'
        },
        {
          field: 'charging_amount_month',
          label: 'Стоимость'
        },
        {
          field: 'speed_tx',
          label: 'Скорость Download'
        },
        {
          field: 'count_subscribers',
          label: 'Кол-во абонетов'
        },
      ],
      tariffs: [],
      // options for ordering items
      ordering: "department__name",
      ordering_direction: 'ASC',
      // options for pagination block
      limitOptions: [15, 25, 50, 100],
      limit: 15,
      page: 1,
      count: 0,
      // filter options for department filter
      department: undefined,
      departments: [],
    }
  },
  created() {
    this.pagination_init(this.page, this.limit);

    this.set_data_from_route([
      {'name': 'ordering_direction', 'type': 'str'},
      {'name': 'ordering', 'type': 'str'}], this);
  },
  mounted() {
    this.loadFilterOptions();
    this.load_tariffs();
  },
  methods: {
    load_tariffs() {
      var self = this;

      var params = Object.assign({}, self.$route.query,
          {
            page: !self.$route.query.page ? self.page : self.$route.query.page,
            limit: !self.$route.query.limit ? self.limit : self.$route.query.limit,
            offset: !self.$route.query.offset ? self.get_offset(self.page) : self.$route.query.offset,
          });

      // set ordering params to load ordered items
      params = self.setOrderingToParams(params);

      self.$set(params, 'department', self.department)

      API.get('/report/tariff/', params)
          .then(function (response) {
            self.tariffs = response.data.results;
            self.count = response.data.count;

            // update route ordering params
            self.updateRouteQueryOrderingParams();
            // update department filter
            self.updateRouterQuery('department', self.departments, self.department);
          })
          .catch(function (error) {
            // API.default_catch(self, error)
          })
          .then(function () {
            // API.default_always(self);
          })
    },
    resetComponentFilters() {
      this.resetFilters();
      this.load_tariffs();
    },
    // here we have to load data for filtering
    loadFilterOptions() {
      this.load_departments();
    },
  },
  watch: {
    // watcher for filter department
    department(value) {
      if (value && value.length > 0) {
        this.load_tariffs();
      }
    },
    // watch multiple properties with single handler
    orderingAndOrderingName(value) {
      this.load_tariffs();
    },
  }
}
</script>

<style scoped>

</style>