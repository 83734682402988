<template>
    <popup :settings="settings">
        <div slot="title">Пополнение Unicard</div>
        <div slot="content">
            <div class="body-size">
                <div class="body-size__480">
                    <form class="form-modal" @submit.prevent="save()">
                        <div class="form-modal__group">
                            <fieldset class="form-modal__item">
                                <span class="form-modal__title">
                                <span class="form-modal__icon"><i class="fal fa-credit-card-front"></i></span>
                                    Код пополнения
                                </span>
                                <input v-model="form.recharge_code" type="text" class="form-modal__input"
                                       placeholder="Введите секретный код пополнения">
                                <form-error v-bind:value="errors.recharge_code"></form-error>
                            </fieldset>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="btn-group">
                <span class="btn btn_grey" @click="popup_close">
                    <span class="btn__icon"><i class="fal fa-times-circle"></i></span>
                    <span class="btn__title">Отмена</span>
                </span>
                <span class="btn btn_blue" @click="save()">
                    <span class="btn__icon"><i class="fal fa-check"></i></span>
                    <span class="btn__title">Пополнить</span>
                </span>
            </div>
        </div>
    </popup>
</template>

<script>
    import Popup from '../../modal/popup.vue'
    import {CrudMixin} from "../../../../../mixins/admin/crud/crud_base";
    import FormErrors from '../../form/errors.vue';
    import {SubscriberConnectionMixin} from "../../../../../mixins/admin/subscriber_connection";
    import {API} from "../../../../../api";

    export default {
        name: "unicard-recharge",
        props: ["settings", "subscriber_connection"],
        data() {
            return {
                form: this.form_data(),
                errors: {}
            }
        },
        mixins: [
            CrudMixin, SubscriberConnectionMixin
        ],
        components: {
            "popup": Popup,
            "form-error": FormErrors
        },
        methods: {
            form_data() {
                return {
                    recharge_code: "",
                }
            },
            save() {
                var self = this;
                this.recharge_with_unicard(this.subscriber_connection.id, this.form)
                    .then(function (response) {
                        var unicard = response.data.unicard;
                        self.$emit('update_subscriber_connection');
                        self.form = self.form_data();
                        self.alert("Пополнение Unicard", "Успешно пополнено на " + unicard.unicard_set_expand.face_value + "грн.");
                        self.popup_close();
                    })
                    .catch(function (error) {
                        API.default_catch(self, error);
                    })
                    .then(function () {
                        API.default_always(self);
                    });
            }
        }
    }
</script>

<style scoped>

</style>