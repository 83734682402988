<template>
    <section class="page">
        <div class="page__inner">
            <div class="container-width">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="page-header">
                            <div class="row">
                                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <h1 class="page-header__title"> Новая магазинная партия</h1>
                                </div>
                                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <DropDown></DropDown>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- CONTENT PAGE -->
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="page-body">
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <form action="" class="add-data">
                                        <div class="row">
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div class="add-data__item">
                                                    <div class="row">
                                                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                                            <label><i class="fal fa-server"></i> Магазин: <span
                                                                    class="control"><i class="fas fa-star-of-life"></i></span></label>
                                                        </div>
                                                        <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                                                            <template v-if="shops_disabled">
                                                                <input type="text" :value="get_shop_by_id(shop).name" disabled value="ФИО">
                                                            </template>
                                                            <template v-else>
                                                                <div class="selected">
                                                                    <select v-model="shop" required >
                                                                        <option value="" disabled hidden>Выберите
                                                                            магазин
                                                                        </option>
                                                                        <option v-for="item in shops" v-bind:value="item.id">{{ item.name }}</option>
                                                                    </select>
                                                                </div>
                                                            </template>
                                                        </div>
                                                    </div>
                                                    <form-error v-bind:value="get_shop_errors()"></form-error>
                                                </div>
                                                <span v-for="(unicard_shop_batch, index) in unicard_shop_batches_list">

                                                    <div class="add-data__header" v-if="index > 0">
                                                        <div class="add-data__padding-item"></div>
                                                        <div class="row">
                                                            <div class="col-xs-12 col-sm-6 col-md-8 col-lg-8">
                                                                <h3>Дополнительная партия</h3>
                                                            </div>
                                                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                                                <div class="add-data__remove-item" @click="remove(index)">
                                                                    <i class="fal fa-minus-circle"></i> Удалить партию
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <additional-consigment
                                                            @update_unicard_set="update_unicard_set"
                                                            :errors="get_errors(index)"
                                                            :index="index"
                                                            :available_batch_series="available_batch_series"
                                                            ></additional-consigment>
                                                </span>

                                            </div>
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <span class="add-data__add-item" @click="add()">+ Добавить партию</span>
                                            </div>
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div class="add-data__footer">
                                                    <router-link title="Отмена" :to="{ name: 'unicards-consignment-shop'}">
                                                        <span class="btn grey">Отмена</span>
                                                    </router-link>
                                                    <span class="btn blue" @click="save()">Отправить <i class="fal fa-long-arrow-right"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import DropDown from "../dropdown/dropdown";
    import FormErrors from '../../form/errors.vue';
    import {User} from "../../../../../mixins/admin/user";
    import {API} from "../../../../../api";
    import AdditionalConsigment from "./sub_components/additional_consigment";
    import {UnicardsMixin} from "../../../../../mixins/admin/unicards";

    export default {
        name: "new-shop-consignmenta",
        data(){
          var item = {
              "shop": "",
              "unicard_batch": "",
              "number_from": "",
              "number_to": "",
          };

          return {
              shop: "",
              shops_disabled: false,
              shops: [],
              available_batch_series: [],
              unicard_shop_batches_list: [Object.assign({}, item)],
              unicard_shop_batches_item: item,
              errors: []
          }
        },
        mixins: [User, UnicardsMixin],
        components: {
            "form-error": FormErrors,
            AdditionalConsigment,
            DropDown
        },
        mounted(){
            this.fetchUser();
        },
        methods: {
            fetchUser(){
                var self = this;
                API.get("/shops/").then(function (response) {
                        self.shops = response.data.results;
                });

                this.load_available_for_shop();

                if("shop_id" in this.$route.query){
                    this.shop = this.$route.query.shop_id;
                    this.shops_disabled = true;
                }
            },
            save(){
                var self = this;
                API.post('/api/v1/unicards/shop-batch-many/', {'unicard_shop_batches': this.unicard_shop_batches_list})
                    .then(function (response) {
                        self.$router.push({name: "unicards-consignment-shop"});
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            },
            get_errors(index){
                if(this.errors.unicard_shop_batches && index in this.errors.unicard_shop_batches) {
                    return this.errors.unicard_shop_batches[index]
                }
                return {}
            },
            get_shop_errors(){
                if(this.errors.unicard_shop_batches){
                    for(var error of this.errors.unicard_shop_batches){
                        if("shop" in error){
                            return error["shop"];
                        }
                    }
                }
                return undefined
            },
            add(){
                var item = Object.assign({}, this.unicard_shop_batches_item);
                this.unicard_shop_batches_list.push(item);
            },
            remove(index){
                this.unicard_shop_batches_list.splice(index, 1);
            },
            update_unicard_set(obj){
                this.unicard_shop_batches_list[obj.index].unicard_batch = obj.unicard_batch;
                this.unicard_shop_batches_list[obj.index].number_from = obj.number_from;
                this.unicard_shop_batches_list[obj.index].number_to = obj.number_to;
            },
            get_shop_by_id(shop){
                for (var shop of this.shops){
                    if(shop.id === this.shop){
                        return shop
                    }
                }

                return {}
            }
        },
        watch: {
            shop(){
                // Update shop
                this.unicard_shop_batches_item.shop = this.shop;
                for(var index in this.unicard_shop_batches_list){
                    this.unicard_shop_batches_list[index].shop = this.shop;
                }
            }
        }
    }
</script>

<style scoped>

</style>