<template>
  <section class="page">
    <div class="page__inner">
      <div class="container-width">
        <!--HEADER PAGE-->
        <div class="page__header">
          <h1 class="page__title">Отчет сети</h1>
          <report-menu :active_name="'reports-page'"></report-menu>
        </div>
        <!-- END HEADER PAGE -->
        <!-- BODY PAGE -->
        <div class="page__body">
          <div class="table-box">
            <div class="table-box__header">
              <div class="table-box__header-block">
                <div class="table-filter">
                </div>
              </div>
            </div>
            <table class="table-box__table">
              <thead class="table-box__thead">
              <th class="table-box__th">
                <span class="table-box__thead-item">
                  <span class="table-box__thead-title">Сеть</span>
                </span>
              </th>
              <th class="table-box__th">
                <span class="table-box__thead-item table-box__thead-item_center">
                  <span class="table-box__thead-title">Архив</span>
                </span>
              </th>
              <th class="table-box__th">
                <span class="table-box__thead-item table-box__thead-item_center">
                  <span class="table-box__thead-title">6 мес</span>
                </span>
              </th>
              <th class="table-box__th">
                <span class="table-box__thead-item table-box__thead-item_center">
                  <span class="table-box__thead-title">3 мес</span>
                </span>
              </th>
              <th class="table-box__th">
                <span class="table-box__thead-item table-box__thead-item_center">
                  <span class="table-box__thead-title">1 мес</span>
                </span>
              </th>
              <th class="table-box__th">
                <span class="table-box__thead-item table-box__thead-item_center">
                  <span class="table-box__thead-title">Более 6 мес</span>
                </span>
              </th>
              <th class="table-box__th">
                <span class="table-box__thead-item table-box__thead-item_center">
                  <span class="table-box__thead-title">Детали</span>
                </span>
              </th>
              </thead>
              <template v-for="(department, index) in departments">
                <NetworkReportDepartmentInfo :department="department"
                                             :key="index"
                                             :index="index"
                                             :show-table="showTables[index].isVisible"
                                             @showSettlement="showDepartmentSettlements(index)"/>
                <template v-if="showTables[index].isVisible">
                  <NetworkReportSettlementInfo v-for="settlement in department.settlements"
                                               :settlement="settlement" />
                </template>
              </template>
            </table>
          </div>
        </div>
        <!-- END BODY PAGE -->
      </div>
    </div>
  </section>

</template>

<script>
import ReportMenu from './../menu/menu';
import {API} from "../../../../../api";
import NetworkReportDepartmentInfo from "./network-report-department-info";
import NetworkReportSettlementInfo from "./network-report-settlement-info";

export default {
  name: "network",
  components: {
    NetworkReportDepartmentInfo,
    NetworkReportSettlementInfo,
    'report-menu': ReportMenu,
  },
  data() {
    return {
      departments: [],
      showTables: [],
    }
  },
  mounted() {
    this.fetchUser();
  },
  methods: {
    showDepartmentSettlements(index) {
      this.showTables[index].isVisible = !this.showTables[index].isVisible;
    },
    fetchUser() {
      this.loadNetworkReportDepartmentsData();
    },
    loadNetworkReportDepartmentsData() {
      var self = this;

      API.get('/report/subscriber-last-refill-transaction/')
        .then(res => {
          self.departments = res.data;
          self.initShowTables();
        })
        .catch(err => {
          API.default_catch(self, err);
        })
        .then(() => {
          API.default_always(self);
        })
    },
    initShowTables() {
      var arr = [];

      this.departments.forEach((value, index, array) => {
        let obj = {};
        obj['isVisible'] = false;
        arr.push(obj);
      })
      this.showTables = arr;
    },
  },
}
</script>

<style scoped>

</style>