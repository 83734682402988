<template>
    <section class="page">
        <div class="page__inner">
            <div class="container-width">
                <!--HEADER PAGE-->
                <div class="page__header">
                    <h1 class="page__title" v-if="Object.keys(subscriber_connection).length">
                        <router-link class="page__title-back" title="Абоненты"
                                     :to="{ name: 'subscribers-item', params: {id: subscriber_connection.subscriber_expand.id}}">
                            <i class="fal fa-arrow-circle-left"></i>
                        </router-link>
                        <subscriber-name :subscriber_connection_input="subscriber_connection"></subscriber-name>
                        <span class="page__title-login">({{ subscriber_connection.username }})</span>
                        <template v-if="subscriber_connection.online">
                            <span class="status-login status-login_auth">Online</span>
                        </template>
                        <template v-else>
                            <span class="status-login status-login_deny">Offline</span>
                        </template>
                    </h1>
                    <div class="btn-group">

                        <app-has-perm-action-btn :perms="'subscriber_connection_send_notif_sms'">
                             <template slot-scope="{ access }">

                                 <span class="btn-l" :class="access ? 'btn-l_orange' : 'btn-l_grey'" v-tooltip="TooltipText='Отправить СМС'" >
                                    <span class="btn-l__icon"><i class="fal fa-sms"></i></span>
                                </span>

                             </template>
                        </app-has-perm-action-btn>

                        <app-has-perm-action-btn :perms="'subscriber_connection_send_notif_email'">
                             <template slot-scope="{ access }">

                                 <span class="btn-l" :class="access ? 'btn-l_blue' : 'btn-l_grey'" v-tooltip="TooltipText='Отправить E-mail'">
                                    <span class="btn-l__icon"><i class="fal fa-at"></i></span>
                                </span>

                             </template>
                        </app-has-perm-action-btn>

                    </div>
                </div>
                <!--END HEADER PAGE-->

                <!--INFO SCORE-->
                <template v-if="subscriber_connection.tariff_expand">
                    <!--Уведомление о подключенном акционном тарифе-->
                    <NotificationPromotionalTariff :subscriber_connection="subscriber_connection" />
                    <!--Уведомление о тестовом периоде-->
                    <NotificationTrialPeriod :subscriber_connection="subscriber_connection" />
                    <!--Уведомление о запланированной смене тарифа-->
                    <template v-for="tariff_transfer in tariff_transfers">
                        <NotificationTransferTariff :tariff_transfer="tariff_transfer" :key="tariff_transfer.id"
                                                    @popup_cancel_planing_tariff="popup_cancel_planing_tariff" />
                    </template>
                    <div class="blocks" v-has-perm="'subscribers_access'">
                        <!--Баланс-->
                        <div class="blocks__box blocks__box_balance">
                            <!--Кешбек-->
                            <div class="cashback" v-if="isCashback">
                                <div class="cashback__header">
                                    <span class="cashback__header-icon"><i class="fal fa-gift"></i></span>
                                    <span class="cashback__header-title">Кешбэк</span>
                                </div>
                                <div class="cashback__body">
                                    <div class="cashback__summ">
                                        <span class="cashback__summ-currency">&#x20b4</span>
                                        <span class="cashback__summ-value">{{ subscriber_connection.bonus_balance }}</span>
                                    </div>
                                    <div class="cashback__desc">
                                        <template v-if="left_until_withdrawal()">
                                            <span class="cashback__desc-title">Осталось накопить:</span>
                                            <span class="cashback__desc-date">&#x20b4 {{ left_until_withdrawal() }} грн</span>
                                        </template>
                                        <template v-else>
                                            <span class="cashback__desc-date">Вы можете вывести</span>
                                        </template>
                                    </div>
                                    <button class="cashback__switch" @click="isShowBlock"><i
                                            class="fal fa-piggy-bank"></i></button>
                                </div>
                                <div class="balance__footer">
                                    <template v-if="has_perm('financial_operations_recharge_by_bonus')">
                                        <template v-if="has_perm('financial_operations_edit_bonus_balance')">
                                                <span class="btn btn_darkgrey" @click="recharge_balance_by_bonus()">
                                                    <span class="btn__icon"><i class="fal fa-piggy-bank"></i></span>
                                                    <span class="btn__title">Вывести на счет</span>
                                                </span>
                                                <span class="btn btn_green" @click="popup_bonus_balance_up()">
                                                    <span class="btn__icon btn__icon_nt"><i class="fal fa-plus"></i></span>
                                                    <span class="btn__title"></span>
                                                </span>
                                                <span class="btn btn_red" @click="popup_bonus_balance_reset()">
                                                    <span class="btn__icon btn__icon_nt"><i class="fal fa-ban"></i></span>
                                                    <span class="btn__title"></span>
                                                </span>
                                        </template>
                                        <template v-else>
                                            <span class="btn btn_darkgrey" @click="recharge_balance_by_bonus()">
                                                <span class="btn__icon"><i class="fal fa-piggy-bank"></i></span>
                                                <span class="btn__title">Вывести на счет</span>
                                            </span>
                                        </template>
                                    </template>
                                    <template v-else>
                                            <span class="btn btn_grey">
                                                <span class="btn__icon"><i class="fal fa-piggy-bank"></i></span>
                                                <span class="btn__title">Вывести на счет</span>
                                            </span>
                                    </template>
                                </div>
                            </div>
                            <!--Остаток баланса-->
                            <div v-bind:class="[is_status_active(subscriber_connection) ? 'unlock' : 'lock']" class="balance" v-if="!isBalance">
                                <div class="balance__header">
                                    <span class="balance__header-icon"><i class="fal fa-piggy-bank"></i></span>
                                    <span class="balance__header-title">Баланс</span>
                                </div>
                                <div class="balance__body">
                                    <div class="balance__summ">
                                        <span class="balance__summ-currency">&#x20b4</span>
                                        <span class="balance__summ-value">{{ subscriber_connection.balance }}</span>
                                    </div>
                                    <template v-if="is_status_active(subscriber_connection)"><!--check status is CLS_AUTH OR CLS_DENY-->
                                        <div v-if="!isTrustDayActive(subscriber_connection)" class="balance__desc"><!--check is active trust day now-->
                                            <span class="balance__desc-title">Средств достаточно до:</span>
                                            <span class="balance__desc-date">
                                                <b>{{ subscriber_connection.balance_positive_till_date }}</b> ({{ get_balance_days() }})
                                            </span>
                                        </div>
                                        <div class="balance__desc" v-else>
                                            <span class="balance__desc-title">Включена услуга "День доверия"</span>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="balance__desc">
                                            <span class="balance__desc-title">Заблокирован:</span>
                                            <span class="balance__desc-date">
                                                {{ subscriber_connection.cls_list_deny_date | moment("DD.MM.YYYY HH:mm") }}
                                            </span>
                                        </div>
                                    </template>
                                    <template v-if="isPrivateUser()">
                                        <button class="balance__switch" @click="isShowBlock"><i class="fal fa-gift"></i></button>
                                    </template>
                                </div>
                                <div class="balance__footer" v-if="isPrivateUser()" >

                                    <app-has-perm-action-btn :perms="'financial_operations_recharge_unicard'" @action="popup_unicard_recharge()">
                                         <template slot-scope="{ access }">

                                            <span class="btn" :class="access ? 'btn_green' : 'btn_grey'">
                                                <span class="btn-score__icon"></span>
                                                <span class="btn-score__title">Пополнения Unicard</span>
                                            </span>

                                         </template>
                                    </app-has-perm-action-btn>

                                </div>
                            </div>
                        </div>
                        <!--Расходы-->
                        <div class="blocks__box blocks__box_expenses">
                            <div class="expenses">
                                <div class="expenses__header">
                                    <span class="expenses__header-icon"><i class="fal fa-exchange"></i></span>
                                    <span class="expenses__header-title">Расходы</span>
                                </div>
                                <div class="expenses__body">
                                    <div class="expenses__expense">
                                        <!--Если тарификация указана помесячно-->
                                        <template v-if="subscriber_connection.tariff_expand.charging_type === 'month'">
                                            <div class="expenses__total">
                                                <span class="expenses__total-currency">&#x20b4</span>
                                                <span class="expenses__total-value">{{ subscriber_connection.total_cost_month }}</span>
                                                <span class="expenses__total-period">/мес</span>
                                            </div>
                                        </template>
                                        <!--Если тарификация указана посуточно-->
                                        <template v-if="subscriber_connection.tariff_expand.charging_type === 'day'">
                                            <div class="expenses__total">
                                                <span class="expenses__total-currency">&#x20b4</span>
                                                <span class="expenses__total-value">{{ subscriber_connection.total_cost_month }}</span>
                                                <span class="expenses__total-period">/мес</span>
                                            </div>
                                            <div class="expenses__expense-divide"></div>
                                            <div class="expenses__summ">
                                                <span class="expenses__summ-currency">&#x20b4</span>
                                                <span class="expenses__summ-value">{{ subscriber_connection.total_cost_day }}</span>
                                                <span class="expenses__summ-period">/день</span>
                                            </div>
                                        </template>
                                        <!--Если тарификация указана почасово-->
                                        <template v-if="subscriber_connection.tariff_expand.charging_type === 'hour'">
                                            <div class="expenses__summ">
                                                <span class="expenses__summ-currency">&#x20b4</span>
                                                <span class="expenses__summ-value">{{ subscriber_connection.total_cost_hour }}</span>
                                                <span class="expenses__summ-period">/час</span>
                                            </div>
                                        </template>
                                    </div>
                                    <div class="expenses__desc">
                                        <span class="expenses__desc-title">Расходы содержат сумму <br/>всех подключенных услуг</span>
                                        <span class="expenses__summ-value">{{ subscriber_connection.services_total_day_amount }}</span>
                                        <span class="expenses__summ-value">{{ subscriber_connection.services_total_month_amount }}</span>
                                    </div>
                                </div>
                                <div class="expenses__footer"></div>
                            </div>
                        </div>
                        <!--Тариф-->
                        <div class="blocks__box">
                            <div class="rate">
                                <div class="rate__header">
                                    <div class="rate__tarif">
                                        <span class="rate__tarif-icon"><i class="fal fa-business-time"></i></span>
                                        <span class="rate__tarif-title">Системное имя:</span>
                                        <template
                                                v-if="tariff_transfers.length || !has_perm('subscribers_add_edit')">
                                            <span class="rate__tarif-name rate__tarif-name_deactive">{{ subscriber_connection.tariff_expand.system_name }}</span>
                                        </template>
                                        <template v-else>
                                            <span class="rate__tarif-name" @click="popup_transfer()">{{ subscriber_connection.tariff_expand.system_name }}</span>
                                        </template>
                                    </div>
                                    <div class="rate__tarif">
                                        <span class="rate__tarif-icon"><i class="fal fa-briefcase"></i></span>
                                        <span class="rate__tarif-title">Тариф:</span>
                                        <template
                                                v-if="tariff_transfers.length || !has_perm('subscribers_add_edit')">
                                            <span class="rate__tarif-name rate__tarif-name_deactive">{{ subscriber_connection.tariff_expand.name }}</span>
                                        </template>
                                        <template v-else>
                                            <span class="rate__tarif-name" @click="popup_transfer()">{{ subscriber_connection.tariff_expand.name }}</span>
                                        </template>
                                    </div>
                                </div>
                                <div class="rate__body">
                                    <div class="rate__block">
                                        <div class="rate__price">
                                            <!--Если тарификация указана помесячно-->
                                            <template
                                                    v-if="subscriber_connection.tariff_expand.charging_type === 'month'">
                                                <div class="rate__total">
                                                    <span class="rate__total-currency">&#x20b4</span>
                                                    <span class="rate__total-value">{{ subscriber_connection.tariff_expand.charging_amount_month }}</span>
                                                    <span class="rate__total-period">/мес</span>
                                                </div>
                                            </template>
                                            <!--Если тарификация указана посуточно-->
                                            <template
                                                    v-if="subscriber_connection.tariff_expand.charging_type === 'day'">
                                                <div class="rate__total">
                                                    <span class="rate__total-currency">&#x20b4</span>
                                                    <span class="rate__total-value">{{ subscriber_connection.tariff_expand.charging_amount_month }}</span>
                                                    <span class="rate__total-period">/мес</span>
                                                </div>
                                                <div class="rate__price-divide"></div>
                                                <div class="rate__summ">
                                                    <span class="rate__summ-currency">&#x20b4</span>
                                                    <span class="rate__summ-value">{{ subscriber_connection.tariff_expand.charging_amount_day }}</span>
                                                    <span class="rate__summ-period">/день</span>
                                                </div>
                                            </template>
                                            <!--Если тарификация указана почасово-->
                                            <template
                                                    v-if="subscriber_connection.tariff_expand.charging_type === 'hour'">
                                                <div class="rate__total">
                                                    <span class="rate__total-currency">&#x20b4</span>
                                                    <span class="rate__total-value">{{ subscriber_connection.tariff_expand.charging_amount_hour }}</span>
                                                    <span class="rate__total-period">/час</span>
                                                </div>
                                            </template>
                                        </div>
                                        <div class="rate__desc">
                                            Стоимость тарифа биллингового периода
                                        </div>
                                        <ul class="rate__info">
                                            <li class="rate__info-item">
                                                <span class="rate__info-params">
                                                    <span class="rate__info-icon"><i
                                                            class="fal fa-calendar-alt"></i></span>
                                                    <span class="rate__info-title">Дата подключения:</span>
                                                </span>
                                                <span class="rate__info-value">{{ subscriber_connection.tariff_change_date | moment("DD/MM/YYYY") }}</span>
                                            </li>
                                        </ul>
                                        <div class="rate__actions">
                                            <template
                                                    v-if="tariff_transfers.length || !has_perm('subscribers_add_edit')">
                                                <span class="btn-b btn-b_grey">
                                                    <span class="btn-b__icon"><i class="fal fa-exchange"></i></span>
                                                    <span class="btn-b__title">Сменить тариф</span>
                                                </span>
                                            </template>
                                            <template v-else>
                                                <span class="btn btn_blue" @click="popup_transfer()">
                                                    <span class="btn__icon"><i class="fal fa-exchange"></i></span>
                                                    <span class="btn__title">Сменить тариф</span>
                                                </span>
                                            </template>
                                        </div>
                                    </div>
                                    <div class="rate__block rate__block_params">
                                        <div class="rate__params">
                                            <div class="rate__speed">
                                                <div class="rate__tx">
                                                    <span class="rate__tx-icon"><i
                                                            class="fal fa-cloud-download"></i></span>
                                                    <span class="rate__tx-value">{{ subscriber_connection.speed_rx | prettyMbit(1) }} Мбит/с</span>
                                                </div>
                                                <div class="rate__speed-divide"></div>
                                                <div class="rate__rx">
                                                    <span class="rate__rx-icon"><i
                                                            class="fal fa-cloud-download"></i></span>
                                                    <span class="rate__rx-value">{{ subscriber_connection.speed_rx | prettyMbit(1) }} Мбит/с</span>
                                                </div>
                                            </div>
                                            <!--Спецификации тарифа-->
                                            <ul class="rate__spec">
                                                <!--Лимит трафика-->
                                                <li class="rate__spec-item">
                                                    <span class="rate__spec-params">
                                                        <span class="rate__spec-icon"><i
                                                                class="fal fa-tasks-alt"></i></span>
                                                        <span class="rate__spec-title">Лимит трафика:</span>
                                                    </span>
                                                    <span class="rate__spec-value">
                                                        <span class="rate__spec-value rate__spec-value_item">
                                                             <span class="rate__spec-icon rate__spec-icon_v"><i
                                                                     class="fal fa-download"></i></span>
                                                             <span class="rate__spec-title rate__spec-title_v">
                                                                 <template
                                                                         v-if="subscriber_connection.tariff_expand.traffic_limit_tx === 0">
                                                                    Безлим
                                                                </template>
                                                                <template v-else>
                                                                    {{ subscriber_connection.tariff_expand.traffic_limit_tx | prettyGb(1) }} Гбайт
                                                                </template>
                                                             </span>
                                                        </span>
                                                        <span class="rate__spec-value rate__spec-value_item">
                                                             <span class="rate__spec-icon rate__spec-icon_v"><i
                                                                     class="fal fa-upload"></i></span>
                                                             <span class="rate__spec-title rate__spec-title_v">
                                                                 <template
                                                                         v-if="subscriber_connection.tariff_expand.traffic_limit_rx === 0">
                                                                    Безлим
                                                                 </template>
                                                                 <template v-else>
                                                                    {{ subscriber_connection.tariff_expand.traffic_limit_rx | prettyGb(1) }} Гбайт
                                                                 </template>
                                                             </span>
                                                        </span>
                                                    </span>
                                                </li>
                                                <!--Тип тарификации-->
                                                <li class="rate__spec-item">
                                                    <span class="rate__spec-params">
                                                        <span class="rate__spec-icon"><i
                                                                class="fal fa-hourglass-end"></i></span>
                                                        <span class="rate__spec-title">Тарификация:</span>
                                                    </span>
                                                    <span class="rate__spec-value">
                                                        <span class="rate__spec-icon rate__spec-icon_v"><i
                                                                class="fal fa-stopwatch"></i></span>
                                                        <span class="rate__spec-title rate__spec-title_v">
                                                            <template
                                                                    v-if="subscriber_connection.tariff_expand.charging_type === 'month'">
                                                                Месяц
                                                            </template>
                                                            <template
                                                                    v-if="subscriber_connection.tariff_expand.charging_type === 'day'">
                                                                Сутки
                                                            </template>
                                                            <template
                                                                    v-if="subscriber_connection.tariff_expand.charging_type === 'hour'">
                                                                Час
                                                            </template>
                                                        </span>
                                                    </span>
                                                </li>
                                                <li class="rate__spec-item">
                                                    <span class="rate__spec-params">
                                                        <span class="rate__spec-icon"><i
                                                                class="fal fa-ethernet"></i></span>
                                                        <span class="rate__spec-title">Технология:</span>
                                                    </span>
                                                    <span class="rate__spec-value">
                                                        <span class="rate__spec-icon rate__spec-icon_v"><i
                                                                class="fal fa-router"></i></span>
                                                        <span class="rate__spec-title rate__spec-title_v">Wi-fi</span>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="score__item">
                        <div class="not-installed">
                            <i class="fal fa-receipt"></i>
                            <span class="info">Тариф не установлен!</span>
                            <span class="btn orange"
                                  @click="popup_change()">Установить тариф</span>
                        </div>
                    </div>
                </template>
                <!--END INFO SCORE-->
                <!--BODY PAGE-->
                <div class="tabs-sc" id="tabs-sc">
                    <ul class="tabs-sc__nav">
                        <li class="tabs-sc__link" v-bind:class="{ 'is-active': isActive === 'data' }"
                            v-on:click="isActive = 'data'">
                            <span class="tabs-sc__link-icon"><i class="fal fa-users-cog"></i></span>
                            <span class="tabs-sc__link-title">Лицевой счет</span>
                        </li>
                        <li class="tabs-sc__link" v-bind:class="{ 'is-active': isActive === 'service' }"
                            v-on:click="isActive = 'service'">
                            <span class="tabs-sc__link-icon"><i class="fal fa-toolbox"></i></span>
                            <span class="tabs-sc__link-title">Услуги</span>
                        </li>
                        <li class="tabs-sc__link" v-bind:class="{ 'is-active': isActive === 'logactions' }"
                            v-on:click="isActive = 'logactions'">
                            <span class="tabs-sc__link-icon"><i class="fal fa-hryvnia"></i></span>
                            <span class="tabs-sc__link-title">Операции со счетом</span>
                        </li>
                        <li class="tabs-sc__link" v-bind:class="{ 'is-active': isActive === 'logpppoe' }"
                            v-on:click="isActive = 'logpppoe'">
                            <span class="tabs-sc__link-icon"><i class="fal fa-power-off"></i></span>
                            <span class="tabs-sc__link-title">Активность</span>
                        </li>
                        <li class="tabs-sc__link" v-bind:class="{ 'is-active': isActive === 'logpay' }"
                            v-on:click="isActive = 'logpay'">
                            <span class="tabs-sc__link-icon"><i class="fal fa-wallet"></i></span>
                            <span class="tabs-sc__link-title">Платежи</span>
                        </li>
                        <li class="tabs-sc__link" v-bind:class="{ 'is-active': isActive === 'comments' }"
                            v-on:click="isActive = 'comments'">
                            <span class="tabs-sc__link-icon"><i class="fal fa-comments"></i></span>
                            <span class="tabs-sc__link-title">Примечания</span>
                        </li>
                    </ul>
                    <div class="tabs-sc__container">
                        <!--Лицевой счет-->
                        <div class="tabs-sc__item" v-bind:class="{ 'is-active': isActive === 'data' }">
                            <!--Режим просмотра данных лицевого счета-->
                            <template v-if="mode === 'view'">
                                <SubscribersConnectionView :subscriber_connection="subscriber_connection"
                                                           v-on:set_mode="set_mode"></SubscribersConnectionView>
                                <div class="grid">
                                    <div class="grid__col">
                                        <div class="grid__box">
                                            <div class="actions-score">
                                                <!--Баланс-->
                                                <div class="actions-score__item" v-has-perm="'subscribers_access'">
                                                    <div class="actions-score__label">
                                                      <span class="actions-score__label-icon"><i class="fal fa-piggy-bank"></i></span>
                                                      <span class="actions-score__label-title"> Баланс:</span>
                                                    </div>
                                                    <div class="actions-score__value">
                                                        <template v-if="subscriber_connection.balance >= 0">
                                                            <span class="actions-score__value-balance">
                                                                 &#x20b4 {{ subscriber_connection.balance }}
                                                            </span>
                                                        </template>
                                                        <template v-else>
                                                            <span class="actions-score__value-balance actions-score__value-balance_negative">
                                                                 &#x20b4 {{ subscriber_connection.balance }}
                                                            </span>
                                                        </template>
                                                    </div>
                                                    <div class="actions-score__actions">
                                                        <div class="btn-group">

                                                            <app-has-perm-action-btn :perms="'financial_operations_recharge'" @action="popup_balance_up()">
                                                                 <template slot-scope="{ access }">

                                                                      <span class="btn-score" :class="access ? 'btn-score_green' : 'btn-l_grey'">
                                                                          <span class="btn-score__title">Пополнить</span>
                                                                    </span>

                                                                 </template>
                                                            </app-has-perm-action-btn>

                                                            <app-has-perm-action-btn :perms="'financial_operations_set_balance'" @action="popup_balance_set()">
                                                                 <template slot-scope="{ access }">

                                                                    <span class="btn-score" :class="access ? 'btn-score_blue' : 'btn-l_grey'">
                                                                         <span class="btn-score__title">Задать баланс</span>
                                                                    </span>

                                                                 </template>
                                                            </app-has-perm-action-btn>

                                                        </div>
                                                    </div>
                                                </div>
                                                <!--Корректировка-->
                                                <div class="actions-score__item" v-has-perm="'subscribers_access'">
                                                    <div class="actions-score__label">
                                                      <span class="actions-score__label-icon"><i class="fal fa-edit"></i></span>
                                                      <span class="actions-score__label-title"> Корректировка:</span>
                                                    </div>
                                                    <div class="actions-score__value">
                                                        <span class="actions-score__value-group">
                                                            <span class="actions-score__value-balance">% {{ subscriber_connection.tariff_adjustment }}</span>
                                                            <span class="actions-score__value-divide"></span>
                                                            <span class="actions-score__value-balance">&#x20b4 {{
                                                                subscriber_connection.tariff_adjustment_amount }}</span>

                                                        </span>
                                                    </div>
                                                    <div class="actions-score__actions">
                                                        <div class="btn-group">

                                                            <app-has-perm-action-btn :perms="'financial_operations_tariff_adjustment'" @action="popup_balance_adjustment()">
                                                                 <template slot-scope="{ access }">

                                                                    <span class="btn-score" :class="access ? 'btn-score_green' : 'btn-l_grey'">
                                                                        <span class="btn-score__icon"></span>
                                                                        <span class="btn-score__title">Установить</span>
                                                                    </span>

                                                                 </template>
                                                            </app-has-perm-action-btn>

                                                            <app-has-perm-action-btn :perms="'financial_operations_tariff_adjustment'" @action="popup_balance_adjustment_reset()">
                                                                 <template slot-scope="{ access }">

                                                                     <span class="btn-score" :class="access ? 'btn-score_blue' : 'btn-l_grey'">
                                                                        <span class="btn-score__icon"></span>
                                                                        <span class="btn-score__title">Сбросить</span>
                                                                    </span>

                                                                 </template>
                                                            </app-has-perm-action-btn>


                                                        </div>
                                                    </div>
                                                </div>
                                                <!--Отсрочка-->
                                                <div class="actions-score__item" v-has-perm="'subscribers_access'">
                                                    <div class="actions-score__label">
                                                      <span class="actions-score__label-icon"><i class="fal fa-handshake-alt"></i></span>
                                                      <span class="actions-score__label-title"> Отсрочка:</span>
                                                    </div>
                                                    <div class="actions-score__value">
                                                        <span class="actions-score__value-group">
                                                            <span class="actions-score__value-balance">{{ subscriber_connection.balance_delay_days }} д</span>
                                                            <span class="actions-score__value-divide"></span>
                                                            <span class="actions-score__value-balance">&#x20b4 {{ subscriber_connection.balance_delay_days_amount }}</span>
                                                        </span>
                                                    </div>
                                                    <div class="actions-score__actions">
                                                        <div class="btn-group">

                                                            <app-has-perm-action-btn :perms="'financial_operations_balance_delay_days'" @action="popup_balance_credit()">
                                                                 <template slot-scope="{ access }">

                                                                    <span class="btn-score" :class="access ? 'btn-score_green' : 'btn-l_grey'">
                                                                        <span class="btn-score__icon"></span>
                                                                        <span class="btn-score__title">Установить</span>
                                                                    </span>

                                                                 </template>
                                                            </app-has-perm-action-btn>

                                                            <app-has-perm-action-btn :perms="'financial_operations_balance_delay_days'" @action="popup_balance_credit_reset()">
                                                                 <template slot-scope="{ access }">

                                                                    <span class="btn-score" :class="access ? 'btn-score_blue' : 'btn-l_grey'" >
                                                                        <span class="btn-score__icon"></span>
                                                                        <span class="btn-score__title">Сбросить</span>
                                                                    </span>

                                                                 </template>
                                                            </app-has-perm-action-btn>

                                                        </div>
                                                    </div>
                                                </div>
                                                <!--Перерасчет-->
                                                <div class="actions-score__item" v-has-perm="'subscribers_access'">
                                                    <div class="actions-score__label">
                                                      <span class="actions-score__label-icon"><i class="fal fa-handshake-alt"></i></span>
                                                      <span class="actions-score__label-title"> Перерасчет:</span>
                                                    </div>
                                                    <div class="actions-score__value">

                                                    </div>
                                                    <div class="actions-score__actions">
                                                        <div class="btn-group">

                                                            <app-has-perm-action-btn :perms="'financial_operations_recalculate_days'" @action="popup_recalculation()">
                                                                 <template slot-scope="{ access }">

                                                                    <span class="btn-score" :class="access ? 'btn-score_green' : 'btn-l_grey'">
                                                                        <span class="btn-score__icon"></span>
                                                                        <span class="btn-score__title">Перерасчет</span>
                                                                    </span>

                                                                 </template>
                                                            </app-has-perm-action-btn>

                                                        </div>
                                                    </div>
                                                </div>
                                                <!--Блокировка-->
                                                <div class="actions-score__item" v-has-perm="'subscribers_access'">
                                                    <div class="actions-score__label">
                                                      <span class="actions-score__label-icon"><i class="fal fa-handshake-alt"></i></span>
                                                      <span class="actions-score__label-title"> Блокировка:</span>
                                                    </div>
                                                    <div class="actions-score__value">
                                                        <span class="actions-score__value-status" v-bind:class="[cls_list_css_class(subscriber_connection)]">
                                                                    {{ subscriber_connection.cls_list_expand }}
                                                            </span>
                                                    </div>
                                                    <div class="actions-score__actions">
                                                        <div class="btn-group">
<!--                                                            TRUST DAY-->
                                                            <template v-if="isConnectTrustDay">
                                                                <span class="btn-score btn-score_blue"  @click="activate_trust_day_subscriber_connection(subscriber_connection)">
                                                                    <span class="btn-score__icon"></span>
                                                                    <span class="btn-score__title">День доверия</span>
                                                                </span>
                                                            </template>
<!--                                                            END TRUST DAY-->
                                                            <span class="btn-score btn-score_blue"  @click="activate_citizens_support(subscriber_connection)">
                                                                <span class="btn-score__icon"></span>
                                                                <span class="btn-score__title">Разблокировать на 7 дней</span>
                                                            </span>

                                                            <app-has-perm-action-btn :perms="'subscriber_connection_blocking'"
                                                                                     @action="subscriber_connection.cls_list === 'CLS_AUTH'?change_cls_list('CLS_DENY'):change_cls_list('CLS_AUTH')">
                                                                 <template slot-scope="{ access }">

                                                                     <template v-if="subscriber_connection.cls_list === 'CLS_AUTH'">
                                                                        <span class="btn-score" :class="access ? 'btn-score_red' : 'btn-l_grey'">
                                                                            <span class="btn-score__icon"></span>
                                                                            <span class="btn-score__title">Заблокировать</span>
                                                                        </span>

                                                                    </template>
                                                                    <template v-else>
                                                                        <span class="btn-score" :class="access ? 'btn-score_green' : 'btn-l_grey'">
                                                                            <span class="btn-score__icon"></span>
                                                                            <span class="btn-score__title">Разблокировать</span>
                                                                        </span>
                                                                    </template>

                                                                 </template>
                                                            </app-has-perm-action-btn>

                                                        </div>
                                                    </div>
                                                </div>
                                                <!--Вывод ошибки если включена админская блокировка-->
                                                <form-error v-bind:value="errors.service_trust_day"></form-error>
                                                <form-error v-bind:value="errors.cls_list"></form-error>
                                                <!--Админская блокировка-->
                                                <div class="actions-score__item" v-has-perm="'subscribers_access'">
                                                    <div class="actions-score__label">
                                                      <span class="actions-score__label-icon"><i class="fal fa-handshake-alt"></i></span>
                                                      <span class="actions-score__label-title"> Блокировка администратором:</span>
                                                    </div>
                                                    <div class="actions-score__value">
                                                        <span class="actions-score__value-status"
                                                                 v-bind:class="[temporarily_disabled_css_class(temporarily_disabled)]">
                                                                <template v-if="!temporarily_disabled">Отключена</template>
                                                                <template v-else>Включена</template>
                                                        </span>
                                                    </div>
                                                    <div class="actions-score__actions">
                                                        <div class="btn-group">
                                                            <app-has-perm-action-btn :perms="'subscriber_connection_admin_blocking'" @action="temporarily_disabled_login(!temporarily_disabled)">
                                                                 <template slot-scope="{ access }">

                                                                    <template v-if="!temporarily_disabled">
                                                                        <span class="btn-score" :class="access ? 'btn-score_red' : 'btn-l_grey'">
                                                                            <span class="btn-score__icon"></span>
                                                                            <span class="btn-score__title">Включить</span>
                                                                        </span>
                                                                    </template>
                                                                    <template v-else>
                                                                            <span class="btn-score" :class="access ? 'btn-score_green' : 'btn-l_grey'">
                                                                            <span class="btn-score__icon"></span>
                                                                            <span class="btn-score__title">Отключить</span>
                                                                        </span>
                                                                    </template>

                                                                 </template>
                                                            </app-has-perm-action-btn>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <SubscribersSystemBlock :subscriber_connection="subscriber_connection"></SubscribersSystemBlock>
                                    </div>
                                    <div class="grid__col grid__col_right">
                                        <!--Действия-->
                                        <SubscribersActionsBlock
                                                :subscriber_connection="subscriber_connection"></SubscribersActionsBlock>
                                        <!--PPPoE-->
                                        <SubscribersPppoeBlock
                                                :subscriber_connection="subscriber_connection"></SubscribersPppoeBlock>
                                        <!--Адрес-->
                                        <SubscribersAddressBlock
                                                :subscriber_connection="subscriber_connection"></SubscribersAddressBlock>
                                        <!--Информация покдлючения-->
                                        <SubscribersInformationBlock
                                                :subscriber_connection="subscriber_connection"></SubscribersInformationBlock>
                                    </div>
                                </div>
                            </template>
                            <!--Редактирование-->
                            <template v-else>
                                <SubscribersConnectionEdit
                                        :subscriber_connection="subscriber_connection"
                                        v-on:set_mode="set_mode"
                                        v-on:update_subscriber_connection="update_subscriber_connection"></SubscribersConnectionEdit>
                            </template>
                        </div>
                        <!--Дополнительные услуги-->
                        <div class="tabs-sc__item" v-bind:class="{ 'is-active': isActive === 'service' }">
                            <div class="tabs-sc__header">
                                <div class="tabs-sc__title">
                                    <span class="tabs-sc__action-icon"><i class="fal fa-toolbox"></i></span>
                                    <span class="tabs-sc__action-title">Дополнительные услуги</span>
                                </div>
                            </div>
                            <ConnectionServices :subscriber_connection="subscriber_connection"
                                                v-on:update_subscriber_connection="update_subscriber_connection"></ConnectionServices>
                        </div>
                        <!--Операции со счетом-->
                        <div class="tabs-sc__item" v-bind:class="{ 'is-active': isActive === 'logactions' }">
                            <div class="tabs-sc__header">
                                <div class="tabs-sc__title">
                                    <span class="tabs-sc__action-icon"><i class="fal fa-exchange"></i></span>
                                    <span class="tabs-sc__action-title">Операции со счетом</span>
                                </div>
                            </div>
                            <ActionsScore :subscriber_connection="subscriber_connection"></ActionsScore>
                        </div>
                        <!--Активность-->
                        <div class="tabs-sc__item" v-bind:class="{ 'is-active': isActive === 'logpppoe' }">
                            <div class="tabs-sc__header">
                                <div class="tabs-sc__title">
                                    <span class="tabs-sc__action-icon"><i class="fal fa-power-off"></i></span>
                                    <span class="tabs-sc__action-title">Активность</span>
                                </div>
                            </div>
                            <ActivityHistory :subscriber_connection="subscriber_connection"></ActivityHistory>
                        </div>
                        <!--Платежи-->
                        <div class="tabs-sc__item" v-bind:class="{ 'is-active': isActive === 'logpay' }">
                            <div class="tabs-sc__header">
                                <div class="tabs-sc__title">
                                    <span class="tabs-sc__action-icon"><i class="fal fa-wallet"></i></span>
                                    <span class="tabs-sc__action-title">Платежи</span>
                                </div>
                            </div>
                            <PaymentHistory :subscriber_connection="subscriber_connection"></PaymentHistory>
                        </div>
                        <div class="tabs-sc__item" v-bind:class="{ 'is-active': isActive === 'comments' }">
                            <NoteList :subscriber_connection="subscriber_connection"></NoteList>
                        </div>
                    </div>
                </div>
                <!--END BODY PAGE-->



            </div>
        </div>
        <!-- Modal Transfer window -->
        <tariffs-transfer-popup :settings="transfer_popup"
                                :subscriber_connection="subscriber_connection"
                                v-on:update_tariff_transfer="update_tariff_transfer"
                                v-on:update_subscriber_connection="update_subscriber_connection"></tariffs-transfer-popup>
        <tariffs-change-popup :settings="change_popup"></tariffs-change-popup>
        <unicard-recharge-popup :settings="unicard_recharge_popup"
                                :subscriber_connection="subscriber_connection"
                                v-on:update_subscriber_connection="update_subscriber_connection">
        </unicard-recharge-popup>
        <balance-charge-popup :settings="balance_up_popup"
                              :subscriber_connection="subscriber_connection"
                              v-on:update_subscriber_connection="update_subscriber_connection">
        </balance-charge-popup>
<!--        BONUS BALANCE POPUPS-->
        <bonus-balance-charge-popup :settings="bonus_balance_up_popup"
                              :subscriber_connection="subscriber_connection"
                              v-on:update_subscriber_connection="update_subscriber_connection">
        </bonus-balance-charge-popup>
        <bonus-balance-reset-popup :settings="bonus_balance_reset_popup"
                                    v-on:update_subscriber_connection="update_subscriber_connection"
                                    :subscriber_connection="subscriber_connection">
        </bonus-balance-reset-popup>
<!--        END BONUS BALANCE POPUPS-->
        <balance-set-popup :settings="balance_set_popup"
                           :subscriber_connection="subscriber_connection"
                           v-on:update_subscriber_connection="update_subscriber_connection">
        </balance-set-popup>
        <balance-adjustment-popup :settings="balance_adjustment_popup"
                                  :subscriber_connection="subscriber_connection"
                                  v-on:update_subscriber_connection="update_subscriber_connection">
        </balance-adjustment-popup>
        <balance-adjustment-reset-popup :settings="balance_adjustment_reset_popup"
                                        v-on:update_subscriber_connection="update_subscriber_connection"
                                        :subscriber_connection="subscriber_connection">
        </balance-adjustment-reset-popup>
        <balance-credit-popup :settings="balance_credit_popup"
                              :subscriber_connection="subscriber_connection"
                              v-on:update_subscriber_connection="update_subscriber_connection">
        </balance-credit-popup>
        <balance-credit-reset-popup :settings="balance_credit_reset_popup"
                                    v-on:update_subscriber_connection="update_subscriber_connection"
                                    :subscriber_connection="subscriber_connection">
        </balance-credit-reset-popup>
        <balance-recalculation-popup :settings="balance_recalculation_popup"
                                     :subscriber_connection="subscriber_connection"
                                     v-on:update_subscriber_connection="update_subscriber_connection">
        </balance-recalculation-popup>
        <cancel-planing-popup :settings="cancel_planing_tariff_popup"
                              v-on:update_tariff_transfer="update_tariff_transfer"
                              v-on:update_subscriber_connection="update_subscriber_connection"
        ></cancel-planing-popup>
        <cancel-mass-planing-popup :settings="cancel_mass_planing_tariff_popup"></cancel-mass-planing-popup>
    </section>
</template>

<script>
    import {API} from "../../../../api";

    // Mixins
    import {SubscriberConnectionMixin} from "../../../../mixins/admin/subscriber_connection";
    import {CrudMixin} from "../../../../mixins/admin/crud/crud_base";
    import {CrudListMixin} from "../../../../mixins/admin/crud/crud_list";

    // Components
    import SubscribersConnectionView from './view.vue';
    import SubscribersConnectionEdit from './edit.vue';
    import SubscribersActionsBlock from './sc-information/action-block';
    import SubscribersPppoeBlock from './sc-information/pppoe-block';
    import SubscribersAddressBlock from './sc-information/address-block';
    import SubscribersInformationBlock from './sc-information/information-block';
    import SubscribersSystemBlock from './sc-information/system-block';
    import NoteList from '../note/list';
    import PaymentHistory from './payment-history.vue';
    import ActionsScore from './actions-score.vue';
    import ActivityHistory from './activity-history.vue';
    import TariffsTransferPopup from '../tariffs/transfer-tariffs.vue';
    import TariffsChangePopup from './change-tariffs.vue';
    import BalanceChargePopup from "./modal/balance-charge";
    import BonusBalanceChargePopup from "./modal/bonus-balance-manual-charge";
    import BonusBalanceResetPopup from "./modal/bonus-balance-manual-reset";
    import UnicardRechargePopup from "./modal/unicard-recharge.vue";
    import BalanceSetPopup from "./modal/balance-set";
    import BalanceAdjustmentPopup from "./modal/balance-adjustment";
    import BalanceAdjustmentResetPopup from "./modal/balance-adjustment-reset";
    import BalanceCreditPopup from "./modal/balance-credit";
    import BalanceCreditResetPopup from "./modal/balance-credit-reset";
    import BalanceRecalculationPopup from "./modal/balance-recalculation";
    import CancelPlaningTariffPopup from "./cancel-planing-tariff";
    import CancelMassPlaningTariffPopup from "./cancel-mass-planing-tariff";
    import {TariffMixin} from "../../../../mixins/admin/tariff";
    import ConnectionServices from './sc_services/services';
    import {SubscriberMixin} from "../../../../mixins/admin/subscriber";
    import SubscriberName from "./sub_components/subscriber_name";
    import {SearchMixin} from "../../../../mixins/admin/search";
    import NotificationTransferTariff from "./sc_notification/transfer-tariff";

    import NotificationTrialPeriod from "./sc_notification/trial-period";
    import NotificationPromotionalTariff from "./sc_notification/promotional-tariff";
    import FormErrors from "../form/errors";
    import {pluralize} from "../../../../helpers/pluralize";
    import {SubscriberConnections} from "../../../../mixins/front/subscriber_connections";
    import {ServiceTrustDayMixin} from "../../../../mixins/admin/services_trust_day";

    export default {
        name: "subscribers-connection-item",
        data: function () {
            return {
                id: "",
                mode: "view",
                isActive: 'data',
                transfer_popup: {
                    visible: false
                },
                unicard_recharge_popup: {
                    visible: false
                },
                balance_up_popup: {
                    visible: false
                },
                bonus_balance_up_popup: {
                    visible: false
                },
                bonus_balance_reset_popup: {
                    visible: false
                },
                balance_set_popup: {
                    visible: false
                },
                balance_adjustment_popup: {
                    visible: false
                },
                balance_adjustment_reset_popup: {
                    visible: false
                },
                balance_credit_popup: {
                    visible: false
                },
                balance_credit_reset_popup: {
                    visible: false
                },
                balance_recalculation_popup: {
                    visible: false
                },
                cancel_planing_tariff_popup: {
                    tariff_transfer: {},
                    visible: false
                },
                cancel_mass_planing_tariff_popup: {
                    visible: false
                },
                isCashback: false,
                isBalance: false,
                subscriber_connection: {},
                temporarily_disabled: false,
            }
        },
        mixins: [SubscriberConnectionMixin, ServiceTrustDayMixin, SubscriberConnections, SubscriberMixin, TariffMixin, CrudMixin, CrudListMixin, SearchMixin],
        created() {
            this.id = this.$route.params.id;
            this.$root.$on('search_hit_subscriber-connections', (function (data) {
                this.load();
            }).bind(this));

            this.$root.$on('search_clear_subscriber-connections', (function (data) {
                this.load();
            }).bind(this));
        },
        beforeDestroy: function () {
            this.destroy_search_listeners("subscriber-connections");
        },
        mounted: function () {
            this.load();
        },
        components: {
            SubscribersConnectionView,
            SubscribersConnectionEdit,
            ConnectionServices,
            SubscribersActionsBlock,
            SubscribersPppoeBlock,
            SubscribersAddressBlock,
            SubscribersInformationBlock,
            SubscribersSystemBlock,
            "form-error": FormErrors,
            'tariffs-transfer-popup': TariffsTransferPopup,
            'tariffs-change-popup': TariffsChangePopup,
            'unicard-recharge-popup': UnicardRechargePopup,
            'balance-charge-popup': BalanceChargePopup,
            'bonus-balance-charge-popup': BonusBalanceChargePopup,
            'bonus-balance-reset-popup': BonusBalanceResetPopup,
            'balance-set-popup': BalanceSetPopup,
            'balance-adjustment-popup': BalanceAdjustmentPopup,
            'balance-adjustment-reset-popup': BalanceAdjustmentResetPopup,
            'balance-credit-popup': BalanceCreditPopup,
            'balance-credit-reset-popup': BalanceCreditResetPopup,
            'balance-recalculation-popup': BalanceRecalculationPopup,
            'cancel-planing-popup': CancelPlaningTariffPopup,
            'cancel-mass-planing-popup': CancelMassPlaningTariffPopup,
            'subscriber-name': SubscriberName,
            NotificationTransferTariff,
            NoteList,
            PaymentHistory,
            ActionsScore,
            ActivityHistory,
            NotificationTrialPeriod,
            NotificationPromotionalTariff
        },
        methods: {
            load: function () {
                this.id = this.$route.params.id;
                this.load_subscriber_connection(this.id);
                this.load_tariff_transfer({subscriber_connection: this.id, status: "scheduled"});
            },
            change_cls_list: function (value) {
                var self = this;
                var data = {
                    cls_list: value
                };

                this.edit_subscriber_connection(this.subscriber_connection.id, data)
                    .then(function (response) {
                        self.subscriber_connection = response.data;
                    })
                    .catch(function (error) {
                        API.default_catch(self, error);
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            },
            temporarily_disabled_login: function (value) {
                var self = this;
                var data = {
                    temporarily_disabled: value
                }
                API.post('/subscriber-connections/' + this.subscriber_connection.id + '/temporarily-disabled-login/', data)
                    .then(function (response) {
                        self.subscriber_connection = response.data;
                    })
                    .catch(function (error) {
                        API.default_catch(self, error);
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            },
            recharge_balance_by_bonus: function (value) {
                var self = this;
                API.post('/subscriber-connections/' + this.subscriber_connection.id + '/rechange-balance-by-bonus/')
                    .then(function (response) {
                        self.subscriber_connection = response.data;
                    })
                    .catch(function (error) {
                        API.default_catch(self, error);
                        if('non_field_errors' in self.errors) {
                            self.alert("Внимание", self.errors['non_field_errors'][0]);
                        }
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            },
            cls_list_css_class: function (obj) {
                var css_class = '';
                if (obj.cls_list === 'CLS_AUTH') {
                    css_class = 'status-online'
                } else if (obj.cls_list === 'CLS_DENY') {
                    css_class = 'status-blocked'
                }

                return css_class
            },
            temporarily_disabled_css_class: function (obj) {
                var css_class = '';
                if (obj === false) {
                    css_class = 'status-online'
                } else {
                    css_class = 'status-blocked'
                }

                return css_class
            },
            get_balance_days(){
                var given = this.$moment(this.subscriber_connection.balance_positive_till_date, "DD.MM.YYYY");
                var current = this.$moment();
                var days = given.diff(current, 'days');

                return days + " " + pluralize(days, ['день', 'дня', 'дней'])
            },
            left_until_withdrawal() {
                var amount = 100 - this.subscriber_connection.bonus_balance;
                if (amount <= 0) {
                    amount = 0
                }
                return amount
            },
            popup_transfer() {
                this.transfer_popup.visible = true;
                this.crud.errors = {};
            },
            set_mode: function (mode) {
                // Edit or View mode
                this.mode = mode;
            },
            update_subscriber_connection: function () {
                this.load_subscriber_connection(this.id);
            },
            update_tariff_transfer: function () {
                this.load_tariff_transfer({subscriber_connection: this.id, status: "scheduled"});
            },
            popup_unicard_recharge() {
                this.unicard_recharge_popup.visible = true;
                this.crud.errors = {};
            },
            popup_balance_up() {
                this.balance_up_popup.visible = true;
                this.crud.errors = {};
            },
            popup_bonus_balance_up() {
                this.bonus_balance_up_popup.visible = true;
                this.crud.errors = {};
            },
            popup_bonus_balance_reset() {
                this.bonus_balance_reset_popup.visible = true;
                this.crud.errors = {};
            },
            popup_balance_set() {
                this.balance_set_popup.visible = true;
                this.crud.errors = {};
            },
            popup_balance_adjustment() {
                this.balance_adjustment_popup.visible = true;
                this.crud.errors = {};
            },
            popup_balance_adjustment_reset() {
                this.balance_adjustment_reset_popup.visible = true;
                this.crud.errors = {};
            },
            popup_balance_credit() {
                this.balance_credit_popup.visible = true;
                this.crud.errors = {};
            },
            popup_balance_credit_reset() {
                this.balance_credit_reset_popup.visible = true;
                this.crud.errors = {};
            },
            popup_recalculation() {
                this.balance_recalculation_popup.visible = true;
                this.crud.errors = {};
            },
            popup_cancel_planing_tariff(tariff_transfer) {
                this.cancel_planing_tariff_popup.tariff_transfer = tariff_transfer;
                this.cancel_planing_tariff_popup.visible = true;
                this.crud.errors = {};
            },
            popup_cancel_mass_planing_tariff() {
                this.cancel_mass_planing_tariff_popup.visible = true;
                this.crud.errors = {};
            },
            isShowBlock() {
                this.isCashback = !this.isCashback;
                this.isBalance = !this.isBalance;
            },
            isPrivateUser() {
                if (this.subscriber_connection.subscriber_expand.subscriber_type_expand.name === 'private'){
                    return true
                }
                return false
            },
        },
        watch: {
            subscriber_connection: function (value) {
                this.isConnectedTrustDay(this.subscriber_connection);

                var extra_data = this.subscriber_connection.subscriber_connection_extra_data;
                if (extra_data) {
                    if (extra_data.temporarily_disabled) {
                        this.temporarily_disabled = true;
                    } else {
                        this.temporarily_disabled = false;
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>