<template>
    <span>
        <template v-if="unicard.unicard_batch">
            <template v-if="unicard.unicard_set_expand && unicard.unicard_batch_expand.owner_expand">
                {{ unicard.unicard_batch_expand.owner_expand.fio }}
            </template>
        </template>
        <template v-else>-</template>
    </span>
</template>

<script>
    export default {
        name: "field_owner_unicard_batch",
        props: ['unicard']
    }
</script>

<style scoped>

</style>