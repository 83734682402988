import {API} from "../../api";

export var ConnectionTypeMixin = {
    data: function () {
        return {
            connection_types: [],
        }
    },
    methods: {
        load_connection_type: function () {
            var self = this;
            API.get('/connection-type/')
                .then(function (response) {
                    self.connection_types = response.data.results;
                })
                .catch(function (error) {
                    // API.default_catch(self, error)
                })
                .then(function () {
                    // API.default_always(self);
                })
        }
    }
};
