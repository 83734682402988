import {API} from "../../api";

export const UnicardBatchParamsLoaderMixin = {
    methods: {
        loadUnicardBatchSeries() {
            var self = this;

            API.get('/unicards/batch/unicard-batch-series/')
                .then(function (response) {
                    self.series_list = response.data;
                })
                .catch(function (error) {
                    API.default_catch(self, error);
                    if('non_field_errors' in self.errors) {
                        self.alert("Внимание", self.errors['non_field_errors'][0]);
                    }
                })
        },
        loadUnicardBatchValues() {
            var self = this;

            API.get('/unicards/batch/unicard-batch-values/')
                .then(function (response) {
                    self.values_list = response.data;
                })
                .catch(function (error) {
                    API.default_catch(self, error);
                    if('non_field_errors' in self.errors) {
                        self.alert("Внимание", self.errors['non_field_errors'][0]);
                    }
                })
        },
        loadUnicardBatchOwners() {
            var self = this;

            API.get('/unicards/batch/unicard-batch-owners/')
                .then(function (response) {
                    self.owners_list = response.data;
                })
                .catch(function (error) {
                    API.default_catch(self, error);
                    if('non_field_errors' in self.errors) {
                        self.alert("Внимание", self.errors['non_field_errors'][0]);
                    }
                })
        },
    }
}