<template>
    <section class="page">
        <div class="page__inner">
            <div class="container-width">
                <div class="row">
                    <!-- HEADING PAGE -->
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="page-header">
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                                    <h1 class="page-header__title"> Добавление абонента</h1>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                    <div class="page-header__btn-group">
                                        <router-link title="Добавить группу" :to="{ name: 'subscribers-list'}">
                                            <span class="btn grey"><i class="fal fa-undo"></i> <span class="title">Вернуться</span></span>
                                        </router-link>
                                        <span class="btn blue" @click="save"><i class="fal fa-plus"></i> <span
                                                class="title">Добавить</span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END HEADING PAGE -->
                    <!-- CONTENT PAGE -->
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="page-body">
                            <form action="" class="add-data">
                                <div class="row">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                        <div class="add-data__header">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                    <h3><i class="fal fa-address-card"></i> Персональные данные</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="add-data__item">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                                                    <label><i class="fal fa-users-crown"></i> Тип абонента: <span
                                                            class="control"><i
                                                            class="fas fa-star-of-life"></i></span></label>
                                                </div>
                                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                                                    <div class="selected">
                                                        <select v-model="subscriber.subscriber_type" required>
                                                            <option value="" disabled hidden>Выберите тип</option>
                                                            <option v-for="subscriber_type in subscriber_types"
                                                                    v-bind:value="subscriber_type.id">
                                                                {{ subscriber_type.name_expand }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="clearfix"></div>
                                                <form-error v-bind:value="errors.subscriber_type"></form-error>
                                            </div>
                                        </div>
                                        <div v-show="is_subscriber_private()">

                                            <div class="add-data__item">
                                                <div class="row">
                                                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                                                        <label><i class="fal fa-user-crown"></i> ФИО: <span class="control"><i
                                                                class="fas fa-star-of-life"></i></span></label>
                                                    </div>
                                                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                                                        <input type="text" v-model="subscriber.fio"
                                                               placeholder="Фамилия имя отчество">
                                                    </div>
                                                    <div class="clearfix"></div>
                                                    <form-error v-bind:value="errors.fio"></form-error>
                                                </div>
                                            </div>
                                            <phone-list
                                                    :phone="subscriber.phone"
                                                    :phone_1="subscriber.phone_1"
                                                    :phone_2="subscriber.phone_2"
                                                    :phone_3="subscriber.phone_3"
                                                    :phone_4="subscriber.phone_4"
                                                    :phone_5="subscriber.phone_5"
                                                    :errors="errors"
                                                    @set_phone_single="set_phone_single"
                                                    @set_phones_list="set_phones_list">
                                            </phone-list>
                                            <div class="add-data__item">
                                                <div class="row">
                                                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                                                        <label><i class="fal fa-at"></i> E-mail:</label>
                                                    </div>
                                                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                                                        <input type="text" v-model="subscriber.subscriber_email"
                                                               placeholder="Введите e-mail">
                                                    </div>
                                                    <div class="clearfix"></div>
                                                    <form-error v-bind:value="errors.subscriber_email"></form-error>
                                                </div>
                                            </div>
                                        </div>

                                        <business-fields v-show="is_subscriber_business()"
                                                         :subscriber="subscriber"
                                                         :errors="errors"
                                                         @update_subscriber="update_subscriber"
                                        >
                                        </business-fields>

                                    </div>

                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">

                                        <private-passport-data v-show="is_subscriber_private()"
                                                               :subscriber="subscriber"
                                                               :errors="errors"
                                                               @update_subscriber="update_subscriber"
                                        >
                                        </private-passport-data>
                                    </div>

                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div class="add-data__footer">
                                            <span class="btn blue" @click="save"><i
                                                    class="fal fa-plus"></i> Добавить</span>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import FormErrors from '../form/errors.vue';
    import {SubscriberMixin} from "../../../../mixins/admin/subscriber";
    import {API} from "../../../../api";
    import {SubscriberTypeMixin} from "../../../../mixins/admin/subscriber_type";
    import BusinessFields from "./sub_components/business_fields";
    import PrivatePassportData from "./sub_components/private_passport_data";
    import PhoneList from '../phones/list';

    export default {
        name: "subscribers-add",
        created: function () {
            this.load_subscriber_types();
        },
        mixins: [
            SubscriberMixin,
            SubscriberTypeMixin
        ],
        components: {
            PrivatePassportData,
            BusinessFields,
            "form-error": FormErrors,
            "phone-list": PhoneList
        },
        methods: {
            save: function () {
                var self = this;

                // Add Subscriber Connection
                this.add_subscriber(this.subscriber)
                    .then(function (response) {
                        self.$router.push({name: 'subscribers-item', params: {"id": response.data.id}})
                    })
                    .catch(function (error) {
                        self.subscriber_catch(self, error);
                    })
                    .then(function () {
                        API.default_always(self);
                    });
            },
            update_subscriber: function (value) {
                // this.$set(this, 'subscriber', Object.assign({}, this.subscriber, value));
                // this.subscriber = Object.assign({}, this.subscriber, value);
                this.subscriber = value;
            },
            show_subscribers: function () {
                this.$router.push({name: "subscribers"})
            },
            back: function () {
                this.show_subscribers();
            },
            set_phone_single: function (value) {
                this.subscriber.phone = value;
            },
            set_phones_list: function (phones) {
                for(var i=0;i <5; i++){
                    if((phones[i] !== "") && (phones[i] !== undefined)){
                        this.subscriber['phone_' + (i+1)] = phones[i];
                    } else {
                        this.subscriber['phone_' + (i+1)] = "";
                    }
                }
            }
        },
    }
</script>

<style scoped>

</style>