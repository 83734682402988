<template>
    <div class="grid__box" v-has-perm="'subscribers_access'">
        <div class="view">
            <div class="view__row">
                <div class="view__section">
                    <div class="view__header">
                        <div class="view__header-title">
                            <span class="view__header-icon"><i class="fal fa-file-alt"></i></span>
                            <span class="view__header-text">Информация подключения</span>
                        </div>
                    </div>
                    <!--Монтажник-->
                    <div class="view__item">
                        <div class="view__label">
                            <span class="view__label-icon"><i class="fal fa-wrench"></i></span>
                            <span class="view__label-title">Монтажник:</span>
                        </div>
                        <div class="view__value">
                            <span class="view__value-text">
                                <template
                                        v-if="Object.keys(subscriber_connection).length && subscriber_connection.employee_mounter_expand">
                                {{ subscriber_connection.employee_mounter_expand.fio }}
                                </template>
                                <template v-else>
                                    Не указано
                                </template>
                            </span>
                        </div>
                    </div>
                    <!--Менеджер-->
                    <div class="view__item">
                        <div class="view__label">
                            <span class="view__label-icon"><i class="fal fa-user-headset"></i></span>
                            <span class="view__label-title">Менеджер:</span>
                        </div>
                        <div class="view__value">
                            <span class="view__value-text">
                                <template v-if="subscriber_connection.employee_creator_expand">
                                    {{ subscriber_connection.employee_creator_expand.fio }}
                                </template>
                                <template v-else>
                                    Не указано
                                </template>
                            </span>
                        </div>
                    </div>
                    <!--Дата создания-->
                    <div class="view__item">
                        <div class="view__label">
                            <span class="view__label-icon"><i class="fal fa-calendar-alt"></i></span>
                            <span class="view__label-title">Дата создания:</span>
                        </div>
                        <div class="view__value">
                            <span class="view__value-text">
                                <template
                                    v-if="Object.keys(subscriber_connection).length && subscriber_connection.employee_mounter_expand">
                                    {{ subscriber_connection.created_at | moment("DD.MM.YYYY HH:mm") }}
                                </template>
                                <template v-else>
                                     Не указано
                                </template>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "information-block",
        props: ['subscriber_connection'],
    }
</script>

<style scoped>

</style>