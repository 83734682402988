<template>
    <td class="table-box__td">
        <span class="table-data__text">
            <slot name="data-text"></slot>
        </span>
    </td>
</template>

<script>
    export default {
    name: "table-td"
    }
</script>

<style scoped>

</style>