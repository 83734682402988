<template>
    <aside class="sitebar" :class="{ slideActive }">
        <template v-if="user.id">
            <div class="logo">
                <div class="toggle" @click="slideActive = !slideActive"></div>
            </div>
            <nav class="navigation">
                <ul>
                    <li>
                        <router-link v-tooltip.left="TooltipText='Панель управления'" :to="{ name: 'dashboard-page'}">
                            Dashboard
                            <i class="fal fa-tachometer-alt"></i>
                        </router-link>
                    </li>
                    <li v-has-perm="perm['subscribers']">
                        <router-link v-tooltip.left="TooltipText='Абоненты'" :to="{ name: 'subscribers-list'}">
                            Абоненты
                            <i class="fal fa-users"></i>
                        </router-link>
                    </li>
                    <li v-has-perm="perm['services']">
                        <router-link v-tooltip.left="TooltipText='Услуги'" :to="{ name: 'services-list'}">
                            Услуги<i class="fal fa-toolbox"></i>
                        </router-link>
                    </li>
                    <li v-has-perm="perm['catalogs']">
                        <router-link v-tooltip.left="TooltipText='Справочники'" :to="{ name: 'catalogs-all'}">
                            Справочники<i class="fal fa-books"></i>
                        </router-link>
                    </li>
                    <li v-has-perm="perm['reports']">
                        <router-link v-tooltip.left="TooltipText='Отчеты'" :to="{ name: 'reports-page'}">
                            Отчеты<i class="fal fa-analytics"></i>
                        </router-link>
                    </li>
                    <li v-has-perm="perm['unicards']">
                        <router-link v-tooltip.left="TooltipText='Unicards'" :to="get_unicards_link()">
                            Unicards<i class="fal fa-credit-card-front"></i>
                        </router-link>
                    </li>
                    <li>
                        <router-link v-tooltip.left="TooltipText='Настройки'" title="Настройки" :to="{ name: 'settings-page'}">
                            Настройки<i class="fal fa-cogs"></i>
                        </router-link>
                    </li>
                </ul>
            </nav>

        </template>
    </aside>
</template>

<script>
    // import {User} from "../../../mixins/admin/user";

    export default {
        // mixins: [User],
        // props: ['user'],
        data: function () {
            return {
                slideActive: false,
                perm: {
                    'subscribers': {
                        permissions: 'subscribers_access,' +
                            'subscriber_passport_details_view,' +
                            'subscriber_business_details_view,' +
                            'subscribers_add_edit,',
                        comparison: 'OR'
                    },
                    'groups': {
                        permissions: 'catalog_subscriber_groups_access',
                        comparison: 'OR'
                    },
                    'tariffs': {
                        permissions: 'catalog_tariffs_access',
                        comparison: 'OR'
                    },
                    'nas': {
                        permissions: 'catalog_nas_access',
                        comparison: 'OR'
                    },
                    'employees': {
                        permissions: 'catalog_employees_access',
                        comparison: 'OR'
                    },
                    'reports': {
                        permissions: 'reports_access',
                        comparison: 'AND'
                    },
                    'unicards': {
                        permissions: 'unicards_info_access,unicards_user_access,unicards_super_access',
                        comparison: 'OR'
                    },
                    'catalogs': {
                        permissions: 'catalog_employee_positions_access,catalog_departments_access,catalog_settlements_access,catalog_connection_types_access,catalog_tariffs_access,catalog_subscriber_groups_access,catalog_ip_address_access,catalog_mac_address_access,catalog_nas_access,catalog_employees_access,',
                        comparison: 'OR'
                    },
                    'services': {
                        permissions: 'catalog_additional_services_personal_access,catalog_additional_services_service_access',
                        comparison: 'OR'
                    },
                }
            }
        },
        methods: {
            fetchUser() {
                this.$forceUpdate();
            },
            get_unicards_link() {
                if (this.has_perm('unicards_super_access')) {
                    return {name: 'unicards-list'};

                } else if(this.has_perm('unicards_user_access')){
                    return {name: 'unicards-consignment-main'}

                } else if(this.has_perm('unicards_info_access')){
                    return {name: 'unicards-list'}

                } else {
                    return {name: 'unicards-consignment-main'}

                }
            },
        },
        watch: {
            user: {
                handler(value) {
                    /* User loaded */
                    this.fetchUser();
                },
                deep: true
            },
            '$route'(route_to, route_from) {
                this.slideActive = false;
            }
        },
    }
</script>

<style scoped>

</style>