<template>
    <section class="page">
        <div class="page__inner">
            <div class="container-width">
                <!--HEADER PAGE-->
                <div class="page__header">
                    <h1 class="page__title">Населенные пункты</h1>
                    <div class="btn-group">
                        <router-link title="Архив"  v-has-perm="'archive_view'" :to="{ name: 'login'}">
                        <span class="btn btn_grey">
                            <span class="btn__icon"><i class="fal fa-archive"></i></span>
                            <span class="btn__title">Архив</span>
                        </span>
                        </router-link>
                        <!--ADD REGION-->
                        <app-has-perm-action-btn :perms="'catalog_settlements_add_edit'"
                                                  @action="popup_add">
                            <template slot-scope="{ access }">
                                <span class="btn" :class="access ? 'btn_green' : 'btn_grey'">
                                    <span class="btn__icon"><i class="fal fa-plus"></i></span>
                                    <span class="btn__title">Добавить регион</span>
                                </span>
                            </template>
                        </app-has-perm-action-btn>
                        <!--END ADD REGION-->
                    </div>
                </div>
                <!--END HEADER PAGE-->
                <!--BODY PAGE-->
                <div class="page__body">
                    <div class="table-box">
                        <table class="table-box__table">
                            <thead class="table-box__thead">
                            <tr class="table-box__thead-tr">
                                <th class="table-box__th">
                                    <span class="table-box__thead-item">
                                        <span class="table-box__thead-icon"><i class="fal fa-globe-europe"></i></span>
                                        <span class="table-box__thead-title">Регионы</span>
                                    </span>
                                </th>
                                <th class="table-box__th">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-map-marked-alt"></i></span>
                                        <span class="table-box__thead-title">Районы</span>
                                    </span>
                                </th>
                                <th class="table-box__th">
                                     <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-map-marker-alt"></i></span>
                                        <span class="table-box__thead-title">Населенные пункты</span>
                                    </span>
                                </th>
                                <th class="table-box__th">
                                     <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-check-circle"></i></span>
                                        <span class="table-box__thead-title">Статус</span>
                                    </span>
                                </th>
                                <th class="table-box__th">
                                     <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-mouse-pointer"></i></span>
                                        <span class="table-box__thead-title">Действия</span>
                                    </span>
                                </th>
                            </tr>
                            </thead>
                            <tbody class="table-box__tbody">
                            <tr class="table-box__tr table-data" v-for="(item, index) in crud.data.list">
                                <!--Регионы-->
                                <td class="table-box__td">
                                    <span class="table-data__text">{{ item.name }}</span>
                                </td>
                                <!--Кол-во районов-->
                                <td class="table-box__td table-box__td_center">
                                    <span class="table-data__text table-data__text_bold">{{ item.count_districts }}</span>
                                </td>
                                <!--Кол-во городов-->
                                <td class="table-box__td table-box__td_center">
                                    <span class="table-data__text table-data__text_bold">{{ item.count_settlements }}</span>
                                </td>
                                 <!--Статус-->
                                <td class="table-box__td table-box__td_center">
                                    <span class="table-data__status table-data__status_active" v-if="item.is_active">Активен</span>
                                    <span class="table-data__status table-data__status_deactive" v-else >Не активен</span>
                                </td>
                                <!--Действия-->
                                <td class="table-box__td table-box__td_right">
                                    <div class="btn-group">
                                        <span v-tooltip="TooltipText='Карта'" class="btn-l btn-l_blue" @click="popup_view(item)">
                                            <span class="btn-l__icon"><i class="fal fa-map"></i></span>
                                        </span>
                                        <!--EDIT REGION-->
                                        <app-has-perm-action-btn :perms="'catalog_settlements_add_edit'"
                                                                 @action="popup_edit(index, item)">
                                            <template slot-scope="{ access }">
                                                <span class="btn-l" :class="access ? 'btn-l_orange' : 'btn-l_grey'" v-tooltip="TooltipText='Редактировать'">
                                                    <span class="btn-l__icon"><i class="fal fa-edit"></i></span>
                                                </span>
                                            </template>
                                        </app-has-perm-action-btn>
                                        <!--END EDIT REGION-->
                                        <!--DELETE REGION-->
                                        <app-remove-btn :perms="'catalog_settlements_delete'" @action="popup_delete"></app-remove-btn>
                                        <!--END DELETE REGION-->
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!--END BODY PAGE-->
            </div>
        </div>
        <!--MODAL ADD REGION-->
        <modal-add-edit-region :settings="add_edit_popup" :region="crud.data.item" @update_list="fetchUser" ></modal-add-edit-region>
        <modal-geography-region :settings="view_popup" :region="crud.data.item" @update_list="fetchUser" ></modal-geography-region>
    </section>
</template>
<script>
    import {CrudMixin} from "../../../../mixins/admin/crud/crud_base";
    import {CrudListMixin} from "../../../../mixins/admin/crud/crud_list";

    import ModalAddEditRegion from "./modal/add-edit-region";
    import ModalGeographyRegion from "./modal/geography-region";

    export default {
        name: "addresses",
        mixins: [CrudMixin, CrudListMixin],
        components: {
            "modal-add-edit-region": ModalAddEditRegion,
            "modal-geography-region": ModalGeographyRegion,
        },
        data: function () {
            return {
                id: "",
                crud: {
                    api: '/address/region/',
                },
                add_edit_popup: {
                    visible: false
                },
            }
        },
        methods: {
            fetchUser() {
                // if (this.has_perm('common_view_catalogs')) {
                //     var params = this.$route.query;
                //     this.crud_get_list(params);
                // }
                var params = this.$route.query;
                this.crud_get_list(params);
            },
            popup_add() {
                this.crud.data.item = {name: ""};

                this.add_edit_popup.visible = true;
                this.crud.errors = {};
            },
            popup_edit(index, item) {
                this.crud.data.item = Object.assign({}, item);
                this.crud.errors = {};

                this.add_edit_popup.visible = true;
            },
        },
        mounted() {
            this.fetchUser()
        }
    }
</script>

<style scoped>

</style>