<template>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="view-data__header">
            <h3><i class="fal fa-address-card"></i> Доступ к отделам </h3>
            <div class="access-department">
                <ul class="access-department__list">
                    <li class="access-department__item"
                        v-for="department in departments">
                        <template v-if="readonly">
                            <span v-bind:class="[check_department(department) ? 'access-rights__enable' : 'access-rights__disable']">
                                <i class="far" v-bind:class="[check_department(department) ? 'fa-check' : 'fa-minus']"></i>
                            </span>
                        </template>
                        <template v-else>
                            <label class="checkbox">
                                <input type="checkbox"
                                       v-model="department_permissions_checked"
                                       v-bind:value="department.id">
                                <span class="checkmark"></span>
                            <span class="checkbox__title">{{ department.name }}</span>
                            </label>
                        </template>

                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import {DepartmentMixin} from "../../../../mixins/admin/department";

    export default {
        name: "access-department",
        props: ['department_permissions', 'readonly'],
        mixins: [DepartmentMixin],
        data: function () {
            return {
                department_permissions_checked: [],
            }
        },
        methods: {
            check_department: function (department) {
                var count = this.department_permissions_checked.length
                if (count === 0) {
                    return false;
                } else {
                    for (var i = 0; i < count; i++) {
                        if (this.department_permissions_checked[i] === department.id) {
                            return true;
                        }
                    }
                    return false;
                }
            }
        },
        created: function () {
            this.load_departments();
        },
        watch: {
            department_permissions_checked(value) {
                this.$emit("set_department_permissions_checked", value);
            },
            department_permissions(value) {
                if (value !== undefined) {
                    for (var i = 0; i < Object.keys(value).length; i++) {
                        this.department_permissions_checked.push(value[i].id);
                    }
                }
            }
        },
    }
</script>

<style scoped>

</style>