import {API} from "../../api";

export var TariffChargingTypeMixin = {
    data: function () {
        return {
            tariffs_charging_types: [],
            tariffs_charging_type: {},
        }
    },
    methods: {
        get_charging_type: function (id) {
            var self = this;
            API.get('/tariffs-charging-types/'+id+'/')
                .then(function (response) {
                    self.tariffs_charging_type = response.data;
                })
                .catch(function (error) {
                    // API.default_catch(self, error)
                })
                .then(function () {
                    // API.default_always(self);
                })
        },
        load_charging_types: function () {
            var self = this;
            API.get('/tariffs-charging-types/')
                .then(function (response) {
                    self.tariffs_charging_types = response.data;
                })
                .catch(function (error) {
                    // API.default_catch(self, error)
                })
                .then(function () {
                    // API.default_always(self);
                })
        }
    }
};
