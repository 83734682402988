import {API} from "../../api";
import {loader} from "../../models/loader";

export var TariffMixin = {
    data: function () {
        return {
            tariff_transfers: [],
            // tariff_transfer: {},
            tariffs: [],
            tariff: {},
        }
    },
    methods: {
        get_tariffs: function (filters) {
            var self = this;
            loader.active = false;
            API.get('/tariffs/', filters)
                .then(function (response) {
                    self.tariffs = response.data.results;
                })
                .catch(function (error) {
                    // API.default_catch(self, error)
                })
                .then(function () {
                    loader.active = true;
                    // API.default_always(self);
                })
        },
        get_available_tariffs: function (data) {
            var self = this;
            loader.active = false;
            API.get('/subscriber-connection-available-tariffs/', data)
                .then(function (response) {
                    self.tariffs = response.data;
                })
                .catch(function (error) {
                    // API.default_catch(self, error)
                })
                .then(function () {
                    loader.active = true;
                    // API.default_always(self);
                })
        },
        get_available_tariffs_by_id: function (subscriber_connection_id, params={}) {
            var self = this;
            loader.active = false;
            API.get('/subscriber-connections/'+subscriber_connection_id+'/available-tariffs/', params)
                .then(function (response) {
                    self.tariffs = response.data;
                })
                .catch(function (error) {
                    // API.default_catch(self, error)
                })
                .then(function () {
                    loader.active = true;
                    // API.default_always(self);
                })
        },
        get_available_tariffs_for_transfer: function (tariff_id, params={}) {
            var self = this;
            loader.active = false;
            API.get('/tariffs/'+tariff_id+'/available-tariffs-for-transfer/', params)
                .then(function (response) {
                    self.tariffs = response.data;
                })
                .catch(function (error) {
                    // API.default_catch(self, error)
                })
                .then(function () {
                    loader.active = true;
                    // API.default_always(self);
                })
        },
        get_tariff: function (id) {
            var self = this;
            API.get('/tariffs/'+id+'/')
                .then(function (response) {
                    self.tariff = response.data;
                })
                .catch(function (error) {
                    // API.default_catch(self, error)
                })
                .then(function () {
                    // API.default_always(self);
                })
        },
        load_tariff(subscriber_connection) {
            var subscriber_type = "";
            subscriber_connection = subscriber_connection || this.subscriber_connection;

            if(subscriber_connection.subscriber_expand) {
                subscriber_type = subscriber_connection.subscriber_expand.subscriber_type;
            } else if(this.subscriber && this.subscriber.subscriber_type) {
                subscriber_type = this.subscriber.subscriber_type;
            }

            // console.log(subscriber_connection.department,  " | ",
            //     subscriber_connection.group,  " | ",
            //     subscriber_connection.connection_type,  " | ",
            //     subscriber_connection.settlement,  " | ",
            //     subscriber_type)

            if (subscriber_connection.department &&
                subscriber_connection.group &&
                subscriber_connection.connection_type &&
                subscriber_connection.settlement &&
                subscriber_type) {

                var filter = {
                    department: subscriber_connection.department,
                    group: subscriber_connection.group,
                    connection_type: subscriber_connection.connection_type,
                    subscriber_type: subscriber_type,
                    settlement: subscriber_connection.settlement
                };
                this.get_available_tariffs(filter);
                this.$delete(this.errors, 'tariff');
            } else {
                this.$set(this.errors,
                    "tariff",
                    ["Для выбора тарифа нужно указать 'Отдел', 'Группу', 'Тип подключения' и 'Населенный пункт'"])
            }
        },
        tariffs_calc_day_by_month: function (charging_amount_month) {
            return API.post('/tariffs-calc-day-by-month/', {charging_amount_month: charging_amount_month})
        },
        load_tariff_transfer: function (filters) {
            var self = this;
            loader.active = false;
            API.get('/subscriber-connections-tariff-transfer/', filters)
                .then(function (response) {
                    self.tariff_transfers = response.data.results;
                })
                .catch(function (error) {
                    // API.default_catch(self, error)
                })
                .then(function () {
                    loader.active = true;
                    // API.default_always(self);
                })
        },
        // get_tariff_transfer: function (id, filters) {
        //     var self = this;
        //     loader.active = false;
        //     API.get('/subscriber-connections-tariff-transfer/'+id+'/', filters)
        //         .then(function (response) {
        //             self.tariff_transfer = response.data.results;
        //         })
        //         .catch(function (error) {
        //             // API.default_catch(self, error)
        //         })
        //         .then(function () {
        //             loader.active = true;
        //             // API.default_always(self);
        //         })
        // },
        cancel_tariff_transfer: function (id) {
            var self = this;
            loader.active = false;
            return API.delete('/subscriber-connections-tariff-transfer/'+id+'/')
        },
    }
};
