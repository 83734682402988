<template>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="information-block orange">
            <div class="information-block__icon orange">
                <i class="fal fa-engine-warning"></i>
            </div>
            <div class="information-block__title orange">
                Запланирована смена тарифа
                <strong>{{ tariff_transfer.transfer_date| moment("DD.MM.YYYY") }}</strong>
            </div>
            <div class="information-block__desc">
                Внимание! Запланирована смена тарифа <br> с
                <strong>{{ tariff_transfer.tariff_from_expand.name }} / {{ tariff_transfer.tariff_from_expand.system_name }}</strong> на
                <strong>{{ tariff_transfer.tariff_to_expand.name }} / {{ tariff_transfer.tariff_to_expand.system_name }}</strong>
            </div>
            <div class="information-block__action">
                <span class="btn orange" @click="$emit('popup_cancel_planing_tariff', tariff_transfer)">
                    <i class="fal fa-times"></i> <span class="title">Отменить</span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "transfer-tariff",
    props: {
        tariff_transfer: {
            type: Object,
            required: false
        }
    }
}
</script>

<style scoped>

</style>