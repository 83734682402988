<template>
    <div class="grid">
        <div class="grid__col">
            <div class="grid__box">
                <div class="services">
                    <div class="services__head">
                        <div class="services__title">Сервисные услуги</div>
                    </div>
                    <div class="table-box">
                        <table class="table-box__table">
                            <thead class="table-box__thead">
                            <tr class="table-box__thead-tr">
                                <th class="table-box__th">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-toolbox"></i></span>
                                        <span class="table-box__thead-title">Название услуги</span>
                                    </span>
                                </th>
                                <th class="table-box__th">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-hourglass-end"></i></span>
                                        <span class="table-box__thead-title">Доступность</span>
                                    </span>
                                </th>
                                <th class="table-box__th">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-check-double"></i></span>
                                        <span class="table-box__thead-title">Статус</span>
                                    </span>
                                </th>
                                <th class="table-box__th">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-toggle-off"></i></span>
                                        <span class="table-box__thead-title">Управление</span>
                                    </span>
                                </th>
                                <th class="table-box__th">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-icon"><i class="fal fa-mouse-pointer"></i></span>
                                        <span class="table-box__thead-title">Действия</span>
                                    </span>
                                </th>

                            </tr>
                            </thead>
                            <tbody class="table-box__tbody">
                                <!--Trust day-->
                                <template v-if="trust_day_services.length > 0">
                                    <service-trust-day v-for="(service, index) in trust_day_services"
                                                        :subscriber_connection="subscriber_connection"
                                                        :errors="errors"
                                                        :service="service"
                                                        :index="loyalty_services.indexOf(service)"
                                                        @change_is_active="change_is_active"
                                                        @update_subscriber_connection="update_subscriber_connection">
                                    </service-trust-day>
                                </template>
                                <template v-else>
                                    <service-trust-day :subscriber_connection="subscriber_connection"
                                                       :errors="errors"
                                                       @update_subscriber_connection="update_subscriber_connection"
                                                       @add_new_service_by_checkbox="add_new_service_by_checkbox">
                                    </service-trust-day>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormErrors from "../../form/errors";
import ServiceTrustDay from "./loyalty_services/service-trust-day"

export default {
    name: "loyalty-services",
    props: ['loyalty_services', 'errors', 'subscriber_connection'],
    components: {
        "form-error": FormErrors,
        "service-trust-day": ServiceTrustDay,
    },
    data() {
        return {
            trust_day_services: [],
        }
    },
    methods: {
        update_subscriber_connection() {
            this.$emit("update_subscriber_connection");
        },
        change_is_active(service, index) {
            this.$emit('change_is_active', service, index);
        },
        add_new_service_by_checkbox(service_type) {
            this.$emit('add_new_service_by_checkbox', service_type);
        }
    },
    watch: {
        loyalty_services(values) {
            this.trust_day_services = values.filter(elm => elm.service_type === 'service_trust_day');
        }
    }
}
</script>

<style scoped>

</style>