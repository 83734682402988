<template>
    <div class="permissions">
        <div class="permissions__body">
            <!--head-->
            <div class="permissions__head">
                <div class="permissions__title">
                    <span class="permissions__title-icon"><i class="fal fa-user-lock"></i></span>
                    <span class="permissions__title-txt">Абоненты отделов и отчеты</span>
                </div>
            </div>
            <!--group checkbox-->
            <div class="permissions__group">
                <div class="permissions__group-title">Абоненты отделов и отчеты</div>
                <div class="permissions__group-row">

                    <div v-for="department in departments" class="permissions__group-col permissions__group-col_25">
                        <!--permissions item-->
                        <div class="permissions__item">
                            <ul class="permissions__list">
                                <li class="permissions__list-item permissions__list-item_head">

                                    <template v-if="readonly">
                                        <span class="permissions__checkbox-title">{{ department.name }}</span>
                                    </template>
                                    <template v-else>
                                        <label class="permissions__checkbox">
                                            <input type="checkbox" @change="base_perm_change"
                                                   v-model="select_group_department[department.id]"
                                                   v-bind:value="department.id">
                                            <span class="permissions__checkmark"></span>
                                            <span class="permissions__checkbox-title">{{ department.name }}</span>
                                        </label>
                                    </template>

                                </li>
                                <li class="permissions__list-item">

                                    <label class="permissions__checkbox">
                                        <input type="checkbox" :disabled="disabled_checked.indexOf(department.id) > -1" v-model="is_department_access[department.id]" @change="perm_change(department.id, 'is_department_access',$event)">
                                        <span class="permissions__checkmark"></span>
                                        <span class="permissions__checkbox-title">Доступ к отделу</span>
                                    </label>
<!--                                    <template v-if="readonly">-->
<!--                                        <employee-permission-readonly :access="is_department_access[department.id]" -->
<!--                                                                      :title="'Доступ к отделу'">-->
<!--                                        </employee-permission-readonly>-->
<!--                                   </template>-->
<!--                                    <template v-else>-->
<!--                                        <label class="permissions__checkbox">-->
<!--                                            <input type="checkbox" :disabled="disabled_checked.indexOf(department.id) > -1" v-model="is_department_access[department.id]" @change="perm_change(department.id, 'is_department_access',$event)">-->
<!--                                            <span class="permissions__checkmark"></span>-->
<!--                                            <span class="permissions__checkbox-title">Доступ к отделу</span>-->
<!--                                        </label>-->
<!--                                    </template>-->

                                </li>
                                <li class="permissions__list-item">
                                    <template v-if="readonly">
                                        <employee-permission-readonly :access="is_report_subscriber[department.id]" :title="'Отчет `Абоненты`'"></employee-permission-readonly>
                                    </template>
                                    <template v-else>
                                        <label class="permissions__checkbox">
                                            <input type="checkbox" v-model="is_report_subscriber[department.id]" @change="perm_change(department.id, 'is_report_subscriber', $event)">
                                            <span class="permissions__checkmark"></span>
                                            <span class="permissions__checkbox-title">Отчет "Абоненты"</span>
                                        </label>
                                    </template>
                                </li>
                                <li class="permissions__list-item">
                                    <template v-if="readonly">
                                        <employee-permission-readonly :access="is_report_service[department.id]" :title="'Отчет `Услуги`'"></employee-permission-readonly>
                                    </template>
                                    <template v-else>
                                        <label class="permissions__checkbox">
                                            <input type="checkbox" v-model="is_report_service[department.id]" @change="perm_change(department.id, 'is_report_service', $event)">
                                            <span class="permissions__checkmark"></span>
                                            <span class="permissions__checkbox-title">Отчет "Услуги"</span>
                                        </label>
                                    </template>
                                </li>
                                <li class="permissions__list-item">
                                    <template v-if="readonly">
                                        <employee-permission-readonly :access="is_report_network[department.id]" :title="'Отчет `Сети`'"></employee-permission-readonly>
                                    </template>
                                    <template v-else>
                                        <label class="permissions__checkbox">
                                            <input type="checkbox" v-model="is_report_network[department.id]" @change="perm_change(department.id, 'is_report_network', $event)">
                                            <span class="permissions__checkmark"></span>
                                            <span class="permissions__checkbox-title">Отчет "Сети"</span>
                                        </label>
                                    </template>
                                </li>
                                <li class="permissions__list-item">
                                    <template v-if="readonly">
                                        <employee-permission-readonly :access="is_report_subscribers_leak[department.id]" :title="'Отчет `Утечка абонентов`'"></employee-permission-readonly>
                                    </template>
                                    <template v-else>
                                        <label class="permissions__checkbox">
                                            <input type="checkbox" v-model="is_report_subscribers_leak[department.id]" @change="perm_change(department.id, 'is_report_subscribers_leak', $event)">
                                            <span class="permissions__checkmark"></span>
                                            <span class="permissions__checkbox-title">Отчеты "Утечка абонентов" и "Задержка оплат"</span>
                                        </label>
                                    </template>
                                </li>
                                <li class="permissions__list-item">
                                    <template v-if="readonly">
                                        <employee-permission-readonly :access="is_report_department_full[department.id]" :title="'Отчет `Отделы` - полный'"></employee-permission-readonly>
                                    </template>
                                    <template v-else>
                                        <label class="permissions__checkbox">
                                            <input type="checkbox" v-model="is_report_department_full[department.id]" @change="perm_change(department.id, 'is_report_department_full', $event)">
                                            <span class="permissions__checkmark"></span>
                                            <span class="permissions__checkbox-title">Отчет "Отделы" - полный</span>
                                        </label>
                                    </template>
                                </li>
                                <li class="permissions__list-item">
                                    <template v-if="readonly">
                                        <employee-permission-readonly :access="is_report_department_partial[department.id]" :title="'Отчет `Отделы` - частичный'"></employee-permission-readonly>
                                    </template>
                                    <template v-else>
                                        <label class="permissions__checkbox">
                                            <input type="checkbox" v-model="is_report_department_partial[department.id]" @change="perm_change(department.id, 'is_report_department_partial', $event)">
                                            <span class="permissions__checkmark"></span>
                                            <span class="permissions__checkbox-title">Отчет "Отделы" - частичный</span>
                                        </label>
                                    </template>
                                </li>
                                <li class="permissions__list-item">
                                    <template v-if="readonly">
                                        <employee-permission-readonly :access="is_reporting_by_default[department.id]" :title="'Получение отчета по-умолчанию'"></employee-permission-readonly>
                                    </template>
                                    <template v-else>
                                        <label class="permissions__checkbox">
                                            <input type="checkbox" v-model="is_reporting_by_default[department.id]" @change="perm_change(department.id, 'is_reporting_by_default', $event)">
                                            <span class="permissions__checkmark"></span>
                                            <span class="permissions__checkbox-title">Получение отчета по-умолчанию</span>
                                        </label>
                                    </template>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import EmployeePermissionReadonly from './_readonly.vue';

    export default {
        name: "permission-department",
        props: ['departments', 'permissions_department', 'readonly'],
        components: {
            "employee-permission-readonly": EmployeePermissionReadonly,
        },
        data() {
            return {
                select_group_department: {},
                is_department_access: {},
                is_report_subscriber: {},
                is_report_service: {},
                is_report_network: {},
                is_report_subscribers_leak: {},
                is_report_department_full: {},
                is_report_department_partial: {},
                is_reporting_by_default: {},
                disabled_checked: [],
            }
        },
        methods: {
            base_perm_change: function ({type, target}) {
                this.set_all_child_perm(target.value);
                this.make_response();
            },
            perm_change: function (department_id, model_name, {type, target}) {
                this.select_group_department[department_id] = this.check_group_department(department_id);

                if(this.need_disable_checked(department_id)){
                    this.is_department_access[department_id] = true;
                    this.add_disabled_checked(department_id);
                }else {
                    this.remove_disabled_checked(department_id);
                }

                if(model_name === 'is_report_department_full'){
                    if(this.is_report_department_partial[department_id]){
                        this.is_report_department_partial[department_id] = false;
                    }
                }

                if(model_name === 'is_report_department_partial'){
                    if (this.is_report_department_partial[department_id]) {
                        this.is_report_department_full[department_id] = false;
                    }
                }

                this.make_response();
            },
            add_disabled_checked: function (department_id) {
                if (this.disabled_checked.indexOf(department_id) === -1) {
                    this.disabled_checked.push(department_id);
                }
            },
            remove_disabled_checked: function (department_id) {
                var index = this.disabled_checked.indexOf(department_id);
                if (index !== -1) {
                    this.disabled_checked.splice(index, 1);
                }
            },
            set_all_child_perm(department_id) {
                this.$set(this.is_department_access, department_id, this.select_group_department[department_id]);
                this.$set(this.is_report_subscriber, department_id, this.select_group_department[department_id]);
                this.$set(this.is_report_service, department_id, this.select_group_department[department_id]);
                this.$set(this.is_report_network, department_id, this.select_group_department[department_id]);
                this.$set(this.is_report_subscribers_leak, department_id, this.select_group_department[department_id]);
                this.$set(this.is_report_department_full, department_id, this.select_group_department[department_id]);
                // Disable report_department_partial
                this.$set(this.is_report_department_partial, department_id, false);

                this.$set(this.is_reporting_by_default, department_id, this.select_group_department[department_id]);

                if(this.select_group_department[department_id]){
                    this.add_disabled_checked(department_id)
                } else {
                    this.remove_disabled_checked(department_id)
                }
            },
            check_group_department(department_id) {
                return (this.is_department_access[department_id] && this.is_report_subscriber[department_id] &&
                    (this.is_report_department_full[department_id] || this.is_report_department_partial[department_id]) &&
                    this.is_reporting_by_default[department_id]);
            },
            need_disable_checked(department_id) {
                return !(!this.is_report_subscriber[department_id] && !this.is_report_department_full[department_id] &&
                         !this.is_report_department_partial[department_id] && !this.is_reporting_by_default[department_id]);
            },
            make_response() {
                var permissions_department = {}, department_id, field;
                for (field of ['is_department_access', 'is_report_subscriber', 'is_report_service',
                               'is_report_network', 'is_report_subscribers_leak', 'is_report_department_full',
                               'is_report_department_partial', 'is_reporting_by_default']) {
                    for (department_id in this[field]) {
                        if (!(department_id in permissions_department)) {
                            permissions_department[department_id] = {
                                'department': department_id,
                                'is_department_access': false,
                                'is_report_subscriber': false,
                                'is_report_service': false,
                                'is_report_network': false,
                                'is_report_subscribers_leak': false,
                                'is_report_department_full': false,
                                'is_report_department_partial': false,
                                'is_reporting_by_default': false,
                            };
                        }

                        permissions_department[department_id][field] = this[field][department_id];
                    }
                }

                this.$emit("set_department_permissions", Object.values(permissions_department));
            }
        },
        watch: {
            permissions_department(){
                for(var permissions_department of this.permissions_department) {
                    this.$set(this.is_department_access, permissions_department.department, permissions_department.is_department_access);
                    this.$set(this.is_report_subscriber, permissions_department.department, permissions_department.is_report_subscriber);
                    this.$set(this.is_report_service, permissions_department.department, permissions_department.is_report_service);
                    this.$set(this.is_report_network, permissions_department.department, permissions_department.is_report_network);
                    this.$set(this.is_report_subscribers_leak, permissions_department.department, permissions_department.is_report_subscribers_leak);
                    this.$set(this.is_report_department_full, permissions_department.department, permissions_department.is_report_department_full)
                    this.$set(this.is_report_department_partial, permissions_department.department, permissions_department.is_report_department_partial);
                    this.$set(this.is_reporting_by_default, permissions_department.department, permissions_department.is_reporting_by_default);

                    this.select_group_department[permissions_department.department] = this.check_group_department(permissions_department.department);
                }
            }
        }
    }
</script>

<style scoped>

</style>