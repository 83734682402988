<template>
    <div class="services__total">
        <div class="services__total-type">
            <div class="services__total-title">Общая стоимость с тарифом:</div>
            <div class="services__total-summ">
                <span class="services__total-all">
                    <span class="services__total-currency">&#x20b4</span>
                    <span class="services__total-value">{{ subscriber_connection.total_cost_month }}</span>
                    <span class="services__total-period">/мес</span>
                </span>
                <span class="services__total-divide"></span>
                <span class="services__total-all">
                    <span class="services__total-currency">&#x20b4</span>
                    <span class="services__total-value">{{ subscriber_connection.total_cost_day }}</span>
                    <span class="services__total-period">/день</span>
                </span>
            </div>
        </div>
        <div class="services__total-type">
            <div class="services__total-title">Сумма активных услуг:</div>
            <div class="services__total-summ">
                <span class="services__total-all">
                    <span class="services__total-currency">&#x20b4</span>
                    <span class="services__total-value">{{ subscriber_services.total_cost_month }}</span>
                    <span class="services__total-period">/мес</span>
                </span>
                <span class="services__total-divide"></span>
                <span class="services__total-all">
                    <span class="services__total-currency">&#x20b4</span>
                    <span class="services__total-value">{{ subscriber_services.total_cost_day }}</span>
                    <span class="services__total-period">/день</span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "total-cost-services",
    props: ['subscriber_connection', 'subscriber_services', ]
}
</script>

<style scoped>

</style>