import {API} from "../../api";

export var ServiceActivationMixin = {
    methods: {
        change_iptv_triniti_is_active(service, index) {
            var self = this;
            self.errors = {};

            API.post('/services/iptv-triniti-subscriber-connection-activation/', {
                iptv_triniti_connection: service.service_object_id,
                is_active: service.is_active,
            })
                .then(function (response) {
                    self.$emit('update_subscriber_connection');
                    if (response.data.is_deleted) {
                        self.subscriber_services.service_list.additional_services.splice(index, 1);
                    }
                })
                .catch(function (error) {
                    API.default_catch(self, error)
                })
                .then(function () {
                    API.default_always(self);
                })
        },
        change_ip_address_is_active(service, index) {
            var self = this;
            self.errors = {};

            API.post('/services/ip-address-subscriber-connection-activation/', {
                ip_address_connection: service.service_object_id,
                is_active: service.is_active,
            })
                .then(function (response) {
                    self.$emit('update_subscriber_connection');
                    if (response.data.is_deleted) {
                        self.subscriber_services.service_list.additional_services.splice(index, 1);
                    }
                })
                .catch(function (error) {
                    API.default_catch(self, error)
                })
                .then(function () {
                    API.default_always(self);
                })
        },
        change_trust_day_is_active(service, index) {
            var self = this;
            self.errors = {};

            API.post('/services/trust-day-subscriber-connection-activation/', {
                trust_day_subscriber_connection: service.service_object_id,
                is_active: service.is_active,
            })
                .then(function (response) {
                    self.$emit('update_subscriber_connection');
                    if (response.data.is_deleted) {
                        self.subscriber_services.service_list.additional_services.splice(index, 1);
                    }
                })
                .catch(function (error) {
                    API.default_catch(self, error)
                })
                .then(function () {
                    API.default_always(self);
                })
        },
    }
};
