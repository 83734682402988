<template>
    <popup :settings="settings">
        <div slot="title">ТВ: {{ crud.data.item.name }}</div>
        <div slot="content">
            <div class="body-size">
                <div class="body-size__650">
                    <div class="view-modal">
                        <div class="view-modal__item">
                            <span class="view-modal__title">
                                <span class="view-modal__icon"><i class="fal fa-calendar-check"></i></span>
                                       Дата создания:
                                </span>
                            <span class="view-modal__value">
                                {{ crud.data.item.created_at }}
                            </span>
                        </div>
                        <div class="view-modal__item">
                            <span class="view-modal__title">
                                <span class="view-modal__icon"><i class="fal fa-map-marked-alt"></i></span>
                                    Доступно в ЛК:
                            </span>
                            <span class="view-modal__value">
                                <template v-if="crud.data.item.is_available_in_profile">
                                    <span class="view-modal__value-status view-modal__value-status_active">
                                        Доступен
                                    </span>
                                </template>
                                <template v-else>
                                    <span class="view-modal__value-status view-modal__value-status_deactive">
                                        Недоступен
                                    </span>
                                </template>
                            </span>
                        </div>
                        <div class="view-modal__item">
                            <span class="view-modal__title">
                                <span class="view-modal__icon"><i class="fal fa-phone-laptop"></i></span>
                                       Кол-во устройств:
                            </span>
                            <span class="view-modal__value view-modal__value_bold">
                                {{ crud.data.item.count_devices }}
                            </span>
                        </div>
                        <div class="view-modal__item">
                            <span class="view-modal__title">
                                <span class="view-modal__icon"><i class="fal fa-users-crown"></i></span>
                                       Тариф Trinity:
                            </span>
                            <span v-if="crud.data.item.triniti_tariff_expand" class="view-modal__value">
                                {{ crud.data.item.triniti_tariff_expand.name }}
                            </span>
                        </div>
                        <div class="view-modal__item">
                            <span class="view-modal__title">
                                <span class="view-modal__icon"><i class="fal fa-users-crown"></i></span>
                                       Тип абонента:
                            </span>
                            <span class="view-modal__value">
                                {{ crud.data.item.count_devices }}
                            </span>
                        </div>
                        <div class="view-modal__item">
                            <span class="view-modal__title">
                                <span class="view-modal__icon"><i class="fal fa-code-branch"></i></span>
                                       Отдел:
                            </span>
                            <span v-if="crud.data.item.department_expand" class="view-modal__value">
                                {{ crud.data.item.department_expand.name }}
                            </span>
                            <span v-else class="view-modal__value">
                                Все
                            </span>
                        </div>
                        <div class="view-modal__item">
                            <span class="view-modal__title">
                                <span class="view-modal__icon"><i class="fal fa-ethernet"></i></span>
                                       Технология:
                            </span>
                            <span v-if="crud.data.item.connection_type_expand" class="view-modal__value">
                                {{ crud.data.item.connection_type_expand.label }}
                            </span>
                            <span v-else class="view-modal__value">
                                Все
                            </span>
                        </div>
                        <div class="view-modal__item">
                            <span class="view-modal__title">
                                <span class="view-modal__icon"><i class="fal fa-calendar-alt"></i></span>
                                       Тарификация:
                            </span>
                            <span class="view-modal__value">
                                <span class="view-modal__price">
                                    <span class="view-modal__price-item" v-if="crud.data.item.charging_amount_month">
                                        <span class="view-modal__price-icon">₴</span>
                                        <span class="view-modal__price-value">300</span>
                                    </span>
                                    <span class="view-modal__price-divide"></span>
                                    <span class="view-modal__price-item" v-if="crud.data.item.charging_amount_day">
                                        <span class="view-modal__price-icon">₴</span>
                                        <span class="view-modal__price-value">10</span>
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div class="view-modal__item">
                            <span class="view-modal__title">
                                <span class="view-modal__icon"><i class="fal fa-comment-lines"></i></span>
                                       Примечание:
                            </span>
                            <span class="view-modal__value">
                                {{ crud.data.item.note }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="btn-group">
                <span class="btn btn_grey" @click="popup_close">
                    <span class="btn__icon"><i class="fal fa-times-circle"></i></span>
                    <span class="btn__title">Ознакомлен</span>
                </span>
            </div>
        </div>
    </popup>
</template>

<script>
    import Popup from '../../modal/popup.vue';

    import {CrudMixin} from "../../../../../mixins/admin/crud/crud_base";

    export default {
        name: "view-iptv-triniti",
        props: ["settings", "crud", ],
        mixins: [CrudMixin],
        components: {
            "popup": Popup,
        },
    }
</script>

<style scoped>

</style>