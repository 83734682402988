/* User model */
import {API} from "../../api";
import {UserModel} from "../../models/user";
import {LOGIN_REQUIRED_ROUTES, LOGIN_REDIRECT_TO, SOCIAL_ACCOUNTS} from "../../config";


export var User = {
    methods: {
        login_google: function(){
            var self = this;
            // const token = self.$localStorage.get('google_token');
            const token = self.$session.get('google_token');

            // Remove old token
            // self.$localStorage.remove('access_token');
            self.$session.remove('access_token');
            API.access_token = "";
            UserModel.data.is_auth = false;

            this.login({access_token: token})
                .then(function (response) {
                    // Set token
                    self.set_user_by_data(response.data.user);
                    self.set_token(response.data.token);

                    self.$router.push({name: "dashboard-page"})

                })
                .catch(function (error) {
                    API.default_catch(self, error);
                    if(self.errors.daily_password !== undefined){
                        self.pin_show();
                    } else {
                        if(self.errors.access_token !== "") {
                            self.alert("Нет доступа", self.errors.access_token[0]);
                        }
                    }

                })
        },
        login: function(data) {
            return API.post(SOCIAL_ACCOUNTS['providers']['google']['url'], data)
        },
        logout: function () {
            var self=this;
            API.post("/auth-logout/")
                .then(function (response) {
                    // self.$localStorage.remove('google_token');
                    // self.$localStorage.remove('access_token');

                    self.$session.remove('google_token');
                    self.$session.remove('access_token');
                    API.access_token = "";
                    UserModel.data.is_auth = false;

                    if(LOGIN_REDIRECT_TO !== self.$route.name){
                        self.$router.push({name: LOGIN_REDIRECT_TO})
                    }
                })
                .catch(function (error) {
                    this.alert("Ошибка", "Произошла ошибка выхода из системы");
                })
        },
        set_token: function(access_token) {
            // this.$localStorage.set('access_token', access_token);
            this.$session.set('access_token', access_token);
            this.$store.commit('user/setAccessToken', access_token);

            API.access_token = access_token;
        },
    }
};
