<template>
  <div class="grid">
    <div class="grid__col">
      <div class="grid__box">
        <!--Unicards-->
        <div class="page-set">
          <div class="page-set__head">
            <div class="page-set__col">
              <div class="page-set__title">Unicards</div>
              <div class="page-set__desc">Пополнение счета с помощью специального кода карт пополнения.
              </div>
            </div>
            <div class="page-set__col">
              <div class="page-set__img page-set__img_right">
                <img src="../../../../../../../../media/img/logo/unicard.svg" alt="" class="page-set__logo">
              </div>
            </div>
          </div>
          <form class="form-set">
            <div class="form-set__row">
              <div class="form-set__col">
                <div class="form-set__item">
                  <div class="form-set__label">
                    <span class="form-set__label-icon"><i class="fal fa-tools"></i></span>
                    <span class="form-set__label-title">Параметры</span>
                    <span class="form-set__label-help"
                          v-tooltip.right="TooltipText='Параметры отсутсвуют <br>у данного способа оплаты'"><i
                        class="fal fa-info-circle"></i></span>
                  </div>
                  <div class="form-set__value">
                    <input type="text" placeholder="Нет надстроек" disabled class="form-set__input">
                  </div>
                </div>
              </div>
              <div class="form-set__col">
                <div class="form-set__item">
                  <div class="form-set__label">
                    <span class="form-set__label-icon"><i class="fal fa-power-off"></i></span>
                    <span class="form-set__label-title">Статус</span>
                    <span class="form-set__label-help"
                          v-tooltip.right="TooltipText='Вкл/выкл способа оплаты'"><i
                        class="fal fa-info-circle"></i></span>
                  </div>
                  <div class="form-set__value">
                    <div class="form-set__switch">
                      <input type="checkbox" id="switch-status-unicards"
                             class="form-set__input form-set__input_switch">
                      <label for="switch-status-unicards" class="form-set__switch-for"></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div class="page-set__divide"></div>
          <!--Приват24-->
          <div class="page-set__head">
            <div class="page-set__col">
              <div class="page-set__title">Приват24</div>
              <div class="page-set__desc">Пополнение счета с помощью марчант ПАО "Приватбанк".</div>
            </div>
            <div class="page-set__col">
              <div class="page-set__img page-set__img_right">
                <img src="../../../../../../../../media/img/logo/pb24.svg" alt="" class="page-set__logo">
              </div>
            </div>
          </div>
          <form class="form-set">
            <div class="form-set__row">
              <div class="form-set__col">
                <div class="form-set__item">
                  <div class="form-set__label">
                    <span class="form-set__label-icon"><i class="fal fa-tools"></i></span>
                    <span class="form-set__label-title">Merchant ID</span>
                    <span class="form-set__label-help"
                          v-tooltip.right="TooltipText='Мерчат ID получить нужно<br> в кабинете Приват24'"><i
                        class="fal fa-info-circle"></i></span>
                  </div>
                  <div class="form-set__value">
                    <input type="text" placeholder="Merchant ID" class="form-set__input">
                  </div>
                </div>
              </div>
              <div class="form-set__col">
                <div class="form-set__item">
                  <div class="form-set__label">
                    <span class="form-set__label-icon"><i class="fal fa-power-off"></i></span>
                    <span class="form-set__label-title">Статус</span>
                    <span class="form-set__label-help"
                          v-tooltip.right="TooltipText='Вкл/выкл способа оплаты'"><i
                        class="fal fa-info-circle"></i></span>
                  </div>
                  <div class="form-set__value">
                    <div class="form-set__switch">
                      <input type="checkbox" id="switch-status-pb24"
                             class="form-set__input form-set__input_switch">
                      <label for="switch-status-pb24" class="form-set__switch-for"></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div class="page-set__divide"></div>
          <!--LiqPay-->
          <div class="page-set__head">
            <div class="page-set__col">
              <div class="page-set__title">Liqpay</div>
              <div class="page-set__desc">Пополнение счета с помощью марчант платежной системы Liqpay.</div>
            </div>
            <div class="page-set__col">
              <div class="page-set__img page-set__img_right">
                <img src="../../../../../../../../media/img/logo/liqpay-main.svg" alt="" class="page-set__logo">
              </div>
            </div>
          </div>
          <form class="form-set">
            <div class="form-set__row">
              <div class="form-set__col">
                <div class="form-set__item">
                  <div class="form-set__label">
                    <span class="form-set__label-icon"><i class="fal fa-tools"></i></span>
                    <span class="form-set__label-title">Merchant ID</span>
                    <span class="form-set__label-help"
                          v-tooltip.right="TooltipText='Мерчат ID получить нужно<br> в кабинете Liqpay'"><i
                        class="fal fa-info-circle"></i></span>
                  </div>
                  <div class="form-set__value">
                    <input type="text" placeholder="Merchant ID" class="form-set__input">
                  </div>
                </div>
              </div>
              <div class="form-set__col">
                <div class="form-set__item">
                  <div class="form-set__label">
                    <span class="form-set__label-icon"><i class="fal fa-power-off"></i></span>
                    <span class="form-set__label-title">Статус</span>
                    <span class="form-set__label-help"
                          v-tooltip.right="TooltipText='Вкл/выкл способа оплаты'"><i
                        class="fal fa-info-circle"></i></span>
                  </div>
                  <div class="form-set__value">
                    <div class="form-set__switch">
                      <input type="checkbox" id="switch-status-liqpay"
                             class="form-set__input form-set__input_switch">
                      <label for="switch-status-liqpay" class="form-set__switch-for"></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div class="page-set__divide"></div>
          <!--EasyPay-->
          <div class="page-set__head">
            <div class="page-set__col">
              <div class="page-set__title">EasyPay</div>
              <div class="page-set__desc">Прием платежей через сеть терминалов "EasyPay", также Visa & MasterCard.</div>
            </div>
            <div class="page-set__col">
              <div class="page-set__img page-set__img_right">
                <img src="../../../../../../../../media/img/logo/easypay.svg" alt="" class="page-set__logo">
              </div>
            </div>
          </div>
          <form class="form-set">
            <div class="form-set__row">
              <div class="form-set__col">
                <div class="form-set__item">
                  <div class="form-set__label">
                    <span class="form-set__label-icon"><i class="fal fa-tools"></i></span>
                    <span class="form-set__label-title">Ключь API</span>
                    <span class="form-set__label-help"
                          v-tooltip.right="TooltipText='Ключь API Easypay'"><i
                        class="fal fa-info-circle"></i></span>
                  </div>
                  <div class="form-set__value">
                    <input type="text" placeholder="API KEY" class="form-set__input">
                  </div>
                </div>
              </div>
              <div class="form-set__col">
                <div class="form-set__item">
                  <div class="form-set__label">
                    <span class="form-set__label-icon"><i class="fal fa-power-off"></i></span>
                    <span class="form-set__label-title">Статус</span>
                    <span class="form-set__label-help"
                          v-tooltip.right="TooltipText='Вкл/выкл способа оплаты'"><i
                        class="fal fa-info-circle"></i></span>
                  </div>
                  <div class="form-set__value">
                    <div class="form-set__switch">
                      <input type="checkbox" id="switch-status-easypay"
                             class="form-set__input form-set__input_switch">
                      <label for="switch-status-easypay" class="form-set__switch-for"></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div class="page-set__divide"></div>
          <!--Family-->
          <div class="page-set__head">
            <div class="page-set__col">
              <div class="page-set__title">Банк "Фамильный"</div>
              <div class="page-set__desc">Прием оплаты через сеть терминалов банка "Фамильный".</div>
            </div>
            <div class="page-set__col">
              <div class="page-set__img page-set__img_right">
                <!--                            <img src="../../../../../../../media/img/logo/easypay.svg" alt="" class="page-set__logo">-->
              </div>
            </div>
          </div>
          <form class="form-set">
            <div class="form-set__row">
              <div class="form-set__col">
                <div class="form-set__item">
                  <div class="form-set__label">
                    <span class="form-set__label-icon"><i class="fal fa-tools"></i></span>
                    <span class="form-set__label-title">Ключь API</span>
                    <span class="form-set__label-help"
                          v-tooltip.right="TooltipText='Ключь API от платежной системы банка Фамильный'"><i
                        class="fal fa-info-circle"></i></span>
                  </div>
                  <div class="form-set__value">
                    <input type="text" placeholder="API KEY" class="form-set__input">
                  </div>
                </div>
              </div>
              <div class="form-set__col">
                <div class="form-set__item">
                  <div class="form-set__label">
                    <span class="form-set__label-icon"><i class="fal fa-power-off"></i></span>
                    <span class="form-set__label-title">Статус</span>
                    <span class="form-set__label-help"
                          v-tooltip.right="TooltipText='Вкл/выкл способа оплаты'"><i
                        class="fal fa-info-circle"></i></span>
                  </div>
                  <div class="form-set__value">
                    <div class="form-set__switch">
                      <input type="checkbox" id="switch-status-family"
                             class="form-set__input form-set__input_switch">
                      <label for="switch-status-family" class="form-set__switch-for"></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "payment"
}
</script>

<style scoped>

</style>