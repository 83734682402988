<template>
    <header class="header">
        <div class="layout">
            <div class="header__body">
                <div class="h-logo">
                    <template v-if="$route.path!=='/connection/:id([A-Za-z0-9_-]+)'">
                        <img src="/media/img/site/logo-belnet.svg" class="h-logo__svg"
                             alt="ISP BELNET — Персональний кабінет">
                    </template>
                    <template v-else>
                        <router-link title="Кабінет" :to="{ name: 'connection'}">
                            <img src="/media/img/site/logo-belnet.svg" class="h-logo__svg"
                                 alt="ISP BELNET — Персональний кабінет">
                        </router-link>
                    </template>
                </div>

                <div class="header__action">
                    <div class="h-links">
                        <a href="https://bel.net.ua" class="h-links__item">
                            <span class="h-links__item-icon"><i class="fal fa-globe-europe"></i></span>
                            <span class="h-links__item-title">Сайт компанії</span>
                        </a>
                        <!--<a href="#" class="h-links__item">-->
                        <!--<span class="h-links__item-icon"><i class="fal fa-life-ring"></i></span>-->
                        <!--<span class="h-links__item-title">Домога у керуванні</span>-->
                        <!--</a>-->
                    </div>
                    <HNotification></HNotification>
                    <HAuth></HAuth>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    import HAuth from '../components/header/h-auth'
    import HNotification from '../components/header/h-notification'

    export default {
        name: "header-block",

        components: {
            HAuth,
            HNotification,
        }
    }
</script>

<style scoped>

</style>