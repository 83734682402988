export var PaginationMixin = {
    data() {
        return {
            initPage: 0,
            initLimit: 0,
            isInitPage: false,
            isInitLimit: false
        }
    },
    methods: {
        pagination_init: function (default_page, default_limit) {
            var page = parseInt(this.$route.query.page);
            var limit = parseInt(this.$route.query.limit);
            if (isNaN(page)) {
                page = default_page || 1;
            }
            if (isNaN(limit)) {
                limit = default_limit || 15;
            }
            this.page = page;
            this.limit = limit > 100 ? 100 : limit;
        },
        get_offset: function (page) {
            var offset = (page * this.limit) - this.limit;
            return (offset > 0) ? offset : 0;
        },
        page_set: function (value, viewAllItems = false) {
            if ((viewAllItems) && (this.limit === null)) {
                this.page = 1;
                return;
            }
            this.page = parseInt(this.page);

            value = parseInt(value);
            var new_page = this.page + value;

            if ((new_page > 0) && (this.countItems >= this.get_offset(new_page))) {
                this.page = new_page;
            }
        },
        setInitPage(page) {
            if (!this.isInitPage) {
                this.initPage = page;
                this.isInitPage = true;
            }
        },
        setInitLimit(page) {
            if (!this.isInitLimit) {
                this.initLimit = page;
                this.isInitLimit = true;
            }
        },
        resetPaginationFilters() {
            this.limit = this.initLimit;
            this.page = this.initPage;
        },
        resetQueryPaginationFilters() {
            this.$route.query.limit = this.initLimit;
            this.$route.query.page = this.initPage;
            this.$route.query.offset = 0;

            this.resetPaginationFilters();
        },
        destroyPaginationListeners() {
            console.log("OFF pagination cleaner");
            this.$root.$off('resetQueryPaginationFilters');
        }
    },
    watch: {
        '$route.query': function (newValue, oldValue) {
            // because of this mixin also inherit list component in it attr initLimit && initPage always will be zero
            if (this.initPage === 0 || this.initLimit === 0) return;

            if (newValue && (!('limit' in newValue) && !('page' in newValue) && !('offset' in newValue))) {
                this.resetPaginationFilters();
            }

        }
    }
};
