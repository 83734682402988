<template>
    <div class="accordion">
        <div class="accordion__item" v-if="!showAccordion" @click="showAccordion = !showAccordion">
            <slot name="title-accordion"></slot>
            <span class="accordion__icon"><i class="fal fa-chevron-down"></i></span>
        </div>
        <div class="accordion__item active" v-else @click="showAccordion = !showAccordion">
            <slot name="title-accordion"></slot>
            <span class="accordion__icon"><i class="fal fa-chevron-up"></i></span>
        </div>
        <transition name="accordion">
            <div class="accordion__wrapper" v-if="showAccordion">
                <slot name="wrapper-accordion"></slot>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: "accordion",
        props: ["accordion"],
        data: function () {
            return {
                showAccordion: false,
            }
        },
    }
</script>

<style scoped>

</style>