<template>
    <popup :settings="settings">
        <div slot="title">Редактирование типа подключения</div>
        <div slot="content">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div class="modal__content">
                    <form action="" class="modal-form">
                        <fieldset class="modal-form__item">
                            <p><i class="fal fa-folder"></i> Ярлык типа подключения:</p>
                            <input type="text" v-model="crud.data.item.label" placeholder="Ярлык типа подключения">
                            <form-error v-bind:value="crud.errors.label" ></form-error>
                        </fieldset>
                        <fieldset class="modal-form__item">
                            <p><i class="fal fa-folder"></i> Ярлык типа подключения(На украинском):</p>
                            <input type="text" v-model="crud.data.item.label_uk" placeholder="Ярлык типа подключения(На украинском)">
                            <form-error v-bind:value="crud.errors.label_uk" ></form-error>
                        </fieldset>
                        <fieldset class="modal-form__item">
                            <p><i class="fal fa-folder"></i> Имя типа подключения:</p>
                            <input type="text" v-model="crud.data.item.name" placeholder="Имя типа подключения">
                            <form-error v-bind:value="crud.errors.name" ></form-error>
                        </fieldset>
                        <fieldset class="modal-form__item">
                            <p><i class="fal fa-comment-exclamation"></i> Описание типа подключения:</p>
                            <textarea name="desc" v-model="crud.data.item.description" placeholder="Описание типа подключения"></textarea>
                            <form-error v-bind:value="crud.errors.description" ></form-error>
                        </fieldset>
                    </form>
                </div>
            </div>
<!--            <div class="info-hide col-md-6 col-lg-6">-->
<!--                <div class="modal__content">-->
<!--                    <ul class="modal-info">-->
<!--                        <li class="modal-info__label"><i class="fal fa-folder"></i> Ярлык типа подключения:</li>-->
<!--                        <li class="modal-info__value">{{ crud.data.item_view.label }}</li>-->
<!--                        <li class="modal-info__label"><i class="fal fa-folder"></i> Имя типа подключения:</li>-->
<!--                        <li class="modal-info__value">{{ crud.data.item_view.name }}</li>-->
<!--                        <li class="modal-info__label"><i class="fal fa-comment-exclamation"></i> Описание типа подключения:</li>-->
<!--                        <li class="modal-info__value">{{ crud.data.item_view.description }}-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                </div>-->
<!--            </div>-->
        </div>
        <div slot="footer">
            <a href="" class="modal__btn" @click.prevent="crud_edit(crud.data.item.id, crud.data.item)">Сохранить</a>
            <a href="#" @click="popup_close">Отмена</a>
        </div>
    </popup>

</template>

<script>
    import Popup from '../modal/popup.vue'
    import {CrudMixin} from "../../../../mixins/admin/crud/crud_base";
    import FormErrors from '../form/errors.vue';

    export default {
        name: "connection_types-edit-popup",
        props: ["settings", "crud"],
        mixins: [
            CrudMixin
        ],
        components: {
            "popup": Popup,
            "form-error": FormErrors
        }
    }
</script>

<style scoped>

</style>