<template>
    <div class="permissions">
        <div class="permissions__body">
            <!--head-->
            <div class="permissions__head">
                <div class="permissions__title">
                    <span class="permissions__title-icon"><i class="fal fa-user-lock"></i></span>
                    <span class="permissions__title-txt">Права доступа</span>
                </div>
            </div>
            <!--group checkbox-->
            <div class="permissions__group">
                <div class="permissions__group-title">Права доступа</div>
                <div class="permissions__group-row">

                    <!-- START -->
                    <div v-for="(permission_block, permission_block_key) in employee_permissions_info.permissions_config" class="permissions__group-col permissions__group-col_33">

                        <!--permissions item-->
                        <div class="permissions__item">
                            <div class="permissions__item-title">{{ employee_permissions_info.permissions_config[permission_block_key].name }}</div>

                            <ul v-for="(sub_1_permission_block, sub_1_permission_block_key) in permission_block.group_perm_sub_1" class="permissions__list">
                                <li class="permissions__list-item permissions__list-item_head">

                                    <template v-if="readonly">

                                        <span class="permissions__checkbox-title">{{ permission_block.group_perm_sub_1[sub_1_permission_block_key].name }}</span>

                                    </template>
                                    <template v-else>

                                        <label class="permissions__checkbox">
                                            <input type="checkbox" @change="base_perm_change"
                                               v-model="select_group_permission[sub_1_permission_block_key]"
                                               v-bind:value="sub_1_permission_block_key">

                                            <span class="permissions__checkmark"></span>
                                            <span class="permissions__checkbox-title">{{ permission_block.group_perm_sub_1[sub_1_permission_block_key].name }}</span>
                                        </label>

                                    </template>

                                </li>

                                <template v-for="(sub_2_permission_block, sub_2_permission_block_key) in sub_1_permission_block.group_perm_sub_2" >

                                    <!--divide-->
                                    <li v-if="sub_2_permission_block_key !== 'no_name'" class="permissions__divide">
                                        <span class="permissions__divide-icon"></span>
                                        <span class="permissions__divide-title">{{ sub_1_permission_block.group_perm_sub_2[sub_2_permission_block_key].name }}</span>
                                    </li>

                                    <li v-for="(permission, permission_code) in sub_2_permission_block.permissions" class="permissions__list-item">

                                        <template v-if="readonly">
                                            <span class="permissions__checked" v-bind:class="[permissions_checked.indexOf(permission_code) === -1 ? 'permissions__checked_deactive' : 'permissions__checked_active']">
                                                <i class="far" v-bind:class="[permissions_checked.indexOf(permission_code) === -1 ? 'fa-times' : 'fa-check']"></i>
                                            </span>
                                            <span class="permissions__checkbox-title">{{ permission.name }}</span>

                                        </template>
                                        <template v-else>

                                            <label class="permissions__checkbox">
                                                <input type="checkbox"
                                                       :disabled="disabled_checked.indexOf(permission_code) > -1"
                                                       @change="perm_change(sub_1_permission_block_key, $event)"
                                                       v-model="permissions_checked" v-bind:value="permission_code" >

                                                <span class="permissions__checkmark"></span>
                                                <span class="permissions__checkbox-title">{{ permission.name }}</span>
                                            </label>

                                        </template>
                                    </li>

                                </template>
                            </ul>
                        </div>
                    </div>
                    <!-- END -->
                </div>
            </div>

            <!--group view-->
<!--            <div class="permissions__group">-->
<!--                <div class="permissions__group-title">Права доступа</div>-->
<!--                <div class="permissions__group-row">-->
<!--                    <div class="permissions__group-col permissions__group-col_33">-->
<!--                        &lt;!&ndash;permissions item&ndash;&gt;-->
<!--                        <div class="permissions__item">-->
<!--                            <div class="permissions__item-title">Администрирование</div>-->
<!--                            <ul class="permissions__list">-->
<!--                                <li class="permissions__list-item permissions__list-item_head">-->
<!--                                    <span class="permissions__checkbox-title">NAS</span>-->
<!--                                </li>-->
<!--                                <li class="permissions__list-item">-->
<!--                                    <span class="permissions__checked permissions__checked_active">-->
<!--                                        <i class="far fa-check"></i>-->
<!--                                    </span>-->
<!--                                    <span class="permissions__checkbox-title">Доступ к справочнику</span>-->
<!--                                </li>-->
<!--                                <li class="permissions__list-item">-->
<!--                                    <span class="permissions__checked permissions__checked_deactive">-->
<!--                                        <i class="far fa-times"></i>-->
<!--                                    </span>-->
<!--                                    <span class="permissions__checkbox-title">Добавление/редактирование</span>-->
<!--                                </li>-->
<!--                                <li class="permissions__list-item">-->
<!--                                    <span class="permissions__checked permissions__checked_deactive">-->
<!--                                        <i class="far fa-times"></i>-->
<!--                                    </span>-->
<!--                                    <span class="permissions__checkbox-title">Удаление (перенос в архив)</span>-->
<!--                                </li>-->
<!--                            </ul>-->
<!--                            <ul class="permissions__list">-->
<!--                                <li class="permissions__list-item permissions__list-item_head">-->
<!--                                    <span class="permissions__checked permissions__checked_deactive">-->
<!--                                        <i class="far fa-times"></i>-->
<!--                                    </span>-->
<!--                                    <span class="permissions__checkbox-title">IP адреса</span>-->
<!--                                </li>-->
<!--                                <li class="permissions__list-item">-->
<!--                                    <span class="permissions__checked permissions__checked_active">-->
<!--                                        <i class="far fa-check"></i>-->
<!--                                    </span>-->
<!--                                    <span class="permissions__checkbox-title">Доступ к справочнику</span>-->
<!--                                </li>-->
<!--                                <li class="permissions__list-item">-->
<!--                                    <span class="permissions__checked permissions__checked_active">-->
<!--                                        <i class="far fa-check"></i>-->
<!--                                    </span>-->
<!--                                    <span class="permissions__checkbox-title">Управление</span>-->
<!--                                </li>-->
<!--                                &lt;!&ndash;divide&ndash;&gt;-->
<!--                                <li class="permissions__divide">-->
<!--                                    <span class="permissions__divide-icon"></span>-->
<!--                                    <span class="permissions__divide-title">IP poll</span>-->
<!--                                </li>-->
<!--                                <li class="permissions__list-item">-->
<!--                                    <span class="permissions__checked permissions__checked_active">-->
<!--                                        <i class="far fa-check"></i>-->
<!--                                    </span>-->
<!--                                    <span class="permissions__checkbox-title">Добавление/редактирование</span>-->
<!--                                </li>-->
<!--                            </ul>-->

<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
        </div>
    </div>
</template>

<script>
    import {EmployeePermissionsInfoMixin} from "../../../../../mixins/admin/employee_permissions_info";

    export default {
        name: "permissions-list",
        props: ['permissions', 'permissions_department', 'readonly'],
        mixins: [EmployeePermissionsInfoMixin],
        data: function () {
            return {
                auto_enable_perms_map: {
                    'nas': 'catalog_nas_access',
                    'mac_address': 'catalog_mac_address_access',
                    'ip_address': 'catalog_ip_address_access',
                    'subscriber_groups': 'catalog_subscriber_groups_access',
                    'tariffs': 'catalog_tariffs_access',
                    'connection_types': 'catalog_connection_types_access',
                    'settlements': 'catalog_settlements_access',
                    'logging': 'catalog_logging_access',
                    'archive': 'archive_view',
                    'personal': 'catalog_additional_services_personal_access',
                    'service': 'catalog_additional_services_service_access',
                    'subscriber_card': 'subscribers_access',
                    'financial_operations': 'subscribers_access',
                    'subscriber_connection': 'subscribers_access',
                    'employees': 'catalog_employees_access',
                    'departments': 'catalog_departments_access',
                    'employee_positions': 'catalog_employee_positions_access',
                    'reports': 'reports_access',
                },
                select_group_permission: {},
                permissions_checked: [],
                disabled_checked: [],
            }
        },
        created: function () {
            this.load_employee_permissions_info();
        },
        methods: {
            base_perm_change: function ({type, target}) {
                const value = target.value;
                this.set_all_child_perm(value);

                // // Custom logic
                // if (target.value === "subscriber_card") {
                //     this.select_group_permission["subscriber_connection"] = true;
                //     this.set_all_child_perm("subscriber_connection");
                // }
            },
            perm_change: function (parent, {type, target}) {

                if (this.permissions_checked.indexOf(target.value) === -1) {
                    if (this.select_group_permission[parent] === true) {
                        this.select_group_permission[parent] = false;
                    }

                    this.remove_auto_enable(parent);

                } else {
                    // console.log(parent);
                    this.add_auto_enable(parent, target.value);

                }

                this.auto_set_base_perm();
                this.check_permissions_department();
            },
            remove_auto_enable: function (parent) {
                var children_permission = this.get_children_permission(parent, true);

                if(children_permission.length <= 1 && parent in this.auto_enable_perms_map){
                    this.remove_disabled_checked(this.auto_enable_perms_map[parent]);
                }
            },
            add_auto_enable: function (parent, perm) {
                for (var auto_enable_parent in this.auto_enable_perms_map) {
                    if(auto_enable_parent === parent && this.auto_enable_perms_map[auto_enable_parent] !== perm){
                        this.add_permissions_checked(this.auto_enable_perms_map[auto_enable_parent]);
                        this.add_disabled_checked(this.auto_enable_perms_map[auto_enable_parent])
                    }
                }
            },
            add_permissions_checked: function (perm) {
                if (this.permissions_checked.indexOf(perm) === -1) {
                    this.permissions_checked.push(perm);
                }
            },
            add_disabled_checked: function (perm) {
                if (this.disabled_checked.indexOf(perm) === -1) {
                    this.disabled_checked.push(perm);
                }
            },
            remove_disabled_checked: function (perm) {
                var index = this.disabled_checked.indexOf(perm);
                if (index !== -1) {
                    this.disabled_checked.splice(index, 1);
                }
            },
            set_all_child_perm: function (parent_code) {
                var permissions = this.get_children_permission(parent_code);

                if (this.select_group_permission[parent_code] === true) {
                    // Set all values to permissions_checked
                    for (var permission of permissions) {
                        // console.log("add " + perm);
                        this.add_permissions_checked(permission);
                    }

                    this.add_auto_enable(parent_code);
                } else {
                    // Removed all values to permissions_checked
                    for (var permission of permissions) {
                        this.permissions_checked = this.permissions_checked.filter(function (item) {
                            return item !== permission
                        })
                    }

                    this.remove_auto_enable(parent_code);
                }
                this.check_permissions_department();
            },
            auto_set_base_perm: function () {
                for(var permission_block_key in this.employee_permissions_info.permissions_config) {

                    var group_perm_sub_1 = this.employee_permissions_info.permissions_config[permission_block_key].group_perm_sub_1;
                    for (var sub_1_permission_code in group_perm_sub_1) {

                        var selected_base = true;
                        var group_perm_sub_2 = group_perm_sub_1[sub_1_permission_code].group_perm_sub_2;
                        for (var sub_2_permission_code in group_perm_sub_2) {

                            for (var permission_code in group_perm_sub_2[sub_2_permission_code].permissions) {
                                // Check all checked in permission group
                                if (this.permissions_checked.indexOf(permission_code) === -1) {
                                    selected_base = false;
                                    break;
                                }
                            }
                        }

                        this.select_group_permission[sub_1_permission_code] = selected_base;
                        if (selected_base === true) {
                            this.set_all_child_perm(sub_1_permission_code);
                        }
                    }
                }
            },
            get_children_permission(parent_code, only_checked=false) {
                var permissions = [];

                for(var permission_block_key in this.employee_permissions_info.permissions_config) {

                    var group_perm_sub_1 = this.employee_permissions_info.permissions_config[permission_block_key].group_perm_sub_1;
                    for (var sub_1_permission_code in group_perm_sub_1) {

                        if(sub_1_permission_code !== parent_code){
                            continue
                        }

                        var group_perm_sub_2 = group_perm_sub_1[sub_1_permission_code].group_perm_sub_2;
                        for (var sub_2_permission_code in group_perm_sub_2) {

                            for (var permission_code in group_perm_sub_2[sub_2_permission_code].permissions) {
                                if(only_checked){
                                   if (this.permissions_checked.indexOf(permission_code) !== -1) {
                                        permissions.push(permission_code)
                                    }
                                } else {
                                    permissions.push(permission_code)
                                }
                            }
                        }
                    }
                }
                return permissions;
            },
            check_permissions_department(){
                // Custom permission logic with department permissions
                var reports_access = false;
                for(let permissions_department of this.permissions_department){
                    // If department access enabled then auto enable `reports_access`
                    if(permissions_department.is_department_access){
                        reports_access = true;
                    }
                }

                // if(reports_access) {
                //     this.add_permissions_checked("reports_access");
                //     this.add_disabled_checked("reports_access");
                // } else {
                //     this.remove_disabled_checked("reports_access")
                // }
            }
        },
        watch: {
            permissions_checked(value) {
                this.$emit("set_permissions_checked", value);
            },
            permissions(value) {
                if (value !== undefined) {
                    this.permissions_checked = value;
                    this.auto_set_base_perm();
                }
            },
            permissions_department() {
                // console.log(value)
                this.check_permissions_department()
            }
        }
    }
</script>

<style scoped>

</style>