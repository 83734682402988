import {API} from "../../api";

export var SubscriberMixin = {
    data: function () {
        return {
            subscriber: {
                subscriber_type: "",
                fio: "",
                phone: "",
                phone_1: "",
                subscriber_email: "",
                user_passportdetails: {
                    country: "",
                    region: "",
                    settlement: "",
                    street: "",
                    house_number: "",
                    apartment_number: "",
                    passport_series_number: "",
                    date_of_issue: "",
                    issued_by: "",
                    identification_number: "",
                    private_subscriber_passport_details_file: [],
                    private_subscriber_passport_details_file_expand: [],
                    private_subscriber_additional_details_file: [],
                    private_subscriber_additional_details_file_expand: [],
                },
                subscriber_business_details: {
                    organizational_form: "",
                    company_name: "",
                    registry_code: "",
                    is_vat_payer: false,
                    taxpayer_id_number: "",
                    vat_certificate_number: "",
                    business_address_region: "",
                    business_address_district: "",
                    business_address_settlement: "",
                    business_address_street: "",
                    business_address_house_number: "",
                    business_address_apartment_number: "",
                    actual_address_region: "",
                    actual_address_district: "",
                    actual_address_settlement: "",
                    actual_address_street: "",
                    actual_address_house_number: "",
                    actual_address_apartment_number: "",
                    bank_payment_account: "",
                    bank_name: "",
                    // bank_code: "",
                    contact_full_name: "",
                    contact_position: "",
                    contact_phone: "",
                    contact_email: "",
                    agreement_name: "",
                    agreement_number: "",
                    agreement_conclusion_date: "",
                    agreement_expiration_date: "",
                    automatic_extension: false,
                    business_details_agreement_file: [],
                    business_details_agreement_file_expand: [],
                    business_details_statutory_document: [],
                    business_details_statutory_document_expand: [],
                    business_details_members: []
                }
            },
            errors: {
                user_passportdetails: {},
                subscriber_business_details: {
                    business_details_members: {}
                },
            },
        }
    },
    methods: {
        add_subscriber: function (data) {
            return API.post('/subscribers/', data)
        },
        load_subscriber: function (id) {
            var self = this;

            self.loader_show();
            API.get('/subscribers/' + id + '/')
                .then(function (response) {
                    var subscriber_business_details = Object.assign({}, self.subscriber.subscriber_business_details);
                    self.subscriber = response.data;
                    if(self.subscriber.subscriber_business_details == null){
                        self.subscriber.subscriber_business_details = subscriber_business_details;
                    }
                })
                .catch(function (error) {
                    // API.default_catch(self, error)
                })
                .then(function () {
                    API.default_always(self);
                })
        },
        edit_subscriber: function (id, data) {
            return API.patch('/subscribers/' + id + '/', data)
        },
        is_subscriber_business: function () {
            return this.is_subscriber_type("business");
        },
        is_subscriber_private: function () {
            return this.is_subscriber_type("private");
        },
        is_subscriber_type: function (type) {
            for(var i in this.subscriber_types) {
                if(this.subscriber_types[i].id === this.subscriber.subscriber_type){
                    return this.subscriber_types[i].name === type;
                }
            }
            return false;
        },
        check_subscriber_type: function (subscriber, type) {
            return subscriber.subscriber_type_expand.name === type;
        },
        subscriber_catch: function(self, error) {
            var errors = API.get_errors(error);
            self.$set(self, 'errors', Object.assign({}, API.convert_errors(errors)));

            // console.log(self.errors);
            if (!("user_passportdetails" in self.errors)) {
                self.errors["user_passportdetails"] = {}
            } else {
                self.errors["user_passportdetails"] = self.errors["user_passportdetails"][0];
            }

            if (!("subscriber_business_details" in self.errors)) {
                self.errors["subscriber_business_details"] = {
                    "business_details_members": {}
                }
            } else {
                self.errors["subscriber_business_details"] = self.errors["subscriber_business_details"][0];

                if("business_details_members" in self.errors["subscriber_business_details"]) {
                    self.errors["subscriber_business_details"]["business_details_members"] = self.errors["subscriber_business_details"]["business_details_members"];
                } else {
                    self.errors["subscriber_business_details"]["business_details_members"] = {};
                }
            }
        },
        cls_list_css_class: function (obj) {
            var css_class = '';
            if (obj.cls_list === 'CLS_AUTH') {
                css_class = 'table-data__status_active'
            } else if (obj.cls_list === 'CLS_DENY') {
                css_class = 'table-data__status_deactive'
            }

            return css_class
        }
    }
};
