<template>
    <div class="tabs-sc__header" v-has-perm="'subscribers_access'">
        <div class="tabs-sc__title">
            <span class="tabs-sc__action-icon"><i class="fal fa-users-cog"></i></span>
            <span class="tabs-sc__action-title">Лицевой счет</span>
        </div>

        <app-has-perm-action-btn :perms="'subscribers_add_edit'" @action="set_mode_edit">
                <template slot-scope="{ access }">
                    <div class="tabs-sc__action">
                        <span class="tabs-sc__action-icon"><i class="fal fa-user-edit"></i></span>
                        <span class="tabs-sc__action-title">Редактировать</span>
                    </div>
                </template>
        </app-has-perm-action-btn>
    </div>
</template>

<script>

    export default {
        name: "subscribers-connection-edit",
        props: ['subscriber_connection'],
        data: function () {
            return {
                password_visible: false
            }
        },
        methods: {
            set_mode_edit: function () {
                this.$emit('set_mode', 'edit')
            },
            set_password_visible: function (value) {
                this.password_visible = value;
            }
        }
    }
</script>

<style scoped>

</style>