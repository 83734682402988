<template>
    <section class="page">
        <div class="page__inner" v-if="Object.keys(tariff).length">
            <div class="container-width">
                <!--HEADER PAGE-->
                <div class="page__header">
                    <h1 class="page__title">Тариф: ({{ tariff.name }})</h1>
                    <div class="btn-group">
                        <router-link title="Вернуться" :to="{ name: 'tariffs-list'}">
                        <span class="btn btn_grey">
                            <span class="btn__icon"><i class="fal fa-undo"></i></span>
                            <span class="btn__title">Вернуться</span>
                        </span>
                        </router-link>
                        <!-- TODO Добавить ссылку на редактирование текущего item + добавить права на просмотр кнопки -->
                        <router-link title="Редактировать" :to="{ name: 'tariffs-edit'}">
                        <span class="btn btn_orange">
                            <span class="btn__icon btn__icon_nt"><i class="fal fa-edit"></i></span>
                        </span>
                        </router-link>
                    </div>
                </div>
                <!--END HEADER PAGE-->
                <!-- CONTENT PAGE -->
                <div class="page__body">
                    <div class="view">
                        <div class="view__row">
                            <div class="view__section">
                                <!--Системное имя-->
                                <div class="view__item">
                                    <div class="view__label">
                                        <span class="view__label-icon"><i class="fal fa-toolbox"></i></span>
                                        <span class="view__label-title">Системное имя:</span>
                                    </div>
                                    <div class="view__value">
                                        <span class="view__value-text">{{ tariff.system_name }}</span>
                                    </div>
                                </div>
                                <!--Название тарифа-->
                                <div class="view__item">
                                    <div class="view__label">
                                        <span class="view__label-icon"><i class="fal fa-briefcase"></i></span>
                                        <span class="view__label-title">Название:</span>
                                    </div>
                                    <div class="view__value">
                                        <span class="view__value-text">{{ tariff.name }}</span>
                                    </div>
                                </div>
                                <!-- Тип абонента -->
                                <div class="view__item">
                                    <div class="view__label">
                                        <span class="view__label-icon"><i class="fal fa-users-crown"></i></span>
                                        <span class="view__label-title">Тип абонента:</span>
                                    </div>
                                    <div class="view__value">
                                        <span class="view__value-text">{{ tariff.subscriber_type_expand.name_expand }}</span>
                                    </div>
                                </div>
                                <!--Отдел-->
                                <div class="view__item">
                                    <div class="view__label">
                                        <span class="view__label-icon"><i class="fal fa-code-branch"></i></span>
                                        <span class="view__label-title">Отдел:</span>
                                    </div>
                                    <div class="view__value">
                                        <span class="view__value-text" v-if="!tariff.is_common_tariff">{{ tariff.department_expand.name }}</span>
                                        <span class="view__value-text" v-else>ВСЕ ОТДЕЛЫ</span>
                                    </div>
                                </div>
                                <!--Группа-->
                                <div class="view__item">
                                    <div class="view__label">
                                        <span class="view__label-icon"><i class="fal fa-folder"></i></span>
                                        <span class="view__label-title">Группа:</span>
                                    </div>
                                    <div class="view__value">
                                        <span class="view__value-text">{{ tariff.group_expand.name }}</span>
                                    </div>
                                </div>
                                <!--Доступность тарифа абоненту-->
                                <div class="view__item">
                                    <div class="view__label">
                                        <span class="view__label-icon"><i class="fal fa-eye-slash"></i></span>
                                        <span class="view__label-title">Доступ абонента к тарифу:</span>
                                    </div>
                                    <div class="view__value">
                                        <template v-if="tariff.is_hidden_for_subscriber">
                                            <span class="view__value-status view__value-status_deactive" >
                                                Недоступен
                                            </span>
                                        </template>
                                        <template v-else>
                                            <span class="view__value-status view__value-status_active">
                                                Доступен
                                            </span>
                                        </template>
                                    </div>
                                </div>
                                <!--Списывать деньги если была поднята сессия-->
                                <div class="view__item">
                                    <div class="view__label">
                                        <span class="view__label-icon"><i class="fal fa-power-off"></i></span>
                                        <span class="view__label-title">Списывать средства при сессии:</span>
                                    </div>
                                    <div class="view__value">
                                        <template v-if="tariff.withdraw_if_was_session">
                                            <span class="view__value-status view__value-status_active" >
                                                Активно
                                            </span>
                                        </template>
                                        <template v-else>
                                            <span class="view__value-status view__value-status_deactive">
                                                Неактивно
                                            </span>
                                        </template>
                                    </div>
                                </div>
                            </div>
                            <div class="view__section">
                                <!--Технология-->
                                <div class="view__item">
                                    <div class="view__label">
                                        <span class="view__label-icon"><i class="fal fa-ethernet"></i></span>
                                        <span class="view__label-title">Технология:</span>
                                    </div>
                                    <div class="view__value">
                                        <span class="view__value-text">
                                            <template v-if="tariff.connection_type_expand">
                                                 <span v-for="(connection_type, index) in tariff.connection_type_expand" >
                                                     {{ connection_type.label }}<template v-if="index + 1 !== tariff.connection_type_expand.length">, </template>
                                                 </span>
                                            </template>
                                        </span>
                                    </div>
                                </div>
                                <!--Тарификация-->
                                <div class="view__item">
                                    <div class="view__label">
                                        <span class="view__label-icon"><i class="fal fa-calendar-alt"></i></span>
                                        <span class="view__label-title">Тарификация:</span>
                                    </div>
                                    <div class="view__value">
                                        <span class="view__value-text">{{ tariff.charging_type_expand }}</span>
                                    </div>
                                </div>
                                <!--Стоимость-->
                                <div class="view__item">
                                    <div class="view__label">
                                        <span class="view__label-icon"><i class="fal fa-wallet"></i></span>
                                        <span class="view__label-title">Стоимость:</span>
                                    </div>
                                    <div class="view__value">
                                        <tariffs-charging-type :item="tariff"></tariffs-charging-type>
                                    </div>
                                </div>
                                <!--Скорость-->
                                <div class="view__item">
                                    <div class="view__label">
                                        <span class="view__label-icon"><i class="fal fa-tachometer-alt-fastest"></i></span>
                                        <span class="view__label-title">Скорость (Kbit/s):</span>
                                    </div>
                                    <div class="view__value">
                                        <div class="view__speed">
                                            <span class="view__speed-item">
                                                <span class="view__speed-icon"><i class="fal fa-cloud-download"></i></span>
                                                <span class="view__speed-value">{{ tariff.speed_tx }}</span>
                                            </span>
                                            <span class="view__speed-divide"><i class="fal fa-arrows-h"></i></span>
                                            <span class="view__speed-item">
                                                <span class="view__speed-icon"><i class="fal fa-cloud-upload"></i> </span>
                                                <span class="view__speed-value">{{ tariff.speed_rx }}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <!--Ускорение-->
                                <div class="view__item">
                                    <div class="view__label">
                                        <span class="view__label-icon"><i class="fal fa-rocket"></i></span>
                                        <span class="view__label-title">Burst (Kbit/s):</span>
                                    </div>
                                    <div class="view__value">
                                        <div class="view__speed">
                                            <span class="view__speed-item">
                                                <span class="view__speed-icon"><i class="fal fa-cloud-download"></i></span>
                                                <span class="view__speed-value">{{ tariff.burst_speed_tx }}</span>
                                            </span>
                                            <span class="view__speed-divide"><i class="fal fa-arrows-h"></i></span>
                                            <span class="view__speed-item">
                                                <span class="view__speed-icon"><i class="fal fa-cloud-upload"></i> </span>
                                                <span class="view__speed-value">{{ tariff.burst_speed_rx }}</span>
                                            </span>
                                            <span class="view__speed-divide"><i class="fal fa-arrows-h"></i></span>
                                            <span class="view__speed-item">
                                                <span class="view__speed-icon"><i class="fal fa-stopwatch"></i></span>
                                                <span class="view__speed-value">{{ tariff.burst_time }}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <!--Лимит трафика-->
                                <div class="view__item">
                                    <div class="view__label">
                                        <span class="view__label-icon"><i class="fal fa-hourglass-half"></i></span>
                                        <span class="view__label-title">Лимит трафика (Kbyte):</span>
                                    </div>
                                    <div class="view__value">
                                        <div class="view__speed">
                                            <span class="view__speed-item">
                                                <span class="view__speed-icon"><i class="fal fa-cloud-download"></i></span>
                                                <span class="view__speed-value">{{ tariff.traffic_limit_tx }}</span>
                                            </span>
                                            <span class="view__speed-divide"><i class="fal fa-arrows-h"></i></span>
                                            <span class="view__speed-item">
                                                <span class="view__speed-icon"><i class="fal fa-cloud-upload"></i> </span>
                                                <span class="view__speed-value">{{ tariff.traffic_limit_rx }}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <RegionsView :tariff="tariff" ></RegionsView>
                </div>
                <!-- END CONTENT PAGE -->
            </div>
        </div>
    </section>
</template>

<script>

    import {TariffMixin} from "../../../../mixins/admin/tariff";
    import {User} from "../../../../mixins/admin/user";
    import TariffsChargingType from './charging_type/index';
    import RegionsView from './regions/regions-view';

    export default {
        name: "tariff-view",
        mixins: [User, TariffMixin],
        components: {
            'tariffs-charging-type': TariffsChargingType,
            RegionsView
        },
        data: function () {
            return {
                id: null,
                tariff: {}
            }
        },
        mounted() {
            this.fetchUser();
        },
        methods: {
            fetchUser() {
                if (this.has_perm('catalog_tariffs_access')) {
                    if (this.$route.params.id) {
                        this.id = this.$route.params.id;
                        this.get_tariff(this.id);
                    }
                }
            }
        }

    }
</script>

<style scoped>

</style>