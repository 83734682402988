import axios from 'axios';
import {loader} from './models/loader';
import {alert_popup} from './models/alert_popup';
import {API} from "./api";
import Vue from "vue";

export default function setup() {

    axios.interceptors.request.use((config) => {
        if(loader.active){
            loader.is_show = true;
        }

        return config;
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    });

    axios.interceptors.response.use(function (response) {
        loader.is_show = false;
        return response;
    }, function (err) {
        loader.is_show = false;
        // console.log(err.response);

        if (err.response.status >= 500) {
            alert_popup.show("Ошибка сервера", "Произошла ошибка на сервере, обратитесь к администратору");
            return
        }

        if (err.response.status === 403) {
            var errors = API.convert_errors(API.get_errors(err.response.data.errors));
            alert_popup.show("Ошибка прав доступа", errors.non_field_errors[0]);
            return
        }

        if (err.response.status === 401) {
            if (window.location.pathname !== '/') {
                window.location = '/';
            } else {
                alert_popup.show("Ошибка авторизации", "Выполните повторный вход в систему");
            }
            return
        }
        return Promise.reject(err);
    });

}