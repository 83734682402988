<template>
    <span>{{ obj.created_at | moment("DD/MM/YYYY hh:mm") }}<br>{{ obj.updated_at | moment("DD/MM/YYYY hh:mm")}}</span>
</template>

<script>
    export default {
        name: "field_created_modify",
        props: ["obj"]
    }
</script>

<style scoped>

</style>