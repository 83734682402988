<template>
    <section class="page">
        <div class="page__inner">
            <div class="container-width">
                <!--HEADER PAGE-->
                <div class="page__header">
                    <h1 class="page__title">Отчет {{ type_report }}</h1>
                    <div class="btn-group">
                        <span class="btn btn_grey">
                            <span class="btn__icon"><i class="fal fa-undo"></i></span>
                            <span class="btn__title">Вернуться</span>
                        </span>
                    </div>
                </div>
                <!-- END HEADER PAGE -->
                <!-- BODY PAGE -->
                <div class="page__body">
                    <div class="table-box">
                        <div class="table-box__header">
                            <div class="table-box__header-block">
                                <template v-if="subscriber_connection">
                                    <div class="title">Петров Иван Иванович {{ subscriber_connection }}</div>
                                </template>
                                <ul class="view-data-reports__header-list">
                                    <template v-if="type_report">
                                        <li>Тип платежа: <span class="value">{{ type_report }}</span></li>
                                    </template>
                                    <li v-if="month && year">Месяц: <span class="value">{{ get_month_by_digit(month) }} {{ year }}</span>
                                    </li>
                                    <li v-if="date_lte && date_gte">Диапазон: <span class="value">{{ date_gte }} - {{ date_lte }}</span>
                                    </li>
                                    <li>Сумма: <span class="value">{{ total_amount }} грн</span></li>
                                </ul>
                            </div>
                        </div>
                        <table class="table-box__table">
                            <thead class="table-box__thead">
                            <tr class="table-box__thead-tr">
                                <template v-for="col in cols">
                                <th @click="set_ordering(col.field)" class="table-box__th">
                                    <span class="table-box__thead-item table-box__thead-item_center">
                                        <span class="table-box__thead-title"
                                              :class="[get_class_ordering_direction(col.field)]">
                                            {{ col.label }}
                                            <i class="fal" :class="[get_class_ordering_direction(col.field)]"></i>
                                        </span>
                                    </span>
                                </th>
                                </template>
                            </tr>
                            </thead>
                            <tbody class="table-box__tbody">
                                <tr class="table-box__tr table-data" v-for="item in transactions">
                                    <td class="table-box__td table-box__td_center">
                                        <span class="table-data__text">
                                            {{ item.date | moment("DD.MM.YYYY HH:mm") }}
                                        </span>
                                    </td>
                                     <td class="table-box__td table-box__td_center">
                                        <router-link class="table-data__link" v-if="has_perm('subscribers_access')"
                                                     :to="{ name: 'subscribers-connections-item', params: {'id': item.subscriber_connection}}">
                                            {{ item.subscriber_connection_expand.username }}
                                        </router-link>
                                         <span v-else>
                                            {{ item.subscriber_connection_expand.username }}
                                         </span>
                                    </td>
                                     <td class="table-box__td table-box__td_center">
                                        <span class="table-data__currency">₴</span>
                                        <span class="table-data__text">{{ item.amount }}</span>
                                    </td>
                                     <td class="table-box__td table-box__td_center">
                                        <span class="table-data__text">
                                            <template v-if="item.user">
                                                {{ item.user_expand.fio }}
                                            </template>
                                            <template v-else>
                                                {{ item.user_system }}
                                            </template>
                                        </span>
                                    </td>
                                     <td class="table-box__td table-box__td_center">
                                        <span class="table-data__currency">₴</span>
                                        <span class="table-data__text">{{ item.balance_before }}</span>
                                    </td>
                                    <td class="table-box__td table-box__td_center">
                                        <span class="table-data__currency">₴</span>
                                        <span class="table-data__text">{{ item.balance_after }}</span>
                                    </td>
                                    <td class="table-box__td">
                                        <span class="table-data__text">
                                            {{ item.transaction_note }}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!--Pagination block-->
                    <Pagination @loadItems="load_transactions"
                                :options="limitOptions"
                                :limit-items="limit"
                                :page-items="page"
                                :count-items="count"
                                :add-null-option="true" />
                    <!-- END Pagination block-->
                </div>
                <!-- END BODY PAGE -->
            </div>
        </div>
    </section>
</template>

<script>
    import ReportMenu from '../sub_components/menu.vue';
    import {API} from "../../../../../api";
    import {OrderMixin} from "../../../../../mixins/admin/order";
    import {RouteHelperMixin} from "../../../../../mixins/admin/route_helper";
    import {PaginationMixin} from "../../../../../mixins/admin/pagination";
    import VueNumeric from 'vue-numeric';
    import Options from "../../tariffs/options";
    import Pagination from "../../pagination/pagination";

    export default {
        name: "Replenishment-list",
        components: {
            Options,
            "report-menu": ReportMenu,
            VueNumeric,
            Pagination,
        },
        mixins: [OrderMixin, RouteHelperMixin, PaginationMixin],
        data() {
            return {
                cols: [
                    {
                        field: 'date',
                        label: 'Дата и время'
                    },
                    {
                        field: 'subscriber_connection',
                        label: 'LOGIN'
                    },
                    {
                        field: 'amount',
                        label: 'Сумма'
                    },
                    {
                        field: 'user_expand',
                        label: 'Менеджер'
                    },
                    {
                        field: 'balance_before',
                        label: 'Баланс до операции'
                    },
                    {
                        field: 'balance_after',
                        label: 'Текущий баланс'
                    },
                    {
                        field: 'transaction_note',
                        label: 'Способ пополнения'
                    }
                ],
                total_amount: 0,
                year: undefined, // this.$moment().year(),
                month: undefined, // this.$moment().month(),
                date_gte: undefined,
                date_lte: undefined,
                type: undefined,
                subscriber_connection: undefined,
                subscriber_connection__department: undefined,
                ordering: undefined,
                ordering_direction: 'ASC',
                is_charge_types: false,
                is_cash_types: false,
                is_cashless_types: false,
                unicard_sold_in_department: undefined,
                unicard_sold_in_department_exclude: undefined,
                unicard_used_in_department: undefined,
                unicard_used_in_department_exclude: undefined,
                unicard_used_with_shop_agent_id: undefined,
                unicard_face_value: undefined,
                unicard_sold_used_in_department: undefined,
                report_department: undefined,
                report_department_simple: undefined,
                subscriber_type_name: undefined,
                report_department_only_department: undefined,
                report_department_simple_only_department: undefined,
                user_id: undefined,
                transactions: [],
                from_url: undefined,
                errors: [],
                is_replenishment: false,
                nas: undefined,
                settlement: undefined,
                limitOptions: [100, 500],
                limit: 100,
                page: 1,
                count: 0,
            }
        },
        computed: {
            type_report() {
                if (this.type === "charge_balance") {
                    return "РУЧНОЕ ПОПОЛНЕНИЕ"
                } else if (this.type === "charge_balance_cashless") {
                    return "РУЧНОЙ БЕЗНАЛ"
                } else if (this.type === "set_balance") {
                    return "SET"
                } else if (this.type === "bonus_balance") {
                    return "Программа лояльности"
                } else if (this.type === "charge_with_easy_pay") {
                    return "EasyPay"
                } else if (this.type === "charge_with_privat24") {
                    return "Приват24"
                } else if (this.type === "charge_with_liqpay") {
                    return "LiqPay"
                } else if (this.type === "charge_with_family_bank") {
                    return "Банк Фамильный"
                } else if (this.type === "recalculate_days") {
                    return "ПЕРЕРАСЧЁТ"
                    // custom type if common type of report
                } else if (this.is_charge_types === true) {
                    return "СУММА ВСЕГО"
                } else if (this.is_cash_types === true) {
                    return "ИТОГО НАЛИЧНЫЕ"
                } else if (this.is_cashless_types === true) {
                    return "ИТОГО БЕЗНАЛИЧНЫЕ"
                }
                return ""
            }
        },
        created() {
            this.set_data_from_route([
                {'name': 'subscriber_connection', 'type': 'int'},
                {'name': 'type', 'type': 'str'},
                {'name': 'amount', 'type': 'str'},
                {'name': 'date_gte', 'type': 'str'},
                {'name': 'date_lte', 'type': 'str'},
                {'name': 'year', 'type': 'int'},
                {'name': 'month', 'type': 'int'},
                {'name': 'is_charge_types', 'type': 'str'},
                {'name': 'is_cash_types', 'type': 'str'},
                {'name': 'is_cashless_types', 'type': 'str'},
                {'name': 'unicard_sold_in_department', 'type': 'str'},
                {'name': 'unicard_sold_in_department_exclude', 'type': 'str'},
                {'name': 'unicard_used_in_department', 'type': 'str'},
                {'name': 'unicard_used_in_department_exclude', 'type': 'str'},
                {'name': 'unicard_used_with_shop_agent_id', 'type': 'str'},
                {'name': 'unicard_face_value', 'type': 'str'},
                {'name': 'subscriber_connection__department', 'type': 'str'},
                {'name': 'unicard_sold_used_in_department', 'type': 'str'},
                {'name': 'report_department', 'type': 'str'},
                {'name': 'report_department_simple', 'type': 'str'},
                {'name': 'subscriber_type_name', 'type': 'str'},
                {'name': 'ordering_direction', 'type': 'str'},
                {'name': 'report_department_only_department', 'type': 'str'},
                {'name': 'report_department_simple_only_department', 'type': 'str'},
                {'name': 'user_id', 'type': 'str'},
                {'name': 'ordering', 'type': 'str'},
                {'name': 'from_url', 'type': 'str'},
                {'name': 'is_replenishment', 'type': 'str'},
                {'name': 'nas', 'type': 'str'},
                {'name': 'settlement', 'type': 'str'},
            ], this);

            this.pagination_init(this.page, this.limit);
            this.loader_show();
            this.load_transactions();
        },
        methods: {
            load_transactions() {
                var self = this;

                // pagination
                var filter = Object.assign({}, self.$route.query,
                    {
                        page: !self.$route.query.page ? self.page : self.$route.query.page,
                        limit: !self.$route.query.limit ? self.limit : self.$route.query.limit,
                        offset: !self.$route.query.offset ? self.get_offset(self.page) : self.$route.query.offset,
                    });

                filter = self.setOrderingToParams(filter);
                filter['year'] = this.year;
                filter['type'] = this.type;
                filter['month'] = this.month;
                filter['subscriber_connection'] = this.subscriber_connection;
                filter['subscriber_connection__department'] = this.subscriber_connection__department;
                filter['date_gte'] = this.date_gte;
                filter['date_lte'] = this.date_lte;
                filter['unicard_sold_in_department'] = this.unicard_sold_in_department;
                filter['unicard_sold_in_department_exclude'] = this.unicard_sold_in_department_exclude;
                filter['unicard_used_in_department'] = this.unicard_used_in_department;
                filter['unicard_used_in_department_exclude'] = this.unicard_used_in_department_exclude;
                filter['unicard_used_with_shop_agent_id'] = this.unicard_used_with_shop_agent_id;
                filter['unicard_face_value'] = this.unicard_face_value;
                filter['unicard_sold_used_in_department'] = this.unicard_sold_used_in_department;
                filter['report_department'] = this.report_department;
                filter['report_department_simple'] = this.report_department_simple;
                filter['subscriber_type_name'] = this.subscriber_type_name;
                // need attach uuid field not str
                // filter['report_department_only_department'] = this.subscriber_type_name;
                // filter['report_department_simple_only_department'] = this.subscriber_type_name;
                filter['user_id'] = this.user_id;
                filter['is_replenishment'] = this.is_replenishment;
                filter['nas'] = this.nas;
                filter['settlement'] = this.settlement;

                if (this.is_charge_types) {
                    filter['is_charge_types'] = this.is_charge_types;
                }

                if (this.is_cash_types) {
                    filter['is_cash_types'] = this.is_cash_types;
                }

                if (this.is_cashless_types) {
                    filter['is_cashless_types'] = this.is_cashless_types;
                }

                API.get('/subscriber-connections-transactions/', filter)
                    .then(function (response) {
                        self.transactions = response.data.results;
                        self.count = response.data.count;
                        // Update router
                        self.$router.replace({
                            query: Object.assign({}, self.$route.query, {
                                year: self.year,
                                month: self.month,
                            }),
                        });

                        // update route ordering params
                        self.updateRouteQueryOrderingParams();
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })

                API.get('/subscriber-connections-transactions/total-amount/', filter)
                    .then(function (response) {
                        self.total_amount = response.data.total_amount;
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            },
            go_back_link() {
                if (this.from_url) {
                    return {path: this.from_url}
                }
                return {name: 'reports-page'}
            },
        },
        watch: {
            // year(value) {
            //     this.load_transactions();
            // },
            orderingAndOrderingName(value) {
                this.load_transactions();
            }
        }
    }
</script>

<style scoped>

</style>