<template>
    <popup :settings="settings">
        <div slot="title">
             <template v-if="crud.data.item.id">
                 Редактирование региона
             </template>
            <template v-else>
                Добавление региона
            </template>
        </div>
        <div slot="content">
            <div class="body-size">
                <div class="body-size__650">
                    <form class="form-modal">
                        <div class="form-modal__group">
                            <fieldset class="form-modal__item">
                                <span class="form-modal__title">
                                <span class="form-modal__icon"><i class="fal fa-globe-europe"></i></span>
                                    Название региона
                                </span>
                                <input v-model="crud.data.item.name" type="text" class="form-modal__input" placeholder="Название региона">
                                <form-error v-bind:value="crud.errors.name"></form-error>
                            </fieldset>
                        <fieldset class="form-modal__item form-modal__item_switch">
                        <span class="form-modal__title">
                        <span class="form-modal__icon"><i class="fal fa-check-circle"></i></span>
                        Статус
                        </span>
                                <input type="checkbox" class="form-modal__input form-modal__input_switch" v-model="crud.data.item.is_active" id="switch-lk">
                                <label class="form-modal__switch-for" for="switch-lk"></label>
                            </fieldset>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="btn-group">
                <span class="btn btn_grey" @click="popup_close">
                    <span class="btn__icon"><i class="fal fa-times-circle"></i></span>
                    <span class="btn__title">Отмена</span>
                </span>
                <span class="btn btn_blue" @click="save">
                    <span class="btn__icon"><i class="fal fa-save"></i></span>
                    <span class="btn__title">
                        <template v-if="is_edit()">Изменить</template><template v-else >Добавить</template>
                    </span>
                </span>
            </div>
        </div>
    </popup>
</template>

<script>
    import Popup from '../../modal/popup.vue';
    import FormErrors from '../../form/errors.vue';
    import {CrudMixin} from "../../../../../mixins/admin/crud/crud_base";

    export default {
        name: "add-region",
        components: {
            "popup": Popup,
            "form-error": FormErrors,
        },
        mixins: [CrudMixin],
        props: ["settings", "region"],
        data: function () {
            return {
                crud: {
                    api: '/address/region/',
                    data: {
                        item: {
                            id: "",
                            name: "",
                            is_active: false
                        }
                    },
                    errors: {}
                },
            }
        },
        methods: {
            popup_close() {
                this.settings.visible = false;
                this.$emit("update_list");
            },
            save(){
                if(this.is_edit()) {
                    this.crud_edit(this.crud.data.item.id, this.crud.data.item);
                } else {
                    this.crud_create(this.crud.data.item);
                }
            },
            is_edit(){
                return ((this.crud.data.item.id !== "") && (this.crud.data.item.id !== undefined))
            }
        },
        watch: {
            region() {
                this.crud.data.item = Object.assign({}, this.region);
            }
        }
    }
</script>

<style scoped>

</style>