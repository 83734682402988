<template>
    <section class="page">
        <div class="page__inner">
            <div class="container-width">
                <div class="row">
                    <!--HEADER PAGE-->
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="page-header">
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                                    <h1 class="page-header__title">Справочники</h1>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                    <div class="page-header__btn-group"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--END HEADER PAGE-->
                    <!--ITEMS PAGE-->
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" v-if="Object.keys(catalogs).length">
                        <div class="page-items">
                            <div class="row">
                                <!-- Groups -->
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4" v-if="has_perm(perm['groups'])">
                                    <router-link title="Группы абонентов" :to="{ name: 'groups-list'}">
                                        <div class="page-items__item">
                                            <div class="icon"><i class="fal fa-folder"></i></div>
                                            <div class="info">
                                                <h3 class="info__title">{{ catalogs.group.object_name }}</h3>
                                                <p class="info__desc">{{catalogs.group.object_description}}</p>
                                            </div>
                                            <div class="count">{{ catalogs.group.count }}</div>
                                        </div>
                                    </router-link>
                                </div>
                                <!-- End Groups -->
                                <!-- Tariffs -->
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4" v-if="has_perm(perm['tariffs'])">
                                    <router-link title="Тарифы" :to="{ name: 'tariffs-list'}">
                                        <div class="page-items__item">
                                            <div class="icon"><i class="fal fa-briefcase"></i></div>
                                            <div class="info">
                                                <h3 class="info__title">{{ catalogs.tariff.object_name }}</h3>
                                                <p class="info__desc">{{catalogs.tariff.object_description}}</p>
                                            </div>
                                            <div class="count">{{ catalogs.tariff.count }}</div>
                                        </div>
                                    </router-link>
                                </div>
                                <!-- End Tariffs -->
                                <!-- NAS -->
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4" v-if="has_perm(perm['nas'])">
                                    <router-link title="NAS" :to="{ name: 'nas-list'}">
                                        <div class="page-items__item">
                                            <div class="icon"><i class="fal fa-server"></i></div>
                                            <div class="info">
                                                <h3 class="info__title">{{ catalogs.nas.object_name }}</h3>
                                                <p class="info__desc">{{catalogs.nas.object_description}}</p>
                                            </div>
                                            <div class="count">{{ catalogs.nas.count }}</div>
                                        </div>
                                    </router-link>
                                </div>
                                <!-- End NAS -->
                                <!-- IP-list -->
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4" v-if="has_perm(perm['ipaddress'])">
                                    <router-link title="IP List" :to="{ name: 'ipaddress-list'}">
                                        <div class="page-items__item">
                                            <div class="icon"><i class="fal fa-network-wired"></i></div>
                                            <div class="info">
                                                <h3 class="info__title">{{ catalogs.ip_address.object_name }}</h3>
                                                <p class="info__desc">{{catalogs.ip_address.object_description}}</p>
                                            </div>
                                            <div class="count">{{ catalogs.ip_address.count }}</div>
                                        </div>
                                    </router-link>
                                </div>
                                <!-- End IP-list -->
                                <!-- MAC-address -->
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4" v-if="has_perm(perm['mac'])">
                                    <router-link title="IP List" :to="{ name: 'macaddress-list'}">
                                        <div class="page-items__item">
                                            <div class="icon"><i class="fal fa-phone-laptop"></i></div>
                                            <div class="info">
                                                <h3 class="info__title">{{ catalogs.mac_address.object_name }}</h3>
                                                <p class="info__desc">{{catalogs.mac_address.object_description}}</p>
                                            </div>
                                            <div class="count">{{ catalogs.mac_address.count }}</div>
                                        </div>
                                    </router-link>
                                </div>
                                <!-- End MAC-address -->
                                <!-- Departaments -->
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4" v-if="has_perm(perm['departments'])">
                                    <router-link title="Отделы" :to="{ name: 'departments-list'}">
                                        <div class="page-items__item">
                                            <div class="icon"><i class="fal fa-code-branch"></i></div>
                                            <div class="info">
                                                <h3 class="info__title">{{ catalogs.department.object_name }}</h3>
                                                <p class="info__desc">{{catalogs.department.object_description}}</p>
                                            </div>
                                            <div class="count">{{ catalogs.department.count }}</div>
                                        </div>
                                    </router-link>
                                </div>
                                <!-- End Departaments -->
                                <!-- Employees -->
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4" v-if="has_perm(perm['employees'])">
                                    <router-link title="Сотрудники" :to="{ name: 'employees-list'}">
                                        <div class="page-items__item">
                                            <div class="icon"><i class="fal fa-user-tie"></i></div>
                                            <div class="info">
                                                <h3 class="info__title">{{ catalogs.employee.object_name }}</h3>
                                                <p class="info__desc">{{catalogs.employee.object_description}}</p>
                                            </div>
                                            <div class="count">{{ catalogs.employee.count }}</div>
                                        </div>
                                    </router-link>
                                </div>
                                <!-- End Employees -->
                                <!-- Address -->
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4" v-if="has_perm(perm['settlements'])">
                                    <router-link title="Населенные пункты" :to="{ name: 'addresses-list'}">
                                        <div class="page-items__item">
                                            <div class="icon"><i class="fal fa-map-marked-alt"></i></div>
                                            <div class="info">
                                                <h3 class="info__title">{{ catalogs.address.object_name }}</h3>
                                                <p class="info__desc">{{catalogs.address.object_description}}</p>
                                            </div>
                                            <div class="count">{{ catalogs.address.count }}</div>
                                        </div>
                                    </router-link>
                                </div>
                                <!-- End Address -->
                                <!--Connection types-->
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4" v-if="has_perm(perm['connection_types_list'])">
                                    <router-link title="Типы подключения" :to="{ name: 'connection_types-list'}">
                                        <div class="page-items__item">
                                            <div class="icon"><i class="fal fa-wifi"></i></div>
                                            <div class="info">
                                                <h3 class="info__title">{{ catalogs.connection_type.object_name }}</h3>
                                                <p class="info__desc">{{catalogs.connection_type.object_description}}</p>
                                            </div>
                                            <div class="count">{{ catalogs.connection_type.count }}</div>
                                        </div>
                                    </router-link>
                                </div>
                                <!--End Connection types-->
                            </div>
                        </div>
                    </div>
                    <!--END ITEMS PAGE-->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import {API} from "../../../../api";

    export default {
        name: "catalogs",
        data: function () {
            return {
                perm: {
                    'groups': {
                        permissions: 'catalog_subscriber_groups_access',
                        comparison: 'OR'
                    },
                    'tariffs': {
                        permissions: 'catalog_tariffs_access',
                        comparison: 'OR'
                    },
                    'nas': {
                        permissions: 'catalog_nas_access',
                        comparison: 'OR'
                    },
                    'ipaddress': {
                        permissions: 'catalog_ip_address_access',
                        comparison: 'OR'
                    },
                    'mac': {
                        permissions: 'catalog_mac_address_access',
                        comparison: 'OR'
                    },
                    'departments': {
                        permissions: 'catalog_departments_access',
                        comparison: 'OR'
                    },
                    'employees': {
                        permissions: 'catalog_employees_access,catalog_employee_positions_access',
                        comparison: 'OR'
                    },
                    'connection_types_list': {
                        permissions: 'catalog_connection_types_access',
                        comparison: 'OR'
                    },
                    'settlements': {
                        permissions: 'catalog_settlements_access',
                        comparison: 'OR'
                    }
                },
                catalogs: {},
                errors: {}
            }
        },
        mounted: function () {
            this.fetchUser()
        },
        methods: {
            fetchUser() {
                this.load_catalogs()
            },
            load_catalogs: function () {
                var self = this;

                self.loader_show();
                API.get('/catalogs/all/')
                    .then(function (response) {
                        for(var item of response.data) {
                            // self.services[item.object_type] = item;
                            self.$set(self.catalogs, item.object_type, item);
                        }
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            }
        },
    }
</script>

<style scoped>

</style>