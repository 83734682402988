<template>
  <section class="page">
    <div class="page__inner">
      <div class="container-width">
        <!--HEADER PAGE-->
        <div class="page__header">
          <h1 class="page__title">Отчет по услугам</h1>
          <report-menu :active_name="'reports-page'"></report-menu>
        </div>
        <!--END HEADER PAGE-->
        <!--BODY PAGE -->
        <div class="page__body">
          <div class="table-reports">
            <table class="table-reports__table">
              <thead class="table-reports__thead">
              <tr class="table-reports__thead-tr table-data">
                <th class="table-subscribers__th">
                  <span class="table-box__thead-item table-box__thead-item_center">
                    <span class="table-box__thead-icon"></span>
                    <span class="table-box__thead-title">Пакет</span>
                  </span>
                </th>
                <th class="table-subscribers__th">
                  <span class="table-box__thead-item table-box__thead-item_center">
                    <span class="table-box__thead-icon"></span>
                    <span class="table-box__thead-title">Параметры</span>
                  </span>
                </th>
                <th class="table-subscribers__th">
                  <span class="table-box__thead-item table-box__thead-item_center">
                    <span class="table-box__thead-icon"></span>
                    <span class="table-box__thead-title">Кол-во активных мес</span>
                  </span>
                </th>
                <th class="table-subscribers__th">
                  <span class="table-box__thead-item table-box__thead-item_center">
                    <span class="table-box__thead-icon"></span>
                    <span class="table-box__thead-title">Кол-во подключений мес</span>
                  </span>
                </th>
                <th class="table-subscribers__th">
                  <span class="table-box__thead-item table-box__thead-item_center">
                    <span class="table-box__thead-icon"></span>
                    <span class="table-box__thead-title">Кол-во активных устройства</span>
                  </span>
                </th>
              </tr>
              </thead>
              <tbody class="table-subscribers__tbody">
              <tr class="table-subscribers__tr table-data">
                <td class="table-subscribers__td" rowspan="3">
                    Пакет 1
                </td>
                <td class="table-subscribers__td">
                    <span class="table-data__text">
                      Отдел
                    </span>
                </td>
                <td class="table-subscribers__td">
                    <span class="table-data__text">
                      1
                    </span>
                </td>
                <td class="table-subscribers__td">
                    <span class="table-data__text">
                      2
                    </span>
                </td>
                <td class="table-subscribers__td">
                    <span class="table-data__text">
                      3
                    </span>
                </td>
              </tr>
              <tr class="table-subscribers__tr table-data">
                <td class="table-subscribers__td">
                    <span class="table-data__text">
                      Бандл
                    </span>
                </td>
                <td class="table-subscribers__td">
                    <span class="table-data__text">
                      1
                    </span>
                </td>
                <td class="table-subscribers__td">
                    <span class="table-data__text">
                      2
                    </span>
                </td>
                <td class="table-subscribers__td">
                    <span class="table-data__text">
                      3
                    </span>
                </td>
              </tr>
              <tr class="table-subscribers__tr table-data">
                <td class="table-subscribers__td">
                    <span class="table-data__text">
                      Итого
                    </span>
                </td>
                <td class="table-subscribers__td">
                    <span class="table-data__text">
                      1
                    </span>
                </td>
                <td class="table-subscribers__td">
                    <span class="table-data__text">
                      2
                    </span>
                </td>
                <td class="table-subscribers__td">
                    <span class="table-data__text">
                      3
                    </span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- END BODY PAGE -->
      </div>
    </div>
  </section>
</template>

<script>
import ReportMenu from "./menu/menu";

export default {
  name: "services",

  components: {
    "report-menu": ReportMenu
  },
}
</script>

<style scoped>

</style>