import {pluralize} from "../../helpers/pluralize";

export var SubscriberConnections = {
    methods: {
        get_address(subscriber_connection){
            if(!Object.keys(subscriber_connection).length ||
                subscriber_connection.settlement_expand === undefined ||
                subscriber_connection.settlement_expand === null){
                return "-"
            }

            let apartment_number =  subscriber_connection.apartment_number ? ", кв. " + subscriber_connection.apartment_number : ""
            return subscriber_connection.settlement_expand.district_expand.region_expand.name + ", " +
                   subscriber_connection.settlement_expand.district_expand.name + ", " +
                   subscriber_connection.settlement_expand.name + ", " +
                   subscriber_connection.street_expand.name + ", " +
                   subscriber_connection.house_number + apartment_number

        },
        get_balance_days(subscriber_connection){
                var given = this.$moment(subscriber_connection.balance_positive_till_date, "DD.MM.YYYY");
                var current = this.$moment();
                var days = given.diff(current, 'days');

                return days + " " + pluralize(days, ['день', 'дня', 'дней'])
        },
        is_status_active(subscriber_connection){
            return subscriber_connection.cls_list === 'CLS_AUTH'
        }
    }
}

