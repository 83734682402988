<template>
    <popup :settings="settings">
        <div slot="title">Устройства</div>
        <div slot="content">
            <div class="body-size">
                <div class="body-size__980">
                    <div class="modal-value">
                        <div class="modal-value__item">
                            <div class="modal-value__params">
                                <span class="modal-value__params-title">Кол-во устройств:</span>
                                <span class="modal-value__params-value">{{ service_tv.devices.length }}/{{ count_payment_devices }}</span>
                            </div>
                        </div>
                    </div>
                    <form-error v-bind:value="errors.note"></form-error>
                    <!--DELETE DEVICE ALERT-->
                    <device-alert :settings="view_delete_alert"
                                  @cancel_action="close_delete_device_alert"
                                  @confirm_action="delete_device">
                        <template slot="title">Удалить устройство?</template>
                        <template v-if="delete_device_obj"
                                  slot="main">
                            Вы уверены, что хотите устройство - <b> {{ delete_device_obj.device_id }}</b>?
                        </template>
                    </device-alert>
                    <!--ADDED BOARDING DEVICE ALERT-->
                    <device-alert :settings="view_add_bordering_device_alert"
                                  @cancel_action="close_bordering_device_alert"
                                  @confirm_action="add_device">
                        <template slot="title">
                            Вы уверены, что желаете добавить устройство?
                        </template>
                        <template slot="main">
                            При добавлении этого устройства Вам будет надан такой же пакет услуг, по полной его стоимости.
                        </template>
                    </device-alert>
                    <div class="table-modal">
                        <table class="table-modal__table">
                            <thead class="table-modal__thead">
                            <tr class="table-modal__tr">
                                <th class="table-modal__th">
                                    <span class="table-modal__thead-item table-modal__thead-item_center">
                                        <span class="table-modal__thead-title">Дата вкл.</span>
                                    </span>
                                </th>
                                <th class="table-modal__th">
                                    <span class="table-modal__thead-item table-modal__thead-item_center">
                                        <span class="table-modal__thead-title">Название</span>
                                    </span>
                                </th>
                                <th class="table-modal__th">
                                    <span class="table-modal__thead-item table-modal__thead-item_center">
                                        <span class="table-modal__thead-title">MAC</span>
                                    </span>
                                </th>
                                <th class="table-modal__th">
                                    <span class="table-modal__thead-item table-modal__thead-item_center">
                                        <span class="table-modal__thead-title">ID устройства</span>
                                    </span>
                                </th>
                                <th class="table-modal__th">
                                    <span class="table-modal__thead-item table-modal__thead-item_center">
                                        <span class="table-modal__thead-title">UUID</span>
                                    </span>
                                </th>
                                <th class="table-modal__th">
                                    <span class="table-modal__thead-item table-modal__thead-item_center">
                                        <span class="table-modal__thead-title">Vendor</span>
                                    </span>
                                </th>

                                <th class="table-modal__th">
                                    <span class="table-modal__thead-item table-modal__thead-item_center">
                                        <span class="table-modal__thead-title"> </span>
                                    </span>
                                </th>
                            </tr>
                            </thead>
                            <tbody class="table-modal__tbody">
                            <template v-for="(payment_unit, index) in devicePaymentUnits">
                                <tr>
                                    <td>
                                        Пакет {{ index + 1 }}
                                    </td>
                                    <td>
                                        Стоимость: {{ defineCost(payment_unit.payment) }}
                                    </td>
                                    <td>
                                        Устройств: <span v-if="payment_unit.devices">{{ payment_unit.devices.length }}</span>
                                        <span v-else>0</span>/{{ service_tv.count_devices }}
                                    </td>
                                </tr>
                                <tr v-for="(device, index) in payment_unit.devices" class="table-modal__tr table-data">
                                    <td class="table-modal__td table-modal__td_center">
                                        <span class="table-data__text">{{ device.created_at | moment("DD/MM/YYYY") }}</span>
                                    </td>
                                    <td class="table-modal__td table-modal__td_center">
                                        <template v-if="edit_line_id !== device.id">
                                            <span class="table-data__text">{{ device.note }}</span>
                                        </template>
                                        <template v-else>
                                            <input type="text" v-model="name" placeholder="Название устройства"
                                                   class="table-data__input">
                                        </template>
                                    </td>
                                    <td class="table-modal__td table-modal__td_center">
                                        <span v-if="device.mac_address"
                                              class="table-data__text">{{ device.mac_address }}</span>
                                        <span v-else>Неизвестно</span>
                                    </td>
                                    <td class="table-modal__td table-modal__td_center">
                                        <span class="table-data__text">{{ device.device_id }}</span>
                                    </td>
                                    <td class="table-modal__td table-modal__td_center">
                                        <span class="tooltip">
                                            <span class="table-data__hiddentip">
                                                <i class="fal fa-info-circle"></i>
                                            </span>
                                            <span class="tooltip__text">{{ device.triniti_uuid }}</span>
                                        </span>
                                    </td>
                                    <td class="table-modal__td table-modal__td_center">
                                        <span v-if="device.vendor" class="table-data__text">{{ device.vendor }}</span>
                                        <span v-else>Неизвестно</span>
                                    </td>
                                    <td class="table-modal__td table-modal__td_right">
                                        <template v-if="edit_line_id !== device.id">
                                            <div class="btn-group">
                                            <span class="btn-l btn-l_orange" @click="edit_line(device)"
                                                  title="Редактировать">
                                                <span class="btn-l__icon"><i class="fal fa-edit"></i></span>
                                            </span>
                                                <span class="btn-l btn-l_delete" @click="delete_device_alert(device)"
                                                      title="Удалить">
                                                <span class="btn-l__icon"><i class="fal fa-trash-alt"></i></span>
                                            </span>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="btn-group">
                                                <span class="btn-c btn-c_green" @click="edit_device_note(device.id)">
                                                    <span class="btn-c__icon"><i class="fal fa-check"></i></span>
                                                </span>
                                                <span class="btn-c btn-c_grey" @click="show_line()">
                                                    <span class="btn-c__icon"><i class="fal fa-times"></i></span>
                                                </span>
                                            </div>
                                        </template>
                                    </td>
                                </tr>
                            </template>
                            </tbody>
                        </table>
                    </div>
                    <div class="modal-divide">
                        <div class="modal-divide__title"><span class="modal-divide__title-icon">
                            <i class="fal fa-tv-retro"></i></span>Добавить устройство
                        </div>
                    </div>
                    <form class="form-modal-tv">
                        <div class="form-modal-tv__group">
                            <input v-model="code" type="text" class="form-modal-tv__input" placeholder="PIN">
                            <button type="button" class="form-modal-tv__btn" @click="handlerAddDevice">
                                <span class="form-modal-tv__btn-icon"><i
                                        class="fal fa-plus"></i></span>Добавить
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="btn-group">
                <span class="btn btn_grey" @click="popup_close">
                    <span class="btn__icon"><i class="fal fa-times-circle"></i></span>
                    <span class="btn__title">Я передумал</span>
                </span>
            </div>
        </div>
    </popup>
</template>

<script>
    import Popup from '../../../../modal/popup.vue';
    import FormErrors from '../../../../form/errors.vue';
    import {CrudMixin} from "../../../../../../../mixins/admin/crud/crud_base";
    import {API} from "../../../../../../../api";
    import DeviceAlert from './tv-device-alert';
    import {ServiceIptvTrinitiDevicesUnitsHelper} from "../../../../../../../mixins/admin/service-iptv-triniti-devices-units-helper";

    export default {
        name: "tv-device",
        props: ["settings", "service_tv"],
        components: {
            "device-alert": DeviceAlert,
            "popup": Popup,
            "form-error": FormErrors,
        },
        data() {
            return {
                code: "",
                edit_line_id: "",
                delete_device_obj: null,
                view_delete_alert: false,
                view_add_bordering_device_alert: false,
                errors: {}
            }
        },
        mixins: [
            CrudMixin, ServiceIptvTrinitiDevicesUnitsHelper,
        ],
        created(){
            this.initDevicePaymentUnits(this.service_tv);
        },
        computed: {
            count_payment_devices() {
                return this.service_tv.count_devices * this.service_tv.count_payments;
            }
        },
        methods: {
            edit_line(device) {
                this.edit_line_id = device.id;
                this.name = device.note;
            },
            show_line() {
                this.edit_line_id = "";
                this.name = "";
            },
            add_device() {
                var self = this;

                if (!self.code) {
                    this.alert("Ошибка", "Чтобы добавить устройство, нужно указать PIN");
                }

                API.post('/services/iptv-triniti-subscriber-connection/' + self.service_tv.service_object_id + '/add-device/', {code: this.code})
                    .then(function (response) {
                        self.close_bordering_device_alert();
                        self.$emit('update_subscriber_connection');
                        self.popup_close();
                        self.clear();
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            },
            delete_device_alert(delete_device) {
                this.delete_device_obj = delete_device;
                this.view_delete_alert = true;
            },
            close_delete_device_alert() {
                this.delete_device_obj = null;
                this.view_delete_alert = false;
            },
            open_bordering_device_alert() {
                this.view_add_bordering_device_alert = true;
            },
            close_bordering_device_alert() {
                this.view_add_bordering_device_alert = false;
            },
            delete_device() {
                var self = this;

                var data = {
                    id_device: self.delete_device_obj.id,
                }

                API.post('/services/iptv-triniti-subscriber-connection/' + self.service_tv.service_object_id + '/delete-device/', data)
                    .then(function (response) {
                        self.close_delete_device_alert();
                        self.$emit('update_subscriber_connection');
                        self.popup_close();
                        self.clear();
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            },
            edit_device_note(device_id) {
                var self = this;
                self.loader_show();

                var data = {
                    id_device: device_id,
                    note: this.name,
                }
                API.post('/services/iptv-triniti-subscriber-connection/' + self.service_tv.service_object_id + '/edit-note-device/', data)
                    .then(function (response) {
                        self.$emit("update_subscriber_connection");
                        self.show_line();
                        self.errors = {};
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })

            },
            defineCost(payment) {
                if (payment.is_included_in_tariff) {
                    return "Входит в тариф."
                } else {
                    return payment.total_cost_month + 'грн. / ' + payment.total_cost_day + 'грн.'
                }
            },
            handlerAddDevice() {
                if (this.service_tv.devices.length % this.service_tv.count_devices === 0 &&
                    this.service_tv.devices.length !== 0) {
                    this.open_bordering_device_alert();
                } else {
                    this.add_device();
                }
            },
        },
        watch: {
        }
    }
</script>

<style scoped>

</style>