// initial state
const state = () => ({
    access_token: ""
})

// getters
const getters = {
    getAccessToken(state, access_token) {
        return state.access_token || localStorage.getItem('access_token');
    }
}

// actions
const actions = {}

// mutations
const mutations = {
    setAccessToken(state, access_token) {
        localStorage.setItem('access_token', access_token);
        state.access_token = access_token;
    },
    initAccessToken(state) {
        if (localStorage.getItem('access_token')) {
            state.access_token = localStorage.getItem('access_token');
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}