<template>
    <div class="page-body" v-has-perm="'subscribers_access'">
        <div class="view-data">
            <div class="view-data__header">
                <div class="row">
                    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                        <h3><i class="fal fa-network-wired"></i> Точка включения ({{ subscriber_connection.username }})
                        </h3>
                    </div>
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                        <span class="action-link">
                        <router-link
                                :to="{ name: 'subscribers-connections-item', params:{id: subscriber_connection.id }}">
                            <i class="fal fa-eye"></i> Открыть
                        </router-link>
                        </span>
                    </div>
                </div>
            </div>
            <div class="view-data__item">
                <div class="row">
                    <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                        <div class="label"><i class="fal fa-user"></i> Логин:</div>
                    </div>
                    <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8">
                        <div class="value">
                            <router-link
                                    :to="{ name: 'subscribers-connections-item', params:{id: subscriber_connection.id }}">
                                {{ subscriber_connection.username }}
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="view-data__item" v-has-perm="'subscribers_access'">
                <div class="row">
                    <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                        <div class="label"><i class="fal fa-piggy-bank"></i> Баланс:</div>
                    </div>
                    <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8">
                        <!-- Положительный баланс class="balance-positive" Негативный баланс class="balance-negative" -->
                        <div class="value"><span class="balance-positive"><i class="fal fa-hryvnia"></i> {{ subscriber_connection.balance }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="view-data__item">
                <div class="row">
                    <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                        <div class="label"><i class="fal fa-check-double"></i> Статус:</div>
                    </div>
                    <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8">
                        <div class="value">
                            <span class="ustatus" v-bind:class="[cls_list_css_class(subscriber_connection)]">{{ subscriber_connection.cls_list_expand }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="view-data__item">
                <div class="row">
                    <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                        <div class="label"><i class="fal fa-map-marker-alt"></i> Адрес включения:</div>
                    </div>
                    <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8">
                        <div class="value">{{ subscriber_connection_address_full }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {SubscriberMixin} from "../../../../mixins/admin/subscriber";
    import {SubscriberConnectionMixin} from "../../../../mixins/admin/subscriber_connection";

    export default {
        name: "subscribers-item-connection",
        props: ['subscriber_connection'],
        mixins: [SubscriberMixin, SubscriberConnectionMixin],
    }
</script>

<style scoped>

</style>