<template>
    <div class="add-ippool__calc">

        <ul>
            <li>Network: <span class="value"> {{ network }}</span></li>
            <li>Netmask: <span class="value"> /{{netmask}}</span></li>
        </ul>
        <ul>
            <li>HostMin: <span class="value"> {{form.host_min}}</span></li>
            <li>HostMax: <span class="value"> {{form.host_max}}</span></li>
        </ul>
        <ul>
            <li>Hosts/Net: <span class="value"> {{form.count_hosts_net}}</span></li>
        </ul>
    </div>
</template>

<script>
    import {API} from "../../../../../../api";
    import {loader} from "../../../../../../models/loader";
    import FormErrors from '../../../form/errors.vue';

    export default {
        name: "pool-calc",
        props: ['network', 'netmask', 'host_min', 'host_max', 'count_hosts_net'],
        data(){
            return {
                form: {
                    count_hosts_net: 0,
                    host_min: "",
                    host_max: ""
                },
                errors: {}
            }
        },
        components: {
            "form-error": FormErrors
        },
        methods: {
            calc(){
                var self = this;
                self.errors = {};

                loader.active = false;
                API.post('/ip-address/ip-pool-calculate/', {network: this.network, netmask: this.netmask})
                    .then(function (response) {
                        self.form.count_hosts_net = response.data.count_hosts_net;
                        self.form.host_min = response.data.host_min;
                        self.form.host_max = response.data.host_max;
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                        loader.active = true;
                        self.$emit('set_errors', self.errors);
                    })
            }
        },
        watch: {
            network(value){
                this.calc()
            },
            netmask(value){
                this.calc()
            },
            host_min(value){
                this.form.host_min = value;
            },
            host_max(value){
                this.form.host_max = value;
            },
            count_hosts_net(value){
                this.form.count_hosts_net = value;
            }
        }
    }
</script>

<style scoped>

</style>