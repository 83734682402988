

export var SearchMixin = {
    beforeDestroy: function () {
        this.destroy_search_listeners();
    },
    methods: {
        destroy_search_listeners(suffix){
            console.log("OFF search_hit & search_clear");
            this.$root.$off('search_hit_' + suffix);
            this.$root.$off('search_clear_' + suffix);
        }
    }
};