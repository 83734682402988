<template>
    <section class="page">
        <div class="page__inner">
            <div class="container-width">
                <!--HEADER PAGE-->
                <div class="page__header">
                    <h1 class="page__title">Отчет по населенным пунктам</h1>
                    <report-menu :active_name="'reports-page'"></report-menu>
                </div>
                <!--END HEADER PAGE-->
                <!-- BODY PAGE -->
                <div class="page__body">
                    <div class="filters">
                      <div class="filters__body">
                        <div class="filters__select">
                          <year-filter v-model="year" :years="years" :is-table-select="false"></year-filter>
                        </div>
                        <div class="filters__select">
                          <select v-model="region" required>
                              <option disabled :value="undefined">Область</option>
                              <option :value="null">Все области</option>
                              <option v-for="region in regions" :value="region.id">{{ region.name }}
                              </option>
                          </select>
                        </div>
                        <div class="filters__select">
                          <select v-model="district" required>
                            <option disabled :value="undefined">Район</option>
                            <option :value="null">Все районы</option>
                            <option v-for="district in districts" :value="district.id">
                                {{ district.name }}
                            </option>
                        </select>
                        </div>
                        <div class="filters__actions">
                          <div class="filters__btn filters__btn_green" @click="load_settlements()">
                            <span class="filters__btn-icon"></span>
                            <span class="filters__btn-title">Сгенерировать</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="table-box">
                        <table class="table-box__table">
                            <thead class="table-box__thead">
                            <tr class="table-box__thead-tr">
                                <template v-for="col in cols">
                                    <th @click="set_ordering(col.field)" class="table-box__th">
                                        <span class="table-box__thead-item table-box__thead-item_center">
                                            <span class="table-box__thead-title">
                                                {{ col.label }}
                                                <i class="fal" :class="[get_class_ordering_direction(col.field)]"></i>
                                            </span>
                                        </span>
                                    </th>
                                </template>
                            </tr>
                            </thead>
                            <tbody class="table-box__tbody">
                            <tr class="table-box__tr table-data" v-for="settlement in settlements">
                                <td class="table-box__td table-box__td_center">
                                    <span class="table-data__text">
                                        {{ settlement.settlement_name }}
                                    </span>
                                </td>
                                <td class="table-box__td table-box__td_center">
                                    <span class="table-data__currency">₴</span>
                                    <report-link :item="settlement" :field_name="'total'"></report-link>
                                </td>
                                <td class="table-box__td table-box__td_center" v-for="month in [1,2,3,4,5,6,7,8,9,10,11,12]">
                                    <span class="table-data__currency">₴</span>
                                    <report-link :item="settlement" :field_name="'month_' + month"></report-link>
                                </td>
                            </tr>
                            <tr class="table-box__tr table-data" v-if="Object.keys(total).length > 0">
                                <td class="table-box__td">
                                    <span class="table-data__text table-data__text_bold">
                                        Итого:
                                    </span>
                                </td>
                                <td class="table-box__td table-box__td_center">
                                    <span class="table-data__currency">₴</span>
                                    <report-link :item="total" :field_name="'total'"></report-link>
                                </td>
                                <td class="table-box__td table-box__td_center" v-for="month in [1,2,3,4,5,6,7,8,9,10,11,12]">
                                    <span class="table-data__currency">₴</span>
                                    <report-link :item="total" :field_name="'month_' + month"></report-link>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- END BODY PAGE -->
            </div>
        </div>
    </section>

</template>

<script>
    import ReportMenu from './menu/menu.vue';
    import ReportLink from "./sub_components/report-link";
    import {API} from "../../../../api";
    import {OrderMixin} from "../../../../mixins/admin/order";
    import {RouteHelperMixin} from "../../../../mixins/admin/route_helper";
    import YearFilter from "./sub_components/year_filter";
    import {loader} from "../../../../models/loader";

    export default {
        name: "settlements",
        components: {
            "year-filter": YearFilter,
            "report-menu": ReportMenu,
            'report-link': ReportLink,
        },
        data() {
            return {
                cols: [
                    {
                        field: 'settlement_name',
                        label: 'Населенный пункт'
                    },
                    {
                        field: 'total',
                        label: 'Всего'
                    },
                    {
                        field: 'month_1',
                        label: 'Январь'
                    },
                    {
                        field: 'month_2',
                        label: 'Февраль'
                    },
                    {
                        field: 'month_3',
                        label: 'Март'
                    },
                    {
                        field: 'month_4',
                        label: 'Апрель'
                    },
                    {
                        field: 'month_5',
                        label: 'Май'
                    },
                    {
                        field: 'month_6',
                        label: 'Июнь'
                    },
                    {
                        field: 'month_7',
                        label: 'Июль'
                    },
                    {
                        field: 'month_8',
                        label: 'Август'
                    },
                    {
                        field: 'month_9',
                        label: 'Сентябрь'
                    },
                    {
                        field: 'month_10',
                        label: 'Октябрь'
                    },
                    {
                        field: 'month_11',
                        label: 'Ноябрь'
                    },
                    {
                        field: 'month_12',
                        label: 'Декабрь'
                    },
                ],
                total: [],
                year: this.$moment().year(),
                district: null,
                region: null,
                ordering: "settlement_name",
                ordering_direction: 'ASC',
                years: [],
                settlements: [],
                regions: [],
                districts: [],
                errors: []
            }
        },
        mixins: [OrderMixin, RouteHelperMixin,],
        created() {
            this.set_data_from_route([
                {'name': 'ordering_direction', 'type': 'str'},
                {'name': 'year', 'type': 'int'},
                {'name': 'ordering', 'type': 'str'}], this);

            this.loader_show();
            this.load_years();
            this.load_regions();
            this.load_districts(this.region);
        },
        updated() {
          loader.active = false;
        },
        methods: {
            load_settlements() {
                var self = this;

                loader.active = true;

                var filter = {
                    year: this.year,
                    ordering: this.ordering_direction === "ASC" ? this.ordering : "-" + this.ordering,
                };
                if (self.district) {
                    filter['district'] = self.district;
                }
                if (self.region) {
                    filter['region'] = self.region;
                }

                API.get('/report/settlement/', filter)
                    .then(function (response) {
                        self.settlements = response.data.results;
                        self.total = response.data.summary_report;

                        // Update router
                        self.$router.replace({
                            query: Object.assign({}, self.$route.query, {
                                year: self.year,
                                ordering: self.ordering,
                                ordering_direction: self.ordering_direction,
                                region: self.region,
                                district: self.district
                            }),
                        });
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            },
            load_years() {
                var self = this;

                API.get('/report/settlement/years/')
                    .then(function (response) {
                        self.years = response.data;
                    })
                    .catch(function (error) {
                        API.default_catch(self, error)
                    })
                    .then(function () {
                        API.default_always(self);
                    })
            },
            load_regions: function () {
                var self = this;
                loader.active = false;
                API.get('/address/region/')
                    .then(function (response) {
                        loader.active = true;
                        self.regions = response.data.results;
                    })
            },
            load_districts: function (region) {
                var self = this;
                if (region === "") {
                    return
                }
                loader.active = false;
                API.get('/address/district/', {region: region})
                    .then(function (response) {
                        self.districts = response.data.results;

                        // Select by default first
                        if ((self.districts.length > 0) && (self.district === "")) {
                            self.district = self.districts[0].id;
                        }
                        loader.active = true;
                    })
            },
        },
        watch: {
            ordering(value) {
                this.load_settlements();
            },
            ordering_direction(value) {
                this.load_settlements();
            },
            region(value) {
                this.load_districts(value);
            },
        }
    }
</script>

<style scoped>

</style>