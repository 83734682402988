<template>
    <popup :settings="settings">
        <div slot="title">Возврат партии</div>
        <div slot="content" v-if="item">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="modal__content">
                    <div class="return-unicard">
                        <p><i class="fal fa-info-circle"></i> Вы действительно хотите вернуть партию?</p>
                        <ul class="return-unicard__list">
                            <li><span class="title">От:</span>
                                {{ get_owner(item) }}
                            </li>
                            <li><span class="title">Кому:</span>
                                <template v-if="item.owner_from_expand">
                                    {{ item.owner_from_expand.fio }}
                                </template>
                            </li>
                            <li><span class="title">Серия:</span>
                                {{ get_series(item) }}
                            </li>

                            <return_intervas :intervals="unicards_intervals.batch_unicards" :title="'Диапазон'" ></return_intervas>
                            <return_intervas :intervals="unicards_intervals.shop_unicards" :title="'В магазине'" ></return_intervas>
                            <return_intervas :intervals="unicards_intervals.returned_unicards" :title="'Возвращены'" ></return_intervas>

                            <li><span class="title">Всего кол-во:</span>{{ item.count_unicards }} шт.</li>
                        </ul>
                    </div>

                    <form-error v-bind:value="errors.non_field_errors"></form-error>
                </div>
            </div>
        </div>
        <div slot="footer">
            <a href="#" @click="return_item()" class="modal__btn">Вернуть</a>
            <a href="#" @click="popup_close">Отмена</a>
        </div>
    </popup>
</template>

<script>
    import Popup from '../../modal/popup.vue'
    import {PopupMixin} from "../../../../../mixins/admin/popup";
    import {UnicardsMixin} from "../../../../../mixins/admin/unicards";
    import FormErrors from '../../form/errors.vue';
    import {API} from "../../../../../api";
    import Return_intervas from "./sub_components/return_intervas";

    export default {
        name: "return",
        props: ["settings", "item", "type"],
        components: {
            Return_intervas,
            "popup": Popup,
            "form-error": FormErrors,
        },
        mixins: [
            PopupMixin, UnicardsMixin
        ],
        data(){
          return {
              unicards_intervals: {},
              errors: {}
          }
        },
        methods: {
            return_item(){
                var url = '', self = this;

                if(this.is_main_type()){
                    url = '/unicards/batch/'+this.item.id+'/return-batch/';
                } else {
                    url = '/unicards/shop-batch/'+this.item.id+'/return-shop-batch/';
                }

                API.post(url).then(function (response) {
                    self.$emit("update_list");
                    self.popup_close();
                }).catch(function (error) {
                    API.default_catch(self, error);
                })
            }
        },
        watch: {
            item (){
                this.errors = {};
                this.unicards_intervals = {};
                this.get_unicard_intervals(this.item.id);
            }
        }
    }
</script>

<style scoped>

</style>