import {API} from "../../api";

export var EmployeePositionsMixin = {
    data: function () {
        return {
            employee_positions: [],
        }
    },
    methods: {
        load_employee_positions: function () {
            var self = this;
            API.get('/employee-positions/')
                .then(function (response) {
                    self.employee_positions = response.data.results;
                })
                .catch(function (error) {
                    // API.default_catch(self, error)
                })
                .then(function () {
                    // API.default_always(self);
                })
        },
        get_permission_by_position: function (employee_position_id) {
            for(var index in this.employee_positions){
                if(this.employee_positions[index].id === employee_position_id){
                    return this.employee_positions[index].position_permissions_expand;
                }
            }
            return []
        }
    }
};
