<template>
    <section class="page" v-has-perm="'subscribers_add_edit'">
        <div class="page__inner">
            <div class="container-width">
                <!-- HEADING PAGE -->
                <div class="page__header">
                    <h1 class="page__title">Добавление точки включения</h1>
                    <div class="btn-group">
                        <span class="btn btn_grey" @click="back">
                            <span class="btn__icon"><i class="fal fa-undo"></i></span>
                            <span class="btn__title">Вернуться</span>
                        </span>
                    </div>
                </div>
                <!-- END HEADING PAGE -->
                <!--BODY PAGE-->
                <div class="page__body">
                    <form action="" class="form-data">
                        <div class="form-data__row">
                            <div class="form-data__section">
                                <!--Данные поключения-->
                                <div class="form-data__head">
                                    <span class="form-data__head-icon"><i class="fal fa-power-off"></i></span>
                                    <span class="form-data__head-title">Данные подключения</span>
                                </div>
                                <!--Группа-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-folder"></i></span>
                                            <span class="form-data__label-title">Группа:</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <div class="form-data__select">
                                                <select v-model="subscriber_connection.group" required>
                                                    <option value="" hidden>Группа</option>
                                                    <option v-for="group in groups" v-bind:value="group.id">
                                                        {{ group.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <form-error v-bind:value="errors.group"></form-error>
                                </fieldset>
                                <!--Отдел-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i
                                                    class="fal fa-code-branch"></i></span>
                                            <span class="form-data__label-title">Отдел:</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <div class="form-data__select">
                                                <select v-model="subscriber_connection.department" required>
                                                    <option value="" hidden>Отдел</option>
                                                    <option v-for="department in departments"
                                                            v-bind:value="department.id">
                                                        {{ department.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <form-error v-bind:value="errors.department"></form-error>
                                </fieldset>
                                <!--Монтажник-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-wrench"></i></span>
                                            <span class="form-data__label-title">Монтажник:</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <div class="form-data__select">
                                                <select v-model="subscriber_connection.employee_mounter" required>
                                                    <option value="" hidden>Монтажник</option>
                                                    <option v-for="employee in employees" v-bind:value="employee.id">
                                                        {{ employee.fio }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <form-error v-bind:value="errors.employee_mounter"></form-error>
                                </fieldset>
                                <!--Технические данные-->
                                <div class="form-data__head">
                                    <span class="form-data__head-icon"><i class="fal fa-ethernet"></i></span>
                                    <span class="form-data__head-title">Технические данные</span>
                                </div>
                                <!--Логин-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-user"></i></span>
                                            <span class="form-data__label-title">Логин PPPoE:</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <input type="text" v-model="subscriber_connection.username"
                                                   class="form-data__input"
                                                   placeholder="Логин"
                                                   :disabled="!has_perm('subscriber_connection_edit_username_password')">
                                            <span class="password-generator__btn"
                                                  data-title="Сгенерировать новый логин"
                                                  @click="change_login()"><i class="fal fa-sync"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <form-error v-bind:value="errors.username"></form-error>
                                </fieldset>
                                <!--Пароль-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-key"></i></span>
                                            <span class="form-data__label-title">Пароль PPPoE:</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <password size="9" auto="true" v-model="subscriber_connection.password"
                                                      characters="0-9"
                                                      :disabled="!has_perm('subscriber_connection_edit_username_password')"
                                                      placeholder="Пароль"></password>
                                        </div>
                                    </div>
                                    <form-error v-bind:value="errors.password"></form-error>
                                </fieldset>
                                <!--NAS-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-server"></i></span>
                                            <span class="form-data__label-title">NAS:</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <div class="form-data__select">
                                                <select v-model="subscriber_connection.nas" required>
                                                    <option value="" hidden>NAS</option>
                                                    <option v-for="item in nas" v-bind:value="item.id">
                                                        {{ item.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <form-error v-bind:value="errors.nas"></form-error>
                                </fieldset>
                                <!--Технология-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-ethernet"></i></span>
                                            <span class="form-data__label-title">Технология:</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <div class="form-data__select">
                                                <select v-model="subscriber_connection.connection_type" required>
                                                    <option value="" hidden>Тип подключения</option>
                                                    <option v-for="connection_type in connection_types"
                                                            v-bind:value="connection_type.id">
                                                        {{ connection_type.label }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <form-error v-bind:value="errors.connection_type"></form-error>
                                </fieldset>

                                <!--Производитель-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-trademark"></i></span>
                                            <span class="form-data__label-title">Производитель:</span>
                                        </div>
                                        <div class="form-data__value">
                                            <input type="text" v-model="subscriber_connection.device_manufacturer"
                                                   class="form-data__input" placeholder="Производитель">
                                        </div>
                                    </div>
                                    <form-error v-bind:value="errors.device_manufacturer"></form-error>
                                </fieldset>

                                <!--Модель-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-barcode"></i></span>
                                            <span class="form-data__label-title">Модель:</span>
                                        </div>
                                        <div class="form-data__value">
                                            <input type="text" v-model="subscriber_connection.device_model"
                                                   class="form-data__input" placeholder="Модель">
                                        </div>
                                    </div>
                                    <form-error v-bind:value="errors.device_model"></form-error>
                                </fieldset>

                                <!--MAC-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-phone-laptop"></i></span>
                                            <span class="form-data__label-title">MAC:</span>
                                        </div>
                                        <div class="form-data__value">
                                            <input type="text" v-model="subscriber_connection.device_mac"
                                                   class="form-data__input" placeholder="MAC">
                                        </div>
                                    </div>
                                    <form-error v-bind:value="errors.device_mac"></form-error>
                                </fieldset>

                                <!--Port-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-brackets"></i></span>
                                            <span class="form-data__label-title">Порт:</span>
                                        </div>
                                        <div class="form-data__value">
                                            <input type="text" v-model="subscriber_connection.device_port"
                                                   class="form-data__input" placeholder="Порт">
                                        </div>
                                    </div>
                                    <form-error v-bind:value="errors.device_port"></form-error>
                                </fieldset>

                                <!--Дата создания-->
                                <fieldset class="form-data__fieldset" v-if="has_perm('subscriber_connection_edit_created_at')">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-ethernet"></i></span>
                                            <span class="form-data__label-title">Дата создания:</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                <date-picker v-model="subscriber_connection.created_at"
                                                             :lang="'ru'"
                                                             placeholder="Дата(Необязательно)"
                                                             :format="'YYYY-MM-DD'">
                                                </date-picker>
                                            </div>
                                        </div>
                                    </div>
                                    <form-error v-bind:value="errors.created_at"></form-error>
                                </fieldset>
                            </div>
                            <div class="form-data__section">
                                <!--Адрес подключения-->
                                <div class="form-data__head">
                                    <span class="form-data__head-icon"><i class="fal fa-map-marker-alt"></i>    </span>
                                    <span class="form-data__head-title">Адрес подключения</span>
                                </div>
                                <!--Область-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i
                                                    class="fal fa-globe-europe"></i></span>
                                            <span class="form-data__label-title">Область:</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <div class="form-data__select">
                                                <select v-model="region" required>
                                                    <option value="" hidden>Область</option>
                                                    <option v-for="item in regions" v-bind:value="item.id">
                                                        {{ item.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <form-error v-bind:value="errors.region"></form-error>
                                </fieldset>
                                <!--Район-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i
                                                    class="fal fa-map-marked-alt"></i></span>
                                            <span class="form-data__label-title">Район:</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <div class="form-data__select">
                                                <select v-model="district" required>
                                                    <option value="" hidden>Район</option>
                                                    <option v-for="item in districts" v-bind:value="item.id">
                                                        {{ item.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <form-error v-bind:value="errors.district"></form-error>
                                </fieldset>
                                <!--Город-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i
                                                    class="fal fa-map-marker-alt"></i></span>
                                            <span class="form-data__label-title">Город:</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <div class="form-data__select">
                                                <select v-model="settlement" required>
                                                    <option value="" hidden>Город</option>
                                                    <option v-for="item in settlements" v-bind:value="item.id">
                                                        {{ item.name }}
                                                    </option>
                                                </select>
                                            </div>
                                            <span class="form-data__add-input" data-title="Добавить город" @click="show_settlement_popup">
                                                <i class="fal fa-plus"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <form-error v-bind:value="errors.settlement"></form-error>
                                </fieldset>
                                <!--Улица-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-road"></i></span>
                                            <span class="form-data__label-title">Улица:</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <v-select v-model="subscriber_connection.street" label="name"
                                                      :reduce="street => street.id" :options="streets"></v-select>
                                            <span class="form-data__add-input" data-title="Добавить улицу" @click="show_street_popup">
                                                <i class="fal fa-plus"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <form-error v-bind:value="errors.street"></form-error>
                                </fieldset>
                                <!--Дом-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-home"></i></span>
                                            <span class="form-data__label-title">Дом:</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <input type="text" v-model="subscriber_connection.house_number"
                                                   class="form-data__input" placeholder="№ дома">
                                        </div>
                                    </div>
                                    <form-error v-bind:value="errors.house_number"></form-error>
                                </fieldset>
                                <!--Квартира-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i class="fal fa-building"></i></span>
                                            <span class="form-data__label-title">Квартира:</span>
                                        </div>
                                        <div class="form-data__value">
                                            <input type="text" v-model="subscriber_connection.apartment_number"
                                                   class="form-data__input" placeholder="№ квартиры">
                                        </div>
                                    </div>
                                    <form-error v-bind:value="errors.apartment_number"></form-error>
                                </fieldset>
                                <!--Счет абонента-->
                                <div class="form-data__head">
                                    <span class="form-data__head-icon"><i class="fal fa-hryvnia"></i></span>
                                    <span class="form-data__head-title">Счет</span>
                                </div>
                                <!--Тариф-->
                                <fieldset class="form-data__fieldset">
                                    <div class="form-data__item">
                                        <div class="form-data__label">
                                            <span class="form-data__label-icon"><i
                                                    class="fal fa-briefcase"></i></span>
                                            <span class="form-data__label-title">Тариф:</span>
                                            <span class="form-data__label-control"><i
                                                    class="fal fa-asterisk"></i></span>
                                        </div>
                                        <div class="form-data__value">
                                            <div class="form-data__select">
                                                <select v-model="subscriber_connection.tariff" required>
                                                    <option value="">Выберите тариф</option>
                                                    <option v-for="tariff in tariffs"
                                                            v-bind:value="tariff.id">
                                                        {{ tariff.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <form-error v-bind:value="errors.tariff"></form-error>
                                </fieldset>
                            </div>
                        </div>
                        <div class="form-data__footer form-data__footer_right">
                            <div class="btn-group">
                                <span class="btn btn_blue" @click="save()">
                                    <span class="btn__icon"><i class="fal fa-save"></i></span>
                                    <span class="btn__title">Сохранить</span>
                                </span>
                            </div>
                        </div>
                    </form>
                </div>
                <!--END BODY PAGE-->
            </div>

            <add-settlement-popup :settings="settlement_popup" v-on:update_settlement="update_settlement"></add-settlement-popup>
            <add-street-popup :settings="street_popup" v-on:update_street="update_street"></add-street-popup>
        </div>
    </section>
</template>

<script>
    import {API} from "../../../../api";
    import {SubscriberConnectionMixin} from "../../../../mixins/admin/subscriber_connection";
    import {SubscriberMixin} from "../../../../mixins/admin/subscriber";
    import {GroupMixin} from "../../../../mixins/admin/group";
    import {DepartmentMixin} from "../../../../mixins/admin/department";
    import {NasMixin} from "../../../../mixins/admin/nas";
    import {ConnectionTypeMixin} from "../../../../mixins/admin/connection_type";
    import {AddressMixin} from "../../../../mixins/admin/address";
    import AddSettlement from './add-settlement.vue'
    import AddStreet from './add-street.vue'
    import PasswordGeneration from '../password/generation.vue';
    import FormErrors from '../form/errors.vue';
    import {TariffMixin} from "../../../../mixins/admin/tariff";
    import {EmployeeMixin} from "../../../../mixins/admin/employee";
    import DatePicker from 'vue2-datepicker';

    export default {
        name: "subscriber-connections-add",
        data: function () {
            return {
                settlement_popup: {
                    region: "",
                    district: "",
                    visible: false
                },
                street_popup: {
                    visible: false
                },
                subscriber_connection: {
                    subscriber: "",
                    apartment_number: "",
                    house_number: "",
                    street: "",
                    settlement: "",
                    connection_type: "",
                    nas: "",
                    device_manufacturer: "",
                    device_model: "",
                    device_mac: "",
                    device_port: "",
                    password: "",
                    department: "",
                    group: "",
                    tariff: "",
                    username: "",
                    created_at: undefined,
                },
                subscriber: {
                    id: "",
                },
                region: "",
                district: "",
                settlement: "",
                errors: {}
            }
        },
        components: {
            "password": PasswordGeneration,
            "add-settlement-popup": AddSettlement,
            "add-street-popup": AddStreet,
            "form-error": FormErrors,
            "date-picker": DatePicker,
        },
        mixins: [
            SubscriberMixin,
            SubscriberConnectionMixin,
            GroupMixin,
            DepartmentMixin,
            NasMixin,
            ConnectionTypeMixin,
            EmployeeMixin,
            AddressMixin,
            TariffMixin,
        ],
        created: function () {
            this.subscriber.id = this.$route.params.id;
            this.subscriber_connection.subscriber = this.subscriber.id;
            this.load_groups();
            this.load_departments();
            this.load_nas_by_department_employee_permissions();
            this.load_connection_type();
            this.load_regions();
            this.load_districts(this.region);
            this.load_settlements(this.district);
            this.load_streets();
            this.load_subscriber(this.subscriber.id);
            this.load_employees();
            this.get_available_login();
        },
        methods: {
            save: function () {
                var self = this;

                if (this.has_perm('subscriber_connection_edit_created_at')
                    &&!this.subscriber_connection.created_at) {
                    this.subscriber_connection.created_at = this.$moment();
                }

                // Add Subscriber Connection
                this.add_subscriber_connection(this.subscriber_connection).then(function (response) {
                    self.show_subscriber();
                })
                    .catch(function (error) {
                        API.default_catch(self, error);
                    })
                    .then(function () {
                        API.default_always(self);
                    });
            },
            get_available_login() {
                var self = this;

                this.subscriber_connections_get_available_login().then(function (response) {
                    self.subscriber_connection.username = response.data.username;
                })
            },
            change_login() {
                if (this.has_perm('subscriber_connection_edit_username_password')){
                    this.get_available_login();
                }
            },
            show_subscriber: function () {
                this.$router.push({name: "subscribers-item", params: {"id": this.subscriber.id}})
            },
            back: function () {
                this.show_subscriber();
            }
        },
        watch: {
            // subscriber_types: function (value) {
            //     this.subscriber.subscriber_type = value[0].id;
            // },
            // nas: function (value) {
            //     this.subscriber_connection.nas = value[0].id;
            // },
            'subscriber_connection.group': function (value) {
                this.load_tariff();
            },
            'subscriber_connection.department': function (value) {
                this.load_tariff();
            },
            'subscriber_connection.connection_type': function (value) {
                this.load_tariff();
            },
            settlement: function (value) {
                this.$set(this.subscriber_connection, "settlement", value);
                this.load_tariff();
            }
        }
    }
</script>

<style scoped>

</style>