<template>
    <div>
        <div class="add-data__header">
            <h3><i class="fal fa-print"></i> {{ title }}:</h3>
        </div>
        <div class="add-data-docs">
            <div class="row">
                <template v-for="(file_item, index) in files_list">
                    <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
                        <file-base64
                                class="v1 docs-item"
                                placeholder="Выберите документ"
                                accept="application/pdf,image/png,image/jpeg"
                                image-class="v1-image"
                                input-class="v1-image"
                                :defaultPreview="files_list[index].file_expand"
                                :fileName="files_list[index].name"
                                :index="index"
                                :disable-preview="true"
                                :max-size="customImageMaxSize"
                                @size-exceeded="onSizeExceeded"
                                @load="onLoad"
                                :value="fileName || file && file.name"
                        />
                        <form-error v-if="errors && errors[index]" v-bind:value="set_errors(index, errors)"></form-error>
                        <span v-if="!readonly" class="remove-item-docs" @click="remove_doc(index)"><i
                                class="fal fa-times-circle"></i></span>
                    </div>
                </template>
                <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3" v-if="!readonly">
                     <span class="add-item-docs" @click="add_doc">
                        <i class="fal fa-plus"></i>
                        <span class="add-item-docs__title">Добавить документ</span>
                     </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import FormErrors from './errors.vue';
    import FileBase64 from '../file_base64/item';

    export default {
        name: "file-multiupload",
        props: ["files_input", "errors", "file_max_size", "title", "readonly"],
        data() {
            var customImageMaxSize;
            if(this.file_max_size){
                customImageMaxSize = this.file_max_size;
            } else {
                customImageMaxSize = 20;
            }

            return {
                customImageMaxSize: customImageMaxSize, // megabytes
                files_list: []
            }
        },
        methods: {
            onLoad(dataUri, file, index) {
                // this.files_list[index] = {'file': dataUri, 'name': file.name};
                this.$set(this.files_list, index, {'file': dataUri, 'name': file.name});
                this.$emit("set_files", this.files_list);
            },
            onSizeExceeded(size) {
                this.alert('Ошибка', `Размер загружаемого файла ${size}Mb превышает максимально возможный ${this.customImageMaxSize}Mb!`);
            },
            add_doc() {
                this.files_list.push({'name': '', 'file': ''});
            },
            remove_doc(key) {
                this.files_list.splice(key, 1);
            },
            set_errors(index, errors){
                var values = Object.values(errors[index]);
                var result = [];
                for(var i in values){
                    result.push(values[i][0])
                }

                return result
            }
        },
        components: {
            "form-error": FormErrors,
            "file-base64": FileBase64,
        },
        watch: {
            files_input:{
                handler(value) {
                    if(value !== null && value !== undefined) {
                        this.files_list = value;
                    }
                },
                immediate: true,
                deep: true
            },
            files_list: function (value) {
                if(this.files_input !== value) {
                    this.$emit("set_files", value);
                }
            }
        }
    }
</script>

<style scoped>

</style>