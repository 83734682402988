
export var PopupMixin = {
    data: function () {
        return {
        }
    },
    methods: {
        popup_close() {
            this.settings.visible = false;
        }
    }
};
